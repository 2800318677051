import axios from 'axios';
import API from '../Api';

export function addService(params) {
    return axios.post(API.ADD_SERVICE,params)
}

export function getServices(params) {
    return axios.get(API.GET_SERVICES,params)
} 

export function getServicesForSession(params) {
    return axios.get(API.GET_SERVICES_FOR_SESSION,params)
}

export function updateService(params) {
    return axios.post(API.UPDATE_SERVICE,params)
}

export function deleteService(params) {
    return axios.post(API.DELETE_SERVICE,params)
}

export function getV2Services(params) {
    return axios.get(API.GET_SERVICES_V2,params)
}
