import React, { useState, useEffect } from 'react';
import {getAllVerticals, enableVertical} from '../../config/services/commercialService';
import { toast } from 'react-toastify';
import { Tag } from "antd";
import { Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import EditSparePartsModal from './EditSparePartsModal';
import AddSparePartsModal from './AddSparePartsModal';

const itemsCountPerPage = 10;
const columnDetails = [
    { fieldName: "Sr.No." },
    { fieldName: "Product Type" },
    { fieldName: "Spare Parts" },
    { fieldName: "Action" },
];


const SpareParts = () => {
    const [sparePartsList, setSparePartsList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalSpareParts, setTotalSpareParts] = useState(0);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [sparePartsToEdit, setSparePartsToEdit] = useState();
    const [sparePartsToAdd, setSparePartsToAdd] = useState(false);

    useEffect(() => {
        fetchSpareParts(pageNumber);
    }, []);


    const fetchSpareParts = (pageNo) => {
        const params = { pageNo: pageNo, pageSize: itemsCountPerPage };
        getAllVerticals({params})
            .then(res => {
                const response = res.data;
                if (response.statusCode == 1) {
                    const dataObj = response.responseData;
                    dataObj.result && setSparePartsList(dataObj.result);
                    dataObj.result && dataObj.result.pagination && setTotalSpareParts(dataObj.result.pagination.totalRecords);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
        fetchSpareParts(pageNo);
    };

    const openEditModal = (spareParts) => {
        setShowEditModal(true);
        setSparePartsToEdit(spareParts);
    };

    const openAddModal = (spareParts) => {
        setShowAddModal(true);
        setSparePartsToAdd(spareParts);
    };

    const hideEditModal = () => setShowEditModal(false);
    const hideAddModal = () => setShowAddModal(false);

    const handleChangeStatus = (id, isEnabled) => {
        let obj = {
        verticalId: id,
        isEnable: !isEnabled
    }

    enableVertical(obj)
      .then((res) => {
        fetchSpareParts(pageNumber)
        if (res.data.statusCode === 1) {
            toast.success(res.data.responseData.message)
            getAllVerticals();
            
        } else if (res.data.statusCode === 1) {
            toast.error(res.data.error.errorMessage)
        }
      })
  }

    return (
        <div className="main-wrapper">
            <div className="add-gym-button">
            <Button type="primary" onClick={() => openAddModal(setSparePartsToAdd)}>+ Spare Parts</Button>
            </div>
            <div className="table-responsive mb-4" style={{ minHeight: '100px' }}>

                <table className="table table-bordered table-striped text-center">
                    <thead>
                        <tr>
                            {columnDetails.map((item, i) => 
                                <th scope="col" key={i}>{item.fieldName}</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {sparePartsList.map((spareParts, index) => {

                            return (
                                <tr key={spareParts._id}>
                                    <td className="cm_no_wrap">{index + 1}</td>
                                    <td className="cm_no_wrap">{spareParts.verticalName}</td>
                                    <td class="text-left">
                                        <div className="wrapper-overflow">
                                            {spareParts.spares && spareParts.spares.map((spare, i) => (
                                                <span key={i}>
                                                    <Tag color="geekblue">{spare.name}</Tag>
                                                </span>
                                            ))}
                                        </div>
                                    </td>
                                    
                                    <td className="cm_no_wrap">
                                    <Button className='m-1' variant="outline-info" onClick={() => openEditModal(spareParts)}> <i className="fa fa-pencil" /> </Button>
                                    
                                    <span className={`cm_ckeckbox_wrapper ${spareParts.isEnabled === true ? 'cm_active' : 'cm_inactive'}`}
                                    onClick={() => handleChangeStatus(spareParts._id, spareParts.isEnabled)}>
                                    <span className="cm_ckeckbox_btn"></span> 
                                    </span> 
                                    
                                    

                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalSpareParts}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
            </div>

            {showEditModal &&
                <EditSparePartsModal
                  showModal={showEditModal}
                  hideModal={hideEditModal} 
                  sparePartsToEdit={sparePartsToEdit}
                  fetchSpareParts={fetchSpareParts}
                  pageNo = {pageNumber}
                />
            }

            {showAddModal &&
                <AddSparePartsModal
                  showModal={showAddModal}
                  hideModal={hideAddModal} 
                  spareParts={sparePartsToAdd}
                  fetchSpareParts={fetchSpareParts}
                  pageNo = {pageNumber}
                />
            }           
        </div>
    );
};

export default SpareParts;