import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { addProductCoupon, editProductCoupon } from '../../config/services/serviceProductCoupon';
import moment from 'moment';
import { DatePicker } from 'antd';

const AddEditForm = (props) => {
	const { addEditPopup, setAddEditPopup, editMode, updateFormValues, /* getCouponList */ } = props;
  const intialValues = { 
    couponName: '',
    discount: '',
    startDate: null,
    endDate: null,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  const [apiErrorMSG, setApiErrorMSG] = useState({});
	
  // SUBMIT CHECKOUT FORM
  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMSG({});
    setIsSubmitting(true);
    if (validate(formValues)) {
      setIsSubmittingLoader(true);
      formValues.startDate = formValues.startDate ? new Date(Number(formValues.startDate)).valueOf() : null;
      formValues.endDate = formValues.endDate ? new Date(Number(formValues.endDate)).valueOf() : null;
      if(formValues._id) {
        editProductCoupon(formValues)
          .then((response) => {
            // console.log("response", response);
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {              
              if (Object.keys(response.data.responseData.result).length > 0) {
                // getCouponList();
                setApiErrorMSG({ status: 'success', msg: response.data.responseData.message });
              } else {                
                setApiErrorMSG({ status: 'error', msg: response.data.responseData.message });
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error});
            setIsSubmittingLoader(false);
          });
      } else {
        addProductCoupon(formValues)
          .then((response) => {
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {
              if (Object.keys(response.data.responseData.result).length > 0) {
                // getCouponList();
                setFormValues(intialValues);
                setAddEditPopup(false, true, true);
								setApiErrorMSG({}); 
								setIsSubmittingLoader(false);
                // setApiErrorMSG({ status: 'success', msg: response.data.responseData.message });
              } else {
                setApiErrorMSG({ status: 'error', msg: response.data.responseData.message });
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error});
            setIsSubmittingLoader(false);
          });
      }
    }
  };
  // HANDLE CHANGE FORM
  const handleChange = (event) => {
    let formValuesSubmited = formValues;
    const { name, value } = event.target;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  };
  const handleChangeDate = (name, val) => {
    setFormErrors({ ...formErrors, [name]: '' });
    setFormValues({ ...formValues, [name]: val });
  }
  // VALIDATION FORM
  const validate = (values) => {
    let errors = {};
    if (!values.couponName) {
      errors.couponName = 'Required';
    }
    if (!values.discount) {
      errors.discount = 'Required';
    }
    if (!values.startDate) {
      errors.startDate = 'Required';
    }
    if (!values.endDate) {
      errors.endDate = 'Required';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
	
	useEffect(() => {
		if(editMode) {
    	setFormValues(updateFormValues);
		} else {
			setFormValues(intialValues);
		}
  }, [editMode, updateFormValues]);

  return (
    <Popup open={addEditPopup} closeOnDocumentClick={false} onClose={() => {
			setFormValues(intialValues);
			setFormErrors({}); 
			setApiErrorMSG({}); 
			setIsSubmittingLoader(false);
			setAddEditPopup(false, editMode, false);
		}}>
			<div className="cm_modal pl-5 pr-5">
				<span className="cm_modal_close" onClick={() => {
					setFormValues(intialValues); 
					setFormErrors({}); 
					setApiErrorMSG({}); 
					setIsSubmittingLoader(false);
					setAddEditPopup(false, editMode, false);
				}}>&times; </span>
				<h3 className="text-center mb-4 mt-5">{formValues._id ? 'Update record' : 'Add new record'} </h3>
				<div className="text-danger text-center h3"> </div>
				<form onSubmit={handleSubmit}>
					<p className={apiErrorMSG.status === 'error' ? 'error' : 'success'}>{apiErrorMSG.msg}</p>
					<div className="form-group">
						<label>Coupon Name (*)</label>
						<input
							type="text"
              disabled={editMode ? true : false}
							className="form-control"
							placeholder="Coupon"
							name="couponName"
							onChange={handleChange}
							value={formValues.couponName}
						/>
						{formErrors.couponName && <label style={{color: 'red'}}>{formErrors.couponName}</label>}
					</div>
					<div className="form-group">
						<label>Discount % (*)</label>
						<input
							type="text"
							className="form-control"
							placeholder="Discount"
							name="discount"
							maxLength={2}
							onChange={handleChange}
							value={formValues.discount}
						/>
						{formErrors.discount && <label style={{color: 'red'}}>{formErrors.discount}</label>}
					</div>
					<div className="form-group">
						<label>Start Date (*) </label>
						<DatePicker
							className="form-control d-block"
							dropdownClassName="upar_index"
							placeholderText="Start Date"
							onChange={(val) => {handleChangeDate('startDate', val)}}
							name="startDate"
							// selected={formValues.startDate}
							defaultValue={formValues.startDate ? moment(formValues.startDate) : null}
						/>
						{formErrors.startDate && <label style={{color: 'red'}}>{formErrors.startDate}</label>}
					</div>
					<div className="form-group">
						<label>End Date (*)</label>
						<DatePicker
							className="form-control"
							dropdownClassName="upar_index"
							placeholderText="End Date"
							onChange={(val) => {handleChangeDate('endDate', val)}}
							name="endDate"
							defaultValue={formValues.endDate ? moment(formValues.endDate) : null}
						/>
						{formErrors.endDate && <label style={{color: 'red'}}>{formErrors.endDate}</label>}                               
					</div>
					<div className="form-group">
						<button type="submit" className="btn btn-primary btn-block mt-4" disabled={isSubmittingLoader}>
							{isSubmittingLoader && (<i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>)} {formValues._id ? 'Update' : 'Save'}
						</button>
					</div>
				</form>
			</div>
		</Popup>
  );
};

export default AddEditForm;