import axios from 'axios';
import API from '../Api';

export function getRidersList(params) {
    return axios.get(API.GET_RIDERS_LIST, params)
}

export function verifyRiderByAdmin(params) {
    return axios.post(API.VERIFY_RIDER_BY_ADMIN, params)
}

export function getRidersLocationLogs(params) {
    return axios.get(API.GET_RIDERS_LOCATION_LOGS, params)
}

export function deleteRider(params) {
    return axios.post(API.DELETE_RIDER, params)
}

export function editRider(params) {
    return axios.post(API.EDIT_RIDER, params)
}
