import axios from 'axios';
import API from '../Api';

const accessToken = localStorage.getItem('accessToken');


export function createFreeSessionByAdmin(params) {
    return axios.post(API.CREATE_FREE_SESSION_BY_ADMIN, params)
}
export function getFreeSessionsByAdmin(params) {
    return axios.get(API.GET_FREE_SESSIONS_BY_ADMIN, params)
}
export function deleteFreeSessionByAdmin(params) {
    return axios.post(API.DELETE_FREE_SESSIONS_BY_ADMIN, params)
}