import axios from 'axios';
import API from '../Api';



export function getUserDietPlan(params) {
    return axios.get(API.GET_USER_DIET_PLAN,params)
}

export function editDietPlan(params) {
    return axios.post(API.EDIT_DIET_PLAN,params)
}

export function getAllHistory(params) {
    return axios.get(API.GET_ALL_HISTORY_BY_ADMIN, params)
}

export function getDietPlanHistory(params) {
    return axios.get(API.GET_SINGLE_PLAN_HISTORY_BY_ADMIN, params)
}

export function getDietPlanApprovePending(params) {
    return axios.get(API.GET_DIET_PLAN_APPROVE_PENDING, params)
}

export function approveDietPlan(params) {
    return axios.post(API.APPROVE_DIET_PLAN, params)
}

export function rejectDietPlan(params) {
    return axios.post(API.REJECT_DIET_PLAN, params)
}

export const DietPlanService = {
    getUserDietPlan,
    editDietPlan,
    getAllHistory,
    getDietPlanHistory,
    getDietPlanApprovePending,
    approveDietPlan,
    rejectDietPlan
};
