import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Service from '../adminServices/AdminService'


class ProductService extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let { userId, profile } = this.props;
        return (
            <Service isFromUser={true} userId={userId} profile={profile} productJobOpen={true} />
        )
    }


}
export default ProductService;