import axios from 'axios';
import API from '../Api';

const accessToken = localStorage.getItem('accessToken');

export function getAllCoupon(params) {
    return axios.get(API.GET_COUPONS,params)
}

export function generateCouponService(params) {
    return axios({
        method: 'post',
        url: API.GENERATE_COUPON,
        data: params,
        headers: {
            accessToken: accessToken,
            Authorization: API.AUTH,
        }
    })
}

export function sendCouponMail(params) {
    return axios({
        method: 'post',
        url: API.SEND_COUPON,
        data: params,
        headers: {
            accessToken: accessToken,
            Authorization: API.AUTH,
        }
    })
}


export function getAllCouponCount(params) {
    return axios.get(API.GET_TOTAL_COUPONS,params)
}


export function deleteCoupon(params) {
    return axios({
        method: 'post',
        url: API.DELETE_COUPON,
        data: params,
        headers: {
            accessToken: accessToken,
            Authorization: API.AUTH,
        }
    })
}

export const CouponService = {
    getAllCoupon,
    generateCouponService,
    getAllCouponCount,
    sendCouponMail,
    deleteCoupon
};