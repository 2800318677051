import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getAllEquipments, addGymEquipments } from '../../config/services/commercialService';
import { Button, Modal, FormControl, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';

const AddEquipmentToGymModal = (props) => {
    const { showModal, hideModal, gymId, fetchGymEquipments, fetchGymDetails } = props;
    const [equipmentsList, setEquipmentsList] = useState("");
    const [eqpsToAdd, setEqpsToAdd] = useState([{
        gymId: gymId,
        // serialNo: "",
        // purchaseDate: "",
        eqp: ""
    }]);

    useEffect(() => {
        fetchEquipmentsList();
    }, []);

    const fetchEquipmentsList = () => {
        const params = { pageNo: 1, pageSize: 1000 };
        getAllEquipments({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    const list = data.result && data.result.data ? data.result.data.map((eqp) => ({label: eqp.model, value: eqp._id})) : [];
                    setEquipmentsList(list);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handleValidation = () => {
        let flag = true;
        eqpsToAdd.forEach((gymEqp, index) => {
            if(!gymEqp.eqp) {
                toast.error(`Please select Equipment Model for Gym Equipment number ${index + 1}`);
                flag = false;
            };
        });

        return flag;
    };

    const formSubmission = (event) => {
        event.preventDefault();

        const validationFlag = handleValidation();
        if(validationFlag) {
            const finalData = eqpsToAdd.map((gymEqp) => {
                return {
                    gymId: gymEqp.gymId,
                    serialNo: gymEqp.serialNo,
                    purchaseDate: gymEqp.purchaseDate ? moment(gymEqp.purchaseDate).format('MM-DD-YYYY') : "",
                    equipmentId: gymEqp.eqp.value
                };
            });

            const params = { gymEquipmentArray: JSON.stringify(finalData) };
            addGymEquipments(params)
                .then(res => {
                    const response = res.data;

                    if (response.statusCode == 1) {
                        const data = response.responseData;
                        fetchGymEquipments();
                        fetchGymDetails();
                        toast.success(data.message);
                        hideModal();
                    } else if (response.statusCode == 0) {
                        toast.error(response.error.errorMessage)
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                })
        }
    };

    const addRowFn = () => {
        const gymEqpData = {
            gymId: gymId,
            // serialNo: "",
            // purchaseDate: "",
            eqp: ""
        };

        setEqpsToAdd([...eqpsToAdd, gymEqpData]);
    };

    const deleteRowFn = (index) => {
        const data = [...eqpsToAdd];
        data.splice(index, 1);
        setEqpsToAdd(data);
    };

    const handleChange = (index, name, value)=>{
        const data = [...eqpsToAdd];
        data[index][name] = value;
        setEqpsToAdd(data);
    };

    return (
        <div className="main-wrapper">
            <Modal show={showModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Equipment to Gym</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <Button type="primary" onClick={addRowFn}>+ Equipment Row</Button>
                    <Form onSubmit={(e) => formSubmission(e)}>
                        <div className="table-responsive mb-4" style={{ minHeight: "200px", marginTop: "30px" }}>
                            <table className="table table-bordered table-striped text-center" style={{ marginBottom: eqpsToAdd.length === 1 ? "" : "100px" }}>
                                <thead>
                                    <tr>
                                        <th scope="col">Equipment Model</th>
                                        <th scope="col">Serial Number</th>
                                        <th scope="col">Purchase Date</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {eqpsToAdd.map((gymEqp, index) => {
                                        const { serialNo, purchaseDate, eqp } = gymEqp;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Select className="gymEquipmentSelect"
                                                        onChange={(e) => handleChange(index, "eqp", e)}
                                                        options={equipmentsList}
                                                        value={eqp ? eqp : ""}
                                                        placeholder="Select Equipment Model"
                                                    />
                                                </td>

                                                <td>
                                                    <FormControl
                                                        value={serialNo}
                                                        name="serialNumber"
                                                        onChange={(e) => handleChange(index, "serialNo", e.target.value)}
                                                        type="text"
                                                        placeholder="Enter Serial Number"
                                                    />
                                                </td>

                                                <td>
                                                    <FormControl
                                                        value={purchaseDate}
                                                        name="purchaseDate"
                                                        onChange={(e) => handleChange(index, "purchaseDate", e.target.value)}
                                                        type="date"
                                                    />
                                                </td>

                                                <Button className='m-1' variant="outline-danger" onClick={() => deleteRowFn(index)}> <i className="fa fa-trash" /> </Button>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <Row className="justify-content-center" style={{ marginTop: "30px" }}>
                            <Button variant="danger" onClick={hideModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit">Submit</Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddEquipmentToGymModal;