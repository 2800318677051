import axios from 'axios';
import API from '../Api';



export function addVendors (params) {
    return axios.post(API.ADD_VENDOR, params)
}

export function getAllVendors(params) {
    return axios.get(API.GET_ALL_VENDORS, params)
}

export function removeVendor(params) {
    return axios.post(API.REMOVE_VENDOR, params)
}

export function updateVendor(params) {
    return axios.post(API.UPDATE_VENDOR, params)
}

export function getAllEngineers(params) {
    return axios.get(API.GET_ALL_ASSOCIATE_ENGINEER, params)
}

export function generateServiceableVendorUploadTemplate({ pincodes }) {
    return axios.post(API.GENERATE_SERVICEABLE_VENDOR_TEMPLATE, { pincodes } )
}

export function upsertServiceableVendorsEntries(params) {
    return axios.post(API.UPLOAD_SERVICEABLE_VENDOR, params )
}

export function getExistingUserEntries({ vendorId, pincodes }) {
    return axios.post(API.GET_SERVICEABLE_VENDOR_ENTRIES, { vendorId, pincodes } )
}


export const VendorService = {
    addVendors,
    getAllVendors,
    removeVendor,
    updateVendor,
    getAllEngineers
    
};