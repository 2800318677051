import React from 'react';
import { Badge } from 'antd';
import ExercisePlaceholder from '../../assets/images/ExercisePlaceholder.png';
const daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const FitnessPlanCard = ({item}) => (
    <div className="col-xl-3 col-lg-4 col-md-6" key={item._id}>
        <Badge.Ribbon color={"#0d5a6c"} text={""} text={item && item.exerciseCategory && item.exerciseCategory.categoryTitle}>
            <div className="fitness-plan-card">
                <div className="fitness-image-wrapper">
                    <img src={item.exerciseImage ? item.exerciseImage : ExercisePlaceholder} alt="" />
                    <h3 className="excercise-name">{item.exerciseTitle}</h3>
                    <div className="video-wrapper" title="View video">
                        <a href={item.exerciseLink} className="d-flex align-items-center" target="_blank">
                            <i className="fa fa-lg fa-youtube-play" />
                        </a>
                    </div>
                </div>
                <div className="fitness-card-content">
                    <div className="steps-detail">
                        <p className="aminities">
                            <span className="label">Repetitions : </span>
                            <span className="value">{item.repetitions}</span>
                        </p>
                        <p className="aminities">
                            <span className="label">Sets : </span>
                            <span className="value">{item.sets ? item.sets : "-"}</span>
                        </p>
                        <p className="aminities">
                            <span className="label">Time : </span>
                            <span className="value">{item.time ? item.time : '-'} minutes</span>
                        </p>
                    </div>
                </div>
                <div className="fitness-card-description">
                    <h3 className="description-heading">Description</h3>
                    <div className="description-content">
                        <p className="desc-content">{item.description}</p>
                        {
                            item.days ?
                                <div className="days-list">
                                    <h6 className="days-heading">Days : </h6>
                                    <ul className="list-unstyled days-listing">
                                        {item.days.map((day, i) =>
                                            <li className="day" key={i}>{daysName[day - 1]} </li>
                                        )}
                                    </ul>
                                </div>
                                :
                                <div className="days-list">
                                    <h6 className="days-heading">Days : </h6>
                                    <ul className="list-unstyled days-listing">
                                        {daysName.map((day, i) =>
                                            <li className="day" key={i}>{day}</li>
                                        )}
                                    </ul>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </Badge.Ribbon>
    </div>
)

export default FitnessPlanCard