import Smile from './../../assets/images/smile.svg'

export const Data = [
    {
        time:"09:03 am",
        fromName: "VedantSharma",
        emojiId: Smile,
        toName: "to all"
    },
    {
        time:"09:03 am",
        fromName: "VedantSharma",
        emojiId: Smile,
        toName: "to all"
    },
    {
        time:"09:03 am",
        fromName: "VedantSharma",
        emojiId: Smile,
        toName: "to all"
    },
]