import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoaderSvg } from '../Reusable';
import { getAllPincodes, removePincode } from '../../config/services/servicePincodes';
import useStateWithCallback from '../../middleware/useState';
import Pagination from 'react-js-pagination';
import AddPincode from './addPincode';
import EditPincode from './editPincode';
import PopUpModal from '../../components/common/PopUpModal';
import SearchSpinner from '../common/SearchSpinner';

export const Pincode = () => {

  const initialState = {
    loading: true,
    pincodes: [],
    countPerPage: 10,
    sortType: -1,
    sortField: 'created',
    totalPincodeCount: 0,
    pageRangeDisplayed: 3,
    bulkUpload: false,
    error: '',
    apiCalled: false
  }

  let tableHeaders = ['Sr. No', 'Pincode', 'City', 'State', 'Tat', 'Local', 'Action'];

  const [state, setState] = useState({ ...initialState });
  const [pageNo, setPageNo] = useState(1)
  const [pincodeModal, setPincodeModal] = useState(false)
  const [editPincode, setEditPincode] = useState(false);
  const [search, setSearch] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [error, setError] = useState({});
  // passing the call back is optional
  // with useStateWithCallback as hooks useState does not take extraparam 
  // can use useStateWithCallback if the value is not updating at the first event
  const [selectedPincode, setSelectedPincode] = useStateWithCallback('');

  const getPincode = () => {
    let params = {
      params: {
        count: state.countPerPage,
        pageNo: pageNo - 1,
        sortType: state.sortType,
        sortField: state.sortField
      }
    }

    if(search && Number.isNaN(Number(search)) && (search.length > 0)) {
      params.params.search = search;
    } else {
      params.params.pincodeSearch = search;
    }    

    getAllPincodes(params).then(response => {
      let data;
      if (response.data && response.data.statusCode === 1) {
        data = response.data.responseData
        setState({
          ...state,
          pincodes: data[0].result,
          loading: false,
          totalPincodeCount: data[1],
          apiCalled: false
        })
        setSearchLoader(false)
      } else {
        setState({...state, apiCalled: false})
      }
    })
  }

  const removePin = (param) => {
    let params = {
      pincodeId: param._id,
      pincode: param.pincode
    }
    removePincode(params).then(response => {
      let data = response.data
      if (data && data.statusCode === 1) {
        setdeletePopup(false)
        getPincode();
      } else {
        setState({ ...state, error: 'Pincode Not Removed !' })
      }
    })
  }

  const handlePageChange = (pageNum) => {
    setState({ ...state, loading: true })
    setPageNo(pageNum)
    // getPincode();
  }
  const hideDelete = () => setdeletePopup(false);
  const showDelete = (pincode) => {
    setSelectedPincode(pincode);
    setdeletePopup(true)
  }

  const hide = () => setPincodeModal(false);
  const show = (key) => {
    if (key === 'single') {
      setState({ ...state, bulkUpload: false });
      setPincodeModal(true);
    } else {
      setState({ ...state, bulkUpload: true });
      setPincodeModal(true);
    }
  }

  const hideEdit = () => setEditPincode(false);

  const showEdit = (pincode) => {
    setSelectedPincode(pincode);
    setEditPincode(true);
  }
  const handleSearch = (e) => {
    setState({
      ...state,
      apiCalled: true
    })
    setSearch(e.target.value);
    setSearchLoader(true);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getPincode();
    }, 1000);
    return () => clearTimeout(timer);

  }, [pageNo, search])

  return (
    <div className='body-container-wrapper'>
      {/* {state.apiCalled && <Loader />} */}
      <div className='body-container'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
          <li className='breadcrumb-item'>Pincode</li>
        </ol>
        {state && state.loading && <div className="loader_wrapper"><LoaderSvg /></div>}
        <div className='users_header'>
          <div className='container-fluid'>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <h4 className="cm_page_heading margin-bottom-10px">Pincode</h4>
              </div>

              <div className="col-md-3 col-sm-12 text-right cm_search mr-2 position-relative">
              {searchLoader ?
                  <SearchSpinner type="text-info" />
                  : null}
                <input
                  type="text"
                  className="form-control m-b-md-15 margin-bottom-10px"
                  placeholder="Search by State, City, Pincode"
                  onChange={handleSearch}
                  value={search}
                />
                <i className="fa fa-search" />
              </div>
              <div className='row col-md-3 col-sm-12'>
                <button
                  onClick={() => show('single')}
                  className="btn btn-primary btn-sm btn_sm margin-bottom-10px"
                >
                  Add Pincode
                    </button>

                <button
                  onClick={() => show('bulk')}
                  className="btn btn-primary btn-sm btn_sm margin-bottom-10px"
                  style={{ marginLeft: '.2rem' }}
                >
                  Add Multiple Pincodes
                    </button>
              </div>
              {/* <div className='row col-md-2 col-sm-12'>

              </div> */}
            </div>
            <div className='table-responsive overflow-hidden'>
              <table className='table table-bordered table-striped text-center'>
                <thead>
                  <tr>{tableHeaders.map((item, index) => (<th key={index} scope="col">{item}</th>))}</tr>
                </thead>
                <tbody>
                  {
                    state.pincodes && state.pincodes.length > 0 ?
                      state.pincodes.map((pincode, index) => {
                        return (
                          <tr key={index}>
                            <td> {state.countPerPage * (pageNo - 1) + (index + 1)}</td>
                            <td>{pincode.pincode}</td>
                            <td>{pincode.city}</td>
                            <td>{pincode.state}</td>
                            <td>{pincode.tat}</td>
                            <td>{pincode.upCountryOrLocal === 0 ? 'Up Country' : 'Local'}</td>
                            <td>
                              <button
                                className="btn btn-dark btn-sm"
                                onClick={(e) => showEdit(pincode)}
                              >
                                Edit
                                    </button>
                                      &nbsp;
                                    <button
                                className="btn btn-danger btn-sm"
                                onClick={(e) => showDelete(pincode)}
                              >
                                Delete
                                      </button>
                            </td>
                          </tr>
                        )
                      })
                      : null
                  }
                </tbody>
              </table>
              <Pagination
                // pagination still under dev
                activePage={pageNo}
                itemsCountPerPage={state.countsPerPage}
                totalItemsCount={state.totalPincodeCount}
                pageRangeDisplayed={state.pageRangeDisplayed}
                onChange={handlePageChange}
              />
              {pincodeModal &&
                <AddPincode
                  pincodeModal={pincodeModal}
                  hide={hide}
                  getPincode={getPincode}
                  bulkUpload={state.bulkUpload}
                />}
              {editPincode &&
                <EditPincode
                  hide={hideEdit}
                  editPincode={editPincode}
                  selectedPincode={selectedPincode}
                  getPincode={getPincode}
                />}
              <PopUpModal isOpen={deletePopup} closeModal={hideDelete} submit={(e) => removePin(selectedPincode)} message={`Are you sure to delete this pincode?`} validationError={""} submitTitle="Delete" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}