import React, {useState, useEffect} from 'react';
import {deleteMachine, getAllMachines,readVendorTrainingDataFromExcel} from '../../config/services/commercialService';
import {toast} from 'react-toastify';
import {Tag} from "antd";
import {Alert, Button, FormControl, Modal, Row, Spinner} from 'react-bootstrap';
import Pagination from "react-js-pagination";
import HOC from "../../HOC";
import {Link} from "react-router-dom";
import Dropzone from "react-dropzone";
import Popup from "reactjs-popup";
import _ from "lodash";

const itemsCountPerPage = 10;
const columnDetails = [
    {fieldName: "Sr.No."},
    {fieldName: "Model"},
    {fieldName: "Category"},
    {fieldName: "InstallationVideoUrl"},
    {fieldName: "RepairVideoUrl"},
    {fieldName: "UnInstallationVideoUrl"},
    {fieldName: "PDF Url"},
    {fieldName: "Machine Image Url"},
    {fieldName: "Action"},
];


const VendorTraining = () => {
    const [sparePartsList, setSparePartsList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalSpareParts, setTotalSpareParts] = useState(0);
    const [importExcelPopup, setImportExcelPopup] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [validationSuccess, setValidationSuccess] = useState('');
    const [badEntryErrors, setBadEntryErrors] = useState(['']);
    const [requestsExcelSheet, setRequestsExcelSheet] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [machineId, setMachineId] = useState('');
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        fetchAllMachines(pageNumber);
    }, []);

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const handleSearchByEnter = (e) => {
        if (e.target.value.length > 0) {
            if (e.key === 'Enter') {
                fetchAllMachines(pageNumber);
            }
        }
    }

    const handleSearchData = () => {
        if(_.isNil(searchValue)){
            toast.error('Please enter search value');
        }else {
            fetchAllMachines(pageNumber);
        }
    }

    const resetFilter = () => {
        setSearchValue('')
        fetchAllMachines(pageNumber);
    }


    const fetchAllMachines = (pageNo) => {
        const params = {pageNo: pageNo, pageSize: itemsCountPerPage,searchValue};
        getAllMachines({params})
            .then(res => {
                const response = res.data;
                if (response.statusCode === 1) {
                    const dataObj = response.responseData;
                    dataObj.result && setSparePartsList(dataObj.result);
                    dataObj.result && dataObj.result.pagination && setTotalSpareParts(dataObj.result.pagination.totalRecords);
                } else if (response.statusCode === 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };


    const deleteMachineApiCall = ()=>{
        console.log("machine id -- >> ",machineId)
        const param = {machineId}

        console.log("param id -- >> ",param)
        deleteMachine(param).then((result)=>{
            const response = result.data;
            if (response.statusCode === 1) {
                const dataObj = response.responseData;
                // dataObj.result && setSparePartsList(dataObj.result);
                // dataObj.result && dataObj.result.pagination && setTotalSpareParts(dataObj.result.pagination.totalRecords);
                closeDeleteModal()
                fetchAllMachines(pageNumber)
                toast.error(dataObj.message)
            } else if (response.statusCode === 0) {
                toast.error(response.error.errorMessage)
            }
        }).catch((error)=>{
            console.log('error', error);
        });

    }

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
        fetchAllMachines(pageNo);
    };

    const openDeleteModal = (machineId) => {
        console.log("openDeleteModal -machineId-- > ",machineId)
        setShowDeletePopup(true);
        setMachineId(machineId)
    };

    const closeDeleteModal = () => {
        setShowDeletePopup(false);
        setMachineId('')
    }


    const importHandler = () => {
        setImportExcelPopup(true)
        setValidationSuccess('')
        setValidationError('')
    }

    const closeModal = () => {
        setBadEntryErrors([])
        setImportExcelPopup(false)
        setIsUploading(false)
        setValidationSuccess('')
        setValidationError('')
    }

    const openLinks = (url)=>{
        window.open(url, '_blank');
    }

    const handleFileDrop = (accepted, rejected) => {
        if (accepted && accepted.length) {
            setRequestsExcelSheet(accepted[0]);
            setValidationError('');
            setValidationSuccess('');
        }
        else if (rejected && rejected.length) {
            setRequestsExcelSheet('');
            setValidationError("This file format is not supported. Format should be 'xls, xlsx or csv'")
        }
    }

    const  submitValidateExcelUpload = (e) => {
        e.preventDefault();
        if (!requestsExcelSheet) {
            setValidationError('Please select a file!');
            return;
        }
        else {
            let params = new FormData()
            params.append('requestsExcelSheet', requestsExcelSheet);
            setValidationError('')
            setIsUploading(true)
            readVendorTrainingDataFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode === 1) {
                        setValidationSuccess('file imported successfully')
                        setImportExcelPopup(false)
                        fetchAllMachines(pageNumber);
                    } else if (response.statusCode === 0) {
                        setValidationSuccess(response.error.errorMessage)
                    }
                    setIsUploading(false)
                })
        }
    }


    return (
        <HOC>
            <div className="body-container-wrapper">
                <div className={"body-container"}>
                    <ol className="breadcrumb mt-1" style={{display: ""}}>
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item">Vendor Training</li>
                    </ol>

                    <div className="users_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="cm_page_heading mt-1 mb-0">Vendor Training</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-md-6 col-sm-6">
                                <li className="list-inline-item mt-2">
                                    {/*<label inline className="mr-2">Search:</label>*/}
                                    <FormControl id="search"
                                                 className="h-auto" name='search'
                                                 placeholder="Search by Model"
                                                 value={searchValue}
                                                 onChange={handleSearch}
                                                 onKeyPress={handleSearchByEnter}/>
                                </li>

                                <li className="list-inline-item align-self-end mt-sm-3 mt-lg-3 mt-md-3">
                                    <Button variant="primary"
                                            className="btn-reset"
                                            style={{marginBottom: '2px'}}
                                            onClick={handleSearchData}
                                            size='sm'> <i className="fa fa-search"/></Button>
                                </li>

                                <li className="list-inline-item align-self-end">
                                    <Button variant="outline-dark"
                                            className="btn-reset"
                                            style={{marginBottom: '2px'}}
                                            size='sm' onClick={resetFilter}> <i className="fa fa-refresh"/></Button>
                                </li>
                            </div>

                            <div className="col-md-6 col-sm-6 list-inline-item text-right">
                                <li className="list-inline-item align-self-end mt-sm-3 mt-lg-3 mt-md-3 align-end">
                                    <Button variant="primary"
                                            className="btn-primary"
                                            onClick={importHandler}
                                            style={{marginBottom: '2px', minHeight: 40}}
                                            size='sm'> Import</Button>
                                </li>
                            </div>
                        </div>

                    </div>


                    <div className="table-responsive mt-3">
                        <table className="table table-bordered table-striped text-center">
                            <thead>
                            <tr>
                                {columnDetails.map((item, i) =>
                                    <th scope="col" key={i}>{item.fieldName}</th>
                                )}
                            </tr>
                            </thead>

                            <tbody>
                            {sparePartsList.map((spareParts, index) => {

                                return (
                                    <tr key={spareParts._id}>
                                        <td className="cm_no_wrap">{index + 1}</td>
                                        <td className="cm_no_wrap">{spareParts.model}</td>
                                        <td className="cm_no_wrap">{spareParts.category}</td>
                                        <td className="cm_no_wrap">{<Tag color="geekblue" onClick={() => openLinks(spareParts.installationVideoLink)} >{spareParts.installationVideoLink}</Tag>}</td>
                                        <td className="text-left">
                                            <div className="wrapper-overflow">
                                                {/*{spareParts.spares && spareParts.spares.map((spare, i) => (*/}
                                                {/*    <span key={i}>*/}
                                                {/*    <Tag color="geekblue">{"https://www.youtube.com/watch?v=-8OEfGQPJ8c"}</Tag>*/}
                                                {/*</span>*/}
                                                {/*))}*/}

                                                <Tag color="geekblue" onClick={() => openLinks(spareParts.repairVideoLink)}>{spareParts.repairVideoLink}</Tag>
                                            </div>
                                        </td>

                                        <td className="text-left">
                                            <div className="wrapper-overflow">
                                                {/*{spareParts.spares && spareParts.spares.map((spare, i) => (*/}
                                                {/*    <span key={i}>*/}
                                                {/*    <Tag color="geekblue">{"https://www.youtube.com/watch?v=-8OEfGQPJ8c"}</Tag>*/}
                                                {/*</span>*/}
                                                {/*))}*/}

                                                <Tag color="geekblue" onClick={() => openLinks(spareParts.unInstallationVideoLink)} >{spareParts.unInstallationVideoLink}</Tag>
                                            </div>
                                        </td>

                                        <td className="cm_no_wrap">
                                            <Tag color="geekblue" onClick={() => openLinks(spareParts.pdfLink)} >{spareParts.pdfLink}</Tag>
                                        </td>

                                        <td className="cm_no_wrap">
                                            <Tag color="geekblue" onClick={() => openLinks(spareParts.imageUrl)} >{spareParts.imageUrl}</Tag>
                                        </td>

                                        <td className="cm_no_wrap">

                                            <Button className='m-1' variant="outline-danger"
                                                    onClick={() => openDeleteModal(spareParts._id)}> <i
                                                className="fa fa fa-trash"/>
                                            </Button>


                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>

                        <Pagination
                            activePage={pageNumber}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={totalSpareParts}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange}
                        />
                    </div>

                </div>

            </div>

            <Modal show={importExcelPopup} onHide={closeModal} backdrop="static" centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h6>Upload File!</h6> </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
                        {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                            <section>
                                <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                    <i className="fa fa-cloud-upload" />
                                    {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Modal.Body>

                {validationSuccess && <Alert variant="success"> {validationSuccess} </Alert>}
                {validationError && <Alert variant="danger"> {validationError} </Alert>}
                {badEntryErrors.length > 0 && <Alert variant="danger">
                    {badEntryErrors.map(err => {
                        return (
                            <>
                                <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                            </>
                        )
                    })}
                </Alert>}


                <Modal.Footer>
                    <Button variant="danger" onClick={closeModal} size="sm">Cancel</Button>
                    <Button variant="success" onClick={submitValidateExcelUpload} disabled={validationError || isUploading} size="sm">
                        {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Popup
                open={showDeletePopup}
                closeOnDocumentClick={false}
                onClose={closeDeleteModal}

            >
                <div className="cm_modal">
                    <span className="cm_modal_close" onClick={closeModal}>&times;</span>
                    <h5 className="text-center mb-4">Are you sure, you want to delete this Machine?</h5>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-danger btn-sm btn-block" onClick={deleteMachineApiCall}>Delete</button>
                        </div>

                        <div className="col">
                            <button className="btn btn-warning btn-sm btn-block" onClick={closeDeleteModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Popup>

        </HOC>

    );
};


export default VendorTraining;