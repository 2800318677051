import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import Loader from '../loader';
import FileSaver from 'file-saver';
import Select from 'react-select';
import moment from 'moment';
import { LoaderSvg } from '../Reusable';
import { bulkPincodeImport, exportPincodeList, getAllStates, getAllCitys, getWarehouse } from '../../config/services/servicePincodes';

let allowedFileTypes = [
  'application/vnd.ms-excel',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const ImportExport = (props) => {
  let fileObj = { uploadFile: '' };
  const [importFile, setImportFile] = useState(fileObj);
  const [importPopup, setImportPopup] = useState(false);
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loader, setLoader] = useState(false);
  const [allstates, setAllState] = useState([]);
  const [citys, setCities] = useState([]);
  const [wareHouseList, setWarehouseList] = useState([]);

  //add Filter for state,city,warehouse,search etc
  let paramObj = {
    search: '',
    state: '',
    city: '',
    warehouse1: '',
    warehouse2: ''
  };
  const [filterParam, setFilterParam] = useState(paramObj);

  const handelParam = (name, value) => {
    setFilterParam((prevParam) => ({
      ...prevParam,
      [name]: value
    }));
  }

  //Get State 

  const getStates = () => {

    let params = { search: '', sortField: "_id", sortType: 1, pageNo: 0, count: 1000 }
    getAllStates({ params })
      .then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          let data = res.data.responseData;

          if (data.result && data.result.length > 0) {
            let stateLists = [];
             //Set option for empty state
             stateLists.push({
              label: "Select State",
              value: ""
            })

            data.result.forEach(key => {
              //not set empty value in state
              if(key._id){
                stateLists.push({
                  label: key._id,
                  value: key._id,
                })
              }
            })

            setAllState(stateLists);
          }
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })

  }

  // set filter mutiple filter for export
  const handleSteteChange = (value) => {
    setFilterParam((prevParam) => ({
      ...prevParam,
      state: value,
      city: ''
    }));
      if(!value){
        //set city null if state empty
        setCities([]);
      }else{
        getCities(value);
      }
  }


  const getCities = (val) => {

    setLoader(true);
    setCities([]);

    let params = { sortField: "_id", sortType: 1, pageNo: 0, count: 1000 };

    params.states = val;
    params.citySearch = '';

    getAllCitys({ params })
      .then(res => {
        if (res && res.data.statusCode === 1) {
          let data = res.data.responseData
          let citys = [];

          citys.push({
            label: "All",
            value: ""
          })

          if (data.result && data.result.length > 0) {
            data.result.forEach(state => {
              citys.push({
                label: state._id,
                value: state._id
              })
            })
          }

          setCities(citys);
          setLoader(false);

        } else {
          console.log(res.data.error.errorMessage);
          setLoader(false);
        }
      })
  }

  //get all warehouse list for filter 
  const getWarehouseList = () => {
    getWarehouse()
      .then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          let data = res.data.responseData;

          if (data.result && data.result.length > 0) {
            let allWarehouse = [];
            allWarehouse.push({
              label: "All",
              value: ""
            })

            data.result.forEach(key => {
              allWarehouse.push({
                label: key.name,
                value: key._id,
              })
            })

            setWarehouseList(allWarehouse);
          }
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })
  }

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    getWarehouseList();
  }, []);


  const openImportPopup = () => {
    setErrorMsg('');
    setImportPopup(true)
  };

  const handleFileDrop = (file) => {
    setErrorMsg("");
    setImportFile(fileObj);

    if (file && file[0] && allowedFileTypes.includes(file[0].type)) {
      setImportFile({ uploadFile: file[0] });
    } else {
      setErrorMsg("Choose a valid file!");
    }
  }
  // SUBMIT  FILE UPLOAD
  const submitMultipleUpload = (e) => {
    try {
      e.preventDefault();
      setUploadingLoader(true);
      setErrorMsg("");

      if (importFile.uploadFile && allowedFileTypes.includes(importFile.uploadFile.type)) {
        let params = new FormData()
        params.append('uploadPincode', importFile.uploadFile)
        bulkPincodeImport(params)
          .then(res => {
            let response = res.data;
            if (response.statusCode === 1) {
              toast.success(response.responseData.message)
              setImportPopup(false)
            } else {
              setErrorMsg(response.error.errorMessage);
            }
          })

      } else {
        setErrorMsg("Choose a valid file!");
      }

      setUploadingLoader(false);

    } catch (e) {
      console.error(e);
      setUploadingLoader(false);
    }

  }

  //Export file according to filter 
  const exportFile = async () => {
    try {
      setLoader(true);

      if (!filterParam.state) {
        toast.error('State is required.')
      } else {
        /*  let _params = { state: filterParam.state, city: filterParam.city } */
        let res = await exportPincodeList(filterParam)
        var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, `${moment() + '-Deliverable-Pincodes'}.xlsx`);
      }

      setLoader(false);
    } catch (error) {
      console.log("error", error);
      setLoader(false)
    }
  }

  return (
    <div className='body-container-wrapper'>
      {/* {/ {state.apiCalled && <Loader />} /} */}
      <div className='body-container'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
          <li className='breadcrumb-item'><Link to='/deliverablePincode'>Deliverable Pincode</Link></li>
          <li className='breadcrumb-item'>Import / Export </li>
        </ol>
        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
        <div className='users_header'>


          <div className='container-fluid'>
            <div className="row align-items-center">

              <ul className="col-md-12">

                <li className="list-inline-item mt-2">
                  <Select
                    className='cm_min_width'
                    onChange={(e) => handleSteteChange(e.value)}
                    placeholder="Filter By State"
                    options={allstates}
                  />
                </li>

                <li className="list-inline-item mt-2">

                  <Select
                    className='cm_min_width'
                    onChange={(e) => handelParam('city', e.value)}
                    placeholder='Filter By City'
                    options={citys}
                    value={filterParam.city ? citys.find(element => element.value === filterParam.city) : null}
                  />

                </li>
                <li className="list-inline-item mt-2">

                  <Select
                    className='cm_min_width'
                    onChange={(e) => handelParam('warehouse1', e.value)}
                    placeholder='Filter By Wh (L1)'
                    options={wareHouseList}
                    value={filterParam.warehouse1 ? wareHouseList.find(element => element.value === filterParam.warehouse1) : ""}
                  />

                </li>

                <li className="list-inline-item mt-2">

                  <Select
                    className='cm_min_width'
                    onChange={(e) => handelParam('warehouse2', e.value)}
                    placeholder='Filter By Wh (L2)'
                    options={wareHouseList}
                    value={filterParam.warehouse2 ? wareHouseList.find(element => element.value === filterParam.warehouse2) : ""}
                  />

                </li>

                <li className="list-inline-item mt-6">

                  <input
                    type="text"
                    className="form-control m-b-md-15"
                    placeholder="Search by Pincode"
                    onChange={(e) => handelParam('search', e.target.value)}
                    value={filterParam.search}
                  />
                </li>




              </ul>
            </div>
          </div>


          <div className='container-fluid'>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <h4 className="cm_page_heading">Pincode</h4>
              </div>
              <div className='row col-md-12 text-right col-sm-12'>

                <button type="primary" onClick={openImportPopup} className="ml-1 mt-2 btn btn-primary">Import</button>
                <button type="primary" className="ml-1 mt-2 btn btn-primary" onClick={() => { exportFile() }}>Export</button>
              </div>
            </div>
          </div>

          <Popup open={importPopup} closeOnDocumentClick={false} onClose={() => { setImportPopup(false) }}>
            <div className="cm_modal pl-5 pr-5">
              <span className="cm_modal_close" onClick={() => { setImportPopup(false) }}>&times; </span>
              <h3 className="text-center mb-4">Upload an excel file.</h3>

              <div className="btn_group">
                <div className="row">
                  <div className="col">
                    {errorMsg &&
                      <div
                        className="text-danger text-center h3"
                      >
                        {errorMsg}
                      </div>
                    }
                    <Dropzone
                      multiple={false}
                      onDrop={acceptedFiles => handleFileDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                        <section>
                          <div {...getRootProps()} className="dropzone text-center">
                            <input {...getInputProps()} />
                            <i className="fa fa-cloud-upload" />
                            {isDragActive ?
                              <p>Drop it like it's hot!</p>
                              :
                              acceptedFiles.length ?
                                <p>{acceptedFiles[0].name}</p>
                                :
                                <p>Drag file or click to upload</p>}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <button
                      className="btn btn-warning btn-sm btn-block"
                      disabled={uploadingLoader}
                      onClick={submitMultipleUpload}
                    >
                      {uploadingLoader ? 'Uploading.......' : 'Upload'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default ImportExport;