import axios from 'axios';
import API from '../Api';

export function getLeavesList(params) {
    return axios.get(API.GET_LEAVES_LIST, params)
}

export function changeLeaveStatus(params) {
    return axios.post(API.CHANGE_LEAVE_STATUS, params)
}
