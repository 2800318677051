import React, { Component } from "react";
import HOC from "../../HOC";
import {
  ProductsService,
  addKeyFeature,
  removeKeyFeatures
} from "../../config/services/ProductsService";
import { LoaderSvg } from "../Reusable";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { productCategories, tyreSizes, frameMaterials, gearTypes } from "./ProductCategories";
import { productSpecifications } from "./ProductSpecifications";
import ReactQuill from 'react-quill';

class EditProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productName: "",
      productCode: "",
      productLink: "",
      productASIN: "",
      productDescription: "",
      productPrice: "",
      productImage: "",
      productImagePreview: "",
      updateError: "",
      Loader: false,
      productUpdateLoader: false,
      productWebsite: 0,
      productCategory: "",
      selectedCategory: "",
      selectedSubCategory: "",
      gender: null,
      subcategory: "",
      productType: "",
      specifications: [{ label: "", value: "" }],
      brandName: "",
      productPriceDiscount: 0,
      multipleProductImages: [],
      multipleProductImagesPreview: [],
      onefitplusChoiceImage: "",
      onefitplusChoiceImagePreview: "",
      skuIdforGuranteedPlan: "",
      skuIdforDirectPlan: "",
      planDetailsforGuranteedPlan: [],
      planDetailsforDirectPlan: [],
      planIdforGuranteedPlan: "",
      planIdforDirectPlan: "",
      planIdforDiscountGuranteedPlan: "",
      planIdforDiscountDirectPlan: "",
      addKeyFeaturePopup: false,
      featureTitle: "",
      featureDescription: "",
      featureImage: "",
      featureImagePreview: "",
      keyFeatures: [],
      productMRP: "",
      productReview: "",
      productRating: "",
      productDisplayOrder: "",
      isFitwarzCompatible: false,
      isUrbanCompatible: false,
      isLiveSessionCompatible: false,
      color_code: '#000000',
      color_name: '',
      tyre_size: {},
      frame_material: {},
      gear_type: {},
      metaTitle: '',
      metaDescription: '',
      isFetching: false,
      productId: '',
      productService:''

    };
    this.handleChange = this.handleChange.bind(this);
    this.editProduct = this.editProduct.bind(this);
  }

  productDetails() {
    let params = `?productId=${this.props.match.params.id}`;
    ProductsService.getProduct(params).then((res) => {
      if (res.data.statusCode === 1) {
        let {
          productName,
          productCode,
          productASIN,
          productLink,
          productDescription,
          productPrice,
          productImage,
          productCategory,
          gender,
          subcategory,
          productType,
          specifications,
          brandName,
          productPriceDiscount,
          image,
          skuIdforGuranteedPlan,
          skuIdforDirectPlan,
          planDetailsforGuranteedPlan,
          planDetailsforDirectPlan,
          planIdforGuranteedPlan,
          planIdforDirectPlan,
          planIdforDiscountGuranteedPlan,
          planIdforDiscountDirectPlan,
          productWebsite,
          onefitplusChoiceImage,
          keyFeatures,
          productMRP,
          productReview,
          productRating,
          productDisplayOrder,
          isFitwarzCompatible,
          isUrbanCompatible,
          isLiveSessionCompatible,
          color,
          size,
          tyreSize,
          frameMaterial,
          gearType,
          metaTitle,
          metaDescription,
          _id,
          productService
        } = res.data.responseData.product;

        this.setState({
          productName: productName ? productName : "",
          productId: _id,
          productService,
          productCode: productCode ? productCode : "",
          productASIN: productASIN ? productASIN : "",
          productLink: productLink ? productLink : "",
          productDescription: productDescription ? productDescription : "",
          productPrice: productPrice ? productPrice : 0,
          productImagePreview: productImage,
          productCategory: productCategory,
          productType: productType ? productType : "",
          specifications: specifications ? specifications : [],
          brandName: brandName ? brandName : "",
          productPriceDiscount: productPriceDiscount ? productPriceDiscount : 0,
          multipleProductImagesPreview: image,
          skuIdforGuranteedPlan: skuIdforGuranteedPlan
            ? skuIdforGuranteedPlan
            : "",
          skuIdforDirectPlan: skuIdforDirectPlan ? skuIdforDirectPlan : "",
          planDetailsforGuranteedPlan: planDetailsforGuranteedPlan
            ? planDetailsforGuranteedPlan
            : "",
          planDetailsforDirectPlan: planDetailsforDirectPlan
            ? planDetailsforDirectPlan
            : "",
          planIdforGuranteedPlan: planIdforGuranteedPlan
            ? planIdforGuranteedPlan
            : "",
          planIdforDirectPlan: planIdforDirectPlan ? planIdforDirectPlan : "",
          planIdforDiscountGuranteedPlan: planIdforDiscountGuranteedPlan
            ? planIdforDiscountGuranteedPlan
            : "",
          planIdforDiscountDirectPlan: planIdforDiscountDirectPlan
            ? planIdforDiscountDirectPlan
            : "",
          Loader: false,
          productWebsite,
          onefitplusChoiceImagePreview: onefitplusChoiceImage,
          keyFeatures: keyFeatures ? keyFeatures : [],
          productMRP: productMRP ? productMRP : 0,
          productReview: productReview ? productReview : 0,
          productRating: productRating ? productRating : 0,
          productDisplayOrder: productDisplayOrder ? productDisplayOrder : 0,
          isFitwarzCompatible: isFitwarzCompatible
            ? isFitwarzCompatible
            : false,
          isUrbanCompatible: isUrbanCompatible ? isUrbanCompatible : false,
          isLiveSessionCompatible: isLiveSessionCompatible ? isLiveSessionCompatible : false,
          gender,
          color_code: color ? color.code : '#000000',
          color_name: color ? color.name : '',
          size,
          subcategory,
          selectedCategory: productCategories.find(
            (cat) => cat.value === productCategory
          ),
          tyre_size: tyreSizes.find((data) => data.id == tyreSize),
          frame_material: frameMaterials.find((data) => data.id == frameMaterial),
          gear_type: gearTypes.find((data) => data.id == gearType),
          metaTitle,
          metaDescription
        });
      }
    });
  }

  handleChange(e) {
    if (e.target.id == "productImage") {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          productImagePreview: reader.result,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        productImagePreview: file,
      });
      this.setState({
        productImage: e.target.files[0],
      });
    } else if (e.target.id == "onefitplusChoiceImage") {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          onefitplusChoiceImagePreview: reader.result,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        onefitplusChoiceImagePreview: file,
      });

      this.setState({
        onefitplusChoiceImage: e.target.files[0],
      });
    } else if (e.target.id == "featureImage") {
      let file = e.target.files[0];
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({
          featureImagePreview: reader.result,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        featureImagePreview: file,
      });

      this.setState({
        featureImage: e.target.files[0],
      });
    } else if (e.target.type == "checkbox") {
      let name = e.target.name;
      let value = e.target.checked;
      this.setState({ [name]: value });
    } else {
      let { name, value } = e.target;
      this.setState({ [name]: value });
    }
  }

  changeFeatureDescription = (data) => {
    this.setState({ featureDescription: data });
    
  }

  selectProductSubCategory = (subcategory) => {
    this.setState({ subcategory: subcategory.value });
  };

  onHandleChangeNumeric = (e) => {
    let value = e.target.value;
    value = value.replace(/[\D]+/g, "");
    if (!Number(value) && value != "" && value != "0") {
      return;
    }
    this.setState({ [e.target.name]: value });
  };

  onHandleChangeDecimal = (e) => {
    let value = e.target.value;
    value = value.replace(" ", "");
    if (!Number(value) && value != "" && value != "0" && value != "0.") {
      return;
    }
    this.setState({ [e.target.name]: value });
  };

  componentDidMount() {
    this.productDetails();
  }

  handleDescription = (data) => {
    this.setState({ productDescription: data })
  }

  isValid = () => {
    let {
      productName,
      productCode,
      productLink,
      productASIN,
      productWebsite,
      productCategory,
      productImage,
      productDescription,
      productPrice,
      productMRP,
      productType,
      specifications,
      brandName,
      productPriceDiscount,
      multipleProductImages,
      skuIdforGuranteedPlan,
      skuIdforDirectPlan,
      planDetailsforGuranteedPlan,
      planDetailsforDirectPlan,
      planIdforDiscountGuranteedPlan,
      planIdforDiscountDirectPlan,
      gender,
      color_code,
      color_name,
      size,
      tyre_size,
      frame_material,
      gear_type,
      isUrbanCompatible,
      metaTitle,
      metaDescription
    } = this.state;

    if (!productName) {
      this.setState({ validationError: "Product name cannot be blank!" });
      return false;
    } else if (!productCode) {
      this.setState({ validationError: "Product code cannot be blank!" });
      return false;
    } else if (!productLink) {
      this.setState({ validationError: "Product link cannot be blank!" });
      return false;
    }
    else if (!productASIN) {
      this.setState({ validationError: "Product ASIN cannot be blank!" });
      return false;
    }

    else if (!metaTitle) {
      this.setState({ validationError: "Meta Title cannot be blank!" });
      return false;
    }

    else if (!metaDescription) {
      this.setState({ validationError: "Meta Description cannot be blank!" });
      return false;
    }

    // else if (!productWebsite) {
    //     this.setState({ validationError: 'Product Website cannot be blank!' })
    //     return false
    // }
    // else if (!productCategory) {
    //     this.setState({ validationError: 'Product category cannot be blank!' })
    //     return false
    // }
    // else if (!productImage) {
    //     this.setState({ validationError: 'Product image cannot be blank!' })
    //     return false
    // }
    else if (!productDescription) {
      this.setState({
        validationError: "Product description cannot be blank!",
      });
      return false;
    } else if (!productPrice) {
      this.setState({ validationError: "Product price cannot be blank!" });
      return false;
    } else if (productPrice > productMRP) {
      this.setState({ validationError: "Product price cannot be les from product MRP!" });
      return false;
    } else if (!specifications) {
      this.setState({ validationError: "Specifications cannot be blank!" });
      return false;
    } else if (!brandName) {
      this.setState({ validationError: "Brand Name cannot be blank!" });
      return false;
    }
    // else if (!productPriceDiscount) {
    //     this.setState({ validationError: 'Discount cannot be blank!' })
    //     return false
    // }
    else if (!multipleProductImages) {
      this.setState({
        validationError: "Other product images cannot be blank!",
      });
      return false;
    } else if (!skuIdforGuranteedPlan) {
      this.setState({
        validationError: "SkuId for guranteed plan cannot be blank!",
      });
      return false;
    } else if (!skuIdforDirectPlan) {
      this.setState({
        validationError: "SkuId for direct plan cannot be blank!",
      });
      return false;
    } else if (!planDetailsforGuranteedPlan.length) {
      this.setState({
        validationError: "Plan details for guranteed plan cannot be blank!",
      });
      return false;
    } else if (!planDetailsforDirectPlan.length) {
      this.setState({
        validationError: "Plan details for direct plan cannot be blank!",
      });
      return false;
    } else if (!planIdforDiscountGuranteedPlan) {
      this.setState({
        validationError: "PlanId for discount guaranteed plan cannot be blank!",
      });
      return false;
    } else if (!planIdforDiscountDirectPlan) {
      this.setState({
        validationError: "PlanId for discount direct plan cannot be blank!",
      });
      return false;
    }

    else if (isUrbanCompatible) {

      if (!color_code) {
        this.setState({ validationError: "Color Code cannot be blank!" });
        return false;
      }
      else if (!size) {
        this.setState({ validationError: "Product Size cannot be blank!" });
        return false;
      }
      else if (!color_name) {
        this.setState({ validationError: "Color Name cannot be blank!" });
        return false;
      }
      else if (!gender) {
        this.setState({ validationError: "Gender cannot be blank!" });
        return false;
      }
      else if (!tyre_size) {
        this.setState({ validationError: "Tyre Size cannot be blank!" });
        return false;
      }
      else if (!frame_material) {
        this.setState({ validationError: "Frame Material cannot be blank!" });
        return false;
      }
      else if (!gear_type) {
        this.setState({ validationError: "Gear Type cannot be blank!" });
        return false;
      }
    }
    return true
  };

  editProduct(e) {
    e.preventDefault();
    let {
      productName,
      productCode,
      productASIN,
      productLink,
      productDescription,
      productImage,
      productPrice,
      productWebsite,
      productCategory,
      productType,
      specifications,
      brandName,
      productPriceDiscount,
      multipleProductImages,
      skuIdforGuranteedPlan,
      skuIdforDirectPlan,
      planDetailsforGuranteedPlan,
      planDetailsforDirectPlan,
      planIdforGuranteedPlan,
      planIdforDirectPlan,
      planIdforDiscountGuranteedPlan,
      planIdforDiscountDirectPlan,
      onefitplusChoiceImage,
      productMRP,
      productReview,
      productRating,
      productDisplayOrder,
      isFitwarzCompatible,
      isUrbanCompatible,
      isLiveSessionCompatible,
      subcategory,
      gender,
      color_code,
      color_name,
      size,
      tyre_size,
      frame_material,
      gear_type,
      metaTitle,
      metaDescription,
      productService
    } = this.state;

    let obj = new FormData();
    obj.append("productId", this.props.match.params.id);
    obj.append("productName", productName);
    obj.append("productCode", productCode);
    obj.append("productASIN", productASIN);
    obj.append("productLink", productLink);
    obj.append("productDescription", productDescription);
    obj.append("productImage", productImage);
    obj.append("onefitplusChoiceImage", onefitplusChoiceImage);
    obj.append("productPrice", productPrice);
    obj.append("productWebsite", productWebsite);
    obj.append("productCategory", productCategory);
    obj.append("productType", productType);
    obj.append("specifications", JSON.stringify(specifications));
    obj.append("brandName", brandName);
    obj.append("productPriceDiscount", productPriceDiscount);
    obj.append("skuIdforGuranteedPlan", skuIdforGuranteedPlan);
    obj.append("skuIdforDirectPlan", skuIdforDirectPlan);
    obj.append("planIdforGuranteedPlan", planIdforGuranteedPlan);
    obj.append("planIdforDirectPlan", planIdforDirectPlan);
    if (subcategory) {
      obj.append("subcategory", subcategory);
    }
    if (gender) {
      obj.append("gender", gender);
    }
    if (color_code && color_name) {
      obj.append("color", JSON.stringify({ code: color_code, name: color_name }));
    }
    if (size) {
      obj.append("size", size);
    }
    obj.append(
      "planIdforDiscountGuranteedPlan",
      planIdforDiscountGuranteedPlan
    );
    obj.append("planIdforDiscountDirectPlan", planIdforDiscountDirectPlan);
    obj.append(
      "planDetailsforGuranteedPlan",
      JSON.stringify(planDetailsforGuranteedPlan)
    );
    obj.append(
      "planDetailsforDirectPlan",
      JSON.stringify(planDetailsforDirectPlan)
    );
    obj.append("productMRP", productMRP);
    obj.append("productReview", productReview);
    obj.append("productRating", productRating);
    obj.append("productDisplayOrder", productDisplayOrder);
    obj.append("isFitwarzCompatible", isFitwarzCompatible);
    obj.append("isUrbanCompatible", isUrbanCompatible);
    obj.append("isLiveSessionCompatible", isLiveSessionCompatible);

    obj.append("metaTitle", metaTitle);
    obj.append("metaDescription", metaDescription);
    obj.append("productService", productService);


    if (tyre_size) {
      obj.append("tyreSize", tyre_size.id)
    }

    if (frame_material) {
      obj.append("frameMaterial", frame_material.id)
    }

    if (gear_type) {
      obj.append("gearType", gear_type.id)
    }

    multipleProductImages.map((productImage) => {
      obj.append("image", productImage);
    });

    if (this.isValid()) {
      this.setState({ productUpdateLoader: true, validationError: "" });
      ProductsService.editProduct(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.setState({
            errors: false,
            validationError: "",
            productUpdateLoader: false,
            openEd: false,
            name: "",
            uniqueId: "",
            type: null,
          });
          this.props.history.push("/products");
        } else if (res.data.statusCode === 0) {
          this.setState({
            validationError: res.data.error.errorMessage,
            productUpdateLoader: false,
          });
        }
      });
    }
  }

  handleMultipleImgPreivew = (e) => {
    let multipleProductImagesPreview = [];
    let files = Array.from(e.target.files);
    if (files) {
      files.map((image) => {
        multipleProductImagesPreview.push(URL.createObjectURL(image));
      });
      this.setState({
        multipleProductImages: files,
        multipleProductImagesPreview,
      });
    }
  };

  handleAddSpecification = () => {
    let specifications = Array.from(this.state.specifications);
    specifications.push({ label: "", value: "" });
    this.setState({ specifications });
  };

  handleSpecsChange = (e, index) => {
    let { value } = e.target;
    let specifications = Array.from(this.state.specifications);
    specifications[index]["value"] = value;
    this.setState({ specifications });
  };

  selectProductSpecification = (val, index) => {
    let specifications = Array.from(this.state.specifications);
    specifications[index]["label"] = val.label;
    this.setState({ specifications });
  };

  removeSpecification = (index) => {
    let specifications = Array.from(this.state.specifications);
    specifications.splice(index, 1);
    this.setState({ specifications });
  };

  handleAddPlanDetails = (field) => {
    let tempField = Array.from(this.state[field]);
    tempField.push({});
    this.setState({ [field]: tempField });
  };

  handlePlanDetails = (e, field, index) => {
    let { name, value } = e.target;
    let tempField = Array.from(this.state[field]);

    tempField[index][name] = value;
    this.setState({ [field]: tempField });
  };

  selectProductCategory = (category) => {
    this.setState({ productCategory: category.value });
    this.setState({ selectedCategory: category });
    if (category.subCategories.length == 0) {
      this.setState({ subcategory: '' });
    }
  };

  handleDeletePlanDetails = (field, index) => {
    let tempField = Array.from(this.state[field]);
    tempField.splice(index, 1);
    this.setState({ [field]: tempField });
  };

  closeModal = () => {
    this.setState({
      addKeyFeaturePopup: false,
      featureTitle: "",
      featureDescription: "",
      featureImage: "",
      featureImagePreview: "",
      validationError: "",
    });
  };

  handleKeyFeaturePopup = () => {
    this.setState({ addKeyFeaturePopup: true });
  };

  submitAddFeature = () => {
    if (this.state.isFetching) {
      return
    }
    let { featureTitle, featureDescription, featureImage } = this.state;
    let obj = new FormData();
    obj.append("productId", this.props.match.params.id);
    obj.append("keyImages", featureImage);
    obj.append("title", featureTitle);
    obj.append("description", featureDescription);

    if (!featureTitle) {
      this.setState({ validationError: "Enter Feature Title!" });
    } else if (!featureDescription) {
      this.setState({ validationError: "Enter Feature Description!" });
    } else if (!featureImage) {
      this.setState({ validationError: "Upload Feature Image!" });
    } else {
      this.setState({ isFetching: true })
      addKeyFeature(obj).then((res) => {
        if (res.data.statusCode === 1) {
          this.closeModal();
          this.productDetails();
        } else if (res.data.statusCode === 0) {
          this.setState({ validationError: res.data.error.errorMessage });
        }
        this.setState({ isFetching: false })
      });
    }
  };

  removeKeyFeature = async (_id) => {
    try {

      let params = { productId: this.state.productId, featureId: _id }

      await removeKeyFeatures(params)

      let { keyFeatures } = this.state
      let _keyFeatures = [...[], ...keyFeatures]
      _keyFeatures = _keyFeatures.filter(item => item._id !== _id)
      this.setState({ keyFeatures: _keyFeatures })

    } catch (error) {

      console.log(error, "error")

    }
  }

  updateData = (value , key) => {
    this.setState({ [key]: value });
  }


  render() {

    let {
      productName,
      productCode,
      productASIN,
      productLink,
      productDescription,
      productPrice,
      productImage,
      productImagePreview,
      productUpdateLoader,
      updateError,
      productType,
      specifications,
      brandName,
      productPriceDiscount,
      multipleProductImagesPreview,
      skuIdforGuranteedPlan,
      skuIdforDirectPlan,
      planDetailsforGuranteedPlan,
      planDetailsforDirectPlan,
      planIdforGuranteedPlan,
      planIdforDirectPlan,
      planIdforDiscountGuranteedPlan,
      planIdforDiscountDirectPlan,
      Loader,
      productCategory,
      productWebsite,
      onefitplusChoiceImage,
      onefitplusChoiceImagePreview,
      addKeyFeaturePopup,
      featureImagePreview,
      validationError,
      keyFeatures,
      productMRP,
      productReview,
      productRating,
      productDisplayOrder,
      subcategory,
      gender,
      selectedCategory,
      color_code,
      color_name,
      size,
      tyre_size,
      frame_material,
      gear_type,
      metaTitle,
      metaDescription,
      isFetching,
      featureDescription,
      productService
    } = this.state;

    let genders = [
      { label: "male", value: 1 },
      { label: "female", value: 2 },
    ];

    return (
      <HOC>
        {Loader && (
          <div className="loader_wrapper">
            <LoaderSvg />
          </div>
        )}
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item">Edit Product</li>
            </ol>
            <h3 className="text-center mb-4 mt-5">Edit Product</h3>
            <form onSubmit={this.editProduct} className="edit_device_form mb-5">
              <div className="form-group">
                <label>Name of Product</label>
                <input
                  type="text"
                  className="form-control"
                  name="productName"
                  id="productName"
                  value={productName}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
              <label>Product Service</label>
              <ReactQuill theme="snow" 
              value={this.state.productService?this.state.productService:''}
              onChange={(value) => {this.updateData(value , "productService")}}
                height={250}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['link', 'image', 'video'],
                    ['code-block'],
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                    [{ 'direction': 'rtl' }],                         // text direction
                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    [{ 'font': [] }],
                    [{ 'align': [] }],
                    ['clean']
                  ]
                }}
              />
            </div>

              <div className="row">
                <div className="form-group col">
                  <label>Meta Title*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="metaTitle"
                    id="metaTitle"
                    value={metaTitle}
                  />
                </div>

                <div className="form-group col">
                  <label>Meta Description*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    name="metaDescription"
                    id="metaDescription"
                    value={metaDescription}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Product Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="productCode"
                  id="productCode"
                  value={productCode}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label>Product ASIN</label>
                <input
                  type="text"
                  className="form-control"
                  name="productASIN"
                  id="productASIN"
                  value={productASIN}
                  onChange={this.handleChange}
                />
              </div>

              <div className="row">
                <div className="form-group col">
                  <label>Product Price</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.onHandleChangeDecimal}
                    name="productPrice"
                    id="productPrice"
                    value={productPrice}
                  />
                </div>

                <div className="form-group col">
                  <label>Product Type</label>
                  <select
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="productType"
                    id="productType"
                    value={productType}
                  >
                    <option value={0}>Rent</option>
                    <option value={1}>Sale</option>
                  </select>
                </div>
              </div>

              <div className="form-group mt-4">
                <div className="row">
                  <div className="form-group col-3">
                    <label>MRP (₹)</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.onHandleChangeDecimal}
                      name="productMRP"
                      id="productMRP"
                      value={productMRP}
                    />
                  </div>

                  <div className="form-group col-3">
                    <label>Review</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.onHandleChangeNumeric}
                      name="productReview"
                      id="productReview"
                      value={productReview}
                      placeholder="000"
                    />
                  </div>

                  <div className="form-group col-3">
                    <label>Rating</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.onHandleChangeDecimal}
                      placeholder="0.0"
                      name="productRating"
                      id="productRating"
                      value={productRating}
                    />
                  </div>
                  <div className="form-group col-3">
                    <label>Display Order</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.onHandleChangeNumeric}
                      name="productDisplayOrder"
                      id="productDisplayOrder"
                      value={productDisplayOrder}
                      placeholder="1"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2 form-group cm_mt_5">
                <div className="custom-control custom-checkbox cm_mt">
                  <input
                    name="isFitwarzCompatible"
                    id="isFitwarzCompatible"
                    type="checkbox"
                    checked={this.state.isFitwarzCompatible}
                    onChange={this.handleChange}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isFitwarzCompatible"
                  >
                    isFitwarzCompatible
                  </label>
                </div>
              </div>

              <div className="col-md-2 form-group cm_mt_5">
                <div className="custom-control custom-checkbox cm_mt">
                  <input
                    name="isUrbanCompatible"
                    id="isUrbanCompatible"
                    type="checkbox"
                    checked={this.state.isUrbanCompatible}
                    onChange={this.handleChange}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isUrbanCompatible"
                  >
                    isUrbanCompatible
                  </label>
                </div>
              </div>

              <div className="col-md-2 form-group cm_mt_5">
                <div className="custom-control custom-checkbox cm_mt">
                  <input
                    name="isLiveSessionCompatible"
                    id="isLiveSessionCompatible"
                    type="checkbox"
                    checked={this.state.isLiveSessionCompatible}
                    onChange={this.handleChange}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isLiveSessionCompatible"
                  >
                    isLiveSessionCompatible
                    </label>
                </div>
              </div>


              <div className="form-group">
                <label>Product Link</label>
                <input
                  type="text"
                  className="form-control"
                  name="productLink"
                  id="productLink"
                  value={productLink}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label>Product Description</label>
                <ReactQuill theme="snow" value={this.state.productDescription} onChange={this.handleDescription} height={250}
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      ['link', 'image', 'video'],
                      ['code-block'],
                      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                      ['blockquote', 'code-block'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                      [{ 'direction': 'rtl' }],                         // text direction
                      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                      [{ 'font': [] }],
                      [{ 'align': [] }],
                      ['clean']
                    ]
                  }}
                />
              </div>

              <div className="form-group">
                <label>Specifications</label>

                {specifications.map((specs, index) => (
                  <div className="row" key={index}>
                    <h4 className="cm_pos_rel col-12">
                      Spec {index + 1}
                      <span
                        className="btn btn-danger btn-sm"
                        onClick={() => this.removeSpecification(index)}
                      >
                        <i className="fa fa-trash" />
                      </span>
                    </h4>
                    <div className="form-group col">
                      <label>Select Label</label>
                      <Select
                        isSearchable
                        options={productSpecifications}
                        placeholder="Select a supported specification..."
                        onChange={(val) =>
                          this.selectProductSpecification(val, index)
                        }
                        value={specs}
                      />
                    </div>

                    <div className="form-group col">
                      <label>Enter Value</label>
                      <input
                        type="text"
                        placeholder="Enter value for selected specification......"
                        className="form-control"
                        onChange={(e) => this.handleSpecsChange(e, index)}
                        value={specs.value}
                      />
                    </div>
                  </div>
                ))}
                <div>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={this.handleAddSpecification}
                  >
                    {" "}
                    <i className="fa fa-plus-square mr-1" />
                    Add Specs
                  </Button>
                </div>
              </div>

              <div className="form-group">
                <label>Brand Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="brandName"
                  id="brandName"
                  placeholder="Ex: Rpm Fitness...."
                  value={brandName}
                />
              </div>
              <div className="form-group">
                <label>Discounted Price</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="productPriceDiscount"
                  id="productPriceDiscount"
                  placeholder="Ex: 1000"
                  value={productPriceDiscount}
                />
              </div>
              <div className="form-group">
                <label>Product Color Code</label>
                <input
                  type="color"
                  className="form-control color_input"
                  value={color_code}
                  onChange={(e) => {
                    this.setState({ color_code: e.target.value });
                  }}
                  name="Product Color Code"
                  id="Product Color Code"
                  placeholder="Select Product Color Code"
                />
              </div>

              <div className="form-group">
                <label>Product Color Name</label>
                <input
                  type="text"
                  className="form-control color_input"
                  onChange={(e) => {
                    this.setState({ color_name: e.target.value });
                  }}
                  value={color_name}
                  name="Product Color Name"
                  id="Product Color Name"
                  placeholder="Select Product Color Name"
                />

              </div>

              <div className="form-group">
                <label>Product Size</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ size: e.target.value });
                  }}
                  value={size}
                  name="Product Size"
                  id="Product Size"
                  placeholder="Select Product Size"
                />
              </div>

              <div className="form-group">
                <label>Product Category</label>
                <Select
                  isSearchable
                  options={productCategories}
                  placeholder="Select a product category"
                  onChange={(val) => this.selectProductCategory(val)}
                  value={productCategories.find(
                    (cat) => cat.value === productCategory
                  )}
                />
              </div>

              {selectedCategory && selectedCategory.subCategories.length ? (
                <div className="form-group">
                  <label>Product Sub Category</label>
                  <Select
                    isSearchable
                    options={selectedCategory.subCategories}
                    placeholder="Select a product sub category"
                    onChange={(val) => this.selectProductSubCategory(val)}
                    value={selectedCategory.subCategories.find(
                      (cat) => cat.value === subcategory
                    )}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="form-group">
                <label>Tyre Size</label>
                <Select
                  isSearchable
                  options={tyreSizes}
                  placeholder="Select tyre size"
                  onChange={(val) => this.setState({ tyre_size: val })}
                  value={tyre_size}
                  id='tyre_sizes'
                  getOptionValue={option => option.id + 'tyre_size'}
                />
              </div>

              <div className="form-group">
                <label>Frame Material</label>
                <Select
                  isSearchable
                  options={frameMaterials}
                  placeholder="Select frame Materials"
                  onChange={(val) => this.setState({ frame_material: val })}
                  value={frame_material}
                  getOptionValue={option => option.id + 'frame_material'}
                  id='frame_materials'
                />
              </div>

              <div className="form-group">
                <label>Gear Type</label>
                <Select
                  isSearchable
                  options={gearTypes}
                  placeholder="Select gear Type"
                  onChange={(val) => this.setState({ gear_type: val })}
                  value={gear_type}
                  id='gear_type'
                  getOptionValue={option => option.id + 'gear_type'}

                />
              </div>

              <div className="form-group">
                <label>Gender</label>
                <Select
                  isSearchable
                  options={genders}
                  placeholder="Select Gender"
                  onChange={(event) => this.setState({ gender: event.value })}
                  value={genders.find((data) => data.value == gender)}
                />
              </div>

              <div className="form-group mt-5 mb-5">
                <label className="d-block">Select Product Website</label>
                <div className="radio_wrapper text-center">
                  <label>
                    Amazon
                    <input
                      type="radio"
                      checked={productWebsite == 0}
                      value={0}
                      onChange={this.handleChange}
                      name="productWebsite"
                    />
                    <span></span>
                  </label>
                  <label>
                    Flipkart
                    <input
                      type="radio"
                      checked={productWebsite == 1}
                      value={1}
                      onChange={this.handleChange}
                      name="productWebsite"
                    />
                    <span></span>
                  </label>
                  <label>
                    One Fitplus
                    <input
                      type="radio"
                      checked={productWebsite == 2}
                      value={2}
                      onChange={this.handleChange}
                      name="productWebsite"
                    />
                    <span></span>
                  </label>
                </div>
              </div>

              <div className="form-group mt-5 mb-5">
                <label className="d-block">Upload Product's Main Image</label>
                <div className="radio_wrapper text-center">
                  <input
                    type="file"
                    onChange={this.handleChange}
                    id="productImage"
                    name="productImage"
                  />
                  <span></span>
                  <img width="400px" src={productImagePreview} />
                </div>
              </div>

              <div className="form-group mt-5 mb-5">
                <label className="d-block">One Fitplus Choice Image</label>
                <div className="radio_wrapper text-center">
                  <input
                    type="file"
                    onChange={this.handleChange}
                    id="onefitplusChoiceImage"
                    name="onefitplusChoiceImage"
                  />
                  <span></span>
                  <img width="400px" src={onefitplusChoiceImagePreview} />
                </div>
              </div>

              <div className="form-group">
                <label className="d-block">Product's Other Images</label>
                <div className="radio_wrapper text-center">
                  <input
                    type="file"
                    multiple
                    onChange={this.handleMultipleImgPreivew}
                  />
                </div>
              </div>

              {multipleProductImagesPreview
                ? multipleProductImagesPreview.map((imageURI) => (
                  <img
                    className="tracks_img"
                    src={imageURI}
                    alt="Photo uploaded"
                  />
                ))
                : null}

              <div className="form-group mt-4">
                <label>Guaranteed Plan Details</label>
                {planDetailsforGuranteedPlan &&
                  planDetailsforGuranteedPlan.map((detail, i) => (
                    <div className="row" key={i}>
                      <div className="form-group col-3">
                        <label>
                          Plan Tenure {i + 1}
                          <i
                            className="fa fa-minus-circle cm_pointer ml-1"
                            onClick={() =>
                              this.handleDeletePlanDetails(
                                "planDetailsforGuranteedPlan",
                                i
                              )
                            }
                          />
                        </label>
                        <select
                          type="number"
                          className="form-control"
                          name="planTenure"
                          id="planTenure"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforGuranteedPlan",
                              i
                            )
                          }
                          value={detail.planTenure}
                        >
                          <option value={6}>6 Months</option>
                          <option value={9}>9 Months</option>
                          <option value={12}>12 Months</option>
                          <option value={15}>15 Months</option>
                          <option value={18}>18 Months</option>
                        </select>

                        {/* 
                                            <input type="number" className="form-control"
                                                name='planTenure' onChange={(val) => this.handlePlanDetails(val, 'planDetailsforGuranteedPlan', i)} value={detail.planTenure}
                                            /> */}
                      </div>

                      <div className="form-group col-3">
                        <label>Plan Amount (₹)</label>
                        <input
                          type="number"
                          className="form-control"
                          name="planAmount"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforGuranteedPlan",
                              i
                            )
                          }
                          value={detail.planAmount}
                        />
                      </div>

                      <div className="form-group col-3">
                        <label>Deposit (₹)</label>
                        <input
                          type="number"
                          className="form-control"
                          name="deposit"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforGuranteedPlan",
                              i
                            )
                          }
                          value={detail.deposit}
                        />
                      </div>
                      <div className="form-group col-3">
                        <label>Actual Price (₹)</label>
                        <input
                          type="number"
                          className="form-control"
                          name="discount"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforGuranteedPlan",
                              i
                            )
                          }
                          value={detail.discount}
                        />
                      </div>
                      <div className="form-group col-3">
                        <label>Plan Id For Guranteed Plan</label>
                        <input
                          type="text"
                          className="form-control"
                          name="planIdforGuranteedPlan"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforGuranteedPlan",
                              i
                            )
                          }
                          value={detail.planIdforGuranteedPlan}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <i
                className="fa fa-plus-square cm_pointer"
                onClick={() =>
                  this.handleAddPlanDetails("planDetailsforGuranteedPlan")
                }
              >
                {" "}
                Add Guaranteed Plan Detail
              </i>

              <div className="form-group mt-4">
                <label>Direct Plan Details</label>
                {planDetailsforDirectPlan &&
                  planDetailsforDirectPlan.map((detail, i) => (
                    <div className="row" key={i}>
                      <div className="form-group col-3">
                        <label>
                          Plan Tenure {i + 1}
                          <i
                            className="fa fa-minus-circle cm_pointer ml-1"
                            onClick={() =>
                              this.handleDeletePlanDetails(
                                "planDetailsforDirectPlan",
                                i
                              )
                            }
                          />
                        </label>

                        <select
                          type="number"
                          className="form-control"
                          name="planTenure"
                          id="planTenure"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforDirectPlan",
                              i
                            )
                          }
                          value={detail.planTenure}
                        >
                          <option value={6}>6 Months</option>
                          <option value={9}>9 Months</option>
                          <option value={12}>12 Months</option>
                          <option value={15}>15 Months</option>
                          <option value={18}>18 Months</option>
                        </select>

                        {/* 
                                            <input type="number" className="form-control"
                                                name='planTenure' onChange={(val) => this.handlePlanDetails(val, 'planDetailsforDirectPlan', i)} value={detail.planTenure}
                                            /> */}
                      </div>

                      <div className="form-group col-3">
                        <label>Plan Amount (₹)</label>
                        <input
                          type="number"
                          className="form-control"
                          name="planAmount"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforDirectPlan",
                              i
                            )
                          }
                          value={detail.planAmount}
                        />
                      </div>

                      <div className="form-group col-3">
                        <label>Deposit (₹)</label>
                        <input
                          type="number"
                          className="form-control"
                          name="deposit"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforDirectPlan",
                              i
                            )
                          }
                          value={detail.deposit}
                        />
                      </div>
                      <div className="form-group col-3">
                        <label>Actual Price (₹)</label>
                        <input
                          type="number"
                          className="form-control"
                          name="discount"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforDirectPlan",
                              i
                            )
                          }
                          value={detail.discount}
                        />
                      </div>
                      <div className="form-group col-3">
                        <label>Plan Id For Direct Plan</label>
                        <input
                          type="text"
                          className="form-control"
                          name="planIdforDirectPlan"
                          onChange={(val) =>
                            this.handlePlanDetails(
                              val,
                              "planDetailsforDirectPlan",
                              i
                            )
                          }
                          value={detail.planIdforDirectPlan}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <i
                className="fa fa-plus-square cm_pointer"
                onClick={() =>
                  this.handleAddPlanDetails("planDetailsforDirectPlan")
                }
              >
                {" "}
                Add Direct Plan Detail
              </i>

              <div className="form-group">
                <label>SkuId For Guranteed Plan</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="skuIdforGuranteedPlan"
                  id="skuIdforGuranteedPlan"
                  placeholder="Enter SkuId For Guranteed Plan"
                  value={skuIdforGuranteedPlan}
                />
              </div>
              <div className="form-group">
                <label>SkuId For Direct Plan</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="skuIdforDirectPlan"
                  id="skuIdforDirectPlan"
                  placeholder="Enter SkuId For Direct Plan"
                  value={skuIdforDirectPlan}
                />
              </div>
              <div className="form-group">
                <label>PlanId For Discounted Guranteed Plan</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="planIdforDiscountGuranteedPlan"
                  id="planIdforDiscountGuranteedPlan"
                  placeholder="Enter PlanId For Discounted Guranteed Plan"
                  value={planIdforDiscountGuranteedPlan}
                />
              </div>
              <div className="form-group">
                <label>PlanId For Discount Direct Plan</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="planIdforDiscountDirectPlan"
                  id="planIdforDiscountDirectPlan"
                  placeholder="Enter PlanId For Discount Direct Plan"
                  value={planIdforDiscountDirectPlan}
                />
              </div>

              <div className="form-group mt-4">
                <label>Key Features</label>
                {keyFeatures.map((feature, i) => (
                  <div className="row" key={i}>
                    <div className="form-group col-3">
                      <label>Title {i + 1}</label>
                      <p>{feature.title}</p>
                    </div>

                    <div className="form-group col-3">
                      <label>Description</label>
                      
                      <p>{feature.description ? feature.description.replace(/(<([^>]+)>)/gi, "") : ''}</p>
                    </div>

                    <div className="form-group col-3">
                      <img width="100px" src={feature.keyImages} />
                    </div>
                    <div className="form-group col-3">
                      <a onClick={() => { this.removeKeyFeature(feature._id, i) }}>Remove</a>
                    </div>
                  </div>
                ))}
              </div>

              <button
                className="btn btn-primary btn-block mt-4"
                type="submit"
                disabled={productUpdateLoader}
              >
                {!productUpdateLoader ? (
                  <span>Update Product</span>
                ) : (
                  <div>
                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                    <span>Updating....</span>
                  </div>
                )}
              </button>
              {validationError && (
                <h6 className="text-center mb-4 mt-5 error_message">
                  {validationError}
                </h6>
              )}
            </form>
            <Button variant="info" onClick={this.handleKeyFeaturePopup}>
              Add Key Feature
            </Button>
          </div>
        </div>

        <Modal show={addKeyFeaturePopup} onHide={this.closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Feature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Title*</label>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="featureTitle"
                id="featureTitle"
                placeholder="Enter Title"
              />
            </div>
            <div className="form-group">
              <label>Description*</label>
              {/* <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="featureDescription"
                id="featureDescription"
                placeholder="Enter Description"
              /> */}

              <ReactQuill theme="snow" onChange={this.changeFeatureDescription}  value={featureDescription}
                height={250}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['link', 'image', 'video'],
                    ['code-block'],
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                    [{ 'direction': 'rtl' }],                         // text direction
                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    [{ 'font': [] }],
                    [{ 'align': [] }],
                    ['clean']
                  ]
                }}
              />
            </div>
            <div className="form-group mt-5 mb-5">
              <label className="d-block">Feature Image*</label>
              <div className="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleChange}
                  id="featureImage"
                  name="featureImage"
                />
                <span></span>
                <img width="400px" src={featureImagePreview} />
              </div>
            </div>
          </Modal.Body>
          <h4 className="text-center text-danger mb-4 mt-5">{updateError}</h4>

          {validationError && (
            <h6 className="text-center mb-4 mt-5 error_message">
              {validationError}
            </h6>
          )}
          <Modal.Footer>
            <Button variant="danger" onClick={this.closeModal}>
              Close
            </Button>
            <Button variant="info" disabled={isFetching} onClick={() => {
              if (isFetching) {
                return
              }
              this.submitAddFeature()
            }}>
              {isFetching ? 'Submitting...' : 'Submit'}
            </Button>
          </Modal.Footer>
        </Modal>
      </HOC>
    );
  }
}

export default EditProduct;
