export default {
  api: {
    url: 'https://api.fitplus.biz/fitstar/api/',
    socket: 'https://socket.fitplus.biz',
    mode: 'cors'
  },
  firebaseConfig: {
    apiKey: "AIzaSyAWsoS7Oi-DOzghIJmbqCJq6-8IOrmGgKw",
    authDomain: "fitplus-1560243809365.firebaseapp.com",
    databaseURL: "https://fitplus-1560243809365.firebaseio.com",
    projectId: "fitplus-1560243809365",
    storageBucket: "fitplus-1560243809365.appspot.com",
    messagingSenderId: "488083150654",
    appId: "1:488083150654:web:2a33222aebb476b0f27e6f"
  },
  sfsserver:{
    host:"sfs.onefitplus.com",
    port:8443,
    ssl:true
  }
}