import React, { Component } from 'react';
import { BackArrow, Location, Floors, Footspeed, Footstep, Calories } from '../../../assets/SvgIcon';
import { Progress, Tabs, DatePicker } from 'antd';
import moment from 'moment';
import { stepsGraph, getUserTarget } from '../../../config/services/usersService';
import { toast } from 'react-toastify';
import Chart from "react-apexcharts";


class StepsGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedGraphType: 2,
            dateFrom: moment().startOf('month').valueOf(),
            dateTo: moment().endOf('month').valueOf(),
            currentDate: moment().valueOf(),
            userId: this.props.userId,
            graphData: {
                weeklyData: [],
                countData:  []
            },
            target: {},
            stepsTarget: 0,
            totalSteps: 0,
            maxValue: 0,
            totalCalories: 0,
            noOfRecords: 0,
        }
    }

    componentDidMount() {
        this.getStepsGraph()
        this.getTargets()
    }


    getTargets = () => {
        let { userId } = this.state
        getUserTarget({ userId })
            .then(res => {
                const stepsTarget = res.data.responseData.steps ? res.data.responseData.steps : 0
                this.setState({ stepsTarget })
            })
    }


    getStepsGraph = () => {
        let { selectedGraphType, dateFrom, dateTo, userId } = this.state
        let params = {
            dateFrom,
            dateTo,
            requestUserId: userId,
            graphType: selectedGraphType
        }

        stepsGraph(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {

                    let maxValue = Math.max(...response.responseData.stepGraph.countData)
                    let totalSteps = parseInt(response.responseData.stepGraph.countData.reduce((a, b) => a + b))
                    let noOfRecords = parseInt(response.responseData.stepGraph.countData.length)
                    let totalCalories = response.responseData.calorie.length ? parseInt(response.responseData.calorie.reduce((a, b) => a + (b['count'] || 0), 0)) : 0

                    this.setState({
                        graphData: response.responseData.stepGraph,
                        target: response.responseData.target,
                        maxValue,
                        totalSteps,
                        totalCalories,
                        noOfRecords,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleDateChange = (date, dateType) => {
        let dateFrom = moment(date).startOf(dateType).valueOf()
        let dateTo = moment(date).endOf(dateType).valueOf()
        let currentDate = moment(date).valueOf()

        this.setState({ dateFrom, dateTo, currentDate }, () => { this.getStepsGraph() })
    }


    handleGraphType = (graphType) => {
        let dateFrom
        let dateTo

        switch (parseInt(graphType)) {
            case 0:
                dateFrom = moment().startOf('day').valueOf()
                dateTo = moment().endOf('day').valueOf()
                break;
            case 1:
                dateFrom = moment().startOf('week').valueOf()
                dateTo = moment().endOf('week').valueOf()
                break;
            case 2:
                dateFrom = moment().startOf('month').valueOf()
                dateTo = moment().endOf('month').valueOf()
                break;
        }

        this.setState({ selectedGraphType: parseInt(graphType), dateFrom, dateTo }, () => { this.getStepsGraph() })
    }



    render() {
        let { selectedGraphType, graphData, stepsTarget, totalSteps, currentDate, maxValue, totalCalories, noOfRecords } = this.state

        const data = {
            labels: graphData.weeklyData,
            datasets: [
                {
                    label: 'Steps Taken',
                    backgroundColor: '#16D8E0',
                    borderColor: '#16D8E0',
                    borderWidth: 2,
                    data: graphData.countData
                }
            ]
        }


        let options = {
            colors: ["#1BC8D0"],
            chart: {
                id: "Steps-Bar",
                stacked: true,
                stackType: "70%",
            },
            xaxis: {
                categories: graphData.weeklyData
            }
        }

        let series = [
            {
                name: "Steps",
                data: graphData.countData
            }
        ]

        return (
            <div className="graph-module">
                <div className="row justify-content-center">
                    <div className="col-md-4 col-xl-3">
                        {/* <div className="heading">
                            <h5>Steps Taken</h5>
                        </div> */}
                        <div className="tab-group flex-column d-flex align-items-baseline justify-content-between">
                            <div className="filter-tabs w-100">
                                <Tabs defaultActiveKey={selectedGraphType} onChange={this.handleGraphType}>
                                    <Tabs.TabPane
                                        tab={<span>Month</span>}
                                        key={2}
                                    >
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        tab={<span>Week</span>}
                                        key={1}
                                    >
                                    </Tabs.TabPane>

                                    <Tabs.TabPane
                                        tab={<span>Day</span>}
                                        key={0}
                                    >
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>

                            <div className="tabs-date-picker w-100">

                                {selectedGraphType == 2 && <DatePicker.MonthPicker allowClear={false} className="form-control" placeholder="Select Month" format='MMM YYYY' onChange={(val) => this.handleDateChange(val, 'month')}
                                    defaultValue={moment(new Date(currentDate), 'MMM YYYY')} />}

                                {selectedGraphType == 1 && <DatePicker.WeekPicker allowClear={false} className="form-control" placeholder="Select Week" onChange={(val) => this.handleDateChange(val, 'week')}
                                    defaultValue={moment(new Date(currentDate))} />}

                                {selectedGraphType == 0 && <DatePicker allowClear={false} className="form-control" placeholder="Select date" format='Do MMM YYYY' onChange={(val) => this.handleDateChange(val, 'day')}
                                    defaultValue={moment(new Date(currentDate), 'Do MMM YYYY')} />}
                            </div>

                            <div className="user-intake-detail d-block w-100">
                                <div className="progress-section text-center">
                                    <div className="postion-rel">
                                        <Progress type="circle" percent={(totalSteps / stepsTarget) * 100} width={80} />
                                        <span><Footstep /></span>
                                    </div>
                                    <p>{totalSteps} <small>/ Steps</small></p>
                                </div>
                                <div className="mt-4 divider-light-line mb-2"></div>
                                <div className="stepinfo mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                    </div>
                                </div>

                                <table className="table table-bordered stepinfo">
                                    <tbody>                                    
                                        <tr>
                                            <td>
                                                <span className="mr-2"><Calories /></span>
                                            </td>
                                            <td>
                                                {totalCalories} <small>/ Cal</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="mr-2"><Location /></span>
                                            </td>
                                            <td>
                                                {((0.732 * totalSteps) / 1000).toFixed(2)} <small>/ KM</small>
                                            </td>
                                        </tr>
                                    
                                        <tr>
                                            <td>
                                                Average
                                            </td>
                                            <td>
                                                {(totalSteps / noOfRecords).toFixed(2)} <small> / Steps</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Best Record</td>
                                            <td>
                                                {maxValue} <small>/ Steps</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Target</td>
                                            <td>{stepsTarget}  <small>Steps/Day</small></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 ml-auto">
                        <div className="text-center">
                            <div className="heading text-left">
                                <h5>Steps</h5>
                            </div>
                            <div className="graph-wrap">
                            <Chart
                                    options={options}
                                    series={series}
                                    type="bar"
                                        />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




export default StepsGraph;