import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'antd/dist/antd.css';
import { Modal } from 'react-bootstrap';
import { addCRMAgent } from '../../config/services/CRMAgent';
import { Form, Input, Button } from 'antd';
import { getRoles } from '../../config/services/rolesService';
import ReactSelect from 'react-select';
import { editSubAdmin } from '../../config/services/adminService';


const AddCRMAgent = ({ hide, formOpen, getCRMAgent, selectedAgent }) => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [selectedRole, setRole] = useState("");
    const [rolesList, setRoleList] = useState();
    const [selectedId, setselectedId] = useState("");
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const prefixSelector = (
        <Form.Item name="countryCode"
            rules={[
                {
                    type: "number",
                    validator: (_, value) =>
                        Number(value) && (value.length >= 2) ? Promise.resolve() : Promise.reject('Please enter valid country code'),

                },
            ]} noStyle>
            <Input
                placeholder="Ex: +91"
                className='cm_min_width width-70'
            />
        </Form.Item>
    );
    const onSubmit = (values) => {
        let { firstName, lastName, email, countryCode, mobileNo, selectedRole } = values;
        let params = {
            firstName,
            lastName,
            email,
            countryCode,
            mobileNo,
            rolesAllowed: JSON.stringify([selectedRole.value])
        };
        if (selectedId) {
            params["adminId"] = selectedId;
            editSubAdmin(params)
                .then(response => {
                    responseSet(response, hide, getCRMAgent, setError);
                });

        } else {
            addCRMAgent(params)
                .then(response => {
                    responseSet(response, hide, getCRMAgent, setError);
                });

        }

    };
    useEffect(() => {
        getRolesData();
        if (selectedAgent) {
            setselectedId(selectedAgent._id)
            form.setFieldsValue({
                firstName: selectedAgent.firstName,
                lastName: selectedAgent.lastName,
                mobileNo: selectedAgent.mobileNo,
                email: selectedAgent.email,
                selectedRole: { label: selectedAgent.rolesAllowed[0].roleName, value: selectedAgent.rolesAllowed[0]._id },
                countryCode: selectedAgent.countryCode,
            });
        }

    }, []);

    const getRolesData = (params) => {
        getRoles(params)
            .then(res => {
                const response = res.data
                if (response.statusCode == 1) {
                    let rolesListData = response.responseData.result
                    let rolesList = [];
                    rolesListData.map(role =>
                        rolesList.push({
                            label: role.roleName,
                            value: role._id
                        }))
                    setRoleList(rolesList);
                }
            })
    }

    const handleRole = (role) => {
        setRole(role)
    }


    return (
        <Modal show={formOpen} onHide={hide} centered size="lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title><h6>{selectedId && "Edit CRM" || "Add CRM"}</h6> </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form form={form} name="crmForm" scrollToFirstError {...formItemLayout} onFinish={onSubmit}>
                    <Form.Item name="firstName" label="First Name" rules={[{
                        required: true,
                        message: 'Please input First Name!',
                    },
                    ]}
                    >
                        <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item name="lastName" label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Last Name!',
                            },
                        ]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="mobileNo"
                        label="Phone Number"
                        rules={[
                            {
                                required: true,
                                validator: (_, value) =>
                                    Number(value) && value.length == 10 ? Promise.resolve() : Promise.reject('Please enter valid mobile Number'),
                            },
                        ]}
                    >
                        <Input placeholder="Phone Number"
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input placeholder="E-mail" />
                    </Form.Item>
                    <Form.Item
                        name="selectedRole"
                        label="Role"
                        rules={[
                            {

                                required: true,
                                message: 'Please select your Role!',
                            },
                        ]}
                    >
                        <ReactSelect
                            isSearchable
                            options={rolesList}
                            placeholder="Select a role "
                            onChange={(val) => handleRole(val)}
                            value={selectedRole}
                        />

                    </Form.Item>
                    <Form.Item className="mb-0"  {...tailFormItemLayout}>
                        <Button key="submit" htmlType="submit" type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                {error && <div className="text-danger text-center h3"> {error} </div>}
            </Modal.Body>
        </Modal >
    )
}

export default AddCRMAgent;

function responseSet(response, hide, getCRMAgent, setError) {
    if (response && response.data.statusCode === 1) {
        hide();
        getCRMAgent();
        toast.success(response.data.responseData.message);
    }
    if (response && response.data.statusCode === 0) {
        setError(response.data.error.errorMessage);
    }
}
