import React from 'react';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { LoaderSvg } from '../Reusable'


const SmartDietPlans = () => {
    return(
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item">Users</li>
            </ol>



            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-9">
                    <h4 className="cm_page_heading">Users</h4>
                  </div>
                  <div className="col-md-3 text-right cm_search">
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by Name or Email"
                    />
                    <i className="fa fa-search" ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">Name <i className={`fa fa-sort-alpha-desc`}></i><button value="filterFirstName"></button></th>
                    <th scope="col">Assigned Dietician</th>
                    <th scope="col" className="filter_text">Email <i className={`fa fa-sort-alpha-desc`} ></i><button value="filterEmail"></button></th>
                    <th scope="col">Gender</th>
                    <th scope="col">Diet plan</th>
                  </tr>
                </thead>


                <tbody>
                  {
                    loop.map((item) => {
                      return (
                        <tr key={item}>
                          <td>{item}</td>
                          <td>John doe</td>

                          <td>Not yet assigned</td>

                          <td>example@gmail.com</td>
                          <td>Male</td>

                          <td>
                            <Link to={'/'} className="btn btn-info btn-sm">View</Link>
                          </td>

                        </tr>
                      )
                    })
                  }

                </tbody>

              </table>
            </div>
          </div>
        </div>
    )
}

export default SmartDietPlans;


const loop = [1,2,3,4,5,6,7,8,9]