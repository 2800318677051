import axios from 'axios';
import API from '../Api';



export function addCustomerServiceType(params) {
    return axios.post(API.ADD_CUSTOMER_SERVICE_TYPE, params)
}

export function updateCustomerServiceType(params) {
    return axios.post(API.UPDATE_CUSTOMER_SERVICE_TYPE, params)
}

export function deleteCustomerServiceType(params) {
    return axios.post(API.DELETE_CUSTOMER_SERVICE_TYPE, params)
}

export function getCustomerServiceTypesList(params) {
    return axios.get(API.GET_CUSTOMER_SERVICE_TYPES, params)
}