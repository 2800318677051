import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getTransactionDetails, updateDeliveryStatus } from '../../config/services/transactionRequestsService';
import { toast } from 'react-toastify';
import { Dropdown, Button, Modal, InputGroup, FormControl, Alert } from 'react-bootstrap';

class RewardTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            transactionsList: [],
            accessiblePaths: [],
            search: '',
            count: 10,
            totalTransactions: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            titleErrorMsg: '',
            updateTackingIdPopup: false,
            trackingId: '',
            tempTransactionDetailsId: '',
            FilterStatus: false,
            filterBy: '',
            viewDetailsPopup: false,
            userDetails: {},
            productDetails: {},
            address: {},
        }
    }

    componentDidMount() {
        this.getTransactionDetails();
    }

    getTransactionDetails = () => {
        let { search, count, pageNo, sortType, sortField, filterBy } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField, filter: filterBy
            }
        }
        getTransactionDetails(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        transactionsList: response.responseData.result,
                        totalTransactions: response.responseData.totalCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    closeModal = () => this.setState({});

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, titleErrorMsg: '' })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getTransactionDetails());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getTransactionDetails());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getTransactionDetails() });

    parseDeliveryRequestStatus = (status) => {
        switch (status) {
            case 1:
                return "Pending";
            case 2:
                return "Delivered";
            case 3:
                return "Undelivered";
            case 4:
                return "Cancelled";
        }
    }

    updateDeliveryStatus = (transaction, status) => {
        let paramsObj = {
            transactionDetailsId: transaction._id,
            deliveryRequest: status
        }
        this.setState({ loader: true })
        this.updateDeliveryStatusApi(paramsObj)
    }


    updateDeliveryStatusApi = (paramsObj) => {
        updateDeliveryStatus(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.getTransactionDetails()
                    this.handleClose()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    updateTrackingIdPopup = (transaction) => {
        this.setState({
            updateTackingIdPopup: true,
            tempTransactionDetailsId: transaction._id,
            trackingId: transaction.trackingId
        })
    }

    updateTrackingId = () => {
        let { tempTransactionDetailsId, trackingId } = this.state
        let paramsObj = {
            transactionDetailsId: tempTransactionDetailsId,
            trackingId
        }
        if (!trackingId) {
            this.setState({ titleErrorMsg: "Tracking Id* is mandatory" })
        } else {
            this.setState({ loader: true })
            this.updateDeliveryStatusApi(paramsObj)
        }
    }

    handleClose = () => {
        this.setState({
            updateTackingIdPopup: false, trackingId: '', tempTransactionDetailsId: '', viewDetailsPopup: false,
            userDetails: {}, productDetails: {}, address: {}, titleErrorMsg: ''
        })
    }

    toggleFilterStatus = () => {
        this.setState({
            FilterStatus: !this.state.FilterStatus
        })
    }

    handleFilter = (filterBy) => {
        this.setState({ filterBy }, () => this.getTransactionDetails())
    }

    viewTrackingIdPopup = (transaction) => {
        let address = {
            deliveryRequest: transaction.deliveryRequest,
            trackingId: transaction.trackingId,
            userId: transaction.userId,
            addressLine1: transaction.addressLine1,
            addressLine2: transaction.addressLine2,
            city: transaction.city,
            state: transaction.state,
            country: transaction.country,
            zip: transaction.zip,
        }
        this.setState({ viewDetailsPopup: true, userDetails: transaction.userDetails, productDetails: transaction.productDetails, address })
    }

    render() {
        const {
            loader,
            transactionsList,
            search,
            pageNo,
            count,
            totalTransactions,
            updateTackingIdPopup,
            trackingId,
            titleErrorMsg,
            viewDetailsPopup,
            userDetails,
            productDetails,
            address } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Transactions Details</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="cm_page_heading">Transactions Details</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name, Email or Product Name"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-striped text-center mb-5">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('firstName')}>User's Name &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('email')}>User's Email &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('productName')}>Product's Name &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text">Tracking Id
                                        </th>
                                        <th id="filter_text" className={this.state.FilterStatus ? 'filter_text open' : 'filter_text'} onClick={this.toggleFilterStatus}>
                                            Delivery Status <i className="fa fa-filter" />
                                            <ul>
                                                <li onClick={() => this.handleFilter(1)}>
                                                    Pendind
                                                    </li>
                                                <li onClick={() => this.handleFilter(2)}>
                                                    Delivered
                                                </li>
                                                <li onClick={() => this.handleFilter(3)}>
                                                    Undelivered
                                                </li>
                                                <li onClick={() => this.handleFilter(4)}>
                                                    Cancelled
                                                </li>
                                                <li onClick={() => this.handleFilter('')}>
                                                    All
                                                </li>
                                            </ul>
                                        </th>

                                        <th scope="col" className="filter_text">Action &nbsp; </th>

                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        transactionsList.map((transaction, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">
                                                        {transaction.userDetails.firstName + " " + transaction.userDetails.lastName}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {transaction.userDetails.email}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {transaction.productDetails.productName}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        <Button className="cm_trackingId" variant="outline-info"
                                                            onClick={() => this.updateTrackingIdPopup(transaction)}>
                                                            {transaction.trackingId ? transaction.trackingId : "Set"}
                                                        </Button>
                                                    </span>
                                                    </td>
                                                    <td>
                                                        <Dropdown className="cm_dropdown">
                                                            <Dropdown.Toggle className="cm_trackingId" variant="outline-primary" id="dropdown-basic">
                                                                {this.parseDeliveryRequestStatus(transaction.deliveryRequest)}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 1)}>Pending</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 2)}>Delivered</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 3)}>Undelivered</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 4)}>Cancel</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        <Button variant="outline-info"
                                                            onClick={() => this.viewTrackingIdPopup(transaction)}>
                                                            View
                                                        </Button>
                                                    </td>
                                                    <td className="cm_no_wrap">{dateFormat(transaction.created, "d mmmm yyyy")}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalTransactions}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!transactionsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Modal show={updateTackingIdPopup} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Tracking Id</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text># TrackingId</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl placeholder="Enter tracking id" name="trackingId" value={trackingId}
                                onChange={this.handleChange}
                            />
                        </InputGroup>

                        {titleErrorMsg && <Alert variant="danger"> {titleErrorMsg} </Alert>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                            </Button>
                        <Button variant="primary" onClick={this.updateTrackingId}>
                            Save Changes
                            </Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={viewDetailsPopup} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{userDetails.firstName + " " + userDetails.lastName}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        Product:             {productDetails.productName}
                        <br/> Address line 1:{address.addressLine1}
                        <br/> Address line 2:{address.addressLine2}
                        <br/> City:          {address.city}
                        <br/> State:         {address.state}
                        <br/> Country:       {address.country}
                        <br/> Zip:           {address.zip}
                        <br/> Mobile:        {userDetails.fullMobileNo}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>
                            Close
                            </Button>
                    </Modal.Footer>
                </Modal>
            </HOC>


        )
    }
}




export default RewardTransactions;