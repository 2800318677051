import React, { useState, useEffect } from 'react';
import { getAllEquipments, deleteEquipment } from '../../config/services/commercialService';
import { Button } from 'react-bootstrap';
import AddEditModal from './AddEditModal';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';

const itemsCountPerPage = 10;
const columnDetails = [
    { fieldName: "S.No." },
    { fieldName: "Model" },
    { fieldName: "SKU Code" },
    { fieldName: "Brand" },
    { fieldName: "Product type" },
    { fieldName: "" }
];

const EquipmentModels = () => {
    const [equipmentsList, setEquipmentsList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isModalToEdit, setIsModalToEdit] = useState(false);
    const [model, setModel] = useState("");
    const [skuCode, setSkuCode] = useState("");
    const [brand, setBrand] = useState("");
    const [vertical, setVertical] = useState("");
    const [eqpId, setEqpId] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalEqps, setTotalEqps] = useState(0);

    useEffect(() => {
        fetchEquipmentsList(pageNumber);
    }, []);

    const fetchEquipmentsList = (pageNo) => {
        const params = { pageNo: pageNo, pageSize: itemsCountPerPage };
        getAllEquipments({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    data.result && data.result.data && setEquipmentsList(data.result.data);
                    data.result.pagination && setTotalEqps(data.result.pagination.totalRecords);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const deleteEquipmentFn = (id) => {
        const params = { equipmentId: id };
        deleteEquipment(params)
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    fetchEquipmentsList(pageNumber);
                    toast.success(data.message);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
        fetchEquipmentsList(pageNo);
    };

    const hideModal = () => setShowModal(false);

    const openEqpModelFn = (toEdit, equipment) => {
        setShowModal(true);
        setIsModalToEdit(toEdit);
        setModel(equipment.model || "");
        setSkuCode(equipment.skuCode || "");
        setBrand(equipment.brand || "");
        setVertical(toEdit ? {label: equipment.vertical ? equipment.vertical.verticalName : "", value: equipment.vertical ? equipment.vertical._id : ""} : "");
        setEqpId(equipment._id || "");
    };

    return (
        <div className="main-wrapper">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item">Equipment Models</li>
            </ol>

            <div className="add-equipment-model-button">
                <Button type="primary" onClick={() => openEqpModelFn(false, {})}>+ Equipment Model</Button>
            </div>
            <div className="table-responsive mb-4" style={{ minHeight: '100px' }}>
                <table className="table table-bordered table-striped text-center">
                    <thead>
                        <tr>
                            {columnDetails.map((item, i) => 
                                <th scope="col" key={i}>{item.fieldName}</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {equipmentsList.map((eqp, index) => {
                            return (
                                <tr key={eqp._id}>
                                    <td className="cm_no_wrap">{itemsCountPerPage * (pageNumber-1) + (index + 1)}</td>
                                    <td className="cm_no_wrap">{eqp.model}</td>
                                    <td className="cm_no_wrap">{eqp.skuCode}</td>
                                    <td className="cm_no_wrap">{eqp.brand}</td>
                                    <td className="cm_no_wrap">{eqp.vertical ? eqp.vertical.verticalName : ""}</td>
                                    <td className="cm_no_wrap">
                                        <Button className='m-1' variant="outline-info" onClick={() => openEqpModelFn(true, eqp)}> <i className="fa fa-pencil" /> </Button>
                                        <Button className='m-1' variant="outline-danger" onClick={() => deleteEquipmentFn(eqp._id)}> <i className="fa fa-trash" /> </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalEqps}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
            </div>

            {showModal && 
                <AddEditModal 
                    showModal={showModal}
                    isModalToEdit={isModalToEdit}
                    hideModal={hideModal}
                    fetchEquipmentsList={fetchEquipmentsList}
                    pageNo={pageNumber}
                    model={model}
                    skuCode={skuCode}
                    brand={brand}
                    vertical={vertical}
                    eqpId={eqpId}
                />
            }
        </div>
    );
};

export default EquipmentModels;