import merge from 'lodash/merge';
import development from './development';
import local from './local';
import stag from './stag';
import prod from './prod';
import beta from './beta';
import qadev from './qadev';


const settings = {}
export default (() => {
  switch (process.env.REACT_APP_ENV) {
    
   
    case 'development':
    case 'dev':
    case 'dev ':
      console.log(process.env.REACT_APP_ENV)
      return merge(development, settings)
      break;
    case 'staging':
    case 'stag':
    case 'stag ':
      console.log(process.env.REACT_APP_ENV)
      return merge(stag, settings)
      break;
    case 'production':
    case 'prod':
    case 'prod ':
      return merge(prod, settings)
      break;
    case 'localhost':
    case 'local':
    case 'local ':
      return merge(local, settings)
      break;
    case 'beta':
    case 'beta ':
      return merge(beta, settings)
      break;
    case 'qadev':
    case 'qadev ':
      return merge(qadev, settings)
      break;
    default:
      return merge(development, settings)
  }
})()
