import axios from 'axios';
import API from '../Api';



export function getAllFoods(params) {
    return axios.get(API.GET_ALL_FOODS,params)
}

export function addFood(params) {
    return axios.post(API.ADD_FOOD,params)
}

export function deleteFood(params) {
    return axios.post(API.DELETE_FOOD,params)
}

export function editFood(params) {
    return axios.post(API.EDIT_FOOD,params)
}

export function getFoodCategories(params) {
    return axios.get(API.GET_FOOD_CATEGORIES,params)
}

export function addFoodCategory(params) {
    return axios.post(API.ADD_FOOD_CATEGORY,params)
}

export function editFoodCategory(params) {
    return axios.post(API.EDIT_FOOD_CATEGORY,params)
}

export function deleteFoodCategory(params) {
    return axios.post(API.DELETE_FOOD_CATEGORY,params)
}

export function uploadFoodFromExcel(params) {
    return axios.post(API.UPLOAD_FOOD_FROM_EXCEL,params)
}


export function getFoodRequests(params) {
    return axios.get(API.GET_FOOD_REQUESTS,params)
}


export function changeRequestStatus(params) {
    return axios.post(API.CHANGE_REQ_STATUS,params)
}

export const FoodService = {
    getAllFoods,
    addFood,
    deleteFood,
    editFood,
    getFoodCategories,
    addFoodCategory,
    editFoodCategory,
    uploadFoodFromExcel,
    getFoodRequests,
    changeRequestStatus
};