import axios from 'axios';
import API from '../Api';

export function getProductJobList(params) {
    return axios.get(API.GET_PRODUCTJOB_LIST, params)
}

export function getProductJobListForReport(params) {
    return axios.get(API.GET_PRODUCTJOB_LIST_R, params)
}

export function addProductJob(params) {
    return axios.post(API.ADD_PRODUCTJOB, params)
}


export function deleteProductJob(params) {
    return axios.post(API.DELETE_PRODUCTJOB, params)
}
export function getProductJob(params) {
    return axios.get(API.GET_PRODUCTJOB, params)
}
export function editProductJob(params) {
    return axios.post(API.EDIT_PRODUCTJOB, params)
}

export function assignMultipleJobToAssociate (params){
    return axios.post(API.ASSIGN_PRODUCTJOBTOASSOCIATE, params)

}
export function updateJobStatus(params) {
    return axios.post(API.CHANGE_PRODUCTJOBSTATUS, params)
}

export function markProductJobUrgent(params) {
    return axios.post(API.MARK_PRODUCTJOB_URGENT, params)
}

export function updateFromExcel(params) {
    return axios.post(API.UPDATE_PRODUCTJOB_FROM_EXCELSHEET, params)
}

export function exportProductJobRequest(params) {
    return axios.get(API.EXPORT_PRODUCT_JOB_REQUEST, params);
}

export function exportProductJobRequestForReport(params) {
    return axios.get(API.EXPORT_PRODUCT_JOB_REQUEST_FOR_REPORT, params);
}

export function updateAddiPaymentStatus(params) {
    return axios.post(API.UPDATE_ADDITIONAL_CHARGES_PAYMENT_STATUS, params);
}

export function addAdditionalChargesByAdminApi(params) {
    return axios.post(API.ADD_VENDOR_PAYMENT_ADMIN, params);
}
