import React, { useEffect, useState } from 'react';
import { LoaderSvg } from '../Reusable';


let desingCLass = 'shadow-sm p-3 mb-4 rounded';

const OrderInfo = (props) => {
    const { orderDetails } = props;
    const [errorMsg, setErrorMsg] = useState('');
    const [loader, setLoader] = useState(false);
    const [aboutOrderDetail, setAboutOrderDetail] = useState({});

    const setOrePropsValue = () => {
        setLoader(true);
        setAboutOrderDetail(orderDetails);
        setLoader(false);
    }

    useEffect(() => {
        setOrePropsValue();
    }, [props.orderDetails]);

    return (
        <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
            {/* <TabTitle title="User Profile" /> */}

            {loader
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null}
            <div className="row text-center">



                <div className="col-xl-12 col-lg-12 mb-5 col-md-12">
                    <div className="row justify-content-between">

                        <div className="column col-md-5" /* style={{ borderRadius: "25px", border: "1px solid #f1edf7", marginRight: '10px' }} */>
                            <h4>Shipping Address</h4>


                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Customer Name:</b>
                                    {aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.fullName ? aboutOrderDetail.shippingAddress.fullName : ''}</h5>
                            </div>


                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Mobile Number:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.mobileNo ? aboutOrderDetail.shippingAddress.mobileNo : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Email:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.email ? aboutOrderDetail.shippingAddress.email : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Pincode:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.pincode ? aboutOrderDetail.shippingAddress.pincode : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Flat, House No., Building, Company, Apartment:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.address_1 ? aboutOrderDetail.shippingAddress.address_1 : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Area, Colony, Street, Sector, Village:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.address_2 ? aboutOrderDetail.shippingAddress.address_2 : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Landmark:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.address_3 ? aboutOrderDetail.shippingAddress.address_3 : ''}</h5>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>City:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.city ? aboutOrderDetail.shippingAddress.city : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>State:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.state ? aboutOrderDetail.shippingAddress.state : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Country:</b>{aboutOrderDetail.shippingAddress && aboutOrderDetail.shippingAddress.country ? aboutOrderDetail.shippingAddress.country : ''}</h5>
                            </div>
                        </div>
                        <div className="column col-md-5" /* style={{ borderRadius: "25px", border: "1px solid #f1edf7", marginRight: '10px' }} */>
                            <h4>Billing Address</h4>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Customer Name:</b>
                                    {aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.fullName ? aboutOrderDetail.billingAddress.fullName : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Mobile Number:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.mobileNo ? aboutOrderDetail.billingAddress.mobileNo : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Email:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.email ? aboutOrderDetail.billingAddress.email : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Pincode:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.pincode ? aboutOrderDetail.billingAddress.pincode : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Flat, House No., Building, Company, Apartment:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.address_1 ? aboutOrderDetail.billingAddress.address_1 : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Area, Colony, Street, Sector, Village:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.address_2 ? aboutOrderDetail.billingAddress.address_2 : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Landmark:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.address_3 ? aboutOrderDetail.billingAddress.address_3 : ''}</h5>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>City:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.city ? aboutOrderDetail.billingAddress.city : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>State:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.state ? aboutOrderDetail.billingAddress.state : ''}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <h5 className={desingCLass}><b>Country:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.country ? aboutOrderDetail.billingAddress.country : ''}</h5>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderInfo;