import React, { Component } from 'react';
import HOC from '../../HOC';
import { ResetPassword } from '../../config/services/changePasswordService';
import Popup from "reactjs-popup"

class ChangePassword extends Component {
    constructor(props) {
        super()
        this.state = {
            oldPassword: '',
            newPassword: '',
            reEnterPassword: '',
            error: false,
            blankError: false,
            isShowPassword: false,
            isShowPassword2: false,
            isShowPassword3: false,
            sucsessPopup: false,
            resetPasswordMsg: 'Password changed successfully'
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.ShowPassword = this.ShowPassword.bind(this);
        this.ShowPassword2 = this.ShowPassword2.bind(this);
        this.ShowPassword3 = this.ShowPassword3.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    ShowPassword() { this.setState({ isShowPassword: !this.state.isShowPassword }) }
    ShowPassword2() { this.setState({ isShowPassword2: !this.state.isShowPassword2 }) }
    ShowPassword3() { this.setState({ isShowPassword3: !this.state.isShowPassword3 }) }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            blankError: false
        }, () => {
            if (this.state.newPassword !== this.state.reEnterPassword) {
                this.setState({ error: true })
            } else { this.setState({ error: false }) }
        });

    }

    closeModal() {
        this.setState({ sucsessPopup: false,  newPassword:'', oldPassword:'', reEnterPassword:'' });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { newPassword, oldPassword, reEnterPassword } = this.state

        if (newPassword ==''|| oldPassword==''||reEnterPassword=='') {
            this.setState({ sucsessPopup: true, resetPasswordMsg: 'All fields are mandatory' })
        } else if (newPassword === oldPassword) {
            this.setState({ sucsessPopup: true, resetPasswordMsg: 'New password and Old password cannot be same' })
        } else if ((newPassword && oldPassword) && (newPassword === reEnterPassword)) {
            let obj = {
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword
            }
            ResetPassword.Post(obj)
                .then(res => {
                    if (res.data.statusCode === 1) {
                        this.setState({ sucsessPopup: true, resetPasswordMsg: 'Password changed successfully', blankError: false })
                    }
                    else {
                        this.setState({ resetPasswordMsg: res.data.error.errorMessage, sucsessPopup: true })
                    }
                })
        } else {
            this.setState({ blankError: true })
        }
    }






    render() {
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <h3 className="text-center mb-5">Change Password</h3>
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4 cm_show_pass cm_show_pass_inner">
                                        <label htmlFor="oldPassword" className="col-form-label">Current Password</label>
                                        <input
                                            type="password"
                                            name="oldPassword"
                                            className={`form-control ${this.state.blankError ? 'is-invalid' : ''}`}
                                            onChange={(event) => this.handleChange(event)}
                                            value={this.state.oldPassword}
                                            type={this.state.isShowPassword ? 'text' : 'password'}
                                        />
                                        <i
                                            className={`fa fa-eye${this.state.isShowPassword ? '' : '-slash'}`}
                                            onClick={this.ShowPassword}
                                            aria-hidden="true"></i>
                                    </div>

                                    <div className="form-group mb-4 cm_show_pass cm_show_pass_inner">
                                        <label htmlFor="newPassword" className="col-form-label">New Password</label>
                                        <input
                                            type={this.state.isShowPassword2 ? 'text' : 'password'}
                                            name="newPassword"
                                            className={`form-control ${this.state.blankError ? 'is-invalid' : ''}`}
                                            onChange={(event) => this.handleChange(event)}
                                            value={this.state.newPassword}
                                        />
                                        <i
                                            className={`fa fa-eye${this.state.isShowPassword2 ? '' : '-slash'}`}
                                            onClick={this.ShowPassword2}
                                            aria-hidden="true"></i>

                                        {this.state.error ? <span className="cm_error">Confirm password doesn't match</span> : null}
                                    </div>

                                    <div className="form-group mb-5 cm_show_pass cm_show_pass_inner">
                                        <label htmlFor="staticEmail" className="col-form-label">Confirm Password</label>
                                        <input
                                            type={this.state.isShowPassword3 ? 'text' : 'password'}
                                            className={`form-control ${this.state.blankError ? 'is-invalid' : ''}`}
                                            onChange={(event) => this.handleChange(event)}
                                            name="reEnterPassword"
                                            value={this.state.reEnterPassword}
                                        />
                                        <i
                                            className={`fa fa-eye${this.state.isShowPassword3 ? '' : '-slash'}`}
                                            onClick={this.ShowPassword3}
                                            aria-hidden="true"></i>

                                        {this.state.error ? <span className="cm_error">Confirm password doesn't match</span> : null}
                                    </div>



                                    <div className="form-group mb-4">
                                        <button type="submit" className="btn btn-primary btn-block">
                                            Save
                                {!this.state.blankError ? null : <span className="cm_error">All input fields are require</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Popup
                    open={this.state.sucsessPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                </span>
                        <h3 className="text-center">{this.state.resetPasswordMsg}</h3>

                    </div>
                </Popup>
            </HOC>
        );
    }
}

export default ChangePassword;