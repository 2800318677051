import React, { Component } from 'react';
import { BackArrow, DashGlass } from '../../../assets/SvgIcon';
import { Progress, Tabs, DatePicker } from 'antd';
import HOC from '../../../HOC';
import { waterIntakeGraph } from '../../../config/services/usersService';
import { toast } from 'react-toastify';
import moment from 'moment';
import Chart from "react-apexcharts";

class WaterIntakeGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedGraphType: 2,
            dateFrom: moment().startOf('month').valueOf(),
            dateTo: moment().endOf('month').valueOf(),
            currentDate: moment().valueOf(),
            userId: this.props.userId,
            graphData: {
                weeklyData: [],
                countData: []
            },
            target: {},
            totalWaterGlasses: 0,
            maxValue: 0,
        }
    }


    componentDidMount() {
        this.getWaterIntakeGraph()
    }


    getWaterIntakeGraph = () => {
        let { selectedGraphType, dateFrom, dateTo, userId } = this.state
        let params = {
            dateFrom,
            dateTo,
            requestUserId: userId,
            graphType: selectedGraphType
        }

        waterIntakeGraph(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let maxValue = Math.max(...response.responseData.resp.countData)
                    let totalWaterGlasses = response.responseData.resp.countData.reduce((a, b) => a + b)

                    this.setState({
                        graphData: response.responseData.resp,
                        target: response.responseData.target,
                        maxValue,
                        totalWaterGlasses,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    handleDateChange = (date, dateType) => {
        let dateFrom = moment(date).startOf(dateType).valueOf()
        let dateTo = moment(date).endOf(dateType).valueOf()
        let currentDate = moment(date).valueOf()

        this.setState({ dateFrom, dateTo, currentDate }, () => { this.getWaterIntakeGraph() })
    }

    handleGraphType = (graphType) => {
        let dateFrom
        let dateTo

        switch (parseInt(graphType)) {
            case 0:
                dateFrom = moment().startOf('day').valueOf()
                dateTo = moment().endOf('day').valueOf()
                break;
            case 1:
                dateFrom = moment().startOf('week').valueOf()
                dateTo = moment().endOf('week').valueOf()
                break;
            case 2:
                dateFrom = moment().startOf('month').valueOf()
                dateTo = moment().endOf('month').valueOf()
                break;
        }

        this.setState({ selectedGraphType: parseInt(graphType), dateFrom, dateTo }, () => { this.getWaterIntakeGraph() })
    }

    render() {
        let { selectedGraphType, graphData, target, totalWaterGlasses, currentDate, maxValue } = this.state

        const data = {
            labels: graphData.weeklyData,
            datasets: [
                {
                    label: 'Water Intake',
                    backgroundColor: '#16D8E0',
                    borderColor: '#16D8E0',
                    borderWidth: 2,
                    data: graphData.countData
                }
            ]
        }


        let options = {
            colors: ["#1BC8D0"],
            chart: {
                id: "Weight-Bar",
                stacked: true,
                stackType: "70%",
            },
            xaxis: {
                categories: graphData.weeklyData
            }
        }

        let series = [
            {
                name: "Weight",
                data: graphData.countData
            }
        ]

        return (
            <HOC>
                <div className="graph-module">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-xl-3">
                            <div className="tab-group flex-column d-flex align-items-baseline justify-content-between">
                                <div className="filter-tabs w-100">
                                    <Tabs defaultActiveKey={selectedGraphType} onChange={this.handleGraphType}>
                                        <Tabs.TabPane
                                            tab={<span>Month</span>}
                                            key={2}
                                        >
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={<span>Week</span>}
                                            key={1}
                                        >
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={<span>Day</span>}
                                            key={0}
                                        >
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                                <div className="tabs-date-picker w-100">
                                    {selectedGraphType == 2 && <DatePicker.MonthPicker allowClear={false} className="form-control" placeholder="Select Month" format='MMM YYYY' onChange={(val) => this.handleDateChange(val, 'month')}
                                        defaultValue={moment(new Date(currentDate), 'MMM YYYY')} />}

                                    {selectedGraphType == 1 && <DatePicker.WeekPicker allowClear={false} className="form-control" placeholder="Select Week" onChange={(val) => this.handleDateChange(val, 'week')}
                                        defaultValue={moment(new Date(currentDate))} />}

                                    {selectedGraphType == 0 && <DatePicker allowClear={false} className="form-control" placeholder="Select date" format='Do MMM YYYY' onChange={(val) => this.handleDateChange(val, 'day')}
                                        defaultValue={moment(new Date(currentDate), 'Do MMM YYYY')} />}
                                </div>
                                <div className="user-intake-detail d-block w-100">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Total Drink Glasses
                                                </td>
                                                <td>   
                                                    {totalWaterGlasses} <small></small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Target Glasses</td>
                                                <td>{target.waterIntake}</td>
                                            </tr>
                                            <tr>
                                                <td>Left to Dring</td>
                                                <td>{target.waterIntake && ((target.waterIntake - totalWaterGlasses) < 0 ? 0 : target.waterIntake - totalWaterGlasses)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <div className="progress-section text-center">
                                        <p>{totalWaterGlasses} <small>Glasses</small></p>
                                        <div className="postion-rel">
                                            <Progress className="graph-progress-custom" type="circle" percent={30} width={30} />
                                            <span className="progress-inner-icon">
                                                <DashGlass />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="left-drink text-center mt-4 mb-5">
                                        <p>Target: {target.waterIntake} Glasses</p>
                                        <p>Glasses left to drink which is</p>
                                        <div className="circle">
                                            <div className="center-section d-flex justify-content-around align-items-center">
                                                <span><DashGlass /></span>
                                                <span >✖</span>
                                                <p className="mb-0"><b>{target.waterIntake && ((target.waterIntake - totalWaterGlasses) < 0 ? 0 : target.waterIntake - totalWaterGlasses)}</b></p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 ml-auto">
                            <div className="text-center">
                                <div className="heading text-left">
                                    <h5>Water Intake</h5>
                                </div>
                                <div className="graph-wrap">
                                    <Chart
                                        options={options}
                                        series={series}
                                        type="bar"
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            
            </HOC >

        );
    }
}




export default WaterIntakeGraph;