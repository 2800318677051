import React, { Component } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

export default class PopUpModal extends Component {

    render() {
        let { isOpen, closeModal, message, validationError, submit, submitTitle, children } = this.props
        return (<Modal show={isOpen} onHide={closeModal} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title><h6>{message}</h6> </Modal.Title>
            </Modal.Header>
            {validationError && <Alert variant="danger"> {validationError} </Alert>}
            {children && <Modal.Body>{children}</Modal.Body>}
            <Modal.Footer>
                <Button variant="danger" onClick={closeModal} size="sm">Cancel</Button>
                <Button variant="success" disabled={validationError} onClick={submit} size="sm"> {submitTitle ? submitTitle : "Assign" } </Button>
            </Modal.Footer>
        </Modal>)
    }
}