import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Button, Modal, FormControl, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import Select from 'react-select';
import { gymTypes } from './constants';
import { getAllStates, getAllCitys, getAllPincodes } from '../../config/services/servicePincodes';
import { addGymManager, getGymManagers, addGym, editGym } from '../../config/services/commercialService';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';

const AddGym = () => {
    const location = useLocation();
    let gymNameParam, companyNameParam, gymTypeParam, addressParam, pincodeParam, pincodesListParam, stateParam, cityParam, cityListParam, selectedGymManagersParam;
    if(location.state) {
        gymNameParam = location.state.gymNameParam
        companyNameParam = location.state.companyNameParam
        gymTypeParam = location.state.gymTypeParam
        addressParam = location.state.addressParam
        pincodeParam = location.state.pincodeParam
        pincodesListParam = location.state.pincodesListParam
        stateParam = location.state.stateParam
        cityParam = location.state.cityParam
        cityListParam = location.state.cityListParam
        selectedGymManagersParam = location.state.selectedGymManagersParam 
    }

    const [gymName, setGymName] = useState(gymNameParam || "");
    const [companyName, setCompanyName] = useState(companyNameParam || "");
    const [gymType, setGymType] = useState(gymTypeParam || "");
    const [address, setAddress] = useState(addressParam || "");
    const [pincode, setPincode] = useState(pincodeParam || "");
    const [pincodesList, setPincodesList] = useState(pincodesListParam || []);
    const [state, setState] = useState(stateParam || "");
    const [statesList, setStatesList] = useState([]);
    const [city, setCity] = useState(cityParam || "");
    const [cityList, setCityList] = useState(cityListParam || []);
    const [showAddGymManagerModal, setShowAddGymManagerModal] = useState(false);
    const [gymManagerName, setGymManagerName] = useState("");
    const [gymManagerMobileNo, setGymManagerMobileNo] = useState("");
    const [gymManagerEmail, setGymManagerEmail] = useState("");
    const [gymManagersList, setGymManagersList] = useState([]);
    const [selectedGymManagers, setSelectedGymManagers] = useState(selectedGymManagersParam || []);
    const history = useHistory();

    useEffect(() => {
        fetchStates();
        fetchGymManagers();
    }, []);

    const fetchStates = () => {
        const params = { search: '', sortField: "_id", sortType: 1, pageNo: 0, count: 1000 };
        getAllStates({ params })
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    let stateArray = [];
                    if (data.result && data.result.length > 0) {
                        data.result.forEach(key => {
                            stateArray.push({
                                label: key._id,
                                value: key._id,
                            })
                        })
                    }
                    setStatesList(stateArray);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const fetchCities = (selectedState) => {
        const params = { sortField: "_id", sortType: 1, pageNo: 0, count: 2000, states: selectedState, citySearch: '' };
        getAllCitys({ params })
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    let cityArray = [];
                    if (data.result && data.result.length > 0) {
                        data.result.forEach(key => {
                            cityArray.push({
                                label: key._id,
                                value: key._id,
                            })
                        })
                    }
                    setCityList(cityArray);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const fetchPincodes = (value) => {
        const params = { sortField: "_id", sortType: 1, pageNo: 0, count: 2000, stateSearch: state.value, citySearch: value };
        
        getAllPincodes({ params })
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData[0];
                    let pincodeArray = [];
                    if (data.result && data.result.length > 0) {
                        data.result.forEach(key => {
                            pincodeArray.push({
                                label: key.pincode,
                                value: key.pincode,
                            })
                        })
                    }
                    setPincodesList(pincodeArray);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const fetchStateAndCity = (pincode) => {
        if (pincode.length <=5) return;

        const params = { sortField: "_id", sortType: 1, pageNo: 0, count: 2000, pincodeSearch: pincode };
        getAllPincodes({ params })
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData[0];
                    if (data.result && data.result.length > 0) {
                        const obj = data.result[0];
                        setState({label: obj.state, value: obj.state});

                        const cityObj = {label: obj.city, value: obj.city};
                        let cityArray = [];
                        cityArray.push(cityObj);
                        setCity(cityObj);
                        setCityList(cityArray);

                        const pincodeObj = {label: obj.pincode, value: obj.pincode};
                        let pincodeArray = [];
                        pincodeArray.push(pincodeObj);
                        setPincode(pincodeObj);
                        setPincodesList(pincodeArray);

                        return pincodeArray;
                    }
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const fetchGymManagers = () => {
        const params = { pageNo: 0, count: 1000 };
        getGymManagers({ params })
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    let gymManagersArray = [];
                    if (data.gymManagers && data.gymManagers.length > 0) {
                        data.gymManagers.forEach(key => {
                            gymManagersArray.push({
                                label: key.firstName,
                                value: key._id
                            })
                        })
                    }
                    setGymManagersList(gymManagersArray);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handleStateChange = (event) => {
        city && setCity("");
        cityList.length > 0 && setCityList([]);
        pincode && setPincode("");
        pincodesList.length > 0 && setPincodesList([]);
        setState(event);
        fetchCities(event.value);
    };

    const handleCityChange = (event) => {
        pincode && setPincode("");
        pincodesList.length > 0 && setPincodesList([]);
        setCity(event);
        fetchPincodes(event.value);
    };

    const handlePincodeChange = (event) => {
        setPincode(event);
    };

    const hideModal = () => setShowAddGymManagerModal(false);

    const handleGymValidation = () => {
        let emptyFields = "";
        if(!gymType) emptyFields = "Gym Type";
        if(!pincode) emptyFields = emptyFields ? emptyFields + ", Pincode" : "Pincode";
        if(selectedGymManagers.length === 0) emptyFields = emptyFields ? emptyFields + ", Gym Manager" : "Gym Manager";

        if(!emptyFields) return true;
        toast.error(`Please select ${emptyFields}`);
        return false;
    };

    const handleGymSubmission = (event) => {
        event.preventDefault();

        const gymValidationPass = handleGymValidation();
        if(gymValidationPass) {
            const managerIds = selectedGymManagers.map((manager) => manager.value);
            let params = {
                name: gymName,
                companyName: companyName,
                type: gymType.value,
                streetAddr: address,
                pincode: pincode.value,
                managers: managerIds
            };

            if(location.pathname === "/editGym"){
                if(location.state)
                    params = {...params, id: location.state.gymIdParam};

                editGym(params)
                    .then(res => {
                        const response = res.data;
        
                        if (response.statusCode == 1) {
                            toast.success(response.responseData.message);
                            history.push("/gyms");
                        } else if (response.statusCode == 0) {
                            toast.error(response.error.errorMessage);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
            } else {
                addGym(params)
                    .then(res => {
                        const response = res.data;
        
                        if (response.statusCode == 1) {
                            toast.success(response.responseData.message);
                            history.push("/gyms");
                        } else if (response.statusCode == 0) {
                            toast.error(response.error.errorMessage);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
            }
        }
    };

    const handleGymManagerSubmission = (event) => {
        event.preventDefault();
        const params = {
            firstName: gymManagerName,
            mobileNo: gymManagerMobileNo,
            email: gymManagerEmail,
            countryCode: 91
        };

        addGymManager(params)
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    setGymManagersList([...gymManagersList, {label: response.responseData.result.firstName, value: response.responseData.result._id}])
                    setSelectedGymManagers([...selectedGymManagers, {label: response.responseData.result.firstName, value: response.responseData.result._id}]);
                    toast.success(response.responseData.message);
                    hideModal();

                    setGymManagerName("");
                    setGymManagerMobileNo("");
                    setGymManagerEmail("");
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage);
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    return (
        <div className="main-wrapper">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to="/gyms">Gyms</Link></li>
                <li className="breadcrumb-item">{location.pathname === "/editGym" ? "Edit Gym" : "Add Gym"}</li>
            </ol>

            <Form onSubmit={(e) => handleGymSubmission(e)}>
                <div className='row'>
                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>Gym Name</FormLabel>
                        <FormControl
                            value={gymName}
                            name="gymName"
                            onChange={(e) => {setGymName(e.target.value)}}
                            required
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>Company Name</FormLabel>
                        <FormControl
                            value={companyName}
                            name="gymName"
                            onChange={(e) => {setCompanyName(e.target.value)}}
                            required
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>Gym Type</FormLabel>
                        <Select
                            onChange={(e) => setGymType(e)}
                            options={gymTypes}
                            value={gymType ? gymType : ""}
                        />
                    </FormGroup>
                </div>

                <div className='row'>
                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>Address</FormLabel>
                        <FormControl
                            value={address}
                            name="address"
                            onChange={(e) => {setAddress(e.target.value)}}
                            required
                            type="text"
                            placeholder="Address"
                        />
                    </FormGroup>

                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>State</FormLabel>
                        <Select
                            value={state ? state : ""}
                            onChange={(e) => handleStateChange(e)}  
                            options={statesList}
                            placeholder="Select State"
                        />
                    </FormGroup>
                </div>

                <div className='row'>
                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>City</FormLabel>
                        <Select
                            value={city ? city : ""}
                            onChange={(e) => handleCityChange(e)}
                            options={cityList}
                            placeholder="Select City"
                        />
                    </FormGroup>

                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>Pincode</FormLabel>
                        <AsyncSelect
                            isSearchable
                            isDisabled={false}
                            value={pincode ? pincode : ""}
                            defaultOptions={pincodesList}
                            onChange={(e) => handlePincodeChange(e)}
                            loadOptions={(e) => fetchStateAndCity(e)}
                            placeholder="Filter By Pincode"
                        />
                    </FormGroup>
                </div>

                <div className='row'>
                    <FormGroup className='col-lg-6 col-xl-4'>
                        <FormLabel>Choose Gym Manager</FormLabel>
                        <Select
                            isMulti
                            name="gymManagers"
                            options={gymManagersList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => setSelectedGymManagers(e)}
                            value={selectedGymManagers}
                        />
                        <div className='new-gym-manager-button' onClick={() => {setShowAddGymManagerModal(true)}}>+ New Gym Manager</div>
                    </FormGroup>
                </div>

                <Row className="justify-content-center">
                    <Button className="ml-2" variant="success" type="submit">Submit</Button>
                </Row>
            </Form>

            {/* Add Gym Manager Modal */}
            <Modal show={showAddGymManagerModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Gym Manager</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <Form onSubmit={(e) => handleGymManagerSubmission(e)}>
                        <div className='row'>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Name</FormLabel>
                                <FormControl
                                    value={gymManagerName}
                                    name="name"
                                    onChange={(e) => { setGymManagerName(e.target.value) }}
                                    required
                                    type="text"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Contact No.</FormLabel>
                                <FormControl
                                    value={gymManagerMobileNo}
                                    name="contactNo"
                                    onChange={(e) => { setGymManagerMobileNo(e.target.value) }}
                                    required
                                    type="number"
                                />
                            </FormGroup>
                        </div>

                        <div className='row'>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Email</FormLabel>
                                <FormControl
                                    value={gymManagerEmail}
                                    name="email"
                                    onChange={(e) => { setGymManagerEmail(e.target.value) }}
                                    required
                                    type="email"
                                />
                            </FormGroup>
                        </div>

                        <Row className="justify-content-center">
                            <Button variant="danger" onClick={hideModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit">Submit</Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddGym;