import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getWarehouseList, addGymEquipmentLogistics } from '../../config/services/commercialService';
import { Button, Modal, FormControl, Form, Row, FormGroup, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';

const RequestInstallationModal = (props) => {
    const { showModal, hideModal, gymId, fetchGymEquipments, gymEquipmentsList } = props;
    const initialArray = new Array(gymEquipmentsList.length).fill(false);
    const [selectedGymEqps, setSelectedGymEqps] = useState(initialArray);
    const [selectAllFlag, setSelectAllFlag] = useState(true);
    const [WHList, setWHList] = useState("");
    const [dispatchReferenceNo, setDispatchReferenceNo] = useState("");
    const [vehicleNo, setVehicleNo] = useState("");
    const [driverContactNo, setDriverContactNo] = useState("");
    const [dispatchWH, setDispatchWH] = useState("");
    const [deliveryETA, setDeliveryETA] = useState("");
    const [notes, setNotes] = useState("");

    useEffect(() => {
        fetchWarehouseList();
    }, []);

    const fetchWarehouseList = () => {
        getWarehouseList()
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    const list = data.result ? data.result.map((obj) => ({label: obj.name, value: obj._id})) : [];
                    setWHList(list);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handleValidation = () => {
        let flag = false;
        for(let i=0; i<selectedGymEqps.length; i++){
            if(selectedGymEqps[i]){
                flag=true;
                break;
            }
        }

        if(!flag){
            toast.error("Please select atleast 1 equipment")
        }

        return flag;
    };

    const formSubmission = (event) => {
        event.preventDefault();

        const validationFlag = handleValidation();
        if(validationFlag) {
            let equipments = [];
            for(let i=0; i<selectedGymEqps.length; i++) {
                if(selectedGymEqps[i]){
                    equipments.push({equipmentId: gymEquipmentsList[i]._id})
                }
            }

            const params = {
                gymId: gymId,
                equipments: JSON.stringify(equipments),
                dispatchReferenceNo: dispatchReferenceNo
            };

            if(vehicleNo) params.vehicleNo = vehicleNo;
            if(driverContactNo) params.driverContactNo = driverContactNo;
            if(dispatchWH && dispatchWH.value) params.warehouseId = dispatchWH.value;
            if(deliveryETA) params.deliveryETA = moment(deliveryETA).format('MM-DD-YYYY');
            if(notes) params.notes = notes;

            addGymEquipmentLogistics(params)
                .then(res => {
                    const response = res.data;

                    if (response.statusCode == 1) {
                        const data = response.responseData;
                        fetchGymEquipments();
                        toast.success(data.message);
                        hideModal();
                    } else if (response.statusCode == 0) {
                        toast.error(response.error.errorMessage)
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                })
        }
    };

    const handleEqpSelection = (index) => {
        const data = [...selectedGymEqps];
        data[index] = !data[index];
        setSelectedGymEqps(data);
    };

    const selectAllEqpFn = () => {
        const data = new Array(gymEquipmentsList.length).fill(selectAllFlag);
        setSelectAllFlag(!selectAllFlag);
        setSelectedGymEqps(data);
    };

    return (
        <div className="main-wrapper">
            <Modal show={showModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Raise Installation Request</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <p>Please choose the items you want to send installation request for (Please create requests for diferent delivery shipments separately):</p>
                    <Button type="primary" onClick={selectAllEqpFn}>Select All Equipments</Button>
                    <Form onSubmit={(e) => formSubmission(e)}>
                        <div className="table-responsive mb-4" style={{ maxHeight: "200px", marginTop: "30px" }}>
                            <table className="table table-bordered table-striped text-center" style={{ marginBottom: "30px" }}>
                                <thead>
                                    <tr>
                                        <th scope="col"> <input className="checkboxHandle" type='checkbox' onClick={selectAllEqpFn} />S.No.</th>
                                        <th scope="col">Serial No.</th>
                                        <th scope="col">Model</th>
                                        <th scope="col">Brand</th>
                                        <th scope="col">Product type</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {gymEquipmentsList.map((gymEqp, index) => {
                                        return (
                                            <tr key={gymEqp._id}>
                                                <td className="cm_no_wrap">
                                                    <input type='checkbox' checked={selectedGymEqps[index]} onChange={() => handleEqpSelection(index)} /> {index + 1}
                                                </td>
                                                <td className="cm_no_wrap"> {gymEqp.serialNo}</td>
                                                <td className="cm_no_wrap">{gymEqp.equipmentDetails && gymEqp.equipmentDetails.model}</td>
                                                <td className="cm_no_wrap">{gymEqp.equipmentDetails && gymEqp.equipmentDetails.brand}</td>
                                                <td className="cm_no_wrap">{gymEqp.equipmentDetails && gymEqp.equipmentDetails.vertical && gymEqp.equipmentDetails.vertical.verticalName}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Dispatch Reference Number</FormLabel>
                                <FormControl
                                    value={dispatchReferenceNo}
                                    name="dispatchReferenceNo"
                                    onChange={(e) => { setDispatchReferenceNo(e.target.value) }}
                                    required
                                    type="text"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Vehicle Number</FormLabel>
                                <FormControl
                                    value={vehicleNo}
                                    name="vehicleNo"
                                    onChange={(e) => { setVehicleNo(e.target.value) }}
                                    type="text"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Driver Contact Number</FormLabel>
                                <FormControl
                                    value={driverContactNo}
                                    name="driverContactNo"
                                    onChange={(e) => { setDriverContactNo(e.target.value) }}
                                    type="number"
                                />
                            </FormGroup>
                        </div>

                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Dispatch Warehouse</FormLabel>
                                <Select
                                    onChange={(e) => setDispatchWH(e)}
                                    options={WHList}
                                    value={dispatchWH ? dispatchWH : ""}
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Delivery ETA</FormLabel>
                                <FormControl
                                    value={deliveryETA}
                                    name="deliveryETA"
                                    onChange={(e) => setDeliveryETA(e.target.value)}
                                    type="date"
                                />
                            </FormGroup>
                        </div>

                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Notes</FormLabel>
                                <FormControl
                                    value={notes}
                                    name="notes"
                                    onChange={(e) => setNotes(e.target.value)}
                                    as="textarea"
                                    rows="4"
                                    cols="120"
                                />
                            </FormGroup>
                        </div>

                        <Row className="justify-content-center" style={{marginTop: "30px"}}>
                            <Button variant="danger" onClick={hideModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit">Submit</Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default RequestInstallationModal;