import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageSmile from './../../assets/images/smile.svg';
import moment from "moment";
import { ReactComponent as ViewIcon } from './../../assets/images/view.svg'
import { ReactComponent as EditIcon } from './../../assets/images/edit.svg'
import AddEditEmoji from './AddEditEmoji';
import DeleteConfirmation from './../common/DeletePopup';
import { AddEmoji, DeleteEmoji, EditEmoji, GetEmoji } from '../../config/services/emojiService';
import { Select } from 'antd';
import { GET } from '../../config/services/adminService';
import { notification, Space } from 'antd';
import { LoaderSvg } from '../Reusable';
import { Pagination } from 'antd';
import SearchSpinner from '../common/SearchSpinner';
const Emoji = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [modalTitle, setModalTitle] = useState("")
    const [refresh, setRefresh] = useState(false);
    const [deleteItem, setdeleteItem] = useState(null);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [perPageItem, setperPageItem] = useState(5);
    const [sortType, setSorttype] = useState(1);
    const [sortField, setsortField] = useState("displayOrder");
    const [searching, setSearching] = useState(false);
    const [searchName, setSearchName] = useState(null);
    const [searchPlatform, setSearchPlatform,] = useState(null);
    const { Option } = Select;
    const defaultValues = {
        emojiName: "",
        emojiPlatform: "1",
        emojiImageUrl: "",
        emojiImageName: "Upload Image",
        edit: false,
        displayOrder: 0

    }
    const [defaultValuesPopup, setdefaultValuesPopup] = useState(defaultValues)

    const [emojis, setEmojis] = useState([]);
    const [loader, setLoader] = useState(false);
    const showModal = (type, editData) => {
        if (type === "Add") {
            setdefaultValuesPopup(defaultValues);
            setModalTitle("Add Emoji");


        } else {
            if (editData.displayOrder) {
                setdefaultValuesPopup({ ...editData, edit: true });

            }
            else {
                setdefaultValuesPopup({ ...editData, displayOrder: 0, edit: true });

            }
            setModalTitle(editData.emojiName);


        }
        setIsModalVisible(true);

    };
    const handleCancel = () => {
        setdefaultValuesPopup(defaultValues);
        setIsModalVisible(false);
    };

    const DeleteHandle = (confirmation) => {
        if (confirmation) {
            DeleteEmoji(deleteItem).then(async data => {
                setDeleteConfirm(false);
                setRefresh(!refresh);
                await openDeleteNotification("warning");
            })

        }
        else {
            setDeleteConfirm(confirmation)

        }
    }

    const DeleteOpen = (item) => {
        setModalTitle(item.emojiName);
        setDeleteConfirm(true);
        setdeleteItem(item._id)
    }



    const getPlatform = (key) => {
        switch (key) {
            case 1:
                return "OneFitPlus App"

            case 2:
                return "OneFitPlus Web"

            case 3:
                return "Fitwarz"


            default:
                break;
        }

    }

    let upload = (state) => {
        const formData = new FormData();
        formData.append('emojiName', state.emojiName);
        formData.append('emojiImage', state.emojiImage);
        formData.append('emojiPlatform', state.emojiPlatform);
        formData.append('displayOrder',state.displayOrder);
        
        return AddEmoji(formData).then(data => {
            setRefresh(!refresh);
            setIsModalVisible(false);
            setdefaultValuesPopup(defaultValues);
            return data;

        })
    }


    let editData = (state) => {

        const formData = new FormData();
        ////console.log(state);
        formData.append('emojiId', state.emojiId);
        formData.append('emojiName', state.emojiName);
        formData.append('emojiImage', state.emojiImage);
        formData.append('emojiPlatform', state.emojiPlatform);
        formData.append('displayOrder', Number(state.displayOrder))
        return EditEmoji(formData).then(data => {
            setRefresh(!refresh);
            setIsModalVisible(false);
            setdefaultValuesPopup(defaultValues);
            return data;

        })

    }

    const paginate = (current, item) => {
        if (current > 0) {
            setPage(current - 1);
        }
        else {
            setPage(current);
        }



    }

    const handleSearch = (event) => {
        setSearching(true);
        setSearchName(event.target.value);
        let params = { pageNo: page, count: perPageItem, sortType: sortType, sortField: sortField, search: event.target.value, emojiPlatform: searchPlatform }

        GetemojiList(params, page);
    }

    const GetemojiList = (params, page) => {

        GetEmoji(params).then(data => {
            if (data["data"].responseData) {
                setEmojis(data["data"].responseData.result.emojiList);
                setLoader(false);
                setPage(page);
                setTotalCount(data["data"].responseData.result.totalCount)
                setSearching(false);


            }

        })
    }
    useEffect(() => {
        setLoader(true);
        let params = { pageNo: page, count: perPageItem, sortType: sortType, sortField: sortField, emojiPlatform: searchPlatform, search: searchName }

        GetemojiList(params, page);

    }, [refresh, page, perPageItem, sortType, sortField])

    const openDeleteNotification = type => {
        notification[type]({
            message: 'Delete Emoji',
            description:
                'Emoji deleted successfully !',
        });
    };

    let onShowSizeChange = (current, pageSize) => {

        setperPageItem(pageSize);
        setPage(current);

    }

    let handleSortList = (data) => {
        setSorttype(-1 * sortType);
        setsortField(data);

    };

    // pagination function
    const itemRender = (current, type, originalElement) => {

        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    const filterEmojiByPlatform = (value) => {
        setLoader(true);
        setSearchPlatform(value);
        let params = { pageNo: page, count: perPageItem, sortType: sortType, sortField: sortField, emojiPlatform: value, search: searchName }

        GetemojiList(params, page);
    }

    return (
        <div className="body-container-wrapper">
            {loader && <div className="loader_wrapper">
                <LoaderSvg />
            </div>}
            <div className="body-container">
                <div className="d-flex justify-content-between border-bottom custom-list mb-3">
                    <ol className="breadcrumb m-0 border-0 pl-0 breadcrumb-custom">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">Emoji</li>
                    </ol>
                </div>
                <div className="emoji-header justify-content-end d-flex align-items-center flex-column-reverse flex-md-row">
                    <div className="form-group search-platform mr-0 mr-md-3">

                        <Select defaultValue="OneFitPlus App" className="form-control" placeholder="Select Platform" onChange={filterEmojiByPlatform}>
                            <Option value="1">OneFitPlus App</Option>
                            <Option value="2">OneFitPlus Web</Option>
                            <Option value="3">Fitwarz</Option>
                        </Select>
                    </div>
                    <div className="form-group search-platform mr-0 mr-md-3">
                        <input type="text" className="form-control" placeholder="Search Name......" onChange={handleSearch} />
                        {searching ? <div className="search-spiner"><SearchSpinner type="text-info" /></div> : ""}
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary"
                            onClick={
                                () => showModal("Add")
                            }>
                            <i className="fa fa-plus"></i>
                            &nbsp; Add New
                        </button>
                    </div>
                </div>
                {/* List data */}
                <div className="table-responsive custom-table-view">
                    <table className="table table-bordered table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" style={{ cursor: 'pointer' }} onClick={() => {
                                    handleSortList("emojiName");
                                }}>
                                    Name
                                    &nbsp;

                                    {/* implement the Below Logic for sort */}

                                    {<i
                                        className={`fa fa-sort-alpha-${sortType == -1 ? "asc" : "desc"
                                            }`}
                                    ></i>}

                                </th>
                                <th scope="col">Emoji</th>
                                <th scope="col">Platform</th>
                                <th scope="col" style={{ cursor: 'pointer' }} onClick={() => {
                                    handleSortList("displayOrder");
                                }}>Display Order  &nbsp;<i className={`fa fa-sort-alpha-asc`}></i></th>

                                <th scope="col">Created Username</th>
                                <th scope="col">Edited Username</th>
                                <th scope="col" style={{ cursor: 'pointer' }} onClick={() => {
                                    handleSortList("created");
                                }} >
                                    Created At
                                    &nbsp;<i className={`fa fa-sort-alpha-asc`}></i>
                                </th>
                                <th scope="col">Edited At</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>{emojis.map((ele, i) => (<tr key={i}>
                            <td scope="col">{i + 1}</td>
                            <td scope="col">{ele.emojiName}</td>
                            <td scope="col"><img src={ele.emojiImageUrl} className="image-icon" /></td>
                            <td scope="col">{getPlatform(ele.emojiPlatform)}</td>
                            <td scope="col">{ele.displayOrder}</td>

                            <td scope="col">{ele.createdBy.firstName + ele.createdBy.lastName}</td>
                            <td scope="col">{ele.updatedBy ? ele.updatedBy.firstName + ele.updatedBy.lastName : "-"}</td>
                            <td scope="col">{moment(ele.created).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td scope="col">{moment(ele.updated).format("YYYY-MM-DD HH:mm:ss") === moment(ele.created).format("YYYY-MM-DD HH:mm:ss") ? "-" : moment(ele.updated).format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td scope="col" style={{ width: '100px' }}>
                                <div className="d-flex align-items-center justify-content-between" >
                                    {/* <Link to={{pathname: `/emoji/${ele._id}`, data: ele}}>
                                                <ViewIcon className="stream-action-icon cursor-pointer"/>
                                            </Link> */}
                                    <EditIcon onClick={
                                        () => showModal("edit", ele)
                                    }
                                        className="stream-action-icon cursor-pointer" />
                                    <i className="fa fa-trash text-danger stream-action-icon cursor-pointer"
                                        onClick={() => DeleteOpen(ele)}></i>
                                </div>
                            </td>
                        </tr>

                        ))
                        } </tbody>

                    </table>

                    {/* see the reference url - https://ant.design/components/pagination/ */}
                    <div className="text-center">
                        <Pagination
                            total={totalCount}
                            defaultCurrent={page}
                            defaultPageSize={perPageItem}
                            itemRender={itemRender}
                            onChange={paginate}
                            onShowSizeChange={onShowSizeChange} 
                            showSizeChanger
                            />
                    </div>
                </div>
            </div>
            {/* popup for add and edit emoji */}
            <AddEditEmoji title={modalTitle}
                isModalVisible={isModalVisible}
                handleCancel={handleCancel}
                upload={upload}
                editData={editData}
                defaultValues={defaultValuesPopup}
            /> {/* Delete Popup */}

            <DeleteConfirmation deleteTitle={modalTitle}
                isDeleteState={deleteConfirm}
                submit={DeleteHandle}
                cancel={DeleteHandle} />
        </div>
    );
}

export default Emoji;
