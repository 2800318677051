import React, { Component } from 'react';
import Routers from './Routers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import SocketContext,{sfx} from './config/contextapi/sfxconnectionContext';
toast.configure()
class App extends Component {
  render() {
    return (
     
      <MuiPickersUtilsProvider utils={MomentUtils}>
         <SocketContext.Provider value={sfx} >
            <Routers />
      </SocketContext.Provider>
       
      </MuiPickersUtilsProvider>)
      ;
  }
}

export default App;
