import React, { Component } from 'react';
import DeshbordChart from './DeshbordChart';

import HOC from '../../HOC';


class Dashboard extends Component {
  render() {
    return (
        <HOC>
           <div className="body-container-wrapper">
           <div className="body-container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">Dashboard</li>
              </ol>
               <DeshbordChart {...this.props} />
            </div>
           </div>
        </HOC>
    );
  }
}

export default Dashboard;
