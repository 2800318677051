import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";
import { addStation, getStationsListByAdmin, updateStation, deleteStation } from '../../config/services/stationsService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Menus } from '../template/Menus'
import { stat } from 'fs';


class Stations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            stationsList: [],
            stationName: '',
            lat: '',
            long: '',
            search: '',
            count: 10,
            totalStations: 15,
            pageNo: 1,
            sortType: '',
            sortField: '',
            AddStationPopup: false,
            deleteStationPopup: false,
            viewStationPopup: false,
            title: '',
            titleErrorMsg: '',
            updateStationPopup: false,
            customerServiceTypesId: ""
        }
    }


    componentDidMount() {
        this.getStationsListByAdmin();
    }


    getStationsListByAdmin = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        getStationsListByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        stationsList: response.responseData.result.getStations,
                        totalStations: response.responseData.result.stationsCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    openAddStationPopup = () => this.setState({ AddStationPopup: !this.state.AddStationPopup });

    closeModal = () => this.setState({
        AddStationPopup: false,
        customerServiceType: '',
        deleteStationPopup: false,
        updateStationPopup: false,
        stationId: '',
        stationName: '',
        lat: '',
        long: '',
        titleErrorMsg: ''
    });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, titleErrorMsg: '' })
    }


    handleSubmitStation = (e) => {
        e.preventDefault();
        let { stationName, lat, long } = this.state;
        let params = {
            stationName, lat, long
        }
        if (!stationName) {
            this.setState({ titleErrorMsg: 'Enter the station name' })
        } else if (!lat) {
            this.setState({ titleErrorMsg: 'Enter the lat' })
        } else if (!long) {
            this.setState({ titleErrorMsg: 'Enter the long' })
        } else {
            this.addStationApi(params)
        }
    }

    addStationApi = (params) => {
        addStation(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getStationsListByAdmin()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.closeModal()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    openDeleteStationPopup = (data, stations) => this.setState({ deleteStationPopup: !this.state.deleteStationPopup, stationId: stations._id });


    deleteStation = (e) => {
        e.preventDefault();
        const { stationId } = this.state
        let paramsObj = {
            stationId: stationId
        }
        deleteStation(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getStationsListByAdmin();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    openUpdateStationPopup = (data, stations) => {

        this.setState({
            updateStationPopup: true,
            stationId: stations._id,
            stationName: stations.stationName,
            lat: stations.lat,
            long: stations.long
        })
    }


    updateStationSubmit = (e) => {
        e.preventDefault();


        const { stationId, stationName, lat, long } = this.state
        let paramsObj = {
            stationId, stationName, lat, long
        }
        if (!stationName) {
            this.setState({ titleErrorMsg: 'Enter the station Name' })
        } else if (!lat) {
            this.setState({ titleErrorMsg: 'Enter the lat' })
        } else if (!long) {
            this.setState({ titleErrorMsg: 'Enter the long' })
        } else {
            this.setState({ titleErrorMsg: "" })
            this.updateStationApi(paramsObj)
        }
    }

    updateStationApi = (params) => {
        updateStation(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getStationsListByAdmin();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getStationsListByAdmin());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getStationsListByAdmin());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getStationsListByAdmin() });


    render() {
        const {
            loader,
            stationsList,
            search,
            AddStationPopup,
            titleErrorMsg,
            deleteStationPopup,
            viewStationPopup,
            pageNo,
            updateStationPopup,
            count,
            totalStations,
            stationName,
            lat,
            long
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Stations</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Stations</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by station name"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddStationPopup}>Add New Station</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('stationName')}>Station Name <i className={`fa fa-sort-alpha-asc`} />
                                        </th>
                                        <th scope="col">Lat</th>
                                        <th scope="col">Long</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        stationsList.map((stations, i) => {
                                            return (

                                                <tr key={stations._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">{stations.stationName}</span></td>
                                                    <td><span className="cm_table_three_dots">{stations.lat}</span></td>
                                                    <td><span className="cm_table_three_dots">{stations.long}</span></td>
                                                    <td><span className="cm_table_three_dots">{dateFormat(stations.created, "d mmmm yyyy")}</span></td>

                                                    <td className="cm_no_wrap">
                                                        
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openUpdateStationPopup(data, stations)} >Edit</button>

                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteStationPopup(data, stations)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalStations}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!stationsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={AddStationPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Station</h3>
                        <form onSubmit={this.handleSubmitStation}>
                            <div className="form-group">
                                <label>Station Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="stationName"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Lat</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="lat"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Long</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="long"
                                    onChange={this.handleChange}
                                />
                            </div>

                            {titleErrorMsg ? <h5 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h5> : null}

                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={updateStationPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Station</h3>
                        <form onSubmit={this.updateStationSubmit}>
                            <div className="form-group">
                                <label>Station Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="stationName"
                                    value={stationName}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Lat</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="lat"
                                    value={lat}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Long</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="long"
                                    value={long}
                                    onChange={this.handleChange}
                                />
                            </div>

                            {titleErrorMsg && <h5 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h5>}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup>


                <Popup
                    open={deleteStationPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-5">Are you sure, you want to delete this Station?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteStation}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>

            </HOC>
        )
    }
}



export default Stations;