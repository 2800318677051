import axios from 'axios';
import API from '../Api';



export function getAllHelpVideos(params) {
    return axios.get(API.GET_ALL_HELP_VIDEOS,params)
}

export function addHelpVideo(params) {
    return axios.post(API.ADD_HELP_VIDEO,params)
}

export function deleteHelpVideo(params) {
    return axios.post(API.DELETE_HELP_VIDEO,params)
}

export function editHelpVideo(params) {
    return axios.post(API.EDIT_HELP_VIDEO,params)
}

export function getHelpVideoCategories(params) {
    return axios.get(API.GET_HELP_VIDEO_CATEGORIES,params)
}
export function getHelpVideoCategoriesLabels(params) {
    return axios.get(API.GET_HELP_VIDEO_CATEGORIES_LABEL,params)
}

export function addHelpVideoCategory(params) {
    return axios.post(API.ADD_HELP_VIDEO_CATEGORY,params)
}

export function editHelpVideoCategory(params) {
    return axios.post(API.EDIT_HELP_VIDEO_CATEGORY,params)
}

export function deleteHelpVideoCategory(params) {
    return axios.post(API.DELETE_HELP_VIDEO_CATEGORY,params)
}

export function uploadHelpVideoFromExcel(params) {
    return axios.post(API.UPLOAD_HELP_VIDEO_FROM_EXCEL,params)
}


export function getHelpVideoRequests(params) {
    return axios.get(API.GET_HELP_VIDEO_REQUESTS,params)
}


export function changeRequestStatus(params) {
    return axios.post(API.CHANGE_REQ_STATUS,params)
}

export const HelpVideoService = {
    getAllHelpVideos,
    addHelpVideo,
    deleteHelpVideo,
    editHelpVideo,
    getHelpVideoCategories,
    addHelpVideoCategory,
    editHelpVideoCategory,
    uploadHelpVideoFromExcel,
    getHelpVideoRequests,
    changeRequestStatus
};