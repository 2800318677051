import React, { Component } from 'react';
import { Accordion, Button, Card, Modal } from 'react-bootstrap'
import { Weightscale } from '../../../assets/SvgIcon';
import { weightGraph, getUserTarget } from '../../../config/services/usersService';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Progress, Tabs, DatePicker } from 'antd';
import Chart from "react-apexcharts";


class WeightGraph extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedGraphType: 2,
      dateFrom: moment().startOf('month').valueOf(),
      dateTo: moment().endOf('month').valueOf(),
      currentDate: moment().valueOf(),
      userId: this.props.userId,
      graphData: { weeklyData: [], countData: [], weightResistance: [] },
      startingWeight: 0,
      endingWeight: 0,
      weightTarget: 0,
      weightsRecorded: [],
      userDetails: {},
      fat: 0,
      obesity: 0,
      muscle: 0,
      visceralFat: 0,
      protein: 0,
      boneWeight: 0,
      BMR: 0,
      waterPercent: 0,
      leanBodyMass: 0,
      bodyAge: 0,
      weightBreakagePopup: false,
      BMI: 0,
      age: 0,
      requestedValues: [],
      title: '',
      changeInWeight: 0
    }
  }

  componentDidMount() {
    this.getWeightGraph()
    this.getTargets()
  }



  getTargets = () => {
    let { userId } = this.state
    getUserTarget({ userId })
      .then(res => {
        const weightTarget = res.data.responseData.weight ? res.data.responseData.weight : 0
        this.setState({ weightTarget })
      })
  }


  getWeightGraph = () => {
    let { selectedGraphType, dateFrom, dateTo, userId } = this.state
    let params = {
      dateFrom,
      dateTo,
      requestUserId: userId,
      graphType: selectedGraphType
    }

    weightGraph(params)
      .then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          let nonZeroWeights = response.responseData.graphData.countData.filter(val => val > 0)
          let startingWeight = nonZeroWeights[0]
          let endingWeight = nonZeroWeights[nonZeroWeights.length - 1]
          let changeInWeight = startingWeight && endingWeight ? endingWeight - startingWeight : 0

          let { userDetails, graphData } = response.responseData
          var age = moment().diff(userDetails.dob, 'years', false);

          this.setState({
            graphData: graphData,
            userDetails: userDetails,
            weightsRecorded: graphData.weightsRecorded ? graphData.weightsRecorded : [],
            startingWeight,
            endingWeight,
            loader: false,
            age,
            changeInWeight
          })
        } else if (response.statusCode == 0) {
          this.setState({ loader: false })
          toast.error(response.error.errorMessage)
        }
      })
  }


  handleDateChange = (date, dateType) => {
    let dateFrom = moment(date).startOf(dateType).valueOf()
    let dateTo = moment(date).endOf(dateType).valueOf()
    let currentDate = moment(date).valueOf()

    this.setState({ dateFrom, dateTo, currentDate }, () => { this.getWeightGraph() })
  }


  handleGraphType = (graphType) => {
    let dateFrom
    let dateTo

    switch (parseInt(graphType)) {
      case 0:
        dateFrom = moment().startOf('day').valueOf()
        dateTo = moment().endOf('day').valueOf()
        break;
      case 1:
        dateFrom = moment().startOf('week').valueOf()
        dateTo = moment().endOf('week').valueOf()
        break;
      case 2:
        dateFrom = moment().startOf('month').valueOf()
        dateTo = moment().endOf('month').valueOf()
        break;
    }

    this.setState({ selectedGraphType: parseInt(graphType), dateFrom, dateTo }, () => { this.getWeightGraph() })
  }

  calculateBmi = (userDetails, weight) => {
    //Formula:-> weight in kilograms/(height in meters)^2 
    let userHeight;
    let userWeight;
    let bmiCategory;
    let { count } = weight

    //Height will be always in cm, unit is to show the height in particular unit. 1=cm, 2=ft 
    userHeight = userDetails.height.value / 100 //in metres

    // Weight will be always in KGs, unit is to show the weight in particular unit. 1=kg, 2=lbs         
    userWeight = count //in Kgs

    let BMI = Math.round(userWeight / Math.pow(userHeight, 2))

    if (BMI <= 15) {
      bmiCategory = 'Very Severely underweight'
    } else if (BMI <= 16) {
      bmiCategory = 'Severely underweight'
    } else if (BMI <= 18.5) {
      bmiCategory = 'Underweight'
    } else if (BMI <= 25) {
      bmiCategory = 'Healthy'
    } else if (BMI <= 30) {
      bmiCategory = 'Overweight'
    } else if (BMI <= 35) {
      bmiCategory = 'Moderately obese'
    } else if (BMI <= 40) {
      bmiCategory = 'Severely obese'
    } else if (BMI > 40) {
      bmiCategory = 'Very severely obese'
    }

    return (BMI + " (" + bmiCategory + ")")
  }

  closeModal = () => {
    this.setState({
      fat: 0,
      obesity: 0,
      muscle: 0,
      visceralFat: 0,
      protein: 0,
      boneWeight: 0,
      BMR: 0,
      waterPercent: 0,
      leanBodyMass: 0,
      bodyAge: 0,
      weightBreakagePopup: false
    })
  }

  handleWeightBreakdownPopup = (weight) => {
    let { count, resistance } = weight
    let { gender, height } = this.state.userDetails
    let { age } = this.state

    age = parseInt(age)
    let userHeight = (height.value) / 100
    let userWeight = parseInt(count)
    let BMI = parseInt(Math.round(userWeight / Math.pow(userHeight, 2)))

    // if(!resistance){
    //   return
    // }

    let fat
    let obesity
    let muscle
    let visceralFat
    let protein
    let boneWeight
    let BMR
    let waterPercent
    let leanBodyMass
    let bodyAge


    if (gender == 1) {
      bodyAge = (BMI * age * 10) / 270
    }
    else {
      bodyAge = (BMI * age * 10) / 240
    }

    if (gender == 1) {
      visceralFat = ((578.09 * BMI * 10 + 16.90 * (age + 934.18) * 1 - 328.7) / 10000) - 6.0
    }
    else {
      visceralFat = ((578.09 * BMI * 10 + 16.90 * (age + 934.18) * 2 - 328.7) / 10000) / 3
    }

    if (gender == 1) {
      BMR = (10 * userWeight) + (6.25 * userHeight * 100) - (5 * age) + 5.0
    }
    else {
      BMR = (10 * userWeight) + (6.25 * userHeight * 100) - (5 * age) - 161
    }

    if (gender == 1) {
      leanBodyMass = (0.32810 * userWeight) + (0.33929 * userHeight * 100) - 29.5336
    }
    else {
      leanBodyMass = (0.29569 * userWeight) + (0.41813 * userHeight * 100) - 43.2933
    }


    if (resistance) {
      if (gender == 1) {
        fat = 24.1911 + 0.0463 * (age - 0.460888) * userHeight * 100 + 0.6341581 * userWeight + 0.0566524 * resistance
        waterPercent = (80 - fat)
      }
      else {
        fat = 43.1912 + 0.0443 * (age - 0.5008) * userHeight * 100 + 0.7042 * userWeight + 0.0449 * resistance
        waterPercent = (80 - fat) * 0.96
      }

      if (gender == 1) {
        muscle = 66.4907 - 0.1919 * (age + 0.2279) * userHeight * (100 - 0.402) * userWeight - 0.0514 * resistance
      }
      else {
        muscle = 58.4907 - 0.1919 * (age + 0.2278) * userHeight * (100 - 0.402) * userWeight - 0.0514 * resistance
      }

      protein = (muscle / 2) - 3

      if (gender == 1) {
        boneWeight = 1.3991 - 0.00213 * (age + 0.0105) * userHeight * 100 + 0.0205 * userWeight - 0.0026 * resistance
      }
      else {
        boneWeight = 2.1191 - 0.00213 * (age + 0.0059) * userHeight * 100 + 0.010501 * userWeight - 0.001599 * resistance
      }
    }


    let idealBodyWeight = 21.8 * Math.pow(userHeight, 2)
    let differenceInWeight = userWeight - idealBodyWeight
    obesity = (differenceInWeight / idealBodyWeight) * 100


    this.setState({ fat, waterPercent, muscle, protein, boneWeight, BMR, leanBodyMass, obesity, visceralFat, bodyAge })

    return { fat: fat, waterPercent, muscle, protein, boneWeight, BMR, leanBodyMass, obesity, visceralFat, bodyAge, BMI }
  }


  calculateBMI = (weight) => {
    let { gender, height } = this.state.userDetails
    let userHeight = (height.value) / 100
    let userWeight = parseInt(weight)

    let BMI = parseInt(Math.round(userWeight / Math.pow(userHeight, 2)))

    return BMI
  }

  calculateBMR = (weight) => {
    let { gender, height } = this.state.userDetails
    let { age } = this.state
    age = parseInt(age)
    let userHeight = (height.value) / 100
    let userWeight = parseInt(weight)
    let BMR = 0

    if (gender == 1) {
      BMR = (10 * userWeight) + (6.25 * userHeight * 100) - (5 * age) + 5.0
    }
    else {
      BMR = (10 * userWeight) + (6.25 * userHeight * 100) - (5 * age) - 161
    }
    return BMR
  }

  calculateVisceralFat = (weight) => {
    let { gender, height } = this.state.userDetails
    let { age } = this.state
    age = parseInt(age)
    let visceralFat = 0
    let BMI = this.calculateBMI(weight)

    if (gender == 1) {
      visceralFat = ((578.09 * BMI * 10 + 16.90 * (age + 934.18) * 1 - 328.7) / 10000) - 6.0
    }
    else {
      visceralFat = ((578.09 * BMI * 10 + 16.90 * (age + 934.18) * 2 - 328.7) / 10000) / 3
    }

    return visceralFat.toFixed()
  }


  calculateLeanBodyMass = (weight) => {
    let { gender, height } = this.state.userDetails
    let userHeight = (height.value) / 100
    let userWeight = parseInt(weight)
    let leanBodyMass = 0

    if (gender == 1) {
      leanBodyMass = (0.32810 * userWeight) + (0.33929 * userHeight * 100) - 29.5336
    }
    else {
      leanBodyMass = (0.29569 * userWeight) + (0.41813 * userHeight * 100) - 43.2933
    }

    return leanBodyMass.toFixed(2)
  }



  calculateBodyAge = (weight) => {
    let { age } = this.state
    let { gender } = this.state.userDetails
    age = parseInt(age)

    let BMI = this.calculateBMI(weight)
    let bodyAge = 0

    if (gender == 1) {
      bodyAge = (BMI * age * 10) / 270
    }
    else {
      bodyAge = (BMI * age * 10) / 240
    }

    return bodyAge.toFixed()
  }


  calculateObesity = (weight) => {
    let userWeight = parseInt(weight)
    let { height } = this.state.userDetails
    let userHeight = (height.value) / 100
    let obesity = 0

    if (!userWeight) {
      return 0
    }

    let idealBodyWeight = 21.8 * Math.pow(userHeight, 2)
    let differenceInWeight = userWeight - idealBodyWeight
    obesity = (differenceInWeight / idealBodyWeight) * 100

    return obesity.toFixed()
  }


  calculateBoneWeight = (weight, resistance) => {
    let userWeight = parseInt(weight)
    let { gender, height } = this.state.userDetails
    let { age } = this.state
    age = parseInt(age)
    let userHeight = (height.value) / 100
    let boneWeight = 0

    if (!resistance) {
      return 0
    }

    if (gender == 1) {
      boneWeight = 1.3991 - 0.00213 * (age + 0.0105) * userHeight * 100 + 0.0205 * userWeight - 0.0026 * resistance
    }
    else {
      boneWeight = 2.1191 - 0.00213 * (age + 0.0059) * userHeight * 100 + 0.010501 * userWeight - 0.001599 * resistance
    }

    return boneWeight.toFixed()
  }

  calculateFat = (weight, resistance) => {
    let userWeight = parseInt(weight)
    let { gender, height } = this.state.userDetails
    let { age } = this.state
    age = parseInt(age)
    let userHeight = (height.value) / 100
    let fat = 0

    if (!resistance) {
      return 0
    }

    if (gender == 1) {
      fat = 24.1911 + 0.0463 * (age - 0.460888) * userHeight * 100 + 0.6341581 * userWeight + 0.0566524 * resistance
    }
    else {
      fat = 43.1912 + 0.0443 * (age - 0.5008) * userHeight * 100 + 0.7042 * userWeight + 0.0449 * resistance
    }

    return fat.toFixed()
  }


  calculateWaterPercent = (weight, resistance) => {
    let { gender } = this.state.userDetails
    let fat = this.calculateFat(weight, resistance)
    let waterPercent = 0

    if (gender == 1) {
      waterPercent = (80 - fat)
    }
    else {
      waterPercent = (80 - fat) * 0.96
    }

    return waterPercent
  }

  calculateMuscle = (weight, resistance) => {
    let userWeight = parseInt(weight)
    let { gender, height } = this.state.userDetails
    let { age } = this.state
    age = parseInt(age)
    let userHeight = (height.value) / 100
    let muscle = 0

    if (!resistance) {
      return 0
    }

    if (gender == 1) {
      muscle = 66.4907 - 0.1919 * (age + 0.2279) * userHeight * (100 - 0.402) * userWeight - 0.0514 * resistance
    }
    else {
      muscle = 58.4907 - 0.1919 * (age + 0.2278) * userHeight * (100 - 0.402) * userWeight - 0.0514 * resistance
    }

    return muscle.toFixed(2)
  }

  calculateProtein = (weight, resistance) => {
    let muscle = this.calculateMuscle(weight, resistance)
    let protein = 0
    if (!resistance) {
      return 0
    }

    protein = (muscle / 2) - 3
    return protein.toFixed(2)
  }


  handleGraphView = (graphType) => {
    let { graphData } = this.state

    let requestedValues = []
    let title = ''

    switch (graphType) {
      case 'bmi':
        requestedValues = []
        title = "BMI"
        graphData.countData.map((weight, i) => {
          let BMI = this.calculateBMI(weight)
          requestedValues.push(BMI)
        })
        break;

      case 'bmr':
        title = "BMR"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let BMR = this.calculateBMR(weight)
          requestedValues.push(BMR)
        })
        break;

      case 'visceralFat':
        title = "Visceral Fat"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let visceralFat = this.calculateVisceralFat(weight)
          requestedValues.push(visceralFat)
        })
        break;

      case 'leanBodyMass':
        title = "Lean Body Mass"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let leanBodyMass = this.calculateLeanBodyMass(weight)
          requestedValues.push(leanBodyMass)
        })
        break;

      case 'bodyAge':
        title = "Body Age"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let bodyAge = this.calculateBodyAge(weight)
          requestedValues.push(bodyAge)
        })
        break;

      case 'obesity':
        title = "Obesity"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let obesity = this.calculateObesity(weight)
          requestedValues.push(obesity)
        })
        break;

      case 'boneWeight':
        title = "Bone Weight"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let boneWeight = this.calculateBoneWeight(weight, graphData.weightResistance[i])
          requestedValues.push(boneWeight)
        })
        break;

      case 'fat':
        title = "Fat"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let fat = this.calculateFat(weight, graphData.weightResistance[i])
          requestedValues.push(fat)
        })
        break;

      case 'waterPercent':
        title = "Water Percent"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let waterPercent = this.calculateWaterPercent(weight, graphData.weightResistance[i])
          requestedValues.push(waterPercent)
        })
        break;

      case 'muscle':
        title = "Muscle"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let muscle = this.calculateMuscle(weight, graphData.weightResistance[i])
          requestedValues.push(muscle)
        })
        break;

      case 'protein':
        title = "Muscle"
        requestedValues = []
        graphData.countData.map((weight, i) => {
          let protein = this.calculateProtein(weight, graphData.weightResistance[i])
          requestedValues.push(protein)
        })
        break;
    }

    this.setState({ requestedValues, title })
  }

  render() {
    let { selectedGraphType, graphData, target, currentDate, startingWeight, endingWeight, weightTarget, requestedValues, title, changeInWeight } = this.state

    let options = {
      colors: ["#1BC8D0", '#FF0000'],
      legend: {
        show: true
      },
      chart: {
        id: "Weight-Bar",
        stackType: "70%",
      },
      markers: {
        size: 0.2,
      },
      stroke: {
        width: 2,
        curve: 'stepline'
      },
      xaxis: {
        categories: graphData.weeklyData
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    }

    let series = [
      {
        name: "Weight",
        data: graphData.countData
      },
      {
        name: "Selected Breakdown",
        data: requestedValues
      }
    ]


    return (
      // <div className="heading">
      //   <h5>Weighing scale</h5>
      // </div>
      <div className="graph-module">
        <div className="row justify-content-center">
          <div className="col-md-3">
            <div className="tab-group flex-column d-flex align-items-baseline justify-content-between">
              <div className="filter-tabs w-100">
                <Tabs defaultActiveKey={selectedGraphType} onChange={this.handleGraphType}>
                  <Tabs.TabPane
                    tab={<span>Month</span>}
                    key={2}
                  >
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={<span>Week</span>}
                    key={1}
                  >
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={<span>Day</span>}
                    key={0}
                  >
                  </Tabs.TabPane>
                </Tabs>
              </div>
              <div className="user-intake-detail d-block w-100">
                <div className="tabs-date-picker w-100">
                  {selectedGraphType == 2 && <DatePicker.MonthPicker allowClear={false} className="form-control" placeholder="Select Month" format='MMM YYYY' onChange={(val) => this.handleDateChange(val, 'month')}
                    defaultValue={moment(new Date(currentDate), 'MMM YYYY')} />}

                  {selectedGraphType == 1 && <DatePicker.WeekPicker allowClear={false} className="form-control" placeholder="Select Week" onChange={(val) => this.handleDateChange(val, 'week')}
                    defaultValue={moment(new Date(currentDate))} />}

                  {selectedGraphType == 0 && <DatePicker allowClear={false} className="form-control" placeholder="Select date" format='Do MMM YYYY' onChange={(val) => this.handleDateChange(val, 'day')}
                    defaultValue={moment(new Date(currentDate), 'Do MMM YYYY')} />}

                </div>
                <div className="progress-section text-center">
                  <div className="postion-rel">
                    <Progress type="circle" percent={(endingWeight / weightTarget) * 100} width={80} />
                    <span><Weightscale /></span>
                  </div>
                </div>

                <div className="weight-info">
                  <div className="d-flex align-items-center mt-3 mb-3">
                    <span className="target"><Weightscale /></span>
                    <p> Target weight: {weightTarget ? weightTarget : '-'} Kg</p>
                  </div>
                  <div className="d-flex align-items-center mt-3 mb-3">
                    <span className="starting"><Weightscale /></span>
                    <p>Inital weight: {startingWeight ? startingWeight : '-'} Kg</p>
                  </div>

                  <div className="d-flex align-items-center mt-3 mb-3">
                    <span className="initial"><Weightscale /></span>
                    <p>Closing Weight: {endingWeight ? endingWeight : '-'} Kg</p>
                  </div>
                  <div className="d-flex align-items-center mt-3 mb-3">
                    <span className="initial"><Weightscale /></span>
                    <p>{changeInWeight < 0 ? 'Weight Loss' : 'Weight Gain'}: {Math.abs(changeInWeight)} Kg</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-9 ml-auto">
            <div className="text-center">
              <div className="heading text-left">
                <h5>Weight Scale</h5>
              </div>
              <div className="graph-wrap">
                {title && <h2>{title} <small> VS </small> Weight</h2>}
                <div className="graph pt-3">
                  <div className="d-flex flex-wrap justify-content-start">
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('bmi')} > BMI </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('bmr')} > BMR </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('visceralFat')} > Visceral Fat </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('leanBodyMass')} > Lean Body Mass </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('bodyAge')} > Body Age </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('obesity')} > Obesity </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('boneWeight')} > Bone Weight </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('fat')} > Fat </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('waterPercent')} > Water Percent </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('muscle')} > Muscle </Button>
                    <Button size='sm' variant="outline-dark" className='ml-1 mb-2' onClick={() => this.handleGraphView('protein')} > Protein </Button>
                  </div>
                  <Chart options={options} series={series} type="line" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}




export default WeightGraph;