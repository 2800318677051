import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { getProductCouponList, /* addProductCoupon, editProductCoupon, */ deleteProductCoupon, bulkUploadProductCoupon } from '../../config/services/serviceProductCoupon';
import { toast } from 'react-toastify';
// import FileSaver from 'file-saver';
// import moment from 'moment';
import { LoaderSvg } from '../Reusable';
// import { DatePicker } from 'antd';
import dateFormat from 'dateformat';
import AddEditForm from './AddEditForm';
import _ from 'lodash';
import Pagination from "react-js-pagination";

const Coupon = (props) => {
  /* const intialValues = { 
    couponName: '',
    discount: '',
    startDate: null,
    endDate: null,
  }; */
  // const [formValues, setFormValues] = useState(intialValues);
  // const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateFormValues, setUpdateFormValues] = useState({})
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  // const [couponArr, setCouponArr] = useState([]);
  // const openImportPopup = () => setImportPopup(true);
  // const [apiErrorMSG, setApiErrorMSG] = useState({});
  const delayedQuery = _.debounce((q) => sendQuery(q), 800);
  const [couponState, setCouponState] = useState({
    pageNo: 1,
    countPerPage: 10,
    search: "",
    sortType: -1,
    sortField: 'created',
    couponArr: [],
  });
  
  // DELETE PRODUCT COUPON
  const deleteCoupon = (item) => {
    deleteProductCoupon(item).then(res => {
      if (res && res.data.statusCode === 1) {
        if (Object.keys(res.data.responseData.result).length > 0) {
          getCouponList(couponState.pageNo, couponState.search);
        } else {        
          toast.success(res.data.responseData.message);
        }
      } else if (res.data.statusCode === 0) {
        // console.log("Error", res.data.error.errorMessage);
        toast.success(res.data.error.errorMessage)
      }
    });
  }
  
  // GET COUPON LIST API CALL
  const getCouponList = (page, search) => {
    setLoader(true);
    let params = {
      limit: couponState.countPerPage,
      page: page-1,
      sortType: couponState.sortType,
      sortField: couponState.sortField,
      search: search,
    };
    getProductCouponList({params}).then(res => {
      if (res && res.data.statusCode === 1) {
        setLoader(false);        
        setCouponState({
          ...couponState,
          pageNo: page ? page : couponState.pageNo,
          search: search,
          couponArr: res.data.responseData.result.coupons,
          totalCouponCount: res.data.responseData.result.count,
        });        
      } else if (res.data.statusCode === 0) {
        setLoader(false);
        // toast.success(res.data.error.errorMessage)
      }
    });
  }
  // CALL DEBOUNCE METHOD ON SEARCH
  const onSearchApi = (e) => {
    delayedQuery(e.target.value);
  };
  // CALL USER LIST API WITH SEARCH DATA
  const sendQuery = (query) => {
    getCouponList(1, query);
  };
  // PAGE CHANGE PAGINATION
  const handlePageChange = (pageNo) => {
    getCouponList(pageNo, couponState.search);
  };
  useEffect(() => {
    getCouponList(1, couponState.search);
  }, []);

  return (
    <div className='body-container-wrapper'>
      {/* {state.apiCalled && <Loader />} */}
      <div className='body-container'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
          <li className='breadcrumb-item'>Coupon</li>
        </ol>
        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
        <div className='users_header'>
          <div className='container-fluid'>
            <div className="row align-items-center">
              <div className="col-md-7 col-sm-12">
                <h4 className="cm_page_heading">Coupon</h4>
              </div>

              <div className="col-md-3 col-sm-12 text-right cm_search mr-2">
                <input
                  type="text"
                  className="form-control m-b-md-15"
                  placeholder="Search by Name"
                  onChange={onSearchApi}              
                />
                <i className="fa fa-search" />
              </div>
              <button style={{float: 'right'}} className="btn btn-info" onClick={() => {setAddEditPopup(true)}}>Add Coupon</button>
              {/* <div style={{display: 'block'}} className='row col-md-3 col-sm-12'>
                <button style={{float: 'right'}} className="btn btn-info mt-2" onClick={() => {setAddEditPopup(true)}}>Add Coupon</button>
                {/* <button type="primary" onClick={openImportPopup} className="ml-1 mt-2 btn btn-primary">Import</button>
                <button type="primary" className="ml-1 mt-2 btn btn-primary" onClick={() => { downloadCoupon() }}>Export</button> * /}
              </div> */}
            </div>
            <div className='table-responsive mt-2'>
              {couponState.couponArr && couponState.couponArr.length > 0 ? (
                <>
                  <table className='table table-bordered table-striped text-center'>
                    <thead>
                      <tr>
                        <th scope="col" style={{width: '20px'}}>Sr. No</th>
                        <th scope="col">Coupon Name</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date </th>
                        <th scope="col" style={{width: '80px'}}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {couponState.couponArr.map((data, index) => (
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td>{data.couponName}</td>
                          <td>{data.discount}%</td>
                          <td>{dateFormat(data.startDate, "d mmmm yyyy")}</td>
                          <td>{dateFormat(data.endDate, "d mmmm yyyy")}</td>
                          <td>
                            <button
                              className="btn btn-dark btn-sm"
                              onClick={() => {
                                setEditMode(true);
                                setUpdateFormValues(data);
                                setAddEditPopup(true);                            
                              }}
                            >
                              Edit
                            </button>
                            &nbsp;
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                if (window.confirm("Are you sure you want to remove this item?")) {
                                  deleteCoupon(data);
                                }
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>        
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    activePage={couponState.pageNo}
                    itemsCountPerPage={couponState.countPerPage}
                    totalItemsCount={couponState.totalCouponCount}
                    pageRangeDisplayed={couponState.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                </>
              ) : (
                <h3 style={{ textAlign: "center" }}>No product coupon found</h3>
              )}
            </div>
          </div>
          <AddEditForm
            editMode={editMode}
            addEditPopup={addEditPopup} 
            updateFormValues={updateFormValues} 
            setAddEditPopup={(val, editModePopup, addedNewRecord) => {
              setEditMode(false);
              setAddEditPopup(val);
              if(editModePopup) {
                getCouponList(addedNewRecord ? 1 : couponState.pageNo, couponState.search);
              }
            }}
            // getCouponList={() => { getCouponList() }}
          />
        </div>
      </div>
    </div>
  );
};

export default Coupon;