import axios from 'axios';
import API from '../Api';

export function subscriptionListByAdmin(params) {
    return axios.get(API.SUBSCRIPTIONS_LIST, params)
}
export function editEndDate(params) {
    return axios.post(API.EDIT_SUBSCRIPTION_END_DATE, params)
}
export function cancelSubscription(params) {
    return axios.post(API.CANCEL_SUBSCRIPTION, params)
}


export function bulkUploadOrder (params) {
    return axios.post(API.BULK_UPLOAD_ORDERS, params)
}

//export order with filter value
export function downloadOrderList(params) {

    return axios({
        method: 'post',
        url: API.DOWNLOAD_ORDERS,
        responseType: 'blob',
        data: params
    })

}

export function getOrderList(params) {
    return axios.get(API.GET_ORDERS, params)
}

export function editOrder(params) {
    return axios.put(API.EDIT_ORDER, params);
}





