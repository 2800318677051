import React from "react";
import ReactExport from "react-export-excel";
import moment from 'moment'
import { Button } from 'react-bootstrap';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExportExcel extends React.Component {


    parseTimeDifference = (riderDetails) => {
        let { loginTime, logoutTime } = riderDetails

        if (loginTime && logoutTime) {
            var start_date = moment(loginTime);
            var end_date = moment(logoutTime);
            var duration = moment.duration(end_date.diff(start_date));
            var hours = duration.asHours();
            return hours.toFixed(2)
        } else {
            return "-"
        }
    }

    getStatus = (status) => {
        switch (status) {
            case 1:
                return 'Pending'
            case 2:
                return 'Accepted'
            case 3:
                return 'Rejected'
            case 4:
                return 'Cancelled'
            default:
                return "-"
        }
    }

    render() {
        let { riderList } = this.props
        console.log("riderlistt", riderList)
        return (
            <ExcelFile filename={"RidersLogs" + moment().format('Do_MMM_YYYY')} element={<Button variant="outline-info" size="sm">Export To Excel</Button>}>
                <ExcelSheet data={riderList} name="Riders">
                    <ExcelColumn label="User Name" value={(col) => `${col.userDetails.firstName} ${col.userDetails.lastName}`} />
                    <ExcelColumn label="User Email" value={(col) => `${col.userDetails.email}`} />
                    <ExcelColumn label="Product Name" value={(col) => `${col.productDetails.productName}`} />
                    <ExcelColumn label="Tracking Id" value={col => col.trackingId ? col.trackingId : "N/A"} />
                    <ExcelColumn label="Delivery Status" value={col => col.trackingId ? col.trackingId : "N/A"} />
                    <ExcelColumn label="Coins Used" value={col => col.coinsUsed} />
                    <ExcelColumn label="Status" value={col => this.getStatus(col.status) } />
                    <ExcelColumn label="Created" value={col => moment(col.logoutTime).format('DD/ MM/ YYYY')} />
                </ExcelSheet>
            </ExcelFile>
        )
    }
}


export default ExportExcel;