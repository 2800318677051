import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getHelpVideoCategories, getHelpVideoCategoriesLabels, addHelpVideoCategory, editHelpVideoCategory, deleteHelpVideoCategory } from '../../config/services/HelpVideoService';
import AddHelpVideoCategory from './addCategory'
import { toast } from 'react-toastify';
import SearchSpinner from '../common/SearchSpinner';

class HelpVideo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            helpVideoCategories: [],
            categories: [],
            addHelpVideoCategoryPopup: false,
            editHelpVideoCategoryPopup: false,
            deleteCategoryPopup: false,
            search: '',
            count: 10,
            totalCategories: 0,
            pageNo: 1,
            sortType: '',
            sortField: '',
            validationError: '',
            categoryTitle: '',
            tempCategoryId: '',
            createdSortType: '',
            searchLoader: false
        }
    }

    getAllCategories = () => {
        const { pageNo, count, sortField, sortType, search } = this.state

        let params = {
            params: {
                pageNo: pageNo - 1,
                count,
                sortField,
                sortType,
                search
            }
        }
        getHelpVideoCategories(params)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({
                        helpVideoCategories: response.responseData.result,
                        totalCategories: response.responseData.totalCount,
                        loader: false,
                        searchLoader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, searchLoader: false })
                    toast.error(response.error.errorMessage)
                }
            })

        getHelpVideoCategoriesLabels(params)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({
                        categories: response.responseData.result
                    }, () => {
                        this.filterParentCategories(this.state.categories)
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    filterParentCategories = (categories) => {
        const parentCategories = categories.filter((c) => (!c.parent && c.isDeleted === false))
        this.setState({ parentCategories: parentCategories })
    }

    handleParentCategory = (pCategory) => {
        this.setState({ parentCategory: pCategory.value })
    }

    addCategory = () => this.setState({ addHelpVideoCategoryPopup: !this.state.addHelpVideoCategoryPopup })

    handleClose = () => {
        this.setState({ addHelpVideoCategoryPopup: false, editHelpVideoCategoryPopup: false, categoryTitle: '', validationError: '', tempCategoryId: '', deleteCategoryPopup: false })
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    addCategorySubmit = (e) => {
        e.preventDefault();
        const { categoryTitle, parentCategory } = this.state;
        let paramsObj = {
            categoryTitle,
            parent: parentCategory
        }
        if (!categoryTitle) {
            this.setState({ validationError: "Category title cannot be empty." })
        } else if (categoryTitle.length > 20) {
            this.setState({ validationError: "Category title cannot contain more than 20 characters." })
        } else {
            this.setState({ validationError: '', loader: true })
            addHelpVideoCategory(paramsObj)
                .then((res) => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getAllCategories();
                        this.handleClose();
                        toast.success(response.responseData.message)
                        this.setState({ loader: false, parentCategory: null })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    openEditCategoryPopup = (categoryDetails) => {
        this.setState({ editHelpVideoCategoryPopup: !this.state.editHelpVideoCategoryPopup, categoryTitle: categoryDetails.categoryTitle, tempCategoryId: categoryDetails._id })
    }

    openDeleteCategoryPopup = (categoryDetails) => {
        this.setState({ tempCategoryId: categoryDetails._id, deleteCategoryPopup: true })
    }

    editCategorySubmit = (e) => {
        e.preventDefault();
        const { categoryTitle, tempCategoryId } = this.state;
        let paramsObj = {
            categoryTitle,
            categoryId: tempCategoryId,
        }

        if (!categoryTitle) {
            this.setState({ validationError: "Category title cannot be empty." })
        } else if (categoryTitle.length > 20) {
            this.setState({ validationError: "Category title cannot contain more than 20 characters." })
        } else {
            this.setState({ validationError: '', loader: true })
            editHelpVideoCategory(paramsObj)
                .then((res) => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getAllCategories();
                        this.handleClose();
                        toast.success(response.responseData.message)
                        this.setState({ loader: false })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    deleteCategorySubmit = (e) => {
        e.preventDefault();
        const { tempCategoryId } = this.state;
        let paramsObj = {
            categoryId: tempCategoryId,
        }
        deleteHelpVideoCategory(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getAllCategories();
                    this.handleClose();
                    toast.success(response.responseData.message)
                    this.setState({ loader: false })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }


    handleSearch = (e) => {
        const { value } = e.target;
        this.setState({ search: value, pageNo: 1, searchLoader: true }, () => {
            this.getAllCategories();
        })
    }

    handleSort = e => {
        console.log('handleSort', e)
        const fields = ['categoryTitle', 'created']
        let params
        switch (e) {
            case 0:
                this.setState({ sortField: fields[e], sortType: (this.state.sortType === 1) ? 2 : 1 })
                break;
            case 1:
                params = {
                    params: {
                        sortField: fields[e],
                        sortType: this.state.createdSortType === 1 ? 2 : 1
                    }
                }
                this.setState({ sortField: fields[e], sortType: (this.state.createdSortType === 1) ? 2 : 1, createdSortType: this.state.createdSortType === 1 ? 2 : 1 })
                break;
        }
        this.getAllCategories();
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => {

        let params = {
            params: {
                pageNo: this.state.pageNo - 1
            }
        }
        this.getAllCategories(params)
    });

    componentDidMount() {
        this.getAllCategories();
    }


    render() {
        const {
            categories,
            loader,
            helpVideoCategories,
            search,
            validationError,
            pageNo,
            count,
            totalExercises,
            addHelpVideoCategoryPopup,
            editHelpVideoCategoryPopup,
            categoryTitle,
            deleteCategoryPopup,
            sortType,
            createdSortType,
            totalCategories,
            parentCategories,
            searchLoader
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Video Categories</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Video Category</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search position-relative">
                                        {searchLoader ?
                                            <SearchSpinner type="text-info" />
                                            : null}
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by category title"
                                            onChange={this.handleSearch}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.addCategory}>Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort(0)}>Title &nbsp;
                                            <i className={`fa fa-sort-alpha-${sortType == 1 ? "asc" : "desc"}`}></i>
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort(0)}>Parent Category </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort(1)}>Created &nbsp;
                                            <i className={`fa fa-sort-numeric-${createdSortType == 1 ? "asc" : "desc"}`}></i>
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        helpVideoCategories.map((category, i) => {
                                            return (
                                                <tr key={category._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td className="cm_no_wrap">{category.categoryTitle}</td>
                                                    <td className="cm_no_wrap">{(category.parentCategory && category.parentCategory.categoryTitle) ? category.parentCategory.categoryTitle : '-'}</td>
                                                    <td className="cm_no_wrap">{dateFormat(category.created, "d mmmm yyyy")}</td>
                                                    <td className="cm_no_wrap">
                                                        {/* <button className="btn btn-dark btn-sm" onClick={(data) => this.openEditCategoryPopup(category)} >Edit</button>&nbsp;&nbsp; */}
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteCategoryPopup(category)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalCategories}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!helpVideoCategories.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>
                    </div>
                </div>
                <Popup
                    open={addHelpVideoCategoryPopup}
                    closeOnDocumentClick={false}
                    onClose={this.handleClose}
                >
                    <AddHelpVideoCategory
                        handleClose={this.handleClose}
                        addCategorySubmit={this.addCategorySubmit}
                        validationError={validationError}
                        handleChange={this.handleChange}
                        categories={parentCategories}
                        handleParentCategory={this.handleParentCategory}
                    />
                </Popup>


                {/* <Popup
                    open={editHelpVideoCategoryPopup}
                    closeOnDocumentClick
                    onClose={this.handleClose}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.handleClose}>&times;</span>
                        <h3 className="text-center">Update Food Category</h3>

                        <h4 className="text-center mb-4 mt-5 error_message">{validationError}</h4>

                        <form onSubmit={this.editCategorySubmit}>

                            <div className="form-group">
                                <label>Food Category Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="categoryTitle"
                                    value={categoryTitle}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup> */}


                <Popup
                    open={deleteCategoryPopup}
                    closeOnDocumentClick={false}
                    onClose={this.handleClose}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.handleClose}>&times;</span>
                        <h4 className="text-center mb-4 error_message">{validationError}</h4>

                        <h3 className="text-center mb-4">Are you sure, you want to delete this Video category?</h3>
                        <div className="btn_group">
                            <div className="row">
                                <div className="col"><button className="btn btn-danger btn-sm btn-block" onClick={this.deleteCategorySubmit}>Delete</button></div>
                                <div className="col"><button className="btn btn-warning btn-sm btn-block" onClick={this.handleClose}>Cancel</button></div>
                            </div>
                        </div>
                    </div>
                </Popup>

            </HOC>
        )
    }
}



export default HelpVideo;