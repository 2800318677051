import React, { Component } from 'react';
import HOC from '../../HOC';
import { toast } from 'react-toastify';
import { getProductServiceIncidentList } from '../../config/services/ProductServiceIncident'
const { getTableHeader, statusOptions, getValue, statusFilterOptions } = require("../utils/Util.js");



const columnDetails = [
    { fieldName: "S.No." },
    // { fieldName: "Job Creation Date" },
    { fieldName: "Status Change Date" },
    { fieldName: "Request Id." },
    { fieldName: "Job Id." },
    { fieldName: "Assigned To" },
    { fieldName: "Status" },
    { fieldName: "Request /Product Job" },
    { fieldName: "Remark" },
    { fieldName: "Updated By" },
];


class ProductServiceIncident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            productIncidentList: [],
            count: 10,
            totalEngineerCount: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            error: "",
            incidentDataArray: [],
            selectedJobId: "",
            serviceRequestId: "",
            jobCreated: ""



        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.loader && nextProps.jobId) {
            if(nextProps.jobId == 'not_found_any_jobs') {
                this.setState({ incidentDataArray: [] })
            } else {
                this.getProductServiceIncidentList(nextProps);
            }            
        }
    }

    componentDidMount() {
        this.getProductServiceIncidentList(this.props);
    }

    getProductServiceIncidentList = (props) => {
        let { jobId, selectedProductRequest } = props;
        let paramsObj = { params: { jobId: jobId, requestId: selectedProductRequest } }
        getProductServiceIncidentList(paramsObj)
            .then(res => {
                let response = res.data
                let productIncidentList = [];

                if (response.statusCode == 1) {
                    if (response.responseData.result.length > 0) {
                        let resultData = response.responseData.result[0];
                        let { engineerJobAssignmentHistory, jobScheduleHistory, jobStatusHistory, notes, requestStatusHistory, urgentMarkJobHistory, vendorJobAssignmentHistory, requestSelfDoneHistory } = resultData;
                        requestStatusHistory.length > 0 && requestStatusHistory.forEach(function (element) {
                            element.statusForRequest = true;
                        });
                        vendorJobAssignmentHistory[0].mainAdmin = "main"
                        productIncidentList = engineerJobAssignmentHistory.concat(jobScheduleHistory, jobStatusHistory, notes, requestStatusHistory, urgentMarkJobHistory, vendorJobAssignmentHistory, requestSelfDoneHistory);
                        let incidentDataArray = this.getPropertyData(productIncidentList);
                        let serviceJobId = resultData.jobId;
                        let jobCreated = resultData.created;
                        let serviceRequestId = resultData.serviceRequestId
                        console.log(response.responseData)
                        this.setState({ incidentDataArray, serviceJobId, serviceRequestId, jobCreated })
                    } else {
                        this.setState({ incidentDataArray: [] })
                    }
                } else {
                    this.setState({ loader: false })
                    // toast.error(response.error.errorMessage)
                }
            })
    }

    getPropertyData(incidentPropertyData) {
        let incidentDataArray = [];
        incidentPropertyData.map((item) => {
            let keyNames = Object.keys(item);
            let obj = {}
            for (let key of keyNames) {
                let keyValue = item[key];
                if (key.indexOf('By') > -1) {
                    obj.AssignedBy = keyValue && `${keyValue.firstName} ${keyValue.lastName}`;
                    if (key == "markedBy") {
                        obj.Status = item["isUrgent"] == true ? "Marked as urgent" : "Marked as non-urgent"
                    }
                    else {
                        obj.Status = key == "notedBy" ? "Remark Added" : key.replace('By', '');

                    }

                }
                if(key.indexOf('mainAdmin') > -1){
                    obj.MainAdmin = "main"
                }
                if (key.indexOf('On') > -1) {
                    obj.AssignedOn = keyValue;
                }
                if (key.indexOf('To') > -1) {
                    obj.AssignedTo = keyValue && `${keyValue.firstName} ${keyValue.lastName}`;
                }
                if (key.indexOf('newStatus') > -1) {
                    obj.Status = item["newStatus"];
                    obj.StatusFor = keyNames[1].replace('By', '');
                }
                if (key.indexOf('note') > -1 || key.indexOf('remark') > -1) {
                    obj.Remark = item["note"] || item["remark"]
                }
                if (key.indexOf('statusForRequest') > -1) {
                    obj.statusForRequest = true;
                }
                if (key.indexOf('selfDone') > -1) {
                    obj.Status = "Self Done";
                    obj.statusForRequest = true;
                }


            }
            if (Object.keys(obj).length > 0) {
                incidentDataArray.push(obj);
            }
        });

        incidentDataArray.sort(function compare(a, b) {
            var dateA = new Date(a.AssignedOn);
            var dateB = new Date(b.AssignedOn);
            return dateB - dateA;
        });
        console.log(incidentDataArray)
        return incidentDataArray

    }

    render() {
        let {
            search, incidentDataArray, serviceJobId, serviceRequestId, jobCreated } = this.state;
            let {adminRole } =this.props

        return (
            <HOC>
                <div >
                    <div>

                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-striped text-center mb-5">
                                {getTableHeader(columnDetails, adminRole)}
                                <tbody>
                                    {
                                        incidentDataArray && incidentDataArray.map((item, index) => {
                                            let status = item["Status"];
                                            if (Number(item["Status"])) {
                                                status = item["statusForRequest"] ? statusFilterOptions.find((a => a.value == item["Status"])) && statusFilterOptions.find((a => a.value == item["Status"])).label :
                                                    statusOptions.find((a => a.value == item["Status"])) && statusOptions.find((a => a.value == item["Status"])).label

                                            }
                                            return (<tr key={index + 1}>
                                                <td>{index + 1} </td>
                                                {/* <td>{getValue("dateTime", jobCreated)} </td> */}
                                                <td>{getValue("dateTime", item["AssignedOn"])} </td>
                                                <td>{serviceRequestId} </td>
                                                <td>{serviceJobId} </td>
                                                {
                                                    adminRole != 7 &&
                                                <td>{item["AssignedTo"]}</td>
                                                }
                                                <td>{status}</td>
                                                <td>{item["statusForRequest"] ? "Request" : "Product Job"}</td>
                                                <td>{item["Remark"]}</td>
                                                <td>
                                                    {
                                                        adminRole == 7 ? 
                                                            item["MainAdmin"]
                                                            ?
                                                                item["AssignedBy"]
                                                                : 
                                                                'Vendors/Engineers'
                                                            : 
                                                            item["AssignedBy"]
                                                    }
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                            {!incidentDataArray.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>
                    </div>
                </div>
            </HOC>
        )
    }
}


export default ProductServiceIncident;