import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageSmile from './../../assets/images/smile.svg';
const EmojiDetail = ({props}) => {
    console.log("emoji data ---->", props)

    // const {} 
    return ( 
        <div className="body-container-wrapper">
            <div className="body-container">
                <div className="d-flex justify-content-between border-bottom custom-list mb-3">
                    <ol className="breadcrumb m-0 border-0 pl-0 breadcrumb-custom">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/emoji">Emoji</Link></li>
                        <li className="breadcrumb-item">Emoji Detail</li>
                    </ol>
                </div>
                {/* Detail data */}
                <div className="emoji-detail">
                    <div className="row">
                        <div className="col-md-6 mx-auto">
                            <div className="card p-5 d-flex flex-row align-items-center justify-content-between">
                                <div className="detail">
                                    <div className="form-group">
                                        <p>Emoji Name: <strong>Smile</strong></p>
                                    </div>
                                    <div className="form-group">
                                        <p>Emoji Platform: <strong>User App</strong></p>
                                    </div>
                                    <div className="form-group">
                                        <p>Created At: <strong>12/01/2021</strong></p>
                                    </div>
                                    <div className="form-group">
                                        <p>Edited At: <strong>12/12/2021</strong></p>
                                    </div>
                                    <div className="form-group">
                                        <p>Created User: <strong>Shubham</strong></p>
                                    </div>
                                    <div className="form-group">
                                        <p>Edited Edited: <strong>Salman</strong></p>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="emoji-image">
                                        <img src={ImageSmile} alt="smile"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default EmojiDetail;