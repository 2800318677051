import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Popup from "reactjs-popup"
import { Link } from 'react-router-dom';
import { getExerciseCategories, addExerciseCategories, editExerciseCategory, deleteExerciseCategory } from "../../config/services/exerciseService";
import { LoaderSvg } from '../Reusable';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import SearchSpinner from '../common/SearchSpinner';

let image;

class ExerciseCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            addCetegoryPopup: false,
            deleteCetegoryPopup: false,
            exerciseCategoryList: [],
            categoryTitle: '',
            categoryDescription: '',
            categoryImagePreview: '',
            categoryTitleErrorMsg: '',
            editCategoryPopup: false,
            tempCategoryId: "",
            validationError: "",
            search: '',
            count: 20,
            pageNo: 1,
            totalCategories: 50,
            sortType: '',
            sortField: '',
            searchLoader: false
        }
    }


    openCategoryEdit = ({ _id, categoryTitle, categoryDescription, categoryImage }) => {
        this.setState({
            openCategoryEditPoup: !this.state.openCategoryEditPoup,
            categoryId: _id,
            categoryTitle,
            categoryDescription,
            categoryImagePreview: categoryImage,
        })
    }

    openAddCetegoryPopup = () => this.setState({ addCetegoryPopup: !this.state.addCetegoryPopup });
    openDeleteCategoryPopup = (data, category) => {
        this.setState({
            deleteCetegoryPopup: !this.state.deleteCetegoryPopup,
            tempCategoryId: category._id,
        })
    }



    handlePageChange = (pageNumber) => {
        this.setState({ pageNo: pageNumber }, () => { this.getExerciseCategoriesList(); });
    }

    deleteCategory = (e) => {
        e.preventDefault();
        this.setState({ loader: true })
        const { tempCategoryId } = this.state;
        deleteExerciseCategory({ categoryId: tempCategoryId })
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getExerciseCategoriesList()
                    this.closeModal()
                    this.setState({ loader: false })

                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })

    }
    openEditCategoryPopup = (data, category) => {
        this.setState({
            editCategoryPopup: !this.state.editCategoryPopup,
            tempCategoryId: category._id,
            categoryTitle: category.categoryTitle,
            categoryDescription: category.categoryDescription,
            categoryImagePreview: category.categoryImage,
        })
    };

    submitEditCategory = (e) => {
        e.preventDefault();
        let { categoryTitle, categoryDescription, categoryImagePreview, tempCategoryId } = this.state;
        let paramsObj = new FormData();
        paramsObj.append('categoryId', tempCategoryId);
        paramsObj.append('categoryTitle', categoryTitle);
        paramsObj.append('categoryDescription', categoryDescription);
        paramsObj.append('exerciseCategoryImage', image ? image : "");


        if (categoryTitle == "" || categoryDescription == "" || categoryImagePreview == "") {
            this.setState({ validationError: "Field cannot be empty" })
        } else {
            this.setState({ validationError: "" })
            this.editCategoryApi(paramsObj);
        }
    }

    editCategoryApi = (params) => {
        let self = this;
        this.setState({ loader: true })
        editExerciseCategory(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getExerciseCategoriesList()
                    this.closeModal()
                    this.setState({ loader: false })

                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }

    closeModal = () => this.setState({
        addCetegoryPopup: false, deleteCetegoryPopup: false, editCategoryPopup: false, categoryTitle: "",
        openEditCategoryPopup: false,
        categoryDescription: "",
        categoryImagePreview: "",
        tempCategoryId: "",
        categoryTitleErrorMsg: '',
        validationError: '',
    });


    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, categoryTitleErrorMsg: '' })
    }




    handleImgPreivew = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        image = e.target.files[0];

        this.setState({ categoryImagePreview: e.target.files[0] })
        reader.addEventListener("load", () => {
            this.setState({ categoryImagePreview: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }

    }


    handleValidetion = () => {
        let validate = true;
        const { categoryTitle, categoryDescription } = this.state;

        // Category Title Validate
        if (categoryTitle === '' || categoryTitle === undefined) {
            validate = false;
            this.setState({ categoryTitleErrorMsg: 'Please Enter Category Title' })
        } else if (categoryDescription === '' || categoryDescription === undefined) {
            validate = false;
            this.setState({ categoryTitleErrorMsg: 'Please Enter Category Description' })
        } else {
            this.setState({ categoryTitleErrorMsg: '' })
        }
        return validate
    }



    handleSubmit = e => {
        e.preventDefault();

        if (this.handleValidetion()) {
            let { categoryTitle, categoryDescription, categoryImagePreview } = this.state;
            let params = new FormData();
            params.append('categoryTitle', categoryTitle);
            params.append('categoryDescription', categoryDescription);
            params.append('exerciseCategoryImage', image);
            this.addExerciseCategoryApi(params);
        }
    }

    addExerciseCategoryApi = (params) => {
        this.setState({ loader: true })
        addExerciseCategories(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false }, () => this.getExerciseCategoriesList())
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        categoryTitleErrorMsg: res.data.error.errorMessage,
                        loader: false
                    })

                }
            })
    }






    handleCategoryEdit = e => {
        e.preventDefault();
        this.setState({
            loader: true
        })


        if (this.handleValidetion()) {
            this.setState({
                addCetegoryPopup: false,
            })

            let { categoryTitle, categoryDescription, categoryId } = this.state;

            let params = new FormData();
            params.append('categoryTitle', categoryTitle);
            params.append('categoryId', categoryId);
            params.append('categoryDescription', categoryDescription);
            params.append('exerciseCategoryImage', image);


            editExerciseCategory(params).then(res => {
                this.setState({
                    categoryTitle: '',
                    categoryDescription: '',
                    categoryImagePreview: '',
                    categoryTitleErrorMsg: ''
                }, () => this.getExerciseCategoriesList())
            })
        }
    }



    handleCategoryDelete = () => {
        this.setState({
            loader: true
        })

        let { categoryId } = this.state;

        let params = {
            categoryId
        }


        deleteExerciseCategory(params).then(res => {
            if (res.data.statusCode == 1) {
                this.setState({
                    deleteCetegoryPopup: false,
                    categoryId: ''
                }, () => this.getExerciseCategoriesList())
            }

        })

    }


    getExerciseCategoriesList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search
                , count
                , pageNo: pageNo - 1
                , sortType
                , sortField
            }
        }
        getExerciseCategories(paramsObj).then(res => {
            if (res.data.statusCode == 1) {
                this.setState({
                    exerciseCategoryList: res.data.responseData.result,
                    totalCategories: res.data.responseData.totalCount,
                    loader: false,
                    searchLoader: false
                })
            } else {
                this.setState({ loader: false, searchLoader: false })
            }
        })
    }

    handleSearch = e => this.setState({ search: e.target.value, searchLoader: true }, () => this.getExerciseCategoriesList());

    componentDidMount() {
        this.getExerciseCategoriesList();
    }



    render() {
        const { exerciseCategoryList, loader, addCetegoryPopup, categoryTitle, categoryDescription, categoryImagePreview, categoryTitleErrorMsg, deleteCetegoryPopup, editCategoryPopup, validationError, pageNo, count, totalCategories, searchLoader } = this.state;
        return (
            <div className="body-container-wrapper">
                <div className="body-container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item">Exercise Category</li>
                    </ol>


                    {loader
                        ? <div className="loader_wrapper"><LoaderSvg /></div>
                        : null}

                    <div className="users_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="cm_page_heading margin-bottom-10px mt-1">Exercise Category</h4>
                                </div>
                                <div className="col-md-3 text-right cm_search position-relative">
                                    {searchLoader ?
                                        <SearchSpinner type="text-info" />
                                        : null}
                                    <input
                                        type="text"
                                        className="form-control m-b-md-15"
                                        placeholder="Search by category title"
                                        onChange={this.handleSearch}
                                    />
                                    <i className="fa fa-search" ></i>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button className="btn btn-primary btn-block" onClick={this.openAddCetegoryPopup}>Add New</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-3">
                        {exerciseCategoryList.map((category, i) => (
                            <div className="col-xl-3 col-lg-4 col-md-6" key={category._id}>
                                <div className="card card_content">
                                    {
                                        category.categoryImage ? <img className="card-img-top" src={category.categoryImage} alt="Food image" /> : <div className="card-img-top">No Image</div>
                                    }

                                    <div className="card-body">
                                        <h4 className="card-title mb-1">{category.categoryTitle}</h4>
                                        <p><b>Created: </b>{dateFormat(category.created, "d mmmm yyyy")}</p>
                                        <div className="category_description">
                                            <h3>Description</h3>
                                            <p>{category.categoryDescription ? category.categoryDescription : 'No Description'}</p>
                                        </div>

                                        <div className="d-flex card--dflex">
                                            <button className="btn btn-primary btn-xs" onClick={(data) => this.openEditCategoryPopup(data, category)}><i className="fa fa-pencil-square-o"></i></button>
                                            <button className="btn btn-danger btn-xs ml-2" onClick={(data) => this.openDeleteCategoryPopup(data, category)}><i className="fa fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>

                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalCategories}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                    />

                    {!exerciseCategoryList.length ? <h3 className="empty_error">Sorry, we couldn't find any content!</h3> : null}


                </div>



                <Popup
                    open={addCetegoryPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Exercise Category</h3>

                        <form onSubmit={this.handleSubmit}>

                            {
                                categoryTitleErrorMsg ? <h4 className="text-center mb-4 mt-5 error_message">{categoryTitleErrorMsg}</h4> : null
                            }
                            <div className="form-group">
                                <label>Category Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="categoryTitle"
                                    value={categoryTitle}
                                    onChange={this.handleChange}
                                />


                            </div>


                            <div className="form-group">
                                <label>Category Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="categoryDescription"
                                    value={categoryDescription}
                                    onChange={this.handleChange}
                                />
                            </div>



                            <div className="form-group">
                                <label>Exercise Category Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {
                                categoryImagePreview ? <img className="exercise_category_img" src={categoryImagePreview} /> : null
                            }


                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>


                        </form>


                    </div>
                </Popup>



                <Popup
                    open={editCategoryPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Exercise Category</h3>
                        <h6 className="text-center mb-4 mt-2 error_message">{validationError}</h6>

                        <form onSubmit={this.submitEditCategory}>
                            {
                                categoryTitleErrorMsg ? <h4 className="text-center mb-4 mt-5 error_message">{categoryTitleErrorMsg}</h4> : null
                            }
                            <div className="form-group">
                                <label>Category Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="categoryTitle"
                                    value={categoryTitle}
                                    onChange={this.handleChange}
                                />


                            </div>


                            <div className="form-group">
                                <label>Category Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="categoryDescription"
                                    value={categoryDescription}
                                    onChange={this.handleChange}
                                />
                            </div>



                            <div className="form-group">
                                <label>Exercise Category Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {
                                categoryImagePreview ? <img className="exercise_category_img" src={categoryImagePreview} /> : null
                            }


                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup>


                {/* <Popup
                    open={openCategoryEditPoup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Exercise Category</h3>

                        <form onSubmit={this.handleCategoryEdit}>

                            {
                                categoryTitleErrorMsg ? <h4 className="text-center mb-4 mt-5 error_message">{categoryTitleErrorMsg}</h4> : null
                            }
                            <div className="form-group">
                                <label>Category Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="categoryTitle"
                                    value={categoryTitle}
                                    onChange={this.handleChange}
                                />

                                
                            </div>


                            <div className="form-group">
                                <label>Category Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="categoryDescription"
                                    value={categoryDescription}
                                    onChange={this.handleChange}
                                />
                            </div>



                            <div className="form-group">
                                <label>Exercise Category Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {
                                categoryImagePreview ? <img className="exercise_category_img" src={categoryImagePreview} /> : null
                            }


                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>


                        </form>


                    </div>
            </Popup> */}


                <Popup
                    open={deleteCetegoryPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4">Are you sure, you want to delete this Category?</h5>

                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteCategory}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>


                    </div>
                </Popup>




            </div>


        )
    }
}

export default ExerciseCategory;