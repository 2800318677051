import axios from 'axios';
import API from '../Api';

export function addGymManager(params) {
    return axios.post(API.ADD_GYM_MANAGER, params)
}

export function getGymManagers(params) {
    return axios.get(API.GET_GYM_MANAGERS, params)
}

export function getGyms(params) {
    return axios.get(API.GET_GYMS, params)
}

export function deleteGym(params) {
    return axios.delete(API.DELETE_GYM, params)
}

export function deleteGymManager(params) {
    return axios.post(API.DELETE_GYM_MANAGER, params)
}

export function editGymManager(params) {
    return axios.post(API.EDIT_GYM_MANAGER, params)
}

export function addGym(params) {
    return axios.post(API.ADD_GYM, params)
}

export function editGym(params) {
    return axios.put(API.EDIT_GYM, params)
}

export function getEquipments() {
    return axios.get(API.GET_EQUIPMENTS)
}

export function addEquipment(params) {
    return axios.post(API.ADD_EQUIPMENT, params)
}

export function deleteEquipment(params) {
    return axios.post(API.DELETE_EQUIPMENT, params)
}

export function editEquipment(params) {
    return axios.post(API.EDIT_EQUIPMENT, params)
}

export function getProductSpareParts() {
    return axios.get(API.GET_PRODUCT_SPARE_PART)
}

export function getGymDetails(params) {
    return axios.get(API.GET_GYM_DETAILS, params)
}

export function getGymEquipments(params) {
    return axios.get(API.GET_GYM_EQUIPMENTS, params)
}

export function getAllEquipments(params) {
    return axios.get(API.GET_ALL_EQUIPMENTS, params)
}

export function addGymEquipments(params) {
    return axios.post(API.ADD_GYM_EQUIPMENTS, params)
}

export function getWarehouseList() {
    return axios.get(API.GET_WAREHOUSE_LIST)
}

export function addGymEquipmentLogistics(params) {
    return axios.post(API.ADD_GYM_EQUIPMENT_LOGISTICS, params)
}

export function getGymEquipmentLogistics(params) {
    return axios.get(API.GET_GYM_EQUIPMENT_LOGISTICS, params)
}

export function deleteGymEquipment(params) {
    return axios.post(API.DELETE_GYM_EQUIPMENT, params)
}

export function editGymEquipment(params) {
    return axios.post(API.EDIT_GYM_EQUIPMENT, params)
}


export function getAllVerticals() {
    return axios.get(API.GET_ALL_VERTICALS)
}

export function addVertical(params) {
    return axios.post(API.ADD_VERTICAL, params)
}

export function updateVertical(params) {
    return axios.post(API.UPDATE_VERTICAL, params)
}

export function enableVertical(params) {
    return axios.post(API.ENABLE_VERTICAL, params)
}

export function addSpareParts(params) {
    return axios.post(API.ADD_SPARE_PARTS, params)
}

export function getAllMachines(params) {
    return axios.get(API.GET_ALL_MACHINE,params)
}

export function readVendorTrainingDataFromExcel(params) {
    return axios.post(API.READ_VENDOR_TRAINING_SHEET, params)
}

export function deleteMachine(params){
    return axios.post(API.DELETE_MACHINE,params)
}


