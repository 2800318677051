import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import Loader from '../loader';
import FileSaver from 'file-saver';
import moment from 'moment';
import { LoaderSvg } from '../Reusable';
import Select from 'react-select';
import { getWarehouse } from '../../config/services/servicePincodes';
import { bulkUploadOrder, downloadOrderList } from '../../config/services/OrderManagementService';
import { orderStatusOptions,paymentStatusOptions } from "../../utils/commonFunctions";


import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

let allowedFileTypes = [
  'application/vnd.ms-excel',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const ImportExport = (props) => {
  let fileObj = { uploadFile: '' };
  const [importFile, setImportFile] = useState(fileObj);
  const [importPopup, setImportPopup] = useState(false);
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loader, setLoader] = useState(false);

  // Warehouse for filter 
  const [wareHouseList, setWarehouseList] = useState([]);

  // Filter for export
  let paramObj = {
    orderStatus: '',
    paymentStatus: '',
    startDate: '',
    endDate: '',
    warehouse: '',
  };
  const [param, setParam] = useState(paramObj);


  //convert date and set to timestamp for filter 
  const filterByDate = (date) => {
    let dateFrom = null, dateTo = null;
    if (date) {
      dateFrom = moment(date[0]).startOf('day').valueOf()
      dateTo = moment(date[1]).endOf('day').valueOf()
    }

    setParam((prevParam) => ({
      ...prevParam,
      startDate: dateFrom,
      endDate: dateTo,
    }));
  }


  //handel filter for export
  const handelParam = (name, value) => {
    setParam((prevParam) => ({
      ...prevParam,
      [name]: value
    }));
  }

  const openImportPopup = () => {
    setErrorMsg('');
    setImportPopup(true)
  };

  //Upload File 
  const handleFileDrop = (file) => {
    setErrorMsg("");
    setImportFile(fileObj);

    if (file && file[0] && allowedFileTypes.includes(file[0].type)) {
      setImportFile({ uploadFile: file[0] });
    } else {
      setErrorMsg("Choose a valid file!");
    }
  }
  // SUBMIT MULTIPLE INVENTORY FILE UPLOAD
  const submitMultipleUpload = (e) => {
    try {

      e.preventDefault();
      setUploadingLoader(true);
      setErrorMsg("");

      if (importFile.uploadFile && allowedFileTypes.includes(importFile.uploadFile.type)) {
        let params = new FormData()
        params.append('OrderListing', importFile.uploadFile)

        bulkUploadOrder(params)
          .then(res => {
            let response = res.data;
            if (response.statusCode === 1) {
              toast.success(response.responseData.message)
              setImportPopup(false)
            } else {
              setErrorMsg(response.error.errorMessage);
            }
          })

      } else {
        setErrorMsg("Choose a valid file!");
      }

      setUploadingLoader(false);

    } catch (e) {
      console.error(e);
      setUploadingLoader(false);
    }

  }

  const exportFile = async () => {
    try {
      setLoader(true);
      let res = await downloadOrderList(param);
      var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${moment() + '-Order-Lists'}.xlsx`);
      setLoader(false);
    } catch (error) {
      console.log("error", error);
      setLoader(false)
    }
  }


  //get warehouse list
  const getWarehouseList = () => {
    getWarehouse()
      .then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          let data = res.data.responseData;

          if (data.result && data.result.length > 0) {
            let allWarehouse = [];

            allWarehouse.push({
              label: "All",
              value: ""
            })


            data.result.forEach(key => {
              allWarehouse.push({
                label: key.name,
                value: key._id,
              })


            })

            setWarehouseList(allWarehouse);

          }
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })
  }

  useEffect(() => {
    getWarehouseList();
  }, []);

  return (
    <div className='body-container-wrapper'>
      {/* {/ {state.apiCalled && <Loader />} /} */}
      <div className='body-container'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
          <li className='breadcrumb-item'><Link to='/orders'>Orders</Link></li>
          <li className='breadcrumb-item'>Import & Export</li>

        </ol>
        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
        <div className='users_header'>
          <div className='container-fluid'>
            <div className="row align-items-center">

              <ul className="col-md-12">

                <li className="list-inline-item mt-2">
                  <label inline className="mr-2">Filter By Date:</label>
                  <RangePicker
                    style={{ height: "2.8em" }}
                    size={"middle"}
                    onChange={(val) => filterByDate(val)}
                    value={param.startDate && param.endDate && [moment(param.startDate), moment(param.endDate)]}
                    defaultValue={[moment(param.startDate), moment(param.endDate)]}
                  />
                </li>

                <li className="list-inline-item mt-2">
                  <Select
                    className='cm_min_width'
                    isSearchable
                    options={orderStatusOptions()}
                    placeholder='Order Status'
                    onChange={(e) => handelParam('orderStatus', e.value)}
                    value={param.orderStatus ? orderStatusOptions().find(element => element.value === param.orderStatus) : ""}
                    defaultValue={{ label: "All", value: "" }}
                  />
                </li>


                <li className="list-inline-item mt-2">
                  <Select
                    className='cm_min_width'
                    isSearchable
                    options={paymentStatusOptions()}
                    placeholder='Payment Status'
                    onChange={(e) => handelParam('paymentStatus', e.value)}
                    value={param.paymentStatus ? paymentStatusOptions().find(element => element.value === param.paymentStatus) : ""}
                    defaultValue={{ label: "All", value: "" }}
                  />
                </li>

                <li className="list-inline-item mt-2">

                  <Select
                    className='cm_min_width'
                    onChange={(e) => handelParam('warehouse', e.value)}
                    placeholder='Warehouse'
                    options={wareHouseList}
                    value={param.warehouse ? wareHouseList.find(element => element.value === param.warehouse) : ""}
                  />
                </li>

              </ul>
            </div>


            <div className='row col-md-12 text-right col-sm-12'>

              <button type="primary" onClick={openImportPopup} className="ml-1 mt-2 btn btn-primary">Import</button>
              <button type="primary" className="ml-1 mt-2 btn btn-primary" onClick={() => { exportFile() }}>Export</button>
            </div>




          </div>

          <Popup open={importPopup} closeOnDocumentClick closeOnDocumentClick={false} onClose={() => { setImportPopup(false) }}>
            <div className="cm_modal pl-5 pr-5">
              <span className="cm_modal_close" onClick={() => { setImportPopup(false) }}>&times; </span>
              <h3 className="text-center mb-4">Upload an excel file.</h3>

              <div className="btn_group">
                <div className="row">
                  <div className="col">
                    {errorMsg &&
                      <div
                        className="text-danger text-center h3"
                      >
                        {errorMsg}
                      </div>
                    }
                    <Dropzone
                      multiple={false}
                      onDrop={acceptedFiles => handleFileDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                        <section>
                          <div {...getRootProps()} className="dropzone text-center">
                            <input {...getInputProps()} />
                            <i className="fa fa-cloud-upload" />
                            {isDragActive ?
                              <p>Drop it like it's hot!</p>
                              :
                              acceptedFiles.length ?
                                <p>{acceptedFiles[0].name}</p>
                                :
                                <p>Drag file or click to upload</p>}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <button
                      className="btn btn-warning btn-sm btn-block"
                      disabled={uploadingLoader}
                      onClick={submitMultipleUpload}
                    >
                      {uploadingLoader ? 'Uploading.......' : 'Upload'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default ImportExport;