import React, { Component } from 'react';
import { getUserDietPlan } from '../../config/services/dietPlanService';
import { getAllFoods } from '../../config/services/FoodService';
import { toast } from 'react-toastify';
import FoodPlaceholder from '../../assets/images/FoodPlaceholder.jpg';
import EditDietPlan from './EditDietPlan';
import HOC from '../../HOC';
import { Link } from 'react-router-dom';
import { LoaderSvg } from '../Reusable'
import Chip from '@material-ui/core/Chip';
import dateFormat from 'dateformat';
import { Badge } from 'antd';
import DietPlanCard from '../common/DietPlan';

const CardWrapper = props => <div className="col mb-5">{props.children}</div>;

class ViewDietPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.id,
            dietPlanDetails: {},
            morningSnacksList: [],
            breakfastList: [],
            lunchList: [],
            dinnerList: [],
            midEveningDietList: [],
            midMorningDietList: [],
            bedTimeDietList: [],
            openEditPopUp: false,
            foodItems: [],
            loader: false,
        }
    }

    componentDidMount() {
        this.getUserDietPlanFunc();
        this.getAllFoodItems();
    }



    getAllFoodItems = () => {
        let self = this;
        getAllFoods()
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    var foodItems = [];
                    response.responseData.result.map((food, index) => {
                        foodItems.push({
                            label: food.foodName + " (" + food.calorieContent + " cal)",
                            value: food._id,
                            calorie: food.calorieContent,
                            protein: food.proteinContent,
                            fat: food.fatContent,
                            carb: food.carbContent,
                            fibre: food.fibreContent,
                            foodUnit: food.foodUnit
                        });
                    });
                    self.setState({ foodItems })
                } else if (response.statusCode == 0) {
                    console.log(res, "Getting Food items");
                }
            })
    }


    getUserDietPlanFunc = () => {
        let param = {
            params: { dietOfUserId: this.props.match.params.id }
        }

        let self = this;
        this.setState({ loader: true })
        getUserDietPlan(param)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    const { result } = response.responseData;
                    self.setState({
                        morningSnacksList: result ? result.morningSnacksOptions : [],
                        breakfastList: result ? result.breakfastOptions : [],
                        lunchList: result ? result.lunchOptions : [],
                        dinnerList: result ? result.dinnerOptions : [],
                        midEveningDietList: result ? result.midEveningOptions : [],
                        midMorningDietList: result ? result.midMorningOptions : [],
                        bedTimeDietList: result ? result.bedTimeOptions : [],
                        dietPlanDetails: {
                            dietPlanId: result ? result._id : "",
                            perDayCalories: result ? result.perDayCalories : "",
                            recommendedMorningSnacksCalories: result ? result.recommendedMorningSnacksCalories : 0,
                            recommendedBreakfastCalories: result ? result.recommendedBreakfastCalories : 0,
                            recommendedMidMorningCalories: result ? result.recommendedMidMorningCalories : 0,
                            recommendedLunchCalories: result ? result.recommendedLunchCalories : 0,
                            recommendedMidEveningCalories: result ? result.recommendedMidEveningCalories : 0,
                            recommendedDinnerCalories: result ? result.recommendedDinnerCalories : 0,
                            recommendedBedTimeCalories: result ? result.recommendedBedTimeCalories : 0,
                            morningSnacksDietTime: result ? result.morningSnacksDietTime : "00:00",
                            breakfastDietTime: result ? result.breakfastDietTime : "00:00",
                            midMorningDietTime: result ? result.midMorningDietTime : "00:00",
                            lunchDietTime: result ? result.lunchDietTime : "00:00",
                            midEveningDietTime: result ? result.midEveningDietTime : "00:00",
                            dinnerDietTime: result ? result.dinnerDietTime : "00:00",
                            bedTimeDietTime: result ? result.bedTimeDietTime : "00:00",
                            savedAsTemplate: result ? result.savedAsTemplate : '',
                            created: result ? result.created : '',
                            updated: result ? result.updated : '',
                        },
                        loader: false,
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleOpenEditPoup = () => this.setState({ openEditPopUp: !this.state.openEditPopUp });
    handleClose = () => {
        this.setState({ openEditPopUp: false })
    }


    render() {

        const {
            loader,
            morningSnacksList,
            breakfastList,
            lunchList,
            dinnerList,
            midEveningDietList,
            midMorningDietList,
            bedTimeDietList,
            dietPlanDetails,
        } = this.state;

        return (
            <HOC>

                {loader && <div className="loader_wrapper"><LoaderSvg /></div>}


                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dietPlans">Diet Plans</Link></li>
                        </ol>

                        {morningSnacksList.length || breakfastList.length || midMorningDietList.length || lunchList.length || midEveningDietList.length || dinnerList.length || bedTimeDietList.length ?
                            <div className="col mb-4">
                                <div className='align_date'>
                                    <Chip className="mr-3" label={"Assigned On " + dateFormat(dietPlanDetails.created, "dddd, mmmm dS, yyyy")} color="secondary" variant="outlined" />

                                    <Chip label={"Updated On " + dateFormat(dietPlanDetails.updated, "dddd, mmmm dS, yyyy")} color="secondary" variant="outlined" />
                                </div>


                                <h6 className="">Total Calorie Target: {dietPlanDetails.perDayCalories}/day
                                    <button className="btn btn-primary btn-sm __btn_abs" onClick={this.handleOpenEditPoup} >Edit Diet plan<i className="fa fa-pencil-square-o ml-1" />
                                    </button>
                                </h6>
                            </div> : null}



                        <CardWrapper>
                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Pre-Breakfast</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedMorningSnacksCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.morningSnacksDietTime ? dietPlanDetails.morningSnacksDietTime : '-'}</span>
                                </h6>
                            </div>


                            {!morningSnacksList.length ?
                                <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {morningSnacksList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.morningSnacksDiet || []} totalCalories={options.morningSnacksDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Breakfast</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedBreakfastCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.breakfastDietTime}</span>
                                </h6>
                            </div>

                            {!breakfastList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {breakfastList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.breakfastDiet || []} totalCalories={options.breakfastDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Mid Morning</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedMidMorningCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.midMorningDietTime}</span>
                                </h6>
                            </div>

                            {!midMorningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {midMorningDietList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.midMorningDiet || []} totalCalories={options.midMorningDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>
                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Lunch</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedLunchCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.lunchDietTime}</span>
                                </h6>
                            </div>

                            {!lunchList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {lunchList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.lunchDiet || []} totalCalories={options.lunchDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>

                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Mid Evening</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedMidEveningCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.midEveningDietTime}</span>
                                </h6>
                            </div>

                            {!midEveningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {midEveningDietList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.midEveningDiet || []} totalCalories={options.midEveningDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>

                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Dinner</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedDinnerCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.dinnerDietTime}</span>
                                </h6>
                            </div>

                            {!dinnerList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {dinnerList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.dinnerDiet || []} totalCalories={options.dinnerDietCalorie || []} />
                                    ))}
                                </div>
                            }
                        </CardWrapper>



                        <CardWrapper>
                            <div className="diet-plan-heading">
                                <h3 className="heading-title">Bed Time</h3>
                                <h6>Calorie Target :
                                    <span className="value">{dietPlanDetails.recommendedBedTimeCalories} cal</span>
                                </h6>
                                <h6>Timing :
                                    <span className="value">{dietPlanDetails.bedTimeDietTime}</span>
                                </h6>
                            </div>

                            {!bedTimeDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="diet-plan-wrapper">
                                    {bedTimeDietList.map((options, i) => (
                                        <DietPlanCard itrate={i} dietList={options.bedTimeDiet || []} totalCalories={options.bedTimeDietCalorie || []} />
                                    ))}
                                </div>}
                        </CardWrapper>

                    </div>
                </div>

                <EditDietPlan handleClose={this.handleClose} getUserDietPlan={this.getUserDietPlanFunc} {...this.state} />

            </HOC>
        )
    }


}

export default ViewDietPlan;

