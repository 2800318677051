import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";

import { getFitnessExercises, addFitnessExercise, editFitnessExercise, deleteFitnessExercise } from '../../config/services/exerciseService';
import { toast } from 'react-toastify';
import SearchSpinner from '../common/SearchSpinner';

class Exercise extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            exercises: [],
            search: '',
            count: 10,
            totalExercises: 15,
            pageNo: 1,
            sortType: '',
            sortField: '',
            AddExercisePopup: false,
            deleteExercisePopup: false,
            viewExercisePopup: false,
            exerciseImage: '',
            exerciseLink: '',
            exerciseDescription: '',
            title: '',
            imagePreview: '',
            titleErrorMsg: '',
            exerciseId: '',
            editExercisePopup: false,
            tempExerciseId: "",
            searchLoader: false
        }
    }


    handleImgPreivew = e => {
        let reader = new FileReader();
        let file = e.target.files[0];

        this.setState({ exerciseImage: e.target.files[0] })

        reader.addEventListener("load", () => {
            this.setState({ imagePreview: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }

    }



    openAddExercisePopup = () => this.setState({ AddExercisePopup: !this.state.AddExercisePopup });

    openDeleteExercisePopup = (data, exercise) => this.setState({ deleteExercisePopup: !this.state.deleteExercisePopup, tempExerciseId: exercise._id });

    deleteExercise = (e) => {
        e.preventDefault();
        const { tempExerciseId } = this.state
        let paramsObj = {
            fitnessExerciseId: tempExerciseId
        }
        deleteFitnessExercise(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getFitnessExercisesList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    openViewExercisePopup = exerciseId => {
        this.setState({ viewExercisePopup: !this.state.viewExercisePopup, exerciseId })
    };


    closeModal = () => this.setState({
        AddExercisePopup: false, deleteExercisePopup: false, viewExercisePopup: false,
        title: "", exerciseDescription: "", exerciseLink: "", exerciseImage: "", editExercisePopup: false, imagePreview: "", tempExerciseId: "", titleErrorMsg: '',
    });


    handleSearch = e => this.setState({ search: e.target.value, searchLoader: true }, () => this.getFitnessExercisesList());


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getFitnessExercisesList());


    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getFitnessExercisesList() });


    getFitnessExercisesList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let parems = `?search=${search}&count=${count}&pageNo=${pageNo - 1}&sortType=${sortType}&sortField=${sortField}`;
        getFitnessExercises(parems).then(res => {
            if (res.data.statusCode == 1) {
                this.setState({
                    exercises: res.data.responseData.exercises,
                    totalExercises: res.data.responseData.totalCount,
                    loader: false,
                    searchLoader: false
                })
            } else {
                this.setState({ loader: false, searchLoader: false })
            }
        })
    }




    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, titleErrorMsg: '' })
    }




    handleValidetion = () => {
        let validate = true;
        const { title, exerciseLink } = this.state;

        // Category Title Validate
        if (title === '' || title === undefined) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter Title' })
        } else if (exerciseLink === "" || title === undefined) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter Exercise Link' })
        } else {
            this.setState({ titleErrorMsg: '' })
        }

        return validate
    }




    handleSubmit = e => {
        e.preventDefault();

        if (this.handleValidetion()) {
            let { title, exerciseLink, exerciseDescription, exerciseImage } = this.state;
            let params = new FormData();
            params.append('title', title);
            params.append('exerciseLink', exerciseLink);
            params.append('exerciseDescription', exerciseDescription);
            params.append('exerciseImage', exerciseImage);
            this.addExerciseApi(params);
        }
    }

    addExerciseApi = (params) => {
        this.setState({ loader: true })
        addFitnessExercise(params).then(res => {
            if (res.data.statusCode == 1) {
                this.setState({ loader: false }, () => this.getFitnessExercisesList())
                this.closeModal();
                toast.success(res.data.responseData.message)
            } else if (res.data.statusCode == 0) {
                this.setState({
                    titleErrorMsg: res.data.error.errorMessage,
                    loader: false
                })
            }

        })
    }



    openEditExercisePopup = (data, exercise) => {
        this.setState({
            editExercisePopup: true,
            tempExerciseId: exercise._id,
            title: exercise.title,
            exerciseDescription: exercise.exerciseDescription,
            exerciseLink: exercise.exerciseLink,
            imagePreview: exercise.exerciseImage,
        })
    }

    editExerciseSubmit = (e) => {
        e.preventDefault();
        const { title,
            exerciseDescription,
            exerciseLink,
            exerciseImage, tempExerciseId } = this.state


        let paramsObj = new FormData();

        paramsObj.append('fitnessExerciseId', tempExerciseId)
        paramsObj.append('title', title)
        paramsObj.append('exerciseDescription', exerciseDescription)
        paramsObj.append('exerciseLink', exerciseLink)
        paramsObj.append('exerciseImage', exerciseImage)


        if (title == "") {
            this.setState({ titleErrorMsg: "Exercise title can't be empty!" })
        } else if (exerciseLink == "") {
            this.setState({ titleErrorMsg: "Exercise link can't be empty" })
        } else {
            this.setState({ titleErrorMsg: "" })
            this.editExerciseApi(paramsObj)
        }
    }

    editExerciseApi = (params) => {
        let self = this
        this.setState({ loader: true })

        editFitnessExercise(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getFitnessExercisesList();
                    this.setState({ loader: false })
                    this.closeModal();
                    toast.success(res.data.responseData.message)

                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false
                    })
                }
            })

    }

    handleEditSubmit = e => {
        e.preventDefault();
        this.setState({
            loader: true
        })

        if (this.handleValidetion()) {
            this.setState({
                AddExercisePopup: false,
            })

            let { title, exerciseLink, exerciseDescription, exerciseImage, exerciseId } = this.state;

            let params = new FormData();
            params.append('title', title);
            params.append('fitnessExerciseId', exerciseId);
            params.append('exerciseLink', exerciseLink);
            params.append('exerciseDescription', exerciseDescription);
            params.append('exerciseImage', exerciseImage);


            addFitnessExercise(params).then(res => {
                this.setState({
                    exerciseImage: '',
                    exerciseLink: '',
                    exerciseDescription: '',
                    title: '',
                    imagePreview: '',
                    titleErrorMsg: '',
                    loader: false,
                    editExercisePopup: false
                }, () => this.getFitnessExercisesList())
            })
        }
    }





    handleDelete = () => {
        this.setState({
            loader: true
        })


        let { exerciseId } = this.state;

        let params = {
            fitnessExerciseId: exerciseId
        }


        deleteFitnessExercise(params).then(res => {
            this.setState({
                deleteExercisePopup: false,
                exerciseId: ''
            }, () => this.getFitnessExercisesList())
        })
    }




    componentDidMount() {
        this.getFitnessExercisesList();
    }


    render() {

        const {
            loader,
            exercises,
            search,
            AddExercisePopup,
            exerciseImage,
            exerciseLink,
            exerciseDescription,
            title,
            imagePreview,
            titleErrorMsg,
            deleteExercisePopup,
            viewExercisePopup,
            exerciseId,
            pageNo,
            editExercisePopup,
            count,
            totalExercises,
            searchLoader
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Exercise</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Exercise</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search position-relative">
                                        {searchLoader ?
                                            <SearchSpinner type="text-info" />
                                            : null}
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by exercise title"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddExercisePopup}>Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort(0)}>Title &nbsp;
                                            <i className={`fa fa-sort-alpha-asc`}></i>
                                        </th>
                                        <th scope="col">Exercise Image</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort(1)}>Created &nbsp;
                                            <i className={`fa fa-sort-numeric-asc`}></i>
                                        </th>
                                        <th scope="col">Exercise Link</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        exercises.map((exercise, i) => {
                                            return (
                                                <tr key={exercise._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">{exercise.title}</span></td>
                                                    <td><img src={exercise.exerciseImage ? exercise.exerciseImage : noImage} className="exerciseImage" /></td>
                                                    <td className="cm_no_wrap">{dateFormat(exercise.created, "d mmmm yyyy")}</td>
                                                    <td className="cm_no_wrap"><a className="btn btn-dark btn-sm" href={exercise.exerciseLink} target="_blank">View on &nbsp;<i className="fa fa-youtube-play"></i> </a></td>
                                                    <td className="cm_no_wrap"><span className="cm_table_three_dots">{exercise.exerciseDescription ? exercise.exerciseDescription : 'N/A'}</span></td>
                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-primary btn-sm" onClick={() => this.openViewExercisePopup(exercise._id)}>View</button>&nbsp;&nbsp;
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openEditExercisePopup(data, exercise)} >Edit</button>&nbsp;&nbsp;
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteExercisePopup(data, exercise)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalExercises}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!exercises.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={AddExercisePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Exercise</h3>

                        {
                            titleErrorMsg ? <h4 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h4> : null
                        }
                        <form onSubmit={this.handleSubmit}>


                            <div className="form-group">
                                <label>Exercise Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    value={title}
                                    onChange={this.handleChange}
                                />


                            </div>


                            <div className="form-group">
                                <label>Exercise Link</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="exerciseLink"
                                    value={exerciseLink}
                                    onChange={this.handleChange}

                                />
                            </div>


                            <div className="form-group">
                                <label>Exercise Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="exerciseDescription"
                                    value={exerciseDescription}
                                    onChange={this.handleChange}

                                />
                            </div>



                            <div className="form-group">
                                <label>Exercise Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {
                                imagePreview ? <img className="exercise_category_img" src={imagePreview} /> : null
                            }

                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={editExercisePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Exercise</h3>

                        {
                            titleErrorMsg ? <h4 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h4> : null
                        }
                        <form onSubmit={this.editExerciseSubmit}>


                            <div className="form-group">
                                <label>Exercise Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    value={title}
                                    onChange={this.handleChange}
                                />


                            </div>


                            <div className="form-group">
                                <label>Exercise Link</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="exerciseLink"
                                    value={exerciseLink}
                                    onChange={this.handleChange}

                                />
                            </div>


                            <div className="form-group">
                                <label>Exercise Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="exerciseDescription"
                                    value={exerciseDescription}
                                    onChange={this.handleChange}

                                />
                            </div>



                            <div className="form-group">
                                <label>Exercise Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {
                                imagePreview ? <img className="exercise_category_img" src={imagePreview} /> : null
                            }

                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup>





                <Popup
                    open={deleteExercisePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center mb-4">Are you sure, you want to delete this Exercise?</h3>

                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteExercise}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>


                    </div>
                </Popup>




                <Popup
                    open={viewExercisePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>


                        {
                            exercises.map(el => {
                                if (el._id === exerciseId) {
                                    return (
                                        <div className="exercise_view_pop">
                                            <h3>{el.title}</h3>
                                            <p>{dateFormat(el.created, "d mmmm yyyy")}</p>


                                            <div className="row mt-4 mb-4">
                                                <div className="col-md-12">
                                                    <img className="img-fluid" src={el.exerciseImage} />
                                                </div>

                                            </div>

                                            <p>{el.exerciseDescription ? el.exerciseDescription : 'N/A'}</p>

                                        </div>
                                    );
                                }


                            })
                        }


                    </div>
                </Popup>



            </HOC>
        )
    }
}



export default Exercise;