import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';


const filterOption = [
    { value: 'pincode', label: 'Pincode' },
    { value: 'city', label: 'City' },
    { value: 'state', label: 'State' }
]

const Stores = () => {
    const [addStoreModal, setAddStoreModal] = useState(false);


    const closeModal = () => {
        setAddStoreModal(false)
    }


    return(
        <div className="body-container-wrapper">

            <Modal
                show={addStoreModal}
                size="lg"
                centered
                onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add new store</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="row">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Store code</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Store identifier</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Phone number</label>
                                <input type="tel" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Address Line 1</label>
                                <input type="tel" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Address Line 2</label>
                                <input type="tel" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Pin code</label>
                                <input type="tel" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>City</label>
                                <input type="tel" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>State</label>
                                <input type="tel" className="form-control" />
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-primary btn-block mt-4 mb">Save</button>
                </form>
            </Modal.Body>
       
            </Modal>


            <div className="body-container">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item">Stores</li>
                </ol>

                <div className="users_header">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between">
                            <h4 className="cm_page_heading">Stores</h4>
                            <div className="">
                                <span className="btn btn-dark cm_import_file">Import <input type="file" /></span>
                                <button className="btn btn-dark ml-2">Export</button>
                                <button onClick={() => setAddStoreModal(true)} className="btn btn-primary ml-2">Add store</button>
                            </div>
                        </div>


                        <div className="d-flex">
                            <ul>
                                <li className="list-inline-item mr-2">
                                    <Select
                                        className="cm_min_width"
                                        placeholder="Filter by"
                                        options={filterOption} />   
                                </li>
                                {/* <li className="list-inline-item mr-2">
                                    <Select
                                        className="cm_min_width"
                                        placeholder="Filter by pincode"
                                        options={demoOptions} />   
                                </li>
                                <li className="list-inline-item mr-2">
                                    <Select
                                        className="cm_min_width"
                                        placeholder="Filter by pincode"
                                        options={demoOptions} />   
                                </li> */}
                            </ul>

                            <div className="cm__search_box">
                                <i className="fa fa-search" />
                                <input type="text" placeholder="Search here......" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="table-responsive">
                    <table className="table table-bordered table-striped text-center">
                        <thead>
                            <tr>
                                <th>S. No</th>
                                <th>Store code</th>
                                <th>Store identifier</th>
                                <th>Phone number</th>
                                <th>Address Line1</th>
                                <th>Address Line2</th>
                                <th>Pin code</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                loop.map(item => (
                                    <tr>
                                        <td>{item}</td>
                                        <td>0000{item}</td>
                                        <td>OFPTL1</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Hyderabad</td>
                                        <td>Telangana</td>
                                        <td className="cm_actions">
                                            <i className="fa fa-trash" />
                                            <i className="fa fa-pencil" />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Stores;


const loop = [1,2,3,4,5,6,7,8,9]