import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { StepCounts, HeartRate, Calories, Sleepactivity, Weightscale, Waterintake } from '../../../assets/SvgIcon';
import { Tabs } from 'antd';
import WaterIntakeGraph from './WaterIntakeGraph'
import StepsGraph from './StepsGraph'
import HeartRateGraph from './HeartRateGraph'
import CaloriesGraph from './CaloriesGraph'
import SleepGraph from './SleepGraph'
import WeightGraph from './WeightGraph'
import {useWindowSize} from '../../../helper/customHooks'


const GraphsSidebar = (props) => {
    const isMobile = useWindowSize().width<768
    const isSidebarTop = useWindowSize().width<991
    return (
        <div className="body-container-wrapper">
            <div className="body-container">
                <div className="d-sm-flex justify-content-between border-bottom custom-list mb-3">
                    <ol className="breadcrumb breadcrumb-custom">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/users">Users</Link></li>
                        <li className="breadcrumb-item"><Link to={"/profile/" + props.match.params.id}>Profile</Link></li>
                    </ol>
                </div>
                <div className="tabs-wrapper">
                    <Tabs defaultActiveKey={3} tabPosition={isSidebarTop?"top":"left"} className='graph-sidebar-nav'>
                        <Tabs.TabPane  tab={<span className='Graph_Icons'> <Waterintake className="red" /> {isMobile?"":"Water Intake"}</span>} key={1}>
                            <WaterIntakeGraph userId={props.match.params.id}/>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={<span className='Graph_Icons'> <StepCounts /> {isMobile?"":"Steps"}</span>} key={2}>
                            <StepsGraph userId={props.match.params.id} />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={<span className='Graph_Icons'> <HeartRate /> {isMobile?"":"Heart Rate"}</span>} key={3}>
                            <HeartRateGraph userId={props.match.params.id} />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={<span className='Graph_Icons'> <Calories /> {isMobile?"":"Calories"}</span>} key={4}>
                            <CaloriesGraph userId={props.match.params.id} />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={<span className='Graph_Icons'> <Sleepactivity /> {isMobile?"":"Sleep Activity"}</span>} key={5}>
                            <SleepGraph userId={props.match.params.id} />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={<span className='Graph_Icons'> <Weightscale /> {isMobile?"":"Weight Scale"}</span>} key={6}>
                            <WeightGraph userId={props.match.params.id} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default GraphsSidebar;