import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { addBlog, editBlog, deleteBlog, getBlogListByAdmin } from '../../config/services/BlogService';
import { toast } from 'react-toastify';
import { Button, Modal, FormControl, Alert, Accordion, Card } from 'react-bootstrap';
import { BlogTypes } from './BlogTypes'
import SearchSpinner from '../common/SearchSpinner';


class Blog extends Component {
    constructor(props) {
        super(props);
        this.searchTimeOut = null
        this.state = {
            loader: true,
            blogsList: [],
            totalBlogs: 50,
            search: '',
            count: 10,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            selectedBlog: {},
            viewBlogPopup: false,
            tempBlogId: '',
            deleteBlogPopup: false,
            searchLoader: false
        }
    }

    componentDidMount() {
        this.getBlogsList()
    }

    getBlogsList = () => {
        let { search, count, pageNo, sortType, sortField, filterBy } = this.state;
        let paramsObj = { params: { search, limit: count, page: pageNo, sortType, sortField, filter: filterBy } }
        getBlogListByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        blogsList: response.responseData.result && response.responseData.result.blog ? response.responseData.result.blog : [],
                        totalBlogs: response.responseData.result && response.responseData.result.totalCount ? response.responseData.result.totalCount : 0,
                        loader: false,
                        searchLoader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, searchLoader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleViewBlogPopup = (blogDetails) => {
        this.setState({ viewBlogPopup: true, selectedBlog: blogDetails })
    }

    // parseBlogType = (blogType) => {
    //     let blogTypeLabel = BlogTypes.find(res => res.value == blogType)
    //     return blogTypeLabel ? blogTypeLabel.label : '-'
    // }

    handleClose = () => {
        this.setState({ viewBlogPopup: false, selectedBlog: {}, tempBlogId: '', deleteBlogPopup: false })
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getBlogsList() });

    // handleSearch = e => this.setState({ search: e.target.value }, () => this.getBlogsList());


    handleSearch = (e) => {
        this.setState({ search: e.target.value, searchLoader: true })

        if (this.searchTimeOut)
            clearTimeout(this.searchTimeOut);

        this.searchTimeOut = setTimeout(
            function () {
                this.getBlogsList()
            }
                .bind(this),
            500
        );
    }

    handleDeleteBlogPopup = (blogDetails) => {
        this.setState({ tempBlogId: blogDetails._id, deleteBlogPopup: true })
    }

    submitDeleteBlog = (e) => {
        e.preventDefault()
        let { tempBlogId } = this.state
        let params = { blogId: tempBlogId }

        deleteBlog(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.handleClose()
                    this.getBlogsList()
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }

    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getBlogsList());

    render() {
        let { loader, blogsList, totalBlogs, search, count, pageNo, selectedBlog, viewBlogPopup, deleteBlogPopup, searchLoader } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Blogs</li>
                        </ol>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading mt-1">Blogs</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search position-relative">
                                        {searchLoader ?
                                            <SearchSpinner type="text-info" />
                                            : null}
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by User Name......."
                                            onChange={(e) => { this.handleSearch(e) }}
                                        />
                                        <i className="fa fa-search" />
                                    </div>

                                    <div className="col-md-3 text-right">
                                        <Link className="btn btn-primary btn-block" to="/blogs/addBlog">Add Blog</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" >User's Name </th>
                                        <th scope="col" className="filter_text" >Blog Type</th>
                                        <th scope="col" className="filter_text">Short Description</th>
                                        <th scope="col" className="filter_text">Cover Image</th>
                                        <th scope="col" className="filter_text">Profile Image</th>
                                        <th scope="col" className="filter_text">Action &nbsp; </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        blogsList.map((details, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">
                                                        {details.name}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.blogType}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.shortDescription}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.coverImage ? <img className="card-img-top" src={details.coverImage} alt="Cover Image" /> : <div className="card-img-top">No Image</div>}
                                                    </span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {details.profileImage ? <img className="card-img-top" src={details.profileImage} alt="Profile Image" /> : <div className="card-img-top">No Image</div>}
                                                    </span>
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        <Button className='m-1' variant="outline-dark" onClick={() => this.handleViewBlogPopup(details)}> <i className="fa fa-eye" /> </Button>
                                                        <Link to={"/blogs/editBlog/" + details._id}> <Button className='m-1' variant="outline-info">
                                                            <i className="fa fa-pencil" /></Button></Link>
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleDeleteBlogPopup(details)}> <i className="fa fa-trash" /> </Button>
                                                    </td>
                                                    <td className="cm_no_wrap">{dateFormat(details.created, "d mmmm yyyy")}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalBlogs}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                            {!blogsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>
                    </div>
                </div>



                <Modal backdrop="static" show={viewBlogPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedBlog.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card card_content">
                            {selectedBlog.coverImage ? <img className="card-img-top" src={selectedBlog.coverImage} alt="Cover Image" /> : <div className="card-img-top">No Image</div>}
                            <span className="card_style_cat">Cover Image</span>
                        </div>


                        <div className="card card_content">
                            {selectedBlog.profileImage ? <img className="card-img-top" src={selectedBlog.profileImage} alt="Profile Image" /> : <div className="card-img-top">No Image</div>}
                            <span className="card_style_cat">Profile Image</span>
                        </div>

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Blog Type</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>{selectedBlog.blogType ? selectedBlog.blogType : '-'}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Short Description</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>{selectedBlog.shortDescription}</Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">Description</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div dangerouslySetInnerHTML={{ __html: selectedBlog.description }} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>


                <Modal backdrop="static" show={deleteBlogPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure, you want to delete this blog?</h6> </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Cancel</Button>
                        <Button variant="success" onClick={this.submitDeleteBlog}>Delete</Button>
                    </Modal.Footer>
                </Modal>

            </HOC>


        )
    }
}




export default Blog;