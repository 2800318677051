import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../loader';
import moment from 'moment';
import { convertToTimestamp ,timestampToDateFormat ,priceFormat} from "../../utils/commonFunctions";
import ReactImageFallback from "react-image-fallback";
import profilePicPlaceholder from '../../assets/images/profilePicPlaceholder.png'

import { ORDER_STATUS,ORDER_PAYMENT_STATUS } from "../../utils/constants";


let  desingCLass = 'shadow-sm p-3 mb-4 rounded';

const OrderInfo = (props) => {
  const {orderDetails}= props;
  const [errorMsg, setErrorMsg] = useState('');
  const [loader, setLoader] = useState(false);
  const [aboutOrderDetail, setAboutOrderDetail] = useState({});

  const setOrePropsValue = ()=>{
    setAboutOrderDetail(orderDetails);
  } 

  useEffect(()=>{
      setOrePropsValue();
  },[props.orderDetails]);

  return (
    <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
    {/* <TabTitle title="User Profile" /> */}
    <div className="row text-center">
    
 
        <div className="col-xl-12 col-lg-12 mb-5">
            <div className="row">
                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>User First Name:</b> {aboutOrderDetail.user && aboutOrderDetail.user.firstName?aboutOrderDetail.user.firstName:'' }</h5>
                </div>
                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>User Last Name:</b>{aboutOrderDetail.user && aboutOrderDetail.user.lastName?aboutOrderDetail.user.lastName:'' }</h5>
                </div>
                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>User Email:</b>{aboutOrderDetail.user && aboutOrderDetail.user.email?aboutOrderDetail.user.email:'' }</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Order Id:</b>{ aboutOrderDetail.orderId?aboutOrderDetail.orderId:'' }</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Order Status:</b>
                    {ORDER_STATUS[aboutOrderDetail.orderStatus]}</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Payment Status:</b>
                    {ORDER_PAYMENT_STATUS[aboutOrderDetail.paymentStatus]}
                    </h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Order Date:</b>{ aboutOrderDetail.created?timestampToDateFormat(convertToTimestamp(aboutOrderDetail.created)):'' }</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Source:</b>Website</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Coupon Applied:</b>{aboutOrderDetail.couponDetails  && aboutOrderDetail.couponDetails.couponName? aboutOrderDetail.couponDetails.couponName : ''}</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Coupon Amount:</b>{aboutOrderDetail.totalDiscount ? priceFormat(aboutOrderDetail.totalDiscount) : ''}</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Final Amount Paid:</b>{aboutOrderDetail.totalPrice ? priceFormat(aboutOrderDetail.totalPrice) : ''}</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Payment Confirmation Id:</b>{aboutOrderDetail.paymentId ? aboutOrderDetail.paymentId : ''}</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Expected Time Of Delivery (Based on WH ETA):</b>{aboutOrderDetail.ETA ? aboutOrderDetail.ETA : ''}</h5>
                </div>

                <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Invoice Number:</b>{aboutOrderDetail.invoice ? <a href={aboutOrderDetail.invoice} target="_blank">{aboutOrderDetail.invoiceNumber}</a> : ''}</h5>
                </div> 

            </div>
        </div>
    </div>
</div>
 );
};

export default OrderInfo;