var SearchTypes = [
    { value: 'city', label: "city", },
    { value: 'state', label: "state", },
    { value: 'pincode', label: "pincode", },
    { value: 'name', label: "name", },
    { value: 'manager', label: "manager", },
];

module.exports = {
    SearchTypes
}