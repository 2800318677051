import axios from 'axios';
import API from '../Api';

export function addQuestionsByAdmin(params) {
    return axios.post(API.ADD_QUESTION, params)
}
export function editQuestionsByAdmin(params) {
    return axios.post(API.EDIT_QUESTION, params)
}
export function approveQuestionsByAdmin(params) {
    return axios.post(API.APPROVE_QUESTION, params)
}
export function getQuestionsListByAdmin(params) {
    return axios.get(API.GET_QUESTIONS_LIST, params)
}
export function deleteQuestions(params) {
    return axios.post(API.DELETE_QUESTION, params)
}
