import axios from 'axios';
import API from '../Api';

export function getHolidays(params) {
    return axios.get(API.GET_HOLIDAYS,params)
}

export function addHoliday(params) {
    return axios.post(API.ADD_HOLIDAY,params)
}
