import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { Link } from 'react-router-dom';
import { getUserList } from '../../config/services/usersService';
import { LoaderSvg } from '../Reusable'
import { toast } from 'react-toastify';
import SearchSpinner from '../common/SearchSpinner';

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 1,
      users: [],
      count: 20,
      Loader: true,
      UserSearch: '',
      sortFieldValue: '',
      FilterStatus: false,
      sortField: 'created',
      sortFieldIcon: false,
      sortType: 2,
      userId: '',
      userActiveStatus: '',
      loading: false,
      totalUserCount: 0,
      filterUsers: '',
      assignDieticianPopup: false,
      dieticiansList: [],
      selectedDietician: '',
      assignToUserId: '',
      validationError: '',
      searchLoader: false
    }

    this.getUserList = this.getUserList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleUserSearch = this.handleUserSearch.bind(this);
    this.handleSortList = this.handleSortList.bind(this);
  }

  closeModal = () => this.setState({ assignDieticianPopup: false, selectedDietician: '', assignToUserId: '' })

  getUserList() {
    let { pageNo, UserSearch, sortField, sortType, filterUsers, count } = this.state

    let params = {
      pageNo: pageNo - 1,
      search: UserSearch,
      sortField,
      sortType,
      filterUsers,
      count,
    }

    getUserList(params)
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({
            users: res.data.responseData.users,
            totalUserCount: res.data.responseData.usersCount,
            Loader: false,
            searchLoader: false
          })
        }
      })
      .catch((err)=>{
        console.log("error",err);
        this.setState({
          searchLoader: false
        })
      })
  }



  handlePageChange(pageNumber) {
    this.setState({ pageNo: pageNumber, Loader: true }, () => {
      this.getUserList();
    });
  }


  handleUserSearch(e) {
    this.setState({ UserSearch: e.target.value, pageNo: 1, searchLoader: true }, () => {
      this.getUserList();
    });
  }



  handleSortList(sortField) {
    const { sortType } = this.state;

    let sortOrder = sortType === 1 ? 2 : 1

    this.setState({ sortField: sortField, Loader: true, sortType: sortOrder }, () => {
      this.getUserList();
      this.setState({
        sortFieldIcon: !this.state.sortFieldIcon
      })
    });
  }


  componentDidMount() {
    let token = localStorage.getItem('accessToken');
    if (token) {
      this.getUserList();
    }
  }


  hendleSortUsers = query => {
    this.setState({ Loader: true, filterUsers: query }, () => this.getUserList());
  }


  render() {
    const { pageNo, validationError, count, searchLoader } = this.state;
    return (
      <HOC>
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item">Users</li>
            </ol>


            {this.state.Loader
              ? <div className="loader_wrapper"><LoaderSvg /></div>
              : null}

            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-9">
                    <h4 className="cm_page_heading">Users</h4>
                  </div>
                  <div className="col-md-3 text-right cm_search position-relative">
                    {searchLoader ?
                      <SearchSpinner type="text-info" />
                      : null}
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by Name or Email"
                      onChange={this.handleUserSearch}
                      value={this.state.UserSearch}
                    />
                    <i className="fa fa-search" ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">Name <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList('firstName')} value="filterFirstName"></button></th>
                    <th scope="col">Assigned Dietician</th>
                    <th scope="col" className="filter_text">Email <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`} ></i><button onClick={() => this.handleSortList('email')} value="filterEmail"></button></th>
                    <th scope="col">Gender</th>
                    <th scope="col">Diet plan</th>
                  </tr>
                </thead>


                <tbody>
                  {
                    this.state.users &&
                    this.state.users.map((user, index) => {
                      return (
                        <tr key={user._id}>
                          <td>{((index + 1) + ((pageNo - 1) * count))}</td>
                          <td>
                            {user.firstName + " " + user.lastName}
                          </td>

                          <td>{user.assignedDietician ? user.assignedDietician.firstName + " " + user.assignedDietician.lastName : 'Not yet assigned'}</td>

                          <td>{user.email}</td>
                          <td>{user.gender === 1 ? 'Male' : 'Female'}</td>

                          <td>
                            <Link to={'/dietPlans/viewDietPlan/' + user._id} className="btn btn-info btn-sm">View</Link>
                          </td>

                        </tr>
                      )
                    })
                  }

                </tbody>

              </table>
              <Pagination
              activePage={this.state.pageNo}
              itemsCountPerPage={count}
              totalItemsCount={this.state.totalUserCount}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            />
              {this.state.users.length === 0 ? <h3 className="empty_error">Sorry, we couldn't find any content for <span>{this.state.UserSearch}</span></h3> : null}
            </div>
          </div>
        </div>


      </HOC>
    );
  }
}

export default UsersList;