import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";
import { getFoodRequests, changeRequestStatus } from '../../config/services/FoodService';

import { toast } from 'react-toastify';

class FoodRequests extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            foodRequests: [],
            pageNo: 1,
            count: 10,
            totalRequests: 200,
            tempRequestId: "",
            requestStatus: "",
            changeStatusPopup: false,
            validationError: "",
            search: "",
        }
    }

    componentDidMount() {
        this.getFoodRequests();
    }

    getFoodRequests = (params) => {
        this.setState({ loader: true })
        const { search,count,pageNo } = this.state
        let reqParams = {
            params: {
                search,count,pageNo:pageNo-1,
            }
        }
        getFoodRequests(reqParams)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({
                        foodRequests: response.responseData.result,
                        totalRequests:response.responseData.totalRequests,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    changeRequestStatus = (requestData, requestStatus) => {
        this.setState({ tempRequestId: requestData._id, requestStatus, changeStatusPopup: true })
    }

    submitChangeStatus = (e) => {
        e.preventDefault();
        const { tempRequestId, requestStatus } = this.state;

        let paramsObj = {
            requestId: tempRequestId,
            requestStatus,
        }
        this.setState({ loader: true })

        changeRequestStatus(paramsObj)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getFoodRequests();
                    this.setState({ loader: false })
                    this.handleClose();
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })


    }


    handleClose = () => {
        this.setState({
            tempRequestId: "",
            requestStatus: "",
            validationError: "",
            changeStatusPopup: false
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }


    handleSearch = (e) => {
        const { value } = e.target;
        this.setState({ search: value }, () => {
            this.getFoodRequests();
        })

    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => {
        this.getFoodRequests()
    });


    render() {
        const {
            loader,
            foodRequests,
            search,
            pageNo,
            count,
            totalRequests,
            changeStatusPopup,
            validationError,
            requestStatus,
            
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/foods">Food items</Link></li>
                            <li className="breadcrumb-item">Food requests</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="cm_page_heading">Food Requests</h4>
                                    </div>
                                    <div className="col-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Food Name"
                                            onChange={this.handleSearch}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text">Food Name &nbsp;
                                        </th>
                                        <th scope="col" className="filter_text">Request by &nbsp;
                                        </th>
                                        <th scope="col" className="filter_text">Requested on &nbsp;
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        foodRequests.map((foodItem, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td className="cm_no_wrap">{foodItem.foodName}</td>
                                                    <td className="cm_no_wrap">{foodItem.userDetails ? foodItem.userDetails.firstName + " " + foodItem.userDetails.lastName : ""}</td>

                                                    <td className="cm_no_wrap">{dateFormat(foodItem.created, "d mmmm yyyy")}</td>
                                                    <td className="cm_no_wrap">

                                                        {foodItem.status == 1 ? "Accepted" : foodItem.status == 2 ? "Rejected" :

                                                            <div>
                                                                <button className="btn btn-dark btn-sm" onClick={(data) => this.changeRequestStatus(foodItem, 1)}> Accept</button>&nbsp;&nbsp;
                                                             <button className="btn btn-danger btn-sm" onClick={(data) => this.changeRequestStatus(foodItem, 2)}>Reject</button>
                                                            </div>}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalRequests}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!foodRequests.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>
                    </div>
                </div>


                <Popup
                    open={changeStatusPopup}
                    closeOnDocumentClick
                    onClose={this.handleClose}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.handleClose}>
                            &times;
            </span>
                        <h4 className="text-center mb-4 mt-5 error_message">{validationError}</h4>

                        <h3 className="text-center mb-4">Are you sure, you want to {requestStatus == 1 ? "accept" : "reject"} this request?</h3>
                        <div className="btn_group">
                            <div className="row">

                                <div className="col"><button className="btn btn-danger btn-sm btn-block" onClick={this.submitChangeStatus}>Yes</button></div>
                                <div className="col"><button className="btn btn-warning btn-sm btn-block" onClick={this.handleClose}>No</button></div>
                            </div>
                        </div>
                    </div>
                </Popup>

            </HOC>
        )
    }
}



export default FoodRequests;