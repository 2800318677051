import React from 'react';
import Select from 'react-select';
const addCategory = (props) => {
    console.log(props)
    const {handleClose, addCategorySubmit, validationError, handleChange, categories, handleParentCategory } = props
    return (
        <div className="cm_modal">
        <span className="cm_modal_close" onClick={handleClose}>&times;</span>
        <h3 className="text-center">Add Help Video Category</h3>

        <h4 className="text-center mb-4 mt-5 error_message">{validationError}</h4>

        <form onSubmit={addCategorySubmit}>

            <div className="form-group">
                <label>Category Title</label>
                <input
                    type="text"
                    className="form-control"
                    name="categoryTitle"
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Parent Category</label>
                <Select
                    isSearchable
                    options={categories}
                    placeholder="Select a parent category"
                    onChange={(val) => handleParentCategory(val)}
                    className="mt-1 mb-1"
                />
            </div>

            <div className="text-center">
                <button className="btn btn-primary" type="submit">Add</button>
            </div>
        </form>
    </div>
    );
};

export default addCategory;