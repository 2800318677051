import axios from 'axios';
import API from '../Api';

const accessToken = localStorage.getItem('accessToken');


export function addStation(params) {
    return axios.post(API.ADD_STATION, params)
}

export function updateStation(params) {
    return axios.post(API.UPDATE_STATION, params)
}

export function deleteStation(params) {
    return axios.post(API.DELETE_STATION, params)
}

export function getStationsListByAdmin(params) {
    return axios.get(API.GET_STATIONS_BY_ADMIN, params)
}