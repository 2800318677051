import axios from 'axios';
import API from '../Api';

export function addTestimonial(params) {
    return axios.post(API.ADD_TESTIMONIAL, params)
}
export function editTestimonial(params) {
    return axios.post(API.EDIT_TESTIMONIAL, params)
}
export function getTestimonialByAdmin(params) {
    return axios.get(API.GET_TESTIMONIAL, params)
}
export function deleteTestimonial(params) {
    return axios.post(API.DELETE_TESTIMONIAL, params)
}
