import React, { useEffect, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import Smile from './../../assets/images/smile.svg'
//import { messageData } from './messageDate';
import moment from "moment";

const ViewAllMessages = ({ showMessagePopup, isMessagesVisible,roomChat,emojiList }) => {

  let getEmojiUrl=(emojiId)=>
{
   
    if(emojiList.length>0)
    {
      
        let emoji=emojiList.find(ele=>ele._id===emojiId);
      
        return emoji? emoji.emojiImageUrl : "";
    }
    else{
        return "";
    }
   
}
  return (
    <>
      <Modal centered={true}
        className="send-emoji-popup" title="All Messages"
        footer={null} onCancel={showMessagePopup}
        visible={isMessagesVisible}
        maskClosable={false}
      >
        <ul className="list-unstyled list-messages p-0 m-0 d-flex flex-column">
          {roomChat ,Array.isArray(roomChat)?
            roomChat.map((message,i) => (
              <li className="message"  key={i}>
                <div className="emojis-message m-0 py-2 d-flex align-items-center w-100">
                  <p className="single-msg d-flex align-items-center border-0">
                    <span className="time">
                      <em>{moment(message.postedAt).format("hh:mm:ss")}</em>
                    </span>
                    <span className="sender">{message.fromName}</span>
                    <span className="send-mgs">
                      <img src={getEmojiUrl(message.emojiId)} className="emoji" alt="image" />
                    </span>
                    <span className="receiver">
                      <em>{message.toName}</em>
                    </span>
                  </p>
                </div>
              </li>
            ))
          :""}
        </ul>
      </Modal>
    </>
  );
};

export default ViewAllMessages;