import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";
import { addCustomerServiceType, getCustomerServiceTypesList, updateCustomerServiceType, deleteCustomerServiceType } from '../../config/services/CustomerServiceTypesService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Menus } from '../template/Menus'


class CustomerServiceTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            customerServiceTypesList: [],
            customerServiceType: '',
            search: '',
            count: 10,
            totalCustomerServiceTypes: 15,
            pageNo: 1,
            sortType: '',
            sortField: '',
            AddCustomerServiceTypePopup: false,
            deleteCustomerServiceTypePopup: false,
            viewCustomerServiceTypePopup: false,
            title: '',
            titleErrorMsg: '',
            updateCustomerServiceTypePopup: false,
            customerServiceTypesId: ""
        }
    }


    componentDidMount() {
        this.getCustomerServiceTypesList();
    }


    handlePaths = (paths) => {
        this.setState({ accessiblePaths: paths })
    }


    getCustomerServiceTypesList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        getCustomerServiceTypesList(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        customerServiceTypesList: response.responseData.result.customerServiceTypes,
                        totalCustomerServiceTypes: response.responseData.result.customerServiceTypesCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    openAddCustomerServiceTypePopup = () => this.setState({ AddCustomerServiceTypePopup: !this.state.AddCustomerServiceTypePopup });

    closeModal = () => this.setState({
        AddCustomerServiceTypePopup: false,
        customerServiceType: '',
        deleteCustomerServiceTypePopup: false,
        updateCustomerServiceTypePopup: false,
        customerServiceTypeId: '',
        titleErrorMsg: ''
    });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, titleErrorMsg: '' })
    }


    handleSubmitCustomerServiceType = (e) => {
        e.preventDefault();
        let { customerServiceType } = this.state;
        let params = {
            customerServiceType
        }
        if (!customerServiceType) {
            this.setState({ titleErrorMsg: 'Enter the customer Service type' })
        } else {
            this.addCustomerServiceTypeApi(params)
        }
    }

    addCustomerServiceTypeApi = (params) => {
        addCustomerServiceType(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getCustomerServiceTypesList()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.closeModal()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    openDeleteCustomerServiceTypePopup = (data, customerServiceTypes) => this.setState({ deleteCustomerServiceTypePopup: !this.state.deleteCustomerServiceTypePopup, customerServiceTypeId: customerServiceTypes._id });


    deleteCustomerServiceType = (e) => {
        e.preventDefault();
        const { customerServiceTypeId } = this.state
        let paramsObj = {
            customerServiceTypeId: customerServiceTypeId
        }
        deleteCustomerServiceType(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getCustomerServiceTypesList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    // openViewCustomerServiceTypePopup = (data, customerServiceTypes) => {
    //     this.setState({
    //         viewCustomerServiceTypePopup: true,
    //         customerServiceType: customerServiceTypes.customerServiceType,
    //         accessiblePaths: customerServiceType.accessiblePaths,
    //     })
    // }

    openUpdateCustomerServiceTypePopup = (data, customerServiceTypes) => {

        this.setState({
            updateCustomerServiceTypePopup: true,
            customerServiceTypeId: customerServiceTypes._id,
            customerServiceType: customerServiceTypes.customerServiceType
        })
    }


    updateCustomerServiceTypeSubmit = (e) => {
        e.preventDefault();


        const { customerServiceTypeId, customerServiceType } = this.state
        let paramsObj = {
            customerServiceTypeId, customerServiceType
        }
        if (!customerServiceType) {
            this.setState({ titleErrorMsg: 'Enter the customer Service type' })
        } else {
            this.setState({ titleErrorMsg: "" })
            this.updateCustomerServiceTypeApi(paramsObj)
        }
    }

    updateCustomerServiceTypeApi = (params) => {
        updateCustomerServiceType(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getCustomerServiceTypesList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getCustomerServiceTypesList());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getCustomerServiceTypesList());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getCustomerServiceTypesList() });


    render() {
        const {
            loader,
            customerServiceTypesList,
            search,
            AddCustomerServiceTypePopup,
            titleErrorMsg,
            deleteCustomerServiceTypePopup,
            viewCustomerServiceTypePopup,
            pageNo,
            updateCustomerServiceTypePopup,
            count,
            totalCustomerServiceTypes,
            customerServiceType,
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Customer Service Types</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Customer Service Types</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by customer Service type"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddCustomerServiceTypePopup}>Add New Customer Service Type</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('customerServiceType')}>Customer Service Type   <i className={`fa fa-sort-alpha-asc`} />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        customerServiceTypesList.map((customerServiceTypes, i) => {
                                            return (

                                                <tr key={customerServiceTypes._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">{customerServiceTypes.customerServiceType}</span></td>
                                                    <td><span className="cm_table_three_dots">{dateFormat(customerServiceTypes.created, "d mmmm yyyy")}</span></td>

                                                    <td className="cm_no_wrap">
                                                        {/* <button className="btn btn-primary btn-sm" onClick={(data) => this.openViewCustomerServiceTypePopup(data, customerServiceType)}>View Permissions</button> */}

                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openUpdateCustomerServiceTypePopup(data, customerServiceTypes)} >Edit</button>

                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteCustomerServiceTypePopup(data, customerServiceTypes)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalCustomerServiceTypes}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!customerServiceTypesList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={AddCustomerServiceTypePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Customer Service Type</h3>
                        <form onSubmit={this.handleSubmitCustomerServiceType}>
                            <div className="form-group">
                                <label>Customer Service Type</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="customerServiceType"
                                    onChange={this.handleChange}
                                />
                            </div>

                            {titleErrorMsg ? <h5 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h5> : null}

                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={updateCustomerServiceTypePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit CustomerService Type</h3>
                        <form onSubmit={this.updateCustomerServiceTypeSubmit}>
                            <div className="form-group">
                                <label>Customer Service Type</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="customerServiceType"
                                    value={customerServiceType}
                                    onChange={this.handleChange}
                                />
                            </div>

                            {titleErrorMsg && <h5 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h5>}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup>


                <Popup
                    open={deleteCustomerServiceTypePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-5">Are you sure, you want to delete this CustomerServiceType?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteCustomerServiceType}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>


                {/* <Popup
                    open={viewCustomerServiceTypePopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <div className="card card_style">
                            <div className="card-body text-center">
                                <h3>{customerServiceType}</h3>
                                Accessible Paths:
                                <h4 className="">
                                    {accessiblePaths.map(path => {
                                        return (
                                            <span className="badge badge-info ml-2 mt-2">{path.title}</span>
                                        )
                                    })}
                                </h4>
                            </div>
                        </div>
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                    </div>
                </Popup> */}

            </HOC>
        )
    }
}



export default CustomerServiceTypes;