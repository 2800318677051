// Calculating age from birthdate
import _ from "lodash";

export function calculateAge(birthDate) {
    birthDate = new Date(birthDate);
    let otherDate = new Date();

    var years = otherDate.getFullYear() - birthDate.getFullYear();

    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() == birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }

    return years;
  }
  export let getQueryStringParams = (query) => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split("&")
          .reduce((params, param) => {
            let [key, value] = param.split("=");
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, " "))
              : "";
            return params;
          }, {})
      : {};
  };



 export  let getGender = (id) => {
    if (id == 1) {
      return "M";
    }
    else if (id == 2) {
      return "F";
    }
    else if (id == 3) {
      return "O";
    }
    else {
      return "O";

    }
  };



  export let categoryName = (catId,categories) => {
    let catgoryInfo = categories.find((e) => e.id === catId);
    if (catgoryInfo && catgoryInfo.name) {
      return catgoryInfo.name;
    } else {
      return "N/A";
    }
  };


   
  export let calulcateChatsEmojiCount = (chatData={}) => {
    let chats = chatData["chats"];
    if (chats) {
      return Object.values(chats).reduce((acc, ele) => acc + ele.length, 0);
    } else {
      return 0;
    }
  };

  export let getValidatedRowData=(data,_query,colName)=>{
    if(_query.isEnable==0)
    {
      
      let value0=_.get(data, `${colName}`);
      let value1=_.get(data, `userDetails.${colName}`);
     
      let value2=_.get(data, `exerciseData.${colName}`);
      let value3=_.get(data, `demographicData.${colName}`);

 

      if(value0)
      {
        return value0;
      }
      else if(value1)
      {
        return value1;
      }

      else if(value2)
      {
        return value2;
      }
      else if(value3)
      {
        return value2;
      }
     
      else{
        return false;
      }
      
    }
    else{
      let value0=_.get(data, `${colName}`);
      let value1=_.get(data, `demographicData.${colName}`);
      let value2=_.get(data, `exerciseData.${colName}`);

      if(value0)
      {
        return value0;
      }
      else if(value1)
      {
        return value1;
      }

      else if(value2)
      {
        return value2;
      }
     
      else{
        return false;
      }
      

    }


  }

  // find connected and non connected from joining history

 export  let checkConnected=(joiningHistory)=>{
    if(joiningHistory)
    {
      let check=joiningHistory.some(ele=> "deviceId" in ele);
      return check;
    }
    return false;
   

  
  }


  export let millisToMinutesAndSeconds=(duration) =>{
    var milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
  }

  export let secondsToMinHours=(totalSeconds)=>
  {
    if(totalSeconds <= 0){
      return ('00:00:00');
    }
    let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
let minutes = Math.floor(totalSeconds / 60);
let seconds = totalSeconds % 60;
     minutes = String(minutes).padStart(2, "0");
 hours = String(hours).padStart(2, "0");
seconds = String(seconds).padStart(2, "0");
return (hours + ":" + minutes + ":" + seconds);
  }