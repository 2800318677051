var ServiceTypes = [
    { value: 1, label: "INSTALLATION", },
    { value: 2, label: "REPAIR", },
    { value: 3, label: "UNINSTALLATION", },
    { value: 4, label: "REVISIT", }
];
//1=Intallation, 2=Repair, 3=Uninstallation,4=Revisit


var SearchTypes = [
    { value: 'fullName', label: "Customer Name", },
    { value: 'lastName', label: "Last Name", },
    { value: 'orderId', label: "Order Id", },
    { value: 'email', label: "Email", },
    { value: 'fullMobileNo', label: "Contact No", },
    { value: 'requestId', label: "Request Id", }
];

const OptionToHide = {
    HIDE_ACTION : "Hide Action",
    HIDE_SERVICE_TYPE : "Hide Service Type",
    HIDE_SERVICE_CATEGORY : "Hide Service Category",
    HIDE_GENERIC_IMPORT : "Hide Generic Import",
    HIDE_ADD_NEW_SERVICE : "Hide Add New Service",
    HIDE_IMPORT : "Hide Import",
    HIDE_BULK_EDIT : "Hide Bulk Edit",
    HIDE_EXPORT : "Hide Export",
    HIDE_ONLY_COCO_FOFO : "Coco Fofo Only",
    HIDE_CANCEL_STATUS : "Hide Cancel Status",
    HIDE_TABLE_OPTION_COFO : "Hide Assign CRM Agent"
}

const GYM_TYPE = {
    FRANCHISEE : "Franchisee",
    COMPANY_OWNED : "Company Owned",
    CORPORATE : "Corporate",
    P3 : "3P"
}

module.exports = {
    ServiceTypes,
    SearchTypes,
    OptionToHide,
    GYM_TYPE
}
