import axios from 'axios';
import API from '../Api';

export function getInventory (params) {
    return axios.get(API.INVENTRY, params)
}

export function addInventory (params) {
    return axios.post(`${API.INVENTRY}`, params)
}

export function editInventory (params) {
    return axios.put(`${API.INVENTRY}/detail/${params._id}`, params)
}

export function deleteInventoryService (id) {
    return axios.delete(`${API.INVENTRY}/detail/${id}`)
}

export function bulkUploadInventory (params) {
    return axios.post(API.BULK_UPLOAD_INVENTORY, params)
}

export function downloadInventoryList(params) {
    return axios({
        method: 'get',
        url: API.DOWNLOAD_INVENTORY_LIST,
        params,
        responseType: 'blob'
    })
    // return axios.get(API.DOWNLOAD_INVENTORY_LIST);
}