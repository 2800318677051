import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LoaderSvg } from '../Reusable';
import { getOrderFeedbackList } from '../../config/services/OrderFeedback';
import { ORDER_RATING_OPTIONS } from "../../utils/constants";
import EditFeedbackForm from './EditFeedbackForm';
import {  Tooltip } from 'antd';
import {showLimitWords } from "../../utils/commonFunctions";





const OrderFeedback = (props) => {
  const {orderDetails}= props;
  const [errorMsg, setErrorMsg] = useState('');
  const [loader, setLoader] = useState(false);
  const [aboutOrderDetail, setAboutOrderDetail] = useState({});
  const [feedbackList, setFeedbackList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateFormValues, setUpdateFormValues] = useState({});
  const [addEditPopup, setAddEditPopup] = useState(false);

  //set value after changing props
  const setOrePropsValue = ()=>{
    setAboutOrderDetail(orderDetails);
  } 

  //Get Order Feedback 
  const getFeedbackListing = () => {
    try {
      let _params = {
        params: {orderId:orderDetails._id},
      }

      if(orderDetails._id){
      setLoader(true);
      //get Feedback list 
      getOrderFeedbackList(_params).then(res => {
        let response = res.data
        if (response.statusCode == 1 && response.responseData && response.responseData.data.length>0 ) {
          setFeedbackList(response.responseData.data);
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      setLoader(false);

      });
    }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  }


  //filter rating by name 
const filterRatingByName= (array=[],name="")=>{

  let ratingArray= array.find(obj=> obj.name==name);
  if(ratingArray && ratingArray.value){
    return ratingArray.value;
  }else{
    return "";
  }
}

//set order detail after changing props
  useEffect(()=>{
      setOrePropsValue();
  },[props.orderDetails]);


//Get Feedback After changing list
  useEffect(()=>{
    getFeedbackListing();
},[props.orderDetails]);

  return (
    <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
    {/* <TabTitle title="User Profile" /> */}
    <div className="row text-center">
    
 
        <div className="col-md-12 mb-5">
            <div className="row">

            {loader
           ? <div className="loader_wrapper"><LoaderSvg /></div>
           : null}

            <div className='table-responsive mt-2'>
              <table className='table table-bordered table-striped text-center'>
                <thead>
                  <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">On Time <br/>Delivery Rating</th>
                    <th scope="col">Packaging <br/>Quality Rating</th>
                    <th scope="col">Installation and <br/> setup Rating</th>
                    <th scope="col">Product Quality <br/>Rating</th>
                    <th scope="col">Feedback</th>
                    <th scope="col">
                      <Tooltip placement="topLeft" className="d-flex align-items-center"
                       title="Your can able to update feedback on frontend">IS Editable </Tooltip></th>
                    <th scope="col">
                      <Tooltip placement="topLeft" className="d-flex align-items-center"
                       title="Feedback will show in product detail respective product ">IS Approved</Tooltip>
                        </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbackList && feedbackList.length > 0 && feedbackList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.productId && data.productId.productName ? data.productId.productName : ''}</td>
                      <td>
                     {  data.ratings ? filterRatingByName( data.ratings,ORDER_RATING_OPTIONS.ON_TIME_DELIVERY):'' }
                      </td>
                      <td>
                     {  data.ratings ? filterRatingByName( data.ratings,ORDER_RATING_OPTIONS.PACKAGING_QUALITY):'' }
                      </td>

                      <td>
                     {  data.ratings ? filterRatingByName( data.ratings,ORDER_RATING_OPTIONS.INSTALLATION_AND_SETUP):'' }
                      </td>
                      <td>
                     {  data.ratings ? filterRatingByName( data.ratings,ORDER_RATING_OPTIONS.PRODUCT_QUALITY):'' }
                      </td>
                     
                      <td>

                      {data.feedback ? 
                      <Tooltip placement="topLeft" className="d-flex align-items-center"
                       title={data.feedback}>
                             {showLimitWords(data.feedback,120)}
                      </Tooltip>
                      : ''}
                      </td>
                      <td>{data.isEditable && data.isEditable == true ? 'True' : 'False'}</td>
                      <td>{data.isApproved && data.isApproved == true ? 'True' : 'False'}</td>


                      <td> <button
                          className="btn btn-dark btn-sm"
                          onClick={() => {
                            setEditMode(true);
                            setUpdateFormValues(data);
                            setAddEditPopup(true);      
                          }}
                        >
                         Edit
                        </button>
                        &nbsp;</td>
                     
                     
                    </tr>
                  ))}
                </tbody>
              </table>

              <EditFeedbackForm
            editMode={editMode}
            addEditPopup={addEditPopup} 
            updateFormValues={updateFormValues} 
            setAddEditPopup={(val) => {
              setEditMode(false);
              setAddEditPopup(val);
            }}
            getList={() => { getFeedbackListing() }}
          />
              
              {feedbackList && feedbackList.length <1 ? (
                <h3 className="empty_error">
                  Sorry, We couldn't find any content!{" "}
                  {/* <span>{this.state.deviceSearch}</span> */}
                </h3>
              ) : null}
            </div>
  
         
            </div>
        </div>
    </div>
</div>
 );
};

export default OrderFeedback;