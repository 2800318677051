import axios from 'axios';
import API from '../Api';

export function addRole(params) {
    return axios.post(API.ADD_ROLE, params)
}

export function editRole(params) {
    return axios.post(API.EDIT_ROLE, params)
}

export function deleteRole(params) {
    return axios.post(API.DELETE_ROLE, params)
}

export function getRoles(params) {
    return axios.get(API.GET_ROLES, params)
}