import axios from 'axios';

import API from '../Api';

export function getAllPincodes (params) {
    return axios.get(API.GET_PINCODE_LIST, params)
}

export function addPincode (params) {
    return axios.post(API.ADD_PINCODE, params)
}

export function editPincodes (params) {
    return axios.post(API.EDIT_PINCODE, params)
}

export function bulkUploadPincode (params) {
    return axios.post(API.BULK_UPLOAD_PINCODES, params)
}

export function removePincode (params) {
    return axios.post(API.REMOVE_PINCODE, params)
}

// new API's integrated earlier would be phased out once all good!

export function getAllStates (params) {
    return axios.get(API.GET_STATE_LIST, params)
}

export function getAllCitys (params) {
    return axios.get(API.GET_CITYS_LIST, params)
}

export function getPincodesByVendorId (params) {
    return axios.get(API.GET_PINCODE_VENDOR_ID, params)
}


export function bulkPincodeImport (params) {
    return axios.post(API.BULK_PINCODE_IMPORT, params)
}

export function exportPincodeList(params) {
    //add multiple filter for export
    let _url=API.EXPORT_PINCODE_LIST + '?state=' + params.state + '&city='+ params.city+ '&warehouse1='+ params.warehouse1+ '&warehouse2='+ params.warehouse2+ '&search='+ params.search;
    return axios({
        method: 'GET',
        url: _url,
        responseType: 'blob'
    })
}


export function getDeliverablePincode (params) {
    return axios.get(API.GET_DELIVERABLE_PINCODE, params)
}

export function getWarehouse () {
    return axios.get(API.GET_WAREHOUSE)
}

export function postDeliverablePincode (params) {
    return axios.post(API.POST_DELIVERABLE_PINCODE, params)
}

export function editDeliverablePincode (params) {
    return axios.put(`${API.PUT_DELIVERABLE_PINCODE}/update/${params._id}`, params);
}

export function detailDeliverablePincode (id) {
    return axios.get(`${API.GET_DETAIL_DELIVERABLE_PINCODE}/detail/${id}`);
}