import React, { Component } from 'react';
import dateFormat from 'dateformat';
import ReactImageFallback from "react-image-fallback";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import HOC from '../../HOC';
import { getSelectedTimeSlots, sendOtp } from '../../config/services/usersService';
import { editUserProfile } from '../../config/services/adminService';
import profilePicPlaceholder from '../../assets/images/profilePicPlaceholder.png'
import TabTitle from './TabTitle'
import calculator from '../../utils/calculator'
import { convertCmToFt, convertFtToCm, convertKgToLbs, convertLbsToKg } from '../../utils/converters'
import { nameRegex, mobileRegex, emailRegex } from '../../utils/regexUtils'
import recommendedCalories from '../../utils/recommendedCalories'
import UpdateUserModule from '../users/UpdateUserPopup'

import { Button, Modal, FormControl, Form, Alert, FormGroup, FormLabel, Spinner, InputGroup, FormCheck, Row } from 'react-bootstrap';
import { getServices } from "../../config/services/serviceCategoriesService";
import { getAvailableTimeSlotsOfExpert } from "../../config/services/usersService";
import { bookTimeSlotsOfExpert } from "../../config/services/CallSchedules";
import { getTrainersByAvailableSlotV2, getUserFreeSessionsLeft, getUserPaymentDueSession } from "../../config/services/adminService";
import { DatePicker, Comment, Avatar } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import placeholderImage from './../../assets/images/profilePicPlaceholder.png';
class PersonalDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: '',
            selectedTimeSlots: [],
            profile: {
                weight: {},
                height: {},
                preferences: {},
                beforePic: [],
                afterPic: []
            },
            questionAnswer: [],
            loader: true,
            updateUserPopup: false,
            selectedUserData: {},
            otpActive: false,
            editLoader: false,
            validationError: '',

            isOpenBookSchedulePopup: false,
            isAvailabilityTimeSlotPopup: false,
            count: 100,
            pageNo: 1,
            videoSessionType: 1,
            dayOfWeek: '',
            availableTimeSlotMsg: '',
            paymentDue: false,
            freeAlreadyTaken: false,
            serviceCategories: [],
            serviceLists: [],
            getAllTimeSlots: [],
            getAllExpertAvailableTimeSlots: [],
            selectedServiceInfo: {},
            userFreeSession: {},
            searchScheduleDate: moment().startOf('day').valueOf(),
            selectScheduleStartDateFrom: moment().startOf('day').valueOf(),
            selectScheduleStartDateTo: moment().endOf('day').valueOf(),
            setDateFrom: new Date("2021/16/09")
        }
    }

    getSelectedTimeSlots = () => {
        let param = {
            params: { reqOfUserId: this.props.userId }
        }
        getSelectedTimeSlots(param)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    const { selectedTimeSlots } = response.responseData.result

                    selectedTimeSlots.map(slot => {
                        var t = this.convertTime12to24(slot.timeSlot);
                        slot.timeSlotMilliseconds = Number(t.split(':')[0]) * 60 + Number(t.split(':')[1]) * 1000;
                    })
                    selectedTimeSlots.sort((a, b) => (a.timeSlotMilliseconds > b.timeSlotMilliseconds) ? 1 : ((b.timeSlotMilliseconds > a.timeSlotMilliseconds) ? -1 : 0));
                    this.setState({ selectedTimeSlots })
                }
                else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
    }


    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }

    componentDidMount() {
        const { userId, dieticianDetails, profile } = this.props
        var allcategory = [
            { value: 1, label: 'Doctor' },
            { value: 2, label: 'Dietician' },
            { value: 3, label: 'Home workout' }
        ]

        this.setState({ userId, dieticianDetails, profile, serviceCategories: allcategory })
        this.getSelectedTimeSlots();

        this.getServicesList();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.profile !== state.profile || props.questionAnswer !== state.questionAnswer) {
            return {
                profile: props.profile,
                questionAnswer: props.questionAnswer
            };
        }
        return null;
    }

    handleEditUserOnChange = e => {
        let { name, value, checked } = e.target
        let { selectedUserData } = this.state

        switch (name) {
            case 'height':
            case 'weight':
                selectedUserData[name]['value'] = Number(value)
                break;

            case 'heightUnit':
                selectedUserData['height']['unit'] = Number(value)
                break;

            case 'weightUnit':
                selectedUserData['weight']['unit'] = Number(value)
                break;

            case 'isSmoker':
            case 'isDiabetes':
            case 'isHyperTension':
                selectedUserData['preferences'][name] = checked
                break;

            default:
                selectedUserData[name] = value
                break
        }
        this.setState({ selectedUserData, validationError: '' })
    }


    sendOtpFunc = e => {
        let { selectedUserData } = this.state
        let params = {
            countryCode: String(selectedUserData.countryCode),
            mobileNo: String(selectedUserData.mobileNo)
        }

        if (this.validateUserData()) {
            this.setState({ editLoader: true })
            sendOtp(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        toast.success(response.responseData.message)
                        this.setState({ otpActive: true, editLoader: false })
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, editLoader: false })
                    }
                })
        }
    }

    validateUserData = () => {
        let { firstName, lastName, mobileNo, email, dob, countryCode } = this.state.selectedUserData
        if (!nameRegex(firstName).success) {
            this.setState({ validationError: nameRegex(firstName).message })
            return false
        }
        else if (!nameRegex(lastName).success) {
            this.setState({ validationError: nameRegex(lastName).message })
            return false
        }
        else if (!mobileRegex(mobileNo).success) {
            this.setState({ validationError: mobileRegex(mobileNo).message })
            return false
        }
        else if (countryCode !== 91 && countryCode !== '91') {
            this.setState({ validationError: "Country code should be '91'" })
            return false
        }
        else if (!emailRegex(email).success) {
            this.setState({ validationError: emailRegex(email).message })
            return false
        }
        else {
            return true
        }
    }

    submitEditUser = (e) => {
        e.preventDefault()
        let { selectedUserData, profile } = this.state

        if (this.validateUserData()) {
            if (selectedUserData.height && selectedUserData.height.unit === 2) {
                selectedUserData.height.value = convertFtToCm(selectedUserData.height.value)
            }

            if (selectedUserData.weight && selectedUserData.weight.unit === 2) {
                selectedUserData.weight.value = convertLbsToKg(selectedUserData.weight.value)
            }

            let isMobileEdited = false

            if (profile.mobileNo != selectedUserData.mobileNo) {
                isMobileEdited = true
            }

            let params = {
                firstName: selectedUserData.firstName,
                lastName: selectedUserData.lastName,
                countryCode: String(selectedUserData.countryCode),
                mobileNo: String(selectedUserData.mobileNo),
                email: selectedUserData.email,
                otp: selectedUserData.otp,
                reqOfUserId: selectedUserData._id,
                height: selectedUserData.height,
                weight: selectedUserData.weight,
                preferences: selectedUserData.preferences,
                gender: selectedUserData.gender,
                dob: selectedUserData.dob,
                isMobileEdited
            }

            this.setState({ editLoader: true })
            editUserProfile(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.props.getUserProfile()
                        toast.success(response.responseData.message)
                        this.closeModal()
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, editLoader: false })
                    }
                })
        }
    }

    openEditUserPopup = () => {
        let { profile } = this.state
        let selectedUserData = JSON.parse(JSON.stringify(profile))

        if (profile.height && profile.height.unit === 2) {
            selectedUserData.height.value = convertCmToFt(selectedUserData.height.value)
        }

        if (profile.weight && profile.weight.unit === 2) {
            selectedUserData.weight.value = convertKgToLbs(selectedUserData.weight.value)
        }

        this.setState({ updateUserPopup: true, selectedUserData })
    }

    closeModal = () => {
        this.setState({ updateUserPopup: false, selectedUserData: {}, otpActive: false, editLoader: false, validationError: '', isOpenBookSchedulePopup: false, isAvailabilityTimeSlotPopup: false, selectedDate: new Date() })
    }

    handleSelectDate = (date, key) => {
        if (key === 'dob') {
            let { selectedUserData } = this.state
            selectedUserData.dob = new Date(date).getTime()
            this.setState({ selectedUserData })
        }
        else {
            this.setState({ [key]: date })
        }
    }

    // start booking schedule process
    getServicesList = () => {
        let { count, pageNo } = this.state;
        let paramsObj = {
            params: {
                count: count,
                pageNo: pageNo - 1
            }
        }
        getServices(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        serviceLists: res.data.responseData.result,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false, searchLoader: false })
                }
            })
    }

    getUserFreeSessionsLeftInfo = () => {
        let paramsObj = {
            params: {
                userId: this.props.userId
            }
        }
        getUserFreeSessionsLeft(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        userFreeSession: res.data.responseData.result,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false, searchLoader: false })
                }
            })
    }

    getUserPaymentDueSessionInfo = () => {
        let paramsObj = {
            params: {
                userId: this.props.userId
            }
        }
        getUserPaymentDueSession(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    // paymentDue
                    if (res.data.responseData.result && res.data.responseData.result._id) {
                        this.setState({
                            paymentDue: true,
                            loader: false
                        })
                    }
                } else {
                    this.setState({ loader: false, searchLoader: false })
                }
            })
    }

    openBookSchedulePopup = () => {
        this.getUserFreeSessionsLeftInfo();
        this.getUserPaymentDueSessionInfo();
        this.setState({ isOpenBookSchedulePopup: true, dayOfWeek: '', getAllTimeSlots: [], getAllExpertAvailableTimeSlots: [], selectedServiceInfo: {}, searchScheduleDate: moment().startOf('day').valueOf(), selectScheduleStartDateFrom: moment().startOf('day').valueOf(), selectScheduleStartDateTo: moment().endOf('day').valueOf() })
    }

    showBookSchedulePopup = () => {
        this.setState({ isOpenBookSchedulePopup: true, isAvailabilityTimeSlotPopup: false })
    }

    getAvailabilityTime = (val) => {
        const { selectScheduleStartDateFrom, selectScheduleStartDateTo } = this.state;
        this.setState({ isOpenBookSchedulePopup: false, isAvailabilityTimeSlotPopup: true, selectedServiceInfo: val }, function () {
            this.getAvailableTimeSlotsOfExpertLists();
        })
    }

    getAvailableTimeSlotsOfExpertLists = () => {
        const { freeAlreadyTaken, userFreeSession, videoSessionType, selectedServiceInfo, selectScheduleStartDateFrom, selectScheduleStartDateTo } = this.state;
        console.log('userFreeSession ', userFreeSession);
        console.log('selectedServiceInfo ', selectedServiceInfo);

        let isFreeAlreadyTaken = false;
        if (selectedServiceInfo.parentCategory == 1) {
            if (userFreeSession && userFreeSession.isDoctorSessionTaken) {
                isFreeAlreadyTaken = true;
            }
        }
        if (selectedServiceInfo.parentCategory == 2) {
            if (userFreeSession && userFreeSession.isDieticianSessionTaken) {
                isFreeAlreadyTaken = true;
            }
        }
        if (selectedServiceInfo.parentCategory == 3) {
            if (userFreeSession && userFreeSession.isTrainerSessionTaken) {
                isFreeAlreadyTaken = true;
            }
        }
        if (userFreeSession && userFreeSession.isAlreadyFreeSessionApply) {
            isFreeAlreadyTaken = true;
        }


        this.setState({
            freeAlreadyTaken: isFreeAlreadyTaken
        })

        let paramsObj = {
            params: {
                categoryId: selectedServiceInfo.parentCategory,
                videoSessionType: videoSessionType.toString(),
                dateFrom: selectScheduleStartDateFrom,
                dateTo: selectScheduleStartDateTo,
                serviceId: selectedServiceInfo._id
            }
        }

        getAvailableTimeSlotsOfExpert(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        getAllTimeSlots: res.data.responseData.result,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleDate = (val, key) => {
        let selectScheduleStartDateFrom = null, selectScheduleStartDateTo = null;
        selectScheduleStartDateFrom = moment(val).startOf('day').valueOf()
        selectScheduleStartDateTo = moment(val).endOf('day').valueOf()
        this.setState({ [key]: moment(val).format(), selectScheduleStartDateFrom: selectScheduleStartDateFrom, selectScheduleStartDateTo: selectScheduleStartDateTo })
    }

    handleTime = (val, key) => {
        this.setState({ [key]: val })
    }

    searchAvailableTimeSlotRequest = () => {
        // const { selectedServiceInfo, selectScheduleStartDateFrom,selectScheduleStartDateTo, selectScheduleTime } = this.state;
        this.setState({ loader: true, getAllExpertAvailableTimeSlots: [] }, function () {
            this.getTrainersByAvailableSlotV2Lists();
        })
    }


    getTrainersByAvailableSlotV2Lists = () => {
        const { videoSessionType, selectedServiceInfo, selectScheduleStartDateFrom, selectScheduleStartDateTo, selectScheduleTime } = this.state;
        console.log('selectedServiceInfo ', selectedServiceInfo);
        const setDate = new Date(selectScheduleStartDateFrom);
        const dayOfWeek = setDate.getDay() + 1;

        let paramsObj = {
            params: {
                dateFrom: selectScheduleStartDateFrom,
                dateTo: selectScheduleStartDateTo,
                dayOfWeek: dayOfWeek,
                timeSlot: selectScheduleTime && selectScheduleTime.value ? selectScheduleTime.value : '',
                serviceId: selectedServiceInfo._id,
                videoSessionType: videoSessionType.toString()
            }
        }

        let isAvailableTimeSlot = false;
        getTrainersByAvailableSlotV2(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    res.data.responseData.result && res.data.responseData.result.length && res.data.responseData.result.map((expert, i) => {
                        if (expert.isAvailable && !expert.isLeave) {
                            expert.callTimeSlots[dayOfWeek].length && expert.callTimeSlots[dayOfWeek]
                                .map((expertimeSlot, index) => {
                                    isAvailableTimeSlot = true;
                                })
                        }
                    })

                    this.setState({
                        getAllExpertAvailableTimeSlots: res.data.responseData.result,
                        dayOfWeek: dayOfWeek,
                        loader: false
                    })

                    if (!isAvailableTimeSlot) {
                        toast.error('No timeslot is available')
                    }
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    bookTimeSlotRequest = (expert, bookDetail) => {
        const { videoSessionType, selectedServiceInfo, searchScheduleDate } = this.state;
        // console.log('rkk expert:::', expert);
        // console.log('rkk bookDetail:::', bookDetail);
        let paramsObj = {
            userId: this.props.userId,
            expertId: expert._id,
            serviceId: selectedServiceInfo._id,
            callDate: moment(new Date(searchScheduleDate)).endOf('day').valueOf().toString(),
            callTimeSlot: bookDetail,
            videoSessionType: videoSessionType.toString()
        }

        bookTimeSlotsOfExpert(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    toast.success(res.data.responseData.message)
                    this.setState({
                        isAvailabilityTimeSlotPopup: false,
                        selectedDate: new Date(),
                        searchScheduleDate: moment().startOf('day').valueOf(),
                        selectScheduleStartDateFrom: moment().startOf('day').valueOf(),
                        selectScheduleStartDateTo: moment().endOf('day').valueOf(),
                        loader: false
                    })
                } else {
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    render() {
        let desingCLass = 'shadow-sm p-3 mb-4 rounded';
        const { email, status, countryCode, profilePic, firstName, dob, gender, lastName, mobileNo, fitnessCouponCode, dietCouponCode, couponAppliedOn, height, weight, preferences, bmr, bmi } = this.state.profile;
        const { selectedTimeSlots, profile, questionAnswer, updateUserPopup, selectedUserData, otpActive, editLoader, validationError,
            isOpenBookSchedulePopup, serviceCategories, serviceLists, isAvailabilityTimeSlotPopup, getAllTimeSlots, getAllExpertAvailableTimeSlots, dayOfWeek, userFreeSession, paymentDue, freeAlreadyTaken } = this.state;


        const { dieticianDetails } = profile
        let dietician
        if (dieticianDetails && dieticianDetails.length > 0) {
            dietician = dieticianDetails[0]
        }
        const calculate = calculator(height, weight, dob, gender)
        // const caloriesRecommended = recommendedCalories(calculate('BMR'), questionAnswer)
        const caloriesRecommended = recommendedCalories(bmr, questionAnswer)

        return (
            <HOC>
                <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
                    {/* <TabTitle title="User Profile" /> */}
                    <div className="row text-center">
                        <div className="col-xl-2 col-lg-3 mb-5">
                            <div className="user_profile_img shadow">
                                <ReactImageFallback
                                    src={profilePic}
                                    fallbackImage={profilePicPlaceholder}
                                />
                            </div>
                            <div className="text-center">
                                <span className={`text-white  badge   ${status === 1 ? 'badge-success' : 'badge-danger'}`}>{status === 1 ? 'Active' : 'Inactive'}</span>
                            </div>

                            <div className="d-flex flex-wrap profile-btn-wrapper">
                                <div className="col-6 px-1">
                                    <Link to={'/users/Graphs/' + this.state.userId} className="btn btn-primary mt-3 btn-block">View Stats</Link>
                                </div>
                                <div className="col-6 px-1">
                                    <Button className="btn btn-primary mt-3 btn-block" onClick={this.openEditUserPopup}> Edit User</Button>
                                </div>
                                <div className="col-12 px-1">
                                <Button className="btn btn-primary btn-block mt-3" onClick={this.openBookSchedulePopup}> Book Schedule </Button>
                                </div>
                            </div>

                            <div className="mt-3">
                                <span className="widget_label">Available Time Slots:</span> &nbsp;
                                <div className="">
                                    {selectedTimeSlots.length ? selectedTimeSlots.map((timeSlot, index) => (
                                        <span className="badge badge-pill badge-dark cm_slots" data-toggle="tooltip" data-placement="top" title="Time slots" key={index}>{timeSlot.timeSlot}</span>
                                    )) : "No slots selected"}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-10 col-lg-9 mb-5">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>First Name:</b> {firstName ? firstName : "-"}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Mobile Number:</b> {`${mobileNo}` ? `+${countryCode} - ${mobileNo}` : '-'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Gender:</b> {gender === 1 ? 'Male' : gender === 2 ? 'Female' : 'Not Set'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Weight:</b> {`${weight.value ? `${weight.value} Kg` : '-'}`}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>BMI:</b> {bmi ? bmi : "-"} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Diet Coupon:</b> {dietCouponCode ? dietCouponCode : 'Not Used yet'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Dietician Assigned:</b> {dietician ? dietician.firstName : 'Not assigned yet'} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Recommended Calories:</b> {caloriesRecommended ? caloriesRecommended : "-"} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Coupon Applied On:</b>{
                                        couponAppliedOn ? dateFormat(couponAppliedOn, "d mmmm yyyy") : "-"}</h5>
                                </div>

                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Last Name:</b>{lastName ? lastName : '-'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Email:</b> {email ? email : '-'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Date of Birth:</b>{dob ? dateFormat(dob, "d mmmm yyyy") : 'Not Set'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Height:</b> {`${height.value ? `${height.value} cm` : "-"} `}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>BMR:</b> {bmr ? bmr : '-'} </h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Smoker:</b> {preferences.isSmoker ? 'Yes' : 'No'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>HyperTension:</b> {preferences.isHyperTension ? 'Yes' : 'No'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Diabetes:</b> {preferences.isDiabetes ? 'Yes' : 'No'}</h5>
                                </div>
                                <div className="col-lg-4 col-md-6 ">
                                    <h5 className={desingCLass}><b>Fitness Coupon:</b> {fitnessCouponCode ? fitnessCouponCode : 'Not Used yet'}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {updateUserPopup ? <UpdateUserModule show={updateUserPopup} selectedUserData={selectedUserData} onHide={this.closeModal} onChange={this.handleEditUserOnChange}
                    sendOtpFunc={this.sendOtpFunc} otpActive={otpActive} submitEditUser={this.submitEditUser} validationError={validationError} editLoader={editLoader} handleSelectDate={this.handleSelectDate} userDetails={profile} /> : null}


                <Modal show={isOpenBookSchedulePopup} onHide={this.closeModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6>
                                Service Categories
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {serviceCategories && serviceCategories.length ? serviceCategories.map((category, i) => (
                            <div className="bookschedule" key={i}>
                                <div className="label-wrapper">
                                    <label className="field-label">{category.label}</label>
                                </div>
                                <div className="row" >
                                    {
                                        serviceLists && serviceLists
                                            .filter(service => service.parentCategory === category.value)
                                            .map((service, index) => {
                                                return (
                                                    <div className="col-md-3 px-2" onClick={() => this.getAvailabilityTime(service)} key={category._id}>
                                                        <div className="schedule-card">
                                                            <div className="scheduled-image-wrapper">
                                                                {
                                                                    service.serviceImage ? <img className="schedule-card-image" src={service.serviceImage} alt="Service image" /> : <div className="schedule-card-image">No Image</div>
                                                                }
                                                            </div>
                                                            <h6 className="person-title text-truncate">{service.serviceName}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    }
                                </div>

                            </div>
                        )) : null}
                    </Modal.Body>
                </Modal>

                <Modal show={isAvailabilityTimeSlotPopup} onHide={this.closeModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6>
                                <i className="fa fa-arrow-left cm_inline" onClick={() => this.showBookSchedulePopup()}></i>
                                {this.state.selectedServiceInfo ? this.state.selectedServiceInfo.serviceName : null}
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!paymentDue ?
                            <>
                                <Form >
                                    <div className='row border-bottom mb-3'>
                                        <div className='col-lg-4 col-xl-5'>
                                            <FormGroup>
                                                <FormLabel>Date</FormLabel>
                                                <DatePicker
                                                    //minDate={this.state.setDateFrom}
                                                    startDate={this.state.setDateFrom}
                                                    onChange={(val) => this.handleDate(val, 'searchScheduleDate')}
                                                    className="form-control"
                                                    defaultValue={moment(this.state.searchScheduleDate)}
                                                    minDate={moment().toDate()}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-4 col-xl-5'>
                                            <FormGroup>
                                                <FormLabel>Timing</FormLabel>
                                                <Select
                                                    isSearchable
                                                    placeholder='Search Time Slot'
                                                    options={getAllTimeSlots}
                                                    onChange={(val) => this.handleTime(val, 'selectScheduleTime')}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-4 col-xl-2 align-self-end pb-3'>
                                            <Button className="btn btn-block" variant="success" type="button" onClick={(e) => this.searchAvailableTimeSlotRequest(e)}>Search</Button>
                                        </div>
                                    </div>
                                </Form>

                                <div className='border-bottom mb-3 trainer-table-data'>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            {getAllExpertAvailableTimeSlots && getAllExpertAvailableTimeSlots.length ? getAllExpertAvailableTimeSlots.map((expert, i) => (
                                                <tr key={i}>
                                                    {expert.isAvailable && !expert.isLeave ?
                                                        <>
                                                            <td>
                                                                <div className="trainer-data-image">
                                                                    <img src={expert.profilePic ? expert.profilePic : placeholderImage} />
                                                                </div>
                                                            </td>
                                                            <td style={{ maxWidth: "225px" }}>
                                                                <div className="trainer-data">
                                                                    <h4 className="title-trainer text-break">
                                                                        {expert.firstName} {expert.lastName}
                                                                    </h4>
                                                                    <p className="trainer-speciality text-truncate">
                                                                        <span className="label">Speciality : </span>
                                                                        <span className="value">{expert.speciality}</span>
                                                                    </p>
                                                                    <p className="trainer-rating text-truncate">
                                                                        <span className="label">Rating : </span>
                                                                        <span className="value">{expert.rating}</span>
                                                                    </p>
                                                                    <p className="trainer-fees">
                                                                        <span className="label">Fees : </span>
                                                                        <span className="value">{!freeAlreadyTaken ? expert.price : 'Free'}</span>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="slot-column">
                                                                    {
                                                                        expert.callTimeSlots[dayOfWeek].length && expert.callTimeSlots[dayOfWeek]
                                                                            .map((expertimeSlot, index) => {
                                                                                return (
                                                                                    <Button className="ml-2" variant="success" type="button" onClick={(e) => this.bookTimeSlotRequest(expert, expertimeSlot)}> {expertimeSlot} </Button>
                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                            </td>

                                                        </>
                                                        :
                                                        null
                                                    }

                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="3" >
                                                        <p className="p-4 text-center">
                                                            Please select slot and press search
                                                        </p>
                                                    </td>
                                                </tr>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </>
                            : "This user's payment is due. so user can not book any timeslot. user has to pay his pending amount"}
                    </Modal.Body>
                </Modal>

            </HOC >
        );
    }
}

export default PersonalDetails;