import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Popup from "reactjs-popup";
import { DatePicker } from 'antd';
import Select from "react-select";
import { toast } from 'react-toastify';
import { editOrder } from '../../config/services/OrderManagementService';
import { orderStatusOptions } from "../../utils/commonFunctions";

const AddEditForm = (props) => {

  const { editMode, addEditPopup, updateFormValues, setAddEditPopup, getList } = props;
  const intialValues = {
    orderId:'',
    awbNumber:'',
    ewayBill:'',
    courierPartner:'',
    orderNotes:'',
    orderStatus:'',
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  const [apiErrorMSG, setApiErrorMSG] = useState({});

  // SUBMIT  FORM  (Only Edit Allow)
  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMSG({});
    setIsSubmitting(true);
    if (validate(formValues)) {
      setIsSubmittingLoader(true);

      //For Update
      if (formValues._id) {
        editOrder(formValues)
          .then((response) => {
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {
              toast.success(response.data.responseData.message);
              getList();
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error });
            setIsSubmittingLoader(false);
          });
      } else {
         console.error('add is not allow , only do update')
      }
    }
  };
  // HANDLE CHANGE FORM
  const handleChange = (event) => {
    let formValuesSubmited = formValues;
    const { name, value } = event.target;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  //set only for select type 
  const handelSelectChange = (name, value) => {

    let formValuesSubmited = formValues;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  }

  // VALIDATION FORM
  const validate = (values) => {
    let errors = {};

    if (!values.orderId) {
      errors.orderId = 'Required';
    }

    if (!values.orderStatus) {
      errors.orderStatus = 'Required';
    }

    if(!values.orderNotes){
      errors.orderNotes = 'Required';
    }
    
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (editMode) {
      setFormValues((prevState) => ({
        ...prevState,
        _id:updateFormValues._id,
        orderId:updateFormValues.orderId ? updateFormValues.orderId : '',
        orderStatus:updateFormValues.orderStatus ? updateFormValues.orderStatus : '',
      }));

    } else {
      setFormValues(intialValues);
    }
  }, [editMode, updateFormValues]);

  return (
    <Popup closeOnDocumentClick={false} open={addEditPopup} onClose={() => {
      setFormValues(intialValues);
      setFormErrors({});
      setApiErrorMSG({});
      setIsSubmittingLoader(false);
      setAddEditPopup(false);
    }}>
      <div className="cm_modal pl-5 pr-5">
        <span className="cm_modal_close" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>&times; </span>
        <h3 className="text-center mb-4 mt-5">{formValues._id ? 'Update record' : 'Add new record'} </h3>
        <div className="text-danger text-center h3"> </div>
        <form onSubmit={handleSubmit}>
          <p style={{ color: 'red' }} className={apiErrorMSG.status === 'error' ? 'error' : 'success'}>{apiErrorMSG.msg}</p>
          <div className="form-group">
            <label>Order Id(*)</label>

            <input
               disabled={true}
              type="text"
              className="form-control"
              placeholder="Order Id"
              name="orderId"
              maxLength="100"
              value={formValues.orderId}
            />
            {formErrors.orderId && <label style={{ color: 'red' }}>{formErrors.orderId}</label>}
        
          </div>

    
          
          <div className="form-group">
            <label>AWB Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="AWB Number"
              name="awbNumber"
              maxLength="100"
              onChange={handleChange}
              value={formValues.awbNumber}
            />
            {formErrors.awbNumber && <label style={{ color: 'red' }}>{formErrors.awbNumber}</label>}
          </div>
    

          <div className="form-group">
            <label>Eway Bill</label>
               <input
              type="text"
              className="form-control"
              placeholder="Eway Bill"
              name="ewayBill"
              maxLength="100"
              onChange={handleChange}
              value={formValues.ewayBill}
            />
            {formErrors.ewayBill && <label style={{ color: 'red' }}>{formErrors.ewayBill}</label>}
          </div>


          <div className="form-group">
            <label>Courier Partner</label>
               <input
              type="text"
              className="form-control"
              placeholder="Courier Partner"
              name="courierPartner"
              maxLength="50"
              onChange={handleChange}
              value={formValues.courierPartner}
            />
            {formErrors.courierPartner && <label style={{ color: 'red' }}>{formErrors.courierPartner}</label>}
          </div>


          <div className="form-group">
            <label>Order Notes (*)</label>

          <textarea 
          className="form-control"
          rows={4}
          cols={50} 
          name="orderNotes"
          maxlength="200"
          onChange={handleChange}
          value={formValues.orderNotes}
          ></textarea>
            {formErrors.orderNotes && <label style={{ color: 'red' }}>{formErrors.orderNotes}</label>}
          </div>

         
          <div className="form-group">
            <label>Order Status </label>
            <Select
              isSearchable
              options={orderStatusOptions("Select Status")}
              placeholder="Order Status"
              onChange={(e) => handelSelectChange("orderStatus", e.value)}
              value={formValues.orderStatus ? orderStatusOptions("Select Status").find(element => element.value === formValues.orderStatus) : ""}
              defaultValue={{ label: "Select Status", value: "" }}
            />
            {formErrors.orderStatus && <label style={{ color: 'red' }}>{formErrors.orderStatus}</label>}

          </div>
      

       

          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block mt-4" disabled={isSubmittingLoader}>
              {isSubmittingLoader && (<i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>)} Save
						</button>
          </div>

          <div className="form-group">
            <button type="button" className="btn btn-secondary btn-block mt-4" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>
              Cancel
						</button>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default AddEditForm;