var CallStatuses = [
    { value: null, label: "All", },
    { value: 0, label: "Pending", },
    { value: 1, label: "Accepted", },
    { value: 2, label: "Rejected", },
    { value: 3, label: "Canceled", },
    { value: 4, label: "Done", },
    { value: 5, label: "Not Done", },
    { value: 6, label: "On Going", },
];


var InvoiceFilters = [
    { value: null, label: "All", },
    { value: true, label: "With Invoice", },
    { value: false, label: "Without Invoice", },
];

var LiveSessionDevices = [
    { value: 1, label: "Bike", },
    { value: 2, label: "Treadmill", },
    // { value: 3, label: "Others", },
];

module.exports = {
    CallStatuses,
    InvoiceFilters,
    LiveSessionDevices
}
