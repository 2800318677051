import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { addTestimonial, editTestimonial, getTestimonialByAdmin, deleteTestimonial } from '../../config/services/TestimonialService';
import { toast } from 'react-toastify';
import { Button, Modal, FormControl, Alert, Accordion, Card } from 'react-bootstrap';
import SearchSpinner from '../common/SearchSpinner';


class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            testimonialsList: [],
            search: '',
            count: 10,
            totaltestimonials: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            validationError: '',
            editTestimonialPopup: false,
            tempTestimonialId: '',
            FilterStatus: false,
            filterBy: '',
            viewDetailsPopup: false,
            selectedTestimonial: {},
            name: '',
            description: '',
            age: '',
            deleteTestimonialPopup: false,
            addTestimonialPopup: false,

            profileImage: '',
            profileImagePreview: '',
            address: '',
            tagLine: '',
            searchLoader: false
        }
    }

    componentDidMount() {
        this.getTestimonials();
    }

    getTestimonials = () => {
        try {

            let { search, count, pageNo, sortType, sortField, filterBy } = this.state;
            let paramsObj = { params: { search, limit: count, page: pageNo, sortType, sortField, filter: filterBy } }

            getTestimonialByAdmin(paramsObj)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.setState({
                            testimonialsList: response.responseData && response.responseData.result && response.responseData.result.result ? response.responseData.result.result : [],
                            totaltestimonials: response.responseData && response.responseData.result && response.responseData.result.totalCount ? response.responseData.result.totalCount : 0,
                            loader: false,
                            searchLoader: false
                        })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, searchLoader: false })
                        toast.error(response.error.errorMessage)
                    }
                })

        } catch (error) {

            console.log(error);

        }

    }


    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }

    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getTestimonials());

    handleSearch = e => this.setState({ search: e.target.value, searchLoader: true }, () => this.getTestimonials());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getTestimonials() });


    handleEditTestimonialPopup = (story) => {
        let { name, profileImage, description, address, _id, tagLine } = story
        this.setState({ tempTestimonialId: _id, name, profileImagePreview: profileImage, description, address, tagLine, editTestimonialPopup: true })
    }

    handleClose = () => {
        this.setState({
            editTestimonialPopup: false, tempTestimonialId: '', viewDetailsPopup: false, validationError: '', selectedTestimonial: {}, name: '', description: '', loader: false, deleteTestimonialPopup: false, addTestimonialPopup: false, profileImage: '', profileImagePreview: '', address: '', tagLine: ''
        })
    }

    toggleFilterStatus = () => { this.setState({ FilterStatus: !this.state.FilterStatus }) }

    handleFilter = (filterBy) => {
        this.setState({ filterBy }, () => this.getTestimonials())
    }

    viewTestimonialPopup = (testimonial) => {
        this.setState({ viewDetailsPopup: true, selectedTestimonial: testimonial })
    }

    submitEditTestimonial = (e) => {
        e.preventDefault()
        let { name, profileImage, description, address, tempTestimonialId, tagLine } = this.state

        let params = new FormData()
        params.append('testimonialId', tempTestimonialId);
        params.append('name', name);
        params.append('profileImage', profileImage);
        params.append('description', description);
        params.append('address', address);
        params.append('tagLine', tagLine);


        if (this.isValid()) {
            this.setState({ loader: true })

            editTestimonial(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose()
                        this.getTestimonials()
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    handleImageChange = (e) => {
        let { name, value } = e.target
        let file = e.target.files[0];
        let previewFieldName = name + 'Preview'
        let fieldName = name
        let reader = new FileReader();
        reader.onloadend = () => { this.setState({ [previewFieldName]: reader.result }) }
        reader.readAsDataURL(file)
        this.setState({ [previewFieldName]: file })
        this.setState({ [fieldName]: e.target.files[0] });
    }

    handleDeleteTestimonialPopup = (testimonial) => { this.setState({ deleteTestimonialPopup: true, tempTestimonialId: testimonial._id }) }

    submitDeleteTestimonial = (e) => {
        e.preventDefault()
        let { tempTestimonialId } = this.state
        let params = { testimonialId: tempTestimonialId }

        deleteTestimonial(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.handleClose()
                    this.getTestimonials()
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }


    handleAddTestimonialPopup = () => { this.setState({ addTestimonialPopup: true }) }

    submitAddTestimonial = (e) => {
        e.preventDefault()
        let { name, profileImage, description, address, tagLine } = this.state

        let params = new FormData()
        params.append('name', name);
        params.append('profileImage', profileImage);
        params.append('description', description);
        params.append('address', address);
        params.append('tagLine', tagLine);

        if (this.isValid()) {
            this.setState({ loader: true })
            addTestimonial(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose()
                        this.getTestimonials()
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }



    isValid = () => {
        let { name, profileImagePreview, description, address, tagLine } = this.state

        if (!name) {
            this.setState({ validationError: 'Name cannot be empty!' })
            return false
        } else if (!profileImagePreview) {
            this.setState({ validationError: 'Upload profile pic!!' })
            return false

        } else if (!description) {
            this.setState({ validationError: 'Description cannot be empty!' })
            return false
        }
        // else if (!address) {
        //     this.setState({ validationError: 'Address cannot be empty!' })
        //     return false
        // }
        // else if (!tagLine) {
        //     this.setState({ validationError: 'Tag Line cannot be empty!' })
        //     return false
        // }
        else {
            return true
        }
    }


    render() {
        let {
            loader, testimonialsList, search, pageNo, count, totaltestimonials, editTestimonialPopup, validationError, viewDetailsPopup, selectedTestimonial, name, description, deleteTestimonialPopup, addTestimonialPopup, profileImagePreview, address, tagLine, searchLoader
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Testimonials</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">User's Testimonials</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search position-relative">
                                        {searchLoader ?
                                            <SearchSpinner type="text-info" />
                                            : null}
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name......."
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>

                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.handleAddTestimonialPopup}>Add New Testimonial</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" >User's Name </th>
                                        <th scope="col" className="filter_text"> Address </th>
                                        <th scope="col" className="filter_text"> Description</th>
                                        <th scope="col" className="filter_text"> Tag Line</th>
                                        <th scope="col" className="filter_text"> Profile Image</th>
                                        <th scope="col" className="filter_text">Action &nbsp; </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        testimonialsList.map((details, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">
                                                        {details.name}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {details.address}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.description}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.tagLine}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.profileImage ? <img className="card-img-top object-contain object-contain" src={details.profileImage} alt="Profile Image" /> : <div className="card-img-top">No Image</div>}
                                                    </span>
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        <Button className='m-1' variant="outline-dark" onClick={() => this.viewTestimonialPopup(details)}> <i className="fa fa-eye" /> </Button>
                                                        <Button className='m-1' variant="outline-info" onClick={() => this.handleEditTestimonialPopup(details)}> <i className="fa fa-pencil" /> </Button>
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleDeleteTestimonialPopup(details)}> <i className="fa fa-trash" /> </Button>
                                                    </td>
                                                    <td className="cm_no_wrap">{dateFormat(details.created, "d mmmm yyyy")}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totaltestimonials}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!testimonialsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>

                <Modal backdrop="static" show={addTestimonialPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title> {name} </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-custom-bar-margin">
                        <div className="card card_content">
                            {profileImagePreview ? <img className="card-img-top object-contain" src={profileImagePreview} alt="Cover Image" /> : <div className="card-img-top">No Image</div>}
                            <span className="card_style_cat">Profile Image</span>
                            <div class="radio_wrapper text-center">
                                <input type="file" onChange={this.handleImageChange} id="profileImage" name="profileImage" />
                            </div>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Name:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <FormControl
                                            placeholder="name"
                                            value={name}
                                            name='name' onChange={this.handleChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Address:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <FormControl
                                            placeholder="address"
                                            value={address}
                                            name='address' onChange={this.handleChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Description:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body> <FormControl
                                        placeholder="Description"
                                        value={description}
                                        name='description' onChange={this.handleChange} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Tag Line:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body> <FormControl
                                        placeholder="Tagline...."
                                        value={tagLine}
                                        name='tagLine' onChange={this.handleChange} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                        <Button variant="success" onClick={this.submitAddTestimonial}>Submit</Button>
                    </Modal.Footer>
                </Modal>


                <Modal backdrop="static" show={editTestimonialPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{name}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-custom-bar-margin">
                        <div className="card card_content">
                            {profileImagePreview ? <img className="card-img-top object-contain" src={profileImagePreview} alt="Profile Image" /> : <div className="card-img-top">No Image</div>}
                            <span className="card_style_cat">Profile Image</span>
                            <div class="radio_wrapper text-center">
                                <input type="file" onChange={this.handleImageChange} id="profileImage" name="profileImage" />
                            </div>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Name:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <FormControl
                                            placeholder="name"
                                            value={name}
                                            name='name' onChange={this.handleChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Address:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <FormControl
                                            placeholder="address"
                                            value={address}
                                            name='address' onChange={this.handleChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">Description:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body> <FormControl
                                        placeholder="Description"
                                        value={description}
                                        name='description' onChange={this.handleChange} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">Tag Line:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body> <FormControl
                                        placeholder="Tagline...."
                                        value={tagLine}
                                        name='tagLine' onChange={this.handleChange} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                        <Button variant="success" onClick={this.submitEditTestimonial}>Submit</Button>
                    </Modal.Footer>
                </Modal>



                <Modal backdrop="static" show={viewDetailsPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedTestimonial.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-custom-bar-margin">
                        <div className="card card_content">
                            {selectedTestimonial.profileImage ? <img className="card-img-top object-contain" src={selectedTestimonial.profileImage} alt="Profile Image" /> : <div className="card-img-top">No Image</div>}
                            <span className="card_style_cat">Profile Image</span>
                        </div>

                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Address:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>{selectedTestimonial.address}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Description:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>{selectedTestimonial.description}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal backdrop="static" show={deleteTestimonialPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure, you want to delete this testimonial?</h6> </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Cancel</Button>
                        <Button variant="success" onClick={this.submitDeleteTestimonial}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </HOC>


        )
    }
}




export default Testimonial;