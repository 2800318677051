import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getReviewsListByAdmin, approveReviewByAdmin, editReviewsByAdmin, deleteReview } from '../../config/services/Ratings';
import { getQuestionsListByAdmin, addQuestionsByAdmin, editQuestionsByAdmin, deleteQuestions, approveQuestionsByAdmin } from '../../config/services/ProductQuestions';

import { toast } from 'react-toastify';
import { Button, Modal, FormControl, Alert, Accordion, Card } from 'react-bootstrap';
import { PlanFeatureComponents } from './PlanFeatureComponents'
import StarRatings from 'react-star-ratings';
import { Switch } from 'antd';


export default class Ratings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            questionsList: [],
            search: '',
            count: 10,
            totalQuestions: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            validationError: '',
            editQuestionPopup: false,
            tempQuestionId: '',
            FilterStatus: false,
            filterBy: '',
            viewDetailsPopup: false,
            selectedReview: {},
            name: '',
            description: '',
            age: '',
            deleteQuestionPopup: false,
            addTestimonialPopup: false,

            productType: this.props.match.params.id,
            headLine: '',
            review: '',
            rating: 0,
            userData: {},
            addQuestionPopup: false,
            question: ''
        }
    }

    componentDidMount() {
        this.getQuestions();
    }



    getQuestions = () => {
        let { search, count, pageNo, sortType, sortField, productType } = this.state;
        let paramsObj = { params: { search, limit: count, page: pageNo, sortType, sortField, productType } }
        getQuestionsListByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        questionsList: response.responseData.result.user,
                        totalQuestions: response.responseData.result.totalCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }

    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getQuestions());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getQuestions());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getQuestions() });


    handleEditQuestionPopup = (question) => {
        this.setState({ question: question.question, tempQuestionId: question._id, editQuestionPopup: true })
    }

    handleClose = () => {
        this.setState({
            editQuestionPopup: false, tempQuestionId: '', viewDetailsPopup: false, validationError: '', selectedReview: {}, name: '', description: '', loader: false, deleteQuestionPopup: false, addTestimonialPopup: false, profileImage: '', profilePreview: '', address: '', headLine: '',
            review: '',
            rating: 0,
            userData: {},
            addQuestionPopup: false, question: ''
        })
    }

    toggleFilterStatus = () => { this.setState({ FilterStatus: !this.state.FilterStatus }) }

    handleFilter = (filterBy) => {
        this.setState({ filterBy }, () => this.getQuestions())
    }

    viewReviewPopup = (selectedReview) => {
        this.setState({ viewDetailsPopup: true, selectedReview })
    }

    submitAddQuestion = (e) => {
        e.preventDefault()
        let { question, productType } = this.state

        let params = {
            question,
            productType
        }

        if (this.isValid()) {
            this.setState({ loader: true })
            addQuestionsByAdmin(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose()
                        this.getQuestions()
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    submitEditQuestion = (e) => {
        e.preventDefault()
        let { question, tempQuestionId } = this.state
        let params = {
            question,
            questionId: tempQuestionId
        }

        if (this.isValid()) {
            this.setState({ loader: true })
            editQuestionsByAdmin(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose()
                        this.getQuestions()
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    handleImageChange = (e) => {
        let { name, value } = e.target
        let file = e.target.files[0];
        let previewFieldName = name + 'Preview'
        let fieldName = name
        let reader = new FileReader();
        reader.onloadend = () => { this.setState({ [previewFieldName]: reader.result }) }
        reader.readAsDataURL(file)
        this.setState({ [previewFieldName]: file })
        this.setState({ [fieldName]: e.target.files[0] });
    }

    handleDeleteQuestionPopup = (question) => { this.setState({ deleteQuestionPopup: true, tempQuestionId: question._id }) }

    submitDeleteQuestion = (e) => {
        e.preventDefault()
        let { tempQuestionId } = this.state
        let params = { questionId: tempQuestionId }

        deleteQuestions(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.handleClose()
                    this.getQuestions()
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }



    isValid = () => {
        let { question } = this.state
        if (!question) {
            this.setState({ validationError: 'Question cannot be empty!' })
            return false
        } else {
            return true
        }
    }

    parseProductType = (productType) => {
        let ProductType = PlanFeatureComponents.find(option => option.value === productType)
        return ProductType ? ProductType['label'] : "-"
    }

    handleQuesAnsStatus = (status, question, answer) => {

        let params = {
            questionId: question._id,
            answerId: answer ? answer._id : '',
            isApprove: status ? 1 : 0
        }

        if (answer) {
            this.setState({ [answer._id]: true })
        }

        if (question) {
            this.setState({ [question._id]: true })
        }



        approveQuestionsByAdmin(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.getQuestions()

                    if (answer) {
                        this.setState({ [answer._id]: false })
                    }

                    if (question) {
                        this.setState({ [question._id]: false })
                    }


                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    if (answer) {
                        this.setState({ [answer._id]: false })
                    }

                    if (question) {
                        this.setState({ [question._id]: false })
                    }
                    toast.error(response.error.errorMessage)
                }
            })
    }

    changeRating = (stars) => {
        this.setState({ rating: stars })
    }

    openAddQuestionPopup = () => {
        this.setState({ addQuestionPopup: true })
    }


    render() {
        let {
            loader, questionsList, search, pageNo, count, totalQuestions, editQuestionPopup, validationError, viewDetailsPopup, selectedReview, name, deleteQuestionPopup, headLine, review, rating, userData, addQuestionPopup, question } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/guaranteedPlanFeatures">Guaranteed Plan Features</Link></li>
                            <li className="breadcrumb-item">Questions</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">User's Questions</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search By Question......."
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddQuestionPopup}>
                                            Add New Question</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive pb-5">
                            <div className="cm_quition_list">
                                <ol>
                                    {questionsList.map((question, index) => (
                                        <li key={index}>
                                            <span className="product_ques_tag">{this.parseProductType(question.productType)}</span>
                                            <p className="mb-3">
                                                <Switch className='ml-1 mr-1' checked={question.isApprove} onChange={(e) => this.handleQuesAnsStatus(e, question)}
                                                    loading={this.state[question._id]} size='small' />
                                                {((index + 1) + ((pageNo - 1) * count))}, {question.question}


                                                <button className="btn btn-primary btn-xs ml-2" onClick={() => this.handleEditQuestionPopup(question)} ><i className="fa fa-pencil-square-o" /></button>
                                                <button className="btn btn-danger btn-xs ml-2" onClick={() => this.handleDeleteQuestionPopup(question)}><i className="fa fa-trash" /></button>
                                            </p>

                                            {question.answers ? question.answers.map((answer, i) => (
                                                <p key={i} className="cm_options"> {i + 1}.  {answer.text}
                                                    <Switch className='ml-1' checked={answer.isApprove}
                                                        onChange={(e) => this.handleQuesAnsStatus(e, question, answer)}
                                                        loading={this.state[answer._id]} size='small'
                                                    />
                                                </p>
                                            )) : null}
                                        </li>
                                    ))}
                                </ol>
                            </div>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalQuestions}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                            {!questionsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>

                    </div>
                </div>


                <Modal show={addQuestionPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{name}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Enter Question:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <FormControl placeholder="Question..........." name='question' onChange={this.handleChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                        <Button variant="success" onClick={this.submitAddQuestion}>Submit</Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={editQuestionPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Update Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Enter Question:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <FormControl value={question} name='question' onChange={this.handleChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                        <Button variant="success" size='sm' onClick={this.submitEditQuestion}>Update</Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={deleteQuestionPopup} onHide={this.handleClose} centered >
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure, you want to delete this question?</h6> </Modal.Title>
                    </Modal.Header>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="success" size='sm' onClick={this.handleClose}>Cancel</Button>
                        <Button variant="danger" size='sm' onClick={this.submitDeleteQuestion}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </HOC>


        )
    }
}
