import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { addTestimonial, editTestimonial, getTestimonialByAdmin, deleteTestimonial } from '../../config/services/TestimonialService';
import { getReviewsListByAdmin, approveReviewByAdmin, editReviewsByAdmin, deleteReview } from '../../config/services/Ratings';

import { toast } from 'react-toastify';
import { Button, Modal, FormControl, Alert, Accordion, Card } from 'react-bootstrap';
import { PlanFeatureComponents } from './PlanFeatureComponents'
import StarRatings from 'react-star-ratings';
import { Switch } from 'antd';


export default class Ratings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            reviewsList: [],
            search: '',
            count: 10,
            totalReviews: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            validationError: '',
            editReviewPopup: false,
            tempReviewId: '',
            FilterStatus: false,
            filterBy: '',
            viewDetailsPopup: false,
            selectedReview: {},
            name: '',
            description: '',
            age: '',
            deleteReviewPopup: false,
            addTestimonialPopup: false,

            productType: this.props.match.params.id,
            headLine: '',
            review: '',
            rating: 0,
            userData: {},
            toggleLoader: false
        }
    }

    componentDidMount() {
        this.getRatings();
    }



    getRatings = () => {
        let { search, count, pageNo, sortType, sortField, productType } = this.state;

        let paramsObj = { params: { search, limit: count, page: pageNo, sortType, sortField, productType } }

        getReviewsListByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        reviewsList: response.responseData.result.user,
                        totalReviews: response.responseData.result.totalCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }

    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getRatings());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getRatings());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getRatings() });


    handleEditReviewPopup = (story) => {
        let { headLine, review, rating, _id, userData } = story
        this.setState({ tempReviewId: _id, headLine, review, rating, editReviewPopup: true, userData })
    }

    handleClose = () => {
        this.setState({
            editReviewPopup: false, tempReviewId: '', viewDetailsPopup: false, validationError: '', selectedReview: {}, name: '', description: '', loader: false, deleteReviewPopup: false, addTestimonialPopup: false, profileImage: '', profilePreview: '', address: '', headLine: '',
            review: '',
            rating: 0,
            userData: {},
            toggleLoader: false
        })
    }

    toggleFilterStatus = () => { this.setState({ FilterStatus: !this.state.FilterStatus }) }

    handleFilter = (filterBy) => {
        this.setState({ filterBy }, () => this.getRatings())
    }

    viewReviewPopup = (selectedReview) => {
        this.setState({ viewDetailsPopup: true, selectedReview })
    }

    submitEditReview = (e) => {
        e.preventDefault()
        let { rating, headLine, review, tempReviewId } = this.state

        let params = {
            reviewId: tempReviewId,
            rating, headLine, review
        }

        if (this.isValid()) {
            this.setState({ loader: true })
            editReviewsByAdmin(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose()
                        this.getRatings()
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    handleImageChange = (e) => {
        let { name, value } = e.target
        let file = e.target.files[0];
        let previewFieldName = name + 'Preview'
        let fieldName = name
        let reader = new FileReader();
        reader.onloadend = () => { this.setState({ [previewFieldName]: reader.result }) }
        reader.readAsDataURL(file)
        this.setState({ [previewFieldName]: file })
        this.setState({ [fieldName]: e.target.files[0] });
    }

    handleDeleteReviewPopup = (review) => { this.setState({ deleteReviewPopup: true, tempReviewId: review._id }) }

    submitDeleteReview = (e) => {
        e.preventDefault()
        let { tempReviewId } = this.state
        let params = { reviewId: tempReviewId }

        deleteReview(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.handleClose()
                    this.getRatings()
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }



    isValid = () => {
        let { rating, headLine, review } = this.state

        if (!rating && rating != 0) {
            this.setState({ validationError: 'Rating cannot be empty!' })
            return false
        } else if (!headLine) {
            this.setState({ validationError: 'HeadLine cannot be empty!' })
            return false
        } else if (!review) {
            this.setState({ validationError: 'Review cannot be empty!' })
            return false
        } else {
            return true
        }
    }

    parseProductType = (productType) => {
        let ProductType = PlanFeatureComponents.find(option => option.value === productType)
        return ProductType ? ProductType['label'] : "-"
    }

    handleRatingStatus = (status, details) => {
        let params = {
            reviewId: details._id,
            isApprove: status ? 1 : 0
        }

        this.setState({ toggleLoader: true })
        approveReviewByAdmin(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.getRatings()
                    this.handleClose()
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.handleClose()
                    toast.error(response.error.errorMessage)
                }
            })
    }

    changeRating = (stars) => {
        this.setState({ rating: stars })
    }


    render() {
        let {
            loader, reviewsList, search, pageNo, count, totalReviews, editReviewPopup, validationError, viewDetailsPopup, selectedReview, name, deleteReviewPopup, headLine, review, rating, userData,toggleLoader
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/guaranteedPlanFeatures">Guaranteed Plan Features</Link></li>
                            <li className="breadcrumb-item">Reviews</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="cm_page_heading">User's Reviews</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name......."
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-striped text-center mb-5">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" >User's Name </th>
                                        <th scope="col" className="filter_text"> Rating </th>
                                        <th scope="col" className="filter_text"> Review</th>
                                        <th scope="col" className="filter_text"> Product Type</th>
                                        <th scope="col" className="filter_text"> Status</th>
                                        <th scope="col" className="filter_text">Action &nbsp; </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        reviewsList.map((details, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">
                                                        {details.userData ? (details.userData.firstName + " " + details.userData.lastName) : '-'}</span>
                                                    </td>

                                                    <td>
                                                        <StarRatings
                                                            rating={details.rating}
                                                            starDimension="20px"
                                                            starSpacing="8px"
                                                            starRatedColor='rgba(255,108,11,1)'
                                                        />
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.review}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {this.parseProductType(details.productType)}</span>
                                                    </td>

                                                    <td className="cm_quition_list">
                                                        <Switch checked={details.isApprove} onChange={(e) => this.handleRatingStatus(e, details)} loading={toggleLoader} />
                                                    </td>

                                                    <td className="cm_no_wrap">
                                                        <Button className='m-1' variant="outline-dark" onClick={() => this.viewReviewPopup(details)}> <i className="fa fa-eye" /> </Button>
                                                        <Button className='m-1' variant="outline-info" onClick={() => this.handleEditReviewPopup(details)}> <i className="fa fa-pencil" /> </Button>
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleDeleteReviewPopup(details)}> <i className="fa fa-trash" /> </Button>
                                                    </td>
                                                    <td className="cm_no_wrap">{dateFormat(details.created, "d mmmm yyyy")}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalReviews}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                            {!reviewsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>

                    </div>
                </div>


                <Modal show={editReviewPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{name}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">User's Name:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <FormControl
                                            placeholder="name"
                                            value={userData ? userData.firstName + " " + userData.lastName : '-'}
                                            disabled />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Rating:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <StarRatings
                                            rating={rating}
                                            starDimension="20px"
                                            starSpacing="8px"
                                            starRatedColor='rgba(255,108,11,1)'
                                            changeRating={this.changeRating}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Review:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <FormControl
                                            placeholder="Review....."
                                            value={review}
                                            name='review' onChange={this.handleChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">HeadLine:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body> <FormControl
                                        as="textarea"
                                        placeholder="HeadLine....."
                                        value={headLine}
                                        name='headLine' onChange={this.handleChange} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                        <Button variant="success" onClick={this.submitEditReview}>Submit</Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={viewDetailsPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedReview.userData ? selectedReview.userData.firstName + " " + selectedReview.userData.lastName : "-"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Rating:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <StarRatings
                                            rating={selectedReview.rating}
                                            starDimension="20px"
                                            starSpacing="8px"
                                            starRatedColor='rgba(255,108,11,1)'
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Review:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>{selectedReview.review}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">Product Type:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>{this.parseProductType(selectedReview.productType)}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">HeadLine</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>{selectedReview.headLine}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={deleteReviewPopup} onHide={this.handleClose} centered size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure, you want to delete this review?</h6> </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="success" size='sm' onClick={this.handleClose}>Cancel</Button>
                        <Button variant="danger" size='sm' onClick={this.submitDeleteReview}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </HOC>


        )
    }
}
