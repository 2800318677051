import * as SFS2X from "sfs2x-api";
import  setting from "../settings";
export default class sfxmanager {
    constructor() {
        this.smartfoxObj = new SFS2X.SmartFox();

    }
    // Connecting with sfs

    sfsConnect() {

        return new Promise((resolve, reject) => {
               const onConnection = (evtParams) => {
                  
                if (evtParams.success) 
                    resolve("Connected to SmartFoxServer 2X!");
                 else 
                    reject("Connection failed. Is the server running at all?");


                
            }

            const onDisconnection = (evtParams) => {
                  
              //console.log("Disconnected From SFS")


                
            }



            // let configDat=new SFS2X.c
           
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.CONNECTION, onConnection, this);
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.CONNECTION_LOST, onDisconnection, this);


            setTimeout(()=>{
                reject("Not able to conenct with the server ");

               },10000)

           // console.log(setting.sfsserver.host)
            this.smartfoxObj.connect(setting.sfsserver.host, setting.sfsserver.port,setting.sfsserver.ssl);
            // this.smartfoxObj.connect("13.234.99.84",8080);
            // this.smartfoxObj.connect("127.0.0.1",8080);


        })

    }

    sfsDisConnect() {
      
        this.smartfoxObj.removeEventListener(SFS2X.SFSEvent.CONNECTION)
        this.smartfoxObj.removeEventListener(SFS2X.SFSEvent.CONNECTION_LOST)
        this.smartfoxObj.removeEventListener(SFS2X.SFSEvent.LOGIN)

        this.smartfoxObj.removeEventListener(SFS2X.SFSEvent.LOGIN_ERROR)
        this.smartfoxObj.disconnect();


 }
 sfsLogout()
 {
   //  this.smartfoxObj.
 }



    loginToZone(username, password, params, zoneName) {


        return new Promise((resolve, reject) => {


            const onLogin = (evtParams) => {
                resolve("Login SuccessFull")

            }

            const onLoginError = (evtParams) => {
                reject("Login failure:" + evtParams.errorMessage);

            }
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.LOGIN, onLogin, this);
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.LOGIN_ERROR, onLoginError, this);
            this.smartfoxObj.send(new SFS2X.LoginRequest(username, password, params, zoneName))

        })


    }

    loginWithAccessToken(token) {
        return new Promise((resolve, reject) => {


            const onLogin = (evtParams) => {
                resolve("Login SuccessFull")

            }

            const onLoginError = (evtParams) => {
                reject("Login failure:" + evtParams.errorMessage);

            }
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.LOGIN, onLogin, this);
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.LOGIN_ERROR, onLoginError, this);
            // platform devicetype
            // this.smartfoxObj.send(new SFS2X.LoginRequest(token))
            // this.smartfoxObj.send(new SFS2X.LoginRequest(token))
            var obj = new SFS2X.SFSObject();
            obj.putText("platform", "1");
            obj.putText("deviceType", "threadmill");
            obj.putText("appVersion", "1.2.3");
            this.smartfoxObj.send(new SFS2X.LoginRequest(token, "", obj, "OFPLive"));
            // this.smartfoxObj.send(new SFS2X.LoginRequest("FozzieTheBear", "", null, "BasicExamples"));


        })

    }


    getRoomlist() {
        return new Promise((resolve, _) => {
            resolve(this.smartfoxObj.getRoomList())
        })

    }

    joinRoom(room) {
        return new Promise((resolve, reject) => {

            let onRoomJoined = (evtParams) => {
                resolve("Room joined successfully: " + evtParams.room.getUserList());
                this.sendPaginationRequest(0,50);
                this.sendSortRequest("rank",-1);
                this. removeAllFilters();

            }

            let onUserEnterRoom = (evtParams) => {
                var room = evtParams.room;
                var user = evtParams.user;

//console.log("User " + user.name + " just joined Room " + room.name);
            }

            let onRoomJoinError = (evtParams) => {
                reject("Room joining failed: " + evtParams.errorMessage);
            }
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.ROOM_JOIN, onRoomJoined, this);
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.ROOM_JOIN_ERROR, onRoomJoinError, this);
            this.smartfoxObj.addEventListener(SFS2X.SFSEvent.USER_ENTER_ROOM, onUserEnterRoom, this);

            // Join a Room called "Lobby"
            this.smartfoxObj.send(new SFS2X.JoinRoomRequest(room));
            
        })
    }


    // /

    addExtension(onExtensionResponse) {
        this.smartfoxObj.addEventListener(SFS2X.SFSEvent.EXTENSION_RESPONSE, onExtensionResponse, this);
       // console.log("sent");
        
    }

    sendPaginationRequest(startIndex,limit) {

        const params = new SFS2X.SFSObject();
         params.putLong("startIndex", startIndex);
        params.putLong("limit",limit);
        params.putBool("isUp", false);
        params.putBool("myRank", false);
       // console.log("Indexx",startIndex);

     //  console.log("params",JSON.stringify(params));
     

       this.smartfoxObj.send(new SFS2X.ExtensionRequest("CustomLeaderboardKey", params,this.smartfoxObj.lastJoinedRoom));

    }

    sendIndividualChat(emojiId,toId,toName,fromId,fromName)
    {
        
       const params = new SFS2X.SFSObject();
       params.putText("fromId",fromId);
       params.putText("fromName",fromName);
       params.putText("emojiId",emojiId);
       if(toId && toName)
       {
    
        params.putText("toId",toId);
        params.putText("toName",toName);
    this.smartfoxObj.send(new SFS2X.ExtensionRequest("IndividualChatPostKey", params,this.smartfoxObj.lastJoinedRoom));
       }
       else{
        this.smartfoxObj.send(new SFS2X.ExtensionRequest("GroupChatPostKey", params,this.smartfoxObj.lastJoinedRoom));

       }

      
      
    }

    sendSortRequest(sortParam,sortDirection)
    {
        let sfxParams={
            distanceCovered: 'DISTANCE',
            calories: 'CALORIES',
            timeElapsed: 'TIME_ELAPSED',
            loginTime: 'LOGIN_TIME',
            speed: 'SPEED',
            rank: 'RANK',
            signUpTime: 'SIGNUP_TIME',
            gender: 'GENDER',
            firstName: 'FIRST_NAME',
            lastName: 'LAST_NAME',
            rideCount: 'RIDE_COUNT',
            location: 'LOCATION',
            ageRange:'AGR_RANGE',
            birthday:'BIRTHDAY',
            height:'HEIGHT',
            weight:'WEIGHT',
            rank:'RANK'


          }
      sortDirection = sortDirection == 1 ? "DESC" : "ASC"; 
      if(sfxParams[sortParam])  
      {
        const params = new SFS2X.SFSObject();
     
        params.putText("sortParam",sfxParams[sortParam]);
        params.putText("sortDirection",sortDirection);
        this.smartfoxObj.send(new SFS2X.ExtensionRequest("SortedLeaderboardKey", params,this.smartfoxObj.lastJoinedRoom));
      } 
       
     
  
 }
 /**
  * 
  *   let _params = {
    count: 80,
    sortType: -1,
    sortField: "rank",
    sortSfsParentField: null,
    pageNo: 0,
    search: "",
    birthdayToday: false,
    gender: "",
    ageRange: "",
    streamingId: "",
    expertId: "",
    showInactiveUsers:true
  };
  */

 sendShowInactiveUsersRequest(checks)
 {
    const params = new SFS2X.SFSObject();

     if(checks.gender.length>0)
     {
        params.putText("filterType","GENDER");
       params.putText("filterValue",checks.gender);
       params.putText("filterOperation","ADD_FILTER");
       
     }

     if(typeof checks.ageRange ==="object")
     {
        params.putText("filterType","AGE_RANGE");
       params.putText("filterValue",checks.ageRange.min.toString());
       params.putText("filterOperation","ADD_FILTER");

     }

     if(checks.birthdayToday)
     {
        params.putText("filterType","BIRTHDAY");
       params.putText("filterValue","filter_added");
       params.putText("filterOperation","ADD_FILTER");

     }
     if(!checks.birthdayToday)
     {
        params.putText("filterType","BIRTHDAY");
        params.putText("filterOperation","REMOVE_FILTER");   
     }
     if(checks.showInactiveUsers)
     {
        params.putText("filterType","IN_ACTIVE");
        params.putText("filterOperation","REMOVE_FILTER"); 

     }
     if(!checks.showInactiveUsers)
     {
        params.putText("filterType","IN_ACTIVE");
        params.putText("filterValue","filter_added");
        params.putText("filterOperation","ADD_FILTER");
        
     }

     this.smartfoxObj.send(new SFS2X.ExtensionRequest("LeaderboardFilterKey", params,this.smartfoxObj.lastJoinedRoom));

    }


 removeAllFilters()
 {
    const params = new SFS2X.SFSObject();
    params.putText("filterType","ALL");
    params.putText("filterOperation","REMOVE_FILTER");
    this.smartfoxObj.send(new SFS2X.ExtensionRequest("LeaderboardFilterKey", params,this.smartfoxObj.lastJoinedRoom));

 }


 
}
