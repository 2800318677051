import axios from 'axios';
import API from '../Api';

export function getOrderHistory(orderId) {

    return axios.get(`${API.GET_ORDER_HISTORY}/${orderId}`);
}

export function postOrderHistory(parmas) {
    return axios.post(`${API.POST_ORDER_HISTORY}/${parmas.orderId}`,parmas);
}

export function updateOrderHistory(parmas) {
    return axios.put(`${API.UPDATE_ORDER_HISTORY}/${parmas.orderHistoryId}`,parmas);
}





