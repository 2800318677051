import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { addEquipment, getProductSpareParts, editEquipment, getAllVerticals } from '../../config/services/commercialService';
import { Button, Modal, FormControl, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import Select from 'react-select';

const AddEditModal = (props) => {
    const { isModalToEdit, showModal, hideModal, fetchEquipmentsList, eqpId, pageNo } = props;
    const [model, setModel] = useState(props.model || "");
    const [skuCode, setSkuCode] = useState(props.skuCode || "");
    const [brand, setBrand] = useState(props.brand || "");
    const [vertical, setVertical] = useState(props.vertical || "");
    const [verticalsList, setVerticalsList] = useState([]);

    useEffect(() => {
        fetchVerticalsList();
    }, []);

    const fetchVerticalsList = () => {
        getAllVerticals()
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    const list = data.result ? data.result.map((vertical) => ({label: vertical.verticalName, value: vertical._id})) : [];
                    setVerticalsList(list);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handleValidation = () => {
        const emptyField = vertical ? "" : "Vertical";

        if(!emptyField) return true;
        toast.error(`Please select ${emptyField}`);
        return false;
    };

    const formSubmission = (event) => {
        event.preventDefault();

        const validationFlag = handleValidation();
        if(validationFlag) {
            const params = {
                skuCode: skuCode,
                model: model,
                brand: brand,
                verticalId: vertical.value
            };

            const commonFn = () => {
                fetchEquipmentsList(pageNo);
                hideModal();
                setModel("");
                setSkuCode("");
                setBrand("");
                setVertical("");
            };

            if(isModalToEdit) {
                editEquipment({...params, equipmentId: eqpId})
                    .then(res => {
                        const response = res.data;
        
                        if (response.statusCode == 1) {
                            toast.success(response.responseData.message);
                            commonFn();
                        } else if (response.statusCode == 0) {
                            toast.error(response.error.errorMessage);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
            } else {
                addEquipment(params)
                    .then(res => {
                        const response = res.data;
        
                        if (response.statusCode == 1) {
                            toast.success(response.responseData.message);
                            commonFn();
                        } else if (response.statusCode == 0) {
                            toast.error(response.error.errorMessage);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
            }
        }
    };

    return (
        <div className="main-wrapper">
            <Modal show={showModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{isModalToEdit ? "Edit" : "Add"} Equipment</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <Form onSubmit={(e) => formSubmission(e)}>
                        <div className='row'>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Model</FormLabel>
                                <FormControl
                                    value={model}
                                    name="model"
                                    onChange={(e) => { setModel(e.target.value) }}
                                    required
                                    type="text"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>SKU Code</FormLabel>
                                <FormControl
                                    value={skuCode}
                                    name="skuCode"
                                    onChange={(e) => { setSkuCode(e.target.value) }}
                                    type="text"
                                />
                            </FormGroup>
                        </div>

                        <div className='row'>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Brand</FormLabel>
                                <FormControl
                                    value={brand}
                                    name="brand"
                                    onChange={(e) => { setBrand(e.target.value) }}
                                    required
                                    type="text"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Product type</FormLabel>
                                <Select
                                    onChange={(e) => setVertical(e)}
                                    options={verticalsList}
                                    value={vertical ? vertical : ""}
                                />
                            </FormGroup>
                        </div>

                        <Row className="justify-content-center">
                            <Button variant="danger" onClick={hideModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit">Submit</Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddEditModal;