import axios from 'axios';
import API from '../Api';

export function getProductsList(params) {
    return axios({
        method: 'get',
        url: API.GET_PRODUCTS + params,
    })
}

// export function addProduct(params) {
//     return axios({
//         method: 'post',
//         url: API.ADD_PRODUCT,
//         data: params,
//     })
// }

export function addProduct(params) {
    return axios.post(API.ADD_PRODUCT, params)
}

export function deleteProduct(params) {
    return axios({
        method: 'post',
        url: API.DELETE_PRODUCT,
        data: params,
    })
}


export function getProduct(params) {
    return axios({
        method: 'get',
        url: API.GET_PRODUCT+ params,
    })
}


export function editProduct(params) {
    return axios.post(API.EDIT_PRODUCT, params)
}


export function addKeyFeature(params) {
    return axios.post(API.ADD_KEY_FEATURE, params)
}


// export function editProduct(params) {
//     return axios({
//         method: 'post',
//         url: API.EDIT_PRODUCT,
//         data: params,
//     })
// }

export function getTotalProductsCount(params){
    return axios({
        method: 'get',
        url: API.GET_PRODUCTS_COUNT,
    })
}

export function removeKeyFeatures(params) {
    return axios.post(API.REMOVE_KEY_FEATURE, params)
}


export const ProductsService = {
    getProductsList,
    addProduct,
    deleteProduct,
    getProduct,
    editProduct,
    getTotalProductsCount,
    removeKeyFeatures
};