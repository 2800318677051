import axios from 'axios';
import API from '../Api';

export function addBlog(params) {
    return axios.post(API.ADD_BLOG, params)
}
export function editBlog(params) {
    return axios.post(API.EDIT_BLOG, params)
}
export function getBlogListByAdmin(params) {
    return axios.get(API.GET_BLOGS_LIST, params)
}
export function deleteBlog(params) {
    return axios.post(API.DELETE_BLOG, params)
}
export function getParticularBlog(params) {
    return axios.post(API.GET_PARTICULAR_BLOG, params)
}