import axios from 'axios';
import API from '../Api';



export function addCustomerType(params) {
    return axios.post(API.ADD_CUSTOMER_TYPE, params)
}

export function updateCustomerType(params) {
    return axios.post(API.UPDATE_CUSTOMER_TYPE, params)
}

export function deleteCustomerType(params) {
    return axios.post(API.DELETE_CUSTOMER_TYPE, params)
}

export function getCustomerTypesList(params) {
    return axios.get(API.GET_CUSTOMER_TYPES, params)
}