import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { addBlog, editBlog, deleteBlog, getBlogListByAdmin } from '../../config/services/BlogService';
import { toast } from 'react-toastify';
import { Button, Modal, FormControl, Alert, Accordion, Card } from 'react-bootstrap';
import { BlogTypes } from './BlogTypes'
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


class AddBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            name: '',
            profileImage: '',
            profileImagePreview: '',
            coverImage: '',
            coverImagePreview: '',
            blogType: null,
            description: '',
            shortDescription: '',
            validationError: '',
            metaTitle: '',
            metaDescription:''
        }
    }

    componentDidMount() { }

    // parseBlogType = (blogType) => {
    //     let blogTypeLabel = BlogTypes.find(res => res.value == blogType)
    //     return blogTypeLabel ? blogTypeLabel.label : '-'
    // }


    handleClose = () => {
        this.setState({ viewBlogPopup: false, selectedBlog: {}, validationError: '', name: '', profileImage: '', profileImagePreview: '', coverImage: '', coverImagePreview: '', blogType: 0, description: '', shortDescription: '' })
    }


    handleImageChange = (e) => {
        let { name, value } = e.target
        let file = e.target.files[0];
        let previewFieldName = name + 'Preview'
        let fieldName = name
        let reader = new FileReader();
        reader.onloadend = () => { this.setState({ [previewFieldName]: reader.result }) }
        reader.readAsDataURL(file)
        this.setState({ [previewFieldName]: file })
        this.setState({ [fieldName]: e.target.files[0] });
    }


    handleChange = e => {
        let { name, value } = e.target
        console.log(name, value , "dsaaaaaaaaaa");
        this.setState({ [name]: value, validationError: '' })
    }

    submitAddBlog = (e) => {
        e.preventDefault()
        let { name, coverImage, profileImage, description, shortDescription, blogType , metaTitle , metaDescription } = this.state

        let params = new FormData()

        params.append('name', name)
        params.append('coverImage', coverImage)
        params.append('profileImage', profileImage)
        params.append('description', description)
        params.append('shortDescription', shortDescription)
        params.append('blogType', blogType)
        params.append('metaTitle', metaTitle)
        params.append('metaDescription', metaDescription)
        


        if (this.isValid()) {
            this.setState({ loader: true })
            addBlog(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose()
                        this.props.history.push('/blogs')
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }



    isValid = () => {
        let { name, coverImagePreview, profileImagePreview, description, shortDescription, blogType , metaTitle , metaDescription } = this.state

        if (!name) {
            this.setState({ validationError: 'Please Enter Name!' })
            return false
        }

        else if(!metaTitle){
            this.setState({ validationError: 'Please Enter Meta Title!!' })
            return false
        }
        else if(!metaDescription){
            this.setState({ validationError: 'Please Enter Meta Description!!' })
            return false
        }
        
        else
            if (!coverImagePreview) {
                this.setState({ validationError: 'Please Upload Cover Image!' })
                return false
            }
            else
                if (!profileImagePreview) {
                    this.setState({ validationError: 'Please Upload Profile Image!' })
                    return false
                } else
                    if (!description) {
                        this.setState({ validationError: 'Please Enter Description' })
                        return false
                    } else
                        if (!shortDescription) {
                            this.setState({ validationError: 'Please Enter Short Description!' })
                            return false
                        } else
                            if (!blogType && blogType != 0) {
                                this.setState({ validationError: 'Please Select Blog Type' })
                                return false
                            } else {
                                return true
                            }
    }


    handleBlogType = (blogType) => {
        this.setState({ blogType: blogType.value })
    }

    handleDescription = (data) => {
        this.setState({ description: data })
    }


    render() {
        let { loader, name, profileImagePreview, coverImagePreview, blogType, description, shortDescription, validationError , metaTitle , metaDescription } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/blogs">Blogs</Link></li>
                            <li className="breadcrumb-item">Add Blog</li>
                        </ol>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <form onSubmit={this.handleSubmit} className="edit_device_form mb-5">
                            <h3 className="text-center mb-4">Add Blog</h3>

                            <div className="card card_content ">
                                {profileImagePreview ? <img className="card-img-top object-contain" src={profileImagePreview} alt="Profile Image" /> : <div className="card-img-top">No Image</div>}
                                <span className="card_style_cat">Profile Image*</span>
                                <div className="radio_wrapper text-center">
                                    <input type="file" onChange={this.handleImageChange} id="profileImage" name="profileImage" />
                                </div>
                            </div>


                            <div className="card card_content">
                                {coverImagePreview ? <img className="card-img-top object-contain" src={coverImagePreview} alt="Cover Image" /> : <div className="card-img-top">No Image</div>}
                                <span className="card_style_cat">Cover Image*</span>
                                <div className="radio_wrapper text-center">
                                    <input type="file" onChange={this.handleImageChange} id="coverImage" name="coverImage" />
                                </div>
                            </div>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">Name*:</Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="0">
                                        <Card.Body>
                                            <FormControl
                                                placeholder="name"
                                                value={name}
                                                name='name' onChange={this.handleChange} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                             
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">Meta Title*</Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <FormControl
                                                placeholder="meta title"
                                                value={metaTitle}
                                                name='metaTitle' onChange={this.handleChange} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">Meta Description*:</Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <FormControl
                                                placeholder="meta description"
                                                value={metaDescription}
                                                name='metaDescription' onChange={this.handleChange} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">Short Description*</Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            <FormControl
                                                placeholder="Short Description"
                                                value={shortDescription}
                                                name='shortDescription' onChange={this.handleChange} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="4">Description*</Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            <ReactQuill theme="snow" value={description} onChange={this.handleDescription}
                                            height={500}
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                        ['link', 'image', 'video'],
                                                        ['code-block'],
                                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                        ['blockquote', 'code-block'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                                                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                                                        [{ 'direction': 'rtl' }],                         // text direction
                                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                        [{ 'font': [] }],
                                                        [{ 'align': [] }],
                                                        ['clean']
                                                    ]
                                                }}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            <FormControl
                                placeholder="blogType"
                                value={blogType}
                                name="blogType"
                                 onChange={this.handleChange} 
                            />

                            {/* <Select
                                isSearchable
                                options={BlogTypes}
                                placeholder="Select a blog type"
                                onChange={(val) => this.handleBlogType(val)}
                                className="mt-1 mb-1"
                            /> */}

                            {validationError && <Alert variant="danger"> {validationError} </Alert>}
                            <Button className="btn btn-primary btn-block mt-4" variant="success" onClick={this.submitAddBlog}>Add Blog</Button>
                        </form>
                    </div>
                </div>
            </HOC>


        )
    }
}




export default AddBlog;