var SearchTypes = [
    // { value: '', label: "All", },
    { value: 'customerData.firstName', label: "Customer First Name", },
    { value: 'customerData.lastName', label: "Customer Last Name", },
    { value: 'customerData.email', label: "Customer Email", },
    { value: 'customerData.fullMobileNo', label: "Customer Contact No", },
    { value: 'requestServiceData.orderId', label: "Order Id", },
    { value: 'requestId', label: "Request Id", },
    { value: '_id', label: "Job Id", }
];

const  CallStatuses = [
    { value: "", label: 'ALL' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Close' },
    { value: 3, label: 'Pending with spare' },
    // { value: 4, label: 'Urgent' },
    { value: 5, label: 'Non Contactable' },
    // { value: 6, label: 'Self_installed' },
    { value: 7, label: 'Cancelled' },
    // { value: 8, label: 'Returned' },
    // { value: 9, label: 'Not delivered ' },
    // { value: 10, label: 'Un-assigned' },
    { value: 11, label: 'Done' },
    { value: 12, label: 'Completed' },
    // { value: 13, label: 'Rescheduled' },
    { value: 14, label: "Pending with reason" }
];

const noteStateTypeOptions = [
    { value: "", label: 'Select Option' },
    { value: "Open", label: 'Open' },
    { value: "Close", label: 'Close' },
    { value: "Done", label: 'Done' },
    { value: "Self Installed", label: 'Self Installed' },
    { value: "Cancelled", label: 'Cancelled' },
    { value: "Returned", label: 'Returned' },
    { value: "Not Delivered", label: 'Not Delivered' },
    { value: "Un-assigned", label: 'Un-assigned' },
    { value: "Urgent", label: 'Urgent' },
    { value: "Pending with spare", label: 'Pending with spare' },
];

const TatOption = [
    {value: 1, label: 'In Tat'},
    {value: 2, label: 'Out Tat'}
];

const PaymentTypeOptionFilter = [
    {value: 1, label: 'Free'},
    {value: 2, label: 'Prepaid'},
    {value: 3, label: 'COD'}
];

const JOB_ADDI_CHARGES_PAYMENT_STATUS = {
    PENDING: 0,
    AUTO_ACCEPTED: 1,
    ACCEPTED: 2,
    REJECTED: 3,
};

const JOB_STATUS_FOR_AUTO_ACCEPT = [2,16,22,15,24,11,12]

module.exports = {
    SearchTypes, CallStatuses, noteStateTypeOptions, TatOption,PaymentTypeOptionFilter,JOB_ADDI_CHARGES_PAYMENT_STATUS,JOB_STATUS_FOR_AUTO_ACCEPT
}
