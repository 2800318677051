import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { TreadmillSvg, CouponsSvg, LoaderSvg } from '../Reusable';
import Popup from "reactjs-popup"
import HOC from '../../HOC';
import { map } from 'lodash';
import { getAllDataCount } from '../../config/services/usersService';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { getAllPincodes } from '../../config/services/servicePincodes';
import { Button } from 'react-bootstrap';
import { getAllVendors } from '../../config/services/vendorService';
const { serviceTypeOptions, statusFilterOptions, sourceFilterOptions, removeDuplicates } = require("../utils/Util.js");

const dashboardItemsDetails = [{ label: "Total Open Call", icon: <i className="fa fa-bars" aria-hidden="true" />, count: 112 },
{ label: "Unassigned", icon: <i className="fa fa-spinner" aria-hidden="true" />, count: 20 },
{ label: "Pending", icon: <i className="fa fa-spinner" aria-hidden="true" />, count: 89, description: "(Not Contactable, Not delivered)" },
{ label: "Completed", icon: <i className="fa fa-check" aria-hidden="true" />, count: 568 },
{ label: "Cancelled", icon: <i className="fa fa-times" aria-hidden="true" />, count: 874 },
{ label: "Self Installed", icon: "", count: 369, icon: <i className="fa fa-user" aria-hidden="true"></i> },
{ label: "Done", icon: <i className="fa fa-check" aria-hidden="true" />, count: 952, description: "(From Vendor Side)" },
{ label: "Reopen", icon: <i className="fa fa-check" aria-hidden="true" />, count: 456 },
{ label: "In TAT", icon: <i className="fa fa-check" aria-hidden="true" />, count: 789 },
{ label: "Out TAT", icon: <i className="fa fa-check" aria-hidden="true" />, count: 2 },
{ label: "Nearing TAT", icon: <i className="fa fa-check" aria-hidden="true" />, count: 50 }

]


class AdminDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Loader: true,
            AllCountData: {},

        }
    }
    componentDidMount() {
        let token = localStorage.getItem('accessToken');
        if (token) {
            getAllDataCount()
                .then(res => {
                    if (res.data.statusCode == 1) {
                        this.setState({ AllCountData: res.data.responseData.totalCounts, Loader: false, })
                    } else {
                        this.setState({ Loader: false, })
                    }
                })
        }
    }
    handleFilterChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, apiCalled: true });

    }
    loadOptionByval = (type, value) => {
        if (type === "pincode") {
            return this.getDropDownData(value, "pincodeSearch", "pincodeData");
        }
        else if (type === "city") {
            return this.getDropDownData(value, "citySearch", "cityData");
        }
        else {
            return null;
        }

    }
    loadVendors = (val) => {
        return this.getVendors(val)
    }

    onHandleSelect = (data, fieldName) => {
        let fieldNameData = `${fieldName}Data`
        this.setState({ [fieldName]: data.label, vendorFilter: data._id, [fieldNameData]: data, apiCalled: true })
    }
    getVendors = (val) => {
        let paramsobj = { params: { count: 0, pageNo: 0, search: val } }
        return getAllVendors(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let result = res.data.responseData.vendors;
                    let vendors = []
                    result && result.map(vendor => {
                        vendors.push({ label: `${vendor.firstName} ${vendor.lastName}`, value: vendor._id })
                    })
                    this.setState({ vendors })
                    return vendors
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    getDropDownData = (val, searchField, stateField) => {
        let paramsobj = { params: { count: 100, pageNo: 0, [searchField]: val } }
        return getAllPincodes(paramsobj).then(res => {
            if (res && res.data.statusCode === 1) {
                let data = res.data.responseData
                let result = data[0].result
                let option = []
                let removeDublicatess = removeDuplicates(result, searchField.replace("Search", ""));

                if (removeDublicatess && removeDublicatess.length > 0) {
                    removeDublicatess.forEach(item => {
                        option.push({
                            label: item[stateField.replace("Data", "")],
                            value: item._id,
                        })
                    })
                }
                this.setState({ [stateField]: option })
                return option
            } else if (res.data.statusCode == 0) {
                console.log(res.data.error.errorMessage)
            }
        })
    }

    render() {
        let { vendors } = this.state;
        return (
            <HOC>

                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Service Dashboard</li>
                        </ol>
                        <div>
                            <div className="col-md-12 align-items-baseline d-inline-flex mb-4">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mt-2 ">
                                        <Select
                                            className='cm_min_width'
                                            onChange={(e) => this.handleFilterChange(e.value, "sourceFilter")}
                                            placeholder="Source"
                                            options={sourceFilterOptions}
                                            value={sourceFilterOptions.find(element => element.value === this.state.sourceFilter)}

                                        />
                                    </li>
                                    <li className="list-inline-item mt-2">
                                        <Select
                                            className='cm_min_width'
                                            onChange={(e) => this.handleFilterChange(e.value, "serviceTypeFilter")}
                                            placeholder="Service Type"
                                            options={serviceTypeOptions}
                                            value={serviceTypeOptions.find(element => element.value === this.state.serviceTypeFilter)}

                                        />
                                    </li>
                                    <li className="list-inline-item mt-2">
                                        <AsyncSelect
                                            className='cm_min_width'
                                            isDisabled={false}
                                            cacheOptions
                                            value={this.state["cityFilterData"]}
                                            loadOptions={(val) => this.loadOptionByval("city", val)}
                                            defaultOptions={this.state.cityData}
                                            onChange={(val) => this.onHandleSelect(val, "cityFilter")}
                                            placeholder='Filter By City'
                                        />
                                    </li>
                                    <li className="list-inline-item mt-2">
                                        <AsyncSelect
                                            className='cm_min_width'
                                            isDisabled={false}
                                            cacheOptions
                                            value={this.state["vendorFilterData"]}
                                            loadOptions={this.loadVendors}
                                            defaultOptions={vendors}
                                            onChange={(val) => this.onHandleSelect(val, "vendorFilter")}
                                            placeholder="Filter By Vendor"
                                        />
                                    </li>
                                    <li className="list-inline-item mt-2">
                                        <AsyncSelect
                                            className='cm_min_width'
                                            isDisabled={false}
                                            cacheOptions
                                            value={this.state["vendorFilterData"]}
                                            loadOptions={this.loadVendors}
                                            defaultOptions={vendors}
                                            onChange={(val) => this.onHandleSelect(val, "vendorFilter")}
                                            placeholder="Filter By Model"
                                        />
                                    </li>
                                    <li className="list-inline-item">
                                        <Button variant="outline-danger" className="mr-2" size='sm' onClick={this.resetFilter}>Clear </Button>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="chart_wrapper">
                            <div className="row mb-3">
                                {dashboardItemsDetails.map((item) =>
                                    <div className="col-lg-3 col-md-6 mb-3">
                                        <Link to="/adminDashboard">
                                            <div className="cm_card">
                                                <span className="all_users">
                                                    {item.icon}
                                                </span>
                                                <h2>{item.count}</h2>
                                                <h3>{item.label}</h3>
                                                <h3>{item.description}</h3>
                                            </div>
                                        </Link>
                                    </div>


                                )
                                }
                            </div>

                            {this.state.Loader
                                ? <div className="loader_wrapper"><LoaderSvg /></div>
                                : null
                            }

                        </div>
                    </div>
                </div>
            </HOC>
        );
    }
}

export default AdminDashboard;
