import axios from 'axios';
import API from '../Api';

export function getOrderFeedbackList(params) {
    return axios.get(API.GET_ORDER_FEEDBACK, params)
}

export function updateOrderFeedback(params) {
    return axios.put(API.GET_ORDER_FEEDBACK, params)
}



