import axios from 'axios';
import API from '../Api';


export function getFitnessTemplatesByAadmin(params) {
    return axios.get(API.FITNESS_PLAN_TEMPLATE_BY_ADMIN,params)
}

export function editFitnessPlanTemplate(params) {
    return axios.post(API.EDIT_FITNESS_PLAN_TEMPLATE,params)
}

export function getFitnessPlanTemplate(params) {
    return axios.get(API.GET_FITNESS_PLAN_TEMPLATE,params)
}

export function deleteTemplateById(params) {
    return axios.post(API.DELETE_FITNESS_PLAN_TEMPLATE,params)
}

export const FitnessTemplateService = {
    getFitnessTemplatesByAadmin,
    getFitnessPlanTemplate,
    editFitnessPlanTemplate,
    deleteTemplateById
};
