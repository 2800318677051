import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import moment from 'moment';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { addTestimonial, editTestimonial, getTestimonialByAdmin, deleteTestimonial } from '../../config/services/TestimonialService';
import { subscriptionListByAdmin, editEndDate, cancelSubscription } from '../../config/services/OrderManagementService';
import { toast } from 'react-toastify';
import { Button, Modal, FormControl, Alert, Accordion, Card, InputGroup } from 'react-bootstrap';
import { DatePicker } from 'antd';


class OrderManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            ordersList: [],
            search: '',
            count: 10,
            totalOrders: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            validationError: '',
            editSubscriptionPopup: false,
            tempSubscriptionId: '',
            FilterStatus: false,
            filterBy: '',
            viewDetailsPopup: false,
            selectedSubscription: {},
            name: '',
            description: '',
            age: '',
            cancelSubscriptionPopup: false,
            addTestimonialPopup: false,

            profileImage: '',
            profilePreview: '',
            address: '',
            end_Date: '',
        }
    }

    componentDidMount() {
        this.getSubscriptionList();
    }

    getSubscriptionList = () => {
        let { search, count, pageNo, sortType, sortField, filterBy } = this.state;
        let paramsObj = { params: { search, limit: count, page: pageNo, sortType, sortField, filter: filterBy } }

        subscriptionListByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        ordersList: response.responseData.result.list,
                        totalOrders: response.responseData.result.totalCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }

    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getSubscriptionList());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getSubscriptionList());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getSubscriptionList() });


    handleEditSuscriptionPopup = (subscription) => {
        let { end_Date, _id, paymentId } = subscription
        this.setState({ tempSubscriptionId: paymentId, end_Date, editSubscriptionPopup: true })
    }

    handleClose = () => {
        this.setState({
            editSubscriptionPopup: false, tempSubscriptionId: '', viewDetailsPopup: false, validationError: '', selectedSubscription: {}, name: '', description: '', loader: false, cancelSubscriptionPopup: false, addTestimonialPopup: false, profileImage: '', profilePreview: '', address: '', end_Date: '',
        })
    }

    toggleFilterStatus = () => { this.setState({ FilterStatus: !this.state.FilterStatus }) }

    handleFilter = (filterBy) => {
        this.setState({ filterBy }, () => this.getSubscriptionList())
    }

    viewSubscriptionPopup = (subscription) => {
        this.setState({ viewDetailsPopup: true, selectedSubscription: subscription })
    }

    submitEditSubscription = (e) => {
        e.preventDefault()
        let { tempSubscriptionId, end_Date } = this.state
        this.setState({ loader: true })

        let params = {
            subscriptionId: tempSubscriptionId,
            endDate: end_Date
        }

        editEndDate(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.handleClose()
                    this.getSubscriptionList()
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }

    handleImageChange = (e) => {
        let { name, value } = e.target
        let file = e.target.files[0];
        let previewFieldName = name + 'Preview'
        let fieldName = name
        let reader = new FileReader();
        reader.onloadend = () => { this.setState({ [previewFieldName]: reader.result }) }
        reader.readAsDataURL(file)
        this.setState({ [previewFieldName]: file })
        this.setState({ [fieldName]: e.target.files[0] });
    }

    handleCancelSubscriptionPopup = (subsc) => { this.setState({ cancelSubscriptionPopup: true, tempSubscriptionId: subsc.paymentId }) }

    submitCancelSubscriptioin = (e) => {
        e.preventDefault()
        let { tempSubscriptionId } = this.state
        let params = { subscriptionId: tempSubscriptionId }

        cancelSubscription(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.handleClose()
                    this.getSubscriptionList()
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }


    handleAddTestimonialPopup = () => { this.setState({ addTestimonialPopup: true }) }

    submitAddTestimonial = (e) => {
        e.preventDefault()
        let { name, profileImage, description, address } = this.state

        let params = new FormData()
        params.append('name', name);
        params.append('profileImage', profileImage);
        params.append('description', description);
        params.append('address', address);


        if (this.isValid()) {
            this.setState({ loader: true })
            addTestimonial(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.handleClose()
                        this.getSubscriptionList()
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }



    isValid = () => {
        let { name, profileImagePreview, description, address } = this.state

        if (!name) {
            this.setState({ validationError: 'Name cannot be empty!' })
            return false
        } else if (!profileImagePreview) {
            this.setState({ validationError: 'Upload profile pic!!' })
            return false

        } else if (!description) {
            this.setState({ validationError: 'Description cannot be empty!' })
            return false
        } else if (!address) {
            this.setState({ validationError: 'Address cannot be empty!' })
            return false
        } else {
            return true
        }
    }


    handleDateChange = (date) => {
        let end_Date = moment(date).unix()
        this.setState({ end_Date })
    }


    parseBillingDate = (date) => {
        let startDate = moment.unix(parseInt(date))
        let currentDate = new Date().getTime()
        let paymentDate = moment(currentDate).add(1, 'M').set({ date: startDate.get('date') }).format('Do MMM YYYY');

        return paymentDate
    }


    render() {
        let {
            loader, ordersList, search, pageNo, count, totalOrders, editSubscriptionPopup, validationError, viewDetailsPopup, selectedSubscription, name, description, cancelSubscriptionPopup, addTestimonialPopup, profileImagePreview, address, end_Date
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Order Management</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Orders</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name......."
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-striped text-center mb-5">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" className="filter_text" >User's Name </th>
                                        <th scope="col" className="filter_text"> User's Email </th>
                                        <th scope="col" className="filter_text"> Refundable Deposit</th>
                                        <th scope="col" className="filter_text"> Price </th>
                                        <th scope="col" className="filter_text"> Status </th>
                                        <th scope="col" className="filter_text"> Start Date </th>
                                        <th scope="col" className="filter_text"> End Date </th>
                                        <th scope="col" className="filter_text"> Entity </th>
                                        <th scope="col" className="filter_text"> Plan Type </th>
                                        <th scope="col" className="filter_text"> Payment Type </th>
                                        <th scope="col" className="filter_text"> Action </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;<i className="fa fa-sort" />
                                        </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        ordersList.map((details, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_table_three_dots">{details.userData ? details.userData.firstName : '-'}</span></td>
                                                    <td><span className="cm_table_three_dots">{details.userData ? details.userData.email : '-'}</span></td>
                                                    <td><span className="cm_table_three_dots">{details.refundableDeposit}</span></td>
                                                    <td><span className="cm_table_three_dots">{details.price}</span></td>
                                                    <td><span className="cm_table_three_dots">{details.status}</span></td>
                                                    <td><span className="cm_table_three_dots">{details.start_Date ? moment.unix(parseInt(details.start_Date)).format('Do MMM YYYY') : '-'}</span></td>
                                                    <td><span className="cm_table_three_dots"> {details.end_Date ? moment.unix(parseInt(details.end_Date)).format('Do MMM YYYY') : '-'}</span></td>
                                                    <td><span className="cm_table_three_dots">{details.entity}</span></td>
                                                    <td><span className="cm_table_three_dots"> {details.planType == 0 ? 'Guaranteed Plan' : details.planType == 1 ? 'Direct Plan' : 'Not Provided'}</span></td>
                                                    <td><span className="cm_table_three_dots">{details.paymentType == 0 ? 'Subscription' : details.paymentType == 1 ? 'Order/Net Banking' : '-'}</span></td>

                                                    <td className="cm_no_wrap">
                                                        <Button className='m-1' variant="outline-dark" onClick={() => this.viewSubscriptionPopup(details)}> <i className="fa fa-eye" /> </Button>
                                                        <Button className='m-1' variant="outline-info" onClick={() => this.handleEditSuscriptionPopup(details)}> <i className="fa fa-pencil" /> </Button>
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleCancelSubscriptionPopup(details)}> <i className="fa fa-trash" /> </Button>
                                                    </td>
                                                    <td className="cm_no_wrap">{moment(details.created).format('Do MMM YYYY')}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalOrders}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!ordersList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>


                <Modal show={editSubscriptionPopup} onHide={this.handleClose} centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Update End Date</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='text-center' >
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>End Date</InputGroup.Text>
                            </InputGroup.Prepend>
                            <DatePicker onChange={this.handleDateChange}
                                value={end_Date ? moment.unix(parseInt(end_Date), 'Do MMM YYYY') : ''}
                            />
                        </InputGroup>
                    </Modal.Body>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" size="sm" onClick={this.handleClose}>Close</Button>
                        <Button variant="success" size="sm" onClick={this.submitEditSubscription}>Submit</Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={viewDetailsPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Products bought:</h4>


                        {selectedSubscription.productData && selectedSubscription.productData.length && selectedSubscription.productData.map((product, i) => (
                            <div className="card card_content" key={i}>
                                {product.productImage ? <img className="card-img-top object-contain" src={product.productImage} alt="Profile Image" /> : <div className="card-img-top">No Image</div>}
                                <span className="card_style_cat">{product.productName}</span>
                            </div>
                        ))}


                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Subscription Details:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <p>Start Date: {moment.unix(selectedSubscription.start_Date).format('Do MMM YYYY')}</p>
                                        <p>End Date:   {moment.unix(selectedSubscription.end_Date).format('Do MMM YYYY')}</p>
                                        <p>Next Billing Date:   {this.parseBillingDate(selectedSubscription.start_Date)}</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose} size="sm">Close</Button>
                    </Modal.Footer>
                </Modal>




                <Modal show={cancelSubscriptionPopup} onHide={this.handleClose} centered >
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure, you want to cancel this subscription?</h6> </Modal.Title>
                    </Modal.Header>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="success" size="sm" onClick={this.handleClose}>Close</Button>
                        <Button variant="danger" size="sm" onClick={this.submitCancelSubscriptioin}>Yes Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </HOC >


        )
    }
}




export default OrderManagement;