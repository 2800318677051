import axios from 'axios';
import API from '../Api';

export function addServiceBanner(params) {
    return axios.post(API.ADD_SERVICE_BANNER,params)
}

export function getServiceBanners(params) {
    return axios.get(API.GET_SERVICE_BANNERS,params)
}

export function updateServiceBanner(params) {
    return axios.post(API.UPDATE_SERVICE_BANNER,params)
}

export function deleteServiceBanner(params) {
    return axios.post(API.DELETE_SERVICE_BANNER,params)
}
