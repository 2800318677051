import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import { getAllHelpVideos, getHelpVideoCategoriesLabels, addHelpVideo, deleteHelpVideo } from '../../config/services/HelpVideoService';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup"
import { LoaderSvg, VegIcon } from '../Reusable'
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import AddVideo from './addVideo'
import { constant } from 'lodash';
import SearchSpinner from '../common/SearchSpinner';

class Foods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helpVideos: [],
            helpVideosCount: 0,
            foodCategories: [],
            foodImagePrev: "",
            foodImage: "",
            openAddHelpVideoPopup: false,
            foodCategoryPreview: '',
            Loader: true,
            deleteFoodPopup: false,
            openEditFoodPopup: false,
            foodEditLoader: false,
            count: 16,
            search: '',
            filter: '',
            pageNo: 1,
            categories: [],
            addVideoRequest: {
                videoUrl: '',
                videoTitle: '',
                category: '',
                subCategory: '',
            },
            searchLoader: false
        }
    }

    componentDidMount() {
        this.getAllCategories();
        this.getAllHelpVideos()
    }

    prepareCategories = (allCategories) => {
        const categories = allCategories.filter((c) => (!c.parent || c.parent === null))
        this.setState({ categories: categories })
    }

    prepareSubCategories = (parent) => {
        const { allCategories } = this.state
        console.log(allCategories)
        const subCategories = allCategories.filter((c) => (c.parent && c.parent === parent))
        console.log(subCategories)
        this.setState({ subCategories: subCategories })
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => this.getAllHelpVideos());

    getAllCategories = (params) => {
        this.setState({ loader: true })
        getHelpVideoCategoriesLabels(params)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({
                        allCategories: response.responseData.result
                    }, () => {
                        this.prepareCategories(response.responseData.result)
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    onSearch = (e) => {
        const { value } = e.target;
        this.setState({ search: value, searchLoader: true }, () => {
            this.getAllHelpVideos()
        })
    }

    filterSearchCategory = (e) => {
        this.setState({ category: e.target.value }, () => {
            this.getAllHelpVideos()
        })
    }

    getAllHelpVideos = () => {
        const { count, search, filter, pageNo, category } = this.state;

        let params = {
            params: {
                search,
                count,
                filter,
                category,
                pageNo: pageNo - 1,
            }
        }

        getAllHelpVideos(params)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({
                        helpVideos: response.responseData.result,
                        helpVideosCount: response.responseData.totalCount,
                        Loader: false,
                        searchLoader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ Loader: false, searchLoader: false })
                    toast.error(response.error.errorMessage)
                }

            })
    }

    closeModal = () => {
        this.setState({
            openAddHelpVideoPopup: false,
            deletVideoId: '',
            openDeleteHelpVideoPopup: false
        })
    }

    handleChange = (e) => {
        const { addVideoRequest } = this.state
        this.setState({
            addVideoRequest: {
                ...addVideoRequest,
                [e.target.name]: e.target.value
            }
        })
    }

    handleCategory = (category) => {
        const { addVideoRequest } = this.state
        this.setState({
            addVideoRequest: {
                ...addVideoRequest,
                category: category.value
            }
        }, () => {
            this.prepareSubCategories(category.value)
        })
    }

    handleSubCategory = (subCategory) => {
        const { addVideoRequest } = this.state
        this.setState({
            addVideoRequest: {
                ...addVideoRequest,
                subCategory: subCategory.value
            }
        })
    }

    addVideoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            validationError: ''
        })
        const { addVideoRequest } = this.state
        if (!addVideoRequest.videoTitle ||
            !addVideoRequest.videoUrl ||
            !addVideoRequest.category ||
            !addVideoRequest.subCategory) {
            this.setState({
                validationError: 'Please fill all fields'
            })
        } else {
            this.setState({ validationError: '', loader: true })

            addHelpVideo(addVideoRequest)
                .then((res) => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getAllHelpVideos()
                        this.closeModal();
                        toast.success(response.responseData.message)
                        this.setState({ loader: false })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    deleteHelpVideoHandle = (video) => {
        this.setState({
            openDeleteHelpVideoPopup: true,
            deletVideoId: video._id
        })
    }

    handleAddVideo = (video) => {
        this.setState({
            openAddHelpVideoPopup: true
        })
    }
    deleteVideo = () => {
        const { deletVideoId } = this.state
        this.setState({ Loader: true })
        let paramsObj = {
            helpVideoId: deletVideoId
        }
        deleteHelpVideo(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getAllHelpVideos()
                    this.closeModal();
                    toast.success(response.responseData.message)
                    this.setState({ loader: false })
                } else if (response.statusCode == 0) {
                    this.setState({ error: response.error.errorMessage, Loader: false })
                }
            })
    }

    render() {
        const { helpVideos, helpVideosCount, validationError, categories, subCategories, count, pageNo, openAddHelpVideoPopup, Loader, openDeleteHelpVideoPopup, searchLoader} = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Video</li>
                        </ol>
                        {
                            Loader
                                ? <div className="loader_wrapper"><LoaderSvg /></div>
                                : null
                        }
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4 className="cm_page_heading">Videos </h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search position-relative">
                                        {searchLoader ?
                                            <SearchSpinner type="text-info" />
                                            : null}
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by title"
                                            onChange={this.onSearch}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-control" onChange={this.filterSearchCategory} id="foodCategory">
                                            <option selected disabled> Filter by category.....</option>
                                            <option value="" > All</option>
                                            {categories.map((category) => (
                                                <option value={category.value} > {category.label} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.handleAddVideo}>Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text">Title </th>
                                        <th scope="col" className="filter_text">Category </th>
                                        <th scope="col" className="filter_text">Sub Category </th>
                                        <th scope="col" className="filter_text">Created</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        helpVideos.map((video, i) => {
                                            return (
                                                <tr key={video._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td className="cm_no_wrap"><a href={video.videoUrl} target="_blank" >{video.videoTitle}</a></td>
                                                    <td className="cm_no_wrap">{video.category.categoryTitle}</td>
                                                    <td className="cm_no_wrap">{video.subCategory.categoryTitle}</td>
                                                    <td className="cm_no_wrap">{dateFormat(video.created, "d mmmm yyyy")}</td>
                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-danger btn-sm" onClick={() => { this.deleteHelpVideoHandle(video) }}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {!helpVideos.length ? <h3 className="empty_error">Sorry, we couldn't find any content!</h3> : null}


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={helpVideosCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                        </div>

                    </div>


                </div>
                <Popup
                    open={openAddHelpVideoPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <AddVideo
                        handleClose={this.closeModal}
                        addVideoSubmit={this.addVideoSubmit}
                        validationError={validationError}
                        handleChange={this.handleChange}
                        categories={categories}
                        subCategories={subCategories}
                        handleCategory={this.handleCategory}
                        handleSubCategory={this.handleSubCategory}
                    />
                </Popup>

                <Popup
                    open={openDeleteHelpVideoPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4 mt-1">Are you sure, you want to delete this Video?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteVideo}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </HOC>
        )
    }
}


export default Foods;