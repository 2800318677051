import React, { useEffect, useState } from 'react'
import ReactDatePicker from "react-datepicker";
import Loader from "../loader";
import { toast } from 'react-toastify';
import { userDailyDiet } from '../../config/services/usersService';
import { useParams } from 'react-router-dom';
import DietTrackCard from '../common/DietTrackCard';

const CardWrapper = props => <div className="mb-5">{props.children}</div>;

const DietTrack = ({ props, userDetails, dietTrackProp }) => {
    const { id } = useParams()
    const [state, setState] = useState({
        selectedDate: new Date().setHours(0, 0, 0, 0),
        loader: false,
        morningSnacksList: [],
        breakfastList: [],
        lunchList: [],
        dinnerList: [],
        midMorningDietList: [],
        midEveningDietList: [],
        bedTimeDietList: [],
        dietTargets: {},
    });



    useEffect(() => {
        getUserDailyDiet();
    }, [])


    const handleDateChange = date => setState({ ...state, selectedDate: new Date(date).setHours(10, 0, 0, 0) }, () => {
        getUserDailyDiet();
    });

    const getUserDailyDiet = (params) => {
        const { selectedDate } = state;

        let paramsObj = {
            params: {
                dietOfUserId: id,
                currentDate: new Date(selectedDate).setHours(0, 0, 0, 0),
            }
        }
        userDailyDiet(paramsObj)
            .then((res) => {
                let response = res.data
                if (response.statusCode == 1) {
                    const { morningSnacksEaten, breakfastEaten, midMorningDietEaten, lunchEaten, midEveningDietEaten, dinnerEaten, bedTimeDietEaten, dietTargets } = response.responseData.result;

                    setState({
                        ...state,
                        morningSnacksList: morningSnacksEaten.length ? morningSnacksEaten[0].morningSnacksEatenObject : [],
                        breakfastList: breakfastEaten.length ? breakfastEaten[0].breakfastEatenObject : [],
                        midMorningDietList: midMorningDietEaten.length ? midMorningDietEaten[0].midMorningDietEatenObject : [],
                        lunchList: lunchEaten.length ? lunchEaten[0].lunchEatenObject : [],
                        midEveningDietList: midEveningDietEaten.length ? midEveningDietEaten[0].midEveningDietEatenObject : [],
                        dinnerList: dinnerEaten.length ? dinnerEaten[0].dinnerEatenObject : [],
                        bedTimeDietList: bedTimeDietEaten.length ? bedTimeDietEaten[0].bedTimeDietEatenObject : [],
                        dietTargets: dietTargets ? dietTargets : {},
                        dietPlanDetails: {},
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    setState({ ...state, loader: false })
                    toast.success(response.responseData.message)

                }
            })
    }

    const calculateTotalCalories = (foods) => {
        let totalCalorie = 0;
        if (foods.length) {
            foods.map(foodItem => {
                totalCalorie = totalCalorie + foodItem.calorieContent
            })
            return totalCalorie
        }
    }


    let { morningSnacksList, breakfastList, midMorningDietList, lunchList, midEveningDietList, dinnerList, bedTimeDietList, dietPlanDetails, selectedDate, validationError, loader, dietTargets } = state
    return (
        <div className="user-ui-wrapper">
            <div className="row justify-content-between">
                <div className="col-5">
                    <h4 className="user-detail-headings diet-track">
                        Diet Track
                        <span className='d-block sub-title-user-heading'>Per day calorie target- {dietTargets && dietTargets.perDayCalories} cal</span>
                    </h4>

                </div>

                <div className="cm_datepicker text-right col-md-3">
                    <label>Select Date</label>
                    <ReactDatePicker
                        className="form-control text-center"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        showMonthDropdown
                        showYearDropdown
                        withPortal
                        todayButton="Go to today"
                    />
                </div>
            </div>



            <div className="mt-3">
                {loader && <Loader />}
                {
                    !morningSnacksList.length &&
                        !breakfastList.length &&
                        !lunchList.length &&
                        !dinnerList.length &&
                        !midMorningDietList.length &&
                        !midEveningDietList.length &&
                        !bedTimeDietList.length ?
                        <div className="diet-plan-wrapper">
                            <div className="col"><h2 className="cm_not_found text-center">No diet tracked!</h2></div>
                        </div> :

                        <div>
                            <CardWrapper>
                                <div className='diet-plan-heading'>
                                    <h3 className='heading-title'>Morning Snacks</h3>

                                    <h6>Calorie target- {dietTargets.recommendedMorningSnacksCalories} cal</h6>
                                    <h6>Calorie taken- {calculateTotalCalories(morningSnacksList)} cal</h6>

                                </div>
                                <div className="diet-plan-wrapper">
                                    {!morningSnacksList.length ? <p className="text-danger">No food tracked</p> :
                                        <div className='diet-plan-header'>
                                            <div className="diet-plan-card-wrapper">
                                                {morningSnacksList.map((foodItem, i) => (
                                                    <DietTrackCard
                                                        foodItem={foodItem}
                                                        index={i}
                                                    />
                                                ))}
                                            </div>
                                        </div>}
                                </div>
                            </CardWrapper>

                            <CardWrapper>
                                <div className='diet-plan-heading'>
                                    <h3 className='heading-title'>Breakfast</h3>

                                    <h6>Calorie target- {dietTargets.recommendedBreakfastCalories} cal</h6>
                                    <h6>Calorie taken- {calculateTotalCalories(breakfastList)} cal</h6>

                                </div>
                                <div className='diet-plan-wrapper'>
                                    {!breakfastList.length ? <p className="text-danger">No food tracked</p> :
                                        <div className='diet-plan-header'>
                                            <div className="diet-plan-card-wrapper">
                                                {breakfastList.map((foodItem, i) => (
                                                    <DietTrackCard
                                                        foodItem={foodItem}
                                                        index={i}
                                                    />
                                                ))}
                                            </div>
                                        </div>}
                                </div>
                            </CardWrapper>

                            <CardWrapper>
                                <div className='diet-plan-heading'>
                                    <h3 className='heading-title'>Mid Morning Diet</h3>


                                    <h6>Calorie target- {dietTargets.recommendedMidMorningCalories} cal</h6>
                                    <h6>Calorie taken- {calculateTotalCalories(midMorningDietList)} cal</h6>

                                </div>
                                <div className='diet-plan-wrapper'>
                                    {!midMorningDietList.length ? <p className="text-danger">No food tracked</p> :
                                        <div className='diet-plan-header'>
                                            <div className="diet-plan-card-wrapper">
                                                {midMorningDietList.map((foodItem, i) => (
                                                    <DietTrackCard
                                                        foodItem={foodItem}
                                                        index={i}
                                                    />
                                                ))}
                                            </div>
                                        </div>}
                                </div>
                            </CardWrapper>

                            <CardWrapper>
                                <div className='diet-plan-heading'>
                                    <h3 className='heading-title'>Lunch</h3>


                                    <h6>Calorie target- {dietTargets.recommendedLunchCalories} cal</h6>
                                    <h6>Calorie taken- {calculateTotalCalories(lunchList)} cal</h6>

                                </div>
                                <div className='diet-plan-wrapper'>
                                    {!lunchList.length ? <p className="text-danger">No food tracked</p> :
                                        <div className='diet-plan-header'>
                                            <div className="diet-plan-card-wrapper">
                                                {lunchList.map((foodItem, i) => (
                                                    <DietTrackCard
                                                        foodItem={foodItem}
                                                        index={i}
                                                    />
                                                ))}
                                            </div>
                                        </div>}
                                </div>

                            </CardWrapper>

                            <CardWrapper>
                                <div className='diet-plan-heading'>
                                    <h3 className='heading-title'>Mid Evening Diet</h3>

                                    <h6>Calorie target- {dietTargets.recommendedMidEveningCalories} cal</h6>
                                    <h6>Calorie taken- {calculateTotalCalories(midEveningDietList)} cal</h6>

                                </div>
                                <div className='diet-plan-wrapper'>
                                    {!midEveningDietList.length ? <p className="text-danger">No food tracked</p> :
                                        <div className='diet-plan-header'>
                                            <div className="diet-plan-card-wrapper">
                                                {midEveningDietList.map((foodItem, i) => (
                                                    <DietTrackCard
                                                        foodItem={foodItem}
                                                        index={i}
                                                    />
                                                ))}
                                            </div>
                                        </div>}
                                </div>
                            </CardWrapper>


                            <CardWrapper>
                                <div className='diet-plan-heading'>
                                    <h3 className='heading-title'>Dinner</h3>

                                    <h6>Calorie target- {dietTargets.recommendedDinnerCalories} cal</h6>
                                    <h6>Calorie taken- {calculateTotalCalories(dinnerList)} cal</h6>

                                </div>
                                <div className='diet-plan-wrapper'>
                                    {!dinnerList.length ? <p className="text-danger">No food tracked</p> :
                                        <div className='diet-plan-header'>
                                            <div className="diet-plan-card-wrapper">
                                                {dinnerList.map((foodItem, i) => (
                                                    <DietTrackCard
                                                        foodItem={foodItem}
                                                        index={i}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </CardWrapper>

                            <CardWrapper>
                                <div className='diet-plan-heading'>
                                    <h3 className='heading-title'>Bed Time Diet</h3>

                                    <h6>Calorie target- {dietTargets.recommendedBedTimeCalories} cal</h6>
                                    <h6>Calorie taken- {calculateTotalCalories(bedTimeDietList)} cal</h6>

                                </div>
                                <div className='diet-plan-wrapper'>

                                    {!bedTimeDietList.length ? <p className="text-danger">No food tracked</p> :
                                        <div className='diet-plan-header'>
                                            <div className="diet-plan-card-wrapper">
                                                {bedTimeDietList.map((foodItem, i) => (
                                                    <DietTrackCard
                                                        foodItem={foodItem}
                                                        index={i}
                                                    />
                                                ))}
                                            </div>
                                        </div>}
                                </div>
                            </CardWrapper>

                        </div>}
            </div>
        </div>
    );
}

export default DietTrack;