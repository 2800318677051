import React, { Component } from 'react';
import { BackArrow, Calories } from '../../../assets/SvgIcon';
import { caloriesGraph, getUserTarget } from '../../../config/services/usersService';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Progress, Tabs, DatePicker } from 'antd';
import Chart from "react-apexcharts";

class CaloriesGraph extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedGraphType: 2,
            dateFrom: moment().startOf('month').valueOf(),
            dateTo: moment().endOf('month').valueOf(),
            currentDate: moment().valueOf(),
            userId: this.props.userId,
            graphData: {
                weeklyData: [],
                countData: [],
                countData1: []
            },
            target: {},
            totalBurntCalories: 0,
            maxValue: 0,
            totalIntakeCalories: 0,
            burnCalorieTarget: 0
        }
    }


    componentDidMount() {
        this.getCaloriesGraph()
        this.getTargets()
    }



    getTargets = () => {
        let { userId } = this.state
        getUserTarget({ userId })
            .then(res => {
                const burnCalorieTarget = res.data.responseData.burnedCalorie ? res.data.responseData.burnedCalorie : 0
                this.setState({ burnCalorieTarget })
            })
    }



    getCaloriesGraph = () => {
        let { selectedGraphType, dateFrom, dateTo, userId } = this.state
        let params = {
            dateFrom,
            dateTo,
            requestUserId: userId,
            graphType: selectedGraphType
        }

        caloriesGraph(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let maxValue = Math.max(...response.responseData.countData)
                    let totalBurntCalories = parseInt(response.responseData.countData.reduce((a, b) => a + b))
                    let totalIntakeCalories = parseInt(response.responseData.countData1.reduce((a, b) => a + b))

                    this.setState({
                        graphData: response.responseData,
                        // target: response.responseData.target,
                        maxValue,
                        totalBurntCalories,
                        totalIntakeCalories,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }




    handleDateChange = (date, dateType) => {
        let dateFrom = moment(date).startOf(dateType).valueOf()
        let dateTo = moment(date).endOf(dateType).valueOf()
        let currentDate = moment(date).valueOf()

        this.setState({ dateFrom, dateTo, currentDate }, () => { this.getCaloriesGraph() })
    }


    handleGraphType = (graphType) => {
        let dateFrom
        let dateTo

        switch (parseInt(graphType)) {
            case 0:
                dateFrom = moment().startOf('day').valueOf()
                dateTo = moment().endOf('day').valueOf()
                break;
            case 1:
                dateFrom = moment().startOf('week').valueOf()
                dateTo = moment().endOf('week').valueOf()
                break;
            case 2:
                dateFrom = moment().startOf('month').valueOf()
                dateTo = moment().endOf('month').valueOf()
                break;
        }

        this.setState({ selectedGraphType: parseInt(graphType), dateFrom, dateTo }, () => { this.getCaloriesGraph() })
    }





    render() {
        let { selectedGraphType, graphData, currentDate, totalBurntCalories, totalIntakeCalories, burnCalorieTarget } = this.state

        const data = {
            labels: graphData.weeklyData,
            datasets: [
                {
                    label: 'Calories Burnt',
                    backgroundColor: '#16D8E0',
                    borderColor: '#16D8E0',
                    borderWidth: 2,
                    data: graphData.countData,
                    fill: false,
                    lineTension: 0.1,
                },
                {
                    label: 'Calories Intake',
                    backgroundColor: "rgb(243, 69, 46)",
                    borderColor: "rgb(243, 69, 46)",
                    borderWidth: 2,
                    data: graphData.countData1,
                    fill: false,
                    lineTension: 0.1,
                }
            ]
        }



        let options = {
            colors: ["#1BC8D0", "#FF670F"],
            stroke: {
                width: 2
            },
            chart: {
                id: "Calories"
            },
            xaxis: {
                categories: graphData.weeklyData,
            }
        }

        let series = [
            {
                name: "Calories Burnt",
                data: graphData.countData
            },
            {
                name: "Calories Intake",
                data: graphData.countData1
            }
        ]


        return (
            <div className="graph-module">
                <div className="row justify-content-center">
                    <div className="col-md-4 col-xl-3">
                        {/* <div className="heading">
                            <h5>Steps Taken</h5>
                        </div> */}
                        <div className="tab-group flex-column d-flex align-items-baseline justify-content-between">
                            <div className="filter-tabs w-100">
                            <Tabs defaultActiveKey={selectedGraphType} onChange={this.handleGraphType}>
                                <Tabs.TabPane
                                    tab={<span>Month</span>}
                                    key={2}
                                >
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab={<span>Week</span>}
                                    key={1}
                                >
                                </Tabs.TabPane>

                                <Tabs.TabPane
                                    tab={<span>Day</span>}
                                    key={0}
                                >
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <div className="user-intake-detail d-block w-100">
                            <div className="tabs-date-picker w-100">
                                {selectedGraphType == 2 && <DatePicker.MonthPicker allowClear={false} className="form-control" placeholder="Select Month" format='MMM YYYY' onChange={(val) => this.handleDateChange(val, 'month')}
                                defaultValue={moment(new Date(currentDate), 'MMM YYYY')} />}

                                {selectedGraphType == 1 && <DatePicker.WeekPicker allowClear={false} className="form-control" placeholder="Select Week" onChange={(val) => this.handleDateChange(val, 'week')}
                                    defaultValue={moment(new Date(currentDate))} />}

                                {selectedGraphType == 0 && <DatePicker allowClear={false} className="form-control" placeholder="Select date" format='Do MMM YYYY' onChange={(val) => this.handleDateChange(val, 'day')}
                                    defaultValue={moment(new Date(currentDate), 'Do MMM YYYY')} />}
                            </div>
                            <div className="progress-section text-center mb-3">
                                <div className="postion-rel">
                                    <Progress type="circle" percent={(totalBurntCalories / burnCalorieTarget) * 100} width={80} />
                                    <span><Calories /></span>
                                </div>
                            </div>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>
                                            Total Calories Taken
                                        </td>
                                        <td>
                                            {totalIntakeCalories} Cal
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Calories Burnt</td>
                                        <td>{totalBurntCalories} Cal</td>
                                    </tr>                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-8 ml-auto">
                        <div className="text-center">
                            <div className="heading text-left">
                                <h5>Calories</h5>
                            </div>
                            <div className="graph-wrap">
                                <Chart
                                        options={options}
                                        series={series}
                                        type="line"
                                />   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




export default CaloriesGraph;