import React, { Component } from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import Popup from "reactjs-popup"
import HOC from '../HOC';
import Header from './template/Header';
import SideBar from './template/SideBar';
import Dashboard from './dashboard/Dashboard';
import Users from './users/Users';
import Profile from './profile/Profile';
import Answers from './answers/Answers';
import EditProfile from './profile/EditProfile';
import ChangePassword from './forgetPassword/ChangePassword';
import Device from './device/Device';
import EditDevice from './device/EditDevice';
import Exercise from './exercise/Exercise';
import Coupons from './coupons/Coupons';
import Questionaire from './questionaire/Questionaire';
import { checkSession } from '../config/services/usersService';
import { logout, getAdminProfile } from '../config/services/adminService';
import sessionExpireIcon from "../assets/images/sessionExpired.png"
import Products from './products/Products';
import EditProduct from './products/EditProduct';
import UserChart from './users/UsersChart';
import Dieticians from './dietician/Dieticians';
import Foods from './foodItems/foodList'
import FoodCategory from './foodItems/foodCategories'
import HelpVideoCategory from './helpVideos/helpVideoCategory'
import HelpVideos from './helpVideos/helpVideos'
import ExerciseCategory from './exerciseCategory/exerciseCategory'
import UserRatings from './dietician/UserRatings'
import FoodRequests from './foodItems/foodRequests'
import DietPlanTempelate from './dietPlanTempelate/DietPlanTempelate'
import FitnessPlanTemplate from './fitnessPlanTemplate/FitnessPlanTemplate'
import ViewTempelate from './dietPlanTempelate/ViewTempelate'
import ViewFitnessTemplate from './fitnessPlanTemplate/ViewTemplate'
import UsersList from './dietPlan/UsersList'
import SmartDietPlans from './dietPlan/SmartDietPlans'
import ViewDietPlan from './dietPlan/ViewDietPlan'
import ViewFitnessPlan from './fitnessPlan/ViewFitnessPlan'
import SubAdmins from './subAdmins/SubAdmins'
import Roles from './roles/Roles'
import Coins from './coins/Coins'
import RewardProducts from './rewardProducts/RewardProducts'
import RewardTransactions from './rewardTransactions/RewardTransactions'
import TransactionDetails from './transactionDetails/TransactionDetails'
import OutdoorTracks from './outdoorTracks/OutdoorTracks'
import Trainers from './trainers/Trainers'
import categoriesTimeslot from './categoriesTimeslot/categoriesTimeslot'
import ServiceCategories from './serviceCategories/ServiceCategories'
import ServiceBanners from './serviceBanners/ServiceBanners'
import CallSchedules from './trainers/CallSchedules'
import Holidays from './holidays/Holidays'
import DeliveryRiders from './deliveryRiders/DeliveryRiders'
import PageNotFound from './pageNotFound/PageNotFound'
import GraphsSidebar from './users/userGraphs/GraphsSidebar'
import { Pincode } from './pincode'
import Vendors from './vendors'
import AssociateEngineer from './associateEngineer'
import ProductServiceIncident from './productServiceIncident';
import CRMAgent from './crmAgent';
import AddGym from './addGym';
import Gyms from './gyms';
import GymManagers from './gymManagers';
import EquipmentModels from './equipmentModels';
import GymDetails from './gymDetails';
import SpareParts from './spareParts';

import { toast } from 'react-toastify';
// import { Dropdown } from 'react-bootstrap';
import CustomerTypes from './customerTypes/CustomerTypes';
import CustomerServiceTypes from './customerServiceTypes/CustomerServiceTypes';
import ServicePolicy from './servicePolicy/ServicePolicy';
import { Menus } from './template/Menus';
import PrivateRoute from './PrivateRoute';
import Stations from './stations/Stations';
import VideoSessions from './videoSessions/VideoSessions';
import GuaranteedPlanFeature from './guaranteedPlanFeatures/GuaranteedPlanFeature';
import Ratings from './guaranteedPlanFeatures/Ratings';
import Questions from './guaranteedPlanFeatures/Questions';
import Blog from './blog/Blog'
import Story from './story/Story'
import Testimonial from './testimonial/Testimonial'
import AddBlog from './blog/AddBlog'
import EditBlog from './blog/EditBlog'
import OrderManagement from './orderManagement/OrderManagement'
import WhyChooseUs from './whyChooseUs/WhyChooseUs'
import LocationL1L2 from './locationL1L2/LocationL1L2'
import TrainerRatings from './trainers/TrainerRatings'
import TrainerLiveRatings from './trainers/TrainerLiveRatings'
import Leaves from './trainers/Leaves'
import TrainerSchedule from './trainers/TrainerSchedule';
import AdminDashboard from './adminDashbaord/AdminDashboard';
import ProductJob from './productJob/ProductJob';
import AdminService from './adminServices/AdminService';
import UserJobDetail from './profile/UserJobDetails';

import Streaming from './streaming/Streaming'
import Streaming2 from './streaming/Streaming2'
import StreamingDetail from './streaming/details'
import Ads from './ads/adsList'
import Careers from './careers'

import lodashArray from 'lodash/array'
import detailssfx from './streaming/detailssfx';
import Emoji from './Emoji/Emoji';
import EmojiDetail from './Emoji/EmojiDetail';
import Stores from './stores/Stores';
//set all order module in 'orders'
import Orders from './orders';
import OrdersImportExport from './orders/ImportExport';
import OrderDetail from './orders/OrderDetail';
//set all deliverable pincode module in 'deliverablePincode'
import DeliverablePincode from './deliverablePincode';
import DeliverablePincodeImportExport from './deliverablePincode/ImportExport';


import StreamingDetailsOld from './streaming/DetailsOld';
import Inventory from './inventory/index';
import Coupon from './coupon/index';
import GymsJobs from "./productJobsForReport";
import GetJobsForReport from "./productJobsForReport";
import VendorTraining from "./vendorTraining/vendorTraining";

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sessionExpired: false,
            accessiblePaths: Menus,
            menuStatus: false,
            serviceAccessibleOps:[]
        }

        this.handlelogOut = this.handlelogOut.bind(this);
    }

    componentWillMount() {
        console.log('session token', localStorage.getItem('accessToken'))
        if (localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null) {
            this.props.history.push('/login')
            return;
        }
        else {
            this.getProfile()
            this.checkSessionFunction()
        }
    }

    getProfile = () => {
        getAdminProfile()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let { result } = response.responseData
                    localStorage.setItem('userName',`${result && result.firstName} ${result && result.lastName}` )
                    if (result && result.rolesAllowed && result.rolesAllowed.length) {
                        let { rolesAllowed } = result
                        let accessiblePaths = []
                        let serviceAccessibleOps = []

                        rolesAllowed.map(role => {
                            accessiblePaths = lodashArray.unionBy(accessiblePaths, role.accessiblePaths, 'path') //to remove duplicate paths in roles
                            serviceAccessibleOps = role.serviceAccessibleOps
                        })

                        this.setState({ serviceAccessibleOps: serviceAccessibleOps })
                        accessiblePaths = accessiblePaths.length ? accessiblePaths : Menus
                        let newPaths = Menus.filter(obj => accessiblePaths.find(pathObj => pathObj.path == obj.path))

                        this.setState({ accessiblePaths: newPaths }, () => this.setState({ menuStatus: true }));
                    }
                    else if (result && result.roleId && result.roleId.accessiblePaths) {
                        let { roleId } = result
                        let accessiblePaths = roleId && roleId.accessiblePaths && roleId.accessiblePaths.length ? roleId.accessiblePaths : Menus
                        let newPaths = Menus.filter(obj => accessiblePaths.find(pathObj => pathObj.path == obj.path))

                        this.setState({ accessiblePaths: newPaths }, () => this.setState({ menuStatus: true }))
                    }
                    else {
                        this.setState({ accessiblePaths: Menus }, () => this.setState({ menuStatus: true }))
                        console.log('*****Error in accessible paths****');
                    }
                }
                else if (response.statusCode == 0) {
                    toast.error(res.data.error.errorMessage);
                }
            })
    }

    checkSessionFunction(accessToken) {
        checkSession()
            .then(res => {
                if (res.data.statusCode === 0) {
                    this.setState({ sessionExpired: true })
                } else {
                    this.setState({ sessionExpired: false })
                }
            })
    }

    sessionExpired = () => {
        this.setState({ sessionExpired: false })
        localStorage.clear();
        this.props.history.push("/login")
    }

    handlelogOut() {
        logout()
            .then(res => {
                if (res.data.statusCode == 1) {
                    localStorage.clear();
                    this.props.history.push('/login')
                } else if (res.data.statusCode == 0) {
                    toast.error(res.data.error.errorMessage);
                }
            })
    }

    I
    render() {
        let { accessiblePaths, menuStatus,serviceAccessibleOps } = this.state;
        return (
            <HOC>
                {menuStatus ? <Header menu={accessiblePaths} handlelogOut={this.handlelogOut} /> : ''}
                {/* <SideBar accessiblePaths={accessiblePaths} /> */}
                <Switch>
                    <PrivateRoute path="/dashboard" pathValue='dashboard' accessiblePaths={accessiblePaths} component={Dashboard} />
                    <PrivateRoute exact path="/users" pathValue='users' accessiblePaths={accessiblePaths} component={Users} />
                    <PrivateRoute path="/device" pathValue='device' accessiblePaths={accessiblePaths} component={Device} />
                    <PrivateRoute path="/coupons" pathValue='coupons' accessiblePaths={accessiblePaths} component={Coupons} />
                    <PrivateRoute path="/products" pathValue='products' accessiblePaths={accessiblePaths} component={Products} />
                    <PrivateRoute exact path="/dieticians" pathValue='dieticians' accessiblePaths={accessiblePaths} component={Dieticians} />
                    <PrivateRoute path="/food-category" pathValue='food-category' accessiblePaths={accessiblePaths} component={FoodCategory} />
                    <PrivateRoute path="/helpVideoCategory" pathValue='helpVideoCategory' accessiblePaths={accessiblePaths} component={HelpVideoCategory} />
                    <PrivateRoute path="/helpVideos" pathValue='helpVideos' accessiblePaths={accessiblePaths} component={HelpVideos} />
                    <PrivateRoute exact path="/foods" pathValue='foods' accessiblePaths={accessiblePaths} component={Foods} />
                    <PrivateRoute path="/questionaire" pathValue='questionaire' accessiblePaths={accessiblePaths} component={Questionaire} />
                    <PrivateRoute exact path="/exercise-category" pathValue='exercise-category' accessiblePaths={accessiblePaths} component={ExerciseCategory} />
                    <PrivateRoute exact path="/exercise" pathValue='exercise' accessiblePaths={accessiblePaths} component={Exercise} />
                    <PrivateRoute exact path="/dietPlanTempelate" pathValue='dietPlanTempelate' accessiblePaths={accessiblePaths} component={DietPlanTempelate} />
                    <PrivateRoute exact path="/fitnessPlanTemplate" pathValue='fitnessPlanTemplate' accessiblePaths={accessiblePaths} component={FitnessPlanTemplate} />
                    <PrivateRoute exact path="/dietPlans" pathValue='dietPlans' accessiblePaths={accessiblePaths} component={UsersList} />
                    <PrivateRoute exact path="/smartdietPlans" pathValue='smartdietPlans' accessiblePaths={accessiblePaths} component={SmartDietPlans} />
                    <PrivateRoute exact path="/subAdmins" pathValue='subAdmins' accessiblePaths={accessiblePaths} component={SubAdmins} />
                    <PrivateRoute exact path="/roles" pathValue='roles' accessiblePaths={accessiblePaths} component={Roles} />
                    <PrivateRoute exact path="/coins" pathValue='coins' accessiblePaths={accessiblePaths} component={Coins} />
                    <PrivateRoute exact path="/rewardProducts" pathValue='rewardProducts' accessiblePaths={accessiblePaths} component={RewardProducts} />

                    <PrivateRoute exact path="/streaming" pathValue='streaming' accessiblePaths={accessiblePaths} component={Streaming} />
                    {/* new page of streaming  */}
                    <PrivateRoute exact path="/streaming2" pathValue='streaming2' accessiblePaths={accessiblePaths} component={Streaming2} />

                    <PrivateRoute exact path="/rewardTransactions" pathValue='rewardTransactions' accessiblePaths={accessiblePaths} component={RewardTransactions} />
                    <PrivateRoute exact path="/transactionDetails" pathValue='transactionDetails' accessiblePaths={accessiblePaths} component={TransactionDetails} />
                    <PrivateRoute exact path="/outdoorTracks" pathValue='outdoorTracks' accessiblePaths={accessiblePaths} component={OutdoorTracks} />
                    <PrivateRoute exact path="/trainers" pathValue='trainers' accessiblePaths={accessiblePaths} component={Trainers} />
                    <PrivateRoute exact path="/categoriesTimeslot" pathValue='categoriesTimeslot' accessiblePaths={accessiblePaths} component={categoriesTimeslot} />
                    <PrivateRoute exact path="/serviceCategories" pathValue='serviceCategories' accessiblePaths={accessiblePaths} component={ServiceCategories} />
                    <PrivateRoute exact path="/serviceBanners" pathValue='serviceBanners' accessiblePaths={accessiblePaths} component={ServiceBanners} />
                    <PrivateRoute exact path="/holidays" pathValue='holidays' accessiblePaths={accessiblePaths} component={Holidays} />
                    <PrivateRoute exact path="/customerTypes" pathValue='customerTypes' accessiblePaths={accessiblePaths} component={CustomerTypes} />
                    <PrivateRoute exact path="/customerServiceTypes" pathValue='customerServiceTypes' accessiblePaths={accessiblePaths} component={CustomerServiceTypes} />
                    <PrivateRoute exact path="/servicePolicy" pathValue='servicePolicy' accessiblePaths={accessiblePaths} component={ServicePolicy} />
                    <PrivateRoute exact path="/deliveryRiders" pathValue='deliveryRiders' accessiblePaths={accessiblePaths} component={DeliveryRiders} />
                    <PrivateRoute exact path="/stations" pathValue='stations' accessiblePaths={accessiblePaths} component={Stations} />
                    <PrivateRoute exact path="/videoSessions" pathValue='videoSessions' accessiblePaths={accessiblePaths} component={VideoSessions} />
                    <PrivateRoute exact path="/guaranteedPlanFeatures" pathValue='guaranteedPlanFeatures' accessiblePaths={accessiblePaths} component={GuaranteedPlanFeature} />
                    <PrivateRoute exact path="/guaranteedPlanFeatures/ratings/:id" pathValue='guaranteedPlanFeatures' accessiblePaths={accessiblePaths} component={Ratings} />
                    <PrivateRoute exact path="/guaranteedPlanFeatures/questions/:id" pathValue='guaranteedPlanFeatures' accessiblePaths={accessiblePaths} component={Questions} />
                    <PrivateRoute exact path="/blogs" pathValue='blogs' accessiblePaths={accessiblePaths} component={Blog} />
                    <PrivateRoute exact path="/blogs/addBlog" pathValue='blogs' accessiblePaths={accessiblePaths} component={AddBlog} />
                    <PrivateRoute exact path="/blogs/editBlog/:id" pathValue='blogs' accessiblePaths={accessiblePaths} component={EditBlog} />
                    <PrivateRoute exact path="/stories" pathValue='stories' accessiblePaths={accessiblePaths} component={Story} />
                    <PrivateRoute exact path="/testimonial" pathValue='testimonial' accessiblePaths={accessiblePaths} component={Testimonial} />
                    <PrivateRoute exact path="/orderManagement" pathValue='orderManagement' accessiblePaths={accessiblePaths} component={OrderManagement} />
                    <PrivateRoute exact path="/whyChooseUs" pathValue='whyChooseUs' accessiblePaths={accessiblePaths} component={WhyChooseUs} />
                    <PrivateRoute exact path="/fitnessPlanTemplate" pathValue='fitnessPlanTemplate' accessiblePaths={accessiblePaths} component={FitnessPlanTemplate} />
                    <PrivateRoute exact path="/locationsAvailable" pathValue='locationsAvailable' accessiblePaths={accessiblePaths} component={LocationL1L2} />
                    <PrivateRoute path="/adminDashboard" pathValue='adminDashboard' accessiblePaths={accessiblePaths} component={AdminDashboard} />
                    <PrivateRoute path="/stores" pathValue='stores' accessiblePaths={accessiblePaths} component={Stores} />

                    {/*Order Module Listing  */}
                    <PrivateRoute exact path="/orders" pathValue='orders' accessiblePaths={accessiblePaths} component={Orders} />
                    {/* Order Module import & export  */}
                    <PrivateRoute exact path="/orders/import-export" pathValue='orders' accessiblePaths={accessiblePaths} component={OrdersImportExport} />

                    <PrivateRoute exact path="/orders/:id" pathValue='orders' accessiblePaths={accessiblePaths} component={OrderDetail} />

                    {/* Deliverable Pincode Module Listing  */}
                    <PrivateRoute exact path="/deliverablePincode" pathValue='deliverablePincode' accessiblePaths={accessiblePaths} component={DeliverablePincode} />
                    {/* Deliverable Pincode Module import & export  */}
                    <PrivateRoute exact path="/deliverablePincode/import-export" pathValue='deliverablePincode' accessiblePaths={accessiblePaths} component={DeliverablePincodeImportExport} />

                    <PrivateRoute exact path="/productJob" pathValue='productJob' accessiblePaths={accessiblePaths} component={ProductJob} />
                    <PrivateRoute exact path="/reportOnJobs" pathValue='reportOnJobs' accessiblePaths={accessiblePaths} component={GetJobsForReport} />
                    <PrivateRoute exact path="/adminService" pathValue='adminService' accessiblePaths={accessiblePaths} serviceAccessibleOps ={serviceAccessibleOps} component={AdminService} />
                    <PrivateRoute exact path='/pincode' pathValue='pincode' accessiblePaths={accessiblePaths} component={Pincode} />
                    <PrivateRoute exact path="/vendors" pathValue='vendors' accessiblePaths={accessiblePaths} component={Vendors} />

                    <PrivateRoute exact path="/crmAgent" pathValue='crmAgent' accessiblePaths={accessiblePaths} component={CRMAgent} />
                    <PrivateRoute exact path="/vendorTraining" pathValue='vendorTraining' accessiblePaths={accessiblePaths} component={VendorTraining} />
                    <Route exact path="/addGym" pathValue='addGym' accessiblePaths={accessiblePaths} component={AddGym} />
                    <Route exact path="/editGym" pathValue='editGym' accessiblePaths={accessiblePaths} component={AddGym} />
                    <Route exact path="/gyms" pathValue='gyms' accessiblePaths={accessiblePaths} component={Gyms} />
                    <Route exact path="/gymManagers" pathValue='gymManagers' accessiblePaths={accessiblePaths} component={GymManagers} />
                    <Route exact path="/equipmentModels" pathValue='/equipmentModels' accessiblePaths={accessiblePaths} component={EquipmentModels} />
                    <Route exact path="/gym/:id" pathValue='gymDetails' accessiblePaths={accessiblePaths} component={GymDetails} />
                    <Route exact path="/spareParts" pathValue='/spareParts' accessiblePaths={accessiblePaths} component={SpareParts} />
                    <PrivateRoute exact path="/ads" pathValue='ads' accessiblePaths={accessiblePaths} component={Ads} />
                    <PrivateRoute exact path="/careers" pathValue='careers' accessiblePaths={accessiblePaths} component={Careers} />

                    <Route exact path="/streamingDetails2" pathValue='streamingDetails' accessiblePaths={accessiblePaths} component={detailssfx} />

                    <Route exact path="/streamingDetails" pathValue='streamingDetails' accessiblePaths={accessiblePaths} component={StreamingDetail} />
                    <Route exact path="/streaming-details-old" pathValue='streaming-details-old' accessiblePaths={accessiblePaths} component={StreamingDetailsOld} />

                    <Route exact path="/engineers/:id" pathValue='engineers' accessiblePaths={accessiblePaths} component={AssociateEngineer} />
                    <Route exact path="/userJobDetail" pathValue='userJobDetail' accessiblePaths={accessiblePaths} component={UserJobDetail} />
                    <Route exact path="/productServiceIncident" component={ProductServiceIncident} />
                    <Route exact path="/users/Graphs/:id" component={GraphsSidebar} />
                    <Route path="/profile/:id" component={Profile} />
                    <Route path="/answers/:id" component={Answers} />
                    <Route path="/edit-profile" component={EditProfile} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/edit-device/:id" component={EditDevice} />
                    <Route path="/edit-product/:id" component={EditProduct} />
                    <Route path="/chart/:id" component={UserChart} />
                    <Route exact path="/foods/foodRequests" component={FoodRequests} />
                    <Route exact path="/dieticians/user-ratings/:id" component={UserRatings} />
                    <Route exact path="/dietPlanTempelate/viewTempelate/:id" component={ViewTempelate} />
                    <Route exact path="/fitnessPlanTemplate/viewTemplate/:id" component={ViewFitnessTemplate} />
                    <Route exact path="/dietPlans/viewDietPlan/:id" component={ViewDietPlan} />
                    <Route exact path="/fitnessPlan/viewFitnessPlan/:id" component={ViewFitnessPlan} />
                    <Route exact path="/trainers/callSchedules" component={CallSchedules} />
                    <Route exact path="/trainers/user-ratings/:id" component={TrainerRatings} />
                    <Route exact path="/trainers/user-live-ratings/:id" component={TrainerLiveRatings} />
                    <Route exact path="/trainers/schedule/:id" component={TrainerSchedule} />
                    <Route exact path="/trainers/leaves/:id" component={Leaves} />
                    <Route exact path="/emoji" component={Emoji} />
                    <Route exact path="/emoji/:id" component={EmojiDetail} />
                    {/* <Route path="/service/:id/:selectedTab" component={Profile} />   */}
                    <Route path="/service/:id/:selectedTab" component={Profile} />
                    <Route path="/job/:id/:selectedTab/:requestId" component={Profile} />
                    {/* -inventory- */}
                    <Route exact path="/inventory" pathValue='inventory' accessiblePaths={accessiblePaths} component={Inventory} />
                    <Route exact path="/coupon" pathValue='coupon' accessiblePaths={accessiblePaths} component={Coupon} />
                    <Redirect from="/" to="/dashboard" />


                </Switch>
                <Popup
                    open={this.state.sessionExpired}
                    closeOnDocumentClick={false}
                >
                    <div className="session_expired cm_modal text-center">
                        <img className="sessionIcon mb-4" src={sessionExpireIcon} />
                        <h4 className="text-center">Oops!! Please Login again</h4>
                        <button onClick={this.sessionExpired} className="btn btn-primary btn-block">Login</button>
                    </div>
                </Popup>
            </HOC>
        )
    }
}


export default Layout;