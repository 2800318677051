import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";

import { getEngineerList, deleteEngineer, editEngineer, addEngineer } from '../../config/services/Engineer';
import {  getAllStates, getAllCitys, getAllPincodes, getPincodesByVendorId } from '../../config/services/servicePincodes'
import { toast } from 'react-toastify';
import { Button, Modal, FormLabel, Row, Form, FormGroup, FormControl } from 'react-bootstrap';
import PopUpModal from '../../components/common/PopUpModal';
import noImage from '../../assets/images/no-image.jpg';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import _ from 'lodash';
import Loader from '../loader';
import { Tag } from 'antd';

const { getSortingIcon, removeDuplicates, } = require("../utils/Util.js");
const { productTypeOptions, validPhoneNumber, skillTypeOption, genderOption, validCountryCode } = require("../utils/Util.js");


const columnDetails = [
    { fieldName: "S.No." },
    { fieldName: "Profile Pic" },
    { fieldName: "First Name", isSortable: true, dataIndex: "firstName" },
    { fieldName: "Last Name", isSortable: true, dataIndex: "lastName" },
    { fieldName: "Email", isSortable: true, dataIndex: "email" },
    { fieldName: "Mobile" },
    { fieldName: "Gender" },
    { fieldName: "City " },
    { fieldName: "State" },
    { fieldName: "Job Position" },
    { fieldName: "In Tat" },
    { fieldName: "Out Tat" },
    { fieldName: "Open Calls" },
    { fieldName: "Total Calls" },
    { fieldName: "Skill Type" },
    { fieldName: "Product Type" },
    { fieldName: "Pincode" },
    { fieldName: "Action" }
];

let formField = [{ fieldName: "First Name", stateName: "firstName", isRequire: true, type: "text", maxLength: 25, invalidMsg: "First Name should have altest one charater", isValid: false },
{ fieldName: "Last Name", stateName: "lastName", isRequire: true, type: "text", maxLength: 25, invalidMsg: "Last Name should have altest one charater", isValid: false },
{ fieldName: "Email", stateName: "email", isRequire: false, type: "email", maxLength: 30, invalidMsg: "Please enter valid email", disabled: true, isValid: true },
{ fieldName: "Gender", stateName: "gender", isRequire: true, type: "select", options: genderOption, valueField: "genderSelected", invalidMsg: "Please select gender", isValid: false, isSearchable: true },
{ fieldName: "Country Code", stateName: "countryCode", isRequire: true, type: "tel", validateField: validCountryCode, maxLength: 2, invalidMsg: "Please ener valid country code", isValid: false },
{ fieldName: "Mobile No", stateName: "mobileNo", isRequire: true, type: "tel", validateField: validPhoneNumber, maxLength: 10, invalidMsg: "Please ener valid mobile number", isValid: false },

{ fieldName: "State", stateName: "selectedState", isRequire: true, type: "selectState", isSearchable: true, disabled: true, isMulti: true, valueField: "selectedState" },
{ fieldName: "City", stateName: "selectedCities", isRequire: true, type: "selectCity", isSearchable: true, disabled: true, isMulti: true, valueField: "selectedCities" },
{ fieldName: "Pincode", stateName: "selectedPincodeData", isRequire: true, isSearchable: false, type: "asyncSelect", valueField: "selectedPincodeData", invalidMsg: "Please select atleast one pincode", isValid: true },

{ fieldName: "Job Position", stateName: "jobPosition", type: "text", maxLength: 20, invalidMsg: "Job Position should have altest one charater", isValid: true },
{ fieldName: "Skill Type", stateName: "skillType", isRequire: true, type: "select", isMulti: true, options: skillTypeOption, valueField: "skillTypeData", invalidMsg: "Please select atleast one skill", isValid: false },
{ fieldName: "Product Type", stateName: "productType", isRequire: true, type: "select", isSearchable: true, isMulti: true, options: productTypeOptions, valueField: "productTypeData", invalidMsg: "Please select atleast one product type", isValid: false },
{ fieldName: "Profile Picture", stateName: "profilePic", type: "file", isValid: true }
]

class AssociateEngineer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            savingLoader: false,
            engineerList: [],
            search: '',
            count: 10,
            totalEngineerCount: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            addProductJobPopup: false,
            deleteProductJobPopup: false,
            selectedEnginnerId: "",
            error: "",
            assignEngineer: [],           
            skillType: [],
            productType: [],
            sortInfo: {},
            apiCalled: false,
            jobPosition: '',

            vendorState: [],
            vendorCity: [],
            vendorPincode: [],

            statePageNo: 0,
            stateCount: 1000,
            allstates: [],
            selectedDefaultState: [],
            selectedState: [],

            cityCount: 5000,
            allCities: [],
            selectedDefaultCity: [],
            selectedCities: [],

            pincodeCount: 10000,
            selectedPincodeData: [],
        }
    }

    componentDidMount() {
        this.getEngineerList();
        this.getAllStates();        
        this.getPincodesData("", "pincodeSearch", "pincodeData");                  
    }

    handlePageChange = (pageNumber) => { this.setState({ pageNo: pageNumber }, () => { this.getEngineerList() }) }

    handleAssociateSearch = (e) => {
        this.setState({ search: e.target.value, pageNo: 1, apiCalled: true }, () => {
            this.getEngineerList();
        });
    }
    handleSort = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getEngineerList());
    }
    
    getEngineerList = () => {
        let { search, count, pageNo, sortType, sortField, sortInfo } = this.state;
        let { fieldName, order } = sortInfo;

        let params = {
            search,
            count,
            pageNo: pageNo - 1,
            sortType: order,
            sortField: fieldName,
            vendorId: this.props.match.params.id
        }

        getEngineerList({ params })
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        engineerList: response.responseData.engineers,
                        totalEngineerCount: response.responseData.engineersCount,
                        loader: false,
                        apiCalled: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, apiCalled: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    getAllStates = () => {
        const { statePageNo, stateCount } = this.state;
        let states = [];
        let params = { search: '', sortField: "_id", sortType: 1, pageNo: statePageNo, count: stateCount }
        getAllStates({ params })
        .then(res => {
            let response = res.data
            if (response.statusCode == 1) {                
                let data = res.data.responseData
                let totalCount = data.totalCount;
                let pgno = statePageNo + 1;
                
                if (data.result && data.result.length > 0) {
                    data.result.forEach(key => {
                        states.push({
                            label: key._id,
                            value: key._id
                        })
                    })
                }

                this.setState({ statePageNo: pgno, allstates: states}, function () {
                    if(totalCount > (pgno * stateCount)) {
                        this.getAllStates();
                    }
                });  
            } else if (response.statusCode == 0) {
                toast.error(response.error.errorMessage)
            }
        })
    }

    // call once time only get vendor state, city and pincodes 
    getPincodesData = (val, searchField, stateField) => {
        let onlyNumber = /^[0-9]*$/;
        let params = {
            count: 150,
            pageNo: 0,
            [searchField]: new RegExp(onlyNumber).test(val) ? val : '',
            vendorId: this.props.match.params.id
        }

        return getPincodesByVendorId({ params }).then(res => {
            if (res && res.data && res.data.statusCode === 1) {
                let data = res.data.responseData

                let pincodes = [];
                let cities = [];
                data.result.map(item => {
                    pincodes.push({
                        label: item[stateField.replace("Data", "")],
                        value: item._id,
                        city: item.city,
                        state: item.state
                    })

                    let foundCity = cities.filter(function (city) { return city.label == item.city });
                    if(foundCity.length == 0) {
                        cities.push({
                            label: item.city,
                            value: item.city,
                            state: item.state
                        })
                    }
                })

                let allState = [...new Set(pincodes.map(item => item.state))];    
                let states = [];                
                if (allState && allState.length > 0) {
                    allState.forEach(key => {
                        states.push({
                            label: key,
                            value: key
                        })
                    })
                }

                this.setState({
                    "vendorState": states,
                    "vendorCity": cities,
                    "vendorPincode": pincodes
                })
            } else if (res.data.statusCode == 0) {
                console.log(res.data.error.errorMessage)
            }
        })
    }


    
    handleSteteChange = (e) => {  
        const { selectedState, selectedCities, selectedPincodeData } = this.state;   
        if(e && e.length > 0) {
            if(e.length > this.state.selectedState.length) {   
                // add city and pincodes             
                let findState = e[e.length - 1].label;
                let params = {
                    count: this.state.cityCount,
                    pageNo: this.statepageno,
                    states: findState
                }
                getAllCitys({ params }).then(res => {                   
                    if (res && res.data.statusCode === 1) {
                        let getcitys = res.data.responseData.result;
                        if (getcitys && getcitys.length > 0) {
                            getcitys.forEach(key => {                
                                selectedCities.push({
                                    label: key._id,
                                    value: key._id,
                                    state: key.state.state
                                })
                            })  
                            
                            this.setState({ selectedCities: selectedCities}, function () {    
                                let cities = getcitys.map((city) => city._id).join();        
                                this.getPincodesFromCities(cities,0); 
                            });  
                        }                                                                                         
                    } else if (res.data.statusCode == 0) {
                        console.log(res.data.error.errorMessage);
                    }
                })
            } else {
                // remove city and pincodes
                let getPreviousState = selectedState.map((state) => state.value);
                let getCurrentState = e.map((state) => state.value);
                var differenceState = getPreviousState.filter(x => getCurrentState.indexOf(x) === -1);
                if(differenceState && differenceState.length > 0) {
                    differenceState.forEach(state => { 
                        if(selectedCities && selectedCities.length > 0) {
                            var getSelectedCities = selectedCities.filter(function(el) { return el.state != state; });
                            this.setState({ selectedCities: getSelectedCities });
                        }
                        if(selectedPincodeData && selectedPincodeData.length > 0) {                            
                            var getSelectedPincodes = selectedPincodeData.filter(function(el) { return el.state != state; });
                            this.setState({ selectedPincodeData: getSelectedPincodes });
                        }
                    });
                }               
            }

            this.setState({ selectedState: e}, function () { });
        } else {
            this.setState({ selectedState: [], selectedCities: [], selectedPincodeData: []});
        }      
    }

    loadCityOptions = (value, isEdit) => { 
        const { allCities, selectedState } = this.state; 
        let params = { 
            citySearch: value, 
            sortField: "_id", 
            sortType: 1 
        };

        if (selectedState && selectedState.length > 0) {
            params["states"] = selectedState.map((city) => city.label).join();
        }
        
        this.setState({ allCities: []});
        return getAllCitys({ params }).then(res => { 
            if (res && res.data.statusCode === 1) {
                let citys = res.data.responseData.result;     
                if (citys && citys.length > 0) {
                    citys.forEach(key => {    
                        let foundCity = allCities.filter(function (city) { return city.label == key._id });
                        if(foundCity.length == 0) {
                            allCities.push({
                                label: key._id,
                                value: key._id,
                                state: key.state.state
                            })
                        }
                    })   
                    if(isEdit && isEdit === 1) {
                        this.setState({ selectedCities: allCities});
                    } else {
                        return  allCities;
                    }                                         
                } 
            } else if (res.data.statusCode == 0) {
                console.log(res.data.error.errorMessage);
            }
        });        
    }

    handleCityChange = (e) => {
        const { selectedCities, selectedPincodeData } = this.state; 
        if(e && e.length > 0) {
            if(e.length > this.state.selectedCities.length) {       
                let getcities = e[e.length - 1].label;
                this.getPincodesFromCities(getcities,0);                 
            } else {
                let getPreviousCities = selectedCities.map((city) => city.value);
                let getCurrentCities = e.map((city) => city.value);
                var differenceCities = getPreviousCities.filter(x => getCurrentCities.indexOf(x) === -1);
                if(differenceCities && differenceCities.length > 0) {
                    differenceCities.forEach(city => { 
                        if(selectedPincodeData && selectedPincodeData.length > 0) {                            
                            var getSelectedPincodes = selectedPincodeData.filter(function(el) { return el.city != city; });
                            this.setState({ selectedPincodeData: getSelectedPincodes });
                        }
                    });
                }               
            }

            this.setState({ selectedCities: e}, function () { });
        } else {
            this.setState({ selectedCities: [], selectedPincodeData: []});
        }  
    }

    getPincodesFromCities = (getcities, pageNo) => {  
        const { selectedPincodeData } = this.state;        
        if (getcities) {
            let params = {
                count: this.state.pincodeCount,
                pageNo: pageNo,
                pincodeSearch: "",
                pincodes: [],
                citys: getcities
            };

            return getAllPincodes({ params }).then((res) => {
                if (res && res.data.statusCode === 1) {
                    let data = res.data.responseData;
                    let result = data[0].result;
                    let totalPage = data[1]
                    // console.log('rkk totalPage:::', totalPage);

                    if (result && result.length > 0) {
                        result.forEach(key => {
                            selectedPincodeData.push({
                                label: key.pincode,
                                value: key._id,
                                city: key.city,
                                state: key.state
                            })
                        })
                        this.setState({ selectedPincodeData: selectedPincodeData })
                    }               
                } else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage);
                }
            })
        } 
    }

    


    closePopup = (popUpState, formField) => {
        if (formField && formField.length > 0) {
            for (const field in formField) {
                let element = formField[field]
                this.setState({ [element.stateName]: "", [element.valueField]: "" });
            }
        }
        this.setState({ [popUpState]: false })
    }

    changeStatus = (statusValue, id) => {
        this.setState({ statusPopup: true, selectedStatus: statusValue, selectedJobForJobId: id })
    }
    
    onHandleSelect = (selectedData, fieldName, valueField) => {
        let index = formField.findIndex(item => item.stateName === fieldName);
        formField[index].isValid = true;

        this.setState({ [fieldName]: selectedData && selectedData.value, [valueField]: selectedData })
    }

    onHandleAsyncSelect = (selectedData, fieldName, valueField) => {
        let stateValue = [];
        let valueData = [];
        let index = formField.findIndex(item => item.stateName === fieldName);
        formField[index].isValid = true;

        selectedData && selectedData.map(item => {
            valueData.push(item)
            stateValue.push((item.value ? item.value : item));
        });

        this.setState({ [fieldName]: stateValue, [valueField]: valueData })
    }    

    handleChange = (type, e, length, fieldName) => {
        let index = formField.findIndex(item => item.stateName === type);
        formField[index].isValid = true;

        if (type === "profilePic") {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    profileImagePrev: reader.result
                });
            }
            reader.readAsDataURL(file)
            this.setState({
                profileImagePrev: file
            })

            this.setState({
                [type]: e.target.files[0], error: ""
            });
        }
        else {
            if (formField[index].validateField && !formField[index].validateField(e.target.value)) {
                formField[index].isValid = false;
            }
            this.setState({ [type]: e.target.value, error: "" });
        }
    }

    
    loadOptionByval = (value) => { 
        // return this.getPincodesData(value, "pincodeSearch", "pincodeData") 
    }

    handleAddEngineerPopup = () => {
        this.setState({
            addEngineerPopup: true,
            selectedEnginnerId: "",
            profileImagePrev: "",
            validated: false,
            selectedState: this.state.vendorState,
            selectedCities: this.state.vendorCity,            
            selectedPincodeData: this.state.vendorPincode
        })
    }

    handleAddSubmit = (event) => {
        let self = this;
        let hasError = false
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;


        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true })
            return;
        }      

        // formField.forEach(item => {
        //     if (!item.isValid) {
        //         hasError = true;
        //         return;
        //     }
        // });

        if (!hasError) {
            self.setState({ savingLoader: true })
            let params = new FormData();
            formField.forEach(item => {
                if (item.stateName === "selectedState" ) {
                    params.append('state', JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value)))                    
                } else if (item.stateName === "selectedCities" ) {
                    params.append('city', JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value)))                    
                } else if (item.stateName === "selectedPincodeData" ) {
                    params.append('pincode', JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value))) 
                } else if (item.stateName === "productType" || item.stateName === "skillType" ) {
                    params.append([item.stateName], JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value)))                    
                } else {
                    params.append([item.stateName], self.state[item.stateName] && self.state[item.stateName] || "")
                }
            });
            params.append("vendorId", this.props.match.params.id);

            let apiObject = addEngineer(params);
            apiObject.then(res => {
                self.setState({ savingLoader: false })
                const response = res.data;
                if (response.statusCode == 1) {
                    self.setState({ selectedEnginnerId: "" })
                    toast.success(response.responseData.message)
                    self.closePopup("addEngineerPopup", formField);
                    self.getEngineerList();
                } else if (response.statusCode == 0) {
                    self.setState({ error: response.error.errorMessage })
                }
            });
        }
    }


    handleEditEngineerPopup = (data) => {
        let states = [], cities = [], pincodes = [];
        // data["state"] && data.state.map(item => {
        //     if (item) {
        //         state.push({ value: item, label: item })
        //     }
        // })

        // this.setState({ selectedState: state, selectedCities: []}, function () {    
        //     let getCities = data.city.map((city) => city).join();
        //     this.loadCityOptions(getCities, 1)
        // })

        if(data.pincode && data.pincode.length > 0) {
            removeDuplicates(data.pincode, "city").map((item, i) => (
                cities.push({
                    label: item.city,
                    value: item.city,
                    state: item.state
                })
            ))

            removeDuplicates(data.pincode, "state").map((item, i) => (
                states.push({
                    label: item.state,
                    value: item.state
                })
            ))
        }

        data["pincode"] && data.pincode.map(item => {
            if (item) {
                pincodes.push({ value: item._id, city: item.city, label: item.pincode, state: item.state })
            }
        })

        let skillTypeData = skillTypeOption && skillTypeOption.filter(f => data["skillType"] && data["skillType"].includes(f.value));
        let productTypeData = productTypeOptions && productTypeOptions.filter(f => data["productType"] && data["productType"].includes(f.value));
        let genderSelected = genderOption && genderOption.filter(f => data["gender"] && data["gender"].includes(f.value));

        formField.forEach(item => {
            if (data[item.stateName])
                item.isValid = true;
        });

        this.setState({
            "firstName": data["firstName"],
            "lastName": data["lastName"],
            "email": data["email"],
            "countryCode": data["countryCode"],
            "mobileNo": data["mobileNo"],
            "selectedState": states,
            "selectedCities": cities,
            "selectedPincodeData": pincodes,
            "skillType": data.skillType,
            "productType": data["productType"],
            "profilePic": data["profilePic"],
            "addEngineerPopup": true,
            "selectedEnginnerId": data._id,
            "profileImagePrev": data.profilePic,
            "skillTypeData": skillTypeData,
            "productTypeData": productTypeData,
            "gender": data["gender"],
            "genderSelected": genderSelected,
            "jobPosition": data["jobPosition"]
        });

    }

    handleEditSubmit = (event) => {
        let self = this;
        let hasError = false
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        // formField.forEach(item => {
        //     if (!item.isValid) {
        //         hasError = true;
        //         return;
        //     }
        // });

        this.setState({ validated: true })
        if (!hasError) {
            self.setState({ savingLoader: true })
            let params = new FormData();
            formField.forEach(item => {
                if(item.stateName != "city") {
                    if (item.stateName === "selectedState" ) {
                        params.append('state', JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value)))                    
                    } else if (item.stateName === "selectedCities" ) {
                        params.append('city', JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value)))                    
                    } else if (item.stateName === "selectedPincodeData" ) {
                        params.append('pincode', JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value))) 
                    } else if (item.stateName === "productType" || item.stateName === "skillType" ) {
                        params.append([item.stateName], JSON.stringify(self.state[item.valueField] && self.state[item.valueField].map(item => item.value)))                    
                    } else {
                        params.append([item.stateName], self.state[item.stateName] && self.state[item.stateName] || "")
                    }
                }
            });
            params.append("vendorId", this.props.match.params.id);

            let apiObject = editEngineer(params)
            params.append("engineerId", this.state.selectedEnginnerId);
            apiObject = editEngineer(params);
            apiObject.then(res => {
                this.setState({ savingLoader: false })
                const response = res.data;
                if (response.statusCode == 1) {
                    this.setState({ selectedEnginnerId: "" })
                    toast.success(response.responseData.message)
                    this.closePopup("addEngineerPopup", formField);
                    this.getEngineerList();
                } else if (response.statusCode == 0) {
                    this.setState({ error: response.error.errorMessage })
                }
            });
        }

    }
    
    handleDeleteEngineerPopup = (engineer) => {
        this.setState({ deleteEngineerPopup: true, selectedEngineer: engineer })
    }
   
    submitDeleteEngineer = () => {
        let { selectedEngineer } = this.state
        this.setState({ loader: true });
        deleteEngineer({ engineerId: selectedEngineer._id })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getEngineerList();
                    this.closePopup("deleteEngineerPopup");
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })
    }



    render() {
        let { savingLoader, loader, engineerList, search, pageNo, count, totalEngineerCount, deleteEngineerPopup, addEngineerPopup, error, cityData, sortInfo, validated, apiCalled } = this.state;
        let { fieldName, order } = sortInfo;
        return (
            <HOC>
                {apiCalled && <Loader />}
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/vendors">Vendors</Link></li>
                            <li className="breadcrumb-item">Engineers</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h4 className="cm_page_heading">Engineers</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name or Email"
                                            onChange={this.handleAssociateSearch}
                                            value={this.state.search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                    <div className="col-md-2 text-right">
                                        <button className="btn btn-info btn-block" onClick={this.handleAddEngineerPopup}>Add Engineer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-striped text-center mb-5">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item, i) => {
                                            let { isSortable, dataIndex } = item;
                                            return isSortable ? <th scope="col" scope="col" key={i} className="filter_text" onClick={() => this.handleSort(dataIndex)}>{item.fieldName} &nbsp;  <i className={getSortingIcon(dataIndex == fieldName && order, "Text")} /></th>
                                                : <th scope="col" key={i}>{item.fieldName}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        engineerList && engineerList.map((engineer, index) => {
                                            // this function is used to filter the duplicates from array of objectsonbasis ok keys [key]: value
                                            return (
                                                <tr key={engineer._id}>
                                                    <td className="cm_no_wrap">{((index + 1) + ((pageNo - 1) * count))}</td>
                                                    <td className="cm_no_wrap"><img src={engineer.profilePic ? engineer.profilePic : noImage} className="exerciseImage" /></td>

                                                    <td className="cm_no_wrap"> {engineer.firstName}</td>
                                                    <td className="cm_no_wrap"> {engineer.lastName}</td>
                                                    <td className="cm_no_wrap"> {engineer.email}</td>
                                                    <td className="cm_no_wrap"> {engineer.mobileNo}</td>
                                                    <td className="cm_no_wrap"> {Number(engineer.gender) === 0 && "Male" || Number(engineer.gender) === 1 && "Female"}</td>
                                                    <td>
                                                        <div className='engineer-pincodes'>
                                                            {/* {engineer.city && engineer.city.length > 0?
                                                                engineer.city.map((c, i) => <p key={i}>{c}</p>)
                                                                :
                                                                engineer.city && removeDuplicates(engineer.pincode, "city").map((pincode, i) => <p key={i}>{pincode && pincode.city}</p>)
                                                            } */}

                                                            {engineer.pincode && removeDuplicates(engineer.pincode, "city").map((pincode, i) => (
                                                            <span key={i}>
                                                            <Tag color="geekblue">{pincode.city}</Tag>
                                                            </span>
                                                        ))}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='engineer-pincodes'>
                                                        {engineer.pincode && removeDuplicates(engineer.pincode, "state").map((pincode, i) => (
                                                            <span key={i}>
                                                            <Tag color="geekblue">{pincode.state}</Tag>
                                                            </span>
                                                        ))}
                                                        </div>
                                                    </td>
                                                    <td className="cm_no_wrap">{engineer.jobPosition}</td>
                                                    <td className="cm_no_wrap">{engineer.inTat}</td>
                                                    <td className="cm_no_wrap">{engineer.outTat}</td>
                                                    <td className="cm_no_wrap">{engineer.openCalls}</td>
                                                    <td className="cm_no_wrap">{engineer.totalCalls}</td>
                                                    <td>{engineer.skillType && engineer.skillType.map((skillType, i) => <p key={i}>{skillType}</p>)}</td>
                                                    <td>{engineer.productType && engineer.productType.map((productType, i) => <p key={i}>{productType}</p>)}</td>
                                                    <td >
                                                        <div className='engineer-pincodes'>
                                                            {engineer.pincode && engineer.pincode.map((pincode, i) => <span key={i}> <Tag color="geekblue">{pincode && pincode.pincode}</Tag></span>)}
                                                        </div>
                                                    </td>
                                                    {/* <td className="cm_no_wrap">{engineer.status}</td> */}

                                                    <td className="cm_no_wrap">
                                                        <Button className='m-1' variant="outline-info" onClick={() => this.handleEditEngineerPopup(engineer)}> <i className="fa fa-pencil" /> </Button>
                                                        <Button className='m-1' variant="outline-danger" onClick={() => this.handleDeleteEngineerPopup(engineer)}> <i className="fa fa-trash" /> </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalEngineerCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                            {!engineerList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>
                    </div>
                </div>
                <PopUpModal isOpen={deleteEngineerPopup} closeModal={() => this.closePopup("deleteEngineerPopup")} submit={this.submitDeleteEngineer} message={`Are you sure to delete this engineer?`} validationError={""} submitTitle="Delete" />

                <Modal show={addEngineerPopup} onHide={() => this.closePopup("addEngineerPopup", formField)} centered size="lg-engineers" backdrop="static" onKeyDown={e => e.stopPropagation()}
                    onClick={e => e.stopPropagation()}
                    onFocus={e => e.stopPropagation()}
                    onMouseOver={e => e.stopPropagation()}>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>{this.state.selectedEnginnerId ? "Edit Associate" : "Add Associate"}</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Form noValidate validated={validated} onSubmit={(e) => { !this.state.selectedEnginnerId ? this.handleAddSubmit(e) : this.handleEditSubmit(e) }} className="d-flex justify-content-center">
                            <div className="col-md-12">
                                {

                                    formField.map((field, index) => {
                                        let type = field.type;
                                        let toReturn;
                                        let customValidate = false;
                                        switch (type) {
                                            case "file":
                                                toReturn = <Fragment><input type="file" className="form-control col-md-10" onChange={(val) => this.handleChange(field.stateName, val)} id={field.stateName} name={field.stateName} />
                                                    {this.state.profileImagePrev ? <img width="400px" src={this.state.profileImagePrev} className="mt-2" /> : null}
                                                </Fragment>
                                                break;
                                            case "selectState":   
                                                return (                
                                                    <FormGroup key={index} className="form-group row">
                                                        <FormLabel className="col-md-2">{field.fieldName}</FormLabel>
                                                        < Select
                                                            isMulti={field.isMulti}
                                                            isSearchable={field.isSearchable}
                                                            require={true}
                                                            className="col-md-10 pl-0 pr-0"
                                                            onChange={(e) => this.handleSteteChange(e)}
                                                            placeholder={field.fieldName}
                                                            options={this.state.allstates}
                                                            value={this.state[field.valueField]}
                                                        />
                                                        {!field.isValid && <Form.Control.Feedback type="invalid" style={{ display: customValidate ? "block" : "none" }}>
                                                            <FormLabel className="col-md-2"></FormLabel>
                                                            <FormLabel className="col-md-10"> {customValidate && field.invalidMsg}</FormLabel>

                                                        </Form.Control.Feedback>}
                                                    </FormGroup>)
                                                break; 
                                            case "selectCity":   
                                                return (                
                                                    <FormGroup key={index} className="form-group row">
                                                        <FormLabel className="col-md-2">{field.fieldName}</FormLabel>
                                                        < AsyncSelect
                                                            isMulti={field.isMulti}
                                                            isSearchable={field.isSearchable}
                                                            require={true}
                                                            className="col-md-10 pl-0 pr-0"
                                                            onChange={(e) => this.handleCityChange(e)}
                                                            placeholder={field.fieldName}
                                                            options={this.state.allCities}
                                                            value={this.state[field.valueField]}
                                                            loadOptions={this.loadCityOptions}
                                                        />
                                                        {!field.isValid && <Form.Control.Feedback type="invalid" style={{ display: customValidate ? "block" : "none" }}>
                                                            <FormLabel className="col-md-2"></FormLabel>
                                                            <FormLabel className="col-md-10"> {customValidate && field.invalidMsg}</FormLabel>

                                                        </Form.Control.Feedback>}
                                                    </FormGroup>)
                                                break;
                                            case "select":
                                                customValidate = validated && !this.state[field.valueField]
                                                if (!field.isMulti) {

                                                    customValidate = validated && (!this.state[field.stateName]);

                                                }
                                                return (
                                                    <FormGroup key={index} className="form-group row">
                                                        <FormLabel className="col-md-2">{field.fieldName}</FormLabel>
                                                        < Select
                                                            isMulti={field.isMulti}
                                                            require={true}
                                                            className="col-md-10 pl-0 pr-0"
                                                            onChange={(e) => { field.isMulti && this.onHandleAsyncSelect(e, field.stateName, field.valueField) || this.onHandleSelect(e, field.stateName, field.valueField) }}
                                                            placeholder={field.fieldName}
                                                            options={field.stateName !== "gender" && field.options.filter((item) => item.value) || field.options}
                                                            value={this.state[field.valueField]}                                                            
                                                        />
                                                        {!field.isValid && <Form.Control.Feedback type="invalid" style={{ display: customValidate ? "block" : "none" }}>
                                                            <FormLabel className="col-md-2"></FormLabel>
                                                            <FormLabel className="col-md-10"> {customValidate && field.invalidMsg}</FormLabel>

                                                        </Form.Control.Feedback>}
                                                    </FormGroup>)
                                                break;
                                            case "asyncSelect":
                                                customValidate = validated && this.state[field.stateName] && !this.state[field.stateName].length;
                                                return (
                                                    <FormGroup key={index} className="form-group row">
                                                        <FormLabel className="col-md-2">{field.fieldName}</FormLabel>
                                                        <AsyncSelect
                                                            className="col-md-10 pl-0 pr-0 pincode-height-max"
                                                            require={true}
                                                            isMulti
                                                            value={this.state[field.valueField]}
                                                            // loadOptions={this.loadOptionByval}
                                                            defaultOptions={this.state.pincodeData}
                                                            onChange={(val) => this.onHandleAsyncSelect(val, field.stateName, field.valueField)}
                                                            disabled={field.disabled}
                                                            isSearchable={field.isSearchable}
                                                        />
                                                        {!field.isValid && <Form.Control.Feedback type="invalid" style={{ display: customValidate ? "block" : "none" }}>
                                                            <FormLabel className="col-md-2"></FormLabel>
                                                            <FormLabel className="col-md-10"> {customValidate && field.invalidMsg}</FormLabel>

                                                        </Form.Control.Feedback>}
                                                    </FormGroup>)

                                                break;
                                            case "text":
                                            case "number":
                                            case "email":
                                            case "tel":
                                                toReturn = <FormControl
                                                    maxLength={field.maxLength || null}
                                                    className="form-control col-md-10"
                                                    name={field.stateName}
                                                    type={field.type}
                                                    required={field.isRequire}
                                                    disabled={this.state.selectedEnginnerId && field.disabled}
                                                    onChange={(val) => this.handleChange(field.stateName, val, field.maxLength, field.fieldName)}
                                                    value={this.state[field.stateName]}
                                                    placeholder={field.fieldName}
                                                />
                                                break;

                                        }

                                        return (

                                            <FormGroup key={index} className="form-group row">
                                                <FormLabel className="col-md-2">{field.fieldName}</FormLabel>
                                                {toReturn}
                                                {!field.isValid && <Form.Control.Feedback type="invalid">
                                                    <FormLabel className="col-md-2"></FormLabel>
                                                    <FormLabel className="col-md-10"> {field.invalidMsg}</FormLabel>
                                                </Form.Control.Feedback>}
                                            </FormGroup>

                                        )
                                    })
                                }
                                {error && <Row className="justify-content-center">
                                    <h4 className="text-center mb-2  error_message">{error}</h4>

                                </Row>
                                }

                                <Row className="justify-content-center">
                                    <Button variant="danger" onClick={() => this.closePopup("addEngineerPopup", formField)}>Cancel</Button>
                                    <Button className="ml-2" variant="success" type="submit" disabled={savingLoader}>
                                        {!savingLoader ? <span>Submit</span> :      
                                            <>                             
                                            <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                                            <span>Saving....</span>    
                                            </>                                
                                        }
                                    </Button>
                                </Row>

                            </div>
                        </Form>

                    </Modal.Body>
                </Modal>
            </HOC >
        )
    }
}




export default AssociateEngineer;