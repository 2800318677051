import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getGymEquipmentLogistics } from '../../config/services/commercialService';
import { Modal, FormControl, Form, FormGroup, FormLabel } from 'react-bootstrap';
import moment from 'moment';

const RequestSentModal = (props) => {
    const { showModal, hideModal, equipmentLogisticsId } = props;
    const [logisticsDetails, setLogisticsDetails] = useState();

    useEffect(() => {
        fetchLogisticsDetails();
    }, []);

    const fetchLogisticsDetails = () => {
        const params = {equipmentLogisticsId: equipmentLogisticsId};
        getGymEquipmentLogistics({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    data.result && setLogisticsDetails(data.result[0]);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    return (
        <div className="main-wrapper">
            <Modal show={showModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Installation Request Details</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <Form>
                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Dispatch Reference Number</FormLabel>
                                <FormControl
                                    value={logisticsDetails && logisticsDetails.dispatchReferenceNo ? logisticsDetails.dispatchReferenceNo : ""}
                                    name="dispatchReferenceNo"
                                    type="text"
                                    readOnly
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Vehicle Number</FormLabel>
                                <FormControl
                                    value={logisticsDetails && logisticsDetails.vehicleNo ? logisticsDetails.vehicleNo : ""}
                                    name="vehicleNo"
                                    type="text"
                                    readOnly
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Driver Contact Number</FormLabel>
                                <FormControl
                                    value={logisticsDetails && logisticsDetails.driverContactNo ? logisticsDetails.driverContactNo : ""}
                                    name="driverContactNo"
                                    type="number"
                                    readOnly
                                />
                            </FormGroup>
                        </div>

                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Dispatch Warehouse</FormLabel>
                                <FormControl
                                    value={logisticsDetails && logisticsDetails.warehouse ? logisticsDetails.warehouse.name : ""}
                                    name="dispatchWarehouse"
                                    type="text"
                                    readOnly
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Delivery ETA</FormLabel>
                                <FormControl
                                    value={logisticsDetails && logisticsDetails.deliveryETA ? moment(logisticsDetails.deliveryETA).format('DD-MM-YYYY') : ""}
                                    name="deliveryETA"
                                    type="string"
                                    readOnly
                                />
                            </FormGroup>
                        </div>

                        <div className='row' style={{marginTop: "30px"}}>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Notes</FormLabel>
                                <FormControl
                                    value={logisticsDetails && logisticsDetails.notes ? logisticsDetails.notes : ""}
                                    name="notes"
                                    as="textarea"
                                    rows="4"
                                    cols="120"
                                    readOnly
                                />
                            </FormGroup>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default RequestSentModal;