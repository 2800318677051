import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { getServices } from "../../config/services/serviceCategoriesService";
import { addServiceBanner, getServiceBanners, updateServiceBanner, deleteServiceBanner } from "../../config/services/serviceBannersService";
import { addGuaranteedPlanFeature, getGuaranteedPlanFeatureList, editGuaranteedPlanFeature, uploadFeatureImage } from "../../config/services/GuaranteedPlanFeatureService";

import { LoaderSvg } from '../Reusable';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import { Button, Modal, Badge, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { PlanFeatureComponents } from './PlanFeatureComponents'

let image;
class GuaranteedPlanFeatures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            addFeaturePopup: false,
            deleteFeaturePopup: false,
            heading: '',
            description: '',
            serviceId: '',
            featureImagesPreview: [],
            featureImages: [],
            editFeaturePopup: false,
            tempFeatureId: "",
            validationError: "",
            search: '',
            count: 20,
            pageNo: 1,
            totalFeatures: 50,
            sortType: '',
            sortField: '',
            planFeaturesList: [],
            services: [],
            selectedService: {},
            title: '',
            subFeatures: [],
            subHeading: '',
            description: '',
            componentType: '',
            placeHolderImage: '',
            placeHolderImagePreview: '',
            bannerTitle: '',
            bannerSubHeading: ''
        }
    }


    openAddFeaturePopup = () => this.setState({ addFeaturePopup: !this.state.addFeaturePopup });

    openDeleteFeaturePopup = (banner) => {
        this.setState({
            deleteFeaturePopup: !this.state.deleteFeaturePopup,
            tempFeatureId: banner._id,
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ pageNo: pageNumber }, () => { this.getPlanFeaturesList(); });
    }

    deleteFeature = (e) => {
        e.preventDefault();
        this.setState({ loader: true })
        const { tempFeatureId } = this.state;
        deleteServiceBanner({ serviceBannerId: tempFeatureId })
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getPlanFeaturesList()
                    this.closeModal()
                    this.setState({ loader: false })
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }

    openEditFeaturePopup = (details) => {
        let { title, coverImage, _id, feature, subHeading, description, componentType, placeHolderImage, bannerTitle, bannerSubHeading } = details

        feature.map(subFeature => {
            subFeature.featureImagePreview = subFeature.featureImagePreview ? subFeature.featureImagePreview : subFeature.featureImage
            subFeature.featureImage = ''
        })

        this.setState({
            editFeaturePopup: !this.state.editFeaturePopup,
            tempFeatureId: _id,
            title,
            description,
            subHeading,
            featureImagesPreview: coverImage,
            subFeatures: feature,
            componentType,
            placeHolderImagePreview: placeHolderImage,
            bannerTitle, bannerSubHeading
        })
    };

    submitEditFeature = (e) => {
        e.preventDefault();
        let { featureImages, title, subFeatures, subHeading, description, componentType, tempFeatureId, placeHolderImage, bannerTitle, bannerSubHeading } = this.state;

        let params = new FormData();

        featureImages.length && featureImages.map(image => {
            params.append('coverImage', image);
        })

        subFeatures.length && subFeatures.map(feature=>{
            feature.featureImage = feature.featureImagePreview
        })

        params.append('title', title);
        params.append('feature', JSON.stringify(subFeatures));
        params.append('subHeading', subHeading);
        params.append('description', description);
        params.append('componentType', componentType);
        params.append('guaranteedPlanId', tempFeatureId);
        params.append('placeHolderImage', placeHolderImage);
        params.append('bannerTitle', bannerTitle);
        params.append('bannerSubHeading', bannerSubHeading);


        if (this.isValidated()) {
            this.setState({ validationError: "" })
            this.editFeatureApi(params);
        }
    }

    editFeatureApi = (params) => {
        this.setState({ loader: true })
        editGuaranteedPlanFeature(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getPlanFeaturesList()
                    this.closeModal()
                    this.setState({ loader: false })
                    toast.success(res.data.responseData.message)
                }
                else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }

    closeModal = () => {
        image = '';
        this.setState({
            addFeaturePopup: false, deleteFeaturePopup: false, editFeaturePopup: false, heading: "",
            openEditFeaturePopup: false, description: "", featureImagesPreview: [], featureImages: [], tempFeatureId: "",
            validationError: '', description: '', serviceId: '', selectedService: {}, title: '', subFeatures: [],
            subHeading: '', componentType: '', placeHolderImage: '', placeHolderImagePreview: '', bannerTitle: '',
            bannerSubHeading: ''
        })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, validationError: '' })
    }

    handleImgPreivew = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        image = e.target.files[0];

        this.setState({ featureImagesPreview: e.target.files[0] })
        reader.addEventListener("load", () => {
            this.setState({ featureImagesPreview: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    handleAddGuaranteedPlanFeature = e => {
        e.preventDefault();
        let { featureImages, title, subFeatures, subHeading, description, componentType, placeHolderImage, bannerTitle, bannerSubHeading } = this.state;

        let params = new FormData();

        featureImages.length && featureImages.map(image => {
            params.append('coverImage', image);
        })
        params.append('title', title);
        params.append('feature', JSON.stringify(subFeatures));
        params.append('subHeading', subHeading);
        params.append('description', description);
        params.append('componentType', componentType);
        params.append('placeHolderImage', placeHolderImage);
        params.append('bannerTitle', bannerTitle);
        params.append('bannerSubHeading', bannerSubHeading);


        if (this.isValidated()) {
            this.setState({ validationError: "" })
            this.addGuaranteedPlanFeatureApi(params);
        }
    }

    isValidated = () => {
        let validated = true
        let { featureImagesPreview, title, subFeatures, subHeading, description, componentType, bannerTitle, bannerSubHeading } = this.state;

        if (!title) {
            this.setState({ validationError: "Title can't be empty" });
            validated = false
        }
        else if (!featureImagesPreview.length) {
            this.setState({ validationError: "Please select atleast one image for the feature" });
            validated = false
        }
        else if (!subHeading) {
            this.setState({ validationError: "Sub heading can't be empty" });
            validated = false
        }
        else if (!description) {
            this.setState({ validationError: "Description can't be empty" });
            validated = false
        }
        else if (!description) {
            this.setState({ validationError: "Description can't be empty" });
            validated = false
        }
        else if (!bannerTitle) {
            this.setState({ validationError: "Banner Title can't be empty" });
            validated = false
        }
        else if (!bannerSubHeading) {
            this.setState({ validationError: "Banner Sub Heading can't be empty" });
            validated = false
        }
        else if (!componentType && componentType !== 0) {
            this.setState({ validationError: "Please select component type." });
            validated = false
        }

        return validated
    }

    addGuaranteedPlanFeatureApi = (params) => {
        this.setState({ loader: true })
        addGuaranteedPlanFeature(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false }, () => this.getPlanFeaturesList())
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        validationError: res.data.error.errorMessage,
                        loader: false
                    })
                }
            })
    }



    getPlanFeaturesList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search
                , count
                , pageNo: pageNo - 1
                , sortType
                , sortField
            }
        }
        getGuaranteedPlanFeatureList(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let { result, totalCount } = res.data.responseData;
                    this.setState({
                        planFeaturesList: result,
                        totalFeatures: totalCount,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getPlanFeaturesList());

    getServices = (val) => {
        let paramsobj = { params: { count: 0, pageNo: 0, search: val } }
        return getServices(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let { result } = res.data.responseData
                    let services = []
                    result.map(service => {
                        services.push({ label: service.serviceName, value: service._id })
                    })
                    this.setState({ services })
                    return services
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    componentDidMount() {
        this.getPlanFeaturesList();
        this.getServices();
    }

    loadServices = (val) => {
        return this.getServices(val)
    }

    serviceSelect = (service) => {
        this.setState({ serviceId: service.value, selectedService: service })
    }

    handleSelectComponent = (component) => {
        let { planFeaturesList } = this.state
        if (planFeaturesList.find(feature => feature.componentType === component.value)) {
            this.setState({ validationError: `Component Type '${component.label}' already exist!` })
            return
        } else {
            this.setState({ validationError: '' })
        }

        this.setState({ componentType: component.value })
    }

    handleAddSubFeature = () => {
        let subFeatures = Array.from(this.state.subFeatures)
        subFeatures.push({});
        this.setState({ subFeatures })
    }


    handleMultipleImgPreivew = (e) => {
        // let { featureImagesPreview } = this.state
        let featureImagesPreview = []
        let files = Array.from(e.target.files)
        if (files) {
            files.map(image => {
                featureImagesPreview.push(URL.createObjectURL(image))
            })
            this.setState({ featureImages: files, featureImagesPreview })
        }
    }


    handleImageChange = (e) => {
        let { name, value } = e.target
        let file = e.target.files[0];
        let previewFieldName = name + 'Preview'
        let fieldName = name
        let reader = new FileReader();
        reader.onloadend = () => { this.setState({ [previewFieldName]: reader.result }) }
        reader.readAsDataURL(file)
        this.setState({ [previewFieldName]: file })
        this.setState({ [fieldName]: e.target.files[0] });
    }

    handleSubFeaturesImages = (e, i) => {
        let subFeatures = Array.from(this.state.subFeatures)
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = () => {
            subFeatures[i].featureImagePreview = reader.result
            subFeatures[i].featureImage = file
            this.setState({ subFeatures })
        }
        reader.readAsDataURL(file)
    }


    removeSubFeature = (index) => {
        let subFeatures = Array.from(this.state.subFeatures)
        subFeatures.splice(index, 1);
        this.setState({ subFeatures })
    }

    handleSubFeaturesChange = (e, index) => {
        let { name, value } = e.target
        let subFeatures = Array.from(this.state.subFeatures)
        subFeatures[index][name] = value
        this.setState({ subFeatures })
    }


    parseProductType = (productType) => {
        let ProductType = PlanFeatureComponents.find(option => option.value === productType)
        return ProductType ? ProductType['label'] : "-"
    }

    handleUploadSubFeatureImage = (subFeature, i) => {
        let { _id, featureImage } = subFeature
        let { tempFeatureId, subFeatures } = this.state

        let params = new FormData()
        params.append("featureId", tempFeatureId)
        params.append("subFeatureId", _id)
        params.append("featureImage", featureImage)

        this.setState({ [_id]: true })

        uploadFeatureImage(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    //To hide the button after upload
                    subFeatures[i].featureImage = ''
                    this.setState({
                        loader: false, [_id]: false, subFeatures
                    }, () => this.getPlanFeaturesList())
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        validationError: res.data.error.errorMessage,
                        loader: false,
                        [_id]: false
                    })
                }
            })
    }


    render() {
        const { planFeaturesList, loader, addFeaturePopup, heading, deleteFeaturePopup, editFeaturePopup, validationError, pageNo, count, totalFeatures, services, selectedService, subFeatures, title, description, subHeading, featureImagesPreview, componentType, placeHolderImagePreview, bannerTitle, bannerSubHeading } = this.state;

        return (
            <div className="body-container-wrapper">
                <div className="body-container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item">Guaranteed Plan Features</li>
                    </ol>
                    {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                    <div className="users_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-9">
                                    <h4 className="cm_page_heading">Guaranteed Plan Features</h4>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button className="btn btn-primary btn-block" onClick={this.openAddFeaturePopup}>Add New</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-3">
                        {planFeaturesList.map((feature, i) => (
                            <div className="col-sm-3" key={feature._id}>
                                <div className="card card_content">
                                    {
                                        feature.placeHolderImage ? <img className="card-img-top" src={feature.placeHolderImage} alt="feature image" /> : <div className="card-img-top">No Image</div>
                                    }

                                    {<span className="card_style_cat">{feature.title}</span>}

                                    <div className="card-body">
                                        <h4 className="card-title">{feature.subHeading}</h4>
                                        <h6>Component Type:  {this.parseProductType(feature.componentType)}</h6>

                                        <p>Created: {dateFormat(feature.created, "d mmmm yyyy")}</p>

                                        <div className="category_description">
                                            <h3>Description</h3>
                                            <p>{feature.description ? feature.description : 'No Description'}</p>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Link title='View Ratings' className="btn btn-outline-primary btn-block" to={'/guaranteedPlanFeatures/ratings/' + feature.componentType}>
                                                    <i class="fa fa-star"/>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link title='View Questions' className="btn btn-outline-primary btn-block" to={'/guaranteedPlanFeatures/questions/' + feature.componentType}> 
                                                <i class="fa fa-question"/>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <button title='Edit Component' className="btn btn-outline-primary btn-block" onClick={() => this.openEditFeaturePopup(feature)} >
                                                <i className="fa fa-pencil ml-1" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                    {!planFeaturesList.length ? <h3 className="empty_error">Sorry, we couldn't find any content!</h3> : null}
                </div>


                <Modal
                    show={addFeaturePopup}
                    onHide={this.closeModal}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Feature</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form>
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Sub Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="subHeading"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Banner Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bannerTitle"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Banner Sub Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bannerSubHeading"
                                    onChange={this.handleChange}
                                />
                            </div>


                            <div className="form-group">
                                <label>Component Type</label>
                                <Select
                                    className='cm_select_filter'
                                    isSearchable
                                    placeholder='Component Type...'
                                    options={PlanFeatureComponents}
                                    onChange={(val) => this.handleSelectComponent(val)}
                                />
                            </div>

                            <div className="form-group">
                                <label>Sub Features</label>
                                {subFeatures.map((spec, i) => (
                                    <div className='row' key={i}>
                                        <h4 className="cm_pos_rel col-12">Sub Feature {i + 1}
                                            <span className="btn btn-danger" onClick={() => this.removeSubFeature(i)}><i className="fa fa-trash" /></span>
                                        </h4>
                                        <div className="form-group col">
                                            <label>Sub Title</label>
                                            <input type="text" className="form-control"
                                                name='subTitle' onChange={(val) => this.handleSubFeaturesChange(val, i)} value={spec.subTitle}
                                            />
                                        </div>

                                        <div className="form-group col">
                                            <label>Sub Feature</label>
                                            <input type="text" className="form-control"
                                                name='subFeature' onChange={(val) => this.handleSubFeaturesChange(val, i)} value={spec.subFeature}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <i className="fa fa-plus-square cm_pointer" onClick={this.handleAddSubFeature}>Add Feature</i>


                            <div className="form-group">
                                <label className="d-block">Feature Images</label>
                                <div className="radio_wrapper text-center">
                                    <input
                                        type="file"
                                        multiple
                                        onChange={this.handleMultipleImgPreivew}
                                    />
                                </div>
                            </div>
                            {featureImagesPreview.map((imageURI, i) => (<img key={i} className="tracks_img" src={imageURI} alt="Photo uploaded" />))}

                            <div className="form-group mt-5 mb-5">
                                <label className="d-block">Place Holder Image</label>
                                <div className="radio_wrapper text-center">
                                    <input type="file" onChange={this.handleImageChange} id="placeHolderImage" name="placeHolderImage" />
                                    <span></span>
                                    <img width="400px" src={placeHolderImagePreview} />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="info" onClick={this.handleAddGuaranteedPlanFeature} disabled={validationError}> Submit </Button>
                    </Modal.Footer>
                </Modal>



                <Modal
                    show={editFeaturePopup}
                    onHide={this.closeModal}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Feature</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form>
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    onChange={this.handleChange}
                                    value={title}
                                />
                            </div>
                            <div className="form-group">
                                <label>Sub Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="subHeading"
                                    onChange={this.handleChange}
                                    value={subHeading}
                                />
                            </div>

                            <div className="form-group">
                                <label>Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    onChange={this.handleChange}
                                    value={description}
                                />
                            </div>

                            <div className="form-group">
                                <label>Banner Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bannerTitle"
                                    value={bannerTitle}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Banner Sub Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bannerSubHeading"
                                    value={bannerSubHeading}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Component Type</label>
                                <Select
                                    className='cm_select_filter'
                                    isSearchable
                                    placeholder='Component Type'
                                    options={PlanFeatureComponents}
                                    onChange={(val) => this.handleSelectComponent(val)}
                                    value={PlanFeatureComponents.find(option => option.value === componentType)}
                                    isDisabled
                                />
                            </div>

                            <div className="form-group">
                                <label>Sub Features</label>
                                {subFeatures.map((spec, i) => (
                                    <div className='row' key={i}>
                                        <h4 className="cm_pos_rel col-12">Sub Feature {i + 1}
                                            <span className="btn btn-danger" onClick={() => this.removeSubFeature(i)}><i className="fa fa-trash" /></span>
                                        </h4>
                                        <div className="form-group col">
                                            <label>Sub Title</label>
                                            <input type="text" className="form-control"
                                                name='subTitle' onChange={(val) => this.handleSubFeaturesChange(val, i)} value={spec.subTitle}
                                            />
                                        </div>

                                        <div className="form-group col">
                                            <label>Sub Feature</label>
                                            <input type="text" className="form-control"
                                                name='subFeature' onChange={(val) => this.handleSubFeaturesChange(val, i)} value={spec.subFeature}
                                            />
                                        </div>

                                        <div className="form-group mt-2 mb-2 col-12">
                                            <label className="d-block">Sub Feature Image</label>
                                            {spec.featureImage ? <Button variant="outline-info" disabled={this.state[spec._id]} onClick={() => this.handleUploadSubFeatureImage(spec, i)}>
                                                {this.state[spec._id] ? "Uploading......" : "Upload Image"}
                                            </Button> : null}

                                            <div className="radio_wrapper text-center">
                                                <input
                                                    type="file"
                                                    onChange={(val) => this.handleSubFeaturesImages(val, i)}
                                                />
                                                <span></span>
                                                <img width="400px" src={spec.featureImagePreview} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <i className="fa fa-plus-square cm_pointer" onClick={this.handleAddSubFeature}>Add Feature</i>


                            <div className="form-group">
                                <label className="d-block">Feature Images</label>
                                <div className="radio_wrapper text-center">
                                    <input
                                        type="file"
                                        multiple
                                        onChange={this.handleMultipleImgPreivew}
                                    />
                                </div>
                            </div>
                            {featureImagesPreview.map((imageURI, i) => (<img key={i} className="tracks_img" src={imageURI} alt="Photo uploaded" />))}


                            <div className="form-group mt-5 mb-5">
                                <label className="d-block">Place Holder Image</label>
                                <div className="radio_wrapper text-center">
                                    <input type="file" onChange={this.handleImageChange} id="placeHolderImage" name="placeHolderImage" />
                                    <span></span>
                                    <img width="400px" src={placeHolderImagePreview} />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="info" onClick={this.submitEditFeature} disabled={validationError}> Submit</Button>
                    </Modal.Footer>
                </Modal>




                <Modal
                    show={deleteFeaturePopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure, you want to delete this service?</Modal.Title>
                    </Modal.Header>

                    <h6 className="text-center error_message">{validationError}</h6>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.deleteFeature}>Delete</Button>
                        <Button variant="primary" onClick={this.closeModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default GuaranteedPlanFeatures;