import axios from 'axios'
import API from '../Api'

export function getAllCollections(params) {
    return axios.get(API.GET_ALL_COLLECTIONS, params)
}

export function uploadCollectionImage(params) {
    return axios.post(API.UPLOAD_COLLECTION_IMAGE, params)
}

export function addCollection(params) {
    return axios.post(API.ADD_COLLECTION, params)
}

export function updateCollection(params) {
    return axios.post(API.UPDATE_COLLECTION, params)
}

export function deleteCollection(params) {
    return axios.post(API.DELETE_COLLECTION, params)
}
