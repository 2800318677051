import axios from 'axios';
import API from '../Api';

export function getProductCouponList (params) {
    console.log("params", params);
    return axios.get(API.PRODUCT_COUPONS, params);
}

export function addProductCoupon (params) {
    return axios.post(API.PRODUCT_COUPONS, params);
}

export function editProductCoupon (params) {
    return axios.put(`${API.PRODUCT_COUPONS}/${params._id}`, params);
}

export function deleteProductCoupon (params) {
    return axios.delete(`${API.PRODUCT_COUPONS}/${params._id}`);
}

export function bulkUploadProductCoupon (params) {
    return axios.post(API.BULK_UPLOAD_PRODUCT_COUPONS, params);
}

export function downloadProductCouponList(params) {
    // return axios({
    //     method: 'post',
    //     url: API.DOWNLOAD_STREAMLIST,
    //     responseType: 'blob',
    //     data: params
    // })
    return axios.get(API.DOWNLOAD_PRODUCT_COUPONS);
}