import React, { useEffect, useState } from 'react';
import { LoaderSvg } from '../Reusable';


let desingCLass = 'shadow-sm p-3 mb-4 rounded';

const billingAddress = (props) => {
    const { orderDetails } = props;
    const [errorMsg, setErrorMsg] = useState('');
    const [loader, setLoader] = useState(false);
    const [aboutOrderDetail, setAboutOrderDetail] = useState({});

    const setOrePropsValue = () => {
        setLoader(true);
        setAboutOrderDetail(orderDetails);
        setLoader(false);
    }

    useEffect(() => {
        setOrePropsValue();
    }, [props.orderDetails]);

    return (
        <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
        {/* <TabTitle title="User Profile" /> */}
        <div className="row text-center">
        
     
            <div className="col-xl-12 col-lg-12 mb-5">
                <div className="row">
                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Customer Name:</b>
                                    {aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.fullName ? aboutOrderDetail.billingAddress.fullName : ''}</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Mobile Number:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.mobileNo ? aboutOrderDetail.billingAddress.mobileNo : ''}</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Email:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.email ? aboutOrderDetail.billingAddress.email : ''}</h5>
                    </div>
    
                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Pincode:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.pincode ? aboutOrderDetail.billingAddress.pincode : ''}</h5>
                    </div>
    
                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Flat, House No., Building, Company, Apartment:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.address_1 ? aboutOrderDetail.billingAddress.address_1 : ''}</h5>
                    </div>
    
                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Area, Colony, Street, Sector, Village:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.address_2 ? aboutOrderDetail.billingAddress.address_2 : ''}</h5>
                    </div>

                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Landmark:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.address_3 ? aboutOrderDetail.billingAddress.address_3 : ''}</h5>
                    </div>

                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>City:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.city ? aboutOrderDetail.billingAddress.city : ''}</h5>
                    </div>


                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>State:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.state ? aboutOrderDetail.billingAddress.state : ''}</h5>
                    </div>

                    <div className="col-lg-4 col-md-6 ">
                    <h5 className={desingCLass}><b>Country:</b>{aboutOrderDetail.billingAddress && aboutOrderDetail.billingAddress.country ? aboutOrderDetail.billingAddress.country : ''}</h5>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    );
};

export default billingAddress;