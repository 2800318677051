import axios from 'axios';
import API from '../Api';

export function addStream(params) {
    return axios.post(API.ADD_STREAM, params)
}

export function editStream(params) {
    return axios.post(API.UPDATE_BROAD_CART_API, params)
}

export function changeStatus(params) {
    return axios.post(API.CHANGE_STATUS_STREAM, params)
}

export function deleteStream(params) {
    return axios.post(API.DELETE_STREAM, params)
}

export function getStreamList(params) {
    return axios.get(API.GET_STREAM_LIST, params)
}

export function getStreamDetails(params) {
    return axios.get(API.GET_STREAM_DETAILS, params)
}

export function getReviewsList(params) {
    return axios.get(API.GET_LIVE_TRAINER_REVIEW_INFO, params)
}

export function downloadUserList(params) {
    return axios.get(API.DOWNLOAD_USER_LIST, params)
}

export function downloadStreamList(params) {
    return axios({
        method: 'post',
        url: API.DOWNLOAD_STREAMLIST,
        responseType: 'blob',
        data: params
    })
}

export function downloadFilteredUserList(params) {
    return axios.get(API.DOWNLOAD_FILTERED_USER_LIST, params)
}

export function addOrUpdateNotes(params) {
    return axios.post(API.ADD_UPDATE_NOTES, params)
}



