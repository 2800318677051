import axios from 'axios';
import API from '../Api';

export function getEngineerList(params) {
    return axios.get(API.GET_ALL_ASSOCIATE_ENGINEER, params)
}

export function deleteEngineer(params) {
    return axios.post(API.REMOVE_ENGINEER, params)
}

export function addEngineer(params){
    return axios.post(API.ADD_ENGINEER,params)
}

export function editEngineer(params){
    return axios.post(API.EDIT_ENGINEER,params)
}


