import axios from "axios";
import API from "../Api";

export function addCareer(params) {
  return axios.post(API.ADD_CAREER, params);
}

export function updateCareer(params) {
  return axios.post(API.UPDATE_CAREER, params);
}

export function getCareerListing(params) {
  return axios.get(API.CAREER_LISTING, params);
}

export function getCareerConfig(params) {
  return axios.get(API.CAREER_CONFIG);
}

export function changeCareerStatus(params) {
  return axios.post(API.UPDATE_CAREER_STATUS , params);
}


