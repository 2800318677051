import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { LoaderSvg } from '../Reusable';
import { getDietPlanApprovePending, approveDietPlan, rejectDietPlan } from '../../config/services/dietPlanService'

import DietHistoryBody from './DietHistoryBody';
import TabTitle from './TabTitle'
import { Button, Modal, FormControl, Form, FormGroup, FormLabel, Spinner, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PopUpModal from '../../components/common/PopUpModal';
import Popup from "reactjs-popup"
import moment from 'moment';
import profilePicPlaceholder from '../../assets/images/profilePicPlaceholder.png'


class DietPlanApprovePending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approvePendingPlan: '',
            showHideApprovePlan: false,
            showHideRejectPlan: false,
            showAllRejectMsgPopup: false,
            notes: ''
        }
    }

    componentDidMount() {
        const { userId, profile, getUserProfile, getAdminProfile } = this.props
        // console.log('rkk userid:::', userId)
        // console.log('rkk profile:::', profile)
        // console.log('rkk getUserProfile:::', getUserProfile)
        // console.log('rkk getAdminProfile:::', getAdminProfile)

        this.setState({
            dietOfUserId: userId,
            dieticianDetails: profile
        }, ()=>{
                this.getApprovePending()
            }
        )
    }

    getApprovePending(){
        const {dietOfUserId}  = this.state 
        const params = {
            params: {
                dietOfUserId
            }
        }
        getDietPlanApprovePending(params).then((respose)=>{   
            if(respose && respose.data &&  respose.data.responseData ) {
                // console.log('rkk::', respose.data.responseData.result)
                this.setState({
                    approvePendingPlan:respose.data.responseData.result,
                    loader: false
                })
            }
        })
    }

    closeModal = () => {  

        this.setState({
            showHideRejectPlan: false,            
            showAllRejectMsgPopup: false,
            showHideApprovePlan: false,
            notes: ''
        })
    }  

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: false
        })
    }

    openApprovePlanPopup = () => {
        this.setState({
            showHideApprovePlan:true
        })
    }
    openRejectPlanPopup = () => {
        this.setState({
            showHideRejectPlan:true
        })
    }

    showAllRejectMsgPopup = () => {
        const { userId, profile, getUserProfile, getAdminProfile } = this.props
        this.setState({
            showAllRejectMsgPopup:true
        })
    }    

    approvePlanAction = (e) => {
        e.preventDefault();
        this.setState({
            approveloader: true
        })
        const {dietOfUserId, notes}  = this.state       
        let params = {};
        params.dietOfUserId = dietOfUserId;
        params.notes = notes;
        approveDietPlan(params)
            .then((response)=>{   
                if(response && response.data &&  response.data.responseData ) {
                    this.setState({
                        approveloader: false,
                        showHideApprovePlan: false
                    },function() {
                        this.getApprovePending()
                    })
                    toast.success(response.data.responseData.message)
                } else if (response.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.data.error.errorMessage)
                }
             })          
    }  
    
    rejectPlanAction = (e) => {
        e.preventDefault();
        this.setState({
            rejectloader: true
        })
        const {dietOfUserId, notes}  = this.state         
        let params = {};
        params.dietOfUserId = dietOfUserId;
        params.notes = notes;
        rejectDietPlan(params)
        .then((response)=>{   
            if(response && response.data &&  response.data.responseData ) {
                this.setState({
                    rejectloader: false,
                    showHideRejectPlan: false
                },function() {
                    this.getApprovePending()
                })
                toast.success(response.data.responseData.message)
            } else if (response.data.statusCode == 0) {
                this.setState({ loader: false })
                toast.error(response.data.error.errorMessage)
            }
        })
    }      

    render() {
        const { approvePendingPlan,loader, showAllRejectMsgPopup} = this.state
        return (
            <div>
            <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
                 <TabTitle title={approvePendingPlan && approvePendingPlan.status >= 0 && approvePendingPlan.status <= 2?
                 approvePendingPlan.status == 0?"Dietician sends the plan for review":approvePendingPlan.status == 1?"This Plan Is Rejected For Now":approvePendingPlan.status == 2?"No approvals pending":null:'No approvals pending'} />             

            


                    {approvePendingPlan && approvePendingPlan.status >= 0 && approvePendingPlan.status < 2?
                    <>     
 
                        <div className="cm_left_tabs" >
                            <div className="flex-column nav nav-pills">
                                <div className="nav-item">
                                    {approvePendingPlan.perDayCalories} Cals, {dateFormat(approvePendingPlan.created, "d mmm, yy")}
                                </div>
                            </div>

                            {approvePendingPlan.status == 0?
                                <>
                                <Button className="btn btn-primary mt-4" onClick={this.openApprovePlanPopup}> Approve </Button>
                                &nbsp;
                                <Button className="btn btn-primary mt-4" onClick={this.openRejectPlanPopup}> Reject </Button>
                                </>
                                :
                                null
                            }                            

                            {approvePendingPlan.notes && approvePendingPlan.notes.length> 0?
                                <Button className="btn btn-primary mt-4" onClick={this.showAllRejectMsgPopup}> Show All Remarks </Button>
                                :
                                null
                            }           

                            
                        </div>
                        <div className="cm_right_tabs">
                            {loader? <div className="loader_wrapper"><LoaderSvg /></div>: null}
                            <div className="tab-content">
                                {
                                    approvePendingPlan && <DietHistoryBody dietPlanDetails={approvePendingPlan} />
                                }
                            </div>
                        </div>            
                    </>
                    :null
                    }
            </div>

            {/* <PopUpModal isOpen={this.state["showHideApprovePlan"]} closeModal={() => this.setState({ showHideApprovePlan: false })} submit={this.approvePlanAction} message={`Are you sure to approve`} submitTitle="Approve" /> */}
            
            <Modal show={this.state["showHideApprovePlan"]}  closeOnDocumentClick onHide={this.closeModal} centered size="lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><h6>Are you sure to approve</h6> </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={this.approvePlanAction}>
                        <div className='row'>
                            <FormGroup className='col-12'>
                                <FormLabel>Reason</FormLabel>
                                <Form.Control 
                                    as="textarea" 
                                    placeholder="Reason for approve"
                                    value={this.state["notes"]}
                                    name='notes' 
                                    onChange={this.handleChange}
                                    required
                                    rows={3} 
                                />

                                <Form.Control.Feedback type="invalid">
                                    <FormLabel >Reason must be between 1 to 30 character</FormLabel>
                                </Form.Control.Feedback>
                            </FormGroup>                               
                        </div>

                                                    
                        <Row className="justify-content-center">
                            <Button variant="danger" onClick={this.closeModal}>Cancel</Button>
                            {/* <Button className="ml-2" variant="success" type="submit" disabled={this.state["approveloader"]} >
                                {this.state["approveloader"]?'Wait':'Approve'}
                            </Button> */}
                            <Button className="ml-2" variant="success" type="submit" >
                                Approve
                            </Button>
                        </Row>

                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={this.state["showHideRejectPlan"]}  closeOnDocumentClick onHide={this.closeModal} centered size="lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><h6>Are you sure you want to Reject the Request</h6> </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={this.rejectPlanAction}>
                        <div className='row'>
                            <FormGroup className='col-12'>
                                <FormLabel>Reason</FormLabel>
                                <Form.Control 
                                    as="textarea" 
                                    placeholder="Reject the Request"
                                    value={this.state["notes"]}
                                    name='notes' 
                                    onChange={this.handleChange}
                                    required
                                    rows={3} 
                                />

                                <Form.Control.Feedback type="invalid">
                                    <FormLabel >Reason must be between 1 to 30 character</FormLabel>
                                </Form.Control.Feedback>
                            </FormGroup>                               
                        </div>

                                                    
                        <Row className="justify-content-center">
                            <Button variant="danger" onClick={this.closeModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit" disabled={this.state["rejectloader"]} >
                                {this.state["rejectloader"]?'Wait':'Reject'}
                            </Button>
                        </Row>

                    </Form>
                </Modal.Body>
            </Modal>

            <Popup open={showAllRejectMsgPopup}  closeOnDocumentClick onClose={this.closeModal} >
                <div className="cm_modal">
                    <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                    <h3 className="text-center">All Messages</h3>
                    <div className="msg_box">
                    {approvePendingPlan && approvePendingPlan.notes && approvePendingPlan.notes.map(r =>
                        <div className="msg_card">
                            <h6>{r.noteByUserId.firstName} {r.noteByUserId.lastName}</h6>
                            <time>{moment(r.created).format('Do MMM YYYY H:mm')} </time>
                            <span className={`msg_status ${r.action == 0 ? 'review' : r.action == 1 ? 'rejected' : ''}`}>{r.action == 0 ? 'Review' : r.action == 1 ? 'Rejected' : null}</span>
                            <p>{r.message}</p>
                        </div>
                        // <div> 
                        //     <span>
                               
                        //         <span>{r.noteByUserId.firstName} {r.noteByUserId.lastName}</span>
                        //     </span>
                        //     <span> {moment(r.created).format('Do MMM YYYY H:m')} </span>
                        //     <span> {r.action == 0?'Review':r.action == 1?'Rejected':null} </span>
                        //     <span> {r.message} </span>
                        // </div>
                    )}
                </div>
                </div>
            </Popup>
            
            </div>
        )
    }
}


export default DietPlanApprovePending;
