import React from 'react';
import FoodPlaceholder from '../../assets/images/FoodPlaceholder.jpg';

const DietTrackCard = ({ foodItem, index }) => (
    <div className="diet-plan-card" key={index}>
        <div className="food-hero-image mb-0">
            <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" className="image" />
            <div className="food-name-calori">
                <h4 className="food-name">{foodItem.foodName}</h4>
                <h6 className="food-calori">{foodItem.calorieContent} cal / {`${foodItem.quantity}.0 ${foodItem.unit}`}</h6>
            </div>
        </div>
    </div>
)

export default DietTrackCard;