
export const convertFtToCm = (value) => {
    value = Number((value * 30.48).toFixed(1))
    return value
}

export const convertLbsToKg = (value) => {
    value = Number((value * 0.45359237).toFixed(1))
    return value
}


export const convertCmToFt = (value) => {
    value = Number((value / 30.48).toFixed(1))
    return value
}

export const convertKgToLbs = (value) => {
    value = Number((value * 2.205).toFixed(1))
    return value
}