import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import {ReactComponent as WarningIcon} from "./../../assets/images/warning.svg"

const DeleteConfirmation = ({deleteTitle,isDeleteState,submit,cancel}) =>{
    return (
        <>
          <Modal maskClosable={false} title={`${deleteTitle}`} visible={isDeleteState} onOk={()=>submit(true)} onCancel={()=>cancel(false)}>
                <div className="text-center">
                    <WarningIcon className="alert-icon" style={{width: "50px", height:"50px"}}/>
                    <p><strong>Are you sure you want to delete?</strong></p>
                </div>
          </Modal>
        </>
    )
}

export default DeleteConfirmation;