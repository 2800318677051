import _ from 'lodash';
import { LoaderSvg } from '../Reusable';
import { useHistory, useParams ,Link} from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import OrderInfo from './OrderInfo';
import OrderHistory from './OrderHistory';
import OrderFeedback from './OrderFeedback';
import OrderProducts from './OrderProducts';
import OrderAddress from './OrderAddress';


import { getOrderList } from '../../config/services/OrderManagementService';
import { toast } from 'react-toastify';


const OrderDetail = (props) => {

const { id } = useParams();
const [loader, setLoader] = useState(false);
const [selectedTab, setSelectedTab] = useState('');
const [order,setOrder]= useState({});

//Get Order Detail 
const getOrderInfo = () => {
  try {
    setLoader(true);
    let _params = {
      params: {search:id},
    }
  
    getOrderList(_params).then(res => {
      let response = res.data
      if (response.statusCode == 1) {
        let _orderResponse=response.responseData.result[0];
         if(_orderResponse.length>0){
          setOrder({..._orderResponse[0]});
         }
      
      
      } else if (response.statusCode == 0) {
        toast.error(response.error.errorMessage)
      }

      setLoader(false);
    });
  
  } catch (error) {
    setLoader(false);
    console.error(error);
  }
}

useEffect(() => {
  getOrderInfo();
}, []);


   return (   <div className="body-container-wrapper">
   <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
       <div className="d-sm-flex justify-content-between border-bottom custom-list mb-3">
           <ol className="breadcrumb breadcrumb-custom">
               <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
               <li className="breadcrumb-item"><Link to="/orders">Order</Link></li>
               <li className="breadcrumb-item">Detail</li>
           </ol>
       </div>

       {loader
           ? <div className="loader_wrapper"><LoaderSvg /></div>
           : null}

       <Tabs defaultActiveKey={selectedTab ? selectedTab : "order-info"} id="uncontrolled-tab-example" >
            <Tab eventKey="order-info" title="Detail" activeKey={selectedTab}>
                  <OrderInfo orderDetails={order}/>
            </Tab>

            <Tab eventKey="order-address" title="Address" activeKey={selectedTab}>
                  <OrderAddress orderDetails={order}/>
            </Tab>
            <Tab eventKey="order-products" title="Products" activeKey={selectedTab}>
                  <OrderProducts orderDetails={order}/>
            </Tab>

            <Tab eventKey="order-history" title="History" activeKey={selectedTab}>
                  <OrderHistory orderDetails={order}/>
            </Tab>
            <Tab eventKey="order-feedback" title="Feedback" activeKey={selectedTab}>
                  <OrderFeedback orderDetails={order}/>
            </Tab>



            
       </Tabs> 

   </div>
</div>
);
  
};

export default OrderDetail;