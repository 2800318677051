import React ,{ createContext} from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import 'antd/dist/antd.css';

import App from './App';
import * as firebase from "firebase/app";
import 'firebase/analytics';
import "firebase/performance";
import setting from './config/settings'
import reportWebVitals from './reportWebVitals';

const FirebaseContext = createContext(null)
export { FirebaseContext }


// Initialize Firebase
firebase.initializeApp(setting.firebaseConfig);
const perf = firebase.performance();

ReactDOM.render(<FirebaseContext.Provider value={firebase}>
    <App />
    </FirebaseContext.Provider>, document.getElementById('root'));

function sendToAnalytics(metric) {
}

reportWebVitals(sendToAnalytics);

