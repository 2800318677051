var PlanFeatureComponents = [
    { label: "Equipment",          value: 0},
    { label: "Food",               value: 1},
    { label: "Exercises",          value: 2},
    { label: "Medical",            value: 3},
    { label: "Band",               value: 4},
    { label: "FitplusActive",      value: 5},
    { label: "Integrationof Alexa",value: 6},
    { label: "Weighing Scale",     value: 7},
    { label: "Health Coins",       value: 8},
  ]

module.exports = {
    PlanFeatureComponents
}