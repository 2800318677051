import React, { useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { LoaderSvg } from '../Reusable';
import { editPincodes, getAllStates, getAllCitys } from '../../config/services/servicePincodes';
import { toast } from 'react-toastify';
import Select from 'react-select';

const EditPincode = ({ hide, editPincode, getPincode, selectedPincode }) => {

  const initialState = {
    "state": selectedPincode.state,
    "pincode": selectedPincode.pincode,
    "city": selectedPincode.city,
    "tat": selectedPincode.tat,
    "upcountry": selectedPincode.upCountryOrLocal === 0 ? true : false,
    "statePageNo": 0,
    "stateCount": 1000,
    "cityPageNo": 0,
    "cityCount": 1000,
    "allstates": [],
    "allcities": [],
    "isNewState": false,
    "isNewCity": false   
  }

  const getStateLists = () => {    
    const { statePageNo, stateCount, allstates } = state;
    let params = { search: '', sortField: "_id", sortType: 1, pageNo: statePageNo, count: stateCount }
    getAllStates({ params })
        .then(res => {
            let response = res.data;            
            if (response.statusCode == 1) {
                getCityLists(selectedPincode.state); 
                let data = res.data.responseData
                let totalCount = data.totalCount;
                let pgno = statePageNo + 1;

                if (data.result && data.result.length > 0) {
                    data.result.forEach(key => {
                        allstates.push({
                            label: key._id,
                            value: key._id,
                        })
                    })
                }

                // setAllstates(allstates);
                // setState({
                //   ...state,
                //   statePageNo: pgno
                // })                        
                state.statePageNo = pgno;
                state.states = allstates;
                if (totalCount > (pgno * stateCount)) {
                    getStateLists();
                }
            } else if (response.statusCode == 0) {
                toast.error(response.error.errorMessage)
            }
        })
  }

  const getCityLists = (val) => {   
    const { cityPageNo, cityCount } = state;
    let params = { sortField: "_id", sortType: 1, pageNo: cityPageNo, count: cityCount, states: val }
    getAllCitys({ params })
        .then(res => {
            let response = res.data;  
            if (response.statusCode == 1) {
                let data = res.data.responseData
                let citys = []
                if (data.result && data.result.length > 0) {
                    data.result.forEach(state => {
                        citys.push({
                            label: state._id,
                            value: state._id
                        })
                    })
                }
                setState({
                  ...state,
                  allcities: citys,
                  state: val
                })  
            } else if (response.statusCode == 0) {
                toast.error(response.error.errorMessage)
            }
        })
  }

  const [state, setState] = useState({ ...initialState });
  const [newError, setNewError] = useState({});
  const [upcountry, setUpcountry] = useState(selectedPincode.upCountryOrLocal === 0 ? true : false);
  const [allstates, setAllstates] = useState(() => getStateLists())

  const handleSelectChange = (field, value) => {
    switch (field) {
      case 'state': 
        getCityLists(value); 
        newError.state = '';
        newError.city = '';
        setState({ ...state, [field]: value, "city": "" });
        break;    
      case 'city': 
        newError.city = '';
        setState({ ...state, [field]: value });
        break;        
      default:
        break;
    }
  }
  
  const handleIsNewState = (value) => {
    if(value == 'false') {
      value = true;
    } else {
       value = false;
    }
    newError.state = '';
    newError.city = '';
    setState({ ...state, "isNewState": value, "state": "", "city": "", allcities: [], isNewCity: true });    
  }

  const handleIsNewCity = (value) => {
    if(value == 'false') {
      value = true;
    } else {
       value = false;
    }
    newError.city = '';
    setState({ ...state, "isNewCity": value, "city": "" });    
  }

  const handleChange = (value, method, field, length) => {
    value = value.trim();    
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    switch (field) {
      case 'pincode': newError.pincode = value.length !== 6 ? 'pincode must be 6 characters long!' : '';
        break;
      case 'city': newError.city = value.length < 3 ? 'Enter valid city of the associated pincode' : '';
        value = value.toUpperCase() ;
        break;
      case 'state': newError.state = value.length < 3 ? 'Enter valid state of the associated city' : '';
        value = value.toUpperCase() ;
        break;
      case 'tat': newError.tat = value > 0 ? '' : 'Please enter a valid tat!'
        break;
      default:
        break;
    }
    setState({ ...state, [field]: value });
  }

  const handleSubmit = () => {

    const matcherForFeilds = {
      "pincode": "Pincode",
      "city": "City",
      "state": "State",
      "tat": "TAT",
    }

    // this part of the validation is for the plain text and number feilds 
    let feildsToValidate = ["pincode", "city", "state", "tat"]
    feildsToValidate.forEach(feild => {
      if (state[feild] === '' || state[feild] === null || state[feild] === undefined) {
        newError[feild] = `${matcherForFeilds[feild]} cannot be left blank`
      }
    })

    for (var key in newError) {
      if (newError[key] === "") {
        delete newError[key]
      }
    }

    if (Object.keys(newError).length < 1) {
      const params = {
        pincode: state.pincode,
        state: state.state,
        city: state.city,
        tat: state.tat,
        upCountryOrLocal: state.upcountry ? 1 : 0,
        pincodeId: selectedPincode._id
      }


      editPincodes(params)
        .then(response => {
          if (response && response.data.statusCode === 1) {
            hide();
            getPincode();
            toast.success(`${state.pincode} updated!`)
          }
        })
    }

  }

  return (
    <Popup
      open={editPincode}
      closeOnDocumentClick={false}
      onClose={hide}
    >
      <div className="cm_modal pl-5 pr-5">
        <span className="cm_modal_close" onClick={() => hide()}>
          &times;
            </span>
        <h3 className="text-center mb-4 mt-2">Edit Pincode</h3>
        <div className="form-group">
          <label>Pincode</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, setState, "pincode", 6)}
            name="Pincode"
            disabled
            value={state.pincode}
            placeholder="Pincode"
          />
          {newError && newError["pincode"] && <label style={{ color: "red" }}>{newError["pincode"]}</label>}
        </div>

        <div className="form-group">
            <label>
              State 
                  <span className="pull-right" style={{ "margin-left": "30px" }} > 
                  <FormCheck className="radio-custom-filter" name="isNewState" checked={(state.isNewState ? true : false)} inline='true' type="checkbox"  value={state.isNewState} label="Add New State" onChange={(e) => handleIsNewState(e.target.value)} />
                </span>
            </label>

            {state.isNewState?
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => handleChange(e.target.value, setState, "state", 25)}
                  name="state"
                  value={state.state}
                  placeholder="State"
                />                    
              :
              <Select
                onChange={(e) => handleSelectChange("state", e.value)} 
                placeholder="Select State"
                options={state.allstates}
                value={state.state ? state.allstates.find(element => element.value === state.state) : null}
            />
            }               
            {newError && newError["state"] && <label style={{ color: "red" }}>{newError["state"]}</label>}
        </div>
        
        <div className="form-group">
          <label>
              City
              <span className="pull-right" style={{ "margin-left": "30px" }} > 
                <FormCheck className="radio-custom-filter" name="isNewCity" checked={(state.isNewCity ? true : false)} inline='true' type="checkbox"  value={state.isNewCity} label="Add New City" onChange={(e) => handleIsNewCity(e.target.value)} />
              </span>
          </label>
          {state.isNewCity?
              <input
              type="text"
              className="form-control"
              onChange={(e) => handleChange(e.target.value, setState, "city", 25)}
              name="city"
              value={state.city}
              placeholder="City"
            />                
            :
            <Select
              onChange={(e) => handleSelectChange("city", e.value)}
              placeholder="Select City"
              options={state.allcities}
              value={state.city ? state.allcities.find(element => element.value === state.city) : null}
            />
          }             
          {newError && newError["city"] && <label style={{ color: "red" }}>{newError["city"]}</label>}
        </div>

        {/* <div className="form-group">
          <label>City</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, setState, "city", 25)}
            name="City"
            value={state.city}
            placeholder="City"
          />
          {newError && newError["city"] && <label style={{ color: "red" }}>{newError["city"]}</label>}
        </div> */}

        {/* <div className="form-group">
          <label>State</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, setState, "state", 25)}
            name="State"
            value={state.state}
            placeholder="State"
          />
          {newError && newError["state"] && <label style={{ color: "red" }}>{newError["state"]}</label>}
        </div> */}


        <div className="form-group">
          <label>TAT</label>
          <input
            type="number"
            className="form-control"
            onChange={(e) => handleChange(e.target.value, setState, "tat", 3)}
            name="TAT"
            value={state.tat}
            placeholder="TAT"
          />
          {newError && newError["tat"] && <label style={{ color: "red" }}>{newError["tat"]}</label>}
        </div>

        <div className='form-group'>
          <FormCheck
            type="radio"
            label="Up-country"
            name="up-country"
            checked={upcountry}
            onChange={() => setUpcountry(!upcountry)}
          />
          <FormCheck
            type="radio"
            label="Local"
            name="up-country"
            checked={!upcountry}
            onChange={() => setUpcountry(!upcountry)}
          />
        </div>
        <button onClick={handleSubmit} className="btn btn-primary btn-block mt-4">Save</button>
        {/* {
          error ? <div className="loader_wrapper"><LoaderSvg /></div> : null
        } */}
      </div>
    </Popup>
  )
}

export default EditPincode;