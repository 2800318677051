import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { getServices } from "../../config/services/serviceCategoriesService";
import { addServiceBanner, getServiceBanners, updateServiceBanner, deleteServiceBanner } from "../../config/services/serviceBannersService";
import { LoaderSvg } from '../Reusable';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import { Button, Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import SearchSpinner from '../common/SearchSpinner';

let image;
let newBannerImage;

class ServiceBanners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            addBannerPopup: false,
            deleteBannerPopup: false,
            heading: '',
            description: '',
            serviceId: '',
            bannerImagePreview: '',
            newBannerImagePreview: '',
            editBannerPopup: false,
            tempBannerId: "",
            validationError: "",
            search: '',
            count: 20,
            pageNo: 1,
            totalServiceBanners: 50,
            sortType: '',
            sortField: '',
            serviceBanners: [],
            services: [],
            selectedService: {},
            parentCategory: '',
            searchLoader: false
        }
    }


    openAddBannerPopup = () => this.setState({ addBannerPopup: !this.state.addBannerPopup });

    openDeleteBannerPopup = (banner) => {
        this.setState({
            deleteBannerPopup: !this.state.deleteBannerPopup,
            tempBannerId: banner._id,
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ pageNo: pageNumber }, () => { this.getServiceBannersList(); });
    }

    deleteBanner = (e) => {
        e.preventDefault();
        this.setState({ loader: true })
        const { tempBannerId } = this.state;
        deleteServiceBanner({ serviceBannerId: tempBannerId })
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getServiceBannersList()
                    this.closeModal()
                    this.setState({ loader: false })
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }

    openEditBannerPopup = (banner) => {
        let selectedService = {}
        if (banner.serviceId) {
            selectedService = { label: banner.serviceId.serviceName, value: banner.serviceId._id }
        }

        this.setState({
            editBannerPopup: !this.state.editBannerPopup,
            tempBannerId: banner._id,
            heading: banner.heading,
            description: banner.description,
            bannerImagePreview: banner.bannerImage,
            newBannerImagePreview: banner.newBannerImage? banner.newBannerImage : '',
            selectedService,
            parentCategory: banner.parentCategory
        })
    };

    submitEditBanner = (e) => {
        e.preventDefault();
        let { heading, description, serviceId, tempBannerId, parentCategory } = this.state;

        let params = new FormData();
        params.append('serviceBannerId', tempBannerId);
        params.append('heading', heading);
        params.append('description', description);
        params.append('serviceId', serviceId);
        params.append('bannerImage', image);
        params.append('newBannerImage', newBannerImage);
        params.append('parentCategory', parentCategory);

        if (this.isValidated()) {
            this.setState({ validationError: "" })
            this.editBannerApi(params);
        }
    }

    editBannerApi = (params) => {
        this.setState({ loader: true })
        updateServiceBanner(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getServiceBannersList()
                    this.closeModal()
                    this.setState({ loader: false })
                    toast.success(res.data.responseData.message)
                }
                else if (res.data.statusCode == 0) {
                    this.setState({ loader: false, validationError: res.data.error.errorMessage })
                }
            })
    }

    closeModal = () => {
        image = '';
        newBannerImage = ''
        this.setState({
            addBannerPopup: false, deleteBannerPopup: false, editBannerPopup: false, heading: "",
            openEditBannerPopup: false, description: "", bannerImagePreview: "",newBannerImagePreview:"", tempBannerId: "",
            validationError: '', serviceId: '', selectedService: {}, parentCategory: ''
        })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleImgPreivew = e => {
        let { name, files } = e.target
        let reader = new FileReader();
        let file = e.target.files[0];


        if (name == 'bannerImage'){
            image = e.target.files[0];
            this.setState({ bannerImagePreview: e.target.files[0] })
            reader.addEventListener("load", () => {
                this.setState({ bannerImagePreview: reader.result })
            }, false);
        }

        if (name == 'newBannerImage'){
            newBannerImage = e.target.files[0];
            this.setState({ newBannerImagePreview: e.target.files[0] })
            reader.addEventListener("load", () => {
                this.setState({ newBannerImagePreview: reader.result })
            }, false);
        }

        if (file) { reader.readAsDataURL(file) }
    }


    handleAddBanner = e => {
        e.preventDefault();
        let { heading, description, serviceId, parentCategory } = this.state;
        let params = new FormData();
        params.append('heading', heading);
        params.append('description', description);
        params.append('serviceId', serviceId);
        params.append('bannerImage', image);
        params.append('newBannerImage', newBannerImage);
        params.append('parentCategory', parentCategory);


        if (this.isValidated()) {
            this.setState({ validationError: "" })
            this.addAddBannerApi(params);
        }
    }

    isValidated = () => {
        let validated = true
        let { heading, description, serviceId, bannerImagePreview, newBannerImagePreview, selectedService } = this.state;

        if (!heading) {
            this.setState({ validationError: "Banner Heading can't be empty" });
            validated = false
        }
        else if (!image && !bannerImagePreview) {
            this.setState({ validationError: "Please select an image for the service banner" });
            validated = false
        }
        else if (!newBannerImage && !newBannerImagePreview) {
            this.setState({ validationError: "Please select an new image for the service banner" });
            validated = false
        }
        else if (!description) {
            this.setState({ validationError: "Banner description can't be empty" });
            validated = false
        }
        else if (!serviceId && !selectedService) {
            this.setState({ validationError: "Please select a service for the service banner" });
            validated = false
        }

        return validated
    }

    addAddBannerApi = (params) => {
        this.setState({ loader: true })
        addServiceBanner(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false }, () => this.getServiceBannersList())
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        validationError: res.data.error.errorMessage,
                        loader: false
                    })
                }
            })
    }



    getServiceBannersList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search
                , count
                , pageNo: pageNo - 1
                , sortType
                , sortField
            }
        }
        getServiceBanners(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let { result, totalCount } = res.data.responseData;
                    this.setState({
                        serviceBanners: result,
                        totalServiceBanners: totalCount,
                        loader: false,
                        searchLoader: false
                    })
                } else {
                    this.setState({ loader: false, searchLoader: false })
                }
            })
    }

    handleSearch = e => this.setState({ search: e.target.value, searchLoader: true }, () => this.getServiceBannersList());

    getServices = (val) => {
        let paramsobj = { params: { count: 0, pageNo: 0, search: val } }
        return getServices(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let { result } = res.data.responseData
                    let services = []
                    result.map(service => {
                        services.push({ label: service.serviceName, value: service._id })
                    })
                    this.setState({ services })
                    return services
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    componentDidMount() {
        this.getServiceBannersList();
        this.getServices();
    }

    loadServices = (val) => {
        return this.getServices(val)
    }

    serviceSelect = (service) => {
        this.setState({ serviceId: service.value, selectedService: service })
    }

    parseParentCategory = (category) => {
        switch (category) {
            case 1:
                return 'Doctor'
            case 2:
                return 'Dietician'
            case 3:
                return 'Home workout'
            default:
                return '-'
        }
    }

    render() {
        const { serviceBanners, loader, addBannerPopup, heading, bannerImagePreview,newBannerImagePreview, deleteBannerPopup, editBannerPopup, validationError, pageNo, count, totalServiceBanners, services, description, selectedService, parentCategory, searchLoader } = this.state;

        return (
            <div className="body-container-wrapper">
                <div className="body-container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item">Service Banners</li>
                    </ol>
                    {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                    <div className="users_header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="cm_page_heading margin-bottom-10px mt-1">Service Banners</h4>
                                </div>
                                <div className="col-md-3 text-right cm_search position-relative">
                                    {searchLoader ?
                                        <SearchSpinner type="text-info" />
                                        : null}
                                    <input
                                        type="text"
                                        className="form-control m-b-md-15"
                                        placeholder="Search By Banner Heading"
                                        onChange={this.handleSearch}
                                    />
                                    <i className="fa fa-search" />
                                </div>
                                <div className="col-md-3 text-right">
                                    <button className="btn btn-primary btn-block" onClick={this.openAddBannerPopup}>
                                        Add New</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-3">
                        {serviceBanners.map((banner, i) => (
                            <div className="col-sm-3" key={banner._id}>
                                <div className="card card_content">
                                    {
                                        banner.bannerImage ? <img className="card-img-top" src={banner.bannerImage} alt="Banner image" /> : <div className="card-img-top">No Image</div>
                                    }
                                    {banner.serviceId && <span class="card_style_cat">{banner.serviceId.serviceName}</span>}

                                    <div className="card-body">
                                        <h6 className="card-title">{banner.heading}</h6>
                                        <p className="mb-0">Created: {dateFormat(banner.created, "d mmmm yyyy")}</p>
                                        <p>Parent Category: {banner.parentCategory ? this.parseParentCategory(banner.parentCategory) : ''}</p>

                                        <div className="category_description">
                                            <h3>Description</h3>
                                            <p>{banner.description ? banner.description : 'No Description'}</p>
                                        </div>
                                        <div className="d-flex card--dflex">
                                            <button className="btn btn-primary btn-xs" onClick={() => this.openEditBannerPopup(banner)}><i className="fa fa-pencil-square-o"></i></button>
                                            <button className="btn btn-danger btn-xs ml-2" onClick={() => this.openDeleteBannerPopup(banner)}><i className="fa fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>

                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalServiceBanners}
                        pageRangeDisplayed={6}
                        onChange={this.handlePageChange}
                    />

                    {!serviceBanners.length ? <h3 className="empty_error">Sorry, we couldn't find any content!</h3> : null}
                </div>


                <Modal
                    show={addBannerPopup}
                    onHide={this.closeModal}
                    backdrop="static"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Service Banner</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h6 className="text-center error_message">{validationError}</h6>
                        <form>
                            <div className="form-group">
                                <label>Banner Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="heading"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Banner Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Service</label>
                                <AsyncSelect
                                    isDisabled={parentCategory ? true : false}
                                    cacheOptions
                                    loadOptions={this.loadServices}
                                    defaultOptions={services}
                                    onChange={(val) => this.serviceSelect(val)}
                                />
                            </div>

                            <div className="form-group">
                                <label>Parent Category</label>
                                <select className="form-control" name="parentCategory" id="parentCategory" onChange={this.handleChange} disabled={selectedService.label ? true : false}>
                                    <option selected disabled>Select....</option>
                                    <option value={1}>Doctor</option>
                                    <option value={2}>Dietician</option>
                                    <option value={3}>Home workout</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Service Banner Image</label>
                                <input
                                    type="file"
                                    name="bannerImage"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {bannerImagePreview && <img className="exercise_category_img" src={bannerImagePreview} />}

                            <div className="form-group">
                                <label>Service New Banner Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="newBannerImage"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {newBannerImagePreview && <img className="exercise_category_img" src={newBannerImagePreview} />}
                        </form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="info" onClick={this.handleAddBanner}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Modal
                    show={editBannerPopup}
                    onHide={this.closeModal}
                    backdrop="static"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Banner </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-custom-bar-margin">
                        <h6 className="text-center error_message">{validationError}</h6>
                        <form>
                            <div className="form-group">
                                <label>Banner Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="heading"
                                    value={heading}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Banner Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    value={description}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Service</label>
                                <AsyncSelect
                                    isDisabled={parentCategory ? true : false}
                                    cacheOptions
                                    loadOptions={this.loadServices}
                                    defaultOptions={services}
                                    value={selectedService}
                                    onChange={(val) => this.serviceSelect(val)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Parent Category</label>
                                <select className="form-control" name="parentCategory" id="parentCategory" onChange={this.handleChange} value={parentCategory} disabled={selectedService.label ? true : false}>
                                    <option selected disabled>Select....</option>
                                    <option value={1}>Doctor</option>
                                    <option value={2}>Dietician</option>
                                    <option value={3}>Home workout</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Service Banner Image</label>
                                <input
                                    type="file"
                                    name="bannerImage"
                                    className="form-control"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {bannerImagePreview && <img className="exercise_category_img" src={bannerImagePreview} />}

                            <div className="form-group">
                                <label>Service New Banner Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="newBannerImage"
                                    onChange={this.handleImgPreivew}
                                />
                            </div>

                            {newBannerImagePreview && <img className="exercise_category_img" src={newBannerImagePreview} />}
                        </form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="info" onClick={this.submitEditBanner}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>




                <Modal
                    show={deleteBannerPopup}
                    onHide={this.closeModal}
                    backdrop="static"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure, you want to delete this service?</h6></Modal.Title>
                    </Modal.Header>

                    <h6 className="text-center error_message">{validationError}</h6>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.deleteBanner}>Delete</Button>
                        <Button variant="primary" onClick={this.closeModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ServiceBanners;