import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Popup from "reactjs-popup";
import { DatePicker } from 'antd';
import Select from "react-select";
import { toast } from 'react-toastify';
import { orderStatusOptions } from "../../utils/commonFunctions";
import { ORDER_RATING_OPTIONS } from "../../utils/constants";

import { updateOrderFeedback } from '../../config/services/OrderFeedback';



const EditFeedbackForm = (props) => {

  const { editMode, addEditPopup, updateFormValues, setAddEditPopup, getList } = props;
  const intialValues = {
    feedBackId:'',
    isApproved:false,
    isEditable:true,
    feedback:'',
    onTimeDelivery:'',
    packagingQuality:'',
    installationAndSetup:'',
    productQuality:'',
    
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  const [apiErrorMSG, setApiErrorMSG] = useState({});

  // SUBMIT  FORM  (Only Edit Allow)
  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMSG({});
    setIsSubmitting(true);
    if (validate(formValues)) {
      setIsSubmittingLoader(true);

      //For Update
      if (formValues._id) {

        let _feedbackFormParams={
                feedBackId: formValues.feedBackId,
                isApproved: formValues.isApproved,
                isEditable: formValues.isEditable,
                feedback: formValues.feedback,
                ratings: [
                  {
                    name:"onTimeDelivery", 
                    value:formValues.onTimeDelivery
                  },
                  {
                    name:"packagingQuality", 
                    value:formValues.packagingQuality
                  },
                  {
                    name:"installationAndSetup", 
                    value:formValues.installationAndSetup
                  },
                  {
                    name:"productQuality", 
                    value:formValues.productQuality
                  }
                ]
        };

        updateOrderFeedback(_feedbackFormParams)
          .then((response) => {
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {
              toast.success(response.data.responseData.message);
              getList();
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error });
            setIsSubmittingLoader(false);
          });
      } else {
         console.error('add is not allow , only do update')
      }
    }
  };
  // HANDLE CHANGE FORM
  const handleChange = (event) => {
    let formValuesSubmited = formValues;
    const { name, value } = event.target;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  //set only for select type 
  const handelSelectChange = (name, value) => {

    let formValuesSubmited = formValues;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  }

  // VALIDATION FORM
  const validate = (values) => {
    let errors = {};

    if (!values.feedBackId) {
      errors.feedBackId = 'Required';
    }

   

    if (!values.onTimeDelivery) {
      errors.onTimeDelivery = 'Required';
    }

    if (!values.packagingQuality) {
      errors.packagingQuality = 'Required';
    }

    if (!values.installationAndSetup) {
      errors.installationAndSetup = 'Required';
    }

    if (!values.productQuality) {
      errors.productQuality = 'Required';
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

    //set rating options
const ratingOptions = () => {
  let setOptions = [
    { label: 'Select Rating', value: '' },
    { label: 'One Star', value: 1 },
    { label: 'Two Star', value: 2 },
    { label: 'Three Star', value: 3 },
    { label: 'Four Star', value: 4 },
    { label: 'Five Star', value: 5 }
  ];
  return setOptions;
}

  //set isApprove/ isEditable options for input type select 
  const setTrueFalseOptions = () => {
    let setOptions = [
      { label: 'True', value: true },
      { label: 'False', value: false },
  
    ];
    return setOptions;
  }

    //filter rating by name 
const filterRatingByName= (array=[],name="")=>{

  let ratingArray= array.find(obj=> obj.name==name);
  if(ratingArray && ratingArray.value){
    return ratingArray.value;
  }else{
    return "";
  }
}

  useEffect(() => {
    if (editMode) {
      setFormValues((prevState) => ({
        ...prevState,
        _id:updateFormValues._id,
        feedBackId:updateFormValues._id ? updateFormValues._id : '',
       
        isApproved:updateFormValues.isApproved==true ? updateFormValues.isApproved : false,
        isEditable:updateFormValues.isEditable && updateFormValues.isEditable==true ? updateFormValues.isEditable : false,
        feedback:updateFormValues.feedback ? updateFormValues.feedback : '',
        onTimeDelivery:  updateFormValues.ratings ? filterRatingByName( updateFormValues.ratings,'onTimeDelivery'):'',
        packagingQuality: updateFormValues.ratings ? filterRatingByName( updateFormValues.ratings,'packagingQuality'):'',
        installationAndSetup: updateFormValues.ratings ? filterRatingByName( updateFormValues.ratings,'installationAndSetup'):'',
        productQuality: updateFormValues.ratings ? filterRatingByName( updateFormValues.ratings,'productQuality'):''

      }));

    } else {
      setFormValues(intialValues);
    }
  }, [editMode, updateFormValues]);

  return (
    <Popup open={addEditPopup} closeOnDocumentClick onClose={() => {
      setFormValues(intialValues);
      setFormErrors({});
      setApiErrorMSG({});
      setIsSubmittingLoader(false);
      setAddEditPopup(false);
    }}>
      <div className="cm_modal pl-5 pr-5 text-left">
        <span className="cm_modal_close" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>&times; </span>
        <h3 className="text-center mb-4 mt-5">{formValues._id ? 'Update record' : 'Add new record'} </h3>
        <div className="text-danger text-center h3"> </div>
        <form onSubmit={handleSubmit}>
          <p style={{ color: 'red' }} className={apiErrorMSG.status === 'error' ? 'error' : 'success'}>{apiErrorMSG.msg}</p>
         
        

          <div className="form-group">
            <label>Is Approved</label>
            <Select
              isSearchable
              options={setTrueFalseOptions()}
              placeholder="Select Status"
              onChange={(e) => handelSelectChange("isApproved", e.value)}
              value={setTrueFalseOptions().find(element => element.value == formValues.isApproved)}
            />
            {formErrors.isApproved && <label style={{ color: 'red' }}>{formErrors.isApproved}</label>}

          </div>

          <div className="form-group">
            <label>Is Editable</label>
            <Select
              isSearchable
              options={setTrueFalseOptions()}
              placeholder="Select Status"
              onChange={(e) => handelSelectChange("isEditable", e.value)}
              value={setTrueFalseOptions().find(element => element.value == formValues.isEditable)}
            />
            {formErrors.isEditable && <label style={{ color: 'red' }}>{formErrors.isEditable}</label>}

          </div>
      
          <div className="form-group">
            <label>On Time Delivery Rating</label>
            <Select
              isSearchable
              options={ratingOptions()}
              placeholder="Select Rating"
              onChange={(e) => handelSelectChange("onTimeDelivery", e.value)}
              value={formValues.onTimeDelivery ? ratingOptions().find(element => element.value === formValues.onTimeDelivery) : ""}
              defaultValue={{ label: "Select Status", value: "" }}
            />
            {formErrors.onTimeDelivery && <label style={{ color: 'red' }}>{formErrors.onTimeDelivery}</label>}

          </div>



          <div className="form-group">
            <label>Packaging Quality Rating</label>
            <Select
              isSearchable
              options={ratingOptions()}
              placeholder="Select Rating"
              onChange={(e) => handelSelectChange("packagingQuality", e.value)}
              value={formValues.packagingQuality ? ratingOptions().find(element => element.value === formValues.packagingQuality) : ""}
              defaultValue={{ label: "Select Status", value: "" }}
            />
            {formErrors.packagingQuality && <label style={{ color: 'red' }}>{formErrors.packagingQuality}</label>}

          </div>



          <div className="form-group">
            <label>Installation And Setup Rating</label>
            <Select
              isSearchable
              options={ratingOptions()}
              placeholder="Select Rating"
              onChange={(e) => handelSelectChange("installationAndSetup", e.value)}
              value={formValues.installationAndSetup ? ratingOptions().find(element => element.value === formValues.installationAndSetup) : ""}
              defaultValue={{ label: "Select Status", value: "" }}
            />
            {formErrors.installationAndSetup && <label style={{ color: 'red' }}>{formErrors.installationAndSetup}</label>}
          </div>

          <div className="form-group">
            <label>Product Quality Rating</label>
            <Select
              isSearchable
              options={ratingOptions()}
              placeholder="Select Rating"
              onChange={(e) => handelSelectChange("productQuality", e.value)}
              value={formValues.productQuality ? ratingOptions().find(element => element.value === formValues.productQuality) : ""}
              defaultValue={{ label: "Select Status", value: "" }}
            />
            {formErrors.productQuality && <label style={{ color: 'red' }}>{formErrors.productQuality}</label>}

          </div>


          <div className="form-group">
            <label>Feedback</label>
           
          <textarea 
          className="form-control"
          rows={4}
          cols={50} 
          name="feedback"
          maxlength="1500"
          onChange={handleChange}
          value={formValues.feedback}
          ></textarea>
            {formErrors.feedback && <label style={{ color: 'red' }}>{formErrors.feedback}</label>}

          </div>


      

       

          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block mt-4" disabled={isSubmittingLoader}>
              {isSubmittingLoader && (<i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>)} Save
						</button>
          </div>

          <div className="form-group">
            <button type="button" className="btn btn-secondary btn-block mt-4" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>
              Cancel
						</button>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default EditFeedbackForm;