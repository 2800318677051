import React, { useState, useEffect } from 'react';
import { getGymManagers, deleteGymManager, editGymManager } from '../../config/services/commercialService';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tag } from "antd";
import { Button, Modal, FormControl, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import Pagination from "react-js-pagination";

const itemsCountPerPage = 10;
const columnDetails = [
    { fieldName: "S.No." },
    { fieldName: "Name" },
    { fieldName: "Manager Creation Date" },
    { fieldName: "Contact No." },
    { fieldName: "Email" },
    { fieldName: "Gyms Owned" },
    { fieldName: "" }
];

const GymManagers = () => {
    const [gymManagersList, setGymManagersList] = useState([]);
    const [showEditGymManagerModal, setShowEditGymManagerModal] = useState(false);
    const [gymManagerName, setGymManagerName] = useState("");
    const [gymManagerMobileNo, setGymManagerMobileNo] = useState("");
    const [gymManagerEmail, setGymManagerEmail] = useState("");
    const [gymManagerIdToEdit, setGymManagerIdToEdit] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [totalGymManagers, setTotalGymManagers] = useState(0);

    useEffect(() => {
        fetchGymManagers(pageNumber);
    }, []);

    const fetchGymManagers = (pageNo) => {
        const params = { pageNo: pageNo, count: itemsCountPerPage, sortField: "created" };
        getGymManagers({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    data.gymManagers && setGymManagersList(data.gymManagers);
                    data.gymManagerCount && setTotalGymManagers(data.gymManagerCount);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const deleteGymManagerFn = (managerId) => {
        const params = { managerId: managerId };
        deleteGymManager(params)
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    fetchGymManagers();
                    toast.success(data.message);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const hideModal = () => setShowEditGymManagerModal(false);

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo-1);
        fetchGymManagers(pageNo-1);
    };

    const openEditGymManagerModal = (manager) => {
        setGymManagerName(manager.firstName);
        setGymManagerMobileNo(manager.mobileNo);
        setGymManagerEmail(manager.email);
        setGymManagerIdToEdit(manager._id);
        setShowEditGymManagerModal(true);
    };

    const handleGymManagerSubmission = (event) => {
        event.preventDefault();

        const params = {
            firstName: gymManagerName,
            mobileNo: gymManagerMobileNo,
            email: gymManagerEmail,
            countryCode: 91,
            managerId: gymManagerIdToEdit
        };

        editGymManager(params)
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    toast.success(response.responseData.message);
                    fetchGymManagers();
                    hideModal();

                    setGymManagerName("");
                    setGymManagerMobileNo("");
                    setGymManagerEmail("");
                    setGymManagerIdToEdit("");
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage);
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    return (
        <div className="main-wrapper">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item">Gym Managers</li>
            </ol>

            <div className="table-responsive mb-4" style={{ minHeight: '100px' }}>
                <table className="table table-bordered table-striped text-center">
                    <thead>
                        <tr>
                            {columnDetails.map((item, i) => 
                                <th scope="col" key={i}>{item.fieldName}</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {gymManagersList.map((gymManager, index) => {
                            return (
                                <tr key={gymManager._id}>
                                    <td className="cm_no_wrap">{itemsCountPerPage * (pageNumber) + (index + 1)}</td>
                                    <td className="cm_no_wrap">{gymManager.firstName}</td>
                                    <td className="cm_no_wrap">{moment(gymManager.created).format('Do MMM YYYY')}</td>
                                    <td className="cm_no_wrap">{gymManager.mobileNo}</td>
                                    <td className="cm_no_wrap">{gymManager.email}</td>
                                    <td class="text-left">
                                        <div className="wrapper-overflow">
                                            {gymManager.gyms && gymManager.gyms.map((gym, i) => (
                                                <span key={i}>
                                                    <Link to={`/gym/${gym._id}`}><Tag color="geekblue">{gym.name}</Tag></Link>
                                                </span>
                                            ))}
                                        </div>
                                    </td>
                                    <td className="cm_no_wrap">
                                        <Button className='m-1' variant="outline-info" onClick={() => openEditGymManagerModal(gymManager)}> <i className="fa fa-pencil" /> </Button>
                                        <Button className='m-1' variant="outline-danger" onClick={() => deleteGymManagerFn(gymManager._id)}> <i className="fa fa-trash" /> </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <Pagination
                    activePage={pageNumber+1}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalGymManagers}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
            </div>

            {/* Edit Gym Manager Modal */}
            <Modal show={showEditGymManagerModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Gym Manager</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <Form onSubmit={(e) => handleGymManagerSubmission(e)}>
                        <div className='row'>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Name</FormLabel>
                                <FormControl
                                    value={gymManagerName}
                                    name="name"
                                    onChange={(e) => { setGymManagerName(e.target.value) }}
                                    required
                                    type="text"
                                />
                            </FormGroup>

                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Contact No.</FormLabel>
                                <FormControl
                                    value={gymManagerMobileNo}
                                    name="contactNo"
                                    onChange={(e) => { setGymManagerMobileNo(e.target.value) }}
                                    required
                                    type="number"
                                />
                            </FormGroup>
                        </div>

                        <div className='row'>
                            <FormGroup className='col-lg-6 col-xl-4'>
                                <FormLabel>Email</FormLabel>
                                <FormControl
                                    value={gymManagerEmail}
                                    name="email"
                                    onChange={(e) => { setGymManagerEmail(e.target.value) }}
                                    required
                                    type="email"
                                />
                            </FormGroup>
                        </div>

                        <Row className="justify-content-center">
                            <Button variant="danger" onClick={hideModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit">Submit</Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default GymManagers;