import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const AddNotes = ({ note, handleChange, validationError }) => {
    return (
        <div>
            {validationError && <div className="alert alert-danger" role="alert">{validationError}</div>}
            

            <div className="form-group">
                <CKEditor 
                config={{
                    removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                }}
                onChange={handleChange} 
                editor={ ClassicEditor }
                data={note} 
                
                />
                {/* <textarea className="form-control" rows="3" onChange={handleChange} value={note}></textarea> */}
            </div>
        </div>
    );
};

export default AddNotes;