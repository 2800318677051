import axios from 'axios';
import API from '../Api';

export function addSuccessStories(params) {
    return axios.post(API.ADD_SUCCESS_STORIES, params)
}
export function editSuccessStories(params) {
    return axios.post(API.EDIT_SUCCESS_STORY, params)
}
export function getSuccessStoriesByAdmin(params) {
    return axios.get(API.GET_SUCCESS_STORIES, params)
}
export function deleteSuccessStories(params) {
    return axios.post(API.DELETE_SUCCESS_STORY, params)
}
