import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { ProductsService, addProduct } from '../../config/services/ProductsService';
import { Modal, Button } from 'react-bootstrap';
import { LoaderSvg } from '../Reusable'
import AmazonIcon from '../../assets/images/Amazon-icon.png'
import FlipkartIcon from '../../assets/images/flipkart-icon.png'
import OneFitplusIcon from '../../assets/images/OneFitplusIcon.png'
import Popup from "reactjs-popup"
import { Link } from 'react-router-dom';
import AddProduct from './AddProduct';
import SearchSpinner from '../common/SearchSpinner';

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 1,
      products: [],
      productSearch: '',
      productName: '',
      productCode: '',
      productLink: '',
      productASIN: '',
      productWebsite: '0',
      productCategory: '',
      productCategoryIndex: '',
      productImagePreview: '',
      productSaveLoader: false,
      productDescription: '',
      productPrice: '',
      productType: '',
      specifications: '',
      brandName: '',
      productPriceDiscount: 0,

      totalProducts: '',
      search: '',
      openEditProduct: false,
      productObj: '',
      openAddProductPopup: false,
      activePage: 0,
      Loader: true,
      open: false,
      openDropdown: false,
      name: '',
      uniqueId: '',
      type: null,
      typeIndex: null,
      errors: false,
      validateError: false,
      sortField: 1,
      sortFieldIcon: false,
      sortType: 2,
      searchLoader: false

    }
    this.showProductsList = this.showProductsList.bind(this);
    this.openProductModal = this.openProductModal.bind(this);
    this.closeProductModal = this.closeProductModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.handleProductSearch = this.handleProductSearch.bind(this);
    this.handleSortList = this.handleSortList.bind(this);
    this.openEditProductModal = this.openEditProductModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.getProductsCount = this.getProductsCount.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

  }

  componentDidMount() {
    this.showProductsList();
    this.getProductsCount();

  }


  handlePageChange(pageNumber) {
    this.setState({ pageNo: pageNumber }, () => {
      this.showProductsList();
    });
  }

  getProductsCount() {
    let self = this;
    ProductsService.getTotalProductsCount()
      .then(function (res) {
        if (res.data.statusCode === 1) {
          self.setState({
            totalProducts: res.data.responseData.count,
          })
        }
      })
  }

  showProductsList() {
    let params = `?pageNo=${this.state.pageNo - 1}&count=20&search=${this.state.search}&sortField=${this.state.sortField}&sortType=${this.state.sortType}`;

    ProductsService.getProductsList(params)
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({
            products: res.data.responseData.products,
            Loader: false,
            searchLoader: false
          })
        } else if (res.data.statusCode === 0) {
          this.setState({
            products: [],
            Loader: false,
            searchLoader: false
          })
        }
      })
  }

  handleValidation() {
    let { productName,
      productCode,
      productLink,
      productASIN } = this.state

    if (productName || productCode || productLink || productASIN == '') {
      return false
    } else {
      return true
    }
  }

  addProduct(e) {
    e.preventDefault();
    this.setState({ Loader: true, productSaveLoader: true });

    let { productName,
      productCode,
      productLink,
      productASIN, productWebsite, productCategoryIndex, productImage, productDescription, productPrice } = this.state

    let params = new FormData();
    params.append('productName', productName);
    params.append('productCode', productCode);
    params.append('productLink', productLink);
    params.append('productASIN', productASIN);
    params.append('productWebsite', productWebsite);
    params.append('productCategory', productCategoryIndex);
    params.append('productImage', productImage);
    params.append('productDescription', productDescription);
    params.append('productPrice', productPrice);


    addProduct(params)
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({ Loader: false, productSaveLoader: false })
          this.closeProductModal()
          this.showProductsList();
        } else {
          this.setState({
            validateError: res.data.error.errorMessage,
            productSaveLoader: false,
          })
        }
      })
  }

  openProductModal() {
    this.setState({ openAddProductPopup: true })
  }

  closeProductModal() {
    this.setState({
      openAddProductPopup: false,
      productName: '',
      productCode: '',
      productLink: '',
      productWebsite: '0',
      productCategory: '',
      productCategoryIndex: '',
      error: '',
      productImagePreview: '',
      productDescription: '',
      productPrice: '',
    })
  }

  handleChange(e) {
    if (e.target.id == 'productImage') {

      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          productImagePreview: reader.result
        });
      }
      reader.readAsDataURL(file)
      this.setState({
        productImagePreview: file
      })


      this.setState({
        productImage: e.target.files[0]
      });
    } else {
      this.setState({
        [e.target.id]: e.target.value,
        errors: false
      })
    }
  }

  handleProductSearch(e) {
    this.setState({ [e.target.id]: e.target.value, searchLoader: true }, function (search) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.showProductsList();
      }, 1000);
    })
  }

  handleSortList(sortField) {
    const { sortType } = this.state;

    let sortOrder = sortType === 1 ? 2 : 1;

    this.setState({ sortField: sortField, sortType: sortOrder }, function (sorted) {
      this.showProductsList();
      this.setState({ sortFieldIcon: !this.state.sortFieldIcon })
    })
  }

  openEditProductModal(product) {
    this.setState({
      openEditProduct: true,
      productObj: product._id,
    })
  }

  closeModal() {
    this.setState({ openEditProduct: false })
  }

  handleOpenDropdown = () => {
    this.setState({ openDropdown: !this.state.openDropdown })
  }

  setProductCategory(productCategoryIndex, productCategory) {
    this.setState({ productCategoryIndex, productCategory, openDropdown: false })
  }


  handleDeleteProduct(productInfo) {
    productInfo.preventDefault();
    this.setState({ Loader: true });
    let params = {
      productId: this.state.productObj,
    };
    ProductsService.deleteProduct(params)
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({
            openEditProduct: false,
            Loader: false,
            productObj: ''
          })
          this.showProductsList();
        }
      })
  }


  render() {
    const { productSaveLoader, Loader, validateError, openAddProductPopup, openEditProduct, searchLoader } = this.state;
    return (
      <HOC>
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item">Products</li>
            </ol>


            {
              Loader
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null
            }

            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="cm_page_heading">Products</h4>
                  </div>
                  <div className="col-md-3 text-right cm_search position-relative">
                    {searchLoader ?
                      <SearchSpinner type="text-info" />
                      : null}
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search"
                      id="search"
                      onChange={this.handleProductSearch}
                    />
                    <i className="fa fa-search"></i>
                  </div>

                  <div className="col-md-3  m-b-md-15">
                    <button onClick={this.openProductModal} className="btn btn-primary btn-block">Add Product</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">Name <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList(0)}></button></th>
                    <th>Product Code</th>
                    <th>Product ASIN</th>
                    <th>Product Link</th>
                    <th>Product Site</th>
                    <th>Product Type</th>
                    <th>Product Price</th>
                    <th>Display Order</th>

                    <th scope="col" className="filter_text">Created Date <i className={`fa fa-sort-numeric-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList(1)}></button></th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>


                <tbody>
                  {
                    this.state.products ? (
                      this.state.products.map((product, index) => {
                        return (
                          <tr key={product._id}>
                            <td>{((index + 1) + ((this.state.pageNo - 1) * 20))}</td>
                            <td>{product.productName}</td>
                            <td>{product.productCode}</td>
                            <td>{product.productASIN}</td>
                            <td className="text-left text-break w-300">{product.productLink}</td>
                            <td>{product.productWebsite == 0 ? <img width="25" src={AmazonIcon} /> : product.productWebsite == 1 ? <img width="25" src={FlipkartIcon} /> : <img width="25" src={OneFitplusIcon} />}</td>
                            <td>{product.productType === 0 ? "Rent" : "Sale"}</td>
                            <td>{product.productPrice} ₹</td>
                            <td>{product.productDisplayOrder} </td>
                            <td>{dateFormat(product.created, "d mmmm yyyy")}</td>
                            <td><span className="cm_btn_sm" onClick={() => this.openEditProductModal(product)}><i className="fa fa-pencil-square" ></i></span></td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="8">{this.state.error}</td>
                      </tr>
                    )
                  }
                </tbody>


              </table>
              {this.state.products.length === 0 ? <h3 className="empty_error">Sorry, We couldn't find any content! <span>{this.state.deviceSearch}</span></h3> : null}
              <Pagination
                activePage={this.state.pageNo}
                itemsCountPerPage={20}
                totalItemsCount={this.state.totalProducts}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>


        {openAddProductPopup && <AddProduct closeProductModal={this.closeProductModal} showProductsList={this.showProductsList} {...this.state} />}



        <Modal
          show={openEditProduct}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Product Action</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="btn_group">
              <div className="row">
                <div className="col"><Link to={'/edit-product/' + this.state.productObj} className="btn btn-warning btn-sm btn-block">Edit</Link></div>
                <div className="col"><button className="btn btn-danger btn-sm btn-block" onClick={this.handleDeleteProduct}>Delete</button></div>
              </div>
            </div>
          </Modal.Body>
        </Modal>


      </HOC>
    );
  }
}

export default Products;