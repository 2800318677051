import React, { useEffect, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import Smile from './../../assets/images/smile.svg'
import { notification, Space } from 'antd';

const SendEmoji = ({ showSendPopup,sendEmojiNotification ,isSendEmojiVisible, emojiList }) => {
  const sendEmoji = (id) => {
    sendEmojiNotification(id);
    showSendPopup(false);
  }

 
  return (
    <>
      <Modal width={350} centered={true} maskClosable={false} className="send-emoji-popup" title="Tap on emoji to send" footer={null} onCancel={showSendPopup} visible={isSendEmojiVisible} >
        <ul className="list-unstyled list-emojis p-0 m-0 d-flex flex-wrap">
          {emojiList.map((ele, i) => (
            <li className="emoji-icon" key={i}>
              <Tooltip placement="topLeft" className="d-flex align-items-center" title="Tap to send" arrowPointAtCenter>
                <img src={ele.emojiImageUrl} alt="emoji name" onClick={()=>sendEmoji(ele._id)} />
              </Tooltip>
            </li>
          ))}


        </ul>
      </Modal>
    </>
  );
};

export default SendEmoji;