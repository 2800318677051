import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { postDeliverablePincode, editDeliverablePincode } from '../../config/services/servicePincodes';
import Select from "react-select";
import { toast } from 'react-toastify';

import {logisticStatusOptions } from "../../utils/commonFunctions";




const AddEditForm = (props) => {

  const { editMode, addEditPopup, updateFormValues, setAddEditPopup, getList, wareHouseListShow } = props;
  const intialValues = {

    state: '',
    city: '',
    pincode: '',
    warehouse1: '',
    warehouse2: '',
    etaWarehouse1: '',
    etaWarehouse2: '',
    courierPartner: '',
    logisticStatus: '',
    isActive:"1"
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  const [apiErrorMSG, setApiErrorMSG] = useState({});

  // SUBMIT  FORM
  const handleSubmit = (e) => {
    e.preventDefault();
    setApiErrorMSG({});
    setIsSubmitting(true);
    if (validate(formValues)) {
      setIsSubmittingLoader(true);

      //For Update
      if (formValues._id) {
        editDeliverablePincode(formValues)
          .then((response) => {
          
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {
              if (Object.keys(response.data.responseData.result).length > 0) {
                toast.success(response.data.responseData.message);
                getList();
                // setApiErrorMSG({ status: 'success', msg: response.data.responseData.message });
              } else {
                setApiErrorMSG({ status: 'error', msg: response.data.responseData.message });
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error });
            setIsSubmittingLoader(false);
          });
      } else {
       //For Add
        postDeliverablePincode(formValues)
          .then((response) => {
            setIsSubmittingLoader(false);
            if (response.data.statusCode === 0) {
              setApiErrorMSG({ status: 'error', msg: response.data.error.errorMessage });
            } else {
              if (Object.keys(response.data.responseData.result).length > 0) {
                toast.success(response.data.responseData.message);
                getList();
                setFormValues(intialValues);
                setAddEditPopup(false);
                setApiErrorMSG({});
                setIsSubmittingLoader(false);
                // setApiErrorMSG({ status: 'success', msg: response.data.responseData.message });
              } else {
                setApiErrorMSG({ status: 'error', msg: response.data.responseData.message });
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            setApiErrorMSG({ status: 'error', msg: error });
            setIsSubmittingLoader(false);
          });
      }
    }
  };
  // HANDLE CHANGE FORM
  const handleChange = (event) => {
    let formValuesSubmited = formValues;
    const { name, value } = event.target;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  //set only for select type 
  const handelSelectChange = (name, value) => {

    let formValuesSubmited = formValues;
    formValuesSubmited[name] = value;
    if (isSubmitting) {
      validate(formValuesSubmited);
    }
    setFormValues({ ...formValues, [name]: value });
  }

  // VALIDATION FORM
  const validate = (values) => {
    let errors = {};

    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    if (!values.pincode) {
      errors.pincode = 'Required';
    }
    if (!values.warehouse1) {
      errors.warehouse1 = 'Required';
    }
    if (!values.warehouse2) {
      errors.warehouse2 = 'Required';
    }
    if (!values.etaWarehouse1) {
      errors.etaWarehouse1 = 'Required';
    }
    if (!values.etaWarehouse2) {
      errors.etaWarehouse2 = 'Required';
    }
    if (!values.courierPartner) {
      errors.courierPartner = 'Required';
    }
    if (!values.logisticStatus) {
      errors.logisticStatus = 'Required';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

//Add status Options

  let statusOptions=[
    { label: "Active", value: "1" },
    { label: "Deactive", value: "0" },
  ]


  useEffect(() => {
    if (editMode) {
      setFormValues((prevState) => ({
        ...prevState,
        ...updateFormValues,
        state: updateFormValues.state ? updateFormValues.state : '',
        city: updateFormValues.city ? updateFormValues.city : '',
        pincode:  updateFormValues.pincode ? updateFormValues.pincode : '',
        warehouse1:updateFormValues.warehouse1 && updateFormValues.warehouse1._id ? updateFormValues.warehouse1._id : '',
        warehouse2:updateFormValues.warehouse2 && updateFormValues.warehouse2._id ? updateFormValues.warehouse2._id : '',
        etaWarehouse1: updateFormValues.etaWarehouse1 ? updateFormValues.etaWarehouse1 : '',
        etaWarehouse2:updateFormValues.etaWarehouse2 ? updateFormValues.etaWarehouse2 : '',
        courierPartner:  updateFormValues.courierPartner ? updateFormValues.courierPartner : '',
        logisticStatus: updateFormValues.logisticStatus ? updateFormValues.logisticStatus.toString() : '0',
        isActive: updateFormValues.isActive=="1" ? "1" : "0",
      }));
      //setFormValues(updateFormValues);
    } else {
      setFormValues(intialValues);
    }
  }, [editMode, updateFormValues]);

  return (
    <Popup open={addEditPopup} closeOnDocumentClick={false} onClose={() => {
      setFormValues(intialValues);
      setFormErrors({});
      setApiErrorMSG({});
      setIsSubmittingLoader(false);
      setAddEditPopup(false);
    }}>
      <div className="cm_modal pl-5 pr-5">
        <span className="cm_modal_close" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>&times; </span>
        <h3 className="text-center mb-4 mt-5">{formValues._id ? 'Update record' : 'Add new record'} </h3>
        <div className="text-danger text-center h3"> </div>
        <form onSubmit={handleSubmit}>
          <p style={{ color: 'red' }} className={apiErrorMSG.status === 'error' ? 'error' : 'success'}>{apiErrorMSG.msg}</p>
          <div className="form-group">
            <label>Pin code(*)</label>

            <input
              disabled={editMode ? true : false}
              className="form-control"
              type="number"
              pattern="[0-9.]+"
              onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
              placeholder="Pincode"
              name="pincode"
              min="0"
              value={formValues.pincode}
              onChange={(e) => {
                if (formValues.pincode.length >=6 && e.target.value.length > 6) {
                  return;
                }

                handleChange(e);
              }}
            />
            {formErrors.pincode && <label style={{ color: 'red' }}>{formErrors.pincode}</label>}
          </div>

          <div className="form-group">
            <label>City(*)</label>
            <input
              type="text"
              className="form-control"
              placeholder="City"
              name="city"
              maxLength="50"
              onChange={handleChange}
              value={formValues.city}
            />
            {formErrors.city && <label style={{ color: 'red' }}>{formErrors.city}</label>}
          </div>

          
          <div className="form-group">
            <label>Logistic partner (*)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Logistic partner"
              name="courierPartner"
              maxLength="50"
              onChange={handleChange}
              value={formValues.courierPartner}
            />
            {formErrors.courierPartner && <label style={{ color: 'red' }}>{formErrors.courierPartner}</label>}
          </div>
        

          <div className="form-group">
            <label>State(*)</label>
            <input
              type="text"
              className="form-control"
              placeholder="State"
              name="state"
              maxLength="50"
              onChange={handleChange}
              value={formValues.state}
            />
            {formErrors.state && <label style={{ color: 'red' }}>{formErrors.state}</label>}
          </div>
         

          <div className="form-group">
            <label>Primary warehouse L1(*)</label>
            <Select
              className='cm_min_width'
              onChange={(e) => handelSelectChange("warehouse1", e.value)}
              placeholder="Warehouse 1"
              options={wareHouseListShow}
              value={formValues.warehouse1 ? wareHouseListShow.find(element => element.value === formValues.warehouse1) : ""}
              defaultValue={{ label: "Select Warehouse 1", value: "" }}
            />
            {formErrors.warehouse1 && <label style={{ color: 'red' }}>{formErrors.warehouse1}</label>}
          </div>


          <div className="form-group">
            <label>Secondary warehouse L2(*)</label>
            <Select
              className='cm_min_width'
              onChange={(e) => handelSelectChange("warehouse2", e.value)}
              placeholder="Warehouse 2"
              options={wareHouseListShow}
              value={formValues.warehouse2 ? wareHouseListShow.find(element => element.value === formValues.warehouse2) : ""}
              defaultValue={{ label: "Select Warehouse 1", value: "" }}
            />
            {formErrors.warehouse2 && <label style={{ color: 'red' }}>{formErrors.warehouse2}</label>}
          </div>

          <div className="form-group">
            <label>ETA L1 (*)</label>

              <input
              type="text"
              className="form-control"
              placeholder="ETA L1"
              name="etaWarehouse1"
              maxLength="10"
              onChange={(e) => {
              
                if (/[^a-zA-Z0-9 ]/.test(e.target.value)) {
                  return;
                }
                handleChange(e);
              }}
              value={formValues.etaWarehouse1}
            />
            {formErrors.etaWarehouse1 && <label style={{ color: 'red' }}>{formErrors.etaWarehouse1}</label>}
          </div>

          <div className="form-group">
            <label>ETA L2 (*)</label>
          
               <input
              type="text"
              className="form-control"
              placeholder="ETA L2"
              name="etaWarehouse2"
              maxLength="10"
              onChange={(e) => {
              
                if (/[^a-zA-Z0-9 ]/.test(e.target.value)) {
                  return;
                }
                handleChange(e);
              }}
              value={formValues.etaWarehouse2}
            />
            {formErrors.etaWarehouse2 && <label style={{ color: 'red' }}>{formErrors.etaWarehouse2}</label>}
          </div>

         
          <div className="form-group">
            <label>Logistic Status (*) </label>
            <Select
              isSearchable
              options={logisticStatusOptions("Select Status")}
              placeholder="Logistic Status"
              onChange={(e) => handelSelectChange("logisticStatus", e.value)}
              value={formValues.logisticStatus ? logisticStatusOptions("Select Status").find(element => element.value === formValues.logisticStatus) : ""}
              defaultValue={{ label: "Select Status", value: "" }}
            />
            {formErrors.logisticStatus && <label style={{ color: 'red' }}>{formErrors.logisticStatus}</label>}

          </div>
      

        {formValues._id ?
          <div className="form-group">
            <label> Status</label>
            <Select
              isSearchable
              options={statusOptions}
              placeholder="Status"
              onChange={(e) => handelSelectChange("isActive", e.value)}
              value={statusOptions.find(element => element.value === formValues.isActive)}
             
            />
            {formErrors.isActive && <label style={{ color: 'red' }}>{formErrors.isActive}</label>}

          </div>
          :''}

          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block mt-4" disabled={isSubmittingLoader}>
              {isSubmittingLoader && (<i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>)} Save
						</button>
          </div>

          <div className="form-group">
            <button type="button" className="btn btn-secondary btn-block mt-4" onClick={() => {
          setFormValues(intialValues);
          setFormErrors({});
          setApiErrorMSG({});
          setIsSubmittingLoader(false);
          setAddEditPopup(false);
        }}>
              Cancel
						</button>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default AddEditForm;