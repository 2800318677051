import React, { Component } from 'react';
import HOC from '../../HOC';
import { DeviceService } from '../../config/services/DeviceService';
import { LoaderSvg } from '../Reusable';
import { Link } from 'react-router-dom';
import Select from "react-select";

class EditDevice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            type: '',
            uniqueId: '',
            openDropdown: false,
            types: ['Application', 'Treadmill', 'Manual Machine', 'Fitness Trackers', 'Smartscale', "Mannual data", 'Voice Control', 'Bikes', 'Outdoor Cycle', 'Outdoor Run'],
            Loader: true,
            deviceImagePreview: '',
            newDeviceImagePreview: '',
            deviceImage: '',
            newDeviceImage: '',
            active: true,
            category: 0,
            platform: [],
            platformType : [
                {label: 'IOS', value: 1},
                {label: 'ANDROID', value: 2}
            ],
            statusType: [
                {label: 'True', value: true},
                {label: 'False', value: false}
            ],
            categoryType:[
                {label: "None", value: 0},
                {label: "INTEGRATE WITH APP", value: 3}
            ]
        }

        this.deviceDetails = this.deviceDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidetion = this.handleValidetion.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    deviceDetails() {
        let params = { deviceId: this.props.match.params.id }

        DeviceService.getDeviceOneDevice(params)
            .then((res) => {
                if (res.data.statusCode === 1) {
                    let { name, type, uniqueId,deviceImage,newDeviceImage,active, platform, category } = res.data.responseData.device;
                    this.setState({
                        name,
                        type,
                        uniqueId,
                        deviceImage:deviceImage,
                        deviceImagePreview:deviceImage ? deviceImage: '',
                        newDeviceImage:newDeviceImage,
                        newDeviceImagePreview:newDeviceImage ? newDeviceImage : '',
                        active:active? active: true,
                        platform:platform ? platform : [],
                        category:category? category: 0,
                        Loader: false,
                    })
                } else {
                    localStorage.removeItem('accessToken')
                    this.props.history.push('/login');
                }
            })
    }

    handleOpenDropdown = () => {
        this.setState({ openDropdown: !this.state.openDropdown })
    }

    getDeiveType = (val) => {
        this.setState({
            type: val,
            openDropdown: !this.state.openDropdown
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            errors: false
        })
    }

    handleValidetion() {
        let validate = true;
        let name = this.state.name;
        let uniqueId = this.state.uniqueId;

        if (name === '' || name === undefined) {
            validate = false;
        } else {
            validate = true;
        }

        if (uniqueId === '' || uniqueId === undefined) {
            validate = false;
        } else {
            validate = true;
        }

        return validate
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ errors: true })

        let { name, uniqueId, type, deviceImage,newDeviceImage,active, platform, category  } = this.state;
        let data = new FormData();
        data.append('deviceId', this.props.match.params.id);
        data.append('name', name);
        data.append('uniqueId', uniqueId);
        data.append('type', type);
        data.append('deviceImage', deviceImage);
        data.append('newDeviceImage', newDeviceImage);
        data.append('active', active);
        data.append('category', category);
        data.append('platform', JSON.stringify(platform));


        if (this.handleValidetion()) {
            DeviceService.UpdateDevice(data)
                .then(res => {
                    if (res.data.statusCode === 1) {
                        this.setState({
                            errors: false,
                            openAddDevicePopup: false,
                            name: '',
                            uniqueId: '',
                            type: null
                        });

                        this.props.history.push('/device/');
                    }
                })
        } else {
            this.setState({ validateError: true, errors: false })
        }
    }
    handleImageChange=e=> {
        let { name, files } = e.target
        if (name == 'deviceImage') {
            console.log()
            let file = files[0];
            let reader = new FileReader();
            reader.onloadend = () => { this.setState({ deviceImagePreview: reader.result }) }
            reader.readAsDataURL(file)
            this.setState({ deviceImagePreview: file, deviceImage: e.target.files[0]})
        }

        if (name == 'newDeviceImage'){
            let file = files[0];
            let reader = new FileReader();
            reader.onloadend = () => { this.setState({ newDeviceImagePreview: reader.result }) }
            reader.readAsDataURL(file)
            this.setState({ newDeviceImagePreview: file, newDeviceImage: e.target.files[0]})
        }
    }

    handleSelectChange = (key,data) => {
        this.setState({ [key]: data.value })
        console.log(this.state.active)
    }

    handleMultiSelect = (key, val) => {
        if (val && val.length){
            let platformArray = val.map(function (obj) {
                return obj.value;
            });
            this.setState({ platform: platformArray ? platformArray : [] })
        }else{
            this.setState({ platform: [] })
        }

    }


    componentDidMount() {
        this.deviceDetails();
    }



    render() {
        let { name, type, uniqueId, types, category, active, newDeviceImage, deviceImage,platform } = this.state;
        return (
            <HOC>
                {
                    this.state.Loader
                        ? <div className="loader_wrapper"><LoaderSvg /></div>
                        : null
                }
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/device">Device</Link></li>
                            <li className="breadcrumb-item">Edit Device</li>
                        </ol>
                        <h3 className="text-center mb-4 mt-5">Edit Device</h3>
                        <form onSubmit={this.handleSubmit} className="edit_device_form mb-5">

                            <div className="form-group">
                                <label>Name of device</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={name}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Unique connection id of device</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="uniqueId"
                                    value={uniqueId}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className={this.state.openDropdown ? 'form-group cm_dropdown open' : 'form-group cm_dropdown'}>
                                <label>Type of devices</label>
                                <span className="form-control cm_blank_input" onClick={this.handleOpenDropdown}>{types[type]}</span>
                                <input type="hidden" value={type} name="type" />
                                <ul>
                                    <li onClick={() => this.getDeiveType(0)}>
                                        <h4>Application</h4>
                                        <p>Google fit, Samsung Health, Fitbit, Apple Health</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(1)}>
                                        <h4>Treadmill</h4>
                                        <p>RPM Fitness, Fitkit, ES Linker</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(2)}>
                                        <h4>Manual Machine</h4>
                                        <p>RPM Fitness</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(3)}>
                                        <h4>Fitness Trackers</h4>
                                        <p>Mi Band</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(4)}>
                                        <h4>Smartscale</h4>
                                        <p>F+</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(6)}>
                                        <h4>Voice Controls</h4>
                                        <p>Alexa , Google home</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(7)}>
                                        <h4>Bikes</h4>
                                        <p>Spin Bike , Mannual Bike</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(8)}>
                                        <h4>OutDoor</h4>
                                        <p>Urban Terrain</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(9)}>
                                        <h4>OutDoor Run</h4>
                                        <p>Urban Terrain</p>
                                    </li>
                                    <li onClick={() => this.getDeiveType(10)}>
                                        <h4>Cross Trainer</h4>
                                        <p>Elliptical</p>
                                    </li>
                                </ul>
                            </div>

                            <div className="form-group mt-2 mb-1">
                                <label className="d-block">Upload Device Image</label>
                                <div className="radio_wrapper text-center">
                                    <input className='upload_btn-spacing' accept="image/*" type="file" onChange={this.handleImageChange} id="deviceImage" name="deviceImage" />
                                    {this.state.deviceImagePreview? <img width="400px" src={this.state.deviceImagePreview} />:''}
                                </div>
                            </div>

                            <div className="form-group mt-2 mb-1">
                                <label className="d-block">Upload New Device Image</label>
                                <div className="radio_wrapper text-center">
                                    <input className='upload_btn-spacing' accept="image/*" type="file" onChange={this.handleImageChange} id="newDeviceImage" name="newDeviceImage" />
                                    <img width="400px" src={this.state.newDeviceImagePreview} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Category</label>
                                <Select
                                    name="category"
                                    placeholder="Select Category"
                                    options={this.state.categoryType}
                                    onChange={(val) => this.handleSelectChange('category', val)}
                                    value={ category ? this.state.categoryType.filter(({value}) => value === category): this.state.categoryType.filter(({value}) => value === 0)}

                                >
                                </Select>

                            </div>

                            <div className="form-group">
                                <label>Status</label>
                                <Select
                                    name="active"
                                    placeholder="Select Status"
                                    options={this.state.statusType}
                                    onChange={(val) => this.handleSelectChange('active', val)}
                                    value={ active ? this.state.statusType.filter(({value}) => value === active): this.state.statusType.filter(({value}) => value === false)}
                                />
                            </div>

                            <div className="form-group">
                                <label>Platform</label>
                                <Select
                                    name="platform"
                                    placeholder="Select Platform"
                                    options={this.state.platformType}
                                    isMulti
                                    onChange={(val) => this.handleMultiSelect('platform', val)}
                                    value={ (platform && platform.length ) ? this.state.platformType.filter(({value}) => platform.includes(value)): null}
                                />
                            </div>


                            <button className="btn btn-primary btn-block mt-4">Save</button>
                            {this.state.validateError ? <span className="error">error</span> : null}

                        </form>
                    </div>
                </div>
            </HOC>
        )


    }
}

export default EditDevice;