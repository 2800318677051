import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getLocation, addLocation } from '../../config/services/L1L2Service';
import { toast } from 'react-toastify';
import { Button, Modal, Alert, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone'

class LocationL1L2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            locationsList: [],
            search: '',
            count: 10,
            totalLocations: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            validationError: '',
            addLocationsPopup: false,
            excelFile: '',
            uploadingLoader: false

        }
    }

    componentDidMount() {
        this.getLocationsList();
    }

    getLocationsList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = { params: { search, count, pageNo: pageNo - 1, sortType, sortField } }

        getLocation(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode === 1) {
                    this.setState({
                        locationsList: response.responseData.result,
                        totalLocations: response.responseData.totalCount,
                        loader: false
                    })
                } else if (response.statusCode === 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleSearch = e => this.setState({ search: e.target.value, pageNo: 1 }, () => this.getLocationsList());

    handlePageChange = (pageNumber) => { this.setState({ pageNo: pageNumber }, () => { this.getLocationsList() }) }

    handleClose = () => { this.setState({ addLocationsPopup: false, excelFile: '', validationError: '', uploadingLoader: false }) }

    handleAddLocationsPopup = () => { this.setState({ addLocationsPopup: true }) }

    handleFileDrop = (file) => {
        if (file.length && (file[0].type === 'application/vnd.ms-excel' || file[0].type === 'text/csv' || file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            this.setState({ excelFile: file[0], validationError: '' })
        } else {
            this.setState({ validationError: "This file format is not supported!", excelFile: '' })
        }
    }


    submitUploadLocations = () => {
        let { excelFile } = this.state

        if (!excelFile) {
            this.setState({ validationError: 'Upload File First!' })
        } else {
            this.setState({ uploadingLoader: true })
            let params = new FormData()
            params.append('excelFile', excelFile)

            addLocation(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode === 1) {
                        this.handleClose()
                        this.getLocationsList()
                        toast.success(response.responseData.message)
                    } else if (response.statusCode === 0) {
                        this.setState({ uploadingLoader: false, validationError: response.error.errorMessage })
                    }
                })
        }
    }

    render() {
        let {
            loader, locationsList, search, pageNo, count, totalLocations, addLocationsPopup, validationError,uploadingLoader } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">L1 L2</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">L1 L2</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search By City......."
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>

                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-info btn-block" onClick={this.handleAddLocationsPopup}>Bulk Upload</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-striped text-center mb-5">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" >State</th>
                                        <th scope="col" className="filter_text"> City </th>
                                        <th scope="col" className="filter_text"> L1</th>
                                        <th scope="col" className="filter_text"> L2</th>
                                        <th scope="col" className="filter_text"> Time Range</th>
                                        <th scope="col" className="filter_text">Pincode</th>
                                        <th scope="col" className="filter_text">Region</th>
                                        <th scope="col" className="filter_text">Description</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        locationsList.map((details, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">
                                                        {details.state}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {details.normalizedCity}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.l1}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.l2}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.timeRange}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.pincode}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.region}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.description}</span>
                                                    </td>
                                                    <td className="cm_no_wrap">{moment(details.created).format("Do MMM YYYY")}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalLocations}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />
                            {!locationsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>
                    </div>
                </div>

                <Modal show={addLocationsPopup} onHide={this.handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Upload File!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Dropzone
                            multiple={false}
                            onDrop={acceptedFiles => this.handleFileDrop(acceptedFiles)}
                        >
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                        <i class="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Modal.Body>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Cancel</Button>
                        <Button disabled={validationError} variant="success" onClick={this.submitUploadLocations}>
                           {uploadingLoader? <Spinner animation="border" variant="warning" /> :'Upload'}</Button>
                    </Modal.Footer>
                </Modal>
            </HOC>
        )
    }
}




export default LocationL1L2;