import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LoaderSvg } from '../Reusable';
import AddEditHistoryForm from './AddEditHistoryForm';
import { getOrderHistory} from '../../config/services/OrderHistory';
import { ORDER_STATUS,ORDER_PAYMENT_STATUS } from "../../utils/constants";
import {  Tooltip } from 'antd';
import {showLimitWords } from "../../utils/commonFunctions";



let  desingCLass = 'shadow-sm p-3 mb-4 rounded';

const OrderHistory = (props) => {
  const {orderDetails}= props;
  const [errorMsg, setErrorMsg] = useState('');
  const [loader, setLoader] = useState(false);
  const [aboutOrderDetail, setAboutOrderDetail] = useState({});
  const [orderHistoryInfo, setOrderHistoryInfo] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [updateFormValues, setUpdateFormValues] = useState({});
  const [addEditPopup, setAddEditPopup] = useState(false);
  //for checking first order tracking history (orderd)
  const [isFirstHistory, setIsFirstHistory] = useState(false);

  //set value after changing props
  const setOrePropsValue = ()=>{
    setAboutOrderDetail(orderDetails);
  } 

  //Get Order HistoryList 
  const getOrderHistoryListing = () => {
    try {
      if(orderDetails._id){
        setLoader(true);
      //get History list 
      getOrderHistory(orderDetails._id).then(res => {
        let response = res.data;
  
        if (response.statusCode == 1 && response.responseData.result) {
          setOrderHistoryInfo(response.responseData.result);
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
        setLoader(false);
      });
    }
     
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  }



//set order detail after changing props
  useEffect(()=>{
      setOrePropsValue();
  },[props.orderDetails]);

//Get Feedback After changing list
  useEffect(()=>{
    getOrderHistoryListing();
},[props.orderDetails]);

  return (
    <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
 <button className="btn btn-info mt-2" onClick={() => { 
   setAddEditPopup(true);
   setUpdateFormValues({orderId:orderDetails._id});
    }}>Add History</button>
    <div className="row text-center">
    
 
        <div className="col-md-12 mb-5">
            <div className="row">


            {loader
           ? <div className="loader_wrapper"><LoaderSvg /></div>
           : null}

      

            <div className='table-responsive mt-2'>
              <table className='table table-bordered table-striped text-center'>
                <thead>
                  <tr>
                    <th scope="col">Order Status</th>
                    <th scope="col">IS Display For User</th>
                    <th scope="col">Order Notes</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orderHistoryInfo && orderHistoryInfo.length > 0 && orderHistoryInfo.map((data, index) => (
                    <tr key={index}>
                      <td> {data.orderStatus ? ORDER_STATUS[data.orderStatus] :'' }</td>
                      <td> {data.isDisplayForUser==true? "True": "False"}</td>
                      {/* <td>{data.notes ? data.notes:''}</td> */}

                      <td>
                        {data.notes ? 
                      <Tooltip placement="topLeft" className="d-flex align-items-center"
                       title={data.notes}>
                             {showLimitWords(data.notes,120)}
                      </Tooltip>
                      : ''}
                      </td>

                      <td> <button
                          className="btn btn-dark btn-sm"
                          onClick={() => {
                            setEditMode(true);
                            setUpdateFormValues(data);
                            setAddEditPopup(true);  
                            setIsFirstHistory(index==0 ? true: false)    
                          }}
                        >
                         Edit
                        </button>
                        &nbsp;</td>
                     
                     
                    </tr>
                  ))}
                </tbody>
              </table>

              <AddEditHistoryForm
            editMode={editMode}
            addEditPopup={addEditPopup} 
            updateFormValues={updateFormValues} 
            isMyFirstHistory={isFirstHistory}
            setAddEditPopup={(val) => {
              setEditMode(false);
              setAddEditPopup(val);
              setIsFirstHistory(false);
            }}
            getList={() => { getOrderHistoryListing() }}
          />


              {orderHistoryInfo && orderHistoryInfo.length <1 ? (
                <h3 className="empty_error">
                  Sorry, We couldn't find any content!{" "}
                  {/* <span>{this.state.deviceSearch}</span> */}
                </h3>
              ) : null}
            </div>
  
         
            </div>
        </div>
    </div>
</div>
 );
};

export default OrderHistory;