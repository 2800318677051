import React, { Component } from 'react';
import HOC from '../../HOC';
import NotFound from '../../assets/images/NotFound.jpg';

class PageNotFound extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container text-center">
                        <img src={NotFound}/>
                </div>
                </div>
            </HOC>
        )
    }
}

export default PageNotFound;