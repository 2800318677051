import axios from 'axios';
import API from '../Api';

export function getTransactions(params) {
    return axios.get(API.GET_TRANSACTIONS, params)
}

export function changeStatusReward(params) {
    return axios.post(API.CHANGE_STATUS_REWARD, params)
}


export function updateDeliveryStatus(params) {
    return axios.post(API.UPDATE_DELIVERY_STATUS, params)
}

