import React from "react";
import FoodPlaceholder from "../../assets/images/FoodPlaceholder.jpg";
import FoodCard from "./FoodCard";
import DietPlanCard from "../common/DietPlan";
const CardWrapper = (props) => <div className="col mb-5">{props.children}</div>;

const DietHistoryBody = ({ dietPlanDetails }) => {
  const {
    morningSnacksOptions,
    breakfastOptions,
    lunchOptions,
    dinnerOptions,
    midEveningOptions,
    midMorningOptions,
    bedTimeOptions,
    perDayCalories,
    recommendedMorningSnacksCalories,
    recommendedBreakfastCalories,
    recommendedMidMorningCalories,
    recommendedLunchCalories,
    recommendedMidEveningCalories,
    recommendedDinnerCalories,
    recommendedBedTimeCalories,
    morningSnacksDietTime,
    breakfastDietTime,
    midMorningDietTime,
    lunchDietTime,
    midEveningDietTime,
    dinnerDietTime,
    bedTimeDietTime,
  } = dietPlanDetails || {};
  let morningSnacksList = morningSnacksOptions || [];
  let breakfastList = breakfastOptions || [];
  let midMorningDietList = midMorningOptions || [];
  let lunchList = lunchOptions || [];
  let midEveningDietList = midEveningOptions || [];
  let dinnerList = dinnerOptions || [];
  let bedTimeDietList = bedTimeOptions || [];

  return (
    <>
      <CardWrapper>
        <div className="diet-plan-heading">
          <h3 className="heading-title">Pre-Breakfast</h3>
          <h6>Calories per day {perDayCalories}</h6>
          <h6>
            Calorie target- {recommendedMorningSnacksCalories}{" "}
            cal&nbsp;&nbsp;&nbsp; Timing - {morningSnacksDietTime}
          </h6>
        </div>
        <div className="diet-plan-wrapper">
          {/* <CardWrapper> */}
          {!morningSnacksList.length ? (
            <p className="text-danger">No food allotted</p>
          ) : (
            <>
              {/* <FoodCard foodItem={foodItem} /> */}
              {morningSnacksList.map((options, i) => (
                <DietPlanCard
                  itrate={i}
                  dietList={options.morningSnacksDiet || []}
                  totalCalories={options.morningSnacksDietCalorie || []}
                />
              ))}
            </>
          )}
          {/* </CardWrapper> */}
          {/* </div> */}
        </div>
      </CardWrapper>
      <CardWrapper>
        <div className="diet-plan-heading">
          <h3 className="heading-title">Breakfast</h3>
          <h6>
            Calorie target- {recommendedBreakfastCalories} cal&nbsp;&nbsp;&nbsp;
            Timing - {breakfastDietTime}
          </h6>
        </div>
        <div className="diet-plan-wrapper">
          {!breakfastList.length ? (
            <p className="text-danger">No food allotted</p>
          ) : (
            <>
              {breakfastList.map((options, i) => (
                <DietPlanCard
                  itrate={i}
                  dietList={options.breakfastDiet || []}
                  totalCalories={options.breakfastDietCalorie || []}
                />
              ))}
            </>
          )}
        </div>
      </CardWrapper>

      <CardWrapper>
        <div className="diet-plan-heading">
          <h3 className="heading-title">Mid Morning</h3>
          <h6>
            Calorie target- {recommendedMidMorningCalories}{" "}
            cal&nbsp;&nbsp;&nbsp; Timing - {midMorningDietTime}
          </h6>
        </div>
        <div className="diet-plan-wrapper">
          {!midMorningDietList.length ? (
            <p className="text-danger">No food allotted</p>
          ) : (
            <>
              {midMorningDietList.map((options, i) => (
                <DietPlanCard
                  itrate={i}
                  dietList={options.midMorningDiet || []}
                  totalCalories={options.midMorningDietCalorie || []}
                />
              ))}
            </>
          )}
        </div>
      </CardWrapper>

      <CardWrapper>
        <div className="diet-plan-heading">
          <h3 className="heading-title">Lunch</h3>{" "}
          <h6>
            Calorie target- {recommendedLunchCalories} cal&nbsp;&nbsp;&nbsp;
            Timing - {lunchDietTime}
          </h6>
        </div>
        <div className="diet-plan-wrapper">
          {!lunchList.length ? (
            <p className="text-danger">No food allotted</p>
          ) : (
            <>
              {lunchList.map((options, i) => (
                <DietPlanCard
                  itrate={i}
                  dietList={options.lunchDiet || []}
                  totalCalories={options.lunchDietCalorie || []}
                />
              ))}
            </>
          )}
        </div>
      </CardWrapper>

      <CardWrapper>
        <div className="diet-plan-heading">
          <h3 className="heading-title">Mid Evening</h3>
          <h6>
            Calorie target- {recommendedMidEveningCalories}{" "}
            cal&nbsp;&nbsp;&nbsp; Timing - {midEveningDietTime}
          </h6>
        </div>
        <div className="diet-plan-wrapper">
          {!midEveningDietList.length ? (
            <p className="text-danger">No food allotted</p>
          ) : (
            <>
              {midEveningDietList.map((options, i) => (
                <DietPlanCard
                  itrate={i}
                  dietList={options.midEveningDiet || []}
                  totalCalories={options.midEveningDietCalorie || []}
                />
              ))}
            </>
          )}
        </div>
      </CardWrapper>

      <CardWrapper>
        <div className="diet-plan-heading">
          <h3 className="heading-title">Dinner</h3>
          <h6>
            Calorie target- {recommendedDinnerCalories} cal&nbsp;&nbsp;&nbsp;
            Timing - {dinnerDietTime}
          </h6>
        </div>
        <div className="diet-plan-wrapper">
          {!dinnerList.length ? (
            <p className="text-danger">No food allotted</p>
          ) : (
            <>
              {dinnerList.map((options, i) => (
                <DietPlanCard
                  itrate={i}
                  dietList={options.dinnerDiet || []}
                  totalCalories={options.dinnerDietCalorie || []}
                />
              ))}
            </>
          )}
        </div>
      </CardWrapper>

      <CardWrapper>
        <div className="diet-plan-heading">
          <h3 className="heading-title">Bed Time</h3>
          <h6>
            Calorie target- {recommendedBedTimeCalories} cal&nbsp;&nbsp;&nbsp;
            Timing - {bedTimeDietTime}
          </h6>
        </div>
        <div className="diet-plan-wrapper">
          {!bedTimeDietList.length ? (
            <p className="text-danger">No food allotted</p>
          ) : (
            <>
              {bedTimeDietList.map((options, i) => (
                <DietPlanCard
                  itrate={i}
                  dietList={options.bedTimeDiet || []}
                  totalCalories={options.bedTimeDietCalorie || []}
                />
              ))}
            </>
          )}
        </div>
      </CardWrapper>
    </>
  );
};

export default DietHistoryBody;
