import React from 'react';
import Select from 'react-select';
const addVideo = (props) => {
    const {handleClose, addVideoSubmit, validationError, handleChange, categories, subCategories, handleCategory, handleSubCategory } = props
    return (
        <div className="cm_modal">
        <span className="cm_modal_close" onClick={handleClose}>&times;</span>
        <h3 className="text-center">Add Help Video</h3>
        <h4 className="text-center mb-4 mt-5 error_message">{validationError}</h4>

        <form onSubmit={addVideoSubmit}>
            <div className="form-group">
                <label>Video Title</label>
                <input
                    type="text"
                    className="form-control"
                    name="videoTitle"
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Video Url</label>
                <input
                    type="text"
                    className="form-control"
                    name="videoUrl"
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label>Category</label>
                <Select
                    isSearchable
                    options={categories}
                    placeholder="Select a parnent category"
                    onChange={(val) => handleCategory(val)}
                    className="mt-1 mb-1"
                />
            </div>
            <div className="form-group">
                <label>Sub Category</label>
                <Select
                    isSearchable
                    options={subCategories}
                    placeholder="Select a parnent category"
                    onChange={(val) => handleSubCategory(val)}
                    className="mt-1 mb-1"
                />
            </div>
            <div className="text-center">
                <button className="btn btn-primary" type="submit">Add</button>
            </div>
        </form>
    </div>
    );
};

export default addVideo;