import React, { Component } from 'react';
import HOC from '../../HOC';
import { getWhyChooseUs, editWhyChooseUs } from '../../config/services/WhyChooseUsService';
import { LoaderSvg } from '../Reusable';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';

class WhyChooseUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: 0,
            happyCustomer: 0,
            appDownload: 0,
            kilometerCovered: 0,
            dietPlanDelivered: 0,
            trainerSession: 0,
            doctorSession: 0,
            loader: false,
            validationError: '',
            tempEditId: '',
            submitDisabled: true,
            diet_consultation:0,
            installation_pincodes:0
        }

        
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value, submitDisabled: false })
    }

    componentDidMount() {
        this.getWhyChooseUs()
    }

    getWhyChooseUs = () => {
        getWhyChooseUs()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let { product, happyCustomer, appDownload, kilometerCovered, dietPlanDelivered, trainerSession, doctorSession, loader, validationError, _id , diet_consultation , installation_pincodes } = response.responseData.result

                    this.setState({
                        product, happyCustomer, appDownload, kilometerCovered, dietPlanDelivered, trainerSession, doctorSession, loader, validationError, diet_consultation , installation_pincodes,
                        tempId: _id,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    submitEditWhyChooseUs = () => {
        let { product, happyCustomer, appDownload, kilometerCovered, dietPlanDelivered, trainerSession, doctorSession, loader, validationError, tempEditId , diet_consultation , installation_pincodes} = this.state
        let params = {
            product, happyCustomer, appDownload, kilometerCovered, dietPlanDelivered, trainerSession, doctorSession, loader, validationError, editId: tempEditId , diet_consultation , installation_pincodes
        }

        this.setState({ loader: true })
        editWhyChooseUs(params)
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.getWhyChooseUs();
                    this.setState({ loader: false, submitDisabled: true })
                } else if (res.data.statusCode === 0) {
                    this.setState({ validationError: res.data.error.errorMessage, loader: false })
                }
            })
    }


    render() {
        let { product, happyCustomer, appDownload, kilometerCovered, dietPlanDelivered, trainerSession, doctorSession, loader, validationError, submitDisabled , diet_consultation , installation_pincodes } = this.state;

        return (
            <HOC>
                {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Why Choose Us</li>
                        </ol>
                        <form onSubmit={this.editProduct} className="edit_device_form mb-5">
                        <h3 className="text-center mb-4">Why Choose Us</h3>
                            <h4 className="text-center text-danger mb-4 mt-2">{validationError}</h4>
                            
                            <div className="form-group">
                                <label>No. Of Products</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="product"
                                    id="product"
                                    value={product}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>No. Of Happy Customer</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="happyCustomer"
                                    id="happyCustomer"
                                    value={happyCustomer}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>No. Of App Download</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="appDownload"
                                    id="appDownload"
                                    value={appDownload}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>No. Of Kilometers Covered</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="kilometerCovered"
                                    id="kilometerCovered"
                                    value={kilometerCovered}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>No. Of Diet Plans Delivered</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="dietPlanDelivered"
                                    id="dietPlanDelivered"
                                    value={dietPlanDelivered}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>No. Of Trainer Sessions</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="trainerSession"
                                    id="trainerSession"
                                    value={trainerSession}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>No. Of Doctor Sessions</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="doctorSession"
                                    id="doctorSession"
                                    value={doctorSession}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Diet Consultation</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="diet_consultation"
                                    id="diet_consultation"
                                    value={diet_consultation}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Installation Pincodes</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="installation_pincodes"
                                    id="installation_pincodes"
                                    value={installation_pincodes}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-md-2 offset-md-5"><Button variant="info" disabled={submitDisabled} onClick={this.submitEditWhyChooseUs}>Update Stats</Button></div>
                        </div>
                        
                    </div>
                </div>
            </HOC>
        )
    }
}

export default WhyChooseUs;

