import React from 'react';
import { Button, Modal, Form, Col, Alert, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from 'moment'
let today = new Date()

const UpdateUserPopup = ({ show, selectedUserData, onHide, onChange, sendOtpFunc, otpActive, submitEditUser, validationError, editLoader, handleSelectDate, userDetails }) => {
    return <Modal show={show} centered size="lg" onHide={onHide} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>User Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={submitEditUser}>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter First Name" id='firstName' name='firstName' onChange={onChange} value={selectedUserData.firstName} />
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Last Name" id='lastName' name='lastName' onChange={onChange} value={selectedUserData.lastName} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" id='email' name='email' onChange={onChange} value={selectedUserData.email} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control as="select" onChange={onChange} value={selectedUserData.gender ? selectedUserData.gender : 0} name='gender' id='gender' >
                            <option value={0} disabled>Choose...</option>
                            <option value={1}>Male</option>
                            <option value={2}>Female</option>
                            <option value={3}>Others</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>D.O.B</Form.Label>
                        <DatePicker
                            selected={selectedUserData.dob}
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            maxDate={new Date(moment().subtract(10, 'year'))}
                            onChange={date => handleSelectDate(date, 'dob')}
                            placeholderText='Start Date'
                            dateFormat="d MMM yyyy"
                            className="mr-3 form-control"
                            showYearDropdown
                        />
                    </Form.Group>
                </Form.Row>


                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Height</Form.Label>
                        <Form.Control type="number" id='height' name='height' onChange={onChange} value={selectedUserData.height && selectedUserData.height.value} min={0} max={200} step=".01" />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Unit</Form.Label>
                        <Form.Control as="select" id='heightUnit' name='heightUnit' onChange={onChange} value={selectedUserData.height && selectedUserData.height.unit}>
                            <option disabled >Choose...</option>
                            <option value={1}>Cm</option>
                            <option value={2}>Ft</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>


                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Weight</Form.Label>
                        <Form.Control type="number" id='weight' name='weight' onChange={onChange} value={selectedUserData.weight && selectedUserData.weight.value} min={0} max={200} step=".01" />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Unit</Form.Label>
                        <Form.Control as="select" id='weightUnit' name='weightUnit' onChange={onChange} value={selectedUserData.weight && selectedUserData.weight.unit}>
                            <option disabled>Choose...</option>
                            <option value={1}>Kg</option>
                            <option value={2}>Lbs</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Preferences</Form.Label>
                        <div className="mb-3">
                            <Form.Check
                                custom
                                // inline={true}
                                label="Smoker"
                                type='checkbox'
                                id='isSmoker'
                                name='isSmoker'
                                onChange={onChange}
                                checked={selectedUserData.preferences && selectedUserData.preferences.isSmoker}
                            />
                            <Form.Check
                                custom
                                // inline={true}
                                label="Diabetes"
                                type='checkbox'
                                id='isDiabetes'
                                name='isDiabetes'
                                onChange={onChange}
                                checked={selectedUserData.preferences && selectedUserData.preferences.isDiabetes}
                            />
                            <Form.Check
                                custom
                                // inline={true}
                                label="Hyper Tension"
                                type='checkbox'
                                id='isHyperTension'
                                name='isHyperTension'
                                onChange={onChange}
                                checked={selectedUserData.preferences && selectedUserData.preferences.isHyperTension}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Col xs={3}>
                        <Form.Label>Country Code</Form.Label>
                        <Form.Control type="number" placeholder="Ex: 91 (Optional)" id='countryCode' name='countryCode' onChange={onChange} value={selectedUserData.countryCode} min={0} max={999} /> </Col>
                    <Col xs={9}>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter Mobile Number (Optional)" id='mobileNo' name='mobileNo' onChange={onChange} value={selectedUserData.mobileNo} />

                        {userDetails.mobileNo != selectedUserData.mobileNo ?
                            <Form.Text >
                                We will send an OTP on number provided for verification.
                            <span className='send_otp' onClick={sendOtpFunc}><b>{otpActive ? 'Resend OTP' : 'Send OTP'} </b> </span>
                            </Form.Text> : null}
                    </Col>
                </Form.Row>

                {otpActive && <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>OTP</Form.Label>
                        <Form.Control type="otp" placeholder="Enter OTP received." id='otp' name='otp' onChange={onChange} value={selectedUserData.otp} />
                    </Form.Group>
                </Form.Row>}

                <br />
                <Form.Row>
                    <Form.Group as={Col}>
                        <Button variant="success" type="submit">
                            {editLoader ? <Spinner animation="border" variant="warning" /> : 'Submit'}
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        </Modal.Body>
        {validationError && <Alert variant="danger"> {validationError} </Alert>}
        {/* <Modal.Footer>
        </Modal.Footer> */}
    </Modal>
}

export default UpdateUserPopup;
