import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HOC from '../../HOC';
import { getUser } from '../../config/services/usersService';
import { LoaderSvg } from '../Reusable'
import { toast } from 'react-toastify';

class Answers extends Component {



    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.id,
            propfile: {},
            questions: [],
            answers: [],
            loader: false,
        }
    }


    componentWillMount() {
        let userId = this.props.match.params.id;
        this.setState({ userId })
        this.getUserProfile();
    }

    getUserProfile() {
        let self = this;
        this.setState({loader:true})

        let id = `?id=${this.props.match.params.id}`;
        getUser(id)
            .then(res => {
                if (res.data.statusCode === 1) {
                    self.setState({
                        profile: res.data.responseData,
                        loader: false
                    })
                }else if (res.data.statusCode === 0) {
                    toast.error(res.data.error.errorMessage)
                    self.setState({
                        loader: false
                    })
                }
            })
    }



    render() {

        const { profile, loader } = this.state;

        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container cm_profile_wrapper cm_profile_wrapper_bg">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/users">Users</Link></li>
                            <li className="breadcrumb-item">profile</li>
                        </ol>

                        {
                            loader
                                ? <div className="loader_wrapper"><LoaderSvg /></div>
                                : null
                        }

                        <div className="answers_wrapper">
                            <ul>
                                <li>
                                    <p className="cm_question">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className="cm_answer">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </li>
                                <li>
                                    <p className="cm_question">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className="cm_answer">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </li>
                                <li>
                                    <p className="cm_question">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className="cm_answer">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </li>
                                <li>
                                    <p className="cm_question">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className="cm_answer">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </li>
                                <li>
                                    <p className="cm_question">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className="cm_answer">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </li>
                                <li>
                                    <p className="cm_question">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <p className="cm_answer">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </li>
                            </ul>
                        </div>


                    </div>


                </div>
            </HOC>
        )
    }
}

export default Answers;