import React, { useState, useEffect } from 'react';
import { getGyms, deleteGym } from '../../config/services/commercialService';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tag } from "antd";
import { Button, FormControl } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import Select from 'react-select';
import { SearchTypes } from './Constants';

const itemsCountPerPage = 10;
const columnDetails = [
    { fieldName: "S.No." },
    { fieldName: "Gym Name" },
    { fieldName: "Company Name" },
    { fieldName: "Gym Type" },
    { fieldName: "Gym Creation Date" },
    { fieldName: "Gym Managers" },
    { fieldName: "Address" },
    { fieldName: "City" },
    { fieldName: "State" },
    { fieldName: "Pincode" },
    { fieldName: "" }
];

const Gyms = () => {

    const initialState = {
        searchValue: "",
        searchField: "",
        apiCalled: false,
        pageNo: 0,
        pageSize: 0,
      };
    
    const [gymsList, setGymsList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalGyms, setTotalGyms] = useState(0);
    const [state, setState] = useState({ ...initialState });
    const [searchValue, setSearchValue] = useState("");
    const [searchField, setSearchField] = useState("");

    useEffect(() => {
        fetchGyms(pageNumber, searchValue, searchField);
    }, []);

    const fetchGyms = (pageNo, value, field) => {
        setState({ ...state, apiCalled: true });
        const params = {
            pageSize: itemsCountPerPage,
            pageNo: pageNo,
            searchValue: value,
            searchField: field ? field.value : undefined
        };

        getGyms({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const dataObj = response.responseData;
                    dataObj.result && dataObj.result.data && setGymsList(dataObj.result.data);
                    dataObj.result && dataObj.result.pagination && setTotalGyms(dataObj.result.pagination.totalRecords);
                    setState({
                        ...state,
                        gymsList: dataObj.result.data,
                        loading: false,
                        apiCalled: false,
                      });

                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                    setState({ ...state, apiCalled: false });
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const deleteGymFn = (gym) => {
        const params = {id: gym._id};
        deleteGym({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    fetchGyms(pageNumber, searchValue, searchField);
                    toast.success(data.message);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                    setState({ ...state, error: "Not Removed !" });
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
        fetchGyms(pageNo, searchValue, searchField);
        setState({ ...state, loading: true });
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const handleSearchData = () => {
        if (!searchValue) {
         toast.error('Please enter any value');
        }
        else{
            handlePageChange(1)
        }        
    }

    const resetFilter = () => {
        setSearchField(searchField)
        setSearchValue('')
        setPageNumber(1)
        fetchGyms(1, undefined, undefined);
    }

    return (
        <div className="main-wrapper heightAuto">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item">Gyms</li>
            </ol>
            <div className="row align-items-center">
                <div className="col-md-9 col-lg-9">
                    <li className="list-inline-item mb-3">
                        <label inline className="mr-2">Field:</label>
                        <Select
                            classNamePrefix="custom"
                            isSearchable
                            options={SearchTypes}
                            placeholder="Search Field"
                            className="cm_min_filter_width"
                            onChange={(item) => setSearchField(item)}
                            value={searchField}
                            required
                        />
                    </li>
                    <li className="list-inline-item mb-3">
                        <label inline className="mr-2">Search:</label>
                        <FormControl
                        id="search" 
                        name='search'
                        className="h-auto"
                        onChange={handleSearch}
                        value={searchValue}
                        required
                        disabled = {searchField == ''}
                        type= {searchField && searchField.value == 'pincode' ? "number" : "text"}
                        />
                    </li>

                    <li className="list-inline-item align-self-end">
                        <Button variant="primary" className="btn-reset" style={{ marginBottom: '2px' }} size='sm' onClick={handleSearchData}> <i className="fa fa-search" /></Button>
                    </li>

                    <li className="list-inline-item align-self-end">
                        <Button variant="outline-dark" className="btn-reset" style={{ marginBottom: '2px' }} size='sm' onClick={resetFilter} > <i className="fa fa-refresh" /></Button>
                    </li>
                </div>
                <div className="col-md-3 col-lg-3">
                    <div className="add-gym-button mb-3 mr-0">
                        <Button type="primary"><Link to="/addGym" style={{color: "white"}}>+ Gym</Link></Button>
                    </div>
                </div>
            </div>
            <div className="table-responsive mb-4" style={{ minHeight: '100px' }}>
                <table className="table table-bordered table-striped text-center">
                    <thead>
                        <tr>
                            {columnDetails.map((item, i) => 
                                <th scope="col" key={i}>{item.fieldName}</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {gymsList.map((gym, index) => {
                            const editParams = {
                                gymIdParam: gym._id,
                                gymNameParam: gym.name,
                                companyNameParam: gym.companyName,
                                gymTypeParam: { label: gym.type, value: gym.type },
                                addressParam: gym.address.streetAddr,
                                pincodeParam: { label: gym.address.pincode, value: gym.address.pincode },
                                pincodesListParam: [{ label: gym.address.pincode, value: gym.address.pincode }],
                                stateParam: { label: gym.address.state, value: gym.address.state },
                                cityParam: { label: gym.address.city, value: gym.address.city },
                                cityListParam: [{ label: gym.address.city, value: gym.address.city }],
                                selectedGymManagersParam: gym.managers.map((manager) => ({ label: manager.firstName, value: manager._id }))
                            };

                            return (
                                <tr key={gym._id}>
                                    <td className="cm_no_wrap">{itemsCountPerPage * (pageNumber-1) + (index + 1)}</td>
                                    <td className="cm_no_wrap"> {<Link to={{pathname:`/gym/${gym._id}`, state:{gymsRef: true}}} style={{textDecoration: "underline"}}>{gym.name}</Link>}</td>
                                    <td className="cm_no_wrap">{gym.companyName}</td>
                                    <td className="cm_no_wrap">{gym.type}</td>
                                    <td className="cm_no_wrap">{moment(gym.created).format('Do MMM YYYY')}</td>
                                    <td class="text-left">
                                        <div className="wrapper-overflow">
                                            {gym.managers && gym.managers.map((manager, i) => (
                                                <span key={i}>
                                                    <Tag color="geekblue">{manager.firstName}</Tag>
                                                </span>
                                            ))}
                                        </div>
                                    </td>
                                    <td className="cm_no_wrap">{gym.address.streetAddr}</td>
                                    <td className="cm_no_wrap">{gym.address.city}</td>
                                    <td className="cm_no_wrap">{gym.address.state}</td>
                                    <td className="cm_no_wrap">{gym.address.pincode}</td>
                                    <td className="cm_no_wrap">
                                        <Link to={{pathname:"/editGym", state:editParams}}><Button className='m-1' variant="outline-info"> <i className="fa fa-pencil" /> </Button></Link>
                                        <Button className='m-1' variant="outline-danger" onClick={() => deleteGymFn(gym)}> <i className="fa fa-trash" /> </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {!gymsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content</h3> : null}
                <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalGyms}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default Gyms;