
//1=Admin/Subadmin 2=Dietician 3=Trainer 4=Vendor, 5=Engineer
const ADMIN_ROLES = {
    ADMIN: 1,
    DIETICIAN: 2,
    TRAINER: 3,
    VENDOR: 4,
    ENGINEER: 5,
    CRM_MANAGER: 6,
};

const CONFIG_VERSIONS = {
    ADMIN_CONFIG: 'ADMIN_CONFIG'
}

//Order status Constant
const ORDER_STATUS= {
    1: 'Ordered',
    2: 'Dispatched',
    3: 'Out for delivery',
    4: 'Delivered',
    5: 'Installation scheduled',
    6: 'Installed',
    7: 'Returned',
    8: 'Replaced',
}

//Order Payment Status Constant
const ORDER_PAYMENT_STATUS={
    "1" : 'Pending',
    "0" : 'Complete',
    "-1": 'Failed'
}

//Logistic Options Status Constant
const LOGISTIC_STATUS={
    "0" : 'Both',
    "1" : 'Deliverable',
    "2" : 'Serviceable'
}

//Order Feedback Rating Name
const ORDER_RATING_OPTIONS={
    "ON_TIME_DELIVERY"       : 'onTimeDelivery',
    "PACKAGING_QUALITY"      : 'packagingQuality',
    "INSTALLATION_AND_SETUP" : 'installationAndSetup',
    "PRODUCT_QUALITY"        : 'productQuality'
}

const PlatForm = [
    { id: 1, lable: "IOS" },
    { id: 2, lable: "ANDROID" },
    { id: 3, lable: "ADMIN PANEL" },
    { id: 4, lable: "DIETICIAN PANEL" },
    { id: 5, lable: "ONE FITPLUS" },
    { id: 6, lable: "IGAMING IOS" },
    { id: 7, lable: "GAMING ANDROID" },
    { id: 8, lable: "BOT AI" },
]

const MAX_ROLES = {
    MAX_ROLES_VALUE: 100
};

const USER_STATUS = {
    ACTIVE: 1,
    INACTIVE: 2,
    ALL: 3
};


module.exports = {
    PlatForm,
    ADMIN_ROLES,
    CONFIG_VERSIONS,
    ORDER_STATUS,
    ORDER_PAYMENT_STATUS,
    LOGISTIC_STATUS,
    ORDER_RATING_OPTIONS,
    MAX_ROLES,
    USER_STATUS
}