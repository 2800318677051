import React, { Component } from 'react';
import HOC from '../../HOC';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getHolidays, addHoliday } from '../../config/services/holidays';
import { Button, Modal, Badge, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

const localizer = momentLocalizer(moment)

class Holidays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holidayList: [],
            addHolidayPopup: false,
            selectedDate: new Date(),
            reason: '',
            holidayObject: {},
            viewHolidayPopup: false
        }
    }

    componentDidMount() {
        this.getHolidayslist()
    }

    getHolidayslist = () => {
        getHolidays()
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    let holidayList = []
                    response.responseData.result.map(holiday => {
                        holidayList.push({
                            _id: holiday._id,
                            title: holiday.reason,
                            desc: holiday.reason,
                            allDay: true,
                            start: new Date(holiday.holidayDate),
                            end: new Date(holiday.holidayDate)
                        })
                    })
                    this.setState({ holidayList })
                }
            })
    }


    handleViewEvent = (event) => {
        this.setState({ holidayObject: event, viewHolidayPopup: true })
    }

    openAddHolidayPopup = () => {
        this.setState({ addHolidayPopup: !this.state.addHolidayPopup })
    }

    closeModal = () => {
        this.setState({ addHolidayPopup: false, selectedDate: new Date(), viewHolidayPopup: false, holidayObject: {} })
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    selectDate = (date) => {
        this.setState({ selectedDate: new Date(date) })
    }
    submitAddHoliday = () => {
        let { selectedDate, reason } = this.state

        let params = {
            holidayDate: selectedDate,
            reason
        }

        if (!selectedDate) {
            this.setState({ validationError: " Please select a date" })
        }
        else if (!reason) {
            this.setState({ validationError: " Please fill a reason" })
        } else {
            addHoliday(params)
                .then(res => {
                    if (res.data.statusCode == 1) {
                        this.getHolidayslist()
                        this.setState({ loader: false })
                        this.closeModal();
                        toast.success(res.data.responseData.message)
                    } else if (res.data.statusCode == 0) {
                        this.setState({
                            validationError: res.data.error.errorMessage,
                            loader: false
                        })
                    }
                })
        }
    }


    render() {
        const { holidayList, addHolidayPopup, selectedDate, reason, validationError, holidayObject, viewHolidayPopup } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="cm_page_heading">Holidays</h4>
                                    </div>

                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddHolidayPopup}>Add Holiday</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Calendar
                            onSelectEvent={this.handleViewEvent}
                            localizer={localizer}
                            events={holidayList}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 600 }}
                        />
                    </div>
                </div>



                <Modal
                    show={addHolidayPopup}
                    onHide={this.closeModal}
                    backdrop="static"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Holiday</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h6 className="text-center error_message">{validationError}</h6>

                        <div className="form-group text-center">
                            <DatePicker
                                className="form-control"
                                onChange={this.selectDate}
                                inline
                                showYearDropdown
                                selected={selectedDate}
                            />
                        </div>

                        <div className="form-group">
                            <label>Holiday reason</label>
                            <input
                                type="text"
                                className="form-control"
                                name="reason"
                                onChange={this.handleChange}
                            />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="success" onClick={this.submitAddHoliday}>Submit</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={viewHolidayPopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Holiday</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="form-group">
                            <label>Holiday For:</label>
                            <h3>{holidayObject.title}</h3>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

            </HOC>
        )
    }
}



export default Holidays;