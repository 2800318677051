import axios from 'axios';
import API from '../Api';



export function addServicePolicy(params) {
    return axios.post(API.ADD_SERVICE_POLICY, params)
}

export function updateServicePolicy(params) {
    return axios.post(API.UPDATE_SERVICE_POLICY, params)
}

export function deleteServicePolicy(params) {
    return axios.post(API.DELETE_SERVICE_POLICY, params)
}

export function getServicePolicyList(params) {
    return axios.get(API.GET_SERVICE_POLICY, params)
}
export function getServicePolicyV2(params) {
    return axios.get(API.GET_SERVICE_POLICY_V2, params)
}

export function getAllServicePolicies(params) {
    return axios.get(API.GET_ALL_SERVICE_POLICIES, params)
}

export function getServicePolicyByAdmin(params) {
    return axios.get(API.GET_SERVICE_POLICY_BY_ADMIN, {params :params} )
}