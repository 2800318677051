import axios from 'axios';
import API from '../Api';



export function getAllAds(params) {
    return axios.get(API.GET_ALL_ADS,params)
}

export function deleteAds(params) {
    return axios.delete(API.DELETE_ADS,{ data:params})
}

export function createAd(params) {
    return axios.post(API.CREATE_ADS,params)
}

export function editAds(params) {
    return axios.post(API.EDIT_ADS,params)
}


export const AdsService = {
    getAllAds,
    deleteAds,
    createAd, 
    editAds
};