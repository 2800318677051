var productCategories = [
  { label: "Treadmill", value: 0, subCategories: [] },
  { label: "Incline Trainers", value: 1, subCategories: [] },
  { label: "Ellipticals", value: 2, subCategories: [] },
  {
    label: "Bikes",
    value: 3,
    subCategories: [],
  },
  {
    label: "Cycles", value: 4, subCategories: [
      {
        label: "Mountain Bikes",
        value: 1,
      },
      {
        label: "Fat Tyre Bikes",
        value: 2,
      },
      {
        label: "Best Seller",
        value: 3,
      },
      {
        label: "New Arrivals",
        value: 4,
      },
    ]
  },
  { label: "Fitness Tracker", value: 5, subCategories: [] },
  { label: "Smart Scale", value: 6, subCategories: [] },
  { label: "Band", value: 7, subCategories: [] },
];

var tyreSizes = [
  {
    id: 1,
    label: "Fat Tyre"
  },

  {
    id: 2,
    label: "26''"
  },
  {
    id: 3,
    label: "27.5''"
  }
]

var frameMaterials = [
  {
    id: 1,
    label: 'Steel'
  },
  {
    id: 2,
    label: 'alloy'
  }
]


var gearTypes = [
  {
    id: 1,
    label: 'Geared'
  },
  {
    id: 2,
    label: 'Non Geared'
  }
]

module.exports = {
  productCategories,
  tyreSizes,
  frameMaterials,
  gearTypes
};
