import React, { Component } from "react";
import dateFormat from "dateformat";
import { LoaderSvg } from "../Reusable";
import {
  getAllHistory,
  getDietPlanHistory,
  getUserDietPlan
} from "../../config/services/dietPlanService";

import DietHistoryBody from "./DietHistoryBody";
import TabTitle from "./TabTitle";

class DietPlanHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dietHistory: [],
      currentDietPlan: [],
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.setState(
      {
        dietOfUserId: id,
      },
      () => {
        this.getAllHistory();
      }
    );
  }

  getAllHistory() {
    const { dietOfUserId } = this.state;
    const params = {
      params: {
        dietOfUserId,
      },
    };
    getAllHistory(params).then((historyData) => {
      if (
        historyData &&
        historyData &&
        historyData.data &&
        historyData.data.responseData &&
        historyData.data.responseData.dietHistory
      ) {
        this.setState(
          {
            dietHistory: historyData.data.responseData.dietHistory,
            currentDietPlan: historyData.data.responseData.currentDietPlan,
          },
          () => {
            const [history] = this.state.dietHistory;
            // if (history && history._id) {
            //   const params = {
            //     params: {
            //       dietOfUserId,
            //       dietPlanHistoryId: history._id,
            //     },
            //   };
            //   this.selectHistory(params);
            // }
            this.getUserDietPlanFunc();
          }
        );
      }
    });
  }

  selectHistoryHandler = (dietPlanHistoryId) => {
    const { dietOfUserId } = this.state;
    const params = {
      params: {
        dietOfUserId,
        dietPlanHistoryId,
      },
    };
    this.setState(
      {
        loader: true,
      },
      () => {
        this.selectHistory(params);
      }
    );
  };

  selectHistory(params) {
    getDietPlanHistory(params).then((respose) => {
      if (respose && respose.data && respose.data.responseData) {
        this.setState({
          selectedHistory: respose.data.responseData.result,
          loader: false,
        });
      }
    });
  }

  makeActive = (currenId) => {
    const { selectedHistory } = this.state;
    return selectedHistory && selectedHistory._id === currenId
      ? "nav-link active"
      : "nav-link ";
  };

  getUserDietPlanFunc = () => {
    const { dietOfUserId } = this.state;
    let param = {
      params: { dietOfUserId},
    };
    this.setState({ loader: true });
    getUserDietPlan(param).then((res) => {
      let response = res.data;
      if (response.statusCode == 1) {
        const { result } = response.responseData;
        this.setState({
          selectedHistory: result ? result : {},
          loader: false,
        });
      } else if (response.statusCode == 0) {
        this.setState({ loader: false });
      }
    });
  };

  render() {
    const { dietHistory, selectedHistory,currentDietPlan, loader } = this.state;
    return (
      <div className="user-ui-wrapper">
        <TabTitle title="Diet Plan History" />
        {!dietHistory.length  && !currentDietPlan.length ? (
          <div className="text-center">
            <span className="text-danger">No history data!</span>
          </div>
        ) : (
          <>
            <div className="user-ui-inner-wrapper">
              <div className="user-navigation">
                <div variant="pills" className="flex-column nav nav-pills">
                {currentDietPlan.map((history) => {
                      return (
                        <div className="nav-item">
                          <a
                            onClick={() =>
                              this.getUserDietPlanFunc()
                            }
                            className={this.makeActive(history._id)}
                          >
                            {history.perDayCalories} Cals,{" "}
                            {dateFormat(history.updated, "d mmm, yy")}
                          </a>
                        </div>
                      );
                    })}
                    {dietHistory.map((history) => {
                      return (
                        <div className="nav-item">
                          <a
                            onClick={() =>
                              this.selectHistoryHandler(history.value)
                            }
                            className={this.makeActive(history.value)}
                          >
                            {history.label} Cals,{" "}
                            {dateFormat(history.created, "d mmm, yy")}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="user-ui-content">
                {loader ? (
                  <div className="loader_wrapper">
                    <LoaderSvg />
                  </div>
                ) : null}
                <div className="tab-content">
                  {selectedHistory && (
                    <DietHistoryBody dietPlanDetails={selectedHistory} />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default DietPlanHistory;
