import axios from 'axios';
import API from '../Api';



export function getExerciseCategories(params) {
    return axios.get(API.GET_EXERCISE_CATEGORIES, params)
}


export function addExerciseCategories(params) {
    return axios.post(API.ADD_EXERCISE_CATEGORIES, params)
}


export function editExerciseCategories(params) {
    return axios.post(API.EDIT_EXERCISE_CATEGORIES, params)
}


export function deleteExerciseCategories(params) {
    return axios.post(API.DELETE_EXERCISE_CATEGORIES, params)
}

export function editExerciseCategory(params) {
    return axios.post(API.EDIT_EXERCISE_CATEGORY,params)
}

export function deleteExerciseCategory(params) {
    return axios.post(API.DELETE_EXERCISE_CATEGORY,params)
}


export function getFitnessExercises(params) {
    return axios.get(API.GET_FITNESS_EXERCISES+ '/' +params)
}

export function addFitnessExercise(params) {
    return axios.post(API.ADD_FITNESS_EXERCISES, params)
}


export function editFitnessExercise(params) {
    return axios.post(API.EDIT_FITNESS_EXERCISE, params)
}


export function deleteFitnessExercise(params) {
    return axios.post(API.DELETE_FITNESS_EXERCISE, params)
}

export const ExerciseService = {
    getExerciseCategories   
};