import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getFitnessPlanByAdmin } from '../../config/services/fitnessPlanService';
import { LoaderSvg } from '../Reusable';
import ExercisePlaceholder from '../../assets/images/ExercisePlaceholder.png';
import TabTitle from './TabTitle'
import FitnessPlanCard from './../../components/common/FitnessPlan';
const daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


class ExercisePlan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fitnessExercise: [],
            loader: false,
        }
    }

    componentDidMount() {
        const { id } = this.props
        this.setState({
            planOfUserId: id
        }, () => {
            this.getFitnessPlanByAdmin()
        })
    }

    getFitnessPlanByAdmin = () => {
        const { planOfUserId } = this.state
        let paramsObj = {
            params: {
                planOfUserId
            }
        }
        this.setState({ loader: true })
        getFitnessPlanByAdmin(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        fitnessExercise: res.data.responseData.result.fitnessExerciseObject,
                        loader: false
                    })
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                }
            })
    }

    render() {
        let { fitnessExercise } = this.state
        return (
            <div className="user-ui-wrapper">
                <TabTitle title="Exercise Plan" />

                <div className="row">
                    {
                        (!fitnessExercise.length) ? <div className="text-center"> <span className="text-danger">No Fitess plan assigned!</span></div> :
                            fitnessExercise.map(item => (
                                <FitnessPlanCard item={item} />
                            ))
                    }
                </div>
            </div>
        )
    }
}

export default ExercisePlan;