import axios from 'axios';
import API from '../Api';

export function addGuaranteedPlanFeature(params) {
    return axios.post(API.ADD_GUARANTEED_PLAN_FEATURE,params)
}

export function editGuaranteedPlanFeature(params) {
    return axios.post(API.EDIT_GUARANTEED_PLAN_FEATURE,params)
}

export function getGuaranteedPlanFeatureList(params) {
    return axios.get(API.GET_GUARANTEED_PLAN_FEATURES,params)
}
export function deleteGuaranteedPlanFeature(params) {
    return axios.post(API.DELETE_GUARANTEED_PLAN_FEATURE,params)
}

export function uploadFeatureImage(params) {
    return axios.post(API.UPLOAD_FEATURE_IMAGE,params)
}