import React, { useEffect, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import Smile from './../../assets/images/smile.svg'

const ListUserEmoji = ({ showListEmoji, isListEmojiVisible,individualChatData }) => {

  return (
    <>
      <Modal centered={true}
        className="send-emoji-popup" title="Received Emoji"
        footer={null} onCancel={showListEmoji}
        visible={isListEmojiVisible}
        maskClosable={false}
        width={400}
      >
        <ul className="list-unstyled emojis-list p-0 m-0 d-flex flex-column">
          {individualChatData.map((ele,i)=>(
             <li className="emoji-item" key={i}>
             <span className="emoji-image-individaul">
                 <img src={ele.emojiImageUrl} alt="emoji name" onClick={showListEmoji} />
             </span>
             <span className="emoji-count">{ele.count}</span>
         </li>
          ))}
          
          
           
        </ul>
      </Modal>
    </>
  );
};

export default ListUserEmoji;