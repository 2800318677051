import axios from 'axios';
import API from '../Api';
const accessToken = localStorage.getItem('accessToken');


export function getDeviceList(params) {
    return axios.get(API.DEVICE_GET_DEVICE, {params})
}

export function getDeviceOneDevice(params) {
    return axios.get(API.GET_DEVICE, {params})
}

function addDevice(params) {
    return axios.post(API.DEVICE_ADD_DEVICE, params)
}

function DeleteDevice(params) {
    return axios({
        method: 'post',
        url: API.DEVICE_DELETE_DEVICE,
        data: params,
        headers: {
            accessToken: accessToken,
            Authorization: API.AUTH,
           "Content-Type": "application/x-www-form-urlencoded"

        }
    })
}

function UpdateDevice(params) {
    return axios({
        method: 'post',
        url: API.DEVICE_UPDATE_DEVICE,
        data: params,
        headers: {
            accessToken: accessToken,
            Authorization: API.AUTH
        }
    })
}



export function getTotalDeviceCount(params) {
    return axios.get(API.GET_TOTAL_DEVICES, {params})
}

export const DeviceService = {
    getDeviceList,
    addDevice,
    DeleteDevice,
    UpdateDevice,
    getTotalDeviceCount,
    getDeviceOneDevice
};
