import _ from 'lodash';
import Select from 'react-select';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import { LoaderSvg } from '../Reusable';
import { Link } from 'react-router-dom';
import AddEditForm from './AddEditForm';
import Pagination from 'react-js-pagination';
import React, { useEffect, useState } from 'react';
import { getDeliverablePincode, getAllStates, getAllCitys, getWarehouse , editDeliverablePincode} from '../../config/services/servicePincodes';
import { Modal, Button } from 'react-bootstrap';

const Index = (props) => {

  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [allstates, setAllState] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [citys, setCities] = useState([]);
  const [wareHouseList, setWarehouseList] = useState([]);
  const [wareHouseListShow, setWareHouseListShow] = useState([]);

  const [addEditPopup, setAddEditPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateFormValues, setUpdateFormValues] = useState({});

  const [initialState, setInitialState] = useState({
    totalRecords: 0,
    pageRangeDisplayed: 5,
    confirmDeactivate: false,
    tempId: '',
    tempStatus: '',
  });

  //add Filter for search index

  let paramObj = {
    search: '',
    state: '',
    city: '',
    warehouse1: '',
    warehouse2: '',
    page: 0,
    limit: 25,
    sortField: 'created',
    sortType: 2,
  };
  const [param, setParam] = useState(paramObj);

  const getDeliveryPincodeList = () => {
    try {

      setLoader(true);

      let _params = {
        params: param,
      }

      getDeliverablePincode(_params).then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          setList(response.responseData.result);
          setInitialState((prevState) => ({
            ...prevState,
            totalRecords: response.responseData.totalCount
          }));
          //toast.success(response.responseData.message);
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }

        setLoader(false);
      });

    

    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  }

  const handelParam = (name, value) => {
    setParam((prevParam) => ({
      ...prevParam,
      page:0,
      [name]: value
    }));
  }


  //get search after stop input change (mention time)
  const delayedQuery = _.debounce((q) => handelParam("search",q), 800);

  const handelSearch = (e) => {
    delayedQuery(e.target.value);
  };


  const getStates = () => {

    let params = { search: '', sortField: "_id", sortType: 1, pageNo: 0, count: 1000 }
    getAllStates({ params })
      .then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          let data = res.data.responseData;

          if (data.result && data.result.length > 0) {
            let stateLists = [];
            
            //Set option for empty state
            stateLists.push({
              label: "Select State",
              value: ""
            })

            data.result.forEach(key => {
           //not set empty value in state
              if(key._id){
                  stateLists.push({
                    label: key._id,
                    value: key._id,
                  })
              }
            })

            setAllState(stateLists);
          }
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })

  }


  const handleSteteChange = (value) => {
    setParam((prevParam) => ({
      ...prevParam,
      page:0,
      state: value,
      city: ''
    }));

    if(!value){
      //set city null if state empty
      setCities([]);
    }else{
      getCities(value);
    }
  }


  const getCities = (val) => {

    setLoader(true);
    setCities([]);

    let params = { sortField: "_id", sortType: 1, pageNo: 0, count: 1000 };

    params.states = val;
    params.citySearch = '';

    getAllCitys({ params })
      .then(res => {
        if (res && res.data.statusCode === 1) {
          let data = res.data.responseData
          let citys = [];
          citys.push({
            label: "All",
            value: ""
          })

          if (data.result && data.result.length > 0) {
            data.result.forEach(cityVal => {
              citys.push({
                label: cityVal._id,
                value: cityVal._id
              })
            })
          }

          setCities(citys);
          setLoader(false);

        } else {
          console.log(res.data.error.errorMessage);
          setLoader(false);
        }
      })
  }


  const getWarehouseList = () => {
    getWarehouse()
      .then(res => {
        let response = res.data
        if (response.statusCode == 1) {
          let data = res.data.responseData;

          if (data.result && data.result.length > 0) {
            let allWarehouse = [];
            let allWarehouseShow=[];
            allWarehouse.push({
              label: "All",
              value: ""
            })

            allWarehouseShow.push({
              label: "Select Warehouse 1",
              value: ""
            })

            data.result.forEach(key => {
              allWarehouse.push({
                label: key.name,
                value: key._id,
              })

              allWarehouseShow.push({
                label: key.name,
                value: key._id,
              })
            })

            setWarehouseList(allWarehouse);
            setWareHouseListShow(allWarehouseShow);
          }
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })
  }


  //Change Status for active / deactive 
  const handleChangeStatus= (id, status)=>{

    setInitialState((prevState) => ({
      ...prevState,
      [id]: true,
      progress: 0.5,
      confirmDeactivate: true,
      tempId: id,
      tempStatus: status
    }));
  
  }

//close model
  const closeModal = () => {

    setInitialState((prevState) => ({
      ...prevState,
      confirmDeactivate: false,
      tempId: "",
      tempStatus: "",
      [initialState.tempId]: false
    }));
   
  }
  
//submit changes status
  const submitChangeStatus = (e) => {

      e.preventDefault()
    const { tempId, tempStatus } = initialState;
    let obj = {
      _id: tempId,
      isActive: tempStatus == 1 ? "0" : "1"
    }

    editDeliverablePincode(obj)
      .then((res) => {
        if (res.data.statusCode === 1) {
          closeModal();
          getDeliveryPincodeList();
          setInitialState((prevState) => ({
            ...prevState,
            [tempId]: false
          }));
        } else if (res.data.statusCode === 0) {
          console.log(res.data);
          toast.error(res.data.error.errorMessage)
        }
      }) 
  }
 


  useEffect(() => {
    getDeliveryPincodeList();
  }, [param]);

  useEffect(() => {
    getWarehouseList();
  }, []);

  useEffect(() => {
    getStates();
  }, []);



  return (
    <div className='body-container-wrapper'>
      {/* {state.apiCalled && <Loader />} */}
      <div className='body-container'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
          <li className='breadcrumb-item'>Deliverable Pincode</li>
        </ol>
        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
        <div className='users_header'>
          <div className='container-fluid'>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <h4 className="cm_page_heading">Pincode</h4>
              </div>



              <div style={{ display: 'block' }} className='row col-md-6 text-right col-sm-12'>

                <button className="btn btn-info mt-2" onClick={() => { setAddEditPopup(true) }}>Add Pincode</button>

                <Link to='/deliverablePincode/import-export' className="btn btn-info mt-2 ml-2">
                  Import / Export
               </Link>
              </div>
            </div>

            <div className='container-fluid'>
              <div className="row align-items-center">

                <ul className="col-md-12">

                  <li className="list-inline-item mt-2">
                    <Select
                      className='cm_min_width'
                      onChange={(e) => handleSteteChange(e.value)}
                      placeholder="Filter By State"
                      options={allstates}

                    />
                  </li>

                  <li className="list-inline-item mt-2">

                    <Select
                      className='cm_min_width'
                      onChange={(e) => handelParam('city', e.value)}
                      placeholder='Filter By City'
                      options={citys}
                      value={param.city ? citys.find(element => element.value === param.city) : ""}
                    />

                  </li>

                  <li className="list-inline-item mt-2">

                    <Select
                      className='cm_min_width'
                      onChange={(e) => handelParam('warehouse1', e.value)}
                      placeholder='Filter By Wh (L1)'
                      options={wareHouseList}
                      value={param.warehouse1 ? wareHouseList.find(element => element.value === param.warehouse1) : ""}
                    />

                  </li>

                  <li className="list-inline-item mt-2">

                    <Select
                      className='cm_min_width'
                      onChange={(e) => handelParam('warehouse2', e.value)}
                      placeholder='Filter By Wh (L2)'
                      options={wareHouseList}
                      value={param.warehouse2 ? wareHouseList.find(element => element.value === param.warehouse2) : ""}
                    />

                  </li>

                  <li className="list-inline-item mt-6">

                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by Pincode, city"
                      onChange={handelSearch}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className='table-responsive mt-2'>
              <table className='table table-bordered table-striped text-center'>
                <thead>
                  <tr>
                    <th scope="col" className="SrNo">Sr. No</th>
                    <th scope="col">Pincode</th>
                    <th scope="col">City</th>
                    <th scope="col">Primary Wh (L1)</th>
                    <th scope="col">Secondary Wh (L2)</th>
                    <th scope="col">ETA (L1)</th>
                    <th scope="col">ETA (L2)</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {list && list.length > 0 && list.map((data, index) => (
                    <tr key={index}>
                      <td className="SrNo"> {param.limit * param.page + (index + 1)}</td>
                      <td>{data.pincode ? data.pincode : ''}</td>
                      <td>{data.city ? data.city : ''}</td>
                      <td>{data.warehouse1 && data.warehouse1.name ? data.warehouse1.name : ''}</td>
                      <td>{data.warehouse2 && data.warehouse2.name ? data.warehouse2.name : ''}</td>
                      <td>{data.etaWarehouse1 ? data.etaWarehouse1 : ''}</td>
                      <td>{data.etaWarehouse2 ? data.etaWarehouse2 : ''}</td>
                      {/* <td>{data.isActive && data.isActive === true ? 'Active' : 'Deactive'}</td> */}
                      <td>
                      <Switch checked={data.isActive == "1"? true: false} onClick={() => handleChangeStatus(data._id, data.isActive)}  loading={initialState[data._id]}  />
                     </td>
                      <td>
                        <button
                          className="btn btn-dark btn-sm"
                          onClick={() => {
                            setEditMode(true);
                            setUpdateFormValues(data);
                            setAddEditPopup(true);      
                          }}
                        >
                          Edit
                        </button>
                        &nbsp;

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                  // pagination still under dev
                  activePage={param.page + 1}
                  itemsCountPerPage={param.limit}
                  totalItemsCount={initialState.totalRecords}
                  pageRangeDisplayed={initialState.pageRangeDisplayed}
                  onChange={(value) => setParam({...param,page:value - 1})}
                />
            </div>
           
          </div>
             <AddEditForm
            editMode={editMode}
            addEditPopup={addEditPopup} 
            updateFormValues={updateFormValues} 
            wareHouseListShow={wareHouseListShow}
            setAddEditPopup={(val) => {
              setEditMode(false);
              setAddEditPopup(val);
            }}
            getList={() => { getDeliveryPincodeList() }}
          />

        </div>
      </div>
      <Modal show={initialState.confirmDeactivate} backdrop="static" onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <h6>Are you sure you want to  {initialState.tempStatus == 1 ? ' deactivate ' : ' activate '} this pincode? </h6>
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" size='sm' onClick={closeModal}>
              Close
          </Button>
            <Button variant="primary" onClick={submitChangeStatus} size='sm'>
              {initialState.tempStatus == 1 ? ' Deactivate ' : ' Activate '}
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default Index;