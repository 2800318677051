import axios from 'axios';
import API from '../Api';

export function AddEmoji(formData) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'accessToken': localStorage.getItem("accessToken")
        }
    }
    return axios.post(API.ADD_EMOJI, formData, config)
}

export function GetEmoji(params)
{
  
    return axios.get(API.GET_EMOJI,{params:params});
}
export function GetEmojiByPlatform(params)
{
  
    return axios.get(API.GET_EMOJI_BY_PLATOFORM,{params:params});
}

export function DeleteEmoji(emojiId)
{
    return axios.post(API.DELETE_EMOJI,{emojiId});
}

export function EditEmoji(formData)
{
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'accessToken': localStorage.getItem("accessToken")
        }
    }
    return axios.post(API.EDIT_EMOJI, formData, config)
}