import React, { useState, useEffect } from 'react';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { getInventory, deleteInventoryService, downloadInventoryList, bulkUploadInventory } from '../../config/services/serviceInventory';
import { getWarehouse } from '../../config/services/servicePincodes';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import moment from 'moment';
import { LoaderSvg } from '../Reusable';
import AddEditForm from './AddEditForm';
import Pagination from "react-js-pagination";
import _ from 'lodash';
import Select from 'react-select';

let allowedFileTypes = [
  'application/vnd.ms-excel',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const Inventory = (props) => {
  const [loader, setLoader] = useState(false);
  const [importPopup, setImportPopup] = useState(false);
  const [inventoryExcelSheet, setInventoryExcelSheet] = useState([]);
  const [inventoryExcelSheetError, setInventoryExcelSheetError] = useState('');
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const openImportPopup = () => setImportPopup(true);
  const [productIds, setProductIds] = useState('');
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateFormValues, setUpdateFormValues] = useState({});
  const [inventoryState, setInventoryState] = useState({
    pageNo: 1,
    countPerPage: 10,
    search: "",
    warehouseFilter: "",
    sortType: -1,
    sortField: 'created',
  });
  const [warehouseList, setWarehouseList] = useState([]);
  const delayedQuery = _.debounce((q) => sendQuery(q), 800);
  
  // FILE UPLOAD DROP CHOOSE
  const handleFileDrop = (file) => {
    setInventoryExcelSheetError("");
    if (file && file[0] && allowedFileTypes.includes(file[0].type)) {
      setInventoryExcelSheet(file[0]);
    } else {
      setInventoryExcelSheetError("This file format is not supported!");
    }
  }
  // SUBMIT MULTIPLE INVENTORY FILE UPLOAD
  const submitMultipleUpload = (e) => {
    e.preventDefault();
    let params = new FormData();

    if (!inventoryExcelSheet) {
      setInventoryExcelSheetError('Choose a valid file!');
    } else {
      setInventoryExcelSheetError('');
      setUploadingLoader(true);
      params.append('uploadStock', inventoryExcelSheet);
      bulkUploadInventory(params)
        .then(res => {
          let response = res.data;
          if (response.statusCode === 1) {
            setUploadingLoader(false);
            if(response && response.responseData && response.responseData.result && response.responseData.result.failedSKU && response.responseData.result.failedSKU.length > 0) {
              setProductIds(response.responseData.result.failedSKU.join(", ") + ' not imported');
            } else {
              toast.success(response.responseData.message);
              setImportPopup(false);
            }
            getInventoryList(1, '', '');
          } else {
            setUploadingLoader(false);
            setInventoryExcelSheetError(response.error.errorMessage);
          }
        })
    }
  }
  // DELETE PRODUCT COUPON
  const deleteInventory = (item) => {
    deleteInventoryService(item._id).then(res => {
      if (res && res.data.statusCode === 1) {
        if (Object.keys(res.data.responseData.result).length > 0) {
          toast.success(res.data.responseData.message);
        } else {        
          getInventoryList(inventoryState.pageNo, inventoryState.search, inventoryState.warehouseFilter);
          toast.success("Record deleted successfully");
        }
      } else if (res.data.statusCode === 0) {
        // console.log("Error", res.data.error.errorMessage);
        toast.success(res.data.error.errorMessage)
      }
    });
  }
  // DOWNLOAD INVENTORY LIST
  const downloadInventory = async () => {
    try {
      setLoader(true);
      let params = {
        sortType: inventoryState.sortType,
        sortField: inventoryState.sortField,
        search: inventoryState.search,
        warehouse: inventoryState.warehouseFilter,
      };
      let res = await downloadInventoryList(params);
      var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${moment() + '-Inventory-Stock'}.xlsx`);
      setLoader(false);
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  }
  // GET INVENTORY LIST
  const getInventoryList = (page, search, warehouseFilter) => {
    setLoader(true);
    let params = {
      limit: inventoryState.countPerPage,
      // page: page ? page-1 : inventoryState.pageNo-1,
      page: page-1,
      sortType: inventoryState.sortType,
      sortField: inventoryState.sortField,
      // search: search ? search : inventoryState.search,
      search: search,
      // inventories: [],
      // warehouse: warehouseFilter ? warehouseFilter : inventoryState.warehouseFilter,
      warehouse: warehouseFilter,
    };
    getInventory({ params }).then((res) => {
      if (res && res.data && res.data.statusCode === 1) {
        setLoader(false);
        setInventoryState({
          ...inventoryState,
          // pageNo: page ? page : inventoryState.pageNo,
          pageNo: page ? page : inventoryState.pageNo,
          // search: search ? search : inventoryState.search,
          search: search,
          // warehouseFilter: warehouseFilter ? warehouseFilter : inventoryState.warehouseFilter,
          warehouseFilter: warehouseFilter,
          inventories: res.data.responseData.result,
          totalInventryCount: res.data.responseData.totalCount,
        });
      } else {
        setLoader(false);
      }
    });
  };
  // CALL DEBOUNCE METHOD ON SEARCH
  const onSearchApi = (e) => {
    delayedQuery(e.target.value);
  };
  // CALL USER LIST API WITH SEARCH DATA
  const sendQuery = (query) => {
    getInventoryList(1, query, inventoryState.warehouseFilter);
  };
  // PAGE CHANGE PAGINATION
  const handlePageChange = (pageNo) => {
    getInventoryList(pageNo, inventoryState.search, inventoryState.warehouseFilter);
  };
  useEffect(() => {
    getInventoryList(1, inventoryState.search, inventoryState.warehouseFilter);
    getWarehouse().then(res => {
      if (res && res.data && res.data.statusCode === 1) {
        let warehouseListData = [{ value: "", label: "Select Warehouse" }];
        if (res.data.responseData && res.data.responseData.result && res.data.responseData.result.length > 0) {
          res.data.responseData.result.map((res) => {
            res.value = res._id;
            res.label = res.name;
            warehouseListData.push(res);
          });
          setWarehouseList(warehouseListData);
        }
      }
    }).catch(error => { 
      console.log("erro", error);
    });
  }, []);

  return (
    <div className='body-container-wrapper'>
      {/* {state.apiCalled && <Loader />} */}
      <div className='body-container'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'><Link to='/dashboard'>Dashboard</Link></li>
          <li className='breadcrumb-item'>Inventory</li>
        </ol>
        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
        <div className='users_header'>
          <div className='container-fluid'>
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-12">
                <h4 className="cm_page_heading">Inventory</h4>
              </div>

              <div className="col-md-3 col-sm-12 text-right cm_search mt-1">
                <input
                  type="text"
                  className="form-control m-b-md-15"
                  placeholder="Search by SKU"
                  onChange={onSearchApi}
                />
                <i className="fa fa-search" />                
              </div>
              <div className="col-md-2 col-sm-12 text-left">
                <Select
                  name="warehouse"
                  placeholder="Select Warehouse"
                  options={warehouseList}
                  onChange={(val) => { getInventoryList(1, inventoryState.search, val.value)}}
                />
              </div>
              <div className='col-md-3 col-sm-12'>
                <button type="primary" className="btn btn-primary" onClick={() => { downloadInventory() }}>Export</button>
                <button type="primary" onClick={openImportPopup} className="ml-1 btn btn-primary">Import</button>
                <button className="btn btn-info ml-1" onClick={() => {setAddEditPopup(true)}}>Add Inventory</button>
              </div>
            </div>
            <div className='table-responsive mt-2'>
              {inventoryState && inventoryState.inventories && inventoryState.inventories.length > 0 ? (
                <>
                  <table className='table table-bordered table-striped text-center'>
                    <thead>
                      <tr>
                        <th scope="col" style={{width: '20px'}}>Sr. No</th>
                        <th scope="col">SKU ID</th>
                        <th scope="col">Warehouse</th>
                        <th scope="col">Inventory Stock</th>
                        <th scope="col" style={{width: '80px'}}>Action</th>
                      </tr>
                    </thead>                
                    <tbody>
                      {inventoryState.inventories.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.productCode ? data.productCode : ''}</td>
                            <td>{data.warehouse && data.warehouse.name ? data.warehouse.name : ''}</td>
                            <td>{data.qty}</td>
                            <td>
                              <button
                                className="btn btn-dark btn-sm"
                                onClick={() => {
                                  setEditMode(true);
                                  setUpdateFormValues(data);
                                  setAddEditPopup(true);                            
                                }}
                              >
                                Edit
                              </button>
                              &nbsp;
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  if (window.confirm("Are you sure to delete the record?")) {
                                    deleteInventory(data);
                                  }
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>                  
                  </table>
                  <Pagination
                    activePage={inventoryState.pageNo}
                    itemsCountPerPage={inventoryState.countPerPage}
                    totalItemsCount={inventoryState.totalInventryCount}
                    pageRangeDisplayed={inventoryState.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                </>
              ) : (
                <h3 style={{ textAlign: "center" }}>No Inventory found</h3>
              )}
            </div>
          </div>
          {/* ADD/EDIT FORM POPUP */}
          <AddEditForm
            editMode={editMode}
            addEditPopup={addEditPopup}
            warehouseList={warehouseList}
            updateFormValues={updateFormValues} 
            setAddEditPopup={(val, editModePopup, addedNewRecord) => {
              setEditMode(false);
              setAddEditPopup(val);
              if(editModePopup) {
                getInventoryList(addedNewRecord ? 1 : inventoryState.pageNo, inventoryState.search, inventoryState.warehouseFilter);
              }
            }}
            // getInventoryList={() => { getInventoryList() }}
          />
          {/* INVENTRY FILE UPLOAD POPUP */}
          <Popup open={importPopup} closeOnDocumentClick={false} onClose={() => {setImportPopup(false); setProductIds('');}}>
            <div className="cm_modal pl-5 pr-5">
              <span className="cm_modal_close" onClick={() => {setImportPopup(false); setProductIds('');}}>&times; </span>
              <h3 className="text-center mb-4">Upload an excel file.</h3>
              <p style={{color: 'red'}}>{productIds}</p>
              <div className="btn_group">
                <div className="row">
                  <div className="col">
                  {inventoryExcelSheetError &&
                    <div
                      className="text-danger text-center h3"
                    >
                      {inventoryExcelSheetError}
                    </div>
                  }
                    <Dropzone
                      multiple={false}
                      onDrop={acceptedFiles => handleFileDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                        <section>
                          <div {...getRootProps()} className="dropzone text-center">
                            <input {...getInputProps()} />
                            <i className="fa fa-cloud-upload" />
                            {isDragActive ?
                              <p>Drop it like it's hot!</p>
                              :
                              acceptedFiles.length ?
                                <p>{acceptedFiles[0].name}</p>
                                :
                                <p>Drag file or click to upload</p>}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <button
                      className="btn btn-warning btn-sm btn-block"
                      disabled={uploadingLoader}
                      onClick={submitMultipleUpload}
                    >
                      {uploadingLoader ? 'Uploading.......' : 'Upload'}
                    </button>
                  </div>
                </div>
              </div>          
            </div>
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default Inventory;