import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import TabTitle from './TabTitle'
class Questions extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { questionAnswer } = this.props
        return (
            <div className="user-ui-wrapper">
                <TabTitle title="Questions" />
                <div className="user-ui-inner-wrapper">
                    <div className="user-navigation">
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Answered Questions</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="user-ui-content">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                {
                                    (!questionAnswer.length) ? <div className="text-center"> <span className="text-danger">No Answer yet!</span></div> :
                                        <ul className="list-question-wrapper">
                                            {questionAnswer.map((questions, i) => (
                                                <li key={i} className="items-questions">
                                                    <h4 className="items-heading" >{i + 1}. {questions.question}</h4>

                                                    {questions.answer ? questions.answer.map((answers, index) => (
                                                        <p className="items-answers" key={index}>{answers}</p>
                                                    )) : ""}

                                                    <p className="items-answers" >{questions.text}</p>
                                                </li>
                                            ))}
                                        </ul>
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </div>
        );
    }
}

export default Questions;