import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { Switch } from 'antd';
import { getUserList, userChangeStatus, assignPolicies, updateKyc, assignDieticianToMultipleUsers, assignCouponToMultipleUsers, exportUserRequest } from '../../config/services/usersService';
import { updateUserCoupon, addProductPurchased } from '../../config/services/adminService';
import { getAllServicePolicies, getServicePolicyByAdmin } from '../../config/services/servicePolicyService';
import { DieticianService } from '../../config/services/dieticianService';
import { LoaderSvg } from '../Reusable'
import { productTypes } from '../constants/productTypes'
import { toast } from 'react-toastify';
import { Modal, Button, Form, Alert, Dropdown, FormCheck, ProgressBar } from 'react-bootstrap';
import { getAllTrainers, assignTrainer } from '../../config/services/trainerService';
import PopUpModal from '../../components/common/PopUpModal';
import HOC from '../../HOC';
import { ReactComponent as ViewIcon } from './../../assets/images/view.svg'
import FileSaver from 'file-saver';
import { PlatForm } from "../../utils/constants";
import ViewPolicyPopupBody from './ViewPolicyPopupBody'
import SearchSpinner from '../common/SearchSpinner';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 1,
      activePage: 0,
      users: [],
      Loader: true,
      UserSearch: '',
      sortFieldValue: '',
      FilterStatus: false,
      sortField: 'created',
      sortFieldIcon: false,
      sortType: 2,
      userId: '',
      userActiveStatus: '',
      loading: false,
      totalUserCount: 0,
      filterUsers: '',
      assignDieticianPopup: false,
      assignCouponPopup: false,
      dieticiansList: [],
      dieticianMap:new Map(),
      selectDieticianList: [],
      trainersList: [],
      selectedDietician: '',
      selectedDieticianId: '',
      assignToUserId: '',
      validationError: '',
      countsPerPage: 10,
      confirmDeactivate: false,
      tempId: '',
      tempStatus: '',
      couponCode: '',
      productsPurchased: [],
      viewProductsPopup: false,
      assignTrainerPopup: false,
      selectedTrainer: '',
      assignedPolicies: {},
      viewPolicyPopup: false,
      allServicePolicies: [],
      addPolicyPopup: false,
      policiesView: [],
      filterField: '',
      filterValue: '',
      dateFrom: null,
      dateTo: null,
      selectedUsers: [],
      multiUserAssignDieticianPopup: false,
      couponFilter: null,
      multiUserAssignCouponPopup: false,
      datefilter: 'signup',
      userPolicies: new Map(),
      apiCalled: false,
      searchLoader: false,
      dietPlanStatus: '',
      dietPlanOptions: {}
    }

    this.getUserList = this.getUserList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSortList = this.handleSortList.bind(this);
    this.toggleFilterStatus = this.toggleFilterStatus.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.timer = 1;
  }

  openAssignDieticianPopup = (userData, event) => {
    this.setState({ assignDieticianPopup: !this.state.assignDieticianPopup, assignToUserId: userData._id })
  }

  openAssignTrainerPopup = (userData, event) => {
    this.setState({ assignTrainerPopup: !this.state.assignTrainerPopup, assignToUserId: userData._id })
  }

  openAssignCouponPopup = (user) => {
    this.setState({ assignCouponPopup: !this.state.assignCouponPopup, assignToUserId: user._id })
  }

  closeModal = () => {
    this.setState({
      assignDieticianPopup: false, selectedDietician: '', assignToUserId: '', confirmDeactivate: false, tempId: '', tempStatus: '', [this.state.tempId]: false, assignCouponPopup: false, couponCode: '',
      validationError: '', productsPurchased: [], viewProductsPopup: false, assignTrainerPopup: false, selectedTrainer: '', assignedPolicies: {}, viewPolicyPopup: false, addPolicyPopup: false, policiesView: [], multiUserAssignDieticianPopup: false, multiUserAssignCouponPopup: false
    })
  }


  handleClickOutside(event) {
    if (event.target.id !== 'filter_text') {
      this.setState({
        FilterStatus: false
      })
    } else {
      this.setState({
        FilterStatus: true
      })
    }
  }


  getDieticiansList() {
    let self = this;
    const { dieticianMap } = this.state
    DieticianService.getAllDieticians()
      .then(function (res) {
        const response = res.data;
        if (response.statusCode == 1) {
          const { dieticiansList } = res.data.responseData
          //create a map _id and full name of dieticians
          for(let dietician of dieticiansList){
            dieticianMap.set(dietician._id,dietician.firstName+' '+dietician.lastName)
          }
          const activeDieticiansList = dieticiansList.filter((dietician) => {
            return dietician.status == 1
          })
          self.setState({ dieticiansList: activeDieticiansList }, () => {
            self.prepareDietitcianForSelect(self.state.dieticiansList)
          })
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })
  }

  prepareDietitcianForSelect = (dieticiansList) => {
    const selectDieticianList = dieticiansList.map((item) => ({ value: item._id, label: `${item.firstName} ${item.lastName}` }))
    selectDieticianList.unshift({ value: "", label: "All" })
    this.setState({ selectDieticianList })
  }

  selectDeitician = (dietician) => {
    this.setState({
      selectedDieticianId: dietician.value,
      pageNo: 1,
      apiCalled: true,
      Loader: true
    },
      () => {
        this.getUserList()
      }
    )
  }

  filterDietPlan = (e) => {
    this.setState({
      dietPlanStatus: e.value,
      pageNo: 1,
      Loader:true
    }, () => {
      this.getUserList()
    })
  }

  onDateFilterChange = (e) => {
    this.setState({
      datefilter: e.target.value
    })
  }

  toggleFilterStatus() {
    this.setState({
      FilterStatus: !this.state.FilterStatus
    })
    document.addEventListener('click', this.handleClickOutside);
  }

  getUserList(isExport) {
    const { countsPerPage, datefilter, selectedDieticianId, pageNo, UserSearch, sortField, sortType, filterUsers, filterField, filterValue, dateFrom, dateTo, couponFilter, dietPlanStatus } = this.state;
    let params = {
      count: countsPerPage,
      pageNo: pageNo - 1,
      search: UserSearch,
      sortField,
      sortType,
      filterUsers,
      filterField,
      filterValue,
      assignedDietician: selectedDieticianId,
      dateFrom: dateFrom ? moment(dateFrom).startOf('day').valueOf() : null,
      dateTo: dateTo ? moment(dateTo).endOf('day').valueOf() : null,
      datefilter,
      couponFilter,
      dietPlanStatus
    }

    if (isExport) {
      if (this.state.users.length > 0) {
        var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        params.tz = tz;
        exportUserRequest(params).then(res => {
          let response = res.data;
          if (response.statusCode == 1) {
            this.setState({ apiCalled: false })
            window.open(response.responseData.result.Location, "_blank");

          } else if (response.statusCode == 0) {
            this.setState({ apiCalled: false })
            toast.error(response.error.errorMessage)
          }
        })
      }
      else {
        this.setState({ apiCalled: false })
        toast.error("No data available to export")
      }
    } else {
      getUserList(params)
        .then(res => {
          if (res.data.statusCode === 1) {
            this.setState({
              users: res.data.responseData.users,
              totalUserCount: res.data.responseData.usersCount,
              activePage: res.data.responseData.length,
              Loader: false,
              apiCalled: false,
              searchLoader: false
            })
          } else if (res.data.statusCode === 1) {
            toast.error(res.data.error.errorMessage)
            this.setState({
              Loader: false,
              apiCalled: false,
              searchLoader: false
            })
          }
        })
    }
  }


  handlePageChange(pageNumber) {
    this.setState({ pageNo: pageNumber, Loader: true, }, () => {
      this.getUserList();
    });
  }

  handleUserSearch = (e) => {
    e.persist();
    this.setState({ UserSearch: e.target.value, pageNo: 1, searchLoader: true });
    clearTimeout(this.timer)
    this.timer = setTimeout(() => { this.getUserList() }, 800)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams({ search: e.target.value }).toString()
    })
  }

  handleSortList(sortField) {
    const { sortType } = this.state;
    let sortOrder = sortType === 1 ? 2 : 1
    this.setState({ sortField: sortField, sortType: sortOrder, apiCalled: true }, () => {
      this.getUserList();
      this.setState({
        sortFieldIcon: !this.state.sortFieldIcon,
      })
    });
  }


  componentDidMount() {
    let token = localStorage.getItem('accessToken');
    if (token) {
      // get search param
      const query = new URLSearchParams(this.props.location.search);
      const search = query.get('search')
      if (search !== "") {
        this.setState({
          UserSearch: search,
          pageNo: 1
        })
        // this.getUserList();
        clearTimeout(this.timer)
        this.timer = setTimeout(() => { this.getUserList() }, 800)
        console.log("one")
      } else {
        this.getUserList();
        console.log("two")
      }
    }
    this.getDieticiansList();
    this.getTrainersList();
    this.getAllServicePolicies();

    var dietPlanOptions = [
      { value: '', label: 'All' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Reject' },
      { value: 3, label: 'Approve' }
    ]

    this.setState({ dietPlanOptions: dietPlanOptions })
  }


  getAllServicePolicies = () => {
    getAllServicePolicies()
      .then(res => {
        const response = res.data;
        if (response.statusCode == 1) {
          const { result } = res.data.responseData
          result.map(policy => {
            policy.label = policy.versionName + " (" + policy && policy.customerTypeId && policy.customerTypeId.customerType + ") "
            policy.value = policy._id
          })
          this.setState({ allServicePolicies: result })
        }
        else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })
  }



  getTrainersList() {
    let self = this;
    let params = {
      params: { count: 200 }
    }
    getAllTrainers(params)
      .then(function (res) {
        const response = res.data;
        if (response.statusCode == 1) {
          const { result } = res.data.responseData
          self.setState({ trainersList: result })
        } else
          if (response.statusCode == 0) {
            toast.error(response.error.errorMessage)
          }
      })
  }


  handleChangeStatus(id, status) {
    this.setState({ [id]: true, progress: 0.5, confirmDeactivate: true, tempId: id, tempStatus: status })
  }

  submitChangeStatus = (e) => {
    e.preventDefault()
    const { tempId, tempStatus } = this.state
    let obj = {
      activeUserId: tempId,
      activeStatus: tempStatus === 1 ? 2 : 1
    }
    userChangeStatus(obj)
      .then((res) => {
        if (res.data.statusCode === 1) {
          this.closeModal()
          this.getUserList();
          this.setState({ [tempId]: false })
        } else if (res.data.statusCode === 0) {
          this.setState({ validationError: res.error.errorMessage })
        }
      })
  }

  hendleSortUsers = query => {
    this.setState({ Loader: true, filterUsers: query }, () => this.getUserList());
  }

  onDieticianSelect = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  onAssignDieticianSubmit = (e) => {
    e.preventDefault();
    const { selectedDietician, assignToUserId } = this.state
    let self = this;
    let params = {
      dieticianId: selectedDietician,
      assignToUserId
    }
    if (!selectedDietician) {
      this.setState({ validationError: "Please select a dietician" })
    } else {
      this.setState({ validationError: "" })
      DieticianService.assignDietician(params)
        .then(function (res) {
          let response = res.data;
          if (response.statusCode == 1) {
            self.closeModal();
            toast.success(response.responseData.message)
            self.getUserList();
          } else if (response.statusCode == 0) {
            toast.error(response.error.errorMessage)
          }
        })
    }
  }


  onAssignTrainerSubmit = (e) => {
    e.preventDefault();
    const { selectedTrainer, assignToUserId } = this.state
    let self = this;

    let params = {
      trainerId: selectedTrainer,
      assignToUserId
    }
    if (!selectedTrainer) {
      this.setState({ validationError: "Please select a dietician" })
    } else {
      this.setState({ validationError: "" })
      assignTrainer(params)
        .then(function (res) {
          let response = res.data;
          if (response.statusCode == 1) {
            self.closeModal();
            toast.success(response.responseData.message)
            self.getUserList();
          } else if (response.statusCode == 0) {
            toast.error(response.error.errorMessage)
          }
        })
    }
  }

  handleChange = e => {
    let { name, value } = e.target
    this.setState({ [name]: value, validationError: '' })
  }


  submitAssignCoupon = () => {
    let { couponCode, assignToUserId } = this.state
    let params = {
      couponCode,
      couponCategory: 0,
      reqOfUserId: assignToUserId
    }
    if (!couponCode) {
      this.setState({ validationError: "Please Enter Coupon Code!" })
    } else {
      updateUserCoupon(params)
        .then(res => {
          let response = res.data;
          if (response.statusCode == 1) {
            this.closeModal();
            toast.success(response.responseData.message)
            this.getUserList();
          } else if (response.statusCode == 0) {
            this.setState({ validationError: response.error.errorMessage })
          }
        })
    }
  }

  openViewProductsPopup = (user) => {
    let { productsPurchased, _id } = user
    let products = []
    productsPurchased.map(product => {
      let productObj = productTypes.find(p => p.value === product)
      products.push(productObj)
    })
    this.setState({ productsPurchased: products, viewProductsPopup: !this.state.viewProductsPopup, assignToUserId: _id })
  }

  changeProductsPurchased = (prodInfo) => {
    this.setState({ productsPurchased: prodInfo })
  }

  updateProductsPurchased = (e) => {
    e.preventDefault();
    let { productsPurchased, assignToUserId } = this.state;
    let products = [];
    let params = {
      reqOfUserId: assignToUserId,
      productsPurchased: products,
    };

    if (!productsPurchased.length) {
      this.setState({ validationError: 'Please select atleast one product type!' })
    }
    else {
      productsPurchased.map(prodInfo => {
        products.push(prodInfo.value)
      })
      addProductPurchased(params)
        .then(res => {
          let response = res.data;
          if (response.statusCode == 1) {
            this.closeModal();
            toast.success(response.responseData.message)
            this.getUserList();
          } else if (response.statusCode == 0) {
            this.setState({ validationError: response.error.errorMessage })
          }
        })
    }
  }

  openViewPolicyPopup = (user) => {
    const { userPolicies } = this.state
    let policiesView;
    if (user.servicePolicies && user.servicePolicies.servicePolicyId) {
      if (userPolicies.has(user.servicePolicies.servicePolicyId)) {
        policiesView = userPolicies.get(user.servicePolicies.servicePolicyId)
        policiesView.serviceStartDate = user.servicePolicies.serviceStartDate
        this.setState({ policiesView: policiesView, viewPolicyPopup: true, Loader: false })
      } else {
        const params = {
          serviceId: user.servicePolicies.servicePolicyId
        }
        this.setState({ Loader: true })
        getServicePolicyByAdmin(params).then((response) => {
          const { responseData } = response.data
          responseData.result.serviceStartDate = user.servicePolicies.serviceStartDate
          let policiesView = responseData.result
          userPolicies.set(user.servicePolicies.servicePolicyId, policiesView)
          this.setState({ policiesView: policiesView, viewPolicyPopup: true, Loader: false, userPolicies: userPolicies })
        }).catch((e) => {
          console.log(e)
        })
      }

    }
    this.setState({ viewPolicyPopup: true, Loader: false })
  }

  openAddPolicyPopup = (user) => {
    let assignedPolicies = user.servicePolicies ? user.servicePolicies : {}

    if (assignedPolicies && assignedPolicies.servicePolicyId && assignedPolicies.servicePolicyId.versionName) {
      assignedPolicies.servicePolicyId.label = assignedPolicies.servicePolicyId.versionName + " (" + assignedPolicies.servicePolicyId.customerTypeId.customerType + ") "
      assignedPolicies.servicePolicyId.value = assignedPolicies.servicePolicyId._id
    }
    else {
      assignedPolicies = {}
    }
    this.setState({ assignedPolicies, addPolicyPopup: true, assignToUserId: user._id })
  }

  removePolicy = (index) => {
    let assignedPolicies = Array.from(this.state.assignedPolicies)
    assignedPolicies.splice(index, 1);
    this.setState({ assignedPolicies })
  }


  handleAddPolicy = () => {
    let assignedPolicies = Array.from(this.state.assignedPolicies)
    let obj = { label: '', value: '', serviceStartDate: new Date(), servicePolicyId: {} }
    assignedPolicies.push(obj);
    this.setState({ assignedPolicies })
  }

  handleChangePolicy = (valueType, value) => {
    let { assignedPolicies } = this.state
    switch (valueType) {
      case 'policy':
        assignedPolicies = value
        break
      case 'policyDate':
        assignedPolicies.serviceStartDate = new Date(value)
        break
    }
    this.setState({ assignedPolicies })
  }

  updatePolicyAssigned = (e) => {
    e.preventDefault()
    let { assignedPolicies, assignToUserId } = this.state
    let paramsArray = []
    let params = {}

    let policy = { servicePolicyId: assignedPolicies._id, serviceStartDate: assignedPolicies.serviceStartDate }
    params = { servicePolicies: JSON.stringify(policy), requestUserId: assignToUserId }
    if (this.validateAddedPolicy(policy)) {
      assignPolicies(params)
        .then(res => {
          let response = res.data;
          if (response.statusCode == 1) {
            this.closeModal();
            toast.success(response.responseData.message)
            this.getUserList();
          } else if (response.statusCode == 0) {
            this.setState({ validationError: response.error.errorMessage })
          }
        })
    }
  }


  validateAddedPolicy = (policy) => {
    if (!policy.servicePolicyId) {
      this.setState({ validationError: 'Select a policy!' })
      return false
    } else if (!policy.serviceStartDate) {
      this.setState({ validationError: 'Select policy starting date' })
      return false
    } else {
      return true
    }
  }


  handleKycStatus = (status, user) => {
    let params = {
      requestUserId: user._id,
      Kyc: status ? 1 : 0
    }
    this.setState({ [user._id]: true })

    updateKyc(params)
      .then(res => {
        let response = res.data;
        if (response.statusCode == 1) {
          this.setState({ [user._id]: false })
          toast.success(response.responseData.message)
          this.getUserList();
        }
        else if (response.statusCode == 0) {
          this.setState({ [user._id]: false })
          toast.error(response.error.errorMessage)
        }
      })
  }

  handleFilter = (field, value) => {
    this.setState({ filterField: field, filterValue: value, apiCalled: true, pageNo: 1,Loader: true }, () => this.getUserList())
  }

  handleDateFilter = (date, dateField) => {
    switch (dateField) {
      case 'dateFrom':
        this.setState({ dateFrom: new Date(date) })
        break

      case 'dateTo':
        this.setState({ dateTo: new Date(date) })
        break
    }
  }

  handleFilterByCouponUsed = (value) => {
    this.setState({ couponFilter: value, apiCalled: true, pageNo: 1, Loader: true }, () => this.getUserList())
  }

  getDateRangeList = () => {
    let { dateFrom, dateTo } = this.state
    this.setState({ dateFrom, dateTo, pageNo: 1, apiCalled: true }, () => this.getUserList())
  }

  resetDateFilter = () => {
    this.setState({ dateFrom: null, dateTo: null, pageNo: 1, apiCalled: true }, () => this.getUserList())
  }


  handleMultipleSelect = (e) => {
    let { id, value, checked } = e.target
    const selectedUsers = [...this.state.selectedUsers];

    if (e.target.checked) {
      selectedUsers.push(id)
    } else {
      const index = selectedUsers.findIndex((userId) => userId === id);
      selectedUsers.splice(index, 1);
    }

    this.setState({ selectedUsers });
  }

  handleAssignDieticianMultipleUsers = () => {
    this.setState({ multiUserAssignDieticianPopup: true })
  }

  handleAssignCouponMultipleUsers = () => {
    this.setState({ multiUserAssignCouponPopup: true })
  }

  submitMultiUserAssignDietician = (e) => {
    e.preventDefault()
    let { selectedUsers, selectedDietician } = this.state
    let params = {
      userIdsArray: selectedUsers,
      dieticianId: selectedDietician
    }

    assignDieticianToMultipleUsers(params)
      .then(res => {
        let response = res.data;
        if (response.statusCode == 1) {
          this.closeModal();
          toast.success(response.responseData.message)
          this.getUserList();
        } else if (response.statusCode == 0) {
          toast.error(response.error.errorMessage)
        }
      })
  }

  submitMultiUserAssignCoupon = (e) => {
    e.preventDefault()
    let { selectedUsers } = this.state
    let params = { userIdsArray: selectedUsers }

    assignCouponToMultipleUsers(params)
      .then(res => {
        let response = res.data;
        if (response.statusCode == 1) {
          this.closeModal();
          toast.success(response.responseData.message)
          this.getUserList();
        }
        else if (response.statusCode == 0) {
          this.setState({ validationError: response.error.errorMessage })
        }
      })
  }

  // excel export function
  showHideExport = () => {
    this.setState({ showHideExport: true });
  }

  downloadUserList = async () => {
    try {
      this.setState({ loader: true })

      const { countsPerPage, datefilter, selectedDieticianId, pageNo, UserSearch, sortField, sortType, filterUsers, filterField, filterValue, dateFrom, dateTo, couponFilter, dietPlanStatus } = this.state;
      let params = {
        count: countsPerPage,
        pageNo: pageNo - 1,
        search: UserSearch,
        sortField,
        sortType,
        filterUsers,
        filterField,
        filterValue,
        assignedDietician: selectedDieticianId,
        dateFrom: dateFrom ? moment(dateFrom).startOf('day').valueOf() : null,
        dateTo: dateTo ? moment(dateTo).endOf('day').valueOf() : null,
        datefilter,
        couponFilter,
        dietPlanStatus,
        exportType: 'user'
      }

      var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      params.tz = tz;
      let res = await exportUserRequest(params)
      console.log('test');
      // var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // FileSaver.saveAs(blob, `${moment() + '_userlists'}.xlsx`);
      toast.success('User lists has been send to your mail. Please check your email');
      this.setState({
        loader: false,
        showHideExport: false
      })
    } catch (error) {
      console.log("error", error);
      this.setState({
        loader: false
      })
    }
  }

  render() {
    const { pageNo, apiCalled, datefilter, dieticiansList, selectDieticianList, selectedDieticianId, validationError, countsPerPage, confirmDeactivate, tempStatus, assignCouponPopup, viewProductsPopup, productsPurchased, assignTrainerPopup, trainersList, viewPolicyPopup, assignedPolicies, allServicePolicies, addPolicyPopup, policiesView, filterField, filterValue, dateFrom, dateTo, selectedUsers, multiUserAssignDieticianPopup, couponFilter, multiUserAssignCouponPopup, searchLoader, dietPlanOptions,dieticianMap } = this.state;

    return (
      <HOC>
        <div className="body-container-wrapper ">
          <div style={{ padding: "0px 10px 20px" }} className="body-container body-container-stream">
            <div className="d-sm-flex justify-content-between border-bottom custom-list">
              <ol className="breadcrumb breadcrumb-custom">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item">Users</li>
              </ol>
            </div>

            {this.state.Loader
              ? <div className="loader_wrapper"><LoaderSvg /></div>
              : null}

            <div className="users_header">
              <div style={{ padding: "0px 25px" }} className="container-fluid">

                <div className="row">
                  {/* <div className="col-md-9">
                    <h4 className="cm_page_heading">Users</h4>
                  </div> */}
                </div>

                <div className="row flex-column-reverse flex-md-row">
                  <div className="col-md-8">
                    <div className="filter-user-date-wrapper mb-0">
                      <div class="row">
                        <div class="col-md-2">
                          <label inline='true' className="mr-2 d-block">Filter By Date:</label>
                        </div>
                        <div class="col-md-10">
                          <FormCheck className="radio-custom-filter" name="datefilter" checked={((datefilter === "signup") ? true : false)} inline='true' type="radio" value="signup" label="Signup" onChange={this.onDateFilterChange} />
                          <FormCheck className="radio-custom-filter" name="datefilter" checked={((datefilter === "diet") ? true : false)} inline='true' type="radio" value="diet" label="Diet Plan" onChange={this.onDateFilterChange} />
                          <FormCheck className="radio-custom-filter" name="datefilter" checked={((datefilter === "fitness") ? true : false)} inline='true' type="radio" value="fitness" label="Fitness Plan" onChange={this.onDateFilterChange} />
                          <FormCheck className="radio-custom-filter" name="datefilter" checked={((datefilter === "lastLoginDate") ? true : false)} inline='true' type="radio" value="lastLoginDate" label="Last Login" onChange={this.onDateFilterChange} />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <ul className="d-flex align-items-center mb-0">
                            <li className="list-inline-item mt-2 mt-md-0">
                              <DatePicker
                                selected={dateFrom}
                                onChange={date => this.handleDateFilter(date, 'dateFrom')}
                                selectsStart
                                startDate={dateFrom}
                                endDate={dateTo}
                                maxDate={new Date()}
                                placeholderText='Start Date'
                                dateFormat="do MMM yyyy"
                                className="mr-3 form-control"
                              />
                            </li>
                            <li className="list-inline-item mt-2 mt-md-0" >
                              <DatePicker
                                selected={dateTo}
                                onChange={date => this.handleDateFilter(date, 'dateTo')}
                                selectsEnd
                                startDate={dateFrom}
                                endDate={dateTo}
                                minDate={dateFrom}
                                disabled={!dateFrom}
                                maxDate={new Date()}
                                placeholderText='End Date'
                                dateFormat="do MMM yyyy"
                                className="mr-3 form-control"
                              />
                            </li>

                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul className="d-flex align-items-center filter-user-btn-wrapper">
                            <li className="">
                              <Button variant="primary" className="mr-2 " onClick={this.getDateRangeList} disabled={!dateFrom || !dateTo}> Get </Button>
                            </li>
                            <li className="list-inline-item">
                              {this.state.users.length ?<Button disabled={apiCalled} className='ml-1' type="primary" onClick={this.showHideExport}>Export</Button> : null}
                            </li>
                            {/* <li className="list-inline-item">
                                {this.state.users.length ? <ExportExcel users={this.state.users} /> : null}
                              </li> */}
                            <li className="">
                              <Button variant="outline-dark" className="mr-2" onClick={this.resetDateFilter} disabled={!dateFrom && !dateTo}> <i className="fa fa-refresh" /></Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 text-right cm_search serch_progress ml-auto position-relative">
                        {searchLoader ?
                          <SearchSpinner type="text-info" />
                          : null}
                        <input
                          type="text"
                          className="form-control m-b-md-15"
                          placeholder="Search by Name or Email"
                          onChange={this.handleUserSearch}
                          value={this.state.UserSearch}
                        />
                        <i className="fa fa-search" />
                      </div>

                  <div className="col-md-12 text-left text-md-right mt-3 mt-md-0">
                    <ul className="d-flex align-items-center mb-2 flex-wrap">
                     <li className="list-inline-item text-left">
                        <div className="ditician-dropdown">
                          <label>Dietician Name:</label>
                          <Select
                            options={selectDieticianList}
                            placeholder="Select Deitician"
                            onChange={(val) => this.selectDeitician(val)}
                            value={selectDieticianList.find(act => act.value === selectedDieticianId)}
                          />
                        </div>

                      </li>
                      <li className="list-inline-item text-left">
                        <label className="">Assigned Dietician:</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="primary" id="dropdown-basic" className="w-100">
                            {filterField == 'isDieticianAssigned' && filterValue == true ? 'Assigned' :
                              filterField == 'isDieticianAssigned' && filterValue == false ? 'Not Assigned' :
                                filterField == 'isDieticianAssigned' && filterValue == null ? 'All' : 'Select Filter'
                            }
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item active={filterField == 'isDieticianAssigned' && filterValue == true} onClick={() => this.handleFilter('isDieticianAssigned', true)}>Assigned</Dropdown.Item>
                            <Dropdown.Item active={filterField == 'isDieticianAssigned' && filterValue == false} onClick={() => this.handleFilter('isDieticianAssigned', false)}>Not Assigned</Dropdown.Item>
                            <Dropdown.Item active={filterField == 'isDieticianAssigned' && filterValue == null} onClick={() => this.handleFilter('isDieticianAssigned', null)}>All</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>


                      </li>
                      <li className="list-inline-item text-left">
                        <label className="">Coupon Redeemed:</label>
                        <div>
                          <Dropdown>
                            <Dropdown.Toggle className="w-100" variant="primary" id="dropdown-basic">
                              {couponFilter == true ? 'Redeemed' :
                                couponFilter == false ? 'Not Redeemed' :
                                  couponFilter == null ? 'All' : 'Select Filter'
                              }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item active={couponFilter == true} onClick={() => this.handleFilterByCouponUsed(true)}>Redeemed</Dropdown.Item>
                              <Dropdown.Item active={couponFilter == false} onClick={() => this.handleFilterByCouponUsed(false)}>Not Redeemed</Dropdown.Item>
                              <Dropdown.Item active={couponFilter == null} onClick={() => this.handleFilterByCouponUsed(null)}>All</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </li>

                      <li className="list-inline-item text-left">
                      <div className="ditician-dropdown">
                        <label>Diet Plan Status:</label>
                        <Select
                          options={dietPlanOptions}
                          placeholder="Diet Plan Status"
                          onChange={(val) => this.filterDietPlan(val)}
                        // value={selectDieticianList.find(act => act.value === selectedDieticianId)}
                        />
                      </div>
                      </li>

                      {selectedUsers.length > 1 ?
                        <li className="list-inline-item text-left mr-2">
                          <label>Assign Dietician:</label>
                          <div> <Button className='cm_btn_width' variant="primary" onClick={this.handleAssignDieticianMultipleUsers}> Select Dietician </Button> </div>
                        </li> : null}


                      {selectedUsers.length > 1 ?
                        <li className="list-inline-item text-left mr-2">
                          <label>Assign Coupon:</label>
                          <div> <Button className='cm_btn_width' variant="primary" onClick={this.handleAssignCouponMultipleUsers}> Assign </Button> </div>
                        </li> : null}
                    </ul>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">Name <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList('firstName')} value="filterFirstName"></button></th>
                    <th scope="col" className="filter_text">Signed Up On <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList('created')} value="filterFirstName"></button></th>

                    <th scope="col"> Mobile </th>
                    <th scope="col"> Assigned Dietician </th>

                    <th scope="col" className="filter_text">Email <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`} ></i><button onClick={() => this.handleSortList('email')} value="filterEmail"></button></th>
                    <th scope="col">Gender</th>
                    <th scope="col"> Vertical </th>

                    <th scope="col">Diet Plan</th>
                    <th scope="col">Diet Plan Date</th>
                    <th scope="col">Fitness Plan</th>
                    <th scope="col">Fitness Plan Date</th>
                    <th scope="col">View Policy</th>
                    <th scope="col">Add Policy</th>
                    <th scope="col">Request For Dietician</th>
                    <th scope="col">Request For Trainer</th>
                    <th scope="col">Last Login</th>
                    <th scope="col">First Login</th>
                    <th scope="col">First Login Platform</th>
                    <th scope="col">Kyc Status</th>
                    <th scope="col" id="filter_text" className={this.state.FilterStatus ? 'filter_text open' : 'filter_text'} onClick={this.toggleFilterStatus}>
                      Status <i className="fa fa-filter" ></i>
                      <ul>
                        <li onClick={() => this.hendleSortUsers(1)}>Active User</li>
                        <li onClick={() => this.hendleSortUsers(2)}>Inactive User</li>
                        <li onClick={() => this.hendleSortUsers('')}>All User</li>
                      </ul>
                    </th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>


                <tbody>
                  {  
                    this.state.users ? (
                      this.state.users.map((user, index) => {
                        return (
                          <tr key={user._id}>
                            <td>
                              <FormCheck inline custom label={((index + 1) + ((pageNo - 1) * countsPerPage))} type='checkbox' id={user._id} onChange={this.handleMultipleSelect} />
                            </td>

                            <td>
                              <Link target="_blank" className="d-block text-truncate" style={{ width: '200px' }} to={'/profile/' + user._id} >{`${user.firstName === "" && user.lastName === "" ? '-' : `${user.firstName && user.firstName}  ${user.lastName && user.lastName}`}`}</Link>
                            </td>

                            <td>{user.created ? moment(user.created).format('DD/MM/YY') : "-"}</td>
                            <td>{`${user.mobileNo ? `+${user.countryCode} - ${user.mobileNo}` : '-'}`}</td>
                            <td>{user.assignedDietician ? dieticianMap.get(user.assignedDietician) : 'Not yet assigned'}</td>
                            <td>{user.email ? user.email : "-"}</td>
                            <td>{user.gender === 1 ? 'Male' : user.gender === 2 ? 'Female' : 'Not Set'}</td>
                            <td>{user.vertical ? user.vertical : "-"}</td>
                            {/* <td>
                              <button className="btn btn-info btn-sm btn_sm" onClick=
                                {() => this.openViewProductsPopup(user)} >
                                {user.productsPurchased && user.productsPurchased.length ? 'View' : 'Add'}</button>
                            </td> */}
                            <td>
                              {
                                (user.dietPlanDate) ? <Link to={'/dietPlans/viewDietPlan/' + user._id} className="btn btn-sm">
                                  <ViewIcon />
                                </Link> : '-'
                              }
                            </td>
                            <td>{(user.dietPlanDate) ? moment(user.dietPlanDate).format('DD/MM/YY') : '-'}</td>
                            <td>
                              {
                                (user.fitenesPlanDate) ? <Link to={'/fitnessPlan/viewFitnessPlan/' + user._id} className="btn btn-sm">
                                  <ViewIcon />
                                </Link> : '-'
                              }
                            </td>
                            <td>{(user.fitenesPlanDate) ? moment(user.fitenesPlanDate).format('DD/MM/YY') : '-'}</td>
                            <td>
                              <Button variant="default" size='sm' onClick={() => this.openViewPolicyPopup(user)}><ViewIcon /></Button>
                            </td>

                            <td>
                              <Button variant="default" size='sm' onClick={() => this.openAddPolicyPopup(user)}><ViewIcon /></Button>
                            </td>

                            <td>
                              {user.isDieticianAssigned ? <Button variant='info' onClick=
                                {this.openAssignDieticianPopup.bind(this, user)} size='sm' >Change Dietician</Button> :

                                (user.isDietCouponUsed ? <Button variant='warning' size='sm' onClick=
                                  {this.openAssignDieticianPopup.bind(this, user)}> Assign Dietician</Button> :

                                  <Button variant='success' size='sm' onClick={() => this.openAssignCouponPopup(user)}> Assign Coupon</Button>
                                )}
                            </td>

                            <td>
                              {user.isTrainerAssigned ? <Button variant='info' size='sm' onClick=
                                {this.openAssignTrainerPopup.bind(this, user)} >Change Trainer</Button> :

                                (user.isDietCouponUsed ? <Button variant='warning' size='sm' onClick=
                                  {this.openAssignTrainerPopup.bind(this, user)}> Assign Trainer</Button> :

                                  <Button variant='success' size='sm' onClick={() => this.openAssignCouponPopup(user)}> Assign Coupon</Button>
                                )}
                            </td>
                            <td className='date_time'>{(user.lastLoginDate) ? moment(user.lastLoginDate).format('DD/MM/YY hh:mm A') : '-'}</td>
                            <td className='date_time'>{(user.firstLoginDate) ? moment(user.firstLoginDate).format('DD/MM/YY hh:mm A') : '-'}</td>
                            <td className='date_time'>{(user.firstLoginPlatform) ? `${PlatForm.filter(({ id }) => id == user.firstLoginPlatform)[0].lable}` : '-'} </td>
                            <td>
                              <Switch checked={user.Kyc} onChange={(e) => this.handleKycStatus(e, user)} loading={this.state[user._id]} />
                            </td>
                            <td>
                              <Switch checked={user.status === 1} onClick={() => this.handleChangeStatus(user._id, user.status)} loading={this.state[user._id]} />
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="8"></td>
                      </tr>
                    )
                  }

                </tbody>
              </table>
              <Pagination
              activePage={this.state.pageNo}
              itemsCountPerPage={countsPerPage}
              totalItemsCount={this.state.totalUserCount}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            />
              {this.state.users.length === 0 ? <h3 className="empty_error">Sorry, we couldn't find any content for <span>{this.state.UserSearch}</span></h3> : null}
            </div>
          </div>
        </div>

        <Modal show={assignCouponPopup} onHide={this.closeModal} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title>Enter Coupon Code</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Control type="text" className="my-3" placeholder="Start typing here......"
              maxLength={6} name="couponCode" onChange={this.handleChange} />
            <div className="d-flex mt-3 justify-content-end">
              <Button variant="danger" onClick={this.closeModal}>
                Close
              </Button>
              <Button variant="primary" className="ml-3" onClick={this.submitAssignCoupon}>
                Submit
              </Button>
            </div>
            {validationError && <Alert variant="danger"> {validationError} </Alert>}
          </Modal.Body>
        </Modal>



        <Modal show={assignTrainerPopup} onHide={this.closeModal} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title>Select Trainer</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form className="mb-5" onSubmit={this.onAssignTrainerSubmit}>
              <select className="form-control" onChange={this.handleChange} name="selectedTrainer">
                <option selected disabled> Select..... </option>
                {trainersList.map((trainer, index) => (
                  <option key={index} value={trainer._id} > {trainer.firstName + " " + trainer.lastName} </option>
                ))}
              </select>
              <button className="btn btn-primary btn-block mt-4" type="submit">Assign</button>
            </form>
            {validationError && <Alert variant="danger"> {validationError} </Alert>}
          </Modal.Body>
        </Modal>




        <Modal show={confirmDeactivate} onHide={this.closeModal} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <h6>Are you sure, you want to  {tempStatus == 1 ? ' deactivate ' : ' activate '} this user? </h6>
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" size='sm' onClick={this.closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.submitChangeStatus} size='sm'>
              {tempStatus == 1 ? ' Deactivate ' : ' Activate '}
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.assignDieticianPopup} backdrop="static" onHide={this.closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Assign Dietician</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="my-3" onSubmit={this.onAssignDieticianSubmit}>
              <select className="form-control" onChange={this.onDieticianSelect} name="selectedDietician">
                <option selected disabled> Select..... </option>
                {dieticiansList.map((dietician, index) => (
                  <option key={index} value={dietician._id} > {dietician.firstName + " " + dietician.lastName} </option>
                ))}
              </select>
              <div className="d-flex justify-content-center mt-3">
                <button className="btn btn-primary mx-auto" type="submit">Assign</button>
              </div>
            </form>
            {validationError && <Alert variant="danger"> {validationError} </Alert>}
          </Modal.Body>
        </Modal>



        <Modal show={multiUserAssignDieticianPopup} backdrop="static" onHide={this.closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select Dietician</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form className="mb-5" onSubmit={this.submitMultiUserAssignDietician}>
              <select className="form-control" onChange={this.onDieticianSelect} name="selectedDietician">
                <option selected disabled> Select..... </option>
                {dieticiansList.map((dietician, index) => (
                  <option key={index} value={dietician._id} > {dietician.firstName + " " + dietician.lastName} </option>
                ))}
              </select>
              <button className="btn btn-primary btn-block mt-4" type="submit">Assign</button>
            </form>
            <h6 className="text-center mb-2 mt-2 error_message">{validationError}</h6>
          </Modal.Body>
        </Modal>


        <Modal show={viewProductsPopup} onHide={this.closeModal} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title>Products Purchased</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              isSearchable
              isMulti
              placeholder='No products purchased yet......'
              value={productsPurchased}
              options={productTypes}
              onChange={(val) => this.changeProductsPurchased(val)}
            />
            {validationError && <Alert variant="danger"> {validationError} </Alert>}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={this.closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.updateProductsPurchased} >
              Update
            </Button>
          </Modal.Footer>
        </Modal>




        <Modal show={viewPolicyPopup} onHide={this.closeModal} backdrop="static" centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Assigned Policy Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewPolicyPopupBody policiesView={policiesView} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={this.closeModal}> Close </Button>
          </Modal.Footer>
        </Modal>



        <Modal show={addPolicyPopup} onHide={this.closeModal} backdrop="static" centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Policies Assigned To User</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h4 className="cm_pos_rel col-12">Policy</h4>
            <div className="row">
              <div className="form-group col-8">
                <label>Policy Name</label>
                <Select
                  isSearchable
                  placeholder='Select Policy'
                  value={assignedPolicies.servicePolicyId}
                  options={allServicePolicies}
                  onChange={(val) => this.handleChangePolicy('policy', val)}
                />
              </div>

              <div className="form-group col-4">
                <label>Start Date</label>
                <DatePicker
                  className="form-control"
                  onChange={(val) => this.handleChangePolicy('policyDate', val)}
                  showYearDropdown
                  dateFormat="do MMM yyyy"
                  placeholderText="Start Date"
                  selected={assignedPolicies.serviceStartDate}
                />
              </div>
            </div>
          </Modal.Body>
          {validationError && <Alert variant="danger"> {validationError} </Alert>}
          <Modal.Footer>
            <Button variant="success" size='sm' onClick={this.updatePolicyAssigned} >Update </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={multiUserAssignCouponPopup} backdrop="static" onHide={this.closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title><h6> Are you sure, you want to assign coupons to selected users? </h6></Modal.Title>
          </Modal.Header>
          {validationError && <Alert variant="danger"> {validationError} </Alert>}
          <Modal.Footer>
            <Button variant="success" size='sm' onClick={this.submitMultiUserAssignCoupon} >Assign </Button>
            <Button variant="danger" size='sm' onClick={this.closeModal} >Cancel </Button>
          </Modal.Footer>
        </Modal>

        <PopUpModal isOpen={this.state["showHideExport"]} closeModal={() => this.setState({ showHideExport: false })} submit={this.downloadUserList} message={`Are you sure to export`} submitTitle="Export" />

      </HOC>
    );
  }
}

export default withRouter(Users);