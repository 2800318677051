import axios from 'axios';
import API from '../Api';



export function getAllQuestions(params) {
    return axios.get(API.GET_ALL_QUESTIONS,params)
}


export function createQuestion(params) {
    return axios.post(API.CREATE_QUESTION,params)
}


export function changeQuestionStatus(params) {
    return axios.post(API.CHANGE_QUESTION_STATUS,params)
}

export function getUserAnswers(params) {
    return axios.get(API.GET_USER_ANSWER_BY_ADMIN,params)
}

export const QuestionaireService = {
   getAllQuestions,
   createQuestion,
   changeQuestionStatus
};