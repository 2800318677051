import React, { Component } from 'react';
import HOC from '../../HOC';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import ToolBar from 'react-big-calendar/lib/Toolbar';
import dates from 'react-big-calendar/lib/utils/dates';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getUserTimeSlotsByAdmin } from '../../config/services/adminService';
import { getTimeSlotList } from '../../config/services/adminTimeSlotService';
import { getAllTrainers } from '../../config/services/trainerService';
import { Button, Modal, Badge, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Select from 'react-select'
import { LoaderSvg } from '../Reusable'

const localizer = momentLocalizer(moment)

class TrainerSchedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trainerName: '',
            trainerCategoryId: '',
            view: 'week',
            loader: true,
            count: 1000,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            dateFrom: '',
            dateTo: '',
            expertId: '',
            serviceId: '',
            filterStatus: null,
            filterByInvoice: null,
            callSchedules: [],
            trainersList: [],
            selectedFilterService: '',
            setTimeSlotInterval: 30,
            setStartTIme: 5,
            setEndTime: 22,
        }

        this.showTrainerSchedule = this.showTrainerSchedule.bind(this);
    }

    componentDidMount() {
        let expertId= this.props.match.params.id;

        let start, end;
        var current_Date = new Date();
        start = moment(current_Date).startOf('week')
        end = moment(current_Date).endOf('week')
        var start_date = Date.parse(start._d);
        var end_date = Date.parse(end._d);

        this.setState({ expertId: expertId, dateFrom: start_date, dateTo: end_date }, function() {
            this.setState({ loader: true })
            this.getTimeSlotLists();            
        })     
    }

    getTimeSlotLists = () => {
        let paramsObj = {}
        getTimeSlotList(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    if(res.data.responseData.result && res.data.responseData.result.categorytimeSlots) {
                        let categorytimeSlots = res.data.responseData.result.categorytimeSlots;
                        this.setState({ categorytimeSlots: categorytimeSlots })
                    }                    
                } 
                // this.setState({ loader: false })
                this.getTrainersList()
            })
    }

    getTrainersList() {
        let self = this;
        let { expertId } = this.state;
        let params = {
            params: { count: 1000, isLive: 0 }
        }
        getAllTrainers(params)
            .then(res => {
                const response = res.data;
                if (response.statusCode == 1) {
                    const { result } = response.responseData
                    let trainersList = []
                    result.map(expertDetails => {
                        if(expertId == expertDetails._id) {  
                            this.state.categorytimeSlots.map(e => {
                                if(e.categoryId == expertDetails.serviceCategoryId) {
                                    console.log('rkk e::',e);
                                    this.setState({ 
                                        setStartTIme: Math.round(parseInt(e.startTime)),
                                        setEndTime: (Math.round(parseInt(e.endTime)) - 1),
                                        setTimeSlotInterval: e.slot 
                                    })
                                }                                                          
                            })   
                        }
                        trainersList.push({ label: `${expertDetails.firstName} ${expertDetails.lastName}`, value: expertDetails._id, serviceCategoryId: expertDetails.serviceCategoryId })
                    })                    
                    
                    trainersList.map(trainer => {            
                        if(expertId == trainer.value) {                            
                            this.setState({trainerName: trainer.label, selectedFilterService: trainer, trainerCategoryId: trainer.serviceCategoryId})
                        }
                    });

                    self.setState({ trainersList }, function() {
                        this.getUserTimeSlots();
                    })                    
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
    }

    showTrainerSchedule = (filterValue) => {
        this.setState({ setTimeSlotInterval: 30 })
        let { expertId, trainersList} = this.state;
        trainersList.map(trainer => {            
            if(filterValue.value == trainer.value) {                
                this.state.categorytimeSlots.map(e => {
                    if(e.categoryId == trainer.serviceCategoryId) {
                        this.setState({ setTimeSlotInterval: e.slot })
                    }                                                          
                })  

                this.setState({trainerName: trainer.label, selectedFilterService: trainer, trainerCategoryId: trainer.serviceCategoryId},function() {
                    this.props.history.push(`/trainers/schedule/${filterValue.value}`)
                    this.setState({ expertId: filterValue.value }, function() {
                        this.setState({ loader: true })
                        this.getUserTimeSlots()
                    })  
                })
            }
        });           
    }

    getUserTimeSlots = () => {
        let { trainerCategoryId, dateFrom, dateTo, expertId } = this.state;

        var categoryId = trainerCategoryId;
        var dayOfWeek = 'all';
        let paramsObj = {
            params: {
                dateFrom, dateTo, expertId, categoryId, dayOfWeek
            }
        }       

        getUserTimeSlotsByAdmin(paramsObj)
            .then(res => {
                let response = res.data;                         
                if (response.statusCode == 1) {                     
                    let getTimeSlotInfo = response.responseData.result;
                    let getCallSchedules = response.responseData.callSchedule;
                    var allTS = [];
                    var userbookslots = [];
                    
                    getCallSchedules.map((callSchedule, key) => { 
                        const get_callTimeSlot = callSchedule.callTimeSlot.split('-');
                        var book = {};
                        book.userId = callSchedule.userDetails._id;
                        book.title = callSchedule.userDetails.firstName+' '+callSchedule.userDetails.lastName;
                        book.start = new Date(moment(callSchedule.callDate).format('MMMM DD YYYY')+' '+get_callTimeSlot[0]+':00');
                        book.end = new Date(moment(callSchedule.callDate).format('MMMM DD YYYY')+' '+get_callTimeSlot[1]+':00');
                        if(callSchedule.status == 2) {
                            book.colorcode = '#FF0000'; 
                            book.redirect = false;
                        } else {
                            book.colorcode = '#56A5EC'; 
                            book.redirect = true;
                        }                                             
                        var setKey = book.start.getTime()+'-'+book.end.getTime()
                        userbookslots[setKey] = book;
                    });

                    // var userbookslot = new Date(moment(dateFrom).format('MMMM DD YYYY'));  ;
                    // userbookslot.setDate(userbookslot.getDate() + 1);
                    // var book = {};
                    // book.userId = '5fa521da1e57885f13143f62';
                    // book.title = 'Ritesh Kejriwal';
                    // book.start = new Date(moment(userbookslot).format('MMMM DD YYYY')+' '+'07:00:00');
                    // book.end = new Date(moment(userbookslot).format('MMMM DD YYYY')+' '+'07:30:00');
                    // book.colorcode = '#0000FF';                      
                    // var setKey = book.start.getTime()+'-'+book.end.getTime()
                    // userbookslots[setKey] = book;

                    getTimeSlotInfo.map((ts, key) => {                                                
                        if(key >= 1 && key <= 7) {
                            var addDay = key - 1;
                            var setDate = new Date(moment(dateFrom).format('MMMM DD YYYY'));                            
                            setDate.setDate(setDate.getDate() + addDay);
                            if(ts.slot == "available" ) {                                
                                ts.timeslot.map(showtimeslot => {
                                    var callTimeSlot = showtimeslot.label;
                                    const get_callTimeSlot = callTimeSlot.split('-');

                                    var schedule = {};
                                    schedule.start = new Date(moment(setDate).format('MMMM DD YYYY')+' '+get_callTimeSlot[0]+':00');
                                    schedule.end = new Date(moment(setDate).format('MMMM DD YYYY')+' '+get_callTimeSlot[1]+':00');
                                    var setKey = schedule.start.getTime()+'-'+schedule.end.getTime();                                  
                                    if(showtimeslot.selected) {
                                        if(userbookslots[setKey]) {
                                            allTS.push(userbookslots[setKey]);  
                                        } else {
                                            schedule.colorcode = '#008000';
                                            schedule.redirect = false;
                                            allTS.push(schedule); 
                                        }                                        
                                    } else {
                                        schedule.colorcode = '#ffffff';
                                        schedule.redirect = false;
                                        allTS.push(schedule); 
                                    }                                                       
                                })
                            }
                            if(ts.slot == "leave" ) {
                                var schedule = {};
                                schedule.allDay = true;
                                schedule.colorcode = '#FF1493'; 
                                schedule.title = 'Leave';
                                schedule.start = new Date(moment(setDate).format('MMMM DD YYYY'));
                                schedule.end = new Date(moment(setDate).format('MMMM DD YYYY'));      
                                schedule.redirect = false;                         
                                allTS.push(schedule);  
                            }
                            if(ts.slot == "holiday" ) {
                                var schedule = {};
                                schedule.allDay = true;
                                schedule.colorcode = '#9796F2'; 
                                schedule.title = 'Holiday';
                                schedule.start = new Date(moment(setDate).format('MMMM DD YYYY'));
                                schedule.end = new Date(moment(setDate).format('MMMM DD YYYY'));    
                                schedule.redirect = false;                           
                                allTS.push(schedule);  
                            } 
                        }                                            
                    });

                    this.setState({ callSchedules:allTS, loader: false })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    onNavigate = (date, view, action) => {
        let start, end;
        if (view === 'week') {
            start = moment(date).startOf('week')
            end = moment(date).endOf('week')
            var start_date = Date.parse(start._d);
            var end_date = Date.parse(end._d);
            this.setState({ dateFrom: start_date, dateTo: end_date }, function() {
                this.setState({ loader: true })
                this.getUserTimeSlots()
            })   
        }
    }
    
    eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event.colorcode;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }
    
    onEventClick = (event) => {
        if(event.redirect) {
            this.props.history.push('/profile/'+event.userId)
        } 
        return;
    }

    render() {
        const { loader, selectedFilterService, trainerName, trainersList, callSchedules, setStartTIme, setEndTime} = this.state;
        let today = moment();
        let start_time = today.set('hour', setStartTIme).set('minutes', 0).toDate();
        let end_time = today.set('hour', setEndTime).set('minutes', 0).toDate();

        return (
            <HOC>
                {
                    loader
                    ? <div className="loader_wrapper"><LoaderSvg /></div>
                    : null
                }
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">{trainerName} Schedule</h4>
                                    </div>
                                    <div className="col-md-6">
                                        <Select isSearchable options={trainersList} placeholder="Expert's Name"  onChange={(val) => this.showTrainerSchedule(val)}  value={selectedFilterService}/> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'partia_space_' + this.state.setTimeSlotInterval}>
                            <Calendar
                                localizer={localizer}
                                events={callSchedules}       
                                style={{ height: 900 }}                               
                                 views={['week']}
                                view={this.state.view}
                                min={start_time}
                                max={end_time}
                                // timeslots={4}
                                // step={15}                            
                                components = {{toolbar : CustomCalendarToolbar}}
                                onNavigate={this.onNavigate}
                                eventPropGetter={(this.eventStyleGetter)}
                                onSelectEvent={event => this.onEventClick(event)}
                            />
                        </div>
                    </div>
                </div>               

            </HOC>
        )
    }
}


class CustomCalendarToolbar extends ToolBar {
    render() {
      return (
        <div className='rbc-toolbar'>
          <span className="rbc-btn-group">
            <button type="button" onClick={() => this.navigate('TODAY')} >today</button>
            <button type="button" onClick={() => this.navigate('PREV')}>back</button>
            <button type="button" onClick={() => this.navigate('NEXT')}>next</button>
          </span>
          <span className="rbc-toolbar-label">{this.props.label}</span>
        </div>
      );
    }
  
    navigate = action => {
        this.props.onNavigate(action)
    }
}

export default TrainerSchedule;