import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getAllSessionsByAdmin } from '../../config/services/CallSchedules';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import { Modal, Button } from 'react-bootstrap';

class TrainerRatings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            callSchedules: [],
            search: '',
            count: 10,
            totalScheduledCalls: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "callDate",
            dateFrom: null,
            dateTo: null,
            selectedDate: null,
            expertId: props.match.params.id,
            viewReviewPopup: false
        }
    }

    componentDidMount() {
        this.getAllSessions();
    }

    getAllSessions = () => {
        let { search, count, pageNo, sortType, sortField, dateFrom, dateTo, expertId } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField, dateFrom, dateTo, expertId
            }
        }
        getAllSessionsByAdmin(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        callSchedules: response.responseData.result,
                        totalScheduledCalls: response.responseData.totalCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    closeModal = () => this.setState({
        viewReviewPopup: false, review: ''
    });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getAllSessions());

    handleSearch = e => this.setState({ search: e.target.value, pageNo: 1 }, () => this.getAllSessions());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getAllSessions() });


    getDataByDate = (date) => {
        let dateFrom = moment(date).startOf('day').valueOf()
        let dateTo = moment(date).endOf('day').valueOf()
        let selectedDate = moment(date).startOf('day').valueOf()

        this.setState({ dateFrom, dateTo, selectedDate, loader: true }, () => {
            this.getAllSessions()
        })
    }

    viewReview = (review) => {
        this.setState({ review, viewReviewPopup: true })
    }

    resetFilter = () => {
        this.setState({
            Loader: true, search: '', pageNo: 1, sortType: -1, sortField: "callDate", dateFrom: null, dateTo: null, selectedDate: null
        }, () => this.getAllSessions())
    }

    render() {
        const {
            loader,
            callSchedules,
            search,
            pageNo,
            count,
            totalScheduledCalls,
            dateFrom,
            dateTo,
            selectedDate,
            review,
            viewReviewPopup
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/trainers">Trainers</Link></li>
                            <li className="breadcrumb-item">Sessions's Ratings</li>
                         </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading">Ratings And Reviews</h4>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="row">
                                            Selected Date:
                                            <div className="col">
                                                <DatePicker
                                                    className="form-control"
                                                    onChange={this.getDataByDate}
                                                    selected={selectedDate ? new Date(selectedDate) : ''}
                                                    placeholderText={"Go to particular date"}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search By Name Or Email..."
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>

                                    <div className="col-md-1 text-right">
                                        <Button variant="outline-dark" className="mr-2" size='sm' onClick={this.resetFilter} > <i className="fa fa-refresh" /></Button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('firstName')}>User's Name &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('email')}>User's Email &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('callDate')}>Call Date &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" >Call Time Slot </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('rating')}>Rating &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" >Review </th>
                                        <th scope="col">Report </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        callSchedules.map((schedule, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.userDetails.firstName + " " +
                                                            schedule.userDetails.lastName}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {schedule.userDetails.email}</span>
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {moment(schedule.callDate).format('Do MMM YYYY')}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {schedule.callTimeSlot}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <StarRatings
                                                            rating={schedule.rating}
                                                            starDimension="20px"
                                                            starSpacing="8px"
                                                            starRatedColor='rgba(255,108,11,1)'
                                                        />
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots cm_pointer" title='View Review' onClick={() => this.viewReview(schedule.review)}>
                                                            {schedule.review}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots" title={schedule.isReported ? schedule.reportDetails && schedule.reportDetails.complaint : 'Not Reported'}>
                                                            {schedule.isReported ? schedule.reportDetails && schedule.reportDetails.complaint : 'Not Reported'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalScheduledCalls}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!callSchedules.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Modal show={viewReviewPopup} onHide={this.closeModal} centered>
                    <Modal.Header closeButton> <Modal.Title>Review</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body ">
                                {review}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </HOC>
        )
    }
}



export default TrainerRatings;