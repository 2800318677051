const ServiceBlockMenu = [
    { level1: 'BlockOption', value: "Hide Action", label: "Hide Action", title: "Hide Action" },
    { level1: 'BlockOption', value: "Hide Generic Import", label: "Hide Generic Import", title: "Hide Generic Import" },
    { level1: 'BlockOption', value: "Hide Add New Service", label: "Hide Add New Service", title: "Hide Add New Service" },
    { level1: 'BlockOption', value: "Hide Import", label: "Hide Import", title: "Hide Import" },
    { level1: 'BlockOption', value: "Hide Bulk Edit", label: "Hide Bulk Edit", title: "Hide Bulk Edit" },
    { level1: 'BlockOption', value: "Hide Export", label: "Hide Export", title: "Hide Export" },
    { level1: 'BlockOption', value: "Hide Service Type", label: "Hide Service Type", title: "Hide Service Type" },
    { level1: 'BlockOption', value: "Hide Service Category", label: "Hide Service Category", title: "Hide Service Category" },
    { level1: 'BlockOption', value: "Hide Cancel Status", label: "Hide Cancel Status", title: "Hide Cancel Status" },
    { level1: 'BlockOption', value: "Coco Fofo Only", label: "Coco Fofo Only", title: "Coco Fofo Only" },
    { level1: 'BlockOption', value: "Hide Assign CRM Agent", label: "Hide Assign CRM Agent", title: "Hide Assign CRM Agent" },
]
    
module.exports = {
    ServiceBlockMenu: ServiceBlockMenu
}

