import axios from 'axios';
import API from '../Api';

export function addCoins(params) {
    return axios.post(API.ADD_COINS, params)
}

export function updateCoinsWeightage(params) {
    return axios.post(API.EDIT_COINS_WEIGHTAGE, params)
}

export function deleteCoinsWeightage(params) {
    return axios.post(API.DELETE_COINS_WEIGHTAGE, params)
}

export function getCoinsWeightage(params) {
    return axios.get(API.GET_COINS_WEIGHTAGE, params)
}