var productSpecifications = [
    { label: "Max User Weight",      value: 'maxUserWeight'},
    { label: "Resistance level",    value: 'resistancelevel' },
    { label: "Fly Wheel Weight",     value: 'flyWheelWeight' },
    { label: "Heart Rate",          value: 'heartRate' },
    { label: "Display Feature",     value: 'displayFeature' },
    { label: "Seat Adjustment",     value: 'seatAdjustment' },
    { label: "Mobile Holder",       value: 'mobileHolder'},
    { label: "Water Bottle Holder",  value: 'waterBottleHolder' },
    { label: "Speed",              value: 'speed'},
    { label: "Gear",               value: 'gear'},
    { label: "Frame Material",      value: 'frameMaterial'},
    { label: "Shifter Type",        value: 'shifterType'},
    { label: "Derailleur",         value: 'derailleur'},
    { label: "Brakes",             value: 'brakes'},
  ]

module.exports = { productSpecifications }