var BlogTypes = [
    { label: "Nutritionist",     value: 0 },
    { label: "Equipments",       value: 1 },
    { label: "Exercise",         value: 2 },
    { label: "Medical Advisory", value: 3 },
    { label: "Game",             value: 4 },
    { label: "Smart Band",       value: 5 },
    { label: "Alexa",            value: 6 },
    { label: "Weighing Scale",   value: 7 },
    { label: "HealthCoins",      value: 8 }, 
  ]
// 0=Nutritionist//1=Equipments//2=Exercise//3=MedicalAdvisory4=Game5=SmartBand6=Alexa/GoogleHome7=WeighingScale8=HealthCoins

module.exports = { BlogTypes }

