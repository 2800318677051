import axios from 'axios';
import API from '../Api';

export function getNoteList(params) {
    return axios.get(API.GET_NOTE, params)
}

export function addNote(params) {
    return axios.post(API.ADD_NOTE, params)
}

export const ProductsService = {
    getNoteList,
    addNote
};