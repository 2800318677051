import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable'
import Popup from "reactjs-popup"
import { Link } from 'react-router-dom';
import { DieticianService } from '../../config/services/dieticianService';
import { toast } from 'react-toastify';
import SearchSpinner from '../common/SearchSpinner';
import { USER_STATUS } from '../../utils/constants';
import FileSaver from 'file-saver';
import moment from "moment";

class Dieticians extends Component {
  constructor(props) {
    super(props);

    this.searchTimeOut = null;
    this.state = {
      openAddDieticiansPopup: false,
      openViewDietician: false,
      viewDieticianDetails: {},
      dietciansList: [],
      searchTimeOut: 0,
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      mobileNo: "",
      experience: "",
      loader: true,
      validationError: "",
      sortType: 1,
      sortField: '',
      pageNo: 1,
      countsPerPage: 15,
      totalDieticians: 20,
      searchLoader: false,
      currentStatus: "",
      pageRangeDisplayed: 2,
      userStatus:[{key: 'ACTIVE', value: USER_STATUS.ACTIVE}, {key: 'INACTIVE', value: USER_STATUS.INACTIVE}, {key: 'ALL', value: USER_STATUS.ALL}]
    }
  }

  handleAddDieticiansPopup = () => this.setState({ openAddDieticiansPopup: !this.state.openAddDieticiansPopup })
  closeModal = () => this.setState({
    openAddDieticiansPopup: false,
    openViewDietician: false,
    viewDieticianDetails: {},
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobileNo: "",
    validationError: "",
    experience: "",
  })


  componentDidMount() {
    let params = {
      params: {
        count: this.state.countsPerPage,
        sortField: 'created',
        sortType: -1,
      }
    }
    this.getAllDieticians(params);
  }

  handlePagination = (pageNumber) => this.setState({ pageNo: pageNumber }, () => {
    let params = {
      params: {
        pageNo: this.state.pageNo - 1,
        count: this.state.countsPerPage,
        search: this.state.searchText
      }
    }
    if (this.state.currentStatus != USER_STATUS.ALL) {
       params.params.filterUsers = this.state.currentStatus
    }
    this.getAllDieticians(params)
  });

  handleStatusChange = e => {
           this.setState({ [e.target.name]: e.target.value, titleErrorMsg: '' });
           // Change the current page no to 1 if there is a status change
           this.state.pageNo = 1;
           let params = {
              params: {
                search: this.state.searchText,
                count: this.state.countsPerPage,
                pageNo: this.state.pageNo - 1,
                sortField: 'created',
                sortType: -1,
              }
           }
           if (e.target.value != USER_STATUS.ALL) {
              // INACTIVE/ACTIVE
              params.params.filterUsers = parseInt(e.target.value);
           }
           this.getAllDieticians(params);
  }

  getAllDieticians(params) {
    let self = this;
    // this.setState({ loader: true })

    DieticianService.getAllDieticians(params)
      .then(function (res) {
        const response = res.data

        if (response.statusCode == 1) {
          self.setState({ dietciansList: response.responseData.dieticiansList, totalDieticians: response.responseData.totalCount, loader: false, searchLoader: false })
          // Set the pageRangeDisplayed variable value here
          /*let pageRangeDisplayedVariable = parseInt(self.state.totalDieticians / self.state.countsPerPage)
          if (self.state.totalDieticians % self.state.countsPerPage != 0) {
            pageRangeDisplayedVariable++;
          }
          self.state.pageRangeDisplayed = pageRangeDisplayedVariable*/
        }
      })
  }



  handleChange = (e) => {
    const { name, value, type } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { firstName, lastName, email, countryCode, mobileNo, experience } = this.state;

    var pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
    let validEmail = new RegExp(pattern).test(email);


    let params = {
      firstName,
      lastName,
      email,
      countryCode,
      mobileNo,
      experience,
    }

    if (firstName == "" || lastName == "" || email == "" || countryCode == "" || mobileNo == "" || experience == "") {
      this.setState({ validationError: "All fields are mandatory *" })
    } else if (!validEmail) {
      this.setState({ validationError: "Enter valid email!" })
    } else {
      this.setState({ validationError: "" })
      this.addDietician(params);
    }
  }


  addDietician = (params) => {
    let self = this;
    this.setState({ loader: true })
    DieticianService.addDietician(params)
      .then(function (res) {
        self.setState({ loader: false })
        const response = res.data;
        if (response.statusCode == 1) {
          toast.success(response.responseData.message)
          self.closeModal();
          self.getAllDieticians();
        } else if (response.statusCode == 0) {
          self.setState({ validationError: response.error.errorMessage })
        }
      })
  }




  handleDieticianSearch = (e) => {
    let params = {
      params: {
        count: this.state.countsPerPage,
        search: e.target.value
      }
    }
    this.setState({searchLoader:true})
    if (this.searchTimeOut)
      clearTimeout(this.searchTimeOut);
    
    this.searchTimeOut = setTimeout(
      function () {
        this.getAllDieticians(params);
      }
        .bind(this),
      400
    );
  }


  handleSortList = (sortField) => {

    const { sortType } = this.state;

    let sortOrder = sortType == 1 ? -1 : 1;

    let self = this;
    this.setState({ sortField: sortField, loader: true, sortType: sortOrder }, () => {
      let params = {
        params: {
          sortField: sortField,
          sortType: sortType
        }
      }
      self.getAllDieticians(params);
    })
  }



  handleChangeStatus(id, status) {
    this.setState({ [id]: true, progress: 0.5, })

    let self = this;
    let obj = {
      dieticianId: id,
      dieticianStatus: status === 1 ? 2 : 1
    }

    DieticianService.changeDieticianStatus(obj)
      .then((res) => {
        if (res.data.statusCode === 1) {
          let params = {
            params: {
              search: this.state.searchText,
              count: this.state.countsPerPage,
              pageNo: this.state.pageNo - 1,
              sortField: 'created',
              sortType: -1,
            }
         }
          self.getAllDieticians(params);
          self.setState({ [id]: false })
        }
      })
  }

  async handleExportUserMetrics(params) {
    let exportButton = document.getElementById("um-export-btn")
    const previousTitle = exportButton.innerHTML
    const previousColor = exportButton.style.backgroundColor
    Dieticians.disableExportButton(exportButton)

    try {
      DieticianService.getUserMetrics(params)
          .then(res => {
            const response = res.data;
            if (response.statusCode === 1) {
              toast.info("Triggered job to send user metrics data in Email")
            } else if (response.statusCode === 0) {
              toast.error(response.error.errorMessage)
            }
            Dieticians.enableExportButton(exportButton, previousTitle, previousColor)
          })
    } catch (e) {
      console.log("User Metrics export error:", e);
      Dieticians.enableExportButton(exportButton, previousTitle, previousColor)
    }
  }

  static disableExportButton(exportButton) {
    exportButton.innerHTML = "Submitting..."
    exportButton.style.backgroundColor = "grey"
    exportButton.disabled = true
  }

  static enableExportButton(exportButton, previousTitle, previousColor) {
    exportButton.innerHTML = previousTitle
    exportButton.style.backgroundColor = previousColor
    exportButton.disabled = false
  }

  viewDietician = (dieticianDetails) => {
    this.setState({ openViewDietician: true, viewDieticianDetails: dieticianDetails })
  }

  render() {

    const { dietciansList, loader, validationError, sortFieldIcon, sortType, openViewDietician, viewDieticianDetails, countryCode, mobileNo, experience,
      pageNo, countsPerPage, totalDieticians, searchLoader } = this.state;

    return (
      <HOC>
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item">Dieticians</li>
            </ol>

            {
              loader
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null
            }

            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3">
                    <h4 className="cm_page_heading">Dieticians</h4>
                  </div>
                  <div className="col-md-3 text-right cm_search position-relative">
                    {searchLoader ?
                      <SearchSpinner type="text-info" />
                      : null}
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by Name, Phone Number or E-mail"
                      name="search"
                      onChange={this.handleDieticianSearch}
                    />
                    <i className="fa fa-search"></i>
                  </div>
                  <div className="col-md-2 m-b-md-15" >
                      <div className="form-group">
                          <select className="form-control text-capitalize" onChange={this.handleStatusChange} value={this.state.currentStatus}  name="currentStatus">
                              <option value='' >Select Status</option>
                              {
                                  this.state.userStatus.map((item, index) => {
                                      return (
                                          <option key={index} value={item.value} > {`${item.key}`} </option>
                                      )
                                  })
                              }
                          </select>
                      </div>
                   </div>
                  <div className="col-md-2  m-b-md-15">
                    <button onClick={this.handleAddDieticiansPopup} className="btn btn-primary btn-block">Add Dietician</button>
                  </div>
                  <div className="col-md-2  m-b-md-15">
                    <button id="um-export-btn" onClick={this.handleExportUserMetrics} className="btn btn-primary btn-block">Export User Metrics</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">Name
                      <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('firstName')}></button>
                    </th>

                    <th scope="col" className="filter_text">E-mail
                      <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('email')}></button>
                    </th>

                    <th>Phone No.</th>

                    <th scope="col" className="filter_text">Created Date
                      <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`}></i><button onClick={() => this.handleSortList('created')}></button>
                    </th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>

                  {dietciansList.map((dietician, index) => (
                    <tr key={index}>
                      <td>{((index + 1) + ((pageNo - 1) * countsPerPage))}</td>
                      <td className="cm_pos_realtive more_padding">
                        <Link className="cm_rating" to={"/dieticians/user-ratings/" + dietician._id}>
                          <i className="fa fa-star" title="See Dietician's ratings" />
                        </Link>

                        {dietician.firstName + " " + dietician.lastName}
                        <button className="btn btn-primary btn-sm cm__btn" onClick={() => this.viewDietician(dietician)}>View</button>
                      </td>
                      <td>{dietician.email}</td>
                      <td>{dietician.mobileNo}</td>
                      <td>{dateFormat(dietician.created, "d mmmm yyyy")}</td>

                      <td>
                        <span className={`cm_ckeckbox_wrapper ${dietician.status === 1 ? 'cm_active' : 'cm_inactive'}`}

                          onClick={() => this.handleChangeStatus(dietician._id, dietician.status)}
                        >
                          <span className="cm_ckeckbox_btn"></span>
                        </span>
                      </td>
                    </tr>
                  ))}


                </tbody>


              </table>
              <Pagination
              activePage={pageNo}
              itemsCountPerPage={countsPerPage}
              totalItemsCount={totalDieticians}
              pageRangeDisplayed={2}
              onChange={this.handlePagination}
            />
              {!dietciansList.length ? <h3 className="empty_error">Sorry, We couldn't find any content! <span>{this.state.deviceSearch}</span></h3> : null}

            </div>
          </div>
        </div>
        <Popup
          open={this.state.openAddDieticiansPopup}
          closeOnDocumentClick={false}
          onClose={this.closeModal}
        >
          <div className="cm_modal pl-3 pr-3">
            <span className="cm_modal_close" onClick={this.closeModal}>
              &times;
            </span>
            <h3 className="text-center mb-4">Add New Dietician</h3>
            <div className="text-danger text-center">{validationError}</div>

            <form onSubmit={this.handleSubmit} className="mb-2 modal-custom-bar">

              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="firstName"
                />
              </div>

              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="lastName"
                />
              </div>

              <div className="form-group">
                <label>Country Code</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="countryCode"
                  value={countryCode}
                  onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                />
              </div>


              <div className="form-group">
                <label>Phone number</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="mobileNo"
                  value={mobileNo}
                  onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                />
              </div>

              <div className="form-group">
                <label>E-mail</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="email"
                />
              </div>

              <div className="form-group">
                <label>Experience (in years)</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange}
                  name="experience"
                  value={experience}
                  onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                />
              </div>

              <button className="btn btn-primary btn-block mt-4">Save</button>

            </form>
            {
              this.state.errors
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null
            }


          </div>
        </Popup>

        <Popup
          open={openViewDietician}
          onClose={this.closeModal}
          closeOnDocumentClick={false}
        >
          <div className="card card_style">
          <span className="cm_modal_close" onClick={this.closeModal}>
              &times;
            </span>
            <div className="card-body text-center">
              <h4 className="card-title">Name: {viewDieticianDetails.firstName + " " + viewDieticianDetails.lastName}</h4>
              <p className="card-text">Email: {viewDieticianDetails.email}</p>
              <p className="card-text">Contact: {viewDieticianDetails.countryCode + " " + viewDieticianDetails.mobileNo}</p>
              <p className="card-text">Experience: {viewDieticianDetails.experience} yrs</p>


            </div>
          </div>

        </Popup>

      </HOC>



    );
  }
}

export default Dieticians;
