import axios from 'axios';
import API from '../Api';

export function addRewardProducts(params) {
    return axios.post(API.ADD_REWARD_PRODUCTS, params)
}

export function editRewardProducts(params) {
    return axios.post(API.EDIT_REWARD_PRODUCTS, params)
}

export function deleteRewardProducts(params) {
    return axios.post(API.DELETE_REWARD_PRODUCTS, params)
}

export function getRewardProducts(params) {
    return axios.get(API.GET_REWARD_PRODUCTS, params)
}