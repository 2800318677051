import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getLeavesList, changeLeaveStatus } from '../../config/services/LeavesService';
import moment from 'moment'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

class Leaves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            leavesList: [],
            search: '',
            count: 10,
            totalLeaves: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            dateFilterStartDate: null,
            dateFilterEndDate: null,
            selectedDate: null,
            expertId: props.match.params.id,
            viewReasonPopup: false,
            acceptOrRejectPopup: false,
            tempLeaveStatus: '',
            tempLeaveCreatedDate: ''
        }
    }

    componentDidMount() {
        this.getLeavesList();
    }

    getLeavesList = () => {
        let { search, count, pageNo, sortType, sortField, dateFilterStartDate, dateFilterEndDate, expertId } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField, dateFilterStartDate, dateFilterEndDate, expertId
            }
        }
        getLeavesList(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        leavesList: response.responseData.result,
                        totalLeaves: response.responseData.totalCount,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    closeModal = () => this.setState({
        viewReasonPopup: false, review: '', acceptOrRejectPopup: false, tempLeaveStatus: '', tempLeaveCreatedDate: ''
    });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getLeavesList());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getLeavesList());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getLeavesList() });


    filterByDate = (date) => {
        let dateFilterStartDate
        let dateFilterEndDate

        if (!date) {
            dateFilterStartDate = null
            dateFilterEndDate = null
        }
        else {
            dateFilterStartDate = moment(date[0]).startOf('day').valueOf()
            dateFilterEndDate = moment(date[1]).endOf('day').valueOf()
        }


        this.setState({ dateFilterStartDate, dateFilterEndDate, loader: true }, () => {
            this.getLeavesList()
        })
    }

    viewReason = (review) => {
        this.setState({ review, viewReasonPopup: true })
    }

    parseLeaveStatus = (status) => {
        switch (status) {
            case 1:
                return 'Pending'
            case 2:
                return 'Accepted'
            case 3:
                return 'Rejected'
            case 4:
                return 'Cancelled'
            default:
                return "-"
        }
    }

    changeLeaveStatus = (schedule, status) => {
        this.setState({ tempLeaveStatus: status, tempLeaveCreatedDate: schedule.created, acceptOrRejectPopup: true })
    }


    submitChangeLeaveStatus = (e) => {
        e.preventDefault();
        const { tempLeaveCreatedDate, tempLeaveStatus, expertId } = this.state

        let paramsObj = {
            leaveCreatedDate: tempLeaveCreatedDate,
            status: tempLeaveStatus,
            expertId
        }

        this.setState({ loader: true })
        changeLeaveStatus(paramsObj)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getLeavesList()
                    this.closeModal()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    render() {
        const {
            loader,
            leavesList,
            search,
            pageNo,
            count,
            totalLeaves,
            dateFilterStartDate,
            dateFilterEndDate,
            selectedDate,
            review,
            viewReasonPopup,
            acceptOrRejectPopup,
            tempLeaveStatus,
            tempLeaveCreatedDate,
            sortField,
            sortType
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to="/trainers">Trainers</Link></li>
                            <li className="breadcrumb-item">Trainer's Leaves</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h4 className="cm_page_heading">Leaves</h4>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            Filter By Date :
                                            <div className="col">
                                                <RangePicker
                                                    onChange={(val) => this.filterByDate(val)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col">
                                            <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'} cm_pointer`} onClick={() => this.handleSort('leaveDate')}>
                                                &nbsp; Leave Date </i>
                                        </th>
                                        <th scope="col">Reason</th>
                                        <th scope="col">
                                            <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'} cm_pointer`} onClick={() => this.handleSort('created')}>
                                                &nbsp; Created
                                               </i>
                                        </th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        leavesList.map((schedule, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>
                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {moment(schedule.leaveDate).format('Do MMM YYYY')}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots cm_pointer" title='View Review' onClick={() => this.viewReason(schedule.reason)}>
                                                            {schedule.reason}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="cm_table_three_dots">
                                                            {moment(schedule.created).format('Do MMM YYYY')}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        {schedule.status == 1 ?
                                                            <div className="row">
                                                                <div className="col">
                                                                    <i className="fa fa-check-circle cm_accept" title="Accept"
                                                                        onClick={(val) => this.changeLeaveStatus(schedule, 2)}
                                                                    />
                                                                </div>
                                                                <div className="col">
                                                                    <i className="fa fa-times-circle-o cm_reject" title="Reject"
                                                                        onClick={(val) => this.changeLeaveStatus(schedule, 3)}
                                                                    />
                                                                </div>
                                                            </div> : this.parseLeaveStatus(schedule.status)
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalLeaves}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!leavesList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Modal show={viewReasonPopup} onHide={this.closeModal} centered>
                    <Modal.Header closeButton> <Modal.Title>Reason</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body ">
                                {review}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                <Modal
                    show={acceptOrRejectPopup}
                    onHide={this.closeModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Are you sure, you want to
                            {tempLeaveStatus === 2 ? " accept" : tempLeaveStatus === 3 ? " reject" : "-"} this request?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                        <button className="btn btn-danger cm_danger" onClick={this.closeModal}>No</button> &nbsp; &nbsp;
                        <button className="btn btn-success" onClick={this.submitChangeLeaveStatus} >Yes</button>
                    </Modal.Body>
                </Modal>
            </HOC>
        )
    }
}



export default Leaves;