import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { changeStatus, addStream, getStreamList, editStream, deleteStream, downloadStreamList, downloadUserList, downloadFilteredUserList } from '../../config/services/streamingService';
import { toast } from 'react-toastify';
import { DatePicker, TimePicker, Switch } from 'antd';
import moment from 'moment';
import FileSaver from 'file-saver';
import { getServicesForSession } from '../../config/services/serviceCategoriesService';
import { getAllTrainers, getExpertsByServiceId, uploadTrainerImages, uploadTrainerVideo } from '../../config/services/trainerService';
import { addCollection, updateCollection, deleteCollection, getAllCollections, uploadCollectionImage } from '../../config/services/OnDemandCollectionService';
import { getLeaderboardConfig } from '../../config/services/usersService';
import { ADMIN_ROLES, CONFIG_VERSIONS } from '../../utils/constants';
import { ReactComponent as RemoveFilter } from './../../assets/images/remove-filter.svg';
import { ReactComponent as DownloadIcon } from './../../assets/images/download.svg'
import { ReactComponent as EditIcon } from './../../assets/images/edit.svg'
import { ReactComponent as DeleteIcon } from './../../assets/images/delete.svg'
import { ReactComponent as StartIcon } from './../../assets/images/start.svg'
import { ReactComponent as StopIcon } from './../../assets/images/stop.svg'
import { ReactComponent as ViewIcon } from './../../assets/images/view.svg'
import { Tooltip } from 'antd';
import { getStreamingTypeDisplayConfig, updateStreamingTypeDisplayConfig } from '../../config/services/adminService';

const { RangePicker } = TimePicker;

class Streaming2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            products: [],
            search: '',
            count: 10,
            totalProducts: 15,
            pageNo: 1,
            sortType: -1,
            sortField: 'startTime',
            AddProductPopup: false,
            AddCollectionPopUp: false,
            UpdateCollectionPopUp: false,
            hideAddCollection: false,
            StreamingTypeMapPopUp: false,
            deleteProductPopup: false,
            viewProductPopup: false,
            productImage: '',
            title: '',
            imagePreview: '',
            titleErrorMsg: '',
            editProductPopup: false,
            hideDurationField: true,
            hideCollectionDropdown: true,
            tempProductId: "",
            minAmount: '',
            maxAmount: '',

            // new
            sessionName: '',
            categoryId: '',
            trainerName: '',
            youtubeUrl: '',
            mediaId: '',
            isNewUserSession: true,
            startDate: '',
            endDate: '',
            coverImage: '',
            categories: [],
            difficulties: [],
            difficulty: '',
            streamDuration: null,
            trainersList: [],
            applicableCollections: [],
            collectionsList: [],
            collection: '',
            collectionTitle: '',
            collectionDesc: '',
            collectionTag: '',
            collectionSortWeightage: '',
            collectionImage: '',
            collectionCategory: '',
            collectionActive: true,
            collectionImageUrl: '',
            collectionImageFile: '',
            streamList: [],
            serviceList: [],
            serviceId: '',
            startTime: '',
            endTime: '',
            loggedInRole: 0,
            loggedInUser: localStorage.getItem('expertId'),
            savingLoader: false,
            filterData: {
                serviceId: '',
                trainersList: [],
                expertId: '',
                categoryId: '',
                dateFrom: moment().startOf('day').valueOf(),
                dateTo: moment().endOf('day').valueOf()
            },
            filterKey: 1,
            isFilterValid: false,
            streamingTypeOption: [],
            streamingType: 'Live',
            sessionFrequency: 0,
            sessionFrequencyTypes: [{ key: 'None', value: 0 }, { key: 'Weekly', value: 7 }, { key: 'Daily', value: 1 }],
            editSessionType: 0,
            broadCastStreamingTemplateId: '',
            disableEndDate: true,
            viewSessionDetail: false,
            streamingTypeMap: [],
            streamingTypeDisplayChanges: {},
            // sessionDetails: '',
            // sessionImageURL: '',
            // sessionFile: ''
        }
    }



    handleImgPreivew = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        this.setState({ productImage: e.target.files[0] })
        reader.addEventListener("load", () => {
            this.setState({ imagePreview: reader.result })
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }



    openAddProductPopup = () => {
        this.setState({ AddProductPopup: !this.state.AddProductPopup, sessionImageURL:'', sessionThumbnailVideoURL:'', sessionDetails:''  })
        document.getElementById('body-id').style.overflow = "hidden"
    }

    openUpdateCollectionPopUp = () => {
        this.callGetCollectionsApi()
        this.setState({
            UpdateCollectionPopUp: true,
            hideAddCollection: false,
        })
        document.getElementById('body-id').style.overflow = "hidden"
    }

    openSessionDetails = (sesstionString) => {
        this.setState({ viewSessionDetail: !this.state.AddProductPopup, sessionDetails: sesstionString })
        document.getElementById('body-id').style.overflow = "hidden"
    }

    openDeleteProductPopup = (data, product) => this.setState({ deleteProductPopup: !this.state.deleteProductPopup, broadCastId: product._id, editSessionType: 0 });

    deleteProduct = (e) => {
        e.preventDefault();
        const { broadCastId, editSessionType } = this.state

        let paramsObj = {
            broadCastId: broadCastId,
            editSessionType: parseInt(editSessionType)
        }
        deleteStream(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getRewardProductsList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    openViewProductPopup = (data, product) => {
        this.setState({
            viewProductPopup: true,
            tempProductId: product._id,
            productName: product.productName,
            coinsRequired: product.coinsRequired,
            productType: product.productType,
            imagePreview: product.productImage,
        })
    };

    getDefaultConfigs = () => {
        let params = { version: CONFIG_VERSIONS.ADMIN_CONFIG }
        getLeaderboardConfig(params)
            .then(res => {
                const response = res.data;
                if (response.statusCode == 1) {
                    let metaData = response.responseData.result.metaData
                    let streamingTypes = metaData && JSON.parse(metaData) ? JSON.parse(metaData).STREAMING_TYPES : []
                    let difficultyLevels = metaData && JSON.parse(metaData) ? JSON.parse(metaData).DIFFICULTY_LEVELS : []
                    streamingTypes.map(type => { type.id = type.value; type.name = type.label })
                    difficultyLevels.map(level => { level.id = level.value; level.name = level.label })
                    this.setState({ categories: streamingTypes, difficulties: difficultyLevels })
                }
                else if (response.statusCode == 0) {
                    toast.success(response.error.errorMessage)
                }
            })
    }

    closeModal = () => {
        this.clearState()
        this.setState({
            AddProductPopup: false, UpdateCollectionPopUp: false, deleteProductPopup: false, viewProductPopup: false,
            title: "", productImage: "", editProductPopup: false, hideCollectionDropdown: true, hideDurationField: true,
            imagePreview: "", tempProductId: "", titleErrorMsg: "", productName: "", coinsRequired: "", productType: "",
            categoryId: "", streamingType: "", savingLoader: false, viewSessionDetail: false, hideAddCollection: true,
        });
        document.getElementById('body-id').style.overflow = ''
    }

    closeAddCollectionPopUp = () => {
        this.setState({ AddCollectionPopUp: false })
    }

    closeUpdateCollectionPopUp = () => {
        this.setState({ UpdateCollectionPopUp: false })
    }

    closeStreamingTypeMapPopUp = () => {
        this.setState({ StreamingTypeMapPopUp: false })
    }

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getRewardProductsList());


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getRewardProductsList());


    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getRewardProductsList() });


    getRewardProductsList = (data) => {
        this.setState({ loader: true })
        let { search, count, pageNo, sortType, sortField, filterData } = this.state;
        let params
        if (data && typeof (data == 'object') && Object.keys(data) && Object.keys(data).length) {
            params = { ...{ search, count, pageNo: pageNo - 1, sortType, sortField }, ...data }
        }
        else {
            params = { ...{ search, count, pageNo: pageNo - 1, sortType, sortField }, ...filterData }
        }

        params = { ...params, trainersList: [] }

        let paramsObj = {
            params
        }
        getStreamList(paramsObj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        streamList: res.data.responseData.result.broadCastData,
                        totalProducts: res.data.responseData.result.broadCastCount,
                        loader: false
                    })
                } else {
                    this.setState({ loader: false })
                }
            })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, titleErrorMsg: '' })
    }
    handleSwitch = (value, field) => {
        this.setState({ [field]: value })
    }

    handleToggleSessionType = isNewUserSession => {
        this.setState({
            isNewUserSession: isNewUserSession
        })
    }

    handleCollectionValidation = () => {
        let validate = true
        let { collectionTitle, collectionDesc, collectionImageUrl, collectionTag, collectionSortWeightage } = this.state

        if (!collectionTitle || collectionTitle.toString().trim().length <= 0 || collectionTitle.toString().trim().length > 50) {
            validate = false
            this.setState({ titleErrorMsg: 'Please enter valid collection name (max length: 50)' })
        } else if (!collectionDesc || collectionDesc.toString().trim().length <= 0 || collectionDesc.toString().trim().length > 500) {
            validate = false
            this.setState({ titleErrorMsg: 'Please enter valid collection description (max length: 500)' })
        } else if (!collectionImageUrl || collectionImageUrl.toString().trim().length <= 0) {
            validate = false
            this.setState({ titleErrorMsg: 'Please upload collection image' })
        } else if (collectionTag && (collectionTag.toString().trim().length <= 0 || collectionTag.toString().length > 25)) {
            validate = false
            this.setState({ titleErrorMsg: 'Please enter valid tag (max length: 25) or leave it empty' })
        }  else if (collectionSortWeightage) {
            const w = Number(collectionSortWeightage)
            if (w <= 0 || w > 1000) {
                validate = false
                this.setState({titleErrorMsg: 'Please enter valid collection sort weightage (1-1000)'})
            }
        }
        return validate
    }

    handleValidation = () => {
        let validate = true;
        let reg = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/
        let {
            sessionName,
            youtubeUrl,
            mediaId,
            isNewUserSession,
            startDate,
            endDate,
            trainerName,
            startTime,
            endTime,
            serviceId,
            categoryId,
            streamingType
        } = this.state;

        if (!sessionName) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter Session name' })
        }
        else if (!streamingType) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please Enter Streaming type' })
        }
        else if (!categoryId) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select category' })
        }
        else if (serviceId == '') {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select the session type' })
        }
        else if (!trainerName) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please select the  trainer name' })
        }
        // else if (youtubeUrl == "") {
        //     validate = false;
        //     this.setState({ titleErrorMsg: 'Please enter url' })
        // }


        // else if (!reg.test(youtubeUrl)) {
        //     validate = false;
        //     this.setState({ titleErrorMsg: 'Please enter valid youtube url' })
        // }

        else if (streamingType === 'Live' && startDate == '') {
            validate = false;
            this.setState({ titleErrorMsg: 'Please enter date' })
        }
        else if (streamingType === 'Live' && (startTime == '' || endTime == '')) {
            validate = false;
            this.setState({ titleErrorMsg: 'Please enter start & end time' })
        }
        else {
            this.setState({ titleErrorMsg: '' })
        }
        return validate
    }


    clearState = () => {
        this.setState({
            broadCastId: '',
            sessionName: '',
            trainerName: '',
            startDate: '',
            startTime: '',
            endTime: '',
            youtubeUrl: '',
            mediaId: '',
            isNewUserSession: true,
            serviceId: '',
            categoryId: ''
        })
    }


    setDMY = (originalDate, newDate) => {
        let changedDate = Number(moment(newDate).set("date", moment(originalDate).get('date')).set("month", moment(originalDate).get('month')).set("year", moment(originalDate).get('year')).format('x'))
        return Number(changedDate)
    }


    handleAddProduct = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            let { startTime, endTime, sessionName, trainerName, youtubeUrl, mediaId, isNewUserSession, startDate, endDate, serviceId, categoryId, sessionFrequency, streamingType, sessionDetails, sessionImageURL, sessionThumbnailVideoURL, sessionDetailsImage, difficulty, collection, streamDuration } = this.state;

            if (streamingType === 'On-Demand' || streamingType === 'ScenicRide') {
                let todayDate = new Date()
                startDate = todayDate.getTime()
                startTime = todayDate.getTime()
                endDate = todayDate.getTime()
                endTime = todayDate.getTime()
            }
            startTime = this.setDMY(startDate, startTime)
            endTime = this.setDMY(startDate, endTime)

            let params = {
                startDate,
                startTime,
                endTime,
                endDate,
                url: isNewUserSession ? '' : youtubeUrl,
                expertId: trainerName,
                mediaId: isNewUserSession ? mediaId : '',
                isNewUserSession: isNewUserSession,
                videoSessionType: 3,
                serviceId: serviceId,
                title: sessionName,
                categoryId: parseInt(categoryId),
                sessionFrequency: sessionFrequency ? parseInt(sessionFrequency) : 0,
                streamingType: streamingType,
                sessionDetails: sessionDetails,
                sessionImageURL: sessionImageURL,
                sessionThumbnailVideoURL: sessionThumbnailVideoURL,
                sessionDetailsImageURL: sessionDetailsImage,
                difficulty: difficulty,
                collectionId: collection || null,
                streamDuration: (streamingType !== 'Live' && streamDuration) ? Number(streamDuration) * 60000 : null,
            }
            this.addProductApi(params)
        }
    }


    addProductApi = async (params) => {
        this.setState({ loader: true, savingLoader: true })
        if (this.state.sessionImageURL) {
            params.sessionImageURL = await this.handlingSessionImageURL(this.state.sessionImageFile, `sessionMedia/sessionImage`)
        }
        if (this.state.sessionThumbnailVideoURL) {
            params.sessionThumbnailVideoURL = await this.handlingSessionVideoURL(this.state.sessionVideoFile, `sessionMedia/sessionVideo`)
        }
        if (this.state.sessionDetailsImageURL) {
            params.sessionDetailsImageURL = await this.handlingSessionDetailedImage(this.state.sessionDetailsImageFile, `sessionMedia/sessionDetailImage`)
        }
        await addStream(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false, savingLoader: false }, () => this.getRewardProductsList())
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false, savingLoader: false
                    })
                }
            })

    }



    openEditProductPopup = (data, params) => {
        const { serviceId, expertId } = params || {}
        this.callGetCollectionsApi()

        this.setState({
            hideDurationField: params.streamingType === 'Live',
            hideCollectionDropdown: params.streamingType !== 'On-Demand',
        })

        this.setState({
            editProductPopup: true,
            broadCastId: params._id,
            sessionName: params.title,
            trainerName: expertId && expertId._id,
            startDate: params.startDate,
            endDate: params.endDate,
            startTime: params.startTime,
            endTime: params.endTime,
            youtubeUrl: params.url,
            mediaId: params.mediaId,
            isNewUserSession: params.isNewUserSession,
            serviceId: serviceId && serviceId._id,
            categoryId: params.categoryId,
            editSessionType: 0,
            broadCastStreamingTemplateId: params.broadCastStreamingTemplateId ? params.broadCastStreamingTemplateId._id : '',
            sessionFrequency: params.broadCastStreamingTemplateId ? params.broadCastStreamingTemplateId.recurringFrequency : 0,
            disableEndDate: false,
            streamingType: params.streamingType ? params.streamingType : '',
            sessionImageURL: params.sessionImageURL,
            sessionThumbnailVideoURL: params.sessionThumbnailVideoURL,
            sessionDetailsImageURL: params.sessionDetailsImageURL,
            sessionDetails: params.sessionDetails,
            difficulty: params.difficulty,
            collection: params.collectionId,
            streamDuration: (params.streamingType !== 'Live' && params.streamDuration) ? Number(params.streamDuration) / 60000 : null,
        }, function () {
            if (serviceId && serviceId._id) {
                let paramsObj = {
                    params: {
                        serviceId: serviceId._id, isLive: 1
                    }
                }
                this.callExpertsByServiceApi(paramsObj);
            }
        })

        document.getElementById('body-id').overflow = 'hidden'
    }

    editProductSubmit = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            let { startTime, endTime, broadCastId, sessionName, trainerName, youtubeUrl, mediaId, isNewUserSession,
                startDate, endDate, serviceId, categoryId, streamingType, editSessionType, broadCastStreamingTemplateId,
                sessionFrequency, sessionDetails, sessionImageURL, sessionThumbnailVideoURL, sessionDetailsImageURL,
                difficulty, collection, streamDuration
            } = this.state;
            startTime = this.setDMY(startDate, startTime)
            endTime = this.setDMY(startDate, endTime)

            let params = {
                startTime,
                endTime,
                startDate,
                broadCastId,
                endDate,
                url: isNewUserSession ? '' : youtubeUrl,
                mediaId: !isNewUserSession ? '' : mediaId,
                isNewUserSession: isNewUserSession,
                expertId: trainerName,
                serviceId: serviceId,
                videoSessionType: 3,
                title: sessionName,
                categoryId: parseInt(categoryId),
                editSessionType: parseInt(editSessionType),
                broadCastStreamingTemplateId,
                sessionFrequency: sessionFrequency ? parseInt(sessionFrequency) : 0,
                streamingType: streamingType,
                sessionDetails: sessionDetails,
                sessionImageURL: sessionImageURL,
                sessionThumbnailVideoURL: sessionThumbnailVideoURL,
                sessionDetailsImageURL: sessionDetailsImageURL,
                difficulty: difficulty,
                collectionId: collection,
                streamDuration: (streamingType !== 'Live' && streamDuration) ? Number(streamDuration) * 60000 : null,
            }
            this.editProductApi(params);
        }
    }

    editProductApi = async (params) => {
        this.setState({ loader: true, savingLoader: true })

        if (this.state.sessionImageURL) {
            params.sessionImageURL = await this.handlingSessionImageURL(this.state.sessionImageFile, `sessionMedia/sessionImage`)
        }
        if (this.state.sessionThumbnailVideoURL) {
            params.sessionThumbnailVideoURL = await this.handlingSessionVideoURL(this.state.sessionVideoFile, `sessionMedia/sessionVideo`)
        }
        await editStream(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.getRewardProductsList();
                    this.setState({ loader: false, savingLoader: false })
                    this.closeModal();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ titleErrorMsg: res.data.error.errorMessage, loader: false, savingLoader: false })
                }
            })
            .catch((error) => {
                console.log('error', error)
            })


    }

    filterByDate = (date) => {
        let dateFrom = null, dateTo = null;
        let __data;
        if (date) {
            let dateFrom = moment(date[0]).startOf('day').valueOf()
            let dateTo = moment(date[1]).endOf('day').valueOf()
            __data = { ...this.state.filterData, dateFrom, dateTo }
        } else {
            __data = { ...this.state.filterData, dateFrom, dateTo }
        }
        this.setState({ dateFrom, dateTo, filterData: __data, pageNo: 1 }, () => {
            this.getRewardProductsList(__data)
        })
        this.setState({ isFilterValid: true });
    }

    filterSessions = (value, key) => {
        let __data;
        // if (key == 'startDate') {
        //     let dateFrom =  moment(value).startOf('day').valueOf() 
        //     let dateTo =  moment(value).endOf('day').valueOf() 
        //     __data = { ...this.state.filterData, dateFrom , dateTo }
        // } else {
        //     __data = { ...this.state.filterData, [key]: value }           
        // }
        __data = { ...this.state.filterData, [key]: value }

        if (key == 'serviceId') {
            let paramsObj = {
                params: {
                    serviceId: value,
                    isLive: 1
                }
            }

            __data = { ...__data, expertId: '' }
            this.callExpertsByServiceApi(paramsObj, true);
        }
        this.setState({
            filterData: __data,
        })
        this.getRewardProductsList(__data);

        this.setState({ isFilterValid: true });

    }

    downloadStreamingList = async () => {

        try {

            this.setState({
                loader: true
            })

            let { search, count, pageNo, sortType, sortField } = this.state;

            let params = { ...{ search, count, pageNo: pageNo - 1, sortType, sortField }, ...this.state.filterData }

            let res = await downloadStreamList(params)

            var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `${moment() + ' sessions'}.xlsx`);
            this.setState({
                loader: false
            })

        } catch (error) {

            console.log("error", error);

            this.setState({
                loader: false
            })
        }
    }



    componentDidMount() {
        this.getRewardProductsList();
        this.getServicesForSession();
        this.setRole();
        this.getDefaultConfigs();

        const streamingTypeOption = [
            { value: 'Live', label: 'Live' },
            { value: 'On-Demand', label: 'On-Demand' },
            { value: 'ScenicRide', label: 'Scenic Ride' },
            { value: 'Cultsport Play', label: 'CultSport Play' },
        ]

        this.setState({ streamingTypeOption: streamingTypeOption })
    }


    setRole = () => {
        this.setState({ loggedInRole: Number(localStorage.getItem('role')) })
    }

    categoryName = (catId) => {
        let catgoryInfo = this.state.categories.find(e => e.id === catId);
        if (catgoryInfo && catgoryInfo.name) {
            return catgoryInfo.name;
        } else {
            return 'N/A';
        }
    }

    changeTime = (dates, dateStrings) => {
        this.setState({ startTime: dates[0].toDate().getTime(), endTime: dates[1].toDate().getTime(), titleErrorMsg: '' })
    }

    dateChange = (dates, dateField) => {
        switch (dateField) {
            case 'startDate':
                if (dates) {
                    this.setState({ startDate: dates.toDate().getTime(), disableEndDate: false, titleErrorMsg: '' })
                } else {
                    this.setState({ startDate: '', disableEndDate: true })
                }
                break

            case 'endDate':
                if (dates)
                    this.setState({ endDate: dates.toDate().getTime(), titleErrorMsg: '' })
                else
                    this.setState({ endDate: '' })
                break
        }

    }


    getServicesForSession = () => {
        getServicesForSession()
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {

                    response.responseData.result.map((data, i) => {
                        data.label = data.serviceName
                        data.value = data._id
                    })
                    this.setState({
                        serviceList: response.responseData.result,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }




    getTrainersList() {
        let self = this;
        let params = {
            params: { count: 200, isLive: 1 }
        }
        getAllTrainers(params)
            .then(function (res) {
                const response = res.data;
                if (response.statusCode == 1) {
                    const { result } = res.data.responseData
                    self.setState({ trainersList: result })
                } else
                    if (response.statusCode == 0) {
                        toast.error(response.error.errorMessage)
                    }
            })
    }

    handleSelectService = (e) => {
        this.setState({ serviceId: e.target.value, titleErrorMsg: '', trainerName: '' })
        let paramsObj = {
            params: {
                serviceId: e.target.value, isLive: 1
            }
        }
        this.callExpertsByServiceApi(paramsObj);
    }

    callExpertsByServiceApi = (paramsObj, isfilter) => {
        getExpertsByServiceId(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    response.responseData.result.map((data, i) => {
                        data.label = data.firstName + ' ' + data.lastName
                        data.value = data._id
                    })
                    if (!isfilter) {
                        this.setState({
                            trainersList: response.responseData.result,
                            loader: false
                        })
                    }
                    else {
                        let __data = { ...this.state.filterData, trainersList: response.responseData.result }
                        this.setState({
                            filterData: __data,
                            loader: false
                        })
                    }

                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }



    changeStatusStream = (status, item) => {
        // e.preventDefault();
        const { tempProductId } = this.state
        let paramsObj = {
            status: status,
            broadCastId: item._id
        }
        changeStatus(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    // this.closeModal();
                    this.getRewardProductsList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    getIsEnable = product => {
        if (moment(product.startDate).format('D') == moment().format('D') && moment(product.endTime) < moment()) {
            return 0
        }
        if (moment(product.startDate) < moment() && moment(product.endTime) < moment()) {
            return 0
        }
        else {
            return 1
        }

    }

    clearFilterData = () => {
        let _filterdata = {
            serviceId: '',
            trainersList: [],
            expertId: '',
            categoryId: '',
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
        }

        this.setState({ filterData: _filterdata })

        this.setState({ filterKey: this.state.filterKey + 1 })

        this.getRewardProductsList(_filterdata)
        this.setState({ isFilterValid: false });
    }

    _downloadUserList = async (title, firstName, streamingId) => {
        try {
            this.setState({ loader: true })
            downloadUserList({ params: { streamingId } })
                .then(async (response) => {
                    let resp = response.data
                    if (resp.statusCode == 1) {
                        let { result } = resp.responseData
                        FileSaver.saveAs(result.fileFullUrl)
                        this.setState({ loader: false })
                        toast.success(resp.responseData.message)
                    }
                    else if (resp.statusCode == 0) {
                        this.setState({ loader: false })
                        toast.error(resp.error.errorMessage)
                    }
                })
        }
        catch (error) {
            this.setState({ loader: false })
            console.log(error);
        }
    };

    _downloadFilteredUserList = async () => {
        let { filterData } = this.state
        try {
            const paramsObj = filterData
            paramsObj.trainersList = []
            this.setState({ loader: true })
            downloadFilteredUserList({ params: paramsObj })
                .then(async (response) => {
                    let resp = response.data
                    if (resp.statusCode == 1) {
                        let { result } = resp.responseData
                        FileSaver.saveAs(result.fileFullUrl)
                        this.setState({ loader: false })
                        toast.success(resp.responseData.message)
                    }
                    else if (resp.statusCode == 0) {
                        this.setState({ loader: false })
                        toast.error(resp.error.errorMessage)
                    }
                })
        }
        catch (error) {
            this.setState({ loader: false })
            console.error(error);
        }
    };

    checkConnectedNonConnectedSession = (categoryId) => {
        let check = this.state.categories.find(ele => ele.value == categoryId);
        if (check) {
            if (check.connectedSession == true) {
                return true;

            }
            else {
                return false;
            }
        }
        else {
            return false;

        }


    }

    getSessionStatus = (startDate, startTime, endTime) => {
        if (
            moment(startDate) < moment() &&
            moment(endTime) < moment()
        ) {
            return "Completed";
        }

        // if (moment(startDate) > moment()) {
        //   return "Not Started";
        // }
        if (moment() < moment(startTime)) {
            return "Yet to start";
        } else if (moment() > moment(endTime)) {
            return "Completed";
        } else if (
            moment() >= moment(startTime) &&
            moment() <= moment(endTime)
        ) {
            return "Started";
        }
        // else if (
        //   moment() >= moment(startTime) &&
        //   moment() <= moment(endTime)
        // ) {
        //   return "Started";
        // }
    };

    callGetCollectionsApi = () => {
        getAllCollections().then(res => {
            let response = res.data
            if (response.statusCode === 1) {
                response.responseData.result.map((data) => {
                    data.label = data.title
                    data.value = data._id
                })
                let collections = response.responseData.result
                collections.push({_id: 'AddNew_SecretOption', title: 'Add new collection . . .'})
                this.setState({
                    collectionsList: collections,
                    loader: false
                })
            } else if (response.statusCode == 0) {
                this.setState({ loader: false })
                toast.error(response.error.errorMessage)
            }
        })
    }

    handleSelectStreamingType = (e) => {
        this.setState({
            collection: '',
            streamingType: e.target.value,
            hideDurationField: e.target.value === 'Live',
        })

        if (e.target.value === 'On-Demand') {
            this.callGetCollectionsApi()
            this.setState({ hideCollectionDropdown: false })
        } else {
            this.setState({ collectionsList: [], hideCollectionDropdown: true })
        }
    }

    handleSelectCategory = (e) => {
        this.setState({ categoryId: e.target.value })

        if (this.state.collectionsList) {
            let acl = []
            this.state.collectionsList.forEach(coll => {
                if (!coll.category || coll.category.toString() === e.target.value) {
                    acl.push(coll);
                }
            });
            this.setState({ applicableCollections: acl });
        }
    }

    handleAddCollection = e => {
        e.preventDefault()
        if (this.handleCollectionValidation()) {
            let { collectionTitle, collectionDesc, collectionSortWeightage, collectionTag, collectionCategory } = this.state

            let params = {
                title: collectionTitle.toString().trim(),
                description: collectionDesc.toString().trim(),
                tag: collectionTag ? collectionTag.toString().trim() : collectionTag,
                sortWeightage: collectionSortWeightage || null,
                category: collectionCategory || null,
            }
            this.addNewCollection(params).then(res => {
                if (!this.state.titleErrorMsg) {
                    this.closeAddCollectionPopUp()
                }
            })
        }
    }

    handleUpsertCollection = e => {
        e.preventDefault()
        if (this.handleCollectionValidation()) {
            const params = {
                title: this.state.collectionTitle.toString().trim(),
                description: this.state.collectionDesc.toString().trim(),
                tag: this.state.collectionTag ? this.state.collectionTag.toString().trim() : this.state.collectionTag,
                sortWeightage: this.state.collectionSortWeightage || null,
                category: this.state.collectionCategory || null,
            }
            if (this.state.hideAddCollection) {
                if (this.state.collectionActive) {
                    this.updateExistingCollection(params)
                } else {
                    this.deleteExistingCollection({_id: this.state.collection})
                }
            } else {
                this.addNewCollection(params)
            }
        }
    }

    handleStreamingTypeMapUpdate = async (e) => {
        e.preventDefault()
        let { streamingTypeDisplayChanges } = this.state
        this.setState({ loader: true, savingLoader: true })
        await updateStreamingTypeDisplayConfig(streamingTypeDisplayChanges)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false, savingLoader: false })
                    this.closeStreamingTypeMapPopUp()
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false,
                        savingLoader: false,
                    })
                }
            })
    }

    addNewCollection = async (params) => {
        this.setState({ loader: true, savingLoader: true })
        if (this.state.collectionImageUrl) {
            params.imageUrl = await this.generateCollectionImageUrl(this.state.collectionImageFile, "collectionMedia")
        }
        await addCollection(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.callGetCollectionsApi()
                    this.setState({ loader: false, savingLoader: false })
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false,
                        savingLoader: false,
                    })
                }
            })
    }

    updateExistingCollection = async (params) => {
        this.setState({ loader: true, savingLoader: true })

        params._id = this.state.collection

        if (this.state.collectionImageUrl !== this.state.prevCollectionImage) {
            params.imageUrl = await this.generateCollectionImageUrl(this.state.collectionImageFile, "collectionMedia")
        } else {
            params.imageUrl = this.state.prevCollectionImage
        }
        await updateCollection(params)
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.callGetCollectionsApi()
                    this.setState({ loader: false, savingLoader: false })
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode === 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false,
                        savingLoader: false,
                    })
                }
            })
    }

    deleteExistingCollection = async(params) => {
        this.setState({ loader: true, savingLoader: true })

        await deleteCollection(params)
            .then(res => {
                if (res.data.statusCode === 1) {
                    this.callGetCollectionsApi()
                    this.setState({ loader: false, savingLoader: false })
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode === 0) {
                    this.setState({
                        titleErrorMsg: res.data.error.errorMessage,
                        loader: false,
                        savingLoader: false,
                    })
                }
            })
    }

    handleSelectCollection = (e) => {
        if (e.target.value === 'AddNew_SecretOption') {
            this.setState({
                collectionTitle: '',
                collectionDesc: '',
                collectionTag: '',
                collectionSortWeightage: '',
                collectionCategory: '',
                prevCollectionImage: '',
                collectionImageUrl: '',
                AddCollectionPopUp: true,
            })
        } else {
            this.setState({collection: e.target.value})
        }
    }

    handleSelectCollectionForEdit = (e) => {
        if (e.target.value === 'AddNew_SecretOption' || e.target.value === 'SelectColl_Secret') {
            this.setState({
                collection: e.target.value,
                hideAddCollection: false,
                collectionTitle: '',
                collectionDesc: '',
                collectionTag: '',
                collectionSortWeightage: '',
                collectionCategory: '',
                prevCollectionImage: '',
                collectionImageUrl: '',
            })
        } else {
            const collection = this.state.collectionsList.find(c => {
                return c._id === e.target.value;
            })
            this.setState({
                collection: e.target.value,
                hideAddCollection: true,
                collectionTitle: collection.title,
                collectionDesc: collection.description,
                collectionTag: collection.tag || '',
                collectionSortWeightage: collection.sortWeightage || '',
                collectionCategory: collection.category || '',
                prevCollectionImage: collection.imageUrl,
                collectionImageUrl: collection.imageUrl,
                collectionActive: true,
            })
        }
    }

    openStreamingTypeMapPopUp = () => {
        getStreamingTypeDisplayConfig().then(res => {
            let response = res.data
            if (response.statusCode === 1) {
                this.setState({ streamingTypeMap: response.responseData.result })
            } else if (response.statusCode == 0) {
                this.setState({ loader: false })
                toast.error(response.error.errorMessage)
            }
        })
        this.setState({ StreamingTypeMapPopUp: true })
    }

    handleCollectionImage = (e) => {
        let file = e.target.files[0];
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ collectionImageUrl: reader.result, collectionImageFile: file })
            });
            reader.readAsDataURL(file);
        }
    }

    generateCollectionImageUrl = (image, imageS3Path) => {
        this.setState({ loader: true })

        if (!image) {
            this.setState({validationError: 'Image cannot be empty'})
            return
        }
        if (!imageS3Path) {
            this.setState({validationError: 'Image path cannot be empty'})
            return
        }
        let params = new FormData()
        params.append('image', image)
        params.append('pathToUpload', imageS3Path)

        return uploadCollectionImage(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let {result} = response.responseData
                    this.setState({loader: false})
                    return result.Location
                } else if (response.statusCode == 0) {
                    this.setState({validationError: response.error.errorMessage, loader: false})
                    return image
                }
            }).catch((error) => {
                console.log('error', error)
                this.setState({loader: false})
            })
    }

    handleSessionDetailedImage = (e) => {
        let file = e.target.files[0];
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ sessionDetailsImageURL: reader.result, sessionDetailsImageFile: file })
            });
            reader.readAsDataURL(file);
        }
    }
    handlingSessionDetailedImage = (image, imageS3Path) => {
        this.setState({ loader: true })
        let params = new FormData()
        params.append('image', image)
        params.append('pathToUpload', imageS3Path)

        if (!image) {
            this.setState({ validationError: 'Image cannot be empty' })
        }
        else if (!imageS3Path) {
            this.setState({ validationError: 'Image Path cannot be empty' })
        }
        else {
            return uploadTrainerImages(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        let { result } = response.responseData
                        this.setState({ loader: false })
                        return result.Location
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, loader: false })
                        return image
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                    this.setState({ loader: false })
                })
        }
    }

    handleSessionImageURL = (e) => {
        let file = e.target.files[0];
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ sessionImageURL: reader.result, sessionImageFile: file })
            });
            reader.readAsDataURL(file);
        }
    }
    handlingSessionImageURL = (image, imageS3Path) => {
        this.setState({ loader: true })
        let params = new FormData()
        params.append('image', image)
        params.append('pathToUpload', imageS3Path)

        if (!image) {
            this.setState({ validationError: 'Image cannot be empty' })
        }
        else if (!imageS3Path) {
            this.setState({ validationError: 'Image Path cannot be empty' })
        }
        else {
            return uploadTrainerImages(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        let { result } = response.responseData
                        this.setState({ loader: false })
                        return result.Location
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, loader: false })
                        return image
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                    this.setState({ loader: false })
                })
        }
    }


    // video session url

    handleSessionVideoURL = (e) => {
        let file = e.target.files[0];
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ sessionThumbnailVideoURL: reader.result, sessionVideoFile: file })
            });
            reader.readAsDataURL(file);
        }
    }
    handlingSessionVideoURL = (video, videoS3Path) => {
        this.setState({ loader: true })
        let params = new FormData()
        params.append('video', video)
        params.append('pathToUpload', videoS3Path)

        if (!video) {
            this.setState({ validationError: 'Video cannot be empty' })
        }
        else if (!videoS3Path) {
            this.setState({ validationError: 'Video Path cannot be empty' })
        }
        else {
            return uploadTrainerVideo(params)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        let { result } = response.responseData
                        this.setState({ loader: false })
                        return result.Location
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ validationError: response.error.errorMessage, submitLoader: false, loader: false })
                        return video
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                    this.setState({ loader: false })
                })
        }
    }

    handleStreamingTypeToggle(e, categoryId, typeId) {
        let type = this.state.streamingTypeDisplayChanges[categoryId]
        if (!type) {
            type = {}
        }
        type[typeId] = e
        this.state.streamingTypeDisplayChanges[categoryId] = type
    }

    render() {
        const {
            loader,
            search,
            AddProductPopup,
            AddCollectionPopUp,
            UpdateCollectionPopUp,
            hideAddCollection,
            StreamingTypeMapPopUp,
            imagePreview,
            titleErrorMsg,
            deleteProductPopup,
            viewProductPopup,
            pageNo,
            editProductPopup,
            hideCollectionDropdown,
            hideDurationField,
            count,
            totalProducts,
            productName,
            coinsRequired,
            productType,
            streamList,
            categories,
            difficulties,
            collectionsList,
            applicableCollections,
            collectionTitle,
            collectionDesc,
            collectionTag,
            collectionSortWeightage,
            collectionCategory,
            collectionActive,
            collectionImage,
            collectionImageUrl,
            categoryId,
            loggedInRole,
            loggedInUser,
            savingLoader,
            filterData,
            filterKey,
            streamingTypeOption,
            streamingType,
            isNewUserSession,
            viewSessionDetail,
            sessionDetails
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container body-container-stream stream-pad">
                        <div className="d-sm-flex justify-content-between border-bottom custom-list mb-3">
                            <ol className="breadcrumb breadcrumb-custom">
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item">Streaming management</li>
                            </ol>
                        </div>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div key={filterKey} className="users_header user-header-streaming m-0">
                            <div className="streaming-header-wrapper">
                                <div className="streaming-filter mb-3 mb-sm-0">
                                    <Tooltip
                                        placement="topLeft"
                                        onClick={() => {
                                            this.state.isFilterValid && this.clearFilterData();
                                        }}
                                        className="d-flex align-items-center"
                                        title={`${this.state.isFilterValid ? "Clear Filter" : "No filter applied yet"
                                            }`}
                                        arrowPointAtCenter
                                    >
                                        {this.state.isFilterValid ? (
                                            <RemoveFilter className="remove-filter" />
                                        ) : (
                                            <i className="fa fa-filter"></i>
                                        )}
                                        <span className="text-filter-mobile d-block d-sm-none ml-3">
                                            Clear Filter
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="filter-wrappers">
                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Category</label>
                                            <select className="form-control" onChange={(e) => { this.filterSessions(e.target.value, 'categoryId') }} name="categoryId">
                                                <option selected value=''> Select Category</option>
                                                {
                                                    categories.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id} > {`${item.name}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Streaming Type</label>
                                            <select className="form-control" onChange={(e) => { this.filterSessions(e.target.value, 'streamingType') }} name="streamingType">
                                                <option selected disabled> Select Streaming Type</option>
                                                {
                                                    this.state.streamingTypeOption.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value} > {`${item.label}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Session Type</label>
                                            <select className="form-control" onChange={(e) => { this.filterSessions(e.target.value, 'serviceId') }} name="serviceId">
                                                <option selected disabled> Select Session Type</option>
                                                {
                                                    this.state.serviceList.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item._id} > {`${item.serviceName}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Trainer Name</label>
                                            <select className="form-control" onChange={(e) => { this.filterSessions(e.target.value, 'expertId') }} name="trainerName">
                                                <option selected value=''> Select trainer name</option>
                                                {
                                                    this.state.filterData.trainersList.map((item, index) => {
                                                        return (
                                                            <option value={item._id} key={index} > {`${item.firstName} ${item.lastName}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="filter-select-wrapper">
                                        <div className="form-group">
                                            <label>Filter By Session Date</label> <br />
                                            <DatePicker.RangePicker
                                                className="form-control"
                                                size={"middle"}
                                                onChange={(val) => this.filterByDate(val)}
                                                defaultValue={[moment(filterData.dateFrom), moment(filterData.dateTo)]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="downloads-button-wrapper d-flex flex-column justify-content-center">

                                {/* add new session */}
                                {loggedInRole !== ADMIN_ROLES.TRAINER ?
                                    <div className="downloads d-flex flex-sm-row flex-md-column flex-lg-row align-items-center justify-sm-content-between justify-lg-content-end mb-4 mb-md-0">
                                        <div className="form-group ml-md-auto mr-lg-3 mb-md-3">
                                            <button className="btn btn-primary btn-block" onClick={this.openStreamingTypeMapPopUp}>
                                                &nbsp; StreamType Config
                                            </button>
                                        </div>
                                        <div className="form-group ml-md-auto mr-lg-3 mb-md-3">
                                            <button className="btn btn-primary btn-block" onClick={this.openUpdateCollectionPopUp}>
                                                &nbsp; Add/Edit Collection
                                            </button>
                                        </div>
                                        <div className="form-group add-session ml-auto">
                                            <button className="btn btn-primary btn-block" onClick={this.openAddProductPopup}>
                                                <i className="fa fa-plus"></i>
                                                &nbsp; Add New
                                            </button>
                                        </div>
                                    </div>
                                    : null}
                                <div className="downloads d-flex flex-sm-row flex-md-column flex-lg-row align-items-center justify-sm-content-between justify-lg-content-end mb-4 mb-md-0">
                                    <div className="form-group  ml-md-auto mr-lg-3  mb-md-3">
                                        <button className="btn button-primary btn-block" onClick={() => { this.downloadStreamingList() }}>
                                            <DownloadIcon />
                                            &nbsp;Stream List
                                        </button>
                                    </div>
                                    <div className="form-group ml-auto">

                                        <button className="btn button-primary btn-block" onClick={() => { this._downloadFilteredUserList() }}>
                                            <DownloadIcon />
                                            &nbsp;Users List
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive custom-table-view h-auto" style={{ overflowY: "initial" }}>
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        {/* <th scope="col" className="filter_text" onClick={() => this.handleSort('productName')}>Session Name &nbsp;
                                         <i className={`fa fa-sort-alpha-asc`} />
                                        </th> */}
                                        <th scope="col" style={{ width: '140px' }}>Category</th>
                                        <th scope="col">Streaming type</th>
                                        <th scope="col">Service type</th>
                                        <th scope="col">Trainer Name</th>
                                        <th scope="col">Session Name</th>
                                        <th scope="col">Session date/Time</th>
                                        <th scope="col">Youtube Url</th>
                                        <th scope="col">Media Id</th>
                                        <th scope="col">Session Details</th>
                                        <th scope="col">New User Session</th>
                                        <th scope="col" className="filter_text"
                                        //  onClick={() => this.handleSort('created')}
                                        >Created &nbsp;
                                            {/* <i className={`fa fa-sort-numeric-asc`} /> */}
                                        </th>
                                        <th scope="col">Action</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">View</th>
                                        <th scope="col">User List</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        streamList.map((product, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    {/* <td><span className="cm_table_three_dots">{product.title}</span></td> */}
                                                    <td style={{ width: "140px", minWidth: "140px" }}>
                                                        <span style={{ width: "140px" }}>
                                                            {/* {product.categoryId ? product.serviceId.serviceName : 'N/A'} */}
                                                            {/* {categories.filter(function (category) { return category.id == product.serviceId })} */}

                                                            {product.categoryId ? this.categoryName(product.categoryId) : 'N/A'}
                                                        </span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">{product.streamingType ? product.streamingType : 'N/A'}</span></td>

                                                    <td><span className="cm_table_three_dots">{product.serviceId ? product.serviceId.serviceName : 'N/A'}</span></td>

                                                    <td><span className="cm_table_three_dots">{product.expertId ? `${product.expertId.firstName} ${product.expertId.lastName}` : 'N/a'}</span></td>

                                                    <td><span className="cm_table_three_dots">{product.title}</span></td>
                                                    {(product.streamingType && product.streamingType === 'Live') ?
                                                        <td className="cm_no_wrap">
                                                            {`${dateFormat(product.startDate, "d/mm/yyyy")}`} <br />

                                                            {`${moment(product.startTime).format('LT')} - ${moment(product.endTime).format('LT')}`}
                                                        </td> : <td className="cm_no_wrap"> -- </td>}

                                                    <td className="cm_no_wrap"><a href={product.url} target="_blank">{product.url}</a></td>
                                                    <td className="cm_no_wrap">{product.mediaId}</td>
                                                    <td className="cm_no_wrap"><ViewIcon className="stream-action-icon" onClick={() => this.openSessionDetails(product.sessionDetails)} /></td>
                                                    <td className="cm_no_wrap">{product.isNewUserSession ? "Yes" : "No"}</td>



                                                    <td className="cm_no_wrap">{dateFormat(product.created, "d mmmm yyyy")}</td>


                                                    <td className="cm_no_wrap">

                                                        {/* <button className="btn btn-primary btn-sm" onClick={(data) => this.openViewProductPopup(data, product)}>View</button>
                                                        &nbsp;&nbsp; */}
                                                        <div className="d-flex align-items-center">
                                                            <span className={loggedInRole === ADMIN_ROLES.TRAINER ? 'disable-cursor' : ''} onClick={loggedInRole == ADMIN_ROLES.TRAINER ? undefined : (data) => this.openEditProductPopup(data, product)} >
                                                                <i className="fa fa-edit stream-action-icon mt-1"></i>
                                                            </span>
                                                            &nbsp;&nbsp;

                                                            <span className={"d-flex align-items-center " + (loggedInRole === ADMIN_ROLES.TRAINER ? 'disable-cursor' : '')} onClick={loggedInRole == ADMIN_ROLES.TRAINER ? undefined : (data) => this.openDeleteProductPopup(data, product)}>
                                                                <i className="fa fa-trash stream-action-icon text-danger"></i>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        {
                                                            product.isEnable == 0 ?
                                                                <span className={loggedInRole === ADMIN_ROLES.TRAINER ? 'disable-cursor' : ''} onClick={loggedInRole == ADMIN_ROLES.TRAINER ? undefined : () => this.changeStatusStream(1, product)} >
                                                                    <StartIcon className="stream-action-icon" />
                                                                </span>
                                                                :
                                                                <span className={loggedInRole === ADMIN_ROLES.TRAINER ? 'disable-cursor' : ''} onClick={loggedInRole == ADMIN_ROLES.TRAINER ? undefined : () => this.changeStatusStream(0, product)} >
                                                                    <StopIcon className="stream-action-icon" />
                                                                </span>
                                                        }
                                                    </td>


                                                    <td className="">
                                                        {
                                                            this.checkConnectedNonConnectedSession(product.categoryId) ? product && product.expertId ?
                                                                ADMIN_ROLES.TRAINER === loggedInRole && product.expertId._id !== loggedInUser ? 'Not Authorised' :
                                                                    <Link target="_blank" to={"/streamingDetails2?expertId=" + product.expertId._id + '&streamingId=' + product._id + '&&isEnable=' + this.getIsEnable(product)}>
                                                                        <ViewIcon className="stream-action-icon" />
                                                                    </Link>

                                                                : '-' : product && product.expertId ?
                                                                ADMIN_ROLES.TRAINER === loggedInRole && product.expertId._id !== loggedInUser ? 'Not Authorised' :
                                                                    <Link target="_blank" to={"/streamingDetails?expertId=" + product.expertId._id + '&streamingId=' + product._id + '&&isEnable=' + this.getIsEnable(product)}>
                                                                        <ViewIcon className="stream-action-icon" />
                                                                    </Link>

                                                                : '-'
                                                        }

                                                    </td>
                                                    <td>
                                                        {this.getSessionStatus(product.startDate, product.startTime, product.endTime) == "Completed" &&
                                                            loggedInRole === ADMIN_ROLES.ADMIN
                                                            ? (
                                                                <div>
                                                                    <a style={{ width: "46px" }}
                                                                        onClick={() => {
                                                                            this._downloadUserList(product.title, product.firstName, product._id);
                                                                        }}
                                                                        className="hover btn btn-primary d-flex align-items-center"

                                                                    >
                                                                        <DownloadIcon className="stream-action-icon" />
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <button disabled={true}
                                                                        className="hover btn btn-primary d-flex align-items-center"

                                                                    >
                                                                        <DownloadIcon className="stream-action-icon" />
                                                                    </button>
                                                                </div>
                                                            )}
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>


                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalProducts}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!streamList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>
                    </div>
                </div>



                <Popup
                    open={AddProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    closeOnDocumentClick={false}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.handleAddProduct}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Session Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sessionName"
                                            placeholder='Session Name.....'
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="d-block">Streaming Type</label>
                                        <select className="form-control" onChange={this.handleSelectStreamingType} id="streamingType" name="streamingType">
                                            <option selected disabled > Select Streaming Type</option>
                                            {
                                                streamingTypeOption.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.value} > {`${item.label}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <select className="form-control" onChange={this.handleSelectCategory} name="categoryId">
                                            <option selected value=''> Select Category</option>
                                            {
                                                categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id} > {`${item.name}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Session Type</label>
                                        <select className="form-control" onChange={this.handleSelectService} name="serviceId">
                                            <option selected disabled> Select Session Type</option>
                                            {
                                                this.state.serviceList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.serviceName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Trainer Name</label>
                                        <select className="form-control" onChange={this.handleChange} name="trainerName">
                                            <option selected value='' > Select trainer name</option>
                                            {
                                                this.state.trainersList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.firstName} ${item.lastName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Difficulty</label>
                                        <select className="form-control" onChange={this.handleChange} name="difficulty">
                                            <option selected disabled> Select difficulty</option>
                                            {
                                                difficulties.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id} > {`${item.name}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div hidden={hideDurationField} className="col-md-6">
                                    <div className="form-group">
                                        <label>Stream Duration in minutes</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="streamDuration"
                                            placeholder='Duration in minutes.....'
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div hidden={hideCollectionDropdown} className="col-md-6">
                                    <div className="form-group">
                                        <label>Collection</label>
                                        <select className="form-control" onChange={this.handleSelectCollection} name="collection">
                                            <option selected value='' > Select collection</option>
                                            {
                                                applicableCollections.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.title}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Session Details</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sessionDetails"
                                            placeholder='Session details.....'
                                            value={sessionDetails}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <label className="d-block">Live Session Detail Image</label>
                                        <div className='radio_wrapper text-center'>
                                            <input class="upload_button-new" type="file" accept="image/*" onChange={(e) => this.handleSessionDetailedImage(e)} />
                                            {this.state.sessionDetailsImage ? <img src={this.state.sessionDetailsImage} className="mt-2 width_trainer_image" /> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <label className="d-block">Live Session Image</label>
                                        <div className='radio_wrapper text-center'>
                                            <input class="upload_button-new" type="file" accept="image/*" onChange={(e) => this.handleSessionImageURL(e)} />
                                            {this.state.sessionImageURL ? <img src={this.state.sessionImageURL} className="mt-2 width_trainer_image" /> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">

                                    <label className="d-block">Live Session Thumbnail Video</label>
                                    <div className="radio_wrapper text-center mb-4">

                                        <input
                                            type="file"
                                            className="upload_button-new"
                                            accept="video/*"
                                            onChange={(e) => this.handleSessionVideoURL(e)}
                                            name="liveSessionsListImagesThumbnailVideo"
                                            // value={"" }value
                                            id="thumbnailVideoAddId"
                                            accept=".mp4"

                                        />
                                        {this.state.sessionThumbnailVideoURL ?
                                            <video width="400" controls className="mt-2 width_trainer_image">
                                                <source src={this.state.sessionThumbnailVideoURL} />
                                            </video>
                                            : null}
                                    </div>
                                </div>



                                <div className="col-md-12">
                                    <div className="form-group d-flex align-items-center justify-content-between">
                                        <label>New User Session</label>

                                        <div className="form-check form-switch">
                                            <Switch defaultChecked={this.state.isNewUserSession} name="isNewUserSession" onChange={this.handleToggleSessionType} />
                                        </div>
                                    </div>
                                </div>

                                {
                                    isNewUserSession == true || isNewUserSession == 'true' ?

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Media Id</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="mediaId"
                                                    placeholder="mediaId"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Youtube url</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="youtubeUrl"
                                                    placeholder="eg.www.youtube.com/aq2hfLHwY_0"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                }



                                {(streamingType && streamingType === 'Live') ? <>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Date</label> <br />
                                            <DatePicker
                                                selected={this.state.startDate}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={date => this.dateChange(date, 'startDate')}
                                                dropdownClassName="upar_index"
                                                className="form-control"
                                                maxDate={new Date()}
                                                disabledDate={(current) => {
                                                    return moment().add(-1, 'days') >= current;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label> End Date</label> <br />
                                            <DatePicker
                                                selected={this.state.endDate}
                                                selectsEnd
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={date => this.dateChange(date, 'endDate')}
                                                dropdownClassName="upar_index"
                                                className="form-control"
                                                maxDate={new Date()}
                                                disabled={this.state.disableEndDate}
                                                disabledDate={(current) => {
                                                    return current.toDate().getTime() <= this.state.startDate;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Time -End Time</label> <br />
                                            <RangePicker
                                                onChange={this.changeTime}
                                                dropdownClassName="upar_index"
                                                className="form-control"
                                                popupClassName="time-range-popup"
                                                format={'HH:mm'}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Frequency</label>
                                            <select className="form-control text-capitalize" onChange={this.handleChange} value={this.state.sessionFrequency} name="sessionFrequency">
                                                {/* <option value='' >No</option> */}
                                                {
                                                    this.state.sessionFrequencyTypes.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value} > {`${item.key}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </>
                                    : null}
                            </div>
                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Adding Session' : 'Add Session'} </button>
                            </div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={AddCollectionPopUp}
                    onClose={this.closeAddCollectionPopUp}
                    closeOnDocumentClick={false}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeAddCollectionPopUp}>&times;</span>
                        <center><h4>Add Collection</h4></center>
                        <form onSubmit={this.handleAddCollection}>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="collectionTitle"
                                        placeholder='Name (max 50 chars).....'
                                        value={collectionTitle}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="collectionDesc"
                                        placeholder='Description (max 500 chars).....'
                                        value={collectionDesc}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Tag</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="collectionTag"
                                        placeholder='Tag (max 25 chars).....'
                                        value={collectionTag}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Sort Weightage</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="collectionSortWeightage"
                                        placeholder='Weightage (1-1000).....'
                                        value={collectionSortWeightage}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Category</label>
                                    <select value={collectionCategory} className="form-control" onChange={this.handleChange} id="collectionCategory" name="collectionCategory">
                                        <option disabled value=''> Select category</option>
                                        {
                                            categories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value} > {`${item.label}`} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group ">
                                    <label className="d-block">Image</label>
                                    <div className='radio_wrapper text-center'>
                                        <input className="upload_button-new" type="file" accept="image/*"
                                               onChange={(e) => this.handleCollectionImage(e)}/>
                                        {collectionImage ? <img src={collectionImage} className="mt-2 width_trainer_image"/> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Adding Collection. . .' : 'Add Collection'} </button>
                            </div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={UpdateCollectionPopUp}
                    onClose={this.closeUpdateCollectionPopUp}
                    closeOnDocumentClick={false}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeUpdateCollectionPopUp}>&times;</span>
                        <center>
                            <h5>Add/Edit Collections</h5>
                        </center>
                        <label>Add new collection or Select one to edit</label>
                        <select className="form-control" onChange={this.handleSelectCollectionForEdit} name="collectionEdit">
                            <option selected value='SelectColl_Secret' > Select collection</option>
                            {
                                collectionsList.map((item, index) => {
                                    return (
                                        <option key={index} value={item._id} > {`${item.title}`} </option>
                                    )
                                })
                            }
                        </select>
                        <br/>
                        <form onSubmit={this.handleUpsertCollection}>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="collectionTitle"
                                        disabled={hideAddCollection}
                                        placeholder='Name (max 50 chars).....'
                                        value={collectionTitle}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="collectionDesc"
                                        placeholder='Description (max 500 chars).....'
                                        value={collectionDesc}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Tag</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="collectionTag"
                                        placeholder='Tag (max 25 chars).....'
                                        value={collectionTag}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Sort Weightage</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="collectionSortWeightage"
                                        placeholder='Weightage (1-1000).....'
                                        value={collectionSortWeightage}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Category</label>
                                    <select value={collectionCategory} className="form-control" onChange={this.handleChange} id="collectionCategory" name="collectionCategory">
                                        <option disabled value='' > Select category</option>
                                        {
                                            categories.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value} > {`${item.label}`} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div hidden={!hideAddCollection} className="form-group">
                                    <label>Active?</label>&nbsp;
                                    <Switch checked={collectionActive} onChange={e => this.handleSwitch(e, 'collectionActive')} />
                                </div>
                            </div>

                            <div hidden={!hideAddCollection} className="col-md-12">
                                <div className="form-group">
                                    <label className="d-block">Image</label>
                                    <img src={collectionImageUrl} alt="img"/>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="d-block">Upload New Image</label>
                                    <div className='radio_wrapper text-center'>
                                        <input className="upload_button-new" type="file" accept="image/*" onChange={this.handleCollectionImage}/>
                                        {collectionImage ? <img src={collectionImage} className="mt-2 width_trainer_image"/> : null}
                                    </div>
                                </div>
                            </div>

                            <div hidden={hideAddCollection} className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Adding Collection. . .' : 'Add Collection'} </button>
                            </div>

                            <div hidden={!hideAddCollection}  className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Updating Collection. . .' : 'Update Collection'} </button>
                            </div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={StreamingTypeMapPopUp}
                    onClose={this.closeStreamingTypeMapPopUp}
                    closeOnDocumentClick={false}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeStreamingTypeMapPopUp}>&times;</span>
                        <form onSubmit={this.handleStreamingTypeMapUpdate}>
                            <div className="form-group">
                                {
                                    this.state.streamingTypeMap.map(category => {
                                        return (
                                            <div className="form-group">
                                                <h6>{category.name}</h6>
                                                <table className="table"><tr>
                                                {
                                                    category.types.map(type => {
                                                        return (
                                                            <td>
                                                                <div className="form-group">
                                                                    <label>{type.name}&nbsp;</label>
                                                                    <Switch defaultChecked={type.enabled} onChange={e => this.handleStreamingTypeToggle(e, category.id, type.id)}/>
                                                                </div>
                                                            </td>
                                                        )
                                                    })
                                                }
                                                </tr></table>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Saving configuration. . .' : 'Save configuration'} </button>
                            </div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={editProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    closeOnDocumentClick={false}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <form onSubmit={this.editProductSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Session Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sessionName"
                                            value={this.state.sessionName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="d-block">Streaming Type</label>
                                        <select disabled className="form-control" onChange={this.handleChange} id="streamingType" name="streamingType" value={streamingType}>
                                            <option selected disabled > Select Streaming Type</option>
                                            {
                                                streamingTypeOption.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.value} > {`${item.label}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <select className="form-control" onChange={this.handleSelectCategory} name="categoryId" value={categoryId}>
                                            <option selected value='' > Select Category</option>
                                            {
                                                categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id} > {`${item.name}`} </option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Session Type</label>
                                        <select className="form-control" onChange={this.handleSelectService} value={this.state.serviceId} name="serviceId">
                                            <option selected disabled>Select Session Type</option>
                                            {
                                                this.state.serviceList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.serviceName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Trainer Name</label>
                                        <select className="form-control" onChange={this.handleChange} value={this.state.trainerName} name="trainerName">
                                            <option selected value=''> Select trainer name</option>
                                            {
                                                this.state.trainersList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.firstName} ${item.lastName}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Difficulty</label>
                                        <select className="form-control" onChange={this.handleChange} value={this.state.difficulty} name="difficulty">
                                            <option selected disabled> Select difficulty</option>
                                            {
                                                difficulties.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id} > {`${item.name}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div hidden={hideDurationField} id='durationField2' className="col-md-6">
                                    <div className="form-group">
                                        <label>Stream Duration in minutes</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="streamDuration"
                                            placeholder='Duration in minutes.....'
                                            value={this.state.streamDuration}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div hidden={hideCollectionDropdown} className="col-md-6">
                                    <div className="form-group">
                                        <label>Collection</label>
                                        <select className="form-control" onChange={this.handleSelectCollection} value={this.state.collection} name="collection">
                                            <option selected value='' > Select collection</option>
                                            {
                                                applicableCollections.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id} > {`${item.title}`} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Session Details</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sessionDetails"
                                            placeholder='Session details.....'
                                            value={this.state.sessionDetails}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <label className="d-block">Live Session Detail Image</label>
                                        <div className='radio_wrapper text-center'>
                                            <input class="upload_button-new" type="file" accept="image/*" onChange={(e) => this.handleSessionDetailedImage(e)} />
                                            {this.state.sessionDetailsImageURL ? <img src={this.state.sessionDetailsImageURL} className="mt-2 width_trainer_image" /> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <label className="d-block">Live Session Image</label>
                                        <div className='radio_wrapper text-center'>
                                            <input class="upload_button-new" type="file" accept="image/*" onChange={(e) => this.handleSessionImageURL(e)} />
                                            {this.state.sessionImageURL ? <img src={this.state.sessionImageURL} className="mt-2 width_trainer_image" /> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">

                                    <label className="d-block">Live Session Thumbnail Video</label>
                                    <div className="radio_wrapper text-center mb-4">

                                        <input
                                            type="file"
                                            className="upload_button-new"
                                            accept="video/*"
                                            onChange={(e) => this.handleSessionVideoURL(e)}
                                            name="liveSessionsListImagesThumbnailVideo"
                                            // value={"" }value
                                            id="thumbnailVideoAddId"
                                            accept=".mp4"

                                        />
                                        {this.state.sessionThumbnailVideoURL ?
                                            <video width="400" controls className="mt-2 width_trainer_image">
                                                <source src={this.state.sessionThumbnailVideoURL} />
                                            </video>
                                            : null}
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group d-flex align-items-center justify-content-between">
                                        <label>New User Session</label>

                                        <div className="form-check form-switch">
                                            <Switch defaultChecked={this.state.isNewUserSession} name="isNewUserSession" onChange={this.handleToggleSessionType} />
                                        </div>
                                    </div>
                                </div>




                                {
                                    isNewUserSession == true || isNewUserSession == 'true' ?

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Media Id</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="mediaId"
                                                    placeholder="mediaId"
                                                    onChange={this.handleChange}
                                                    value={this.state.mediaId}
                                                />
                                            </div>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Youtube url</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="youtubeUrl"
                                                    placeholder="eg.www.youtube.com/aq2hfLHwY_0"
                                                    onChange={this.handleChange}
                                                    value={this.state.youtubeUrl}
                                                />
                                            </div>
                                        </div>

                                }



                                {(streamingType && streamingType === 'Live') ? <>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date</label> <br />
                                            <DatePicker
                                                defaultValue={moment(new Date(this.state.startDate))}
                                                onChange={date => this.dateChange(date, 'startDate')}
                                                dropdownClassName="upar_index"
                                                className="form-control"
                                                disabledDate={(current) => {
                                                    return moment().add(-1, 'days') >= current;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Date</label> <br />
                                            <DatePicker
                                                defaultValue={moment(new Date(this.state.endDate))}
                                                onChange={date => this.dateChange(date, 'endDate')}
                                                dropdownClassName="upar_index"
                                                className="form-control"
                                                disabled={this.state.disableEndDate}
                                                disabledDate={(current) => {
                                                    return current.toDate().getTime() <= this.state.startDate;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Time -End Time</label> <br />
                                            <RangePicker
                                                defaultValue={[moment(new Date(this.state.startTime)), moment(new Date(this.state.endTime))]}
                                                onChange={this.changeTime}
                                                dropdownClassName="upar_index"
                                                className="form-control"
                                                format={'HH:mm'}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Frequency</label>
                                            <select className="form-control text-capitalize" onChange={this.handleChange} value={this.state.sessionFrequency} name="sessionFrequency">
                                                {
                                                    this.state.sessionFrequencyTypes.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value} > {`${item.key}`} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="radio_wrapper text-center">
                                                <label>
                                                    Edit this session
                                                    <input
                                                        type="radio"
                                                        defaultChecked={true}
                                                        onChange={this.handleChange}
                                                        value={0}
                                                        name="editSessionType"
                                                    />
                                                    <span></span>
                                                </label>
                                                <label>
                                                    Edit this and following sessions
                                                    <input
                                                        type="radio"
                                                        value={1}
                                                        onChange={this.handleChange}
                                                        name="editSessionType"
                                                    />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                    : null}
                            </div>

                            <div className="text-center">
                                {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                                <button className="btn btn-primary" type="submit" disabled={savingLoader}>{savingLoader ? 'Updating Session' : 'Update Session'}</button>
                            </div>
                        </form>
                    </div>
                </Popup>





                <Popup
                    open={deleteProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    closeOnDocumentClick={false}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h6 className="text-center mb-2 mt-2">Are you sure, you want to?</h6>
                        <div className="radio_wrapper text-center mb-5 mt-5">
                            <label>
                                Delete this session
                                <input
                                    type="radio"
                                    defaultChecked={true}
                                    onChange={this.handleChange}
                                    value={0}
                                    name="editSessionType"
                                />
                                <span></span>
                            </label>
                            <label>
                                Delete this and following sessions
                                <input
                                    type="radio"
                                    value={1}
                                    onChange={this.handleChange}
                                    name="editSessionType"
                                />
                                <span></span>
                            </label>
                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteProduct}>Delete</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>




                <Popup
                    open={viewProductPopup}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    closeOnDocumentClick={false}
                >
                    <div className="card card_style">
                        <img className="card-img-top" src={imagePreview} alt="Food image" />
                        <span className="card_style_cat">Value : {coinsRequired} Coins</span>
                        <div className="p-3 text-center">
                            <h4 className="card-title three_dots">{productName}</h4>
                            <p className="card-text">{productType == 1 ? "Product" : productType == 2 ? "Deal" : "N/A"} </p>
                        </div>
                    </div>
                </Popup>


                <Popup
                    open={viewSessionDetail}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    closeOnDocumentClick={false}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h4 >Session Details</h4>
                        <p>{sessionDetails}</p>
                    </div>
                </Popup>
            </HOC>
        )
    }
}



export default Streaming2
