import axios from 'axios';
import API from '../Api';

export function getReviewsListByAdmin(params) {
    return axios.get(API.GET_REVIEWS_LIST,params)
}

export function approveReviewByAdmin(params) {
    return axios.post(API.APPROVE_REVIEW,params)
}

export function editReviewsByAdmin(params) {
    return axios.post(API.EDIT_REVIEWS_BY_ADMIN,params)
}

export function deleteReview(params) {
    return axios.post(API.DELETE_REVIEW,params)
}