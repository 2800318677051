
import { ORDER_STATUS ,ORDER_PAYMENT_STATUS,LOGISTIC_STATUS} from "./constants";
import moment from 'moment';


//set order's options for input type select 
const orderStatusOptions = (emptyLabelText="All") => {
  let _stateOption = [];
  _stateOption.push({ label: emptyLabelText, value: "" });
  for (const property in ORDER_STATUS) {
    _stateOption.push({ label: ORDER_STATUS[property], value: property });
  }
  return _stateOption;
}

//set order's payment options for input type select 
const paymentStatusOptions = (emptyLabelText="All") => {
  let _stateOption = [];
  _stateOption.push({ label: emptyLabelText, value: "" });
  for (const property in ORDER_PAYMENT_STATUS) {
    _stateOption.push({ label: ORDER_PAYMENT_STATUS[property], value: property });
  }
  return _stateOption;
}

//Set Logistic's options for input type select 
const logisticStatusOptions = (emptyLabelText="All") => {
  let _stateOption = [];
  _stateOption.push({ label: emptyLabelText, value: "" });
  for (const property in LOGISTIC_STATUS) {
    _stateOption.push({ label: LOGISTIC_STATUS[property], value: property });
  }
  return _stateOption;
}


//check whether price is float (decimal) or not 
const checkNumberIfFloat = (value) => {
  return Number(value) === value && value % 1 !== 0;
};

//  price format decimal 
const priceFormat =(value)=>{
  let price = Number(value.toFixed(2));
  //check float
  if (checkNumberIfFloat(value)) {
    price = Number(value.toFixed(2));
  } else {
    price = Number(value);
  }
  return price;

}

//convert to timestamp
const convertToTimestamp = (date)=>{
  return new Date(date).getTime();
}


//convert to timestamp to date formate
const timestampToDateFormat = (timeStamp,format='DD MMM YYYY hh:mm a')=>{
  if(timeStamp){
     return moment(timeStamp).format(format)
  }else{
    return '';
  }
}

//count words in 

const showLimitWords= (str,limit)=>{
    if(str.length>limit){
        return str.substring(0,limit)+'...';
    }else{
      return str; 
    }
}


export {
  orderStatusOptions,
  paymentStatusOptions,
  logisticStatusOptions,
  checkNumberIfFloat,
  priceFormat,
  convertToTimestamp,
  timestampToDateFormat,
  showLimitWords
};
