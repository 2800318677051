import React, { useState, Fragment, useEffect } from "react";
import Popup from "reactjs-popup";
import { LoaderSvg } from "../Reusable";
import { addVendors } from "../../config/services/vendorService";
import {
  getAllPincodes,
  getAllStates,
  getAllCitys,
} from "../../config/services/servicePincodes";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";

import {
  Form,
  Input,
  Select,
  Button,
  Radio,
  Row,
  Col,
  Checkbox,
  Collapse,
} from "antd";
import { isArray } from "lodash";
const { Panel } = Collapse;
const { removeDuplicates } = require("../utils/Util.js");

let style = { textTransform: "capitalize", fontWeight: "Bold" };
let styleForWrapper = { marginBottom: ".4em" };

const addVendor = ({ hide, addVendorModal, getVendors }) => {
  let labels = ["state", "city", "pincode"];
  const initialState = {
    error: "",
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    state: [],
    tat: "",
    countycode: "91",
    selectedPincode: {},
    states: [],
    city: [],
    pincode: [],
    service: {},
    demoGraphic: [],
    isError: true,
    newError: {},
    checked: true,
  };

  const [state, setState] = useState({ ...initialState });

  // const [selectedID, setSelectedId] = useState('');
  // const [selectedService, setSelectedService] = useState('');
  // const [error, setError] = useState('');
  const [selectedPincode, setPincode] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setselectedCity] = useState([]);
  const [renderValue, setRenderValue] = useState(false);
  const [newError, setNewError] = useState({});
  const [form] = Form.useForm();
  const [sortedCity, setSortedCity] = useState({});
  const [sortedPincode, setSortedPicode] = useState([]);
  const [formLayout, setFormLayout] = useState("horizontal");
  const [citySelect, setServiceCitySelect] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const [fetchingCities , setFetchingCities] = useState(false);


  // const onCheckAllChange = e => {
  //     setCheckedList(e.target.checked ? plainOptions : []);
  //     setIndeterminate(false);
  //     setCheckAll(e.target.checked);
  // };

  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const onSubmit = (values) => {};

  // do not change anything in the useEffect

  useEffect(() => {
    if (selectedState && selectedState.length === 0) {
      setselectedCity([]);
      setPincode([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity && selectedCity.length === 0) {
      // console.log('test')
      setPincode([]);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (
      selectedState &&
      selectedState.length > 1 &&
      selectedCity &&
      selectedCity.length === 0
    ) {
      // console.log("text to check the pincode effect")
      setPincode([]);
    }
  }, [selectedPincode]);

  // do not change anything in the useEffect

  const handleSubmit = () => {
    const matcherForFeilds = {
      firstname: "First name",
      lastname: "Last name",
      mobile: "Mobile",
      email: "Email",
    };

    // this part of the validation is for the plain text and number feilds
    let feildsToValidate = ["firstname", "lastname", "email", "mobile"];
    feildsToValidate.forEach((feild) => {
      if (
        state[feild] === "" ||
        state[feild] === null ||
        state[feild] === undefined
      ) {
        newError[feild] = `${matcherForFeilds[feild]} cannot be left blank`;
      }
    });

    // this part of validation is for the dropdowns
    let dropDownFeilds = ["selectedCity", "selectedState", "selectedPincode"];
    dropDownFeilds.forEach((feild) => {
      if (feild === "selectedCity") {
        newError[feild] =
          selectedCity && selectedCity.length > 0 ? "" : "City Cannot be blank";
      } else if (feild === "selectedState") {
        newError[feild] =
          selectedState && selectedState.length > 0
            ? ""
            : "state Cannot be blank";
      } else {
        newError[feild] =
          selectedPincode && selectedPincode.length > 0
            ? ""
            : "pincode Cannot be blank";
      }
    });

    for (var key in newError) {
      if (newError[key] === "") {
        delete newError[key];
      }
    }

    setRenderValue(!renderValue);
    if (Object.keys(newError).length < 1) {
      const params = {
        firstName: state.firstname,
        lastName: state.lastname,
        email: state.email,
        mobileNo: state.mobile,
        countryCode: state.countycode,
        city: selectedCity.map((city) => city.label),
        state: selectedState.map((state) => state.label),
        tat: state.tat,
        pincode:
          selectedPincode &&
          selectedPincode
            .filter((item, index) => selectedPincode.indexOf(item) === index)
            .map((item) => item.value),
      };
      addVendors(params).then((response) => {
        if (response && response.data.statusCode === 1) {
          hide();
          getVendors();
          toast.success(`${state.firstname} added as vendor !`);
        } else {
          setState({
            ...state,
            error:
              response.data.error.errorCode === 10
                ? response.data.error.errorMessage
                : "Something went  wrong during saving the data, please  try again",
          });
        }
      });
    }
  };

  // for state
  const getStateData = (val) => {
    let params = { search: val, sortField: "_id", sortType: 1 };

    return getAllStates({ params }).then((res) => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData;
        let states = [];
        if (data.result && data.result.length > 0) {
          //   data.result.forEach((key) => {
          //     states.push({
          //       label: key._id,
          //       value: key._id,
          //     });
          //   });
          states = data.result;
        }
        setState({ ...state, states: states });
        return states;
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage);
      }
    });
  };

  const loadStateServices = (key) => {
    return getStateData(key);
  };

  const serviceStateSelect = (service) => {
    console.log(service, "removing")
    let city = [];
    let selectedState = [];
    setFetchingCities(true)
    
    service ? selectedState = service : selectedState = []
    
    //   service.map((item) => {
    //     selectedState.push(item);
    //     city.push(item.label);
    //     setFetchingCities(true)
    //   });

    if (selectedState.length > 0) {
      getCityData("", city);
    } else {
      setSortedCity({});
    }
    // console.log(city, "Citys")
    setSelectedState(selectedState);
    setNewError({ ...newError, selectedState: "" });
  };

  // for city

  const getCityData = (val, cities) => {
    // this above is done to prepopulate
    // the cities when a user selects the state
    cities &&
      setselectedCity(
        selectedCity.filter((item) => cities.includes(item.state))
      );
    cities &&
      setPincode(selectedPincode.filter((item) => cities.includes(item.state)));
    let params = { search: val, sortField: "_id", sortType: 1 };

    if (cities && cities.length > 0) {
      let data = [];
      cities.forEach((city) => {
        data.push(city);
      });
    }
    params.states =
      cities && cities.length > 0
        ? cities.join()
        : selectedState.map((city) => city.label).join();

    if (val) {
      params.citySearch = val;
    }

    return getAllCitys({ params }).then((res) => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData;
        let citys = [];
        if (data.result && data.result.length > 0) {
          data.result.forEach((state) => {
            citys.push({
              label: state._id,
              value: state._id,
              state: state.state.state,
            });
          });
        }
        // getPincodeData(val, citys.map((item) => item.label));
        let sorted = {};
        for (var i = 0, max = citys.length; i < max; i++) {
          if (sorted[citys[i].state] == undefined) {
            sorted[citys[i].state] = [];
          }
          sorted[citys[i].state].push(citys[i]);
        }
        setSortedCity(sorted);
        setselectedCity(citys);
        setState({ ...state, citys: citys });

        return citys;
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage);
      }
    });
  };
  const serviceCitySelect = (service) => {
    let pincodes = [];
    let selectedCityDt = selectedCity;

    selectedCityDt.push(service);
    setselectedCity(selectedCityDt);
    getPincodeData("", selectedCityDt);
    // pincodes.push(service);
    // if (pincodes.length > 0) { getPincodeData("", service) }
    // setselectedCity(selectedCityDt);
    // setNewError({ ...newError, "selectedCity": '' });
  };

  // for pincode
  const getPincodeData = (val, cities) => {
    // ;

    let citys = cities.join();
    let states = selectedState.map((state) => state.label).join();
    let params = {
      pincodeSearch: "",
      pincodes: [],
      citys: [],
    };

    if (val) {
      params["pincodeSearch"] = val;
    }

    if (citys && citys.length > 0) {
      params.citys = states;
    }

    if (states && states.length > 0) {
      params.pincodes = citys;
    }

    return getAllPincodes({ params }).then((res) => {
      if (res && res.data.statusCode === 1) {
        let data = res.data.responseData;
        let result = data[0].result;
        let pincodes = [];
        result.map((service) => {
          pincodes.push({
            label: service.pincode,
            value: service._id,
            state: service.state,
            city: service.city,
          });
        });
        let sorted = {};
        for (var i = 0, max = pincodes.length; i < max; i++) {
          if (sorted[pincodes[i].city] == undefined) {
            sorted[pincodes[i].city] = [];
          }
          sorted[pincodes[i].city].push(pincodes[i]);
        }
        setSortedPicode(sorted);
        return pincodes;
      } else if (res.data.statusCode == 0) {
        console.log(res.data.error.errorMessage);
      }
    });
  };
  const onCitySelect = (service) => {
    setServiceCitySelect(service);
    getPincodeData("", service);
  };

  const servicePincodeSelect = (service) => {
    let selectedPincode = [];
    service &&
      service.map((item) => {
        selectedPincode.push(item);
      });
    setPincode(selectedPincode);
  };

  const renderOptions = (val) => {
    if (val === "city") {
        
      return CheckBoxCity(sortedCity, onCitySelect, "City", citySelect);


         
    } else if (val === "pincode") {
      return CheckBoxCity(
        sortedPincode,
        servicePincodeSelect,
        "Pincode",
        selectedPincode
      );
    }
    if (val === "state") {
      return (
        <Col className="col-md-12">
          <Form.Item
            name="selectedState"
            label="State"
            rules={[
              {
                type: "array",
                required: true,
                message: "Please select your state!",
              },
            ]}
          >
            <AsyncSelect
              isMulti
              isDisabled={false}
              cacheOptions
              loadOptions={loadStateServices}
              // defaultOptions={state.states}
              onChange={(val) => serviceStateSelect(val)}
              placeholder="State"
              getOptionLabel={(opt) => opt._id}
              getOptionValue={(opt) => opt._id}
            />
          </Form.Item>
        </Col>
      );
    }
  };
  // console.log(newError, '')
  return (
    <div className="body-container-wrapper">
      <div className="body-container">
        {/* <Popup
          open={addVendorModal}
          closeOnDocumentClick={false}
          onClose={hide}
          closeOnEscape
          lockScroll={true}
        > */}
          <div className="users_header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-9">
                  <h4 className="cm_page_heading">Add New Vendor</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="shadow-lg p-3 mb-5 bg-white rounded">
            <Form
              name="crmForm"
              scrollToFirstError
              onFinish={onSubmit}
              layout={"vertical"}
              form={form}
              className="col-md-12"
            >
              <Row>
                <Col className="col-md-6">
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input First Name!",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col className="col-md-6">
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Last Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="col-md-6">
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </Col>
                <Col className="col-md-6">
                  <Form.Item
                    name="mobile"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        validator: (_, value) =>
                          Number(value) && value.length == 10
                            ? Promise.resolve()
                            : Promise.reject(
                                "Please enter valid mobile Number"
                              ),
                      },
                    ]}
                  >
                    <Input
                      placeholder="Phone Number"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {labels.map((label, index) => {
                return <Row>{renderOptions(label)}</Row>;
              })}

              <Form.Item>
                <Button key="submit" htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        {/* </Popup> */}
      </div>
    </div>
  );
};

export default addVendor;
function CheckBoxCity(
  sortedCity,
  onChange,
  label,
  selectedValue,
  onCheckAllChange
) {
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const valuesFromApi = sortedCity;


  let cities = [];
  Object.keys(sortedCity).map((item) => {
    let a = [];

    cities.push(
      <Fragment>
        <Row>
          <Collapse>
            <Panel header={item} key="2">
              <Row>
                {" "}
                <Col span={30}>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  >
                    Select All
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                {" "}
                {
                  <Checkbox.Group
                    options={sortedCity[item]}
                    // defaultValue={["Pear"]}
                    onChange={onChange}
                    checked={true}
                  />
                }
              </Row>
            </Panel>
          </Collapse>
        </Row>
      </Fragment>
    );
  });
  return (
    <Col>
      <Form.Item
        name="selectedPincode"
        label={label}
        rules={[
          {
            type: "array",
            required: true,
            message: "Please select your city!",
          },
        ]}
      >
        <Checkbox.Group>
          <Row>{cities}</Row>
        </Checkbox.Group>
      </Form.Item>
    </Col>
  );
}
