import axios from 'axios';
import API from '../Api';

export function getTimeSlotList(params) {
    return axios.get(API.GET_ADMIN_TIME_SLOT_LIST, params)
}

export function updateTimeSlot(params) {
    return axios.post(API.UPDATE_ADMIN_TIME_SLOT, params)
}

export function deleteTimeSlot(params) {
    return axios.post(API.DELETE_ADMIN_TIME_SLOT, params)
}