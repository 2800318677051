import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { getFitnessPlanByAdmin } from '../../config/services/fitnessPlanService';
import { LoaderSvg } from '../Reusable';
import HOC from '../../HOC';
import FitnessPlanCard from './../../components/common/FitnessPlan';


class ViewTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fitnessExercise: [],
            loader: false,
        }
    }

    componentDidMount() {
        this.setState({
            planOfUserId: this.props.match.params.id
        }, () => {
            this.getFitnessPlanByAdmin()
        })
    }

    getFitnessPlanByAdmin = () => {
        const { planOfUserId } = this.state
        let paramsObj = {
            params: {
                planOfUserId
            }
        }
        this.setState({ loader: true })
        getFitnessPlanByAdmin(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        fitnessExercise: res.data.responseData.result.fitnessExerciseObject,
                        loader: false
                    })
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                }
            })
    }

    render() {
        let { fitnessExercise, loader, templateTitle } = this.state
        return (
            <HOC >

                {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                <div className="body-container-wrapper">
                    <div className="body-container">

                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/users">Users</Link></li>
                            <li className="breadcrumb-item">Fitness Plan</li>
                        </ol>
                        {
                            fitnessExercise.length > 0 ?
                                <div className="row">
                                    {

                                        fitnessExercise.map(item => (
                                            <FitnessPlanCard item={item}/>
                                        ))

                                    }
                                </div>
                                :
                                <div className="mt-5">
                                    <h4 className="text-center">No Fitness Plan</h4>
                                </div>
                        }
                    </div>
                </div>
            </HOC >
        )
    }
}

export default ViewTemplate;