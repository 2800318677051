import React, { Component, Fragment } from 'react';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { Link } from 'react-router-dom';
import { getServiceList, addRequestsFromExcel, updateRequestsFromExcel, findExistingOrderIdFromExcel, addMultipleRequestsByAdmin, addRequestByAdmin, deleteRequest, assignRequestToVendor, updateRequestStatus, markSelfDone, exportServiceRequest, updateRequestByAdmin, generateTemplate, genericImport } from '../../config/services/admin_Services';
import { getAllVendors } from '../../config/services/vendorService';
import { LoaderSvg } from '../Reusable'
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { Button, Modal, FormControl, Form, Alert, FormGroup, FormLabel, Spinner, InputGroup, FormCheck, Row } from 'react-bootstrap';
import {ServiceTypes, SearchTypes, OptionToHide, GYM_TYPE} from './Constants';
import Select from 'react-select';
import { DatePicker, Comment, Avatar } from 'antd';
import UserJobDetails from '../profile/UserJobDetails';
import PopUpModal from '../../components/common/PopUpModal';
import { getAllPincodes, getAllStates, getAllCitys } from '../../config/services/servicePincodes'
import { addNoteToServicerequest } from '../../config/services/ProductServiceIncident';
import Loader from '../loader';
import { UserOutlined } from '@ant-design/icons';
import Popup from "reactjs-popup";
import { getCRMAgentList, assignRequestToAgent } from '../../config/services/CRMAgent';
import { Switch } from 'antd';
import _ from 'lodash';
import {paymentTypeOptions, serviceCategoryFilterOptions} from "../utils/Util";
const { removeDuplicates, validString } = require("../utils/Util.js");
const { serviceTypeOptions, statusFilterOptions, sourceFilterOptions, getSortingIcon, statusOptions} = require("../utils/Util.js");
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

let columnDetails = [
    { fieldName: "S.No." },
    { fieldName: "Creation Date", isSortable: true, dataIndex: "created" },

    { fieldName: "Shipment Id" },
    { fieldName: "Request Id" },
    { fieldName: "Commercial Request Id" },
    { fieldName: "Order Id" },
    { fieldName: "Customer Name" },
    { fieldName: "Gym Name" },
    { fieldName: "Gym Type" },
    { fieldName: "Pincode" },
    { fieldName: "Customer Address" },
    { fieldName: "City " },
    { fieldName: "State" },
    { fieldName: "TAT Days" },
    { fieldName: "Email" },
    { fieldName: "Self Done Incentive" },
    { fieldName: "Contact No." },
    { fieldName: "Alternate Contact No." },
    // { fieldName: "Customer Type" },
    { fieldName: "Brand" },
    { fieldName: "Model" },
    { fieldName: "Vertical" },
    { fieldName: "Spare Received" },
    { fieldName: "Service Category" },
    { fieldName: "Source" },
    { fieldName: "Payment Type" },
    { fieldName: "Service Amount" },
    { fieldName: "Service Type" },
    // { fieldName: "Product Service" },
    { fieldName: "Self Done", dataIndex: "selfDone" },
    { fieldName: "Product Name" },
    { fieldName: "Installation Message Sent At" },
    { fieldName: "Installation Payment Link" },
    { fieldName: "Installation Amount Received At" },
    { fieldName: "Assign Vendors" },
    { fieldName: "Assign CRM Agent" },
    { fieldName: "Status" },
    { fieldName: "Latest Job Status" },
    //{ fieldName: "Purchase Date", isSortable: true, dataIndex: "purchaseDate" },
    { fieldName: "Delivery Date" },
    { fieldName: "Dispatched warehouse name" },
    { fieldName: "Dispatch Ref No" },
    { fieldName: "Vehicle No" },
    { fieldName: "Driver Contact No" },
    { fieldName: "Delivery ETA" },
    { fieldName: "Delivery Request Notes" },
    { fieldName: "Issue Type" },
    { fieldName: "Issue Details" },
    { fieldName: "Issue Images/Videos" },
   // { fieldName: "Registration Date", isSortable: true, dataIndex: "registrationDate" },
   { fieldName: "Closure Date" },
   { fieldName: "TAT Date" },
   { fieldName: "TAT Performance" },
    { fieldName: "Action" },
    { fieldName: "Notes" },
];

let columnDetails1 = [
    { fieldName: "S.No." },
    { fieldName: "Creation Date", isSortable: true, dataIndex: "created" },

    { fieldName: "Shipment Id" },
    { fieldName: "Request Id" },
    { fieldName: "Commercial Request Id" },
    { fieldName: "Order Id" },
    { fieldName: "Customer Name" },
    { fieldName: "Gym Name" },
    { fieldName: "Gym Type" },
    { fieldName: "Pincode" },
    { fieldName: "Customer Address" },
    { fieldName: "City " },
    { fieldName: "State" },
    { fieldName: "TAT Days" },
    { fieldName: "Email" },
    { fieldName: "Self Done Incentive" },
    // { fieldName: "Contact No." },
    // { fieldName: "Alternate Contact No." },
    // { fieldName: "Customer Type" },
    { fieldName: "Brand" },
    { fieldName: "Model" },
    { fieldName: "Vertical" },
    { fieldName: "Spare Received" },
    { fieldName: "Service Category" },
    { fieldName: "Source" },
    { fieldName: "Payment Type" },
    { fieldName: "Service Amount" },
    { fieldName: "Service Type" },
    // { fieldName: "Product Service" },
    // { fieldName: "Self Done", dataIndex: "selfDone" },
    // { fieldName: "Assign Vendors" },
    // { fieldName: "Assign CRM Agent" },
    { fieldName: "Product Name" },
    { fieldName: "Installation Message Sent At" },
    { fieldName: "Installation Payment Link" },
    { fieldName: "Installation Amount Received At" },
    { fieldName: "Status" },
    { fieldName: "Latest Job Status" },
    //{ fieldName: "Purchase Date", isSortable: true, dataIndex: "purchaseDate" },
    { fieldName: "Delivery Date" },
    { fieldName: "Dispatched warehouse name" },
    { fieldName: "Dispatch Ref No" },
    { fieldName: "Vehicle No" },
    { fieldName: "Driver Contact No" },
    { fieldName: "Delivery ETA" },
    { fieldName: "Delivery Request Notes" },
    { fieldName: "Issue Type" },
    { fieldName: "Issue Details" },
    { fieldName: "Issue Images/Videos" },
    // { fieldName: "Registration Date", isSortable: true, dataIndex: "registrationDate" },
    { fieldName: "Closure Date" },
    { fieldName: "TAT Date" },
    { fieldName: "TAT Performance" },
    // { fieldName: "Action" },
    { fieldName: "Notes" },
];

const states = []

class AdminService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNo: 1,
            count: 10,
            statePageNo: 0,
            stateCount: 1000,
            cityPageNo: 0,
            cityCount: 2000,
            loader: true,
            UserSearch: '',
            sortField: 'created',
            sortFieldIcon: false,
            sortType: 2,
            loading: false,
            openAddServicePopup: false,
            responseUpdateInfoViaExcelSheetPopup: false,
            serviceRequestsList: [],
            serviceRequestsCount: 0,
            validationError: '',
            importExcelPopup: false,
            importExcelUpdateInfoPopup: false,
            isUploading: false,
            requestsExcelSheet: '',
            validatedServiceRequests: [],
            validateExistingRequestsPopup: false,
            firstName: '',
            lastName: '',
            email: '',
            selfDoneIncentiveCharge:"0",
            countryCode: null,
            mobileNo: null,
            alternatePhoneNo:null,
            orderId: '',
            shipmentID: '',
            modelNo: '',
            deliveryDate: null,
            address: '',
            issue: '',
            spareReceived:'',
            serviceType: '',
            service: '',
            mailingStreet: '',
            mailingCity: '',
            mailingState: '',
            pincode: null,
            selectedService: '',
            searchField: '',
            searchValue: '',
            searchError: '',
            deleteRequestPopup: false,
            selectedRequest: {},
            vendors: [],
            assignVendorPopup: false,
            selectedVendor: {},
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
            statusFilter: "",
            serviceCategory: null,
            serviceTypeFilter: "",
            serviceCategoryFilter: "",
            sourceFilter: "",
            sortInfo: {},
            badEntryErrors: [],
            productWebsite: null,
            addNotes: false,
            notes: '',
            showNotes: false,
            apiCalled: false,
            closePopUp: true,
            sourceWebsite: null,
            paymentType: "",
            crmAgents: [],
            allstates: [],
            citys: [],
            pincodes: [],
            addEditPopupHeading: '',
            importGeneric: false,
            genericImportData: {},
            productServices: {},
            imagesViewPopup: false,
            automaticVendorAllocation: false,
            automaticSuggestionOnly: false,
            filteredData:{},
            enableAddiInputBox:false,
            selfDoneStatusPopup:false,
            serviceAccessibleOps:[]
        }
        this.timer = 1;
    }

    componentDidMount() {
        this.getServiceList();
        this.getAllStates();
        this.loadCRMAgent()
        
    }

    getAllStates = () => {
        const { statePageNo, stateCount } = this.state;
        let params = { search: '', sortField: "_id", sortType: 1, pageNo: statePageNo, count: stateCount }
        getAllStates({ params })
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let data = res.data.responseData
                    let totalCount = data.totalCount;
                    let pgno = statePageNo + 1;

                    if (data.result && data.result.length > 0) {
                        data.result.forEach(key => {
                            states.push({
                                label: key._id,
                                value: key._id,
                            })
                        })
                    }

                    this.setState({ searchField: 0, statePageNo: pgno, allstates: states, citys: [], pincodes: [], mailingCity: '', pincode: '' }, function () {
                        if (totalCount > (pgno * stateCount)) {
                            this.getAllStates();
                        }
                    });
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleSteteChange = (value, fieldName, callListApi) => {
        this.setState({ [fieldName]: value, cityFilter: "", cityFilterData: [], pincodeFilter: "", pincodeFilterData: [], apiCalled: true }, function () {
            if (callListApi) {
                this.getServiceList();
            }
            this.getAllCitys('statechagne', value);
        });
    }

    getAllCitys = (action, val) => {
        const { mailingState, stateFilter, citySearch, cityPageNo, cityCount } = this.state;
        let params = { sortField: "_id", sortType: 1, pageNo: cityPageNo, count: cityCount }
        if (action == 'statechagne') {
            params.states = val;
            params.citySearch = '';
        }
        if (action == 'citychagne') {
            params.states = stateFilter;
            params.citySearch = val;
        }

        return getAllCitys({ params })
            .then(res => {
                if (res && res.data.statusCode === 1) {
                    let data = res.data.responseData
                    let citys = []
                    let option = []
                    if (data.result && data.result.length > 0) {
                        data.result.forEach(state => {
                            citys.push({
                                label: state._id,
                                value: state._id
                            })
                        })
                    }

                    this.setState({ "citys": citys, "cityData": citys, pincodes: [], mailingCity: '', pincode: '', apiCalled: false })
                    return citys;
                } else {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    handleCityChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, pincodeFilter: "", pincodeFilterData: [], apiCalled: true }, function () {
            this.getAllPincodes('cityChange', value);
        });
    }

    getAllPincodes = (action, val) => {
        const { mailingState, mailingCity, stateFilter, cityFilter, cityCount } = this.state;
        let params = { sortField: "_id", sortType: 1, pageNo: 0, count: cityCount }
        if (action == 'cityChange') {
            params.stateSearch = mailingState;
            params.citySearch = mailingCity;
        }
        if (action == 'cityChangeSearch') {
            params.stateSearch = stateFilter;
            params.citySearch = cityFilter;
        }
        if (action == 'pincodeChagne') {
            params.stateSearch = stateFilter;
            params.citySearch = cityFilter;
            params.pincodeSearch = val;
        }
        return getAllPincodes({ params })
            .then(res => {
                if (res && res.data.statusCode === 1) {
                    let data = res.data.responseData[0];
                    let pincodes = []
                    if (data.result && data.result.length > 0) {
                        data.result.forEach(pin => {
                            pincodes.push({
                                label: pin.pincode,
                                value: pin.pincode,
                            })
                        })
                    }

                    this.setState({ "pincodes": pincodes, pincodeData: pincodes, pincode: '', pincodeFilter: "", pincodeFilterData: [], apiCalled: false })
                    return pincodes;
                } else {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    handlePincodeChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, apiCalled: false }, function () { });
    }


    handleAddServicePopup = (heading) => {
        let { isFromUser, profile } = this.props;
        this.setState({addEditPopupHeading: heading})
        if (isFromUser) {
            this.setState({
                openAddServicePopup: !this.state.openAddServicePopup,
                firstName: profile["firstName"],
                countryCode: profile["countryCode"],
                lastName: profile["lastName"],
                mobileNo: profile["mobileNo"],
                alternatePhoneNo: profile["alternatePhoneNo"],
                email: profile["email"]
            })

        }
        else {
            this.setState({ openAddServicePopup: !this.state.openAddServicePopup })
        }
    }


    handleChange = (e) => {
        let { name, value } = e.target
        this.setState({ [name]: value, validationError: '' })
    }

    handleChangeWithValidString = (e) => {
        let { name, value } = e.target
        let isString = validString(value);
        // if(isString || value == '') {
        //     this.setState({ [name]: value, validationError: '' })
        // }else{
        //     this.setState({ [name]: value, validationError: '' })
        // }      
        this.setState({ [name]: value })  
    }

    closeModal = () => {
        this.setState({
            openAddServicePopup: false,
            openViewAdmin: false,
            viewAdminDetails: {},
            firstName: "",
            lastName: "",
            email: "",
            selfDoneIncentiveCharge:"0",
            countryCode: "",
            mobileNo: "",
            alternatePhoneNo: "",
            validationError: "",
            roleId: "",
            orderId:'',
            shipmentID:'',
            modelNo:'',
            deliveryDate: null,
            importExcelPopup: false,
            responseUpdateInfoViaExcelSheetPopup: false,
            importExcelUpdateInfoPopup: false,
            isUploading: false, requestsExcelSheet: '', validatedServiceRequests: [], validateExistingRequestsPopup: false, selectedService: '', searchError: '',
            deleteRequestPopup: false, selectedRequest: {}, assignVendorPopup: false, selectedVendor: {}, badEntryErrors: [], productWebsite: null, validated: false,
            addNotes: false,
            showNotes: false,
            citys: [],
            pincodes: [],
            mailingState: '',
            mailingCity: '',
            pincode: '',
            mailingStreet:'',
            address:'',
            service: '',
            issue:'',
            spareReceived:'',
            vertical:'',
            company:'',
            sourceWebsite:'',
            paymentType:'',
            importGeneric: false,
            genericImportData: {},
            imagesViewPopup: false,
            productServices: {},
            automaticSuggestionOnly: false,
            automaticVendorAllocation: false
        })
        // this is added to refresh the data 
        // from the DB to get real time notes
        this.getServiceList();
    }


    getVendors = (val, pincodeVal) => {
        this.setState({ vendors: [] })
        let paramsobj = { params: { count: 0, pageNo: 0, search: val, pincodeFilter: pincodeVal } }

        return getAllVendors(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {

                    let result = res.data.responseData.vendors;
                    let vendors = []
                    result && result.map(vendor => {
                        vendors.push({ label: `${vendor.firstName} ${vendor.lastName}`, value: vendor._id })
                    })
                    this.setState({ vendors })
                    return vendors
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    loadVendors = (val, pincode) => {
        return this.getVendors(val, pincode);
    }

    loadVendorsFocus = (val) => {
        return this.getVendors("", val)
    }


    loadOptionByval = (type, value) => {
        if (type === "pincode") {
            // return this.getDropDownData(value, "pincodeSearch", "pincodeData");
            return this.getAllPincodes('pincodeChagne', value);
        } else if (type === "city") {
            return this.getAllCitys('citychagne', value);
        } else {
            return null;
        }
    }

    loadPincodeByval = (value) => {
        if(value.length > 5) {        
            const { mailingState, mailingCity, stateFilter, cityFilter, cityCount, citys } = this.state;
            let params = { sortField: "_id", sortType: 1, pageNo: 0, count: cityCount }
            params.pincodeSearch = value;

            return getAllPincodes({ params })
                .then(res => {
                    if (res && res.data.statusCode === 1) {                    
                        let data = res.data.responseData[0];
                        if (data.result && data.result.length > 0) {
                            let pincodes = []
                            let citys = []
                            data.result.forEach(pin => {
                                pincodes.push({
                                    label: pin.pincode,
                                    value: pin.pincode,
                                })
                                
                                citys.push({
                                    label: pin.city,
                                    value: pin.city,
                                })

                                this.setState({ mailingState: pin.state, "citys": citys, "cityData": citys, mailingCity: pin.city, "pincodes": pincodes, pincodeData: pincodes, pincode: '', pincodeFilter: "", pincodeFilterData: [], apiCalled: false }, function () { });
                            })
                            return pincodes;
                        }    
                        // this.setState({ "citys": citys, "cityData": citys, pincodes: [], mailingCity: '', pincode: '', apiCalled: false })
                        // this.setState({  "pincodes": pincodes, pincodeData: pincodes, pincode: '', pincodeFilter: "", pincodeFilterData: [], apiCalled: false })
                    } else {
                        console.log(res.data.error.errorMessage)
                    }
                })
        }
    }

    getDropDownData = (val, searchField, stateField) => {
        let paramsobj = { params: { count: 100, pageNo: 0, [searchField]: val } }
        return getAllPincodes(paramsobj).then(res => {
            if (res && res.data.statusCode === 1) {
                let data = res.data.responseData
                let result = data[0].result
                let option = []
                let removeDublicatess = removeDuplicates(result, searchField.replace("Search", ""));

                if (removeDublicatess && removeDublicatess.length > 0) {
                    removeDublicatess.forEach(item => {
                        option.push({
                            label: item[stateField.replace("Data", "")],
                            value: item._id,
                        })
                    })
                }
                this.setState({ [stateField]: option })
                return option
            } else if (res.data.statusCode == 0) {
                console.log(res.data.error.errorMessage)
            }
        })
    }

    getServiceList = (isExport) => {
        let { search, count, pageNo, sortInfo, searchField, searchValue, dateFrom, dateTo, statusFilter, serviceTypeFilter, sourceFilter,serviceCategoryFilter, pincodeFilter, cityFilter, stateFilter, serviceRequestsList, agentFilter } = this.state;
        let { fieldName, order } = sortInfo;
        let { isFromUser, userId } = this.props;
        let params
        if (this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_ONLY_COCO_FOFO)){
            params = {
                search, count, pageNo: pageNo - 1, sortType: order, sortField: fieldName,
                dateFrom: !isFromUser ? dateFrom ? moment(dateFrom).startOf('day').valueOf() : null : null,
                dateTo: !isFromUser ? dateTo ? moment(dateTo).endOf('day').valueOf() : null : null,
                statusFilter: statusFilter,
                serviceTypeFilter: '2',
                serviceCategoryFilter:2,
                sourceFilter: sourceFilter,
                reqOfUserId: userId,
                pincodeFilter: pincodeFilter,
                cityFilter: cityFilter,
                stateFilter: stateFilter,
                agentFilter: agentFilter,
                searchField: searchField.value,
                searchValue: searchValue.trim(),
                gymType: GYM_TYPE.FRANCHISEE + "," + GYM_TYPE.COMPANY_OWNED
            }
        }else {
            params = {
                search, count, pageNo: pageNo - 1, sortType: order, sortField: fieldName,
                dateFrom: !isFromUser ? dateFrom ? moment(dateFrom).startOf('day').valueOf() : null : null,
                dateTo: !isFromUser ? dateTo ? moment(dateTo).endOf('day').valueOf() : null : null,
                statusFilter: statusFilter,
                serviceTypeFilter: serviceTypeFilter,
                serviceCategoryFilter:serviceCategoryFilter,
                sourceFilter: sourceFilter,
                reqOfUserId: userId,
                pincodeFilter: pincodeFilter,
                cityFilter: cityFilter,
                stateFilter: stateFilter,
                agentFilter: agentFilter,
                searchField: searchField.value,
                searchValue: searchValue.trim()
            }
        }
        if (isExport) {
            if (serviceRequestsList.length > 0) {
                exportServiceRequest({ params }).then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.setState({ apiCalled: false })
                        window.open(response.responseData.result.Location, "_blank");

                    } else if (response.statusCode == 0) {
                        this.setState({ apiCalled: false })
                        toast.error(response.error.errorMessage)
                    }
                })
            }
            else {
                this.setState({ apiCalled: false })
                toast.error("No data available to export")
            }
        }
        else {
            getServiceList({ params })
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        this.setState({
                            serviceRequestsList: response.responseData.result.productServices,
                            serviceRequestsCount: response.responseData.result.productServiceCount,
                            loader: false,
                            apiCalled: false
                        })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false })
                        toast.error(response.error.errorMessage)
                    }
                })
        }
    }


    importHandler = () => {
        this.setState({ importExcelPopup: true })
    }

    importGereric = () => {
        this.setState({ importGeneric: true })
    }

    generateLatestTemplate = () => {
        this.setState({loader: true})
        generateTemplate()
        .then(res => {
            let response = res.data;
            if (response.statusCode === 1) {
                this.setState({ apiCalled: false, loader: false })
                window.open(response.responseData.result.Location, "_blank");
            } else if (response.statusCode == 0) {
                this.setState({ apiCalled: false, loader: false })
                toast.error(response.error.errorMessage)
            }
        })
        .catch(err=>{
            console.log('err',err);
        })
    }

    importUpdateInfoHandler = () => {
        this.setState({ importExcelUpdateInfoPopup: true })
    }

    handleFileDrop = (accepted, rejected) => {
        if (accepted && accepted.length) {
            this.setState({ requestsExcelSheet: accepted[0], validationError: '' })
        }
        else if (rejected && rejected.length) {
            this.setState({ validationError: "This file format is not supported. Format should be 'xls, xlsx or csv'", requestsExcelSheet: '' })
        }
    }


    submitExcelUpload = (e) => {
        e.preventDefault();
        const { requestsExcelSheet, productWebsite } = this.state;

        let params = new FormData()
        params.append('requestsExcelSheet', requestsExcelSheet)
        params.append('productWebsite', productWebsite)


        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Choose a valid file!' })
        }
        else {
            this.setState({ validationError: '', isUploading: true })
            addRequestsFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getServiceList();
                        this.closeModal();
                        this.setState({ isUploading: false })
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    submitValidateExcelUpload = (e) => {
        e.preventDefault();
        const { requestsExcelSheet, productWebsite } = this.state;
        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Please select a file!' })
            return;
        }
        else {
            let params = new FormData()
            params.append('requestsExcelSheet', requestsExcelSheet);
            params.append('productWebsite', productWebsite)
            this.setState({ validationError: '', isUploading: true })
            findExistingOrderIdFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        if (response.responseData.result && response.responseData.result.alreadyExisting && response.responseData.result.alreadyExisting.length) {
                            let validatedServiceRequests = []
                            let { alreadyExisting, requestParams } = response.responseData.result

                            requestParams.map(serviceRequest => {
                                if (alreadyExisting.find(obj => obj.orderId === serviceRequest.orderId)) {
                                    serviceRequest.alreadyExists = true
                                    validatedServiceRequests.push(serviceRequest)
                                } else {
                                    serviceRequest.alreadyExists = false
                                    validatedServiceRequests.push(serviceRequest)
                                }
                            })

                            this.setState({ isUploading: false, importExcelPopup: false, requestsExcelSheet: '', validatedServiceRequests, validateExistingRequestsPopup: true })
                        }
                        else {
                            this.submitExcelUpload(e)
                        }
                    }
                    else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage, badEntryErrors: response.error.errors ? response.error.errors : [] })
                    }
                })
        }
    }

    // generic import
    submitGenericImport = (e) => {
        e.preventDefault();
        const { requestsExcelSheet, automaticSuggestionOnly = false, automaticVendorAllocation = false } = this.state;
        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Please select a file!' })
            return;
        }
        else {
            let params = new FormData()
            params.append('allocationdata', requestsExcelSheet);
            params.append('automaticAllocation', automaticVendorAllocation)
            params.append('automaticSuggestionOnly', automaticSuggestionOnly)
            this.setState({ validationError: '', isUploading: true })
            genericImport(params)
                .then(response => {
                    if (response && response.data && response.data.statusCode == 1) {
                        this.setState({genericImportData:response.data.responseData, isUploading: false})
                    }
                    else if (response.data.statusCode == 0) {
                        this.setState({ isUploading: false });
                        window.open(response.data.error.result.Location, "_blank");
                        this.setState({ validationError: response.data.error.result, badEntryErrors: response.data.error.result ? response.data.error.result : [] })
                    }
                    /*
                     * unchecking auto allocation flags in all the cases
                     */
                    this.setState({ automaticSuggestionOnly: false, automaticVendorAllocation: false})
                })
        }
    }

    submitExcelUploadUpdateInfo = (e) => {
        e.preventDefault();
        const { requestsExcelSheet } = this.state;

        let params = new FormData()
        params.append('requestsExcelSheet', requestsExcelSheet)

        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Choose a valid file!' })
        }
        else {
            this.setState({ validationError: '', isUploading: true })
            updateRequestsFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        let validatedServiceRequests = []
                        this.getServiceList();
                        response.responseData.failQuery.length > 0 && response.responseData.failQueryRecords.map(serviceRequest => {
                            validatedServiceRequests.push(serviceRequest)
                        })

                        this.setState({ isUploading: false, importExcelUpdateInfoPopup: false, responseUpdateInfoViaExcelSheetPopup: true, validatedServiceRequests: validatedServiceRequests, successQuery: response.responseData.successQuery.length, failQuery: response.responseData.failQuery.length })
                    } else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    handleDeleteRequestFromExcel = (reqDetails, i) => {
        let validatedServiceRequests = Array.from(this.state.validatedServiceRequests)
        validatedServiceRequests.splice(i, 1);
        this.setState({ validatedServiceRequests, validationError: '' })
    }


    submitAddMultipleRequestsByAdmin = () => {
        let { validatedServiceRequests, productWebsite } = this.state
        if (validatedServiceRequests.find(req => req.alreadyExists === true)) {
            this.setState({ validationError: "Please remove all the invalid data first!" })
        } else {
            let params = {
                requestsArray: validatedServiceRequests,
                productWebsite: productWebsite,

            }
            this.setState({ isUploading: true })

            addMultipleRequestsByAdmin(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.getServiceList();
                        this.closeModal();
                        this.setState({ isUploading: false })
                        toast.success(response.responseData.message)
                    } else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                })
        }
    }

    handleServiceTypeSelector = (service) => {
        this.setState({ selectedService: service })
    }
    handleSourceSelector = (productWebsite) => {
        this.setState({ sourceWebsite: productWebsite })
    }
    handlePaymentTypeSelector = (paymentType) => {
        this.setState({ paymentType: paymentType })
    }
    handleServiceCategorySelector = (serviceCategory) => {
        this.setState({ serviceCategory: serviceCategory })
    }


    submitAddRequest = (event) => {
        let isError = false;
        event.preventDefault();
        event.stopPropagation();
        let { firstName, lastName, email, countryCode, mobileNo,alternatePhoneNo,orderId,shipmentID, modelNo, deliveryDate, address, issue,spareReceived, mailingStreet, mailingCity, mailingState, pincode, selectedService, company, vertical, sourceWebsite, serviceCategory,paymentType, serviceAmount} = this.state;

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            isError = true;
            this.setState({ validated: true })
            return;

        }
        if (!deliveryDate || Object.keys(selectedService).length <= 0 || Object.keys(selectedService).length <= 0) {
            isError = true;
            this.setState({ validated: true })
            return;
        }
        this.setState({ isSubmitClicked: true });

        let params = {
            firstName, lastName, email, countryCode, mobileNo,alternatePhoneNo,orderId,shipmentID, modelNo, deliveryDate, address, issue,spareReceived, serviceType: selectedService.value, service: selectedService.label, mailingStreet, mailingCity, mailingState, pincode, company, vertical, productWebsite: sourceWebsite && sourceWebsite.value, serviceCategory:serviceCategory && serviceCategory.value, paymentType:paymentType && paymentType.value,serviceAmount
        }

        

        if (!isError) {
            if(this.state.addEditPopupHeading === 'Edit Service'){
                params.requestId = this.state.requestId;
                updateRequestByAdmin(params)
                    .then(res => {
                        let response = res.data;
                        if (response.statusCode == 1) {
                            this.getServiceList();
                            this.closeModal();
                            this.setState({ isSubmitClicked: false })
                            toast.success(response.responseData.message)
                        } else if (response.statusCode == 0) {
                            this.setState({ isSubmitClicked: false })
                            this.setState({ validationError: response.error.errorMessage })
                        }
                    })
                    .catch(err=>{
                        console.log('error',err)
                    })
            }else{
                
                addRequestByAdmin(params)
                    .then(res => {
                        let response = res.data;
                        if (response.statusCode == 1) {
                            this.getServiceList();
                            this.closeModal();
                            this.setState({ isSubmitClicked: false })
                            toast.success(response.responseData.message)
                        } else if (response.statusCode == 0) {
                            this.setState({ isSubmitClicked: false })
                            this.setState({ validationError: response.error.errorMessage })
                        }
                    })
                    .catch(err=>{
                        console.log('error',err)
                    })
            }
        }
    }

    handleSearchType = (search) => {
        this.setState({ searchField: search, searchValue: '', searchError: '', })
    }

    handleSearch = (e) => {
        let { value } = e.target
        let { searchField } = this.state
        this.setState({ searchValue: value });

        if (!searchField) {
            this.setState({ searchError: 'Select Search Field' })
        } else {
            e.persist();
            this.setState({ searchError: '', apiCalled: true });
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.getServiceList();
                this.setState({ apiCalled: false })
            }, 400)

        }
    }

    handleEditRequestPopup = (serviceData, heading)=>{
        this.setState({
            openAddServicePopup: true,
            firstName: serviceData.firstName,
            lastName: serviceData.lastName,
            email: serviceData.email,
            selfDoneIncentiveCharge:serviceData.selfDoneIncentiveCharge,
            mobileNo: serviceData.mobileNo,
            alternatePhoneNo: serviceData.alternatePhoneNo,
            orderId: serviceData.orderId,
            shipmentID: serviceData.shipmentID,
            modelNo: serviceData.modelNo,
            deliveryDate: serviceData.deliveryDate ? serviceData.deliveryDate : null,
            address: serviceData.address,
            issue: serviceData.issue,
            spareReceived: serviceData.spareReceived,
            serviceType: serviceData.serviceType,
            selectedService:serviceData.service? ServiceTypes.find(obj => {
                return (obj.label === serviceData.service)
            }): '',
            mailingStreet: serviceData.mailingStreet,
            mailingCity: serviceData.mailingCity,
            mailingState: serviceData.mailingState ? serviceData.mailingState : '',
            pincode: serviceData.pincode ? serviceData.pincode : '',
            vertical: serviceData.vertical,
            company: serviceData.company,
            sourceWebsite: sourceFilterOptions.find(obj => {
                return obj.value === serviceData.productWebsite
            }),
            paymentType: paymentTypeOptions.find(obj => {
                return obj.value === serviceData.paymentType
            }),
            serviceAmount: serviceData.serviceAmount,
            addEditPopupHeading: heading,
            requestId: serviceData._id,
            countryCode: serviceData.fullMobileNo ? serviceData.fullMobileNo.split(' ')[0] : "",
            filteredData:serviceData
        })
    }

    handleDeleteRequestPopup = (request) => {
        this.setState({ deleteRequestPopup: true, selectedRequest: request })
    }

    openImageVideoView = (jobDetails) => {
        let { mediaUrl} = jobDetails;
        let { productServices } = this.state;
        
        productServices['image'] = mediaUrl ? mediaUrl : '';
        productServices['file_type'] = '';
        productServices['img_url'] = '';
        productServices['video_url'] = '';
        productServices['video_mimetype'] = '';

        if (mediaUrl && mediaUrl[0] && mediaUrl[0].url) {
            productServices['file_type'] = mediaUrl[0].file_type;
            productServices['img_url'] = mediaUrl[0].file_type == 'IMAGE' ? mediaUrl[0].url : mediaUrl[0].thumbnail;
            productServices['video_url'] = mediaUrl[0].url;
            productServices['video_mimetype'] = mediaUrl[0].mimetype;
        }

        this.setState({ imagesViewPopup: true, productServices });
    }

    openFileImageVideoView = (file) => {
        let { productServices } = this.state;
        productServices['file_type'] = '';
        productServices['img_url'] = '';
        productServices['video_url'] = '';
        productServices['video_mimetype'] = '';
        if (file && file.url) {
            productServices['file_type'] = file.file_type;
            productServices['img_url'] = file.file_type == 'IMAGE' ? file.url : file.thumbnail;
            productServices['video_url'] = file.url;
            productServices['video_mimetype'] = file.mimetype;
        }
        this.setState({ productServices });
    }

    handleAddNotesPopup = (request) => {
        this.setState({ addNotes: true, selectedRequest: request }, () => this.getServiceList())
    }

    handleViewNotesPopup = (request) => {
        this.setState({ showNotes: true, selectedRequest: request })
    }


    submitDeleteRequest = () => {
        let { selectedRequest } = this.state
        this.setState({ loader: true })

        deleteRequest({ requestId: selectedRequest._id })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getServiceList();
                    this.closeModal();
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })
    }

    handleAssignVendorPopup = (vendorDetails, request) => {
        this.setState({ assignVendorPopup: true, selectedVendor: vendorDetails, selectedRequest: request })
    }
    handleAgentPopup = (agentDetail, request) => {
        this.setState({ assignAgentPopup: true, selectedAgent: agentDetail, selectedRequest: request })

    }

    submitAssignRequest = () => {
        let { selectedVendor, selectedRequest } = this.state

        assignRequestToVendor({ vendorId: selectedVendor.value, requestId: selectedRequest._id })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getServiceList();
                    this.closeModal();
                    toast.success(response.responseData.message);
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })
    }

    handleDate = (val, key) => {
        this.setState({ [key]: moment(val).format() })
    }

    onWebsiteTypeSelect = (value) => {
        this.setState({ productWebsite: Number(value), validationError: '', badEntryErrors: [] })
    }

    onAutoAllocationCheckSelect = () => {
        this.setState({ automaticVendorAllocation: !this.state.automaticVendorAllocation })
    }

    onAutoSuggestionCheckSelect = () => {
        this.setState({ automaticSuggestionOnly: !this.state.automaticSuggestionOnly })
    }

    handleDateFilter = (date, dateField) => {
        switch (dateField) {
            case 'dateFrom':
                this.setState({ dateFrom: date })
                break

            case 'dateTo':
                this.setState({ dateTo: date })
                break
        }
    }
    
    resetFilter = () => { 
        this.setState({
            searchField: "",
            searchValue: "",
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(), sourceFilter: "", serviceTypeFilter: "",serviceCategoryFilter:"", statusFilter: "", pageNo: 1, Loader: true, pincodeFilter: "", cityFilter: "", stateFilter: "", agentFilter: "", agentFilterData: "",
            pincodeFilterData: [], cityFilterData: [], stateFilterData: []
        }, () => this.getServiceList())
    }

    handleFilterChange = (value, fieldName) => {
        this.setState({ [fieldName]: value, apiCalled: true }, () => this.getServiceList());

    }

    onHandleSelect = (data, fieldName) => {
        let fieldNameData = `${fieldName}Data`
        if (fieldName == "agentFilter") {
            this.setState({ [fieldName]: data.value, vendorFilterId: data._id, [fieldNameData]: data, apiCalled: true }, () => this.getServiceList())
        } else if (fieldName == "cityFilter") {
            this.setState({ [fieldName]: data.label, vendorFilterId: data._id, [fieldNameData]: data, pincodeFilter: "", pincodeFilterData: [], apiCalled: true }, function () {
                this.getServiceList();
                this.getAllPincodes('cityChangeSearch', data.value);
            })
        } else {
            this.setState({ [fieldName]: data.label, vendorFilterId: data._id, [fieldNameData]: data, apiCalled: true }, () => this.getServiceList())
        }
    }

    filterByDate = (date) => {
        let dateFrom = null, dateTo = null;
        if (date) {
            dateFrom = moment(date[0]).startOf('day').valueOf()
            dateTo = moment(date[1]).endOf('day').valueOf()
        }
        this.setState({ dateFrom, dateTo, apiCalled: true, pageNo: 1 }, () => { this.getServiceList() })
    }

    handleSort = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getServiceList());
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getServiceList() });
    onRequestClick = (requestId) => {
        this.setState({ productJob: true, selectedProductRequest: requestId });

    }
    changeStatus = (statusValue, id, customerId , request) => {
        if (statusValue === 7) {
            const selfDoneIncentiveCharge = request.selfDoneIncentiveCharge ? request.selfDoneIncentiveCharge : 0;
            this.setState({
                selfDoneIncentiveCharge: selfDoneIncentiveCharge,
            })
            this.setState({ selfDoneStatusPopup: true, selectedStatus: statusValue, selectedRequestId: id, selectedRequestCustomerId: customerId })
        } else {
            this.setState({ statusPopup: true, selectedStatus: statusValue, selectedRequestId: id, selectedRequestCustomerId: customerId })
        }
    }
    closeStatusPopup = () => {
        this.setState({ statusPopup: false, selectedStatus: "", selectedRequestId: "" })
    }

    closeStatusPopupSelfDone = () => {
        this.setState({ selfDoneStatusPopup: false, selectedStatus: "", selectedRequestId: "" ,enableAddiInputBox: false,selfDoneIncentiveCharge:0})
    }
    changeRequestStaus = () => {
        let { selectedRequestId, selectedStatus, selectedRequestCustomerId,selfDoneIncentiveCharge } = this.state;
        let paramsObj = {
            requestId: selectedRequestId,
            requestStatus: selectedStatus,
            customerId: selectedRequestCustomerId,
            selfDoneIncentiveCharge:selfDoneIncentiveCharge
        }
        if (!this.validateInputs(selfDoneIncentiveCharge)) {
            toast.error("Please enter valid Self Incentive amount")
            return;
        }
        updateRequestStatus(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ selectedRequestId: "", statusValue: "", statusPopup: false });
                    this.closeStatusPopupSelfDone();
                    this.getServiceList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }
    validateInputs = (value) =>{
        const numberRegex = /^[0-9]+$/;
        return numberRegex.test(value);
    }

    selfMarkPopup = (selectedId, selfDone) => {
        this.setState({ markSelfDonePopUP: !this.state.markSelfDonePopUP, selectedRequestId: selectedId ? selectedId : "", selfDone: selfDone ? false : true });
    }
    markSelfDone = () => {
        let { selectedRequestId, selfDone } = this.state;
        let paramsObj = {
            requestId: selectedRequestId,
            selfDone: selfDone
        }

        markSelfDone(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ selectedRequestId: "", markSelfDonePopUP: false });
                    this.getServiceList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    handleNotesOnChange = (e) => {
        this.setState({ notes: e.target.value })
    }

    addNotesToRequest = (note, requestId) => {
        let params = {
            note: note,
            requestId: requestId._id
        }
        addNoteToServicerequest(params).then(response => {
            if (response && response.data.statusCode === 1) {
                this.setState({ addNotes: false })
                toast.success(`Note added to request ${requestId._id}!`)
            } else {
                toast.error(`${response.data.error.errorMessage}!`)
            }
            this.setState({notes:''})
        })
    }
    exportHandler = () => {
        this.setState({ apiCalled: true, showHideExport: false }, () => this.getServiceList(true));
    }
    showHideExport = () => {
        this.setState({ showHideExport: true });
    }
    getCRMList = (val) => {
        this.setState({ crmAgents: [] })
        let paramsobj = { params: { count: 0, pageNo: 0, search: val } }
        return getCRMAgentList(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let crmAgentList = res.data.responseData.adminList;
                    let crmAgents = []
                    crmAgentList && crmAgentList.map(item => {
                        crmAgents.push({ label: `${item.firstName} ${item.lastName}`, value: item._id })
                    })
                    this.setState({ crmAgents })
                    return crmAgents;
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    loadCRMAgent = (val) => {
        return this.getCRMList(val)
    }
    loadCRMAgentFilter = (val) => {
        return this.getCRMList(val)
    }

    assignRequestToAgent = () => {
        let { selectedAgent, selectedRequest } = this.state;
        assignRequestToAgent({ agentId: selectedAgent.value, requestId: selectedRequest._id })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getServiceList();
                    this.setState({ assignAgentPopup: false })
                    toast.success(response.responseData.message);
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage, loader: false })
                }
            })

    }

    checkBoxChanged = (event) => {
        this.setState({enableAddiInputBox : !this.state.enableAddiInputBox})
    }
    checkValueExist =(serviceAccessibleOps,value) =>{
        if(_.isNil(serviceAccessibleOps))
            return  false
        else
            return serviceAccessibleOps.some(function (el) {
                return el.title === value
            });
    }


    handelIncentiveTextField = (e, type, field, length) => {
        this.setState({selfDoneIncentiveCharge : e.target.value})
    }
    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        if ( this.state.serviceAccessibleOps &&
            nextProps.serviceAccessibleOps &&
            this.state.serviceAccessibleOps.length < 1 &&
            nextProps.serviceAccessibleOps.length > 0 ) {
            this.setState({serviceAccessibleOps:nextProps.serviceAccessibleOps})
            window.setTimeout(function() {
                this.getServiceList(false)
            }.bind(this), 200);

        }
        return true
    }
    render() {
        const {
            pageNo,
            productJob,
            validationError,
            count,
            serviceRequestsList,
            serviceRequestsCount,
            importExcelPopup,
            importExcelUpdateInfoPopup,
            responseUpdateInfoViaExcelSheetPopup,
            isUploading,
            loader,
            validatedServiceRequests,
            validateExistingRequestsPopup,
            openAddServicePopup,
            deleteRequestPopup,
            vendors,
            assignVendorPopup,
            selectedVendor,
            productWebsite,
            selectedProductRequest,
            statusPopup,
            selfDoneStatusPopup,
            dateTo,
            dateFrom,
            sortInfo,
            badEntryErrors,
            validated,
            markSelfDonePopUP,
            addNotes,
            notes,
            showNotes,
            selectedRequest,
            apiCalled,
            crmAgents,
            assignAgentPopup,
            allstates,
            citys,
            pincodes,
            addEditPopupHeading,
            selectedService,
            importGeneric,
            imagesViewPopup,
            productServices
        } = this.state;

        let { fieldName, order } = sortInfo;
        let { isFromUser } = this.props;
        let productJobOpen = productJob;
        if (this.props.productJob) {
            productJobOpen = true
        }



        return (
            <HOC>
                {this.state.apiCalled && <div className="loader_wrapper"><LoaderSvg /></div>}
                {!productJobOpen &&
                    < div className={!isFromUser ? "body-container-wrapper" : ""}>
                        <div className="body-container">
                            <ol className="breadcrumb" style={{ display: isFromUser ? "none" : "" }}>
                                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item">Services</li>
                            </ol>
                            {loader ? <div className="loader_wrapper"><LoaderSvg /></div> : null}

                            <div className="users_header" style={{ display: isFromUser ? "none" : "" }} >
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h4 className="cm_page_heading">Service</h4>
                                        </div>
                                    </div>

                                    <div className="list-inline-item">

                                        <div className="row">

                                            <div className="col-md-12 align-items-baseline d-inline-flex">

                                                <ul className="list-inline d-flex flex-wrap">
                                                    <li className="list-inline-item d-flex flex-column mt-2 min-width-150 font11">
                                                        <label inline='true' className="mr-2">Filter By Date:</label>
                                                        <RangePicker
                                                            className=''
                                                            style={{ height: "2.8em" }}
                                                            size={"middle"}
                                                            onChange={(val) => this.filterByDate(val)}
                                                            value={dateTo && dateFrom && [moment(dateFrom), moment(dateTo)]}
                                                            defaultValue={[moment(dateFrom), moment(dateTo)]}
                                                        />
                                                    </li>

                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_SERVICE_TYPE) ? (
                                                        <li className="list-inline-item mt-2 min-width-150 font11">
                                                            <label inline='true' className="mr-2">Service Type:</label>
                                                            <Select
                                                                onChange={(e) => this.handleFilterChange(e.value, "serviceTypeFilter")}
                                                                placeholder="Service Type"
                                                                options={serviceTypeOptions}
                                                                value={serviceTypeOptions.find(element => element.value === this.state.serviceTypeFilter)}

                                                            />
                                                        </li>
                                                    ) : null}



                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_SERVICE_CATEGORY) ? (
                                                        <li className="list-inline-item mt-2 min-width-150 font11">
                                                            <label inline='true' className="mr-2">Service Category:</label>
                                                            <Select
                                                                onChange={(e) => this.handleFilterChange(e.value, "serviceCategoryFilter")}
                                                                placeholder="Service Category"
                                                                options={serviceCategoryFilterOptions}
                                                                value={serviceCategoryFilterOptions.find(element => element.value === this.state.serviceCategoryFilter)}

                                                            />
                                                        </li>
                                                    ) : null}


                                                    <li className="list-inline-item mt-2 min-width-150 font11">
                                                        <label inline='true' className="mr-2">Status:</label>
                                                        <Select
                                                            onChange={(e) => this.handleFilterChange(e.value, "statusFilter")}
                                                            placeholder="Status"
                                                            options={statusFilterOptions}
                                                            value={statusFilterOptions.find(element => element.value === this.state.statusFilter)}

                                                        />
                                                    </li>
                                                    <li className="list-inline-item mt-2 min-width-150 font11">
                                                        <label inline='true' className="mr-2">Platform:</label>
                                                        <Select
                                                            onChange={(e) => this.handleFilterChange(e.value, "sourceFilter")}
                                                            placeholder="Source"
                                                            options={sourceFilterOptions}
                                                            value={sourceFilterOptions.find(element => element.value === this.state.sourceFilter)}

                                                        />
                                                    </li>

                                                    <li className="list-inline-item mt-2 min-width-150 font11">
                                                        <label inline='true' className="mr-2">State:</label>
                                                        <Select
                                                            onChange={(e) => this.handleSteteChange(e.value, "stateFilter", 1)}
                                                            placeholder="Filter By State"
                                                            options={allstates}
                                                            value={this.state.stateFilter ? allstates.find(element => element.value === this.state.stateFilter) : null}
                                                        />
                                                    </li>

                                                    <li className="list-inline-item mt-2 min-width-150 font11">
                                                        <label inline='true' className="mr-2">City:</label>
                                                        <AsyncSelect
                                                            isDisabled={false}
                                                            // cacheOptions
                                                            value={this.state.cityFilterData ? this.state.cityFilterData : null}
                                                            loadOptions={(val) => this.loadOptionByval("city", val)}
                                                            defaultOptions={this.state.cityData}
                                                            onChange={(val) => this.onHandleSelect(val, "cityFilter")}
                                                            placeholder='Filter By City'
                                                        />
                                                    </li>
                                                    <li className="list-inline-item mt-2 min-width-150 font11">
                                                        <label inline='true' className="mr-2">Pincode:</label>
                                                        <AsyncSelect
                                                            isDisabled={false}
                                                            cacheOptions
                                                            value={this.state["pincodeFilterData"]}
                                                            loadOptions={(val) => this.loadOptionByval("pincode", val)}
                                                            defaultOptions={this.state.pincodeData}
                                                            onChange={(val) => this.onHandleSelect(val, "pincodeFilter")}
                                                            placeholder='Filter By Pincode'
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-2 d-flex align-items-center pl-0 pr-0">
                                        
                                            <div className="col-md-12">
                                                        {/* <label inline='true' className="mr-2">Agent:</label> */}
                                                        <AsyncSelect
                                                            cacheOptions
                                                            value={this.state["agentFilterData"]}
                                                            defaultOptions={crmAgents}
                                                            loadOptions={this.loadCRMAgentFilter}
                                                            // defaultOptions={services}
                                                            onChange={(val) => this.onHandleSelect(val, "agentFilter")}
                                                            placeholder='Filter By Agent'
                                                        />
                                                    </div>

                                                    
                                        </div>
                                        <div className="col-md-5 text-right mb-2">
                                            <div className="row align-items-center">
                                            <div className="col-md-11 cm_search">
                                            <InputGroup >
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        <Select
                                                            isSearchable
                                                            options={SearchTypes}
                                                            placeholder="Search Field"
                                                            onChange={(val) => this.handleSearchType(val)}
                                                            className="cm_min_filter_width"
                                                            value={this.state["searchField"]}
                                                        />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl id="search" className="h-auto" name='search' value={this.state["searchValue"]} placeholder="Search here......" onChange={this.handleSearch} />
                                            </InputGroup>
                                            </div>
                                            <div className="col-md-1">
                                                        <Button variant="outline-dark" style={{ marginBottom: '2px' }} className="mr-2 btn-reset" size='sm' onClick={this.resetFilter}> <i className="fa fa-refresh" /></Button>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 text-right">
                                            {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_GENERIC_IMPORT) ? (
                                                <button className="btn btn-primary mt-1" onClick={this.importGereric}>Generic Import</button>
                                            ) : null}

                                            {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_ADD_NEW_SERVICE) ? (
                                                <button className="btn btn-primary ml-1 mt-1" onClick={()=>this.handleAddServicePopup('Add Service')}>Add New Service</button>
                                            ) : null}

                                            {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_IMPORT) ? (
                                                <Button className='ml-1 mt-1' type="primary" onClick={this.importHandler}>Import</Button>
                                            ) : null}

                                            {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_BULK_EDIT) ? (
                                                <Button className='ml-1 mt-1' type="primary" onClick={this.importUpdateInfoHandler}>Bulk Edit</Button>
                                            ) : null}

                                            {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_EXPORT) ? (
                                                <Button disabled={apiCalled} className='ml-1 mt-1' type="primary" onClick={this.showHideExport}>Export</Button>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {isFromUser &&
                                <div className="col-md-3 text-right float-right mb-2">
                                    <button className="btn btn-primary btn-block" onClick={this.handleAddServicePopup}>Add New Service</button>
                                </div>}

                            <div className="table-responsive mb-4" style={{ minHeight: '100px' }}>
                                <table className="table table-bordered table-striped text-center">
                                    <thead>
                                        <tr>

                                            {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_TABLE_OPTION_COFO) ? (
                                                columnDetails.map((item, i) => {
                                                    let { isSortable, dataIndex } = item;
                                                    return isSortable ? <th scope="col" key={i} className="filter_text" onClick={() => this.handleSort(dataIndex)}>{item.fieldName} &nbsp;  <i className={getSortingIcon(dataIndex == fieldName && order)} /></th>
                                                        : <th scope="col" key={i}>{item.fieldName}</th>
                                                })
                                            ) : (columnDetails1.map((item, i) => {
                                                let { isSortable, dataIndex } = item;
                                                return isSortable ? <th scope="col" key={i} className="filter_text" onClick={() => this.handleSort(dataIndex)}>{item.fieldName} &nbsp;  <i className={getSortingIcon(dataIndex == fieldName && order)} /></th>
                                                    : <th scope="col" key={i}>{item.fieldName}</th>
                                            }))}
                                        </tr>

                                        {/* <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text">Name <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList('firstName')} // value="filterFirstName"></button></th>
                                        <th scope="col">Assigned Dietician</th>
                                        <th scope="col" className="filter_text">Email <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`} ></i><button onClick={() => this.handleSortList('email')} // value="filterEmail"></button></th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Diet plan</th>
                                    </tr> */}
                                    </thead>


                                    <tbody>
                                        {serviceRequestsList.map((request, index) => {
                                            let dateToRender = moment(request["created"]).add(Number(request["pincodeData"] && request["pincodeData"].tat) || 0, "days").format('Do MMM YY, h:mm:ss a');
                                            let calculteTatDay = moment(request["closeDate"]).diff(request["created"], 'days');
                                            var tat_day = request && request["pincodeData"] ? request["pincodeData"].tat : 0;
                                            
                                            return (
                                                
                                                <tr key={request._id}>
                                                    <td className="cm_no_wrap">{((index + 1) + ((pageNo - 1) * count))}</td>
                                                    <td className="cm_no_wrap">{request.created ? moment(request.created).format('Do MMM YY') : '-'}</td>
                                                    <td className="cm_no_wrap"> {request.shipmentID}</td>
                                                    <td className="cm_no_wrap"> {isFromUser && <Link to="#" onClick={() => this.onRequestClick(request._id, request.userId)}>{request._id}</Link> || (request._id)}</td>
                                                    <td className="cm_no_wrap"> {request.serviceRequestNo}</td>
                                                    <td className="cm_no_wrap"> {request.orderId}</td>
                                                   {/* <td className="cm_no_wrap"> {!request.isCommercialGymFlow && !isFromUser
                                                        && <Link to={`/service/${request.userId}/productService`}>{request.firstName + " " + request.lastName}</Link> || (request.firstName + " " + request.lastName)}</td>*/}

                                                    <td className="cm_no_wrap"> {!request.isCommercialGymFlow && !isFromUser?
                                                         <Link to={`/service/${request.userId}/productService`}>{request.firstName + " " + request.lastName}</Link> : !_.isNil(request.adminAsManager)? request.adminAsManager.firstName : "-"}</td>

                                                    <td className="cm_no_wrap"> {!_.isNil(request.gymDetails)? request.gymDetails.name : "-"}</td>
                                                    <td className="cm_no_wrap"> {!_.isNil(request.gymDetails)? request.gymDetails.type : "-"}</td>
                                                    <td className="cm_no_wrap"> {request.pincode}</td>
                                                    <td className="text-left" ><div>{request.mailingStreet}</div> </td>
                                                    <td className="cm_no_wrap"> {request.mailingCity}</td>
                                                    <td className="cm_no_wrap"> {request.mailingState}</td>
                                                    <td className="cm_no_wrap"> {request.pincodeData && request.pincodeData.tat}</td>
                                                    <td className="cm_no_wrap">{request.email}</td>
                                                    <td className="cm_no_wrap">{request.selfDoneIncentiveCharge}</td>

                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_TABLE_OPTION_COFO) ? (
                                                        <td className="cm_no_wrap">{request.mobileNo}</td>
                                                    ) : null}

                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_TABLE_OPTION_COFO) ? (
                                                        <td className="cm_no_wrap">{request.alternatePhoneNo}</td>
                                                    ) : null}

                                                    {/* <td className="cm_no_wrap">Pending</td> */}
                                                    <td className="cm_no_wrap">{request.company}</td>
                                                    <td className="cm_no_wrap">{request.modelNo}</td>
                                                    <td className="cm_no_wrap">{request.vertical}</td>
                                                    <td className="cm_no_wrap">{request.spareReceived}</td>
                                                    <td className="cm_no_wrap">
                                                          {request.serviceCategory === 1 ? "DOMESTIC" : request.serviceCategory === 2 ? "COMMERCIAL" : request.serviceCategory === 3 ? "EVENT" : ""}
                                                    </td>
                                                    <td className="cm_no_wrap">{request.productWebsite === 0 ? "AMAZON" : request.productWebsite === 1 ? "FLIPKART" : request.productWebsite === 2 ? "AMAZON MFN" : request.productWebsite === 3 ? "FLIPKART MFN" : request.productWebsite === 4 ? "CULT WEBSITE" : request.productWebsite === 5 ? "CULT APP": request.productWebsite === 6 ? "CULTFIT": request.productWebsite === 7 ? "WEBSITE": request.productWebsite === 8 ? "CULTSPORT WEBSITE": request.productWebsite === 9 ? "URBANTERRAIN WEBSITE":request.productWebsite === 10 ? "GymServ App":'-'}</td>
                                                    <td className="cm_no_wrap">{request.paymentType === 1 ? "FREE" : request.paymentType === 2 ? "PREPAID" : request.paymentType === 3 ? "COD" : ''}</td>
                                                    <td className="cm_no_wrap">{request.serviceAmount}</td>
                                                    <td className="cm_no_wrap">{request.service}</td>
                                                    {/* <td className="cm_no_wrap"> Pending </td> */}


                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_TABLE_OPTION_COFO) ? (
                                                        <td className="cm_no_wrap">
                                                            <Switch loading={this.state[`urgentLoading${request._id}`]} className={`cm_ckeckbox_wrapper cm_ckeckbox_wrapper_height ${request.isSelfDone === true ? 'cm_active' : 'cm_inactive'}`}
                                                                    onClick={(e) => this.selfMarkPopup(request._id, request.isSelfDone, e)}
                                                                    checked={request.isSelfDone === true}
                                                            />
                                                        </td>
                                                    ) : null}

                                                    <td className="cm_no_wrap">{request.productName}</td>
                                                    <td className="cm_no_wrap">{request.installationMessageSentAt ? moment(request.installationMessageSentAt).format('Do MMM YY, h:mm:ss a') : ''}</td>
                                                    <td className="cm_no_wrap">{request.installationPaymentLink}</td>
                                                    <td className="cm_no_wrap">{request.installationServicePaymentReceivedAt ? moment(request.installationServicePaymentReceivedAt).format('Do MMM YY, h:mm:ss a') : ''}</td>

                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_TABLE_OPTION_COFO) ? (
                                                        <td className="cm_no_wrap">
                                                            {request.assignedVendorDetails ?
                                                                request.assignedVendorDetails.firstName + " " + request.assignedVendorDetails.lastName
                                                                : <AsyncSelect
                                                                    cacheOptions
                                                                    className='cm_min_width'
                                                                    loadOptions={(val) => this.loadVendors(val, request.pincode)}
                                                                    defaultOptions={vendors}
                                                                    onFocus={() => this.loadVendorsFocus(request.pincode)}
                                                                    onChange={(val) => this.handleAssignVendorPopup(val, request)}
                                                                />}
                                                        </td>
                                                    ) : null}


                                                    {/*it is for CRM AGENT*/}

                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_TABLE_OPTION_COFO) ? (
                                                        <td className="cm_no_wrap">
                                                            {
                                                                request.assignedAgentDetails ?
                                                                    request.assignedAgentDetails.firstName + " " + request.assignedAgentDetails.lastName :
                                                                    <AsyncSelect
                                                                        cacheOptions
                                                                        className='cm_min_width'
                                                                        defaultOptions={crmAgents}
                                                                        loadOptions={this.loadCRMAgent}
                                                                        onFocus={() => this.loadCRMAgent()}
                                                                        // defaultOptions={services}
                                                                        onChange={(val) => this.handleAgentPopup(val, request)}
                                                                    />
                                                            }
                                                        </td>
                                                    ) : null}


                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_CANCEL_STATUS) ? (
                                                        <td className="cm_no_wrap">
                                                            {
                                                                <Select
                                                                    className='cm_min_width'
                                                                    onChange={(e) => this.changeStatus(e.value, request._id, request.userId,request)}
                                                                    placeholder="Select..."
                                                                    options={statusFilterOptions.filter((item) => item.value)}
                                                                    value={statusFilterOptions.find(status => status.value === request.statusType)}
                                                                />}

                                                        </td>
                                                    ) : (
                                                        <td className="cm_no_wrap">
                                                            {
                                                                <Select
                                                                    className='cm_min_width'
                                                                    onChange={(e) => this.changeStatus(e.value, request._id, request.userId)}
                                                                    placeholder="Select..."
                                                                    options={statusFilterOptions.filter((item) => item.value !== 4 && item.value !== "")}
                                                                    value={statusFilterOptions.find(status => status.value === request.statusType)}
                                                                />}

                                                        </td>
                                                    )}

                                                    <td className="cm_no_wrap">{request.productJobDetails && request.productJobDetails.length > 0  ? statusOptions.find(status => status.value === request.productJobDetails[0].status).label : ''}</td>
                                                    <td className="cm_no_wrap">{request.deliveryDate ? moment(request.deliveryDate).format('Do MMM YY') : '-'}</td>
                                                    <td className="cm_no_wrap">{request.commercialWarehouseDetails && request.commercialWarehouseDetails.name ? request.commercialWarehouseDetails.name : ''}</td>
                                                    <td className="cm_no_wrap">{request.gymEquipmentLogisticDetails && request.gymEquipmentLogisticDetails.dispatchReferenceNo ? request.gymEquipmentLogisticDetails.dispatchReferenceNo : ''}</td>
                                                    <td className="cm_no_wrap">{request.gymEquipmentLogisticDetails && request.gymEquipmentLogisticDetails.vehicleNo ? request.gymEquipmentLogisticDetails.vehicleNo : ''}</td>
                                                    <td className="cm_no_wrap">{request.gymEquipmentLogisticDetails && request.gymEquipmentLogisticDetails.driverContactNo ? request.gymEquipmentLogisticDetails.driverContactNo : ''}</td>
                                                    <td className="cm_no_wrap">{request.gymEquipmentLogisticDetails && request.gymEquipmentLogisticDetails.deliveryETA ? moment(request.gymEquipmentLogisticDetails.deliveryETA).format('Do MMM YY') : ""}</td>
                                                    <td className="cm_no_wrap">{request.gymEquipmentLogisticDetails && request.gymEquipmentLogisticDetails.notes ? request.gymEquipmentLogisticDetails.notes : ''}</td>
                                                    <td className="cm_no_wrap">{request.issue}</td>
                                                    <td className="cm_no_wrap">{request.issueDescription}</td>
                                                    <td className="cm_no_wrap">
                                                    {
                                                    (request.mediaUrl && (request.mediaUrl && request.mediaUrl.length > 0)) ?
                                                    <button className="btn btn-info btn-sm" onClick={() => this.openImageVideoView(request)}>View</button>
                                                    :
                                                    (request.mediaUrl && (request.mediaUrl)) ? 
                                                     <button className="btn btn-info btn-sm" onClick={() => this.openImageVideoView(request)}>View</button>  : 'N/A'
                                                    }
                                                    
                                                    </td>
                                                    <td className="cm_no_wrap">{request.closeDate ? moment(request.closeDate).format('Do MMM YY, h:mm:ss a') : ""}</td>
                                                    <td className="cm_no_wrap">{dateToRender}</td>
                                                    <td className="cm_no_wrap">{calculteTatDay <= tat_day ? "In Tat" : "Out Tat"}</td>

                                                    {!this.checkValueExist(this.state.serviceAccessibleOps,OptionToHide.HIDE_TABLE_OPTION_COFO) ? (
                                                        <td className="cm_no_wrap">
                                                            <Button className='m-1' variant="outline-info" onClick={() => this.handleEditRequestPopup(request, "Edit Service")}> <i className="fa fa-pencil" /> </Button>
                                                            {/*<Button className='m-1' variant="outline-danger" onClick={() => this.handleDeleteRequestPopup(request)}> <i className="fa fa-trash" /> </Button>*/}
                                                        </td>
                                                    ) : null}

                                                    {!_.isNil(request.notes) && !_.isNil(request.notes.note) ? (
                                                        <td className="cm_no_wrap">
                                                            <Button className='m-1' variant="outline-danger" onClick={() => this.handleAddNotesPopup(request)}> <i className="fa fa-sticky-note" /> </Button>
                                                            <Button className='m-1' variant="outline-danger" onClick={() => this.handleViewNotesPopup(request)}> <i className="fa fa-eye" />
                                                                <span className="position-absolute top-0 start-100  translate-top p-1 bg-danger border border-light rounded-circle">
                                                            </span>
                                                            </Button>
                                                        </td>
                                                    ):(
                                                        <td className="cm_no_wrap">
                                                            <Button className='m-1' variant="outline-danger" onClick={() => this.handleAddNotesPopup(request)}> <i className="fa fa-sticky-note" /> </Button>
                                                            <Button className='m-1' variant="outline-danger" onClick={() => this.handleViewNotesPopup(request)}> <i className="fa fa-eye" /> </Button>
                                                        </td>
                                                    )}

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                            activePage={this.state.pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={serviceRequestsCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                        />
                            {this.state.serviceRequestsList.length === 0 ? <h3 className="empty_error">Sorry, we couldn't find any content for the service <span>{this.state.UserSearch}</span></h3> : null}
                        </div>
                    </div>}

                {
                    productJobOpen &&
                    <Fragment>
                        <div className="row col-md-12 mb-2 mt-2">
                            <div className="col-md-3 text-right">
                                <button className="btn btn-primary btn-block" onClick={() => { this.setState({ productJob: false }) }}>Back to request</button>
                            </div>
                        </div>
                        <div >
                            <UserJobDetails isFromUser={isFromUser} requestId={selectedProductRequest} />
                        </div>

                    </Fragment>
                }


                <Modal show={deleteRequestPopup} onHide={this.closeModal} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure to delete this request?</h6> </Modal.Title>
                    </Modal.Header>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitDeleteRequest} size="sm"> Delete </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={addNotes} onHide={this.closeModal} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Please add Notes</h6> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea rows={4} className="form-control" placeholder="Add Note..." cols={50} onChange={(e) => this.handleNotesOnChange(e)} value={notes}></textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => this.addNotesToRequest(notes, this.state.selectedRequest)} size="sm"> Save </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNotes} onHide={this.closeModal} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Notes</h6> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRequest.notes && selectedRequest.notes.note ?

                            <Comment
                                className="customization-inComment-box"
                                author={<span>{selectedRequest.notes.notedBy.firstName}{selectedRequest.notes.notedBy.lastName}</span>}
                                avatar={
                                    <Avatar
                                        style={{
                                            backgroundColor: '#87d068',
                                        }}
                                        icon={<UserOutlined />}
                                    />
                                }
                                content={
                                    <p className="content-detail">
                                        {selectedRequest.notes.note}
                                    </p>
                                }
                                datetime={<span>{moment(selectedRequest.notes.notedOn).format('Do MMM YY, h:mm:ss a')}</span>}
                            />


                            : "No Notes added"}
                    </Modal.Body>
                </Modal>

                <Modal show={assignVendorPopup} onHide={this.closeModal} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Are you sure to assign this request to <b>{selectedVendor.label}</b>?</h6> </Modal.Title>
                    </Modal.Header>
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitAssignRequest} size="sm"> Assign </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={importExcelPopup} onHide={this.closeModal} backdrop="static" centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Upload File!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => this.handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                        <i className="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <div className="row col-md-4">
                            <FormCheck type='radio' checked={productWebsite == 0} label="Amazon" onChange={() => this.onWebsiteTypeSelect(0)} />
                            <FormCheck className="ml-2" type='radio' checked={productWebsite == 1} label="Flipkart" onChange={() => this.onWebsiteTypeSelect(1)} />
                        </div>
                    </Modal.Body>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    {badEntryErrors.length > 0 && <Alert variant="danger">
                        {badEntryErrors.map(err => {
                            return (
                                <>
                                    <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                                </>
                            )
                        })}
                    </Alert>}


                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button disabled={validationError} variant="success" onClick={this.submitValidateExcelUpload} disabled={validationError || isUploading} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={importGeneric} onHide={this.closeModal} backdrop="static" centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Generic Import</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => this.handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                        <i className="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        {
                            !_.isEmpty(this.state.genericImportData) &&
                            <div className='d-flex justify-content-between'>
                                <label className='text-success mb-1'>Successfully Uploaded: {this.state && this.state.genericImportData && this.state.genericImportData.successQuery && this.state.genericImportData.successQuery.length}</label>
                                <label className='text-danger mb-1'>Failed : {this.state && this.state.genericImportData && this.state.genericImportData.failQueryRecords && this.state.genericImportData.failQueryRecords.length}</label>
                            </div>
                            
                        }
                        
                        <div className="row col-md-4">
                            <Button variant="info" onClick={this.generateLatestTemplate} size="sm">Download Template</Button>
                        </div>
                        <div className="row col-md-4">
                            <FormCheck type='checkbox' label="Auto Allocate Vendor" onChange={() => this.onAutoAllocationCheckSelect()} />
                            <FormCheck className="ml-2" type='checkbox' label="Auto Suggest Only" onChange={() => this.onAutoSuggestionCheckSelect()} />
                        </div>
                    </Modal.Body>

                    
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitGenericImport} disabled={validationError || isUploading} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={importExcelUpdateInfoPopup} onHide={this.closeModal} backdrop="static" centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Bulk Edit!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => this.handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                        <i className="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Modal.Body>

                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    {badEntryErrors.length > 0 && <Alert variant="danger">
                        {badEntryErrors.map(err => {
                            return (
                                <>
                                    <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                                </>
                            )
                        })}
                    </Alert>}


                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitExcelUploadUpdateInfo} disabled={validationError || isUploading} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={responseUpdateInfoViaExcelSheetPopup} backdrop="static" onHide={this.closeModal} centered size="lg" >
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Update Info Response</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            <div class="d-md-flex justify-content-between">
                                <div className="text-success mt-3">
                                    Records Successfully Updated : {this.state.successQuery}
                                </div>
                                <div className="text-danger mt-3">
                                    Fail updated Records: {this.state.failQuery}
                                </div>
                            </div>

                            {this.state.failQuery > 0 ?
                                <>
                                    <div className="mt-4 text-center text-primary">
                                        Failed Records Info
                                    </div>
                                    <div className="table-responsive mt-3">
                                        <table className="table table-bordered table-striped text-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sr.no</th>
                                                    <th scope="col">Creation Date</th>
                                                    <th scope="col">Request ID</th>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Customer Name</th>
                                                    <th scope="col">Pincode</th>
                                                    <th scope="col">Customer Address</th>
                                                    <th scope="col">City</th>
                                                    <th scope="col">State</th>
                                                    <th scope="col">Tat Days</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">mobileNo</th>
                                                    <th scope="col">alternatePhoneNo</th>
                                                    <th scope="col">Brand</th>
                                                    <th scope="col">Model</th>
                                                    <th scope="col">Vertical</th>
                                                    <th scope="col">Spare Received</th>
                                                    <th scope="col">Source</th>
                                                    <th scope="col">Service Type</th>
                                                    <th scope="col">Self Done</th>
                                                    <th scope="col">Assign Professional Name</th>
                                                    <th scope="col">Assign Professional Id</th>
                                                    <th scope="col">Status</th>

                                                    <th scope="col">Delivery Date</th>

                                                    <th scope="col">Error Message</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {validatedServiceRequests.map((request, index) => {
                                                    return (
                                                        <tr key={request._id} className={request.alreadyExists ? 'cm_error_row' : ''} >
                                                            <td className="cm_no_wrap">{((index + 1) + ((pageNo - 1) * count))}</td>
                                                            <td className="cm_no_wrap">{request['Creation Date'] ? moment(request['Creation Date']).format('Do MMM YY') : '-'}</td>
                                                            <td className="cm_no_wrap"> {request['Request ID']}</td>
                                                            <td className="cm_no_wrap"> {request['Order ID']}</td>
                                                            <td className="cm_no_wrap"> {request['Customer First Name'] + " " + request['Customer Last Name']}</td>
                                                            <td className="cm_no_wrap"> {request['Pincode']}</td>
                                                            <td className="cm_no_wrap"> {request['Customer Address']}</td>
                                                            <td className="cm_no_wrap"> {request['City']}</td>
                                                            <td className="cm_no_wrap"> {request['State']}</td>
                                                            <td className="cm_no_wrap"> {request['Tat Days']}</td>
                                                            <td className="cm_no_wrap">{request['Email']}</td>
                                                            <td className="cm_no_wrap">{request['mobileNo']}</td>
                                                            <td className="cm_no_wrap">{request['alternatePhoneNo']}</td>
                                                            <td className="cm_no_wrap">{request['Brand']}</td>
                                                            <td className="cm_no_wrap">{request['Model']}</td>
                                                            <td className="cm_no_wrap">{request['Vertical']}</td>
                                                            <td className="cm_no_wrap">{request['spareReceived']}</td>
                                                            <td className="cm_no_wrap">{request['Source']}</td>
                                                            <td className="cm_no_wrap">{request['Service Type']}</td>
                                                            <td className="cm_no_wrap">{request['Self Done']}</td>
                                                            <td className="cm_no_wrap">{request['Assign Professional Name']}</td>
                                                            <td className="cm_no_wrap">{request['Assign Professional Id']}</td>
                                                            <td className="cm_no_wrap">{request['Status']}</td>

                                                            <td className="cm_no_wrap">{request['Delivery Date']}</td>

                                                            <td className="cm_no_wrap">{request['error_msg'] ? request['error_msg'] : ''}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : null}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={validateExistingRequestsPopup} onHide={this.closeModal} backdrop="static" centered size="lg" dialogClassName="cm_modal_hw">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Excel Not Valid!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item, index) => {
                                            let { fieldName } = item;
                                            return <th key={index} scope="col">{fieldName}</th>
                                        })}
                                    </tr>
                                </thead>


                                <tbody>
                                    {validatedServiceRequests.map((request, index) => {
                                        return (
                                            <tr key={request._id} className={request.alreadyExists ? 'cm_error_row' : ''} >
                                                <td className="cm_no_wrap">{((index + 1) + ((pageNo - 1) * count))}</td>
                                                <td className="cm_no_wrap"> {request._id}</td>
                                                <td className="cm_no_wrap"> {request.orderId}</td>
                                                <td className="cm_no_wrap"> {request.firstName + " " + request.lastName}</td>
                                                <td className="cm_no_wrap"> {request.pincode}</td>
                                                <td className="cm_no_wrap"> {request.mailingStreet}</td>
                                                <td className="cm_no_wrap"> {request.mailingCity}</td>
                                                <td className="cm_no_wrap"> {request.mailingState}</td>
                                                <td className="cm_no_wrap"> {request.tat}</td>
                                                <td className="cm_no_wrap">{request.email}</td>
                                                <td className="cm_no_wrap">{request.mobileNo}</td>
                                                <td className="cm_no_wrap">{request.alternatePhoneNo}</td>
                                                {/* <td className="cm_no_wrap">Pending</td> */}
                                                <td className="cm_no_wrap">{request.company}</td>
                                                <td className="cm_no_wrap">{request.modelNo}</td>
                                                <td className="cm_no_wrap">{request.vertical}</td>

                                                <td className="cm_no_wrap">{request.productWebsite === 0 ? "Amazon" : request.productWebsite === 1 ? "Flipkart" : '-'}</td>
                                                <td className="cm_no_wrap">{request.service}</td>
                                                {/* <td className="cm_no_wrap"> Pending </td> */}
                                                <td className="cm_no_wrap">
                                                    <AsyncSelect
                                                        cacheOptions
                                                        className='cm_min_width'
                                                        loadOptions={this.loadServices}
                                                        // defaultOptions={services}
                                                        onChange={(val) => this.serviceSelect(val)}
                                                    />
                                                </td>
                                                <td className="cm_no_wrap">
                                                    <AsyncSelect
                                                        cacheOptions
                                                        className='cm_min_width'
                                                        loadOptions={this.loadServices}
                                                        // defaultOptions={services}
                                                        onChange={(val) => this.serviceSelect(val)}
                                                    />
                                                </td>
                                                <td className="cm_no_wrap">{request.status}</td>
                                                <td className="cm_no_wrap">{request.purchaseDate ? moment(request.purchaseDate).format('Do MMM YY') : '-'}</td>
                                                <td className="cm_no_wrap">{request.registrationDate ? moment(request.registrationDate).format('Do MMM YY') : '-'}</td>
                                                <td className="cm_no_wrap">{request.deliveryDate ? moment(request.deliveryDate).format('Do MMM YY') : '-'}</td>
                                                <td className="cm_no_wrap">
                                                    <button className="btn btn-danger btn-xs ml-2" onClick={() => this.handleDeleteRequestFromExcel(request, index)}>
                                                        <i className="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="row col-md-4">
                            <FormCheck type='radio' checked={productWebsite == 0} label="Amazon" onChange={() => this.onWebsiteTypeSelect(0)} />
                            <FormCheck className="ml-2" type='radio' checked={productWebsite == 1} label="Flipkart" onChange={() => this.onWebsiteTypeSelect(1)} />
                        </div>
                    </Modal.Body>
                    {
                        validationError ? <h4 className="text-center mb-2  error_message">{validationError}</h4> : ''
                    }
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitAddMultipleRequestsByAdmin} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={openAddServicePopup} onHide={this.closeModal} centered size="xl" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>{addEditPopupHeading}</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-body-height">
                        <Form noValidate validated={validated} onSubmit={(e) => this.submitAddRequest(e)}>
                            <div className='row border-bottom mb-3'>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>First Name</FormLabel>
                                    <FormControl
                                        placeholder="First Name"
                                        value={this.state.firstName}
                                        name='firstName' onChange={this.handleChangeWithValidString}
                                        required
                                        type="text"
                                        maxLength={30} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >First Name must be between 1 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Last Name</FormLabel>
                                    <FormControl
                                        placeholder="Last Name"
                                        value={this.state.lastName}
                                        name='lastName' onChange={this.handleChangeWithValidString}
                                        required
                                        maxLength={30} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Last Name must be between 1 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <div className="col-lg-6 col-xl-4">
                                    <div className="row">
                                        <FormGroup className='col-4 pr-0'>
                                            <FormLabel>Country Code</FormLabel>
                                            <FormControl
                                                style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }}
                                                placeholder="Ex: +91"
                                                value={this.state.countryCode}
                                                required
                                                minLength={2}
                                                maxLength={2}
                                                name='countryCode' onChange={this.handleChange} />
                                            <Form.Control.Feedback type="invalid">
                                                <FormLabel >Country Code is required</FormLabel>
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                        <FormGroup className='col-8 pl-0'>
                                            <FormLabel>Mobile No</FormLabel>
                                            <FormControl
                                                style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                                                placeholder="Mobile No"
                                                value={this.state["mobileNo"]}
                                                required
                                                type='text'
                                                pattern="[0-9]{10}"
                                                maxLength={10}
                                                name='mobileNo' onChange={this.handleChange} />
                                            <Form.Control.Feedback type="invalid">
                                                <FormLabel className='text-right d-block'>Mobile No is required</FormLabel>
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </div>
                                </div>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Alternate Mobile No</FormLabel>
                                    <FormControl
                                        placeholder="Alternate Mobile No"
                                        value={this.state["alternatePhoneNo"]}
                                        type='text'
                                        pattern="[0-9]{10}"
                                        maxLength={10}
                                        name='alternatePhoneNo' onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl
                                        type='email'
                                        placeholder="abc@abc.com"
                                        value={this.state["email"]}                                        
                                        name='email' onChange={this.handleChange} />
                                    {/* <Form.Control.Feedback type="invalid">
                                        <FormLabel >Email is required</FormLabel>
                                    </Form.Control.Feedback> */}
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Order Id</FormLabel>
                                    <FormControl
                                        placeholder="Order Id"
                                        required
                                        minLength={5}
                                        maxLength={50}
                                        value={this.state["orderId"]}
                                        name='orderId' onChange={this.handleChange}
                                        disabled={this.state.addEditPopupHeading==="Edit Service"?true:false}
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Order Id must be between 5 to 50 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>

                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Shipment Id</FormLabel>
                                    <FormControl
                                        placeholder="Shipment Id"
                                        maxLength={50}
                                        value={this.state["shipmentID"]}
                                        name='shipmentID'
                                        onChange={this.handleChange}
                                        // disabled={this.state.addEditPopupHeading==="Edit Service"?true:false}
                                        disabled={
                                            (() => {
                                            const self = this
                                                let shouldDisable;
                                                if (self.state.addEditPopupHeading === "Edit Service") {
                                                    if(self.state.filteredData.shipmentID === "" || typeof self.state.filteredData.shipmentID === 'undefined'){
                                                        shouldDisable = false
                                                    }else {
                                                        shouldDisable = true
                                                    }
                                                } else {
                                                    shouldDisable = false
                                                }
                                                return shouldDisable
                                            })()
                                        }
                                    />
                                </FormGroup>

                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Model No.</FormLabel>
                                    <FormControl
                                        placeholder="Model No"
                                        value={this.state["modelNo"]}
                                        required
                                        name='modelNo' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Model No is required</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Delivery Date</FormLabel>
                                    <DatePicker defaultValue={this.state.deliveryDate ? moment(this.state.deliveryDate):''} onChange={(val) => this.handleDate(val, 'deliveryDate')} className="form-control" />
                                    {validated && !this.state["deliveryDate"] && < Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Delivery Date is required</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                            </div>


                            <div className='row border-bottom mb-3'>
                                {
                                    this.state.addEditPopupHeading!=="Edit Service" && 
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Address</FormLabel>
                                    <FormControl
                                        placeholder="Address"
                                        value={this.state["address"]}
                                        required
                                        maxLength={150}
                                        name='address' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Address  must be between 1 to 150 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>
                                }

                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Street</FormLabel>
                                    <FormControl
                                        placeholder="Street"
                                        required
                                        maxlLength={50}
                                        value={this.state["mailingStreet"]}
                                        name='mailingStreet' onChange={this.handleChange} />
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel >Street must be between 1 to 30 character</FormLabel>
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>State</FormLabel>
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleSteteChange(e.value, "mailingState", 0)}
                                        placeholder="Select State"
                                        options={allstates}
                                        value={
                                            this.state["mailingState"] ? 
                                            {
                                                label: this.state["mailingState"],
                                                value: this.state["mailingState"]
                                            }
                                            :''
                                        }
                                    />

                                    {validated && !this.state.mailingState && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >State is required</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>City</FormLabel>
                                    <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handleCityChange(e.value, "mailingCity")}
                                        placeholder="Select City"
                                        options={citys}
                                        value={
                                            this.state["mailingCity"] ?
                                            {
                                                label: this.state["mailingCity"],
                                                value: this.state["mailingCity"]
                                            }
                                            :''
                                        }
                                    />

                                    {validated && !this.state.mailingCity && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >City is required</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Pincode</FormLabel>
                                    {/* <Select
                                        className='cm_min_width'
                                        onChange={(e) => this.handlePincodeChange(e.value, "pincode")}
                                        placeholder="Select Pincode"
                                        options={pincodes}
                                        value={this.state.pincode ? pincodes.find(element => element.value === this.state.pincode) : null}
                                    /> */}
                                    <AsyncSelect
                                        className='cm_min_width'
                                        isSearchable
                                        isDisabled={false}  
                                        value={
                                            this.state["pincode"]?
                                            {
                                                label: this.state["pincode"],
                                                value: this.state["pincode"]
                                            }
                                            :''
                                        }        
                                        defaultOptions={pincodes}
                                        onChange={(e) => this.handlePincodeChange(e.value, "pincode")}
                                        placeholder='Filter By Pincode'
                                        loadOptions={(val) => this.loadPincodeByval(val)}
                                    />

                                    {validated && !this.state.pincode && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Pincode is required</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>

                            </div>


                            <div className='row border-bottom mb-3'>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Issue</FormLabel>
                                    <FormControl
                                        placeholder="Issue"
                                        value={this.state["issue"]}
                                        name='issue' onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Spare Received</FormLabel>
                                    <FormControl
                                        placeholder="Spare Required"
                                        value={this.state["spareReceived"]}
                                        name='spareReceived' onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Service Type</FormLabel>
                                    <Select
                                        isSearchable
                                        options={ServiceTypes}
                                        placeholder="Select service.."
                                        value={
                                            this.state.selectedService?
                                            this.state.selectedService
                                            :''
                                           
                                        }  
                                        onChange={(val) => this.handleServiceTypeSelector(val)}
                                    />
                                    {validated && (!Object.keys(this.state["selectedService"]).length && !this.state["selectedService"].length) && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Service Type is required</FormLabel>
                                    </Form.Control.Feedback>}

                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Vertical</FormLabel>
                                    <FormControl
                                        placeholder="Vertical"
                                        required
                                        value={this.state["vertical"]}
                                        name='vertical' onChange={this.handleChange} />
                                    {validated && <Form.Control.Feedback type="invalid">
                                        <FormLabel >Vertical  must be between 1 to 150 character</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Brand</FormLabel>
                                    <FormControl
                                        placeholder="Company"
                                        required
                                        value={this.state["company"]}
                                        name='company' onChange={this.handleChange} />
                                    {validated && <Form.Control.Feedback type="invalid">
                                        <FormLabel >Company  must be between 1 to 150 character</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Product Website</FormLabel>
                                    <Select
                                        isSearchable
                                        options={sourceFilterOptions.filter(item => item.value || item.value === 0)}
                                        placeholder="productWebsite.."
                                         value={this.state.sourceWebsite }
                                        onChange={(val) => this.handleSourceSelector(val)}
                                    />
                                    {validated && (!Object.keys(this.state["sourceWebsite"]).length && !this.state["sourceWebsite"].length) && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Product Website is required</FormLabel>
                                    </Form.Control.Feedback>}

                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Payment Type</FormLabel>
                                    <Select
                                        isSearchable
                                        options={paymentTypeOptions.filter(item => item.value || item.value === 0)}
                                        placeholder="paymentType.."
                                        value={this.state.paymentType }
                                        onChange={(val) => this.handlePaymentTypeSelector(val)}
                                    />
                                    {validated && (!Object.keys(this.state["paymentType"]).length && !this.state["paymentType"].length) && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Product Website is required</FormLabel>
                                    </Form.Control.Feedback>}

                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Service Amount</FormLabel>
                                    <FormControl
                                        placeholder="Service Amount"
                                        value={this.state["serviceAmount"]}
                                        name='serviceAmount' onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className='col-lg-6 col-xl-4'>
                                    <FormLabel>Service Category</FormLabel>
                                    <Select
                                        isSearchable
                                        options={serviceCategoryFilterOptions.filter(item => item.value || item.value === 0)}
                                        placeholder="serviceCategory.."
                                        value={this.state.serviceCategory }
                                        onChange={(val) => this.handleServiceCategorySelector(val)}
                                    />
                                    {validated && (!Object.keys(this.state["serviceCategory"]).length && !this.state["serviceCategory"].length) && <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                        <FormLabel >Service Category Required</FormLabel>
                                    </Form.Control.Feedback>}
                                </FormGroup>
                            </div>
                            <Row className="justify-content-center">
                                <Button variant="danger" onClick={this.closeModal}>Cancel</Button>
                                <Button disabled={this.state["isSubmitClicked"]} className="ml-2" variant="success" type="submit">Submit</Button>
                            </Row>

                        </Form>
                    </Modal.Body>
                </Modal>
                <PopUpModal isOpen={statusPopup} closeModal={this.closeStatusPopup} submit={this.changeRequestStaus} message={`Are you sure to change the status of request`} submitTitle="Update Status" />
                <Popup open={selfDoneStatusPopup} closeOnDocumentClick={false} onClose={this.closeStatusPopupSelfDone}>
                    <div className="cm_modal cm_modal_hw">
                        <span className="cm_modal_close" onClick={this.closeStatusPopupSelfDone}>&times;</span>
                        <h3 className="text-center">Add Self Done incentive</h3>

                        <div className="container">
                            <div className="container my-5">

                                <div className='row justify-content-center mt-4'>
                                    <div className='col-4'>
                                        <h6>Self Done incentive- </h6>
                                    </div>

                                    <div className="col">
                                        <input type="text" className="form-control input-sm"
                                               name='Incentive'
                                               id="exampleFormControlInput1"
                                               placeholder="Incentive"
                                               inputMode={"numeric"}
                                               value={this.state.selfDoneIncentiveCharge}
                                               onChange={this.handelIncentiveTextField}
                                               disabled={!this.state.enableAddiInputBox}/>
                                    </div>

                                </div>

                            </div>

                            <Row className="justify-content-center">
                                <Button variant="danger" onClick={this.closeStatusPopupSelfDone}>Cancel</Button>
                                <Button className="ml-2" variant="success" type="submit" disabled={loader}
                                        onClick={this.changeRequestStaus}>
                                    {loader ? 'Wait' : 'Add'}
                                </Button>
                            </Row>
                        </div>

                        <div className="form-check mt-4">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                   onClick={event => this.checkBoxChanged(event)}/>
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Add Self done incentive Amount
                            </label>
                        </div>
                    </div>
                </Popup>
                <PopUpModal
                    isOpen={markSelfDonePopUP}
                    closeModal={this.selfMarkPopup}
                    submit={this.markSelfDone}
                    message={this.state.selfDone ? `Are you sure to mark to self done` : `Are you sure to remove the self-done for this customer?`}
                    submitTitle="Ok"
                />
                <PopUpModal isOpen={this.state["showHideExport"]} closeModal={() => this.setState({ showHideExport: false })} submit={this.exportHandler} message={`Are you sure to export`} submitTitle="Export" />
                <PopUpModal isOpen={assignAgentPopup} closeModal={() => this.setState({ assignAgentPopup: false })} submit={this.assignRequestToAgent} message={`Are you sure to assign selected request`} validationError={validationError} />

                {imagesViewPopup && <Modal show={imagesViewPopup} backdrop="static" onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Service Images/Videos</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body text-center">
                                <div className="form-group">
                                    <div className='mt-2'>
                                        <p><b>Image/Video</b></p>
                                         
                                        {productServices && productServices.image && productServices.image.length > 0 ?
                                            <div className="img_gallery">
                                                <div className="sidereel">
                                                    {productServices.image.map(file => (
                                                        
                                                        <>
                                                            {(file && file.file_type == 'IMAGE') || (file && file.file_type == 'image/jpeg') || (file && file.file_type && file.file_type.includes("image")) ?
                                                                <div className="imagereel position-relative" onClick={() => this.openFileImageVideoView(file)}>
                                                                    <img title='Image' src={file.url} />        
                                                                    <i class="fa fa-eye"></i>
                                                                </div>
                                                                :
                                                                <div className="videoreel customVideoreel" onClick={() => this.openFileImageVideoView(file)}>
                                                                    <video title='Video' src={file.url} />
                                                                    <i class="fa fa-video-camera"></i>
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                                <div className="fullimgslider">
                                                    {productServices.file_type == 'IMAGE' || productServices.file_type == 'image/jpeg' ?
                                                    <img title='image' src={productServices.img_url} /> :
                                                        <video
                                                            className="cm_video"
                                                            src={productServices.video_url}
                                                            type={productServices.video_mimetype}
                                                            poster={productServices.img_url}
                                                            controls
                                                        />            
                                                        
                                                    }
                                                </div>
                                            </div>
                                            :
                                            productServices.image ?
                                            <>
                                            <div className="img_gallery">
                                                <div className="sidereel">
                                                    <div className="imagereel">
                                                        <img title='Image' src={productServices.image} />
                                                    </div>
                                                </div>
                                                <div className="fullimgslider">
                                                    <img title='Image' src={productServices.image} />
                                                </div>
                                            </div>
                                        </>
                                        :  'N/A'
                                                
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Close</Button>
                    </Modal.Footer>
                </Modal>}           

            </HOC >
        );
    }
}

export default AdminService;
