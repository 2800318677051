import React,{useState,useEffect} from 'react';
import Smile from './../../assets/images/smile.svg'
import { Tooltip, Carousel } from 'antd';
import EmojiIcon from './../../assets/images/emoji-icon.svg'
import { Data } from './messageDate';
import moment from "moment";

const LatestMessages = ({ toggleSendEmojiPopup, toggleAllMessagePopup, messageData, emojiList ,countdown}) => {
const [state, setstate] = useState(null)

    let getEmojiUrl = (emojiId) => {

        if (emojiList.length > 0) {
            let emoji = emojiList.find(ele => ele._id === emojiId);

            return emoji ? emoji.emojiImageUrl : "";
        }
        else {
            return "";
        }

    }

    useEffect(() => {
        console.log("Lates ---calledd",countdown,messageData,(countdown>0 || messageData));
        setstate(+ new Date());
     }, [messageData])


    return (
        <>
        {
           countdown>0 || messageData?
            <div className="emojis-message d-flex flex-column-reverse mb-2">
            <Tooltip placement="topLeft" className="d-flex align-items-center ml-auto" title="Send emoji to all" arrowPointAtCenter>
                <div className="add-emoji" style={{ width: "70px", color: "#ffffff" }} onClick={toggleSendEmojiPopup}>
                    <span className="pr-2">Send</span>
                    <img src={EmojiIcon} className="emoji-add-icon" />
                </div>
            </Tooltip>
            <div className="custom-carousel-width ml-0">
                {
                    messageData && Array.isArray(messageData)?
                    messageData.slice(0, 3).map((message, index) => {
                        return (
                            <p className="single-msg d-flex align-items-center cursor-pointer" onClick={toggleAllMessagePopup} key={index}>
                                <span className="time">
                                    {moment(message.postedAt).format("hh:mm:ss")}
                                </span>
                                <span className="sender text-truncate">{message.fromName}</span>
                                <span className="send-mgs">
                                    <img src={getEmojiUrl(message.emojiId)} className="emoji" alt="image" />
                                </span>
                                <span className="receiver text-truncate">
                                    {message.toName}
                                </span>
                            </p>
                        )
                    })
                :""}
            </div>
        </div>
        :
        ""
        }
        </>
        
    )
}

export default LatestMessages