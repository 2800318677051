import axios from 'axios';
import API from '../Api';

export function getTracks(params) {
    return axios.get(API.GET_TRACKS, params)
}
export function addTrack(params) {
    return axios.post(API.ADD_TRACK, params)
}
export function updateTrack(params) {
    return axios.post(API.UPDATE_TRACK, params)
}
export function deleteTrack(params) {
    return axios.post(API.DELETE_TRACK, params)
}
export function uploadTracksViaExcel(params) {
    return axios.post(API.UPLOAD_TRACKS_EXCEL, params)
}



