import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/cult-logo.svg'
import { LoaderSvg } from '../Reusable'
import OtpInput from 'react-otp-input';
import { withRouter } from "react-router";
import axios from 'axios';
import _ from 'lodash';
import { reSendOtp, verifyOTP } from '../../config/services/adminService';
import Popup from "reactjs-popup"

const VerifyOTP = () => {
    const history = useHistory()
    const [otp, setOTP] = useState('');
    const [loader, setLoader] = useState(false);
    const [sucsessPopup, setsSucsessPopup] = useState(false);
    const [resetPasswordMsg, setResetPasswordMsg] = useState('')

    const handleChange = (otp) =>{
        setOTP(otp);
    } 

    const submitOTP = ()=>{
        const accessToken = localStorage.getItem('otpToken');
        const forgotPassword = localStorage.getItem('forgotPassword')
        verifyOTP(otp,accessToken)
        .then((response)=>{
            if (response.data.statusCode === 1){
                if (forgotPassword){
                    localStorage.setItem('otpToken', response.data.responseData.accessToken);
                    history.push('/reset-password');
                } else {
                    localStorage.setItem('accessToken', response.data.responseData.accessToken);
                    axios.defaults.headers.common['accessToken'] = response.data.responseData.accessToken;
                    axios.defaults.headers.common['accessToken'] = response.data.responseData.accessToken;
                    axios.defaults.headers.common['Authorization'] = `${'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy'}`;
                    axios.defaults.headers.common['platform'] = 3;
                    history.push('/dashboard')
                }

            } else {
                setResetPasswordMsg(response.data.error.errorMessage)
                setsSucsessPopup(true);
            }
        })
        .catch((error)=>{
            console.log('error messages', error);
        })
    }

    const closeModal = () =>{
        setsSucsessPopup(false);
        setOTP('')
    }

    const handleResendOtp = () =>{
        const accessToken = localStorage.getItem('otpToken');
        // const forgotPassword = localStorage.getItem('forgotPassword');
        setLoader(true)
        reSendOtp(accessToken)
        .then((response)=>{
            if(response.data.statusCode === 1){
                let { result, accessToken } = response.data.responseData;
                localStorage.setItem('otpToken', accessToken);
                console.log('res Resend OTP', response)
                // if(forgotPassword){
                //     localStorage.setItem('otpToken', response.data.responseData.accessToken);
                //     history.push('/reset-password');
                // }else{
                //     axios.defaults.headers.common['accessToken'] = `${localStorage.getItem('accessToken')}`;
                //     axios.defaults.headers.common['accessToken'] = `${accessToken}`;
                //     axios.defaults.headers.common['Authorization'] = `${'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy'}`;
                //     axios.defaults.headers.common['platform'] = 3;
                //     localStorage.setItem('accessToken', response.data.responseData.accessToken);
                //     history.push('/dashboard')
                // }
                setLoader(false)
            }else{
                setResetPasswordMsg(response.data.error.errorMessage)
                setsSucsessPopup(true);
                setLoader(false)
            }
        })
        .catch((error)=>{
            console.log('error messages', error);
            setLoader(false)
        })
    }

    return (
        <div className="login-wrapper">
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-md-6 m-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-title">
                                    <Logo />
                                    {/* {_statusCode ? <p style={{ color: 'red' }}>{_statusCode}</p> : null} */}
                                </div>
                                <div>    
                                    <div className="form-group cm_show_pass text-center">
                                    <OtpInput
                                        value={otp}
                                        onChange={handleChange}
                                        numInputs={4}
                                        separator={<span>-</span>}
                                        inputStyle='otp-input-style'
                                        containerStyle='containerStyle'
                                    />
                                    </div>

                                    <div className='text-center py-4'>
                                        <span className='cursor-pointer' onClick={handleResendOtp}>Resend OTP</span>
                                    </div>
                                    <div className="form-group">
                                            <div className="row">
                                                <div className="col d-flex justify-content-center">
                                                    <button className="btn btn-primary" onClick={submitOTP}>Verify OTP</button>
                                                </div>
                                            </div>
                                        </div>
                                    {
                                        loader &&
                                        <div className="loader_wrapper"><LoaderSvg /></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popup
                    open={sucsessPopup}
                    closeOnDocumentClick
                    onClose={closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={closeModal}>
                            &times;
                </span>
                        <h3 className="text-center">{resetPasswordMsg}</h3>

                    </div>
                </Popup>
        </div>
    )
}



export default VerifyOTP;
