var outdoorTrackTags = [
    { label: "Walking",  value: "Walking" },
    { label: "Running",  value: "Running" },
    { label: "No Shade", value: "No Shade" },
    { label: "Lake",     value: "Lake" },
    { label: "Hike", value: "Hike" },
    { label: "Hiking", value: "Hiking" },
    { label: "Climb Down", value: "Climb Down" },
    { label: "Sloppy",     value: "Sloppy" },
    { label: "Climbing",     value: "Climbing" },
    { label: "Circular",     value: "Circular" },
    { label: "River",     value: "River" },
    { label: "Hilly",     value: "Hilly" },
    { label: "Climbing Up",     value: "Climbing Up" },
    { label: "Sloping Down",     value: "Sloping Down" },
    { label: "Sloping",     value: "Sloping" },
    { label: "Down Hills",     value: "Down Hills" },
    { label: "Up Hills",     value: "Up Hills" },
    { label: "Round",     value: "Round" },
]

module.exports = {
    outdoorTrackTags
}
