import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import io from "socket.io-client";
import Select from "react-select";
import moment from "moment";
import StarRatings from "react-star-ratings";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";

import { getStreamDetails, addOrUpdateNotes } from "../../config/services/streamingService";
import setting from "../../config/settings/";
import { ReactComponent as BirthdayIcon } from "../../../src/assets/images/birthday.svg";
import { LoaderSvg } from "../Reusable";
import { ADMIN_ROLES } from "../../utils/constants";
import { downloadUserList } from "../../config/services/streamingService";
import { ReactComponent as RemoveFilter } from "./../../assets/images/remove-filter.svg";
import Countdown from "react-countdown";
import FileSaver from 'file-saver';
import { Tooltip, Spin, Switch } from "antd";
import { ReactComponent as DownloadIcon } from "./../../assets/images/download.svg";
import PopUpModal from '../../components/common/PopUpModal'
import AddNotes from "./AddNotes";


const socketUrl = setting.api.socket;

const streamingDetails = (props) => {
  // State Variables Declared

  let [loading, setLoading] = useState(true);

  let [isfetching, setIsFetching] = useState(false);

  let [streamData, setStreamData] = useState([]);

  let [trainerData, setTrainerData] = useState({});

  let [countdown, setCountDown] = useState(0);

  let [loggedInRole, setLoggedInRole] = useState("");

  let history = useHistory();

  let categories = [
    { id: 1, name: "Bike" },
    { id: 2, name: "Tread" },
    { id: 3, name: "Yoga" },
    { id: 4, name: "Stretching" },
    { id: 5, name: "Meditation" },
    { id: 6, name: "HIIT" },
     {id:7,name:"Strength"}

  ];

  const [isFilterActive, setIsFilterActive] = useState({
    fiterDropdown: false,
    filterBirthDay: false,
  });

  let getQueryStringParams = (query) => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
      : {};
  };

  let _query = getQueryStringParams(props.location.search);

  let _params = {
    count: 80,
    sortType: -1,
    sortField: "rank",
    pageNo: 0,
    search: "",
    birthdayToday: false,
    gender: "",
    ageRange: "",
    streamingId: "",
    expertId: "",
  };

  let [params, setParams] = useState(_params);

  let [selectkey, setselectkey] = useState(1);

  let [totalCount, setTotalCount] = useState(0);

  let [_fetch, setFetch] = useState(0);

  let [renderKey, setrenderKey] = useState(0);

  let [isdownload, setIsdownload] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [note, setNote] = useState('');
  const [validationError, setValidationError] = useState('');

  let gender = [
    { id: 1, label: "Male", key: 1 },
    { id: 2, label: "Female", key: 2 },
  ];

  let ageRange = [
    { id: 1, label: "20-30", key: { min: 20, max: 30 } },
    { id: 2, label: "30-40", key: { min: 30, max: 40 } },
    { id: 3, label: "40-50", key: { min: 40, max: 50 } },
    { id: 4, label: "50-60", key: { min: 50, max: 60 } },
  ];

  // Random component
  const Completionist = () => <span></span>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state

      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          Time Left - {hours} : {minutes} : {seconds}
        </span>
      );
    }
  };

  let openAddNotesPopUp = () => {
    setShowAddNote(true)
  };
  let closeAddNoteModal = () => {
    setShowAddNote(false);
  }


  let notesChangeHandler = (event, editor) => {
    editor.getData() ?  setValidationError('') : setValidationError('Please write some notes.');
    setNote(editor.getData())
 }
 let addNotesHandler = () => {
  if (!note) {
       setValidationError('Please write some notes.');
  } else {
       setValidationError('');
       let paramsObj = {
         notes: note,
         broadCastId:_query.streamingId
       }
       addOrUpdateNotes(paramsObj)
          .then((response) => {
            if (response.data.statusCode == 1) {
               setShowAddNote(false);
               toast.success(response.data.responseData.message)
             }else{
              setShowAddNote(false);
              toast.error(response.data.error.errorMessage)
             }
            

          })
 

  }
}

  useEffect(() => {
    setParams({
      ...params,
      streamingId: _query.streamingId,
      expertId: _query.expertId,
    });
    _getStreamDetails1();

    setrenderKey(renderKey + 1);
  }, [_fetch]);

  useState(() => {
    setLoggedInRole(Number(localStorage.getItem("role")));
  }, []);

  useEffect(() => {
    if (parseInt(_query.isEnable)==0) {
      return;
    }

    let initSocket = () => {
      let accessToken = localStorage.getItem("accessToken");
      let role = localStorage.getItem("role");
      var socket = io.connect(socketUrl, {
        transports: ["websocket"],
        query: { accessToken: accessToken, role },
      });

      socket.on("connection", (res) => {
        console.log("conn status", res);
      });

      socket.on("USER_JOINED_STREAM", (res) => {
        console.log("join event", res.attendeeDetails);

        try {
          let _streamData = null;
          if (
            loggedInRole === ADMIN_ROLES.ADMIN &&
            res.attendeeDetails.streamingId == _query.streamingId
          ) {
            _streamData = streamData.filter(
              (data) => data.userId != res.attendeeDetails.userId
            );

            _streamData.push(res.attendeeDetails);
          } else if (loggedInRole != ADMIN_ROLES.ADMIN) {
            _streamData = streamData.filter(
              (data) => data.userId != res.attendeeDetails.userId
            );

            _streamData.push(res.attendeeDetails);
          }

          if (_streamData) {
            setStreamData(_streamData);
            setTotalCount(_streamData.length);
          }
        } catch (error) {
          console.log(error);
        }
      });

      socket.on("USER_LEFT_STREAM", (res) => {
        console.log("left event", res);
        try {
          let _streamData = streamData.filter(
            (data) => data.userId != res.userId
          );

          setStreamData(_streamData);
          setTotalCount(_streamData.length);
          // setParams({ ...params, ...{ count: _streamData.length } });
        } catch (error) {
          console.log(error);
        }
      });

      socket.onerror = (error) => {
        console.log("Socket Error Here: ", error);
      };
    };
    initSocket();
  }, [streamData, trainerData]);

  let _getStreamDetails1 = () => {
    setIsFetching(true);

    let paramsObj = {
      params: {
        ...params,
        streamingId: _query.streamingId,
        expertId: _query.expertId,
        filterActive: _query.isEnable,
      },
    };

    if (params.pageNo == 0) {
      setLoading(true);
    }

    getStreamDetails(paramsObj).then((response) => {
      if (response.data.statusCode == 1) {
        if (params.pageNo != 0) {
          setStreamData([
            ...streamData,
            ...response.data.responseData.result.userList,
          ]);
        } else {
          setStreamData(response.data.responseData.result.userList);
        }

        setTrainerData({
          ...response.data.responseData.result.streamDetails,
          ...response.data.responseData.result.expertAvgRating,
        });
        setNote(response.data.responseData.result.streamDetails.notes)


        if (!countdown) {
          if (
            moment(
              response.data.responseData.result.streamDetails.startDate
            ).format("D") == moment().format("D")
          ) {
            if (
              moment() >=
              moment(
                response.data.responseData.result.streamDetails.startTime
              ) &&
              moment() <=
              moment(response.data.responseData.result.streamDetails.endTime)

              //   &&
              // response.data.responseData.result.streamDetails.isEnable
            ) {
              let end = moment(
                response.data.responseData.result.streamDetails.endTime
              );

              var duration = moment.duration(end.diff(moment()));

              setCountDown(duration._milliseconds);
            }
          }
        }

        setTotalCount(response.data.responseData.result.totalCount);
        setLoading(false);
        setIsFetching(false);
      } else if (response.statusCode == 0) {
        toast.error(response.data.error.errorMessage);
      }
    });
  };

  let _countDown = () => {
    let end = moment(trainerData.endTime);

    return Date.now() + moment.duration(end.diff(moment()));
  };

  let clearFilters = () => {
    setParams(_params);
    setselectkey(selectkey + 1);
    setFetch(_fetch + 1);
    setIsFilterActive({
      ...isFilterActive,
      fiterDropdown: false,
      filterBirthDay: false,
    });
  };

  let filterData = (value, key) => {
    setParams({ ...params, [key]: value.key, pageNo: 0 });
    setFetch(_fetch + 1);
    setIsFilterActive({
      ...isFilterActive,
      fiterDropdown: true,
    });
  };

  // filter birthday
  let filterByBirthday = () => {
    setParams({
      ...params,
      birthdayToday: !params.birthdayToday,
      pageNo: 0,
    });
    setFetch(_fetch + 1);
    if (!params.birthdayToday) {
      setIsFilterActive({
        ...isFilterActive,
        filterBirthDay: true,
      });
    } else {
      setIsFilterActive({
        ...isFilterActive,
        filterBirthDay: false,
      });
    }
  };


  let getGender = (id) => {
    if (id == 1) {
      return "M";
    }
    if (id == 2) {
      return "F";
    }
    if (id == 3) {
      return "O";
    }
  };

  let checkBirthday = (dob) => {
    let _dob = JSON.stringify([
      moment(dob).format("M"),
      moment(dob).format("D"),
    ]);

    let today = JSON.stringify([moment().format("M"), moment().format("D")]);

    if (_dob == today) {
      return <BirthdayIcon className="birthday_icon" />;
    } else {
      return "-";
    }
  };

  let fetchData = () => {
    setParams({ ...params, pageNo: params.pageNo + 1 });
    setFetch(_fetch + 1);
  };

  let categoryName = (catId) => {
    let catgoryInfo = categories.find((e) => e.id === catId);
    if (catgoryInfo && catgoryInfo.name) {
      return catgoryInfo.name;
    } else {
      return "N/A";
    }
  };

  let getSessionStatus = () => {
    if (
      moment(trainerData.startDate) < moment() &&
      moment(trainerData.endTime) < moment()
    ) {
      return "Completed";
    }

    // if (moment(trainerData.startDate) > moment()) {
    //   return "Not Started";
    // }
    if (moment() < moment(trainerData.startTime)) {
      return "Yet to start";
    } else if (moment() > moment(trainerData.endTime)) {
      return "Completed";
    } else if (
      moment() >= moment(trainerData.startTime) &&
      moment() <= moment(trainerData.endTime)
    ) {
      return "Started";
    }
    // else if (
    //   moment() >= moment(trainerData.startTime) &&
    //   moment() <= moment(trainerData.endTime)
    // ) {
    //   return "Started";
    // }
  };

  let handleSortList = (data) => {
    let _sortType = params.sortType == 1 ? -1 : 1;
    setParams({ ...params, sortType: _sortType, sortField: data, pageNo: 0 });
    setFetch(_fetch + 1);
  };

  let _downloadUserList = async () => {
    try {
      setIsdownload(true);
      downloadUserList({params:{ streamingId: _query.streamingId }})
        .then(async (response) => {
          let resp = response.data
          if (resp.statusCode == 1) {
              let { result } = resp.responseData
              FileSaver.saveAs(result.fileFullUrl)
              toast.success(resp.responseData.message)
          }
          else if (resp.statusCode == 0) {
              toast.error(resp.error.errorMessage)
          }
      })

      setIsdownload(false);
    } catch (error) {
      console.log(error);
      setIsdownload(false);
    }
  };

  let filterStatus =
    isFilterActive.fiterDropdown === true ||
    isFilterActive.filterBirthDay === true;

  return (
    <>
    <div id="scrollableDiv">
      <div>
        <div className="body-container-wrapper padding-class">
          <div className="body-container">
            <div className="d-flex justify-content-between border-bottom custom-list">
              <ol
                className="breadcrumb breadcrumb-custom"
                style={{ alignItems: "center" }}
              >
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/streaming2">Streaming management</Link>
                </li>
                <li className="breadcrumb-item">
                  <b>
                    {trainerData && trainerData.title ? trainerData.title : ""}
                  </b>
                </li>
              </ol>
              {countdown ? (
                <div className="list-inline-item timer">
                  <Countdown
                    date={_countDown()}
                    renderer={renderer}
                    onComplete={() => {
                      history.push(
                        "/streamingDetails?expertId=" +
                        _query.expertId +
                        "&&streamingId=" +
                        _query.streamingId +
                        "&&isEnable=0"
                      );

                      setCountDown(0);
                      _query.isEnable = 0;
                      _getStreamDetails1();
                    }}
                  />
                </div>
              ) : (
                ""
              )}
               <div className="mb-2 ml-4">
                <button  onClick={() =>openAddNotesPopUp()}  className="btn btn-sm btn-primary">Notes</button>
              </div>
            </div>
           
            {loading && (
              <div className="loader_wrapper">
                <LoaderSvg />
              </div>
            )}

            {isdownload && (
              <div className="loader_wrapper">
                <LoaderSvg />
              </div>
            )}

            <div className="stream-detail-header d-flex flex-wrap">
              <div className="filter-and-details w-100">
                <div className="filter-wrapper">
                  <div className={`clear-filter ${filterStatus ? "active" : ""}`}>
                    <Tooltip
                      placement="topLeft"
                      onClick={() => {
                        filterStatus && clearFilters();
                      }}
                      className="d-flex align-items-center"
                      title={`${filterStatus ? "Clear Filter" : "No filter applied yet"
                        }`}
                      arrowPointAtCenter
                    >
                      {filterStatus ? (
                        <RemoveFilter className="remove-filter" />
                      ) : (
                        <i className="fa fa-filter"></i>
                      )}
                      <span className="text-filter-mobile d-block d-sm-none ml-3">
                        Clear Filter
                      </span>
                    </Tooltip>
                  </div>
                  <div className="filters">
                    <div className="filter-header">
                      
                      {/* Gender Filter */}
                      <Select
                        key={selectkey + "gender"}
                        className="cm_min_width"
                        onChange={(val) => filterData(val, "gender")}
                        placeholder="Gender"
                        options={gender}
                        getOptionValue={(option) => option.id + "gender"}
                        value={gender.find(
                          (element) => element.value === params.gender
                        )}
                      />

                      {/* Birthday Filter */}
                      <div className="birthday-filter">
                        <input
                          type="checkbox"
                          className="imput-box"
                          value=""
                          id="flexCheckChecked"
                          checked={params.birthdayToday}
                          onChange={filterByBirthday}
                        />
                        <label className="label">Birthday</label>
                      </div>

                      {/* Age Range filter */}
                      <Select
                        className="cm_min_width"
                        key={selectkey + "ageRange"}
                        onChange={(val) => filterData(val, "ageRange")}
                        placeholder="Age Range"
                        options={ageRange}
                        getOptionValue={(option) => option.id + "ageRange"}
                      />

                      {/* Download User */}
                      {getSessionStatus() == "Completed" &&
                        loggedInRole === ADMIN_ROLES.ADMIN &&
                        totalCount > 0 ? (
                        <div className="download-user-list d-flex align-items-center">
                          <a
                            onClick={() => {
                              _downloadUserList();
                            }}
                            className="hover btn button-primary mx-auto"
                          >
                            <DownloadIcon />
                            <span className="ml-2">User List</span>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="stream-detail">
                  <ul className="list-unstyled detail-list">
                    <li>
                      <span className="label">Name : </span>
                      <span className="value">{trainerData.title ? trainerData.title : ""}</span>
                    </li>
                    <li>
                      <span className="label">Status : </span>
                      <span className="value">{getSessionStatus()}</span>
                    </li>
                    <li className="customer-count">
                      <span className="label">Customer Count : </span>
                      <span className="value value-count">{totalCount}</span>
                    </li>
                    <li>
                      <span className="label">Trainer Name : </span>
                      <span className="value">
                        {trainerData.expertDetails
                          ? trainerData.expertDetails.firstName +
                          " " +
                          trainerData.expertDetails.lastName
                          : "-"}
                      </span>
                    </li>
                    <li>
                      <span className="label">Category : </span>
                      <span className="value">
                        {trainerData.categoryId
                          ? categoryName(trainerData.categoryId)
                          : "-"}
                      </span>
                    </li>
                    <li>
                      <span className="label">Start Date : </span>
                      <span className="value">
                        {trainerData.startDate
                          ? moment(trainerData.startDate).format("MMMM Do YYYY")
                          : ""}
                      </span>
                    </li>
                    <li>
                      <span className="label">Start Time : </span>
                      <span className="value">
                        {trainerData.startTime
                          ? moment(trainerData.startTime).format("h:mm a")
                          : "-"}
                      </span>
                    </li>
                    <li>
                      <span className="label">End Time : </span>
                      <span className="value">
                        {trainerData.endTime
                          ? moment(trainerData.endTime).format("h:mm a")
                          : "-"}
                      </span>
                    </li>
                    {loggedInRole === ADMIN_ROLES.TRAINER ? (
                      ""
                    ) : moment(trainerData.startDate) < moment() &&
                      moment(trainerData.endTime) < moment() ? (
                      <li>
                        <span className="label">Rating:</span>
                        <span className="value">
                          {trainerData.avgRating ? (
                            <StarRatings
                              rating={trainerData.avgRating}
                              starDimension="14px"
                              starSpacing="0"
                              starRatedColor="rgba(255,108,11,1)"
                            />
                          ) : (
                            "-"
                          )}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
            

            {totalCount ? (
              <InfiniteScroll
                dataLength={streamData.length} //This is important field to render the next data
                next={() => {
                  fetchData();
                }}
                hasMore={totalCount != streamData.length}
                isfetching={false}
                // scrollableTarget="scrollableDiv"
                loader={totalCount != streamData.length ? <p>Loading</p> : ""}
              >
                <div className="table-responsive custom-table-view stream-detail">
                  <table className="table table-bordered table-striped text-center">
                    <thead>
                      <tr>
                        <th scope="col" className="filter_text">Rank
                        
                        <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("rank");
                            }}
                          ></button>
                        </th>
                        <th scope="col" className="filter_text">
                          Name
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("firstName");
                            }}
                          ></button>
                        </th>
                        <th scope="col" className="filter_text">
                          Age
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("dob");
                            }}
                          ></button>
                        </th>
                        <th scope="col" className="filter_text">
                          Weight
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("weight");
                            }}
                          ></button>
                        </th>
                        <th scope="col" className="filter_text">
                          Height
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("height");
                            }}
                          ></button>
                        </th>
                        <th scope="col" className="filter_text">
                          Gender
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("gender");
                            }}
                          ></button>
                        </th>
                        <th scope="col" className="filter_text">
                          Birthday
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("birthday");
                            }}
                          ></button>
                        </th>

                        <th scope="col" className="filter_text">
                          Location
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("location");
                            }}
                          ></button>
                        </th>

                        <th scope="col" className="filter_text">
                          Customer Login Time
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("sessionStartTime");
                            }}
                          ></button>
                        </th>
                        <th scope="col" className="filter_text">
                          Rides count
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("totalRides");
                            }}
                          ></button>
                        </th>
                        {loggedInRole === ADMIN_ROLES.TRAINER ? (
                          ""
                        ) : (
                          <th scope="col" className="filter_text">
                            Customer rating
                            <i
                              className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                                }`}
                            ></i>
                            <button
                              onClick={() => {
                                handleSortList("rating");
                              }}
                            ></button>
                          </th>
                        )}
                        {loggedInRole === ADMIN_ROLES.TRAINER ? (
                          ""
                        ) : (
                          <th scope="col">Customer review</th>
                        )}

                        <th scope="col" className="filter_text">
                          Fitplus Sign up date
                          <i
                            className={`fa fa-sort-alpha-${params.sortType == -1 ? "asc" : "desc"
                              }`}
                          ></i>
                          <button
                            onClick={() => {
                              handleSortList("created");
                            }}
                          ></button>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="display-bold">
                      {streamData.length>0 ?streamData.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td>
                              <span className="cm_table_three_dots">
                                {data.userDetails
                                  ?
                                  data.userDetails.firstName +
                                  " " +
                                  data.userDetails.lastName
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <span className="">
                                {data.userDetails && data.userDetails.dob
                                  ? Math.round(
                                    moment().diff(
                                      data.userDetails.dob,
                                      "years",
                                      true
                                    )
                                  )
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <span className="">
                                {data.userDetails && data.userDetails.weight && data.userDetails.weight.value
                                  ? data.userDetails.weight.value.toFixed(2) + " Kg"
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <span className="">
                                {data.userDetails && data.userDetails.height && data.userDetails.height.value
                                  ? data.userDetails.height.value.toFixed(2) 
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <span className="">
                                {data.userDetails
                                  ? getGender(data.userDetails.gender)
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <span className="">
                                {data.userDetails && data.userDetails.dob
                                  ? checkBirthday(data.userDetails.dob)
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <span className="">
                                {data.userDetails
                                  ? data.userDetails.location
                                  : ""}
                              </span>
                            </td>

                            <td className="cm_no_wrap transform">
                              {data.sessionStartTime
                                ? moment(data.sessionStartTime).format(
                                  "D MMM h:mm a"
                                )
                                : ""}
                            </td>

                            {/* <td className="cm_no_wrap">
                            {`${dateFormat(data.startDate, "d/mm/yyyy")}`}{" "}
                            <br />
                            {`${moment(data.startTime).format("LT")} - ${moment(
                              data.endTime
                            ).format("LT")}`}
                          </td> */}

                            <td className="cm_no_wrap">
                              {data.totalRides
                                ? data.totalRides.ridesCount
                                : ""}
                            </td>

                            {loggedInRole === ADMIN_ROLES.TRAINER ? (
                              ""
                            ) : (
                              <td className="cm_no_wrap">
                                {data.rating ? (
                                  <StarRatings
                                    rating={data.rating}
                                    starDimension="14px"
                                    starSpacing="0"
                                    starRatedColor="rgba(255,108,11,1)"
                                  />
                                ) : (
                                  "-"
                                )}
                              </td>
                            )}
                            {loggedInRole === ADMIN_ROLES.TRAINER ? (
                              ""
                            ) : (
                              <td className="">
                                {data.review ? data.review : "-"}
                              </td>
                            )}

                            <td className="cm_no_wrap">
                              {data.userDetails && data.userDetails.created
                                ? moment(data.userDetails.created).format(
                                  "MMMM Do YYYY"
                                )
                                : ""}
                            </td>
                          </tr>
                        );
                      }):<h3 className="empty_error">
                      Sorry, we couldn't find any content{" "}
                      {params.search ? "for" : "!"} <span>{params.search}</span>
                    </h3>}
                    </tbody>
                  </table>

                  {!streamData.length  ? (
                   
                    <h3 className="empty_error">
                      Sorry, we couldn't find any content{" "}
                      {params.search ? "for" : "!"} <span>{params.search}</span>
                    </h3>
                  ) : null}
                </div>
              </InfiniteScroll>
            ) : (
              <h3 className="empty_error border-top pt-5 text-dark">
                      Sorry, we couldn't find any content{" "}
                      {params.search ? "for" : "!"} <span>{params.search}</span>
                    </h3>
            )}
          </div>
        </div>
      </div>
    </div>
    <PopUpModal
            isOpen={showAddNote}
            closeModal={closeAddNoteModal}
            message='Notes'
            submit={addNotesHandler}
            submitTitle='Save'
        >
            <AddNotes
                note={note}
                handleChange={notesChangeHandler}
                validationError={validationError}
            />
        </PopUpModal>
    </>
  );
};

export default streamingDetails;
