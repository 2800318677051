import React, { Component } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import HOC from "../../HOC";
import { LoaderSvg } from "../Reusable";
import Pagination from "react-js-pagination";
import {
  addSuccessStories,
  editSuccessStories,
  getSuccessStoriesByAdmin,
  deleteSuccessStories,
} from "../../config/services/StoriesService";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  FormControl,
  Alert,
  Accordion,
  Card,
  FormGroup,
  FormLabel,
} from "react-bootstrap";

import ReactQuill from "react-quill";
import SearchSpinner from "../common/SearchSpinner";

class Story extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      storiesList: [],
      search: "",
      count: 10,
      totalStories: 15,
      pageNo: 1,
      sortType: -1,
      sortField: "created",
      validationError: "",
      editStoryPopup: false,
      tempStoryId: "",
      FilterStatus: false,
      filterBy: "",
      viewDetailsPopup: false,
      selectedStory: {},
      name: "",
      beforeImage: "",
      afterImage: "",
      coverImage: "",
      beforeImagePreview: "",
      afterImagePreview: "",
      coverImagePreview: "",
      shortIntroduction: "",
      motivation: "",
      oneFitplusFitnessBegins: "",
      goingStrong: "",
      description: "",
      age: "",
      weight: "",
      height: "",
      gender: "",
      medicalCondition: "",
      deleteStoryPopup: false,
      addStoryPopup: false,
      metaTitle: '',
      metaDescription: ''
    };
  }

  componentDidMount() {
    this.getSuccessStories();
  }

  getSuccessStories = () => {
    let { search, count, pageNo, sortType, sortField, filterBy } = this.state;
    let paramsObj = {
      params: {
        search,
        limit: count,
        page: pageNo,
        sortType,
        sortField,
        filter: filterBy,
      },
    };

    getSuccessStoriesByAdmin(paramsObj).then((res) => {
      let response = res.data;

      if (response.statusCode == 1) {
        let _stories =
          response.responseData.result && response.responseData.result.result
            ? response.responseData.result.result
            : [];
        let _totalCount =
          response.responseData.result &&
            response.responseData.result.totalCount
            ? response.responseData.result.totalCount
            : 0;
        this.setState({
          storiesList: _stories,
          totalStories: _totalCount,
          loader: false,
          searchLoader: false
        });
      } else if (response.statusCode == 0) {
        this.setState({ loader: false, searchLoader: false });
        toast.error(response.error.errorMessage);
      }
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value, validationError: "" });
  };

  handleSort = (e) =>
    this.setState(
      { sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 },
      () => this.getSuccessStories()
    );

  handleSearch = (e) =>
    this.setState({ search: e.target.value, pageNo: 1, searchLoader: true }, () =>
      this.getSuccessStories()
    );

  handlePageChange = (pageNumber) =>
    this.setState({ pageNo: pageNumber }, () => {
      this.getSuccessStories();
    });

  handleEditStoryPopup = (story) => {
    let {
      _id,
      name,
      beforeImage,
      afterImage,
      coverImage,
      shortIntroduction,
      motivation,
      oneFitplusFitnessBegins,
      goingStrong,
      description,
      age,
      weight,
      height,
      gender,
      medicalCondition,
      metaTitle,
      metaDescription,
      listingImage
    } = story;
    this.setState({
      tempStoryId: _id,
      name,
      beforeImagePreview: beforeImage,
      afterImagePreview: afterImage,
      coverImagePreview: coverImage,
      listingImagePreview: listingImage,
      shortIntroduction,
      motivation,
      oneFitplusFitnessBegins,
      goingStrong,
      description,
      age,
      weight,
      height,
      gender,
      medicalCondition,
      editStoryPopup: true,
      metaTitle,
      metaDescription
    });
  };

  handleClose = () => {
    this.setState({
      editStoryPopup: false,
      tempStoryId: "",
      viewDetailsPopup: false,
      validationError: "",
      selectedStory: {},
      name: "",
      beforeImage: "",
      afterImage: "",
      coverImage: "",
      beforeImagePreview: "",
      afterImagePreview: "",
      coverImagePreview: "",
      shortIntroduction: "",
      motivation: "",
      oneFitplusFitnessBegins: "",
      goingStrong: "",
      description: "",
      age: "",
      weight: "",
      height: "",
      gender: "",
      medicalCondition: "",
      loader: false,
      deleteStoryPopup: false,
      addStoryPopup: false,
      metaTitle: '',
      metaDescription: '',
      listingImage: '',
      listingImagePreview: ''
    });
  };

  toggleFilterStatus = () => {
    this.setState({
      FilterStatus: !this.state.FilterStatus,
    });
  };

  handleFilter = (filterBy) => {
    this.setState({ filterBy }, () => this.getSuccessStories());
  };

  viewStoryPopup = (story) => {
    this.setState({ viewDetailsPopup: true, selectedStory: story });
  };

  submitEditStory = async (e) => {
    let error = await this.validateData();
    if (error) {
      return;
    }

    e.preventDefault();
    let {
      tempStoryId,
      name,
      beforeImage,
      afterImage,
      coverImage,
      shortIntroduction,
      motivation,
      oneFitplusFitnessBegins,
      goingStrong,
      description,
      age,
      weight,
      height,
      gender,
      medicalCondition,
      metaTitle,
      metaDescription,
      listingImage
    } = this.state;

    let params = new FormData();
    params.append("storieId", tempStoryId);
    params.append("name", name);
    params.append("beforeImage", beforeImage);
    params.append("afterImage", afterImage);
    params.append("coverImage", coverImage);
    params.append("listingImage", listingImage);
    params.append("shortIntroduction", shortIntroduction);
    params.append("motivation", motivation);
    params.append("oneFitplusFitnessBegins", oneFitplusFitnessBegins);
    params.append("goingStrong", goingStrong);
    params.append("description", description);
    params.append("age", age);
    params.append("weight", weight);
    params.append("height", height);
    params.append("gender", gender);
    params.append("medicalCondition", medicalCondition);
    params.append("metaTitle", metaTitle);
    params.append("metaDescription", metaDescription);

    this.setState({ loader: true });
    editSuccessStories(params).then((res) => {
      let response = res.data;
      if (response.statusCode == 1) {
        this.handleClose();
        this.getSuccessStories();
        toast.success(response.responseData.message);
      } else if (response.statusCode == 0) {
        this.setState({
          loader: false,
          validationError: response.error.errorMessage,
        });
      }
    });
  };

  handleImageChange = (e) => {
    let { name, value } = e.target;
    let file = e.target.files[0];
    let previewFieldName = name + "Preview";
    let fieldName = name;
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ [previewFieldName]: reader.result });
    };
    reader.readAsDataURL(file);
    this.setState({ [previewFieldName]: file });
    this.setState({ [fieldName]: e.target.files[0] });
  };

  handleChangeDescription = (data, fieldName) => {
    this.setState({ [fieldName]: data });

  }

  handleDeleteStoryPopup = (story) => {
    this.setState({ deleteStoryPopup: true, tempStoryId: story._id });
  };
  submitDeleteStory = (e) => {
    e.preventDefault();
    let { tempStoryId } = this.state;
    let params = { storieId: tempStoryId };

    deleteSuccessStories(params).then((res) => {
      let response = res.data;
      if (response.statusCode == 1) {
        this.handleClose();
        this.getSuccessStories();
        toast.success(response.responseData.message);
      } else if (response.statusCode == 0) {
        this.setState({
          loader: false,
          validationError: response.error.errorMessage,
        });
      }
    });
  };

  handleAddStoryPopup = () => {
    this.setState({ addStoryPopup: true });
  };

  validateData = () => {
    let {
      name,
      coverImage,
      beforeImage,
      afterImage,
      listingImage,
      oneFitplusFitnessBegins,
      shortIntroduction,
      motivation,
      description,
      coverImagePreview,
      metaTitle,
      metaDescription,
      beforeImagePreview,
      afterImagePreview,
      listingImagePreview
    } = this.state;

    if (!name) {
      this.setState({ validationError: "Please add user name" });
      return true;
    }

    if (!metaTitle) {
      this.setState({ validationError: "Please add metaTitle" });
      return true;
    }

    if (!metaDescription) {
      this.setState({ validationError: "Please add metaDescription" });
      return true;
    }

    if (!coverImage && !coverImagePreview) {
      this.setState({ validationError: "Please add cover Image" });
      return true;
    }

    if (!beforeImage && !beforeImagePreview) {
      this.setState({ validationError: "Please add before Image" });
      return true;
    }

    if (!afterImage && !afterImagePreview) {
      this.setState({ validationError: "Please add after Image" });
      return true;
    }

    if (!listingImage && !listingImagePreview) {
      this.setState({ validationError: "Please add listing Image" });
      return true;
    }

    // if (!oneFitplusFitnessBegins) {
    //   this.setState({ validationError: "Please add Title" });
    //   return true;
    // }

    // if (!shortIntroduction) {
    //   this.setState({ validationError: "Please add Sub Title" });
    //   return true;
    // }

    if (!motivation) {
      this.setState({ validationError: "Please add Detail Description" });
      return true;
    }

    if (!description) {
      this.setState({ validationError: "Please add  listing description" });
      return true;
    }
    return false;
  };

  submitAddStory = async (e) => {
    let error = await this.validateData();
    if (error) {
      return;
    }

    e.preventDefault();
    let {
      tempStoryId,
      name,
      beforeImage,
      afterImage,
      coverImage,
      shortIntroduction,
      motivation,
      oneFitplusFitnessBegins,
      goingStrong,
      description,
      age,
      weight,
      height,
      gender,
      medicalCondition,
      metaTitle,
      metaDescription,
      listingImage
    } = this.state;

    let params = new FormData();
    params.append("storieId", tempStoryId);
    params.append("name", name);
    params.append("beforeImage", beforeImage);
    params.append("afterImage", afterImage);
    params.append("coverImage", coverImage);
    params.append("listingImage", listingImage);
    params.append("shortIntroduction", shortIntroduction);
    params.append("motivation", motivation);
    params.append("oneFitplusFitnessBegins", oneFitplusFitnessBegins);
    params.append("goingStrong", goingStrong);
    params.append("description", description);
    params.append("age", age);
    params.append("weight", weight);
    params.append("height", height);
    params.append("gender", gender);
    params.append("medicalCondition", medicalCondition);
    params.append("metaTitle", metaTitle);
    params.append("metaDescription", metaDescription);


    this.setState({ loader: true });
    addSuccessStories(params).then((res) => {
      let response = res.data;
      if (response.statusCode == 1) {
        this.handleClose();
        this.getSuccessStories();
        toast.success(response.responseData.message);
      } else if (response.statusCode == 0) {
        this.setState({
          loader: false,
          validationError: response.error.errorMessage,
        });
      }
    });
  };

  render() {
    let {
      loader,
      storiesList,
      search,
      pageNo,
      count,
      totalStories,
      editStoryPopup,
      validationError,
      viewDetailsPopup,
      selectedStory,
      name,
      beforeImagePreview,
      afterImagePreview,
      coverImagePreview,
      shortIntroduction,
      motivation,
      oneFitplusFitnessBegins,
      goingStrong,
      description,
      age,
      weight,
      height,
      gender,
      medicalCondition,
      deleteStoryPopup,
      addStoryPopup,
      metaTitle,
      metaDescription,
      listingImagePreview,
      searchLoader
    } = this.state;
    return (
      <HOC>
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">Stories</li>
            </ol>

            {loader && (
              <div className="loader_wrapper">
                <LoaderSvg />
              </div>
            )}
            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="cm_page_heading">Achiever's Stories</h4>
                  </div>
                  <div className="col-md-3 text-right cm_search position-relative">
                    {searchLoader ?
                      <SearchSpinner type="text-info" />
                      : null}
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search by User Name......."
                      onChange={this.handleSearch}
                      value={search}
                    />
                    <i className="fa fa-search" />
                  </div>

                  <div className="col-md-3 text-right">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.handleAddStoryPopup}
                    >
                      Add New Story
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">
                      User's Name{" "}
                    </th>

                    <th scope="col" className="filter_text">
                      Title{" "}
                    </th>
                    <th scope="col" className="filter_text">
                      Listing Description{" "}
                    </th>
                    <th scope="col" className="filter_text">
                      Detail Description
                    </th>

                    {/* <th scope="col" className="filter_text"> Age </th>
                                        <th scope="col" className="filter_text"> Weight</th>
                                        <th scope="col" className="filter_text"> Height</th> */}
                    {/* <th scope="col" className="filter_text"> Gender</th> */}
                    {/* <th scope="col" className="filter_text">Medical Condition</th> */}

                    <th scope="col" className="filter_text">
                      Action &nbsp;{" "}
                    </th>
                    <th
                      scope="col"
                      className="filter_text"
                      onClick={() => this.handleSort("created")}
                    >
                      Created &nbsp; <i className="fa fa-sort" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {storiesList.map((details, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1 + (pageNo - 1) * count}</td>

                        <td>
                          <span className="cm_table_three_dots">
                            {details.name}
                          </span>
                        </td>

                        <td>
                          <span className="cm_table_three_dots">
                            {details.oneFitplusFitnessBegins}
                          </span>
                        </td>



                        <td>
                          <span className="cm_table_three_dots">
                            {details.description ? details.description.replace(/(<([^>]+)>)/gi, "") : ''}
                          </span>
                        </td>
                        <td>
                          <span className="cm_table_three_dots">
                            {details.motivation ? details.motivation.replace(/(<([^>]+)>)/gi, "") : ''}
                          </span>
                        </td>
                        {/* <td>
                          <span className="cm_table_three_dots">
                            {details.motivation}
                          </span>
                        </td> */}

                        {/* <td><span className="cm_table_three_dots">
                                                        {details.age}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.weight}</span>
                                                    </td>
                                                    <td><span className="cm_table_three_dots">
                                                        {details.height}</span>
                                                    </td> */}
                        {/* <td><span className="cm_table_three_dots">
                                                        {details.gender == 1 ? 'Male' : details.gender == 2 ? 'Female' : 'Others'}</span>
                                                    </td> */}
                        {/* <td><span className="cm_table_three_dots">
                                                        {details.medicalCondition}</span>
                                                    </td> */}
                        <td className="cm_no_wrap">
                          {/* <Button
                            className="m-1"
                            variant="outline-dark"
                            onClick={() => this.viewStoryPopup(details)}
                          >
                            {" "}
                            <i className="fa fa-eye" />{" "}
                          </Button> */}
                          <Button
                            className="m-1"
                            variant="outline-info"
                            onClick={() => this.handleEditStoryPopup(details)}
                          >
                            {" "}
                            <i className="fa fa-pencil" />{" "}
                          </Button>
                          <Button
                            className="m-1"
                            variant="outline-danger"
                            onClick={() => this.handleDeleteStoryPopup(details)}
                          >
                            {" "}
                            <i className="fa fa-trash" />{" "}
                          </Button>
                        </td>
                        <td className="cm_no_wrap">
                          {dateFormat(details.created, "d mmmm yyyy")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                activePage={pageNo}
                itemsCountPerPage={count}
                totalItemsCount={totalStories}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />

              {!storiesList.length ? (
                <h3 className="empty_error">
                  Sorry, we couldn't find any content {search ? "for" : "!"}{" "}
                  <span>{search}</span>
                </h3>
              ) : null}
            </div>
          </div>
        </div>

        <Modal
          show={addStoryPopup}
          onHide={this.handleClose}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormControl
                placeholder="Enter Name......."
                value={name}
                name="name"
                onChange={this.handleChange}
              />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="modal-custom-bar-margin">
            <div className="card card_content">
              {coverImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={coverImagePreview}
                  alt="Cover Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Cover Image*</span>
              <span></span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="coverImage"
                  name="coverImage"
                />
              </div>
            </div>

            <div className="card card_content">
              {beforeImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={beforeImagePreview}
                  alt="Before Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Before Image</span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="beforeImage"
                  name="beforeImage"
                />
              </div>
            </div>

            <div className="card card_content">
              {afterImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={afterImagePreview}
                  alt="After Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">After Image</span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="afterImage"
                  name="afterImage"
                />
              </div>
            </div>

            <div className="card card_content">
              {listingImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={listingImagePreview}
                  alt="Listing Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Listing Image</span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="listingImage"
                  name="listingImage"
                />
              </div>
            </div>

            <Accordion defaultActiveKey="0">
              {/* <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">User Details:</Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className='row'>
                                            <FormGroup className='col-4'>
                                                <FormLabel>Enter Age</FormLabel>
                                                <FormControl
                                                    placeholder="Age in years....."
                                                    value={age}
                                                    name='age' onChange={this.handleChange} />
                                            </FormGroup>

                                            <FormGroup className='col-4'>
                                                <FormLabel>Enter Weight</FormLabel>
                                                <FormControl
                                                    placeholder="Weight in Kgs...."
                                                    value={weight}
                                                    name='weight' onChange={this.handleChange} />
                                            </FormGroup>

                                            <FormGroup className='col-4'>
                                                <FormLabel>Enter Height</FormLabel>
                                                <FormControl
                                                    placeholder="Height in feet inches ex:5'2 "
                                                    value={height}
                                                    name='height' onChange={this.handleChange} />
                                            </FormGroup>
                                        </div>

                                        <div className='row'>
                                            <FormGroup controlId="gender" className='col-6'>
                                                <FormLabel>Select Gender</FormLabel>
                                                <FormControl as="select" placeholder="Select gender..." value={gender} name='gender' onChange={this.handleChange}>
                                                    <option value={1}>Male</option>
                                                    <option value={2}>Female</option>
                                                </FormControl>
                                            </FormGroup>

                                            <FormGroup controlId="medicalCondition" className='col-6'>
                                                <FormLabel>Select Medical Condition</FormLabel>
                                                <FormControl as="select" placeholder="Select gender..." value={medicalCondition} name='medicalCondition' onChange={this.handleChange}>
                                                    <option value={1}>Diabetes</option>
                                                    <option value={2}>Thyroid</option>
                                                    <option value={3}>PCOD</option>
                                                    <option value={4}>Cholestrol</option>
                                                    <option value={5}>PhysicalInjury</option>
                                                    <option value={6}>Hypertension</option>
                                                </FormControl>
                                            </FormGroup>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}


              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Meta Title*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="metaTitle"
                      value={metaTitle}
                      name="metaTitle"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>


              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Meta Description*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="metaDescription"
                      value={metaDescription}
                      name="metaDescription"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Title
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Title"
                      value={oneFitplusFitnessBegins}
                      name="oneFitplusFitnessBegins"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Listing Description*
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {/* <FormControl
                                            as="textarea"
                                            placeholder="SubTitle"
                                            value={shortIntroduction}
                                            name='shortIntroduction' onChange={this.handleChange} /> */}
                    <ReactQuill
                      theme="snow"
                      value={description}
                      name='description' onChange={(data) => { this.handleChangeDescription(data, "description") }}
                      height={250}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ['link', 'image', 'video'],
                          ["code-block"],
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }], // superscript/subscript
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ direction: "rtl" }], // text direction
                          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Detail Description*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <ReactQuill
                      theme="snow"
                      value={motivation}
                      name='motivation' onChange={(data) => { this.handleChangeDescription(data, "motivation") }}
                      height={250}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ['link', 'image', 'video'],
                          ["code-block"],
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }], // superscript/subscript
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ direction: "rtl" }], // text direction
                          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              {/* <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Description*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Description...."
                      value={description}
                      name="description"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Secondary Description:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Secondary Description...."
                      value={goingStrong}
                      name="goingStrong"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card> */}
            </Accordion>
          </Modal.Body>

          {validationError && (
            <Alert variant="danger"> {validationError} </Alert>
          )}
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="success" onClick={this.submitAddStory}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={editStoryPopup}
          onHide={this.handleClose}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormControl
                placeholder="Enter Name......."
                value={name}
                name="name"
                onChange={this.handleChange}
              />
            </Modal.Title>
            {/* <Modal.Title>{name}</Modal.Title> */}
          </Modal.Header>

          <Modal.Body className="modal-custom-bar-margin">
            <div className="card card_content">
              {coverImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={coverImagePreview}
                  alt="Cover Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Cover Image*</span>
              <span></span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="coverImage"
                  name="coverImage"
                />
              </div>
            </div>

            <div className="card card_content">
              {beforeImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={beforeImagePreview}
                  alt="Before Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Before Image</span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="beforeImage"
                  name="beforeImage"
                />
              </div>
            </div>

            <div className="card card_content">
              {afterImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={afterImagePreview}
                  alt="After Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">After Image</span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="afterImage"
                  name="afterImage"
                />
              </div>
            </div>

            <div className="card card_content">
              {listingImagePreview ? (
                <img
                  className="card-img-top object-contain"
                  src={listingImagePreview}
                  alt="Listing Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Listing Image</span>
              <div class="radio_wrapper text-center">
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  id="listingImage"
                  name="listingImage"
                />
              </div>
            </div>

            <Accordion defaultActiveKey="0">

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Meta Title*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="metaTitle"
                      value={metaTitle}
                      name="metaTitle"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>


              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Meta Description*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="metaDescription"
                      value={metaDescription}
                      name="metaDescription"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Title
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Title"
                      value={oneFitplusFitnessBegins}
                      name="oneFitplusFitnessBegins"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Listing Description*
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="1">

                  <Card.Body>
                    {/* <FormControl
                                            as="textarea"
                                            placeholder="SubTitle"
                                            value={shortIntroduction}
                                            name='shortIntroduction' onChange={this.handleChange} /> */}
                    <ReactQuill
                      theme="snow"
                      value={description}
                      name='description' onChange={(data) => { this.handleChangeDescription(data, "description") }}
                      height={250}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ['link', 'image', 'video'],
                          ["code-block"],
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }], // superscript/subscript
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ direction: "rtl" }], // text direction
                          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Detail Description*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <ReactQuill
                      theme="snow"
                      value={motivation}
                      name='motivation' onChange={(data) => { this.handleChangeDescription(data, "motivation") }}
                      height={250}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ['link', 'image', 'video'],
                          ["code-block"],
                          ["bold", "italic", "underline", "strike"], // toggled buttons
                          ["blockquote", "code-block"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }], // superscript/subscript
                          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                          [{ direction: "rtl" }], // text direction
                          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              {/* <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Title*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Title"
                      value={oneFitplusFitnessBegins}
                      name="oneFitplusFitnessBegins"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    SubTitle*
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="SubTitle"
                      value={shortIntroduction}
                      name="shortIntroduction"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Quote*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    {" "}
                    <FormControl
                      as="textarea"
                      placeholder="Quote"
                      value={motivation}
                      name="motivation"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Description*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Description...."
                      value={description}
                      name="description"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Secondary Description:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Secondary Description"
                      value={goingStrong}
                      name="goingStrong"
                      onChange={this.handleChange}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card> */}
            </Accordion>
          </Modal.Body>

          {validationError && (
            <Alert variant="danger"> {validationError} </Alert>
          )}
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="success" onClick={this.submitEditStory}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={viewDetailsPopup}
          onHide={this.handleClose}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedStory.name}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="card card_content">
              {selectedStory.coverImage ? (
                <img
                  className="card-img-top object-contain"
                  src={selectedStory.coverImage}
                  alt="Cover Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Cover Image</span>
            </div>

            <div className="card card_content">
              {selectedStory.beforeImage ? (
                <img
                  className="card-img-top object-contain"
                  src={selectedStory.beforeImage}
                  alt="Before Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">Before Image</span>
            </div>

            <div className="card card_content">
              {selectedStory.afterImage ? (
                <img
                  className="card-img-top object-contain"
                  src={selectedStory.afterImage}
                  alt="After Image"
                />
              ) : (
                <div className="card-img-top">No Image</div>
              )}
              <span className="card_style_cat">After Image</span>
            </div>

            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Title*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Title"
                      value={selectedStory.oneFitplusFitnessBegins}
                      disabled
                      name="oneFitplusFitnessBegins"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    SubTitle*
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="SubTitle"
                      value={selectedStory.shortIntroduction}
                      disabled
                      name="shortIntroduction"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Quote*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    {" "}
                    <FormControl
                      as="textarea"
                      placeholder="Quote"
                      value={selectedStory.motivation}
                      disabled
                      name="motivation"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Description*
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Description...."
                      value={selectedStory.description}
                      disabled
                      name="description"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Secondary Description:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <FormControl
                      as="textarea"
                      placeholder="Secondary Description...."
                      value={selectedStory.goingStrong}
                      disabled
                      name="goingStrong"
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={deleteStoryPopup}
          onHide={this.handleClose}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h6>Are you sure, you want to delete this story?</h6>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="success" onClick={this.submitDeleteStory}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </HOC>
    );
  }
}

export default Story;
