import axios from 'axios';
import API from '../Api';


export function getDietTempelateLabels(params) {
    return axios.get(API.DIET_TEMPELATE_LABELS,params)
}

export function editDietPlanTemplate(params) {
    return axios.post(API.EDIT_DIET_PLAN_TEMPLATE,params)
}

export function getDietPlanTempelate(params) {
    return axios.get(API.GET_DIET_PLAN_TEMPELATE,params)
}

export function deleteTemplateById(params) {
    return axios.post(API.DELETE_DIET_PLAN_TEMPLATE,params)
}

export const DietTempelateService = {
    getDietTempelateLabels,
    getDietPlanTempelate,
    editDietPlanTemplate,
    deleteTemplateById
};
