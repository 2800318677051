import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { addCoins, updateCoinsWeightage, getCoinsWeightage, deleteCoinsWeightage } from '../../config/services/coinsService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Menus } from '../template/Menus'
import { activityTypes } from '../constants/activityTypes'

class Coins extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            coinsList: [],
            roleName: '',
            filter: '',
            count: 10,
            totalCoins: 0,
            pageNo: 1,
            sortType: '',
            sortField: '',
            addCoinsPopup: false,
            deleteCoinsPopup: false,
            title: '',
            titleErrorMsg: '',
            editCoinsPopup: false,
            tempCoinsId: '',
            activityType: '',
            coins: '',
            activityValue: '',
            unit: '',
        }
    }


    componentDidMount() {
        this.getCoinsList();
    }


    selectActivity = (activity) => {
        this.setState({ activityType: activity.value, unit: activity.unit })
    }


    getCoinsList = () => {
        let { filter, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                filter, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        this.setState({ loader: true })
        getCoinsWeightage(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode === 1) {
                    this.setState({
                        coinsList: response.responseData.result,
                        totalCoins: response.responseData.totalCount,
                        loader: false
                    })
                } else if (response.statusCode === 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    openAddCoinsPopup = () => this.setState({ addCoinsPopup: !this.state.addCoinsPopup });

    closeModal = () => this.setState({
        addCoinsPopup: false, activityType: '', activityValue: '', coins: '', unit: '',
        deleteCoinsPopup: false, editCoinsPopup: false, tempCoinsId: ''
    });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, titleErrorMsg: '' })
    }


    handleSubmitCoins = (e) => {
        e.preventDefault();
        let { activityType, activityValue, coins } = this.state;
        let params = {
            activityType, activityValue, coins
        }

        if (!activityType) {
            this.setState({ titleErrorMsg: 'Select an activity type' })
        } else if (!coins) {
            this.setState({ titleErrorMsg: 'Enter no of coins to be rewarded for this activity, type.' })
        } else {
            this.setState({ loader: true })
            this.addCoinsApi(params)
        }
    }

    addCoinsApi = (params) => {
        addCoins(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode === 1) {
                    this.getCoinsList()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.closeModal()
                } else if (response.statusCode === 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    openDeleteCoinsPopup = (data, coins) => this.setState({ deleteCoinsPopup: !this.state.deleteCoinsPopup, tempCoinsId: coins._id });


    deleteCoins = (e) => {
        e.preventDefault();
        const { tempCoinsId } = this.state
        let paramsObj = {
            coinWeightageId: tempCoinsId
        }
        deleteCoinsWeightage(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getCoinsList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    openEditCoinsPopup = (data, coins) => {
        let unit = activityTypes.find(act => act.value === coins.activityType).unit        
        this.setState({
            editCoinsPopup: true,
            tempCoinsId: coins._id,
            coins: coins.coins,
            activityType: coins.activityType,
            activityValue: coins.activityValue,
            unit,
        })
    }


    editCoinsSubmit = (e) => {
        e.preventDefault();
        let { activityValue, coins, tempCoinsId } = this.state;
        
        let params = {
            activityValue, coins, coinWeightageId: tempCoinsId
        }
        if (!activityValue) {
            this.setState({ titleErrorMsg: 'Enter the activity value' })
        } else if (!coins) {
            this.setState({ titleErrorMsg: 'Enter no of coins to be rewarded for this activity, type.' })
        } else {
            this.setState({ titleErrorMsg: "", loader: true })
            this.editCoinsApi(params)
        }


    }

    editCoinsApi = (params) => {
        updateCoinsWeightage(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getCoinsList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getCoinsList());

    handleFilter = e => this.setState({ filter: e.value }, () => this.getCoinsList());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getCoinsList() });

    resetFilter = (e) => {
        e.preventDefault();
        this.setState({ filter: "" }, () => this.getCoinsList())
    }


    render() {
        const {
            loader, coinsList, filter, addCoinsPopup, titleErrorMsg, deleteCoinsPopup, pageNo, editCoinsPopup, count, totalCoins, activityType, activityValue, coins, unit,coinType
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Coins</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading margin-bottom-10px mt-1">Coins</h4>
                                    </div>

                                    <div className="col-sm-1 text-right mt-2">
                                        <i className="fa fa-refresh cm_pointer" title="Reset filters" onClick={(e) => this.resetFilter(e)} />
                                    </div>

                                    <div className="col-md-3">
                                        <Select
                                            isfilterable
                                            options={activityTypes}
                                            placeholder="Find by activity name"
                                            onChange={(val) => this.handleFilter(val)}
                                        />
                                    </div>
                                    <div className="col-md-2 text-right">
                                        <button className="btn btn-primary btn-block margin-bottom-10px" onClick={this.openAddCoinsPopup}>Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('coins')}>Coins &nbsp;  <i className={`fa fa-sort-alpha-asc`} />
                                        </th>
                                        <th scope="col">Activity</th>
                                        <th scope="col">Activity Value</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>
                                            Created &nbsp;  <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        coinsList.map((Coins, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">{Coins.coins}</span></td>

                                                    <td><span className="cm_table_three_dots">{
                                                        activityTypes.filter(e => e.value == Coins.activityType)[0].label
                                                    }</span></td>

                                                    <td><span className="cm_table_three_dots">
                                                        {Coins.activityValue + " " + activityTypes.filter(e => e.value == Coins.activityType)[0].unit}</span>
                                                    </td>

                                                    <td className="cm_no_wrap">{dateFormat(Coins.created, "d mmmm yyyy")}</td>
                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openEditCoinsPopup(data, Coins)} >Edit</button>
                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteCoinsPopup(data, Coins)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalCoins}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!coinsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {filter ? 'for' : "!"} <span>{filter}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={addCoinsPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Coins</h3>
                        <form onSubmit={this.handleSubmitCoins}>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-8">
                                        <label>Activity Type</label>
                                        <Select
                                            isfilterable
                                            options={activityTypes}
                                            placeholder="Select activity"
                                            onChange={(val) => this.selectActivity(val)}
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label>Activity Unit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={unit}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Activity Value</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="activityValue"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Coins</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="coins"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>

                            {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={editCoinsPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Coins</h3>
                        <form onSubmit={this.editCoinsSubmit}>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-8">
                                        <label>Activity Type</label>
                                        <Select
                                            isDisabled
                                            options={activityTypes}
                                            placeholder="Select activity"
                                            onChange={(val) => this.selectActivity(val)}
                                            value={activityTypes.find(act => act.value === activityType)}
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label>Activity Unit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={unit}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Activity Value</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="activityValue"
                                        onChange={this.handleChange}
                                        value={activityValue}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Coins</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="coins"
                                        onChange={this.handleChange}
                                        value={coins}
                                    />
                                </div>
                            </div>

                            {titleErrorMsg && <h6 className="text-center mb-2 mt-2 error_message">{titleErrorMsg}</h6>}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup>


                <Popup
                    open={deleteCoinsPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4">Are you sure, you want to delete this coins weightage?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteCoins}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </HOC>
        )
    }
}



export default Coins;