import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BackArrow, HeartRate } from '../../../assets/SvgIcon';
import { Progress, Tabs, DatePicker } from 'antd';
import moment from 'moment';
import { heartRateGraph } from '../../../config/services/usersService';
import { toast } from 'react-toastify';
import Chart from "react-apexcharts";


class HeartRateGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedGraphType: 2,
            dateFrom: moment().startOf('month').valueOf(),
            dateTo: moment().endOf('month').valueOf(),
            currentDate: moment().valueOf(),
            userId: this.props.userId,
            graphData: {
                weeklyData: [],
                countData: [],
                count1Data: []
            },
            target: {},
            totalSteps: 0,
            maxValue: 0,
            userDetails: {},
            fatBurnZone: 0,
            cardioZone: 0,
            peakZone: 0,
            age: 0
        }
    }


    componentDidMount() {
        this.getHeartRateGraph()
    }


    getHeartRateGraph = () => {
        let { selectedGraphType, dateFrom, dateTo, userId } = this.state
        let params = {
            dateFrom,
            dateTo,
            requestUserId: userId,
            graphType: selectedGraphType
        }

        heartRateGraph(params)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    let maxValue = Math.max(...response.responseData.graphData.countData)
                    let totalSteps = parseInt(response.responseData.graphData.countData.reduce((a, b) => a + b))

                    this.calculateZones(response.responseData.userDetails);
                    this.setState({
                        graphData: response.responseData.graphData,
                        userDetails: response.responseData.userDetails,
                        maxValue,
                        totalSteps,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    calculateZones = (userDetails) => {
        let { dob } = userDetails
        var age = moment().diff(dob, 'years', false);

        let fatBurnZone = (((220 - age) * 50) / 100).toFixed(0)
        let cardioZone = (((220 - age) * 60) / 100).toFixed(0)
        let peakZone = (((220 - age) * 70) / 100).toFixed(0)

        this.setState({ age, fatBurnZone, cardioZone, peakZone })
    }


    handleDateChange = (date, dateType) => {
        let dateFrom = moment(date).startOf(dateType).valueOf()
        let dateTo = moment(date).endOf(dateType).valueOf()
        let currentDate = moment(date).valueOf()

        this.setState({ dateFrom, dateTo, currentDate }, () => { this.getHeartRateGraph() })
    }


    handleGraphType = (graphType) => {
        let dateFrom
        let dateTo

        switch (parseInt(graphType)) {
            case 0:
                dateFrom = moment().startOf('day').valueOf()
                dateTo = moment().endOf('day').valueOf()
                break;
            case 1:
                dateFrom = moment().startOf('week').valueOf()
                dateTo = moment().endOf('week').valueOf()
                break;
            case 2:
                dateFrom = moment().startOf('month').valueOf()
                dateTo = moment().endOf('month').valueOf()
                break;
        }

        this.setState({ selectedGraphType: parseInt(graphType), dateFrom, dateTo }, () => { this.getHeartRateGraph() })
    }



    render() {
        let { selectedGraphType, graphData, target, currentDate, maxValue, userDetails, fatBurnZone, cardioZone, peakZone } = this.state

        let options = {
            colors: ["#ff0000", "#1890ff"],
            stroke: {
                width: 2
            },
            markers: {
                size: selectedGraphType == 0 ? 0 : 7
            },
            chart: {
                id: "Heart Rate"
            },

            xaxis: {
                categories: graphData.weeklyData,
                labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                },
            },

            annotations: {
                yaxis: [{
                    offsetX: 10,
                    offsetY: 10,
                    y: peakZone,
                    borderColor: 'red',
                    label: {
                        borderColor: 'red',
                        style: {
                            color: 'red',
                            background: 'white',
                        },
                        text: 'Peak Zone',
                    }
                },
                {
                    y: cardioZone,
                    borderColor: 'orange',
                    label: {
                        borderColor: 'orange',
                        style: {
                            color: 'orange',
                            background: 'white',
                        },
                        text: 'Cardio Zone',
                    }
                },
                {
                    y: fatBurnZone,
                    borderColor: 'blue',
                    label: {
                        borderColor: 'blue',
                        style: {
                            color: 'blue',
                            background: 'white',
                        },
                        text: 'Fat Burn Zone',
                    }
                }
                ]
            }

        }

        let series = [
            {
                name: "Max",
                data: graphData.countData
            },
            {
                name: "Min",
                data: graphData.count1Data ? graphData.count1Data : [null]
            }
        ]

        return (
            <>
                <div className="graph-module">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-xl-3">
                            <div className="tab-group flex-column d-flex align-items-baseline justify-content-between">
                            {/* <div className="heading">
                                <h5>Heart Rate</h5>
                            </div> */}
                                <div className="filter-tabs  w-100">
                                    <Tabs defaultActiveKey={selectedGraphType} onChange={this.handleGraphType}>
                                        <Tabs.TabPane
                                            tab={<span>Month</span>}
                                            key={2}
                                        >
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={<span>Week</span>}
                                            key={1}
                                        >
                                        </Tabs.TabPane>

                                        <Tabs.TabPane
                                            tab={<span>Day</span>}
                                            key={0}
                                        >
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                                <div className="tabs-date-picker w-100">
                                    {selectedGraphType == 2 && <DatePicker.MonthPicker allowClear={false} className="form-control" placeholder="Select Month" format='MMM YYYY' onChange={(val) => this.handleDateChange(val, 'month')}
                                        defaultValue={moment(new Date(currentDate), 'MMM YYYY')} />}

                                    {selectedGraphType == 1 && <DatePicker.WeekPicker allowClear={false} className="form-control" placeholder="Select Week" onChange={(val) => this.handleDateChange(val, 'week')}
                                        defaultValue={moment(new Date(currentDate))} />}

                                    {selectedGraphType == 0 && <DatePicker allowClear={false} className="form-control" placeholder="Select date" format='Do MMM YYYY' onChange={(val) => this.handleDateChange(val, 'day')}
                                        defaultValue={moment(new Date(currentDate), 'Do MMM YYYY')} />}
                                </div>
                                <div className="user-intake-detail d-block w-100">
                                    <div className="progress-section text-center">
                                        <div className="postion-rel">
                                            <Progress type="circle" percent={(maxValue / 251) * 100} width={80} />
                                            <span><HeartRate /></span>
                                        </div>
                                        <p>{maxValue ? maxValue + ' /BPM' : 'No Data'}</p>
                                    </div>
                                    <div className="mt-4 divider-light-line mb-2"></div>
                                    
                                    {
                                        selectedGraphType == 0 && 
                                   
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                Fat Burn Zone
                                                    
                                                </td>
                                                <td>
                                                    <p>{fatBurnZone} <small> / min</small></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p>Peak Zone</p></td>
                                                <td>
                                                    <p>
                                                    {peakZone} <small> / min</small>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cardio Zone</td>
                                                <td>{cardioZone} <small> / min</small></td>
                                            </tr>                                    
                                        </tbody>
                                    </table>
                                }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 ml-auto">
                            <div className="text-center">
                                <div className="heading text-left">
                                    <h5>Heart Rate</h5>
                                </div>
                                <div className="graph-wrap">
                                <Chart
                                        options={options}
                                        series={series}
                                        width="1000"
                                        type="line" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}




export default HeartRateGraph;