import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getFitnessPlanTemplate} from '../../config/services/fitnessPlanTemplateService';
import { LoaderSvg } from '../Reusable';
import ExercisePlaceholder from '../../assets/images/ExercisePlaceholder.png';
import HOC from '../../HOC';
const daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


class ViewTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fitnessExercise: [],
            loader: false,
        }
    }

    componentDidMount() {
        this.setState({
            tempelateId: this.props.match.params.id
        }, ()=>{
            this.getFitnessPlanTemplate()
        })
    }

    getFitnessPlanTemplate = () => {
        const { tempelateId }  = this.state
        let paramsObj = {
            params: {
                tempelateId
            }
        }
        this.setState({ loader: true })
        getFitnessPlanTemplate(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({
                        fitnessExercise: res.data.responseData.result.fitnessExerciseObject,
                        templateTitle: res.data.responseData.template.tempelateTitle,
                        loader: false
                    })
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                }
            })
    }

    render() {
        let {  fitnessExercise, loader, templateTitle } = this.state
        return (
            <HOC >
           
                {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
             
                <div className="body-container-wrapper">
                    <div className="body-container">

                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/fitnessPlanTemplate">Fitness Plans</Link></li>
                            <li className="breadcrumb-item">{templateTitle}</li>
                        </ol>
                        <div className="row">
                    {
                        
                        fitnessExercise.map(item => (
                            <div className="col-xl-3 col-lg-4 col-md-6" key={item._id}>
                                <div className="exercise_card">
                                    <img src={item.exerciseImage ? item.exerciseImage : ExercisePlaceholder} alt="" />
                                    <span className="badge badge-info cm_exrsc_cat">{item.exerciseCategory ? item.exerciseCategory.categoryTitle : "-"}</span>
                                    <div className="exercise_content">
                                        <h3>{item.exerciseTitle}</h3>
                                        <p>Repetitions: {item.repetitions}</p>
                                        <p>Sets: {item.sets}</p>
                                        <p>Time: {item.time} minutes</p>

                                    </div>
                                    <div className="exercise_content_dec">
                                        <h3>Description</h3>
                                        <p className="cm_no_wrap cm_youtobe" title="View video" ><a href={item.exerciseLink} target="_blank"><i className="fa fa-lg fa-youtube-play" /></a></p>
                                        <p>{item.description}</p>
                                        {
                                            item.days ? <p className="cm_days">Days: {item.days.map((day, i) => <span key={i}>{daysName[day - 1]} </span>)}</p> : <p className="cm_days">Days: {daysName.map((day, i) => <span key={i}>{day}</span>)}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                       
                    }
                    </div>
                    </div>
                </div>
            </HOC >
        )
    }
}

export default ViewTemplate;