export const calculateBMI = (height, weight) => {
    let bmiCategory;
    const userHeight = parseFloat(height) / 100 //in metres
    const userWeight = parseFloat(weight)
    let BMI = (userWeight / Math.pow(userHeight, 2)).toFixed(2)
    if (BMI <= 15) {
        bmiCategory = 'Very Severely underweight'
    } else if (BMI <= 16) {
        bmiCategory = 'Severely underweight'
    } else if (BMI <= 18.5) {
        bmiCategory = 'Underweight'
    } else if (BMI <= 25) {
        bmiCategory = 'Healthy'
    } else if (BMI <= 30) {
        bmiCategory = 'Overweight'
    } else if (BMI <= 35) {
        bmiCategory = 'Moderately obese'
    } else if (BMI <= 40) {
        bmiCategory = 'Severely obese'
    } else if (BMI > 40) {
        bmiCategory = 'Very severely obese'
    }
    return (BMI + " (" + bmiCategory + ")")
}

export const calculateBMR = (height, weight, dob, gender) => {
    // BMR for men     BMR = 66.5 + ( 13.75 × weight in kg ) + ( 5.003 × height in cm ) – ( 6.755 × age in years )
    // BMR for women  BMR = 655.1 + ( 9.563 × weight in kg ) + ( 1.850 × height in cm ) – ( 4.676 × age in years )
    
    let BMR
    const userHeight = parseFloat(height)
    const userWeight = parseFloat(weight)
    var birthDate = new Date(dob);
    const userAge = new Date().getFullYear() - birthDate.getFullYear();
    
    switch (gender) {
        case 'Male': //For Men
            BMR = ((66.5 + (13.75 * userWeight) + (5.003 * userHeight)) - (6.755 * userAge))
            break;
        case 'Female':  //For women
            BMR = ((655.1 + (9.563 * userWeight) + (1.850 * userHeight)) - (4.676 * userAge))
            break;
        default:
            BMR = ((66.5 + (13.75 * userWeight) + (5.003 * userHeight)) - (6.755 * userAge))
            break;
    }
    return BMR.toFixed(2)
}

export default (height, weight, dob, gender) => {
    // const userHeight = ( height.unit === 1 ) ? height.value :( height.value * 2.54 )
    // const userWeight = ( weight.unit === 1 )?( weight.value ): ( weight.value*0.453592 ) 
    const userHeight = height.value
    const userWeight = weight.value
    return function (type) {
        switch (type.toUpperCase()) {
            case "BMI":
                if (!userHeight || !userWeight) {
                    return 'No Data'
                }
                return calculateBMI(userHeight, userWeight)
            case "BMR":
                if (!userHeight || !userWeight || !dob || !gender) {
                    return 'No Data'
                }
                return calculateBMR(userHeight, userWeight, dob, gender)
            default:
                return 'N/A'
        }
    }
}

