import React from 'react';
import { Route, Redirect } from 'react-router-dom';


let isAllowed = (accessiblePaths, requestedPath) => {
    return accessiblePaths.some(route => route.path === requestedPath)
}

const PrivateRoute = ({ component: Component, accessiblePaths,serviceAccessibleOps, pathValue, ...rest }) => {
    return (
        <Route {...rest} render={props => (!isAllowed(accessiblePaths, pathValue) ? <Redirect to={accessiblePaths[0].path} /> : <Component {...props} serviceAccessibleOps={serviceAccessibleOps}/>)} />
    )
}

export default PrivateRoute;
