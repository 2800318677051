import React from "react";
import ReactExport from "react-export-excel";
import moment from 'moment'
import { Button } from 'react-bootstrap';
import { CallStatuses } from './Constants';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExportExcel extends React.Component {

    parseCallStatus = (status) => {
        let callStatus = CallStatuses.find(o => o.value === status)
        if (callStatus) {
            return callStatus.label
        } else {
            return "-"
        }
    }

    render() {
        let { callSchedules } = this.props
        return (
            <ExcelFile filename={"VideoSessions" + moment().format('Do_MMM_YYYY')} element={<Button variant="outline-info" size="sm">Export To Excel</Button>}>
                <ExcelSheet data={callSchedules} name="SessionSchedules">
                    <ExcelColumn label="User's Name" value={(col) => col.userDetails.firstName + " " + col.userDetails.lastName} />
                    <ExcelColumn label="User's Email" value={(col) => col.userDetails.email} />
                    <ExcelColumn label="Mobile No." value={(col) => col.userDetails.mobileNo} />
                    <ExcelColumn label="Service" value={(col) => col.serviceDetails.serviceName} />
                    <ExcelColumn label="Session type" value={(col) => col.videoSessionType == 1 ? 'Solo' : 'Group'} />
                    <ExcelColumn label="Billing" value={(col) => col.isRedeemedFree ? 'Free' : 'Paid'} />
                    <ExcelColumn label="Billing" value={(col) => col.isRedeemedFree ? 'Free' : 'Paid'} />
                    <ExcelColumn label="Price" value={(col) => col.expertDetails.price} />
                    <ExcelColumn label="Discount" value={(col) => col.expertDetails.discount} />
                    <ExcelColumn label="Final payment" value={(col) => col.expertDetails.price - ((col.expertDetails.discount * col.expertDetails.price) / 100)} />
                    <ExcelColumn label="Expert" value={(col) => col.expertDetails.firstName + " " + col.expertDetails.lastName} />
                    <ExcelColumn label="Call Date" value={(col) => moment(col.callDate).format('Do MMM YYYY')} />
                    <ExcelColumn label="Time Slot" value={(col) => col.callTimeSlot} />
                    <ExcelColumn label="Call Status" value={(col) => this.parseCallStatus(col.status)} />
                    <ExcelColumn label="Invoice #" value={(col) => col.invoiceNo ? col.invoiceNo : 'No Invoice'} />
                    <ExcelColumn label="Report" value={(col) => col.isReported ? col.reportDetails && col.reportDetails.complaint : 'Not Reported'} />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}


export default ExportExcel;