import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { DeviceService } from '../../config/services/DeviceService';
import { LoaderSvg } from '../Reusable'
import Popup from "reactjs-popup"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../loader'
import Select from "react-select";

class Device extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 1,
      activePage: 0,
      devices: [],
      Loader: true,
      search: '',
      open: false,
      openAddDevicePopup: false,
      openDropdown: false,
      name: '',
      uniqueId: '',
      type: null,
      typeIndex: null,
      errors: false,
      validateError: false,
      deviceObj: '',
      sortField: '',
      sortFieldIcon: false,
      sortType: 2,
      _device: null,
      totalDeviceCount: 0,
      deviceImagePreview: '',
      newDeviceImagePreview: '',
      deviceImage: '',
      newDeviceImage: '',
      active: true,
      category: 0,
      platform: [],
      types: ['Application', 'Treadmill', 'Manual Machine', 'Fitness Trackers', 'Smartscale', "Mannual data", 'Voice Control', 'Bikes', 'Outdoor Cycle', 'Outdoor Run'],
      apiCalled: false,
      validationError: '',
      platformType : [
          {label: 'IOS', value: 1},
          {label: 'ANDROID', value: 2}
        ],
      statusType: [
        {label: 'True', value: true},
        {label: 'False', value: false}
      ],
      categoryType:[
        {label: "None", value: 0},
        {label: "INTEGRATE WITH APP", value: 3}
      ]
    }

    this.showDeviceList = this.showDeviceList.bind(this);
    this.handleDeviceSearch = this.handleDeviceSearch.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openDeviceModal = this.openDeviceModal.bind(this);
    this.closeDeviceModal = this.closeDeviceModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidetion = this.handleValidetion.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSortList = this.handleSortList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {

    this.setState({ pageNo: pageNumber, Loader: true, search: '' }, () => {
      this.showDeviceList();
    });
  }


  openModal(obj) {
    this.setState({
      open: true,
      deviceObj: obj._id,
    })
  }



  closeModal() {
    this.setState({
      open: false,
      device_Id: '',
      deviceImage: '',
      newDeviceImage: '',
      deviceImagePreview: '',
      newDeviceImagePreview: '',
    })
  }



  openDeviceModal() {
    this.setState({ openAddDevicePopup: true })
  }


  closeDeviceModal() {
    this.setState({ openAddDevicePopup: false, deviceImagePreview: '', deviceImage: '' })
  }

  showDeviceList() {
    let { pageNo, search, sortField, sortType } = this.state
    let params = {
      pageNo: pageNo - 1, search, sortField, sortType
    }

    DeviceService.getDeviceList(params)
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({
            devices: res.data.responseData.device,
            Loader: false,
            apiCalled: false,
          })
        } else if (res.data.statusCode == 0) {
          this.setState({ apiCalled: false, Loader: false })
          toast.error(res.data.error.errorMessage)
        }
      })
  }



  handleDeviceSearch(e) {
    this.setState({ search: e.target.value, apiCalled: true }, () => {
      this.showDeviceList();
    });
  }



  componentDidMount() {

    let token = localStorage.getItem('accessToken');

    if (token) {
      this.showDeviceList();
    }
    DeviceService.getTotalDeviceCount()
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({ totalDeviceCount: res.data.responseData.deviceCount })
        } else if (res.data.statusCode == 0) {
          toast.error(res.data.error.errorMessage)
        }
      })
  }



  handleOpenDropdown = () => {
    this.setState({ openDropdown: !this.state.openDropdown })
  }



  getDeiveType = (val) => {
    this.setState({
      type: val,
      typeIndex: val,
      openDropdown: !this.state.openDropdown
    })
  }



  handleChange(e) {
    let { name, files } = e.target

    if (name == 'deviceImage') {
      let file = files[0];
      let reader = new FileReader();
      reader.onloadend = () => { this.setState({ deviceImagePreview: reader.result }) }
      reader.readAsDataURL(file)
      this.setState({ deviceImagePreview: file, deviceImage: e.target.files[0], validationError: '' })
    }
    else {
      this.setState({ [e.target.name]: e.target.value, validationError: '', errors: false })
    }

    if (name == 'newDeviceImage'){
      let file = files[0];
      let reader = new FileReader();
      reader.onloadend = () => { this.setState({ newDeviceImagePreview: reader.result }) }
      reader.readAsDataURL(file)
      this.setState({ newDeviceImagePreview: file, newDeviceImage: e.target.files[0], validationError: '' })
    }
  }

  handleValidetion() {
    let validate = true;
    let { name, uniqueId, validationError, type } = this.state

    if (name === '' || name === undefined) {
      validate = false;
      validationError = 'Device name cannot be empty.'
    }
    else if (uniqueId === '' || uniqueId === undefined) {
      validate = false;
      validationError = 'Unique Id cannot be empty'
    }
    else if (!type && type !== 0) {
      validate = false;
      validationError = 'Device Type cannot be empty'
    }
    else {
      validate = true;
      validationError = ''
    }

    this.setState({ validationError })
    return validate
  }



  handleSubmit(e) {
    e.preventDefault();
    this.setState({ errors: true })

    let { name, uniqueId, typeIndex, deviceImage, newDeviceImage, active, category, platform } = this.state;
    // let data = `name=${name}&uniqueId=${uniqueId}&type=${typeIndex}`;

    let data = new FormData();
    data.append('name', name);
    data.append('uniqueId', uniqueId);
    data.append('type', typeIndex);
    data.append('deviceImage', deviceImage);
    data.append('newDeviceImage', newDeviceImage);
    data.append('active', active);
    data.append('category', category);
    data.append('platform', JSON.stringify(platform));

    if (this.handleValidetion()) {
      this.setState({ Loader: true })
      DeviceService.addDevice(data)
        .then(res => {
          if (res.data.statusCode === 1) {
            this.setState({ errors: false, validateError: '', openAddDevicePopup: false, name: '', uniqueId: '', type: null, Loader: false })
            this.showDeviceList();
          }
          else if (res.data.statusCode == 0) {
            this.setState({ validationError: res.data.error.errorMessage, Loader: false })
          }
        })
    }
    else {
      this.setState({ validateError: true, errors: false })
    }
  }

  handleDelete() {
    let data = `deviceId=${this.state.deviceObj}`;
    DeviceService.DeleteDevice(data)
      .then(res => {
        if (res.data.statusCode === 1) {
          this.setState({
            open: false,
            deviceObj: ''
          })
          this.showDeviceList();
        }
      })
  }


  handleSortList(sortField) {
    const { sortType } = this.state;

    let sortOrder = sortType === 1 ? 2 : 1;

    this.setState({ sortField: sortField, Loader: true, sortType: sortOrder }, () => {
      this.showDeviceList();
      this.setState({
        sortFieldIcon: !this.state.sortFieldIcon
      })
    });
  }

  handleSelectChange = (key,data) => {
    this.setState({ [key]: data.value })
  }

  handleMultiSelect = (key, val) => {
    if (val && val.length){
      let platformArray = val.map(function (obj) {
        return obj.value;
      });
      this.setState({ platform: platformArray ? platformArray : [] })
    }else{
      this.setState({ platform: [] })
    }

  }

  getPlatformName(data){
    let filteredPlatform = this.state.platformType.filter(({value}) => data.includes(value))
    let platformName = filteredPlatform.map((platform) =>{
      return platform.label
    })
    return platformName.toString()
  }
  getCategoryName(category){
    let filteredCategory = this.state.categoryType.filter(({value}) => value == category)
    return filteredCategory ? filteredCategory[0].label : 'None'
  }

  render() {
    let { types, apiCalled, validationError, validateError } = this.state
    return (
      <HOC>
        {apiCalled && <Loader />}
        <div className="body-container-wrapper">
          <div className="body-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
              <li className="breadcrumb-item">Device</li>
            </ol>

            {
              this.state.Loader
                ? <div className="loader_wrapper"><LoaderSvg /></div>
                : null
            }

            <div className="users_header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="cm_page_heading">Devices</h4>
                  </div>
                  <div className="col-md-3 text-right cm_search">
                    <input
                      type="text"
                      className="form-control m-b-md-15"
                      placeholder="Search"
                      onChange={this.handleDeviceSearch}
                      value={this.state.search}
                    />
                    <i className="fa fa-search"></i>
                  </div>

                  <div className="col-md-3  m-b-md-15">
                    <button onClick={this.openDeviceModal} className="btn btn-primary btn-block">Add Device</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped text-center">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col" className="filter_text">Name

                     <i className={`fa fa-sort-alpha-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList('name')}></button>

                    </th>
                    <th scope="col">Device Type</th>
                    <th>Device Unique Id</th>
                    <th>Active</th>
                    <th>Category</th>
                    <th>Platform</th>
                    <th scope="col" className="filter_text">Created Date <i className={`fa fa-sort-numeric-${this.state.sortFieldIcon ? 'desc' : 'asc'}`}></i><button onClick={() => this.handleSortList('created')}></button></th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>


                <tbody>
                  {
                    this.state.devices ? (
                      this.state.devices.map((device, index) => {
                        return (
                          <tr key={device._id}>
                            <td>{((index + 1) + ((this.state.pageNo - 1) * 20))}</td>
                            <td>{device.name}</td>
                            <td>
                              {types[device.type] ? types[device.type] : '---------'}</td>
                            <td>{device.uniqueId}</td>
                            <td>{device.active == false? 'false' : 'true'}</td>
                            <td>{device.category ? this.getCategoryName(device.category) : 'None'}</td>
                            <td>{(device.platform && device.platform.length) ? this.getPlatformName(device.platform): '--'}</td>
                            <td>{dateFormat(device.created, "d mmmm yyyy")}</td>
                            <td>{device.type == 5 ? <div>Not Editable</div> : <span className="cm_btn_sm" onClick={() => this.openModal(device)}><i className="fa fa-pencil-square" ></i></span>}</td>
                          </tr>
                        )
                      })
                    ) : (
                        <tr>
                          <td colSpan="8">{this.state.error}</td>
                        </tr>
                      )
                  }
                </tbody>


              </table>
              {this.state.devices.length === 0 ? <h3 className="empty_error">Sorry, we couldn't find any content for <span>{this.state.search}</span></h3> : null}
              <Pagination
                activePage={this.state.pageNo}
                itemsCountPerPage={20}
                totalItemsCount={this.state.totalDeviceCount}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            </div>


          </div>
        </div>

        <Popup
          open={this.state.open}
          closeOnDocumentClick={false}
          onClose={this.closeModal}
        >
          <div className="cm_modal">
            <span className="cm_modal_close" onClick={this.closeModal}>
              &times;
            </span>
            <h3 className="text-center mb-4">Device Action</h3>
            <div className="btn_group">
              <div className="row">
                <div className="col"><Link to={'/edit-device/' + this.state.deviceObj} className="btn btn-warning btn-sm btn-block">Edit</Link></div>
                <div className="col"><button className="btn btn-danger btn-sm btn-block" onClick={this.handleDelete}>Delete</button></div>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
          open={this.state.openAddDevicePopup}
          closeOnDocumentClick={false}
          onClose={this.closeDeviceModal}
        >
          <div className="cm_modal pl-5 pr-5">
            <span className="cm_modal_close" onClick={this.closeDeviceModal}>
              &times;
            </span>
            <h3 className="text-center mb-4 mt-2">Add New Device</h3>
            <form onSubmit={this.handleSubmit} className="mb-2">

              <div className="form-group">
                <label>Name of device</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="name"
                />
              </div>

              <div className="form-group">
                <label>Unique connection id of device</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  name="uniqueId"
                />
              </div>

              <div className={this.state.openDropdown ? 'form-group cm_dropdown open' : 'form-group cm_dropdown'}>
                <label>Type of devices</label>
                <span className="form-control cm_blank_input" onClick={this.handleOpenDropdown}>{types[this.state.type]}</span>
                <input type="hidden" value={this.state.devicesType} name="type" />
                <ul>
                  <li onClick={() => this.getDeiveType(0)}>
                    <h4>Application</h4>
                    <p>Google fit, Samsung Health, Fitbit, Apple Health</p>
                  </li>
                  <li onClick={() => this.getDeiveType(1)}>
                    <h4>Treadmill</h4>
                    <p>RPM Fitness, Fitkit, ES Linker</p>
                  </li>
                  <li onClick={() => this.getDeiveType(2)}>
                    <h4>Manual Machine</h4>
                    <p>RPM Fitness</p>
                  </li>
                  <li onClick={() => this.getDeiveType(3)}>
                    <h4>Fitness Trackers</h4>
                    <p>Mi Band</p>
                  </li>
                  <li onClick={() => this.getDeiveType(4)}>
                    <h4>Smartscale</h4>
                    <p>F+</p>
                  </li>
                  <li onClick={() => this.getDeiveType(6)}>
                    <h4>Voice Controls</h4>
                    <p>Alexa , Google home</p>
                  </li>
                  <li onClick={() => this.getDeiveType(7)}>
                    <h4>Bikes</h4>
                    <p>Spin Bike , Mannual Bike</p>
                  </li>
                  <li onClick={() => this.getDeiveType(8)}>
                    <h4>OutDoor Cycle</h4>
                    <p>Urban Terrain</p>
                  </li>
                  <li onClick={() => this.getDeiveType(9)}>
                    <h4>OutDoor Run</h4>
                    <p>Urban Terrain</p>
                  </li>
                  <li onClick={() => this.getDeiveType(10)}>
                    <h4>Cross Trainer</h4>
                    <p>Elliptical</p>
                  </li>
                </ul>
              </div>


              <div className="form-group mt-2 mb-1">
                <label className="d-block">Upload Device Image</label>
                <div className="radio_wrapper text-center">
                  <input className='upload_btn-spacing' type="file" onChange={this.handleChange} id="deviceImage" name="deviceImage" />
                  <img width="400px" src={this.state.deviceImagePreview} />
                </div>
              </div>

              <div className="form-group mt-2 mb-1">
                <label className="d-block">Upload New Device Image</label>
                <div className="radio_wrapper text-center">
                  <input className='upload_btn-spacing' type="file" onChange={this.handleChange} id="newDeviceImage" name="newDeviceImage" />
                  <img width="400px" src={this.state.newDeviceImagePreview} />
                </div>
              </div>

              <div className="form-group">
                <label>Status</label>
                <Select
                    name="category"
                    placeholder="Select Category"
                    options={this.state.categoryType}
                    onChange={(val) => this.handleSelectChange('category', val)}
                />
              </div>

              <div className="form-group">
                <label>Status</label>
                <Select
                    name="active"
                    placeholder="Select Status"
                    options={this.state.statusType}
                    onChange={(val) => this.handleSelectChange('active', val)}
                />
              </div>

              <div className="form-group">
                <label>Platform</label>
                <Select
                    name="platform"
                    placeholder="Select Platform"
                    options={this.state.platformType}
                    // value={this.state.platform}
                    isMulti
                    onChange={(val) => this.handleMultiSelect('platform', val)}
                />
              </div>

              <button className="btn btn-primary btn-block mt-4">Save</button>
              {validationError ? <span className="error">{validationError ? validationError : 'Error'}</span> : null}
            </form>
          </div>
        </Popup>
      </HOC>
    );
  }
}

export default Device;