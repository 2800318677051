import React, { Component } from 'react';
import { BackArrow, Mood, Moon, Footspeed, Footstep } from '../../../assets/SvgIcon';
import { Progress, Tabs, DatePicker } from 'antd';
import Chart from "react-apexcharts";
import moment from 'moment';
import { sleepGraph } from '../../../config/services/usersService';
import { toast } from 'react-toastify';

class SleepGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedGraphType: 2,
            dateFrom: moment().startOf('month').valueOf(),
            dateTo: moment().endOf('month').valueOf(),
            currentDate: moment().valueOf(),
            userId: this.props.userId,
            graphData: [],
            lightSleep: 0,
            deepSleep: 0,
            awakeHours: 0,
            totalSleep: 0,
        }
    }

    componentDidMount() {
        this.getSleepGraph()
    }


    getSleepGraph = () => {
        let { selectedGraphType, dateFrom, dateTo, userId } = this.state
        let params = {
            dateFrom,
            dateTo,
            requestUserId: userId,
            graphType: selectedGraphType
        }

        sleepGraph(params)
            .then(res => {
                let response = res.data

                let graphData = []
                let lightSleep = 0
                let deepSleep = 0
                let awakeHours = 0
                let totalSleep = 0

                if (response.statusCode == 1) {
                    response.responseData.weeklyData.map(sleep => {
                        let x = sleep._id
                        let sleepFrom = sleep.sleepFrom ? moment.utc(sleep.sleepFrom).local().format('HH') : 0
                        let sleepTo = sleep.sleepTo ? moment.utc(sleep.sleepTo).local().format('HH') : 0
                        let y = [sleepTo, sleepTo, sleepFrom, sleepFrom]

                        lightSleep = lightSleep + (sleep.lightSleep ? sleep.lightSleep : 0)
                        deepSleep = deepSleep + (sleep.deepSleep ? sleep.deepSleep : 0)
                        awakeHours = awakeHours + (sleep.awakeHours ? sleep.awakeHours : 0)
                        totalSleep = totalSleep + (sleep.sleepDataCount ? sleep.sleepDataCount : 0)

                        graphData.push({ x, y })
                    })

                    this.setState({ graphData, lightSleep, deepSleep, awakeHours, totalSleep })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    handleDateChange = (date, dateType) => {
        let dateFrom = moment(date).startOf(dateType).valueOf()
        let dateTo = moment(date).endOf(dateType).valueOf()
        let currentDate = moment(date).valueOf()

        this.setState({ dateFrom, dateTo, currentDate }, () => { this.getSleepGraph() })
    }


    handleGraphType = (graphType) => {
        let dateFrom
        let dateTo

        switch (parseInt(graphType)) {
            case 0:
                dateFrom = moment().startOf('day').valueOf()
                dateTo = moment().endOf('day').valueOf()
                break;
            case 1:
                dateFrom = moment().startOf('week').valueOf()
                dateTo = moment().endOf('week').valueOf()
                break;
            case 2:
                dateFrom = moment().startOf('month').valueOf()
                dateTo = moment().endOf('month').valueOf()
                break;
        }

        this.setState({ selectedGraphType: parseInt(graphType), dateFrom, dateTo }, () => { this.getSleepGraph() })
    }

    secondsToHHMM = (dateInSeconds) => {
        var sec_num = parseInt(dateInSeconds, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }

        return hours + '/Hr ' + minutes + '/Min';
    }




    render() {
        let { selectedGraphType, graphData, target, currentDate, lightSleep, deepSleep, awakeHours, totalSleep } = this.state
        let options = {
            colors: ["#1BC8D0"],

            // candlestick: {
            //     colors: {
            //         upward: '#00B746',
            //         downward: '#EF403C'
            //     },
            //     wick: {
            //         useFillColor: true
            //     }
            // },

            chart: {
                id: "basic-bar"
            },
            xaxis: {
                labels: {
                    show: selectedGraphType === 0 ? false : true,
                }
            }
        }

        let series = [{
            data: graphData
        }]

        return (
            <>
                <div className="graph-module">
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-xl-3">
                        {/* <div className="heading">
                            <h5>Steps Taken</h5>
                        </div> */}
                            <div className="tab-group flex-column d-flex align-items-baseline justify-content-between">
                                <div className="filter-tabs  w-100">
                                    <Tabs defaultActiveKey={selectedGraphType} onChange={this.handleGraphType}>
                                        <Tabs.TabPane
                                            tab={<span>Month</span>}
                                            key={2}
                                        >
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={<span>Week</span>}
                                            key={1}
                                        >
                                        </Tabs.TabPane>

                                        <Tabs.TabPane
                                            tab={<span>Day</span>}
                                            key={0}
                                        >
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                                <div className="user-intake-detail d-block w-100">
                                    
                                    <div className="tabs-date-picker w-100">
                                        {selectedGraphType == 2 && <DatePicker.MonthPicker allowClear={false} className="form-control" placeholder="Select Month" format='MMM YYYY' onChange={(val) => this.handleDateChange(val, 'month')}
                                            defaultValue={moment(new Date(currentDate), 'MMM YYYY')} />}

                                        {selectedGraphType == 1 && <DatePicker.WeekPicker allowClear={false} className="form-control" placeholder="Select Week" onChange={(val) => this.handleDateChange(val, 'week')}
                                            defaultValue={moment(new Date(currentDate))} />}

                                        {selectedGraphType == 0 && <DatePicker allowClear={false} className="form-control" placeholder="Select date" format='Do MMM YYYY' onChange={(val) => this.handleDateChange(val, 'day')}
                                            defaultValue={moment(new Date(currentDate), 'Do MMM YYYY')} />}
                                    </div>
                                    {/* <div className="mt-3 divider-light-line mb-2"></div> */}
                                    <div className="stepinfo mb-4 sleep-info mx-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <table className="table table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td>Sleep Time</td>
                                                        <td>{this.secondsToHHMM(totalSleep)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Target Reached</td>
                                                        <td>{((totalSleep / 28800) * 100).toFixed()}%</td>
                                                    </tr>                                        
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="sleep-progress">
                                            <Progress
                                                strokeColor={{
                                                    [(totalSleep / 28800) * 100]: '#FF670F'
                                                }}
                                                percent={(totalSleep / 28800) * 100}
                                            />
                                        </div>
                                    </div>
                                    {selectedGraphType === 0 && <div className="calorie-info sleep_info mb-4">
                                        <div className="mt-4 divider-light-line mb-2"></div>
                                        <div className="d-flex align-items-center">
                                            <span className="awake"><Moon /></span>
                                            <p>{this.secondsToHHMM(awakeHours)}  <small>/Awake Hours</small></p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <span className="light"><Moon /></span>
                                            <p>{this.secondsToHHMM(lightSleep)}  <small>/Light Sleep</small></p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <span className="deep"><Moon /></span>
                                            <p>{this.secondsToHHMM(deepSleep)}  <small>/Deep Sleep</small></p>
                                        </div>
                                    </div>}
                                    <div className="progress-section text-center">
                                        <div className="postion-rel">
                                            <Progress type="circle" percent={(totalSleep / 28800) * 100} width={80} />
                                            <span><Moon /></span>
                                        </div>
                                        <p>{this.secondsToHHMM(totalSleep)}</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-8 ml-auto">
                            <div className="text-center">
                                <div className="heading text-left">
                                    <h5>Sleep Activity</h5>
                                </div>
                                <div className="graph-wrap">
                                    <Chart
                                        options={options}
                                        series={series}
                                        type="candlestick"
                                    />       
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}




export default SleepGraph;