import React, { Component } from 'react';
import { getDietPlanTempelate } from '../../config/services/dietTempelateService';
import { getAllFoods } from '../../config/services/FoodService';

import { toast } from 'react-toastify';
import { LoaderSvg } from '../Reusable';
import FoodPlaceholder from '../../assets/images/FoodPlaceholder.jpg';
import HOC from '../../HOC';
import { Link } from 'react-router-dom';
import EditTemplate from './EditTemplate';

const CardWrapper = props => <div className="col mb-5">{props.children}</div>;


class ViewTempelate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templateId: this.props.match.params.id,
            dietTemplateLabels: [],
            morningSnacksList: [],
            breakfastList: [],
            lunchList: [],
            dinnerList: [],
            midMorningDietList: [],
            midEveningDietList: [],
            bedTimeDietList: [],
            loader:false,
            templateTitle:'',
            editTemplatePopup:false,
            foodItems:[],
        }
    }

    componentDidMount() {
        this.getDietPlanTemplate();
        this.getAllFoodItems();
    }



    getAllFoodItems = () => {
        let self = this;
        getAllFoods()
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    var foodItems = [];
                    response.responseData.result.map((food, index) => {
                        foodItems.push({
                            label: food.foodName + " (" + food.calorieContent + " cal)",
                            value: food._id,
                            calorie: food.calorieContent,
                            protein: food.proteinContent,
                            fat: food.fatContent,
                            carb: food.carbContent,
                            fibre: food.fibreContent,
                            foodUnit: food.foodUnit
                        });
                    });
                    self.setState({ foodItems })
                } else if (response.statusCode == 0) {
                    console.log(res,"Getting Food items");
                }
            })
    }


    getDietPlanTemplate = (e) => {
        const { templateId } = this.state;
        let paramsObj = {
            params: {
                templateId
            }
        }
        this.setState({ loader: true })
        getDietPlanTempelate(paramsObj)
            .then(res => {
                let response = res.data                

                if (response.statusCode == 1) {
                    const { morningSnacksDietTime,
                        breakfastDietTime,
                        midMorningDietTime,
                        lunchDietTime,
                        midEveningDietTime,
                        dinnerDietTime,
                        bedTimeDietTime,
                        perDayCalories,
                        recommendedMorningSnacksCalories,
                        recommendedBreakfastCalories,
                        recommendedMidMorningCalories,
                        recommendedLunchCalories,
                        recommendedMidEveningCalories,
                        recommendedDinnerCalories,
                        recommendedBedTimeCalories,
                        morningSnacksOptions,
                        breakfastOptions,
                        midMorningOptions,
                        lunchOptions,
                        midEveningOptions,
                        dinnerOptions,
                        bedTimeOptions,
                        templateTitle
                    } = response.responseData.result;

                    this.setState({
                        morningSnacksDietTime,
                        breakfastDietTime,
                        midMorningDietTime,
                        lunchDietTime,
                        midEveningDietTime,
                        dinnerDietTime,
                        bedTimeDietTime,
                        perDayCalories,
                        recommendedMorningSnacksCalories,
                        recommendedBreakfastCalories,
                        recommendedMidMorningCalories,
                        recommendedLunchCalories,
                        recommendedMidEveningCalories,
                        recommendedDinnerCalories,
                        recommendedBedTimeCalories,
                        morningSnacksList: morningSnacksOptions,
                        breakfastList: breakfastOptions,
                        midMorningDietList: midMorningOptions,
                        lunchList: lunchOptions,
                        midEveningDietList: midEveningOptions,
                        dinnerList: dinnerOptions,
                        bedTimeDietList: bedTimeOptions,
                        templateTitle,
                        loader: false
                    })

                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })

    }

    editTemplate=()=>{
        this.setState({editTemplatePopup:true})
    }

    handleClose=()=>{
        this.setState({editTemplatePopup:false})
    }

    render() {
        const {
            morningSnacksList,
            breakfastList,
            midMorningDietList,
            lunchList,
            midEveningDietList,
            dinnerList,
            bedTimeDietList,
            morningSnacksDietTime,
            breakfastDietTime,
            midMorningDietTime,
            lunchDietTime,
            midEveningDietTime,
            dinnerDietTime,
            bedTimeDietTime,
            recommendedMorningSnacksCalories,
            recommendedBreakfastCalories,
            recommendedMidMorningCalories,
            recommendedLunchCalories,
            recommendedMidEveningCalories,
            recommendedDinnerCalories,
            recommendedBedTimeCalories,
            loader,
            templateTitle,
            perDayCalories,
            editTemplatePopup,
        } = this.state
        return (
            <HOC >
                     {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                     
                {!morningSnacksList.length && !breakfastList.length && !lunchList.length && !dinnerList.length && !midMorningDietList.length && !midEveningDietList.length && !bedTimeDietList.length ? null :
                    <div className="body-container-wrapper">
                    <div className="body-container">

                    <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dietPlanTempelate">Diet Plans</Link></li>
                            <li className="breadcrumb-item">{templateTitle}</li>
                        </ol>


                        <div className="col mb-4">
                                   <h6 className="cm_pos_realtive">Total calorie target: {perDayCalories}/day
                                   <button className="btn btn-primary btn-sm __btn_abs" onClick={this.editTemplate}>
                                   Edit</button>
                                   </h6>
                                   
                                </div>



                        <CardWrapper>
                            <div className="mb-4 cm_patti">
                                <h3>Pre-Breakfast</h3>
                                <h6>Calorie target- {recommendedMorningSnacksCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {morningSnacksDietTime}</h6>
                            </div>

                            {!morningSnacksList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {morningSnacksList.map((options, index) => (
                                        <React.Fragment>
                                            <div className="col-sm-12 " key={index} >
                                                <p className="cm_likesCount_wrap"> Option {index + 1} &nbsp;&nbsp;&nbsp; Calorie target- {options.morningSnacksDietCalorie ? options.morningSnacksDietCalorie : ""} cal

                                                 <div className="cm_likesCount">
                                                    <i class="fa fa-thumbs-o-up"> {options.likesCount}</i>
                                                        &nbsp;&nbsp;&nbsp;
                                                    <i class="fa fa-thumbs-o-down"> {options.disLikesCount}</i>
                                                 </div>
                                                

                                                </p>


                                                <div className="row">
                                                    {options.morningSnacksDiet.map((foodItem, i) => (
                                                        <div className="p-3" key={i} >
                                                            <div className="about_food">
                                                                <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                                <div className="food_content_bg">
                                                                    <h4>{foodItem.foodName}</h4>
                                                                    <div className="row">
                                                                        <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                        <div className="col"> <h6>Qty. {foodItem.quantity}.0</h6></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>}
                        </CardWrapper>

                        <CardWrapper>

                            <div className="mb-4 cm_patti">
                            <h3>Breakfast</h3>
                            <h6>Calorie target- {recommendedBreakfastCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {breakfastDietTime}</h6>
                            </div>

                            {!breakfastList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {breakfastList.map((options, index) => (
                                        <React.Fragment>
                                            <div className="col-sm-12" key={index} >
                                                <p className="cm_likesCount_wrap"> Option {index + 1} &nbsp;&nbsp;&nbsp; Calorie target- {options.breakfastDietCalorie ? options.breakfastDietCalorie : ""} cal
                                                
                                                <div className="cm_likesCount">
                                                    <i class="fa fa-thumbs-o-up"> {options.likesCount}</i>
                                                        &nbsp;&nbsp;&nbsp;
                                                    <i class="fa fa-thumbs-o-down"> {options.disLikesCount}</i>
                                                 </div>
                                                </p>

                                                <div className="row">
                                                    {options.breakfastDiet.map((foodItem, i) => (
                                                        <div className="p-3" key={i} >
                                                            <div className="about_food">
                                                                <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                                <div className="food_content_bg">
                                                                    <h4>{foodItem.foodName}</h4>
                                                                    <div className="row">
                                                                        <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                        <div className="col"> <h6>Qty. {foodItem.quantity}.0</h6></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>}
                        </CardWrapper>

                        <CardWrapper>

                        <div className="mb-4 cm_patti">
                            <h3>Mid Morning</h3>
                            <h6>Calorie target- {recommendedMidMorningCalories} cal&nbsp;&nbsp;&nbsp;  Timing - {midMorningDietTime}</h6>
                             </div>

                            {!midMorningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {midMorningDietList.map((options, index) => (
                                        <React.Fragment>
                                            <div className="col-sm-12" key={index} >
                                                <p className="cm_likesCount_wrap"> Option {index + 1} &nbsp;&nbsp;&nbsp; Calorie target- {options.midMorningDietCalorie ? options.midMorningDietCalorie : ""} cal
                                                
                                                <div className="cm_likesCount">
                                                    <i class="fa fa-thumbs-o-up"> {options.likesCount}</i>
                                                        &nbsp;&nbsp;&nbsp;
                                                    <i class="fa fa-thumbs-o-down"> {options.disLikesCount}</i>
                                                 </div>
                                                </p>

                                                <div className="row">
                                                    {options.midMorningDiet.map((foodItem, i) => (
                                                        <div className="p-3" key={i} >
                                                            <div className="about_food">
                                                                <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                                <div className="food_content_bg">
                                                                    <h4>{foodItem.foodName}</h4>
                                                                    <div className="row">
                                                                        <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                        <div className="col"> <h6>Qty. {foodItem.quantity}.0</h6></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>}
                        </CardWrapper>

                        <CardWrapper>

                     <div className="mb-4 cm_patti">
                            <h3>Lunch</h3>
                            <h6>Calorie target- {recommendedLunchCalories} cal &nbsp;&nbsp;&nbsp;  Timing - {lunchDietTime}</h6>
                          </div>

                            {!lunchList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {lunchList.map((options, index) => (
                                        <React.Fragment>
                                            <div className="col-sm-12" key={index} >
                                                <p className="cm_likesCount_wrap"> Option {index + 1} &nbsp;&nbsp;&nbsp; Calorie target- {options.lunchDietCalorie ? options.lunchDietCalorie : ""} cal
                                                
                                                <div className="cm_likesCount">
                                                    <i class="fa fa-thumbs-o-up"> {options.likesCount}</i>
                                                        &nbsp;&nbsp;&nbsp;
                                                    <i class="fa fa-thumbs-o-down"> {options.disLikesCount}</i>
                                                 </div>
                                                </p>

                                                <div className="row">
                                                    {options.lunchDiet.map((foodItem, i) => (
                                                        <div className="p-3" key={i} >
                                                            <div className="about_food">
                                                                <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                                <div className="food_content_bg">
                                                                    <h4>{foodItem.foodName}</h4>
                                                                    <div className="row">
                                                                        <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                        <div className="col"> <h6>Qty. {foodItem.quantity}.0</h6></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>}
                        </CardWrapper>

                        <CardWrapper>

                            <div className="mb-4 cm_patti">

                            <h3>Mid Evening</h3>
                            <h6>Calorie target- {recommendedMidEveningCalories} cal &nbsp;&nbsp;&nbsp;  Timing - {midEveningDietTime}</h6>
                                 </div>

                            {!midEveningDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {midEveningDietList.map((options, index) => (
                                        <React.Fragment>
                                            <div className="col-sm-12" key={index} >
                                                <p className="cm_likesCount_wrap"> Option {index + 1} &nbsp;&nbsp;&nbsp; Calorie target- {options.midEveningDietCalorie ? options.midEveningDietCalorie : ""} cal
                                                
                                                <div className="cm_likesCount">
                                                    <i class="fa fa-thumbs-o-up"> {options.likesCount}</i>
                                                        &nbsp;&nbsp;&nbsp;
                                                    <i class="fa fa-thumbs-o-down"> {options.disLikesCount}</i>
                                                 </div>
                                                </p>

                                                <div className="row">
                                                    {options.midEveningDiet.map((foodItem, i) => (
                                                        <div className="p-3" key={i} >
                                                            <div className="about_food">
                                                                <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                                <div className="food_content_bg">
                                                                    <h4>{foodItem.foodName}</h4>
                                                                    <div className="row">
                                                                        <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                        <div className="col"> <h6>Qty. {foodItem.quantity}.0</h6></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>))}
                                </div>}
                        </CardWrapper>


                        <CardWrapper>
                      <div className="mb-4 cm_patti">

                            <h3>Dinner</h3>
                            <h6>Calorie target- {recommendedDinnerCalories} cal &nbsp;&nbsp;&nbsp;  Timing - {dinnerDietTime}</h6>
                         </div>

                            {!dinnerList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {dinnerList.map((options, index) => (
                                        <React.Fragment>
                                            <div className="col-sm-12" key={index} >
                                                <p className="cm_likesCount_wrap"> Option {index + 1} &nbsp;&nbsp;&nbsp; Calorie target- {options.dinnerDietCalorie ? options.dinnerDietCalorie : ""} cal
                                                
                                                <div className="cm_likesCount">
                                                    <i class="fa fa-thumbs-o-up"> {options.likesCount}</i>
                                                        &nbsp;&nbsp;&nbsp;
                                                    <i class="fa fa-thumbs-o-down"> {options.disLikesCount}</i>
                                                 </div> 
                                                </p>

                                                <div className="row">
                                                    {options.dinnerDiet.map((foodItem, i) => (
                                                        <div className="p-3" key={i} >
                                                            <div className="about_food">
                                                                <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                                <div className="food_content_bg">
                                                                    <h4>{foodItem.foodName}</h4>
                                                                    <div className="row">
                                                                        <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                        <div className="col"> <h6>Qty. {foodItem.quantity}.0</h6></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>))}
                                </div>
                            }
                        </CardWrapper>

                        <CardWrapper>
                         <div className="mb-4 cm_patti">
                            <h3>Bed Time</h3>
                            <h6>Calorie target- {recommendedBedTimeCalories} cal &nbsp;&nbsp;&nbsp; 
                             Timing - {bedTimeDietTime}</h6>
                         </div>

                            {!bedTimeDietList.length ? <p className="text-danger">No food allotted</p> :
                                <div className="row">
                                    {bedTimeDietList.map((options, index) => (
                                        <React.Fragment>
                                            <div className="col-sm-12" key={index} >
                                                <p className="cm_likesCount_wrap"> Option {index + 1} &nbsp;&nbsp;&nbsp; Calorie target- {options.bedTimeDietCalorie ? options.bedTimeDietCalorie : ""} cal
                                                
                                                <div className="cm_likesCount">
                                                    <i class="fa fa-thumbs-o-up"> {options.likesCount}</i>
                                                        &nbsp;&nbsp;&nbsp;
                                                    <i class="fa fa-thumbs-o-down"> {options.disLikesCount}</i>
                                                 </div>
                                                </p>

                                                <div className="row">
                                                    {options.bedTimeDiet.map((foodItem, i) => (
                                                        <div className="p-3" key={i} >
                                                            <div className="about_food">
                                                                <img src={foodItem.foodImage ? foodItem.foodImage : FoodPlaceholder} alt="" />

                                                                <div className="food_content_bg">
                                                                    <h4>{foodItem.foodName}</h4>
                                                                    <div className="row">
                                                                        <div className="col"> <h6>{foodItem.calorieContent} cal</h6></div>
                                                                        <div className="col"> <h6>Qty. {foodItem.quantity}.0</h6></div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>))}
                                </div>}
                        </CardWrapper>
                        </div>
                    </div>}

                    <EditTemplate handleClose={this.handleClose} getDietPlanTemplate={this.getDietPlanTemplate} {...this.state} />


            </HOC>
        )
    }
}

export default ViewTempelate;