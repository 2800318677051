import axios from 'axios';
import API from '../Api';

export function getCRMAgentList(params) {
    return axios.get(API.GET_CRM_AGENT, params)
}
export function addCRMAgent(params) {
    return axios.post(API.ADD_CRM_AGENT, params)
}
export function assignRequestToAgent(params) {
    return axios.post(API.ASSIGN_REQ_TO_AGENT, params)
}

