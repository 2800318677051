import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
 
import "react-datepicker/dist/react-datepicker.css";

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          firstName: 'john',
          lastName: 'doe',
          profilePic: 'https://dummyimage.com/vga',
          email: 'example@gmail.com',
          dob: new Date('Mon Mar 18 1998 12:22:51 GMT+0530 (India Standard Time)'),
          gender: 1,
          countryCode: '110092',
          mobileNo: '9999999999',
          height: '170',
          weight: '68',
          beforePic: 'https://dummyimage.com/vga',
          afterPic: 'https://dummyimage.com/vga',
          isSmoker: false,
          isHyperTension: false,
          isDiabetes: false,
          heightConvert: 1,
          weightConvert: 1,
          errors: {
            errorFirstName: false,
            errorLastName: false,
            errorProfilePic: false,
            errorEmail: false,
            errorCountryCode: false,
            errormobileNumber: false,
            errorHeight: false,
            errorWeight: false,
            errorBeforePic: false,
            errorAfterPic: false,
          }

        };


        this.handleChange = this.handleChange.bind(this);
        this.handleDOBChange = this.handleDOBChange.bind(this);
        this.handleProfilePic = this.handleProfilePic.bind(this);
        this.handleBeforePic = this.handleBeforePic.bind(this);
        this.handleAfterPic = this.handleAfterPic.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event) {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let errors = Object.create(this.state.errors)

        this.setState({
            [name]: value,
        },
        () => {
            if(this.state.firstName.length <= 2 && this.state.firstName === ''){
                errors.errorFirstName = true
            }else{errors.errorFirstName = false}

            if(this.state.lastName.length <= 2 && this.state.lastName === ''){
                errors.errorLastName = true
            }else{errors.errorLastName = false}

            if(this.state.emai){
                errors.errorEmail = true
            }else{errors.errorEmail = false}

            if(this.state.mobileNo.search(/^(\+\d{1,3}[- ]?)?\d{10}$/)){
                errors.errormobileNumber = true
            }else{errors.errormobileNumber = false}

            if(this.state.height.search(/[+-]?([0-9]*[.])?[0-9]+/)){
                errors.errorHeight = true
            }else{errors.errorHeight = false}

            if(this.state.weight.search(/[+-]?([0-9]*[.])?[0-9]+/)){
                errors.errorWeight = true
            }else{errors.errorWeight = false}

            if(this.state.countryCode.search(/[+-]?([0-9]*[.])?[0-9]+/)) {
                errors.errorCountryCode = true
            }else{errors.errorCountryCode = false}

            this.setState({
                errors: errors
            })
        })
      }

      handleDOBChange(date) {
        this.setState({
            dob: date
        });
      }

    handleProfilePic(event){
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.addEventListener("load", () => {
            this.setState({profilePic: reader.result})
        }, false);
        if (file) {reader.readAsDataURL(file)}
    }

    handleBeforePic(event){
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.addEventListener("load", () => {
            this.setState({beforePic: reader.result})
        }, false);
        if (file) {reader.readAsDataURL(file)}
    }

    handleAfterPic(event){
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.addEventListener("load", () => {
            this.setState({afterPic: reader.result})
        }, false);
        if (file) {reader.readAsDataURL(file)}
    }

    handleSubmit(event) {
        event.preventDefault();
        if(!this.state.errors.errorFirstName && !this.state.errors.errorLastName && !this.state.errors.errorEmail && !this.state.errors.errorWeight && !this.state.errors.errorHeight && !this.state.errors.errormobileNumber && !this.state.errors.errorCountryCode){
            console.log('okkk')
        }
        else{
            console.log('error')
        }
       
    }

    render(){
        return(
        <div className="body-container-wrapper">
           <div className="body-container cm_edit_profile_wraper">
           <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item">Edit Profile</li>
              </ol>
            <h3 className="text-center mb-5">Edit Profile</h3>
            <Link to="/profile" className="btn btn-primary btn-sm cm_fixed_btn"><i className="fa fa-user" aria-hidden="true"></i>&nbsp; View Profile</Link>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mb-2">
                        <div className="col-md-6 form-group">
                            <label htmlFor="firstName" className="col-form-label">First Name</label>
                            <input
                                type="text"
                                className={`form-control ${this.state.errors.errorFirstName ? 'is-invalid' : ''}`}
                                name="firstName"
                                value={this.state.firstName}
                                onChange={this.handleChange}
                            />
                            {this.state.errors.errorFirstName ? <span className="cm_error">First name is required.</span> : null}

                        </div>

                        <div className="col-md-6 form-group">
                            <label htmlFor="lastName" className="col-form-label">Last Name</label>
                            <input
                                type="text"
                                className={`form-control ${this.state.errors.errorLastName ? 'is-invalid' : ''}`}
                                name="lastName"
                                value={this.state.lastName}
                                onChange={this.handleChange}
                            />
                            {this.state.errors.errorLastName ? <span className="cm_error">Last name is required.</span> : null}
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6 form-group">
                            <label htmlFor="email" className="col-form-label">Email</label>
                            <input
                                type="text"
                                className={`form-control ${this.state.errors.errorEmail ? 'is-invalid' : ''}`}
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                            {this.state.errors.errorEmail ? <span className="cm_error">Email is required.</span> : null}
                        </div>

                        <div className="col-md-6 form-group">
                            <label htmlFor="mobileNo" className="col-form-label">Mobile No.</label>
                            <input
                                type="text"
                                className={`form-control ${this.state.errors.errormobileNumber ? 'is-invalid' : ''}`}
                                name="mobileNo"
                                value={this.state.mobileNo}
                                onChange={this.handleChange}
                            />
                            {this.state.errors.errormobileNumber ? <span className="cm_error">Mobile number is required.</span> : null}
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-3 form-group">
                            <div className="mb-2">
                                <label htmlFor="gender" className="col-form-label">Gender</label>
                            </div>
                            <div className="cm_inline">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="1"
                                    id="male"
                                    className="cm_hide_checkbox"
                                    checked={this.state.gender === 1}
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                />
                                <span className="cm_checkbox_box"></span>
                                <label htmlFor="male">Male</label>
                            </div>

                            <div className="cm_inline">
                                <input
                                    type="radio"
                                    name="gender"
                                    id="female"
                                    value="2"
                                    checked={this.state.gender === 2}
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                    className="cm_hide_checkbox"
                                />
                                <span className="cm_checkbox_box"></span>
                                <label htmlFor="female">Female</label>
                            </div>

                            <div className="cm_inline">
                                <input
                                    type="radio"
                                    name="gender"
                                    id="other"
                                    checked={this.state.gender === 3}
                                    value="3"
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                    className="cm_hide_checkbox"
                                />
                                <span className="cm_checkbox_box"></span>
                                <label htmlFor="other">Others</label>
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label htmlFor="staticEmail" className="col-form-label">Date of Birth</label>
                            <DatePicker
                                selected={this.state.dob}
                                onChange={this.handleDOBChange}
                                className="form-control"
                            />
                        </div>

                        <div className="col-md-2 form-group cm_mt_5">
                            <div className="custom-control custom-checkbox cm_mt">
                                <input
                                    name="isSmoker"
                                    id="isSmoker"
                                    type="checkbox"
                                    checked={this.state.isSmoker}
                                    onChange={this.handleChange}
                                    className="custom-control-input"
                                />
                                <label className="custom-control-label" htmlFor="isSmoker">isSmoker</label>
                            </div>
                        </div>

                        <div className="col-md-2 form-group cm_mt_5">
                            <div className="custom-control custom-checkbox cm_mt">
                                <input
                                    name="isHyperTension"
                                    id="isHyperTension"
                                    type="checkbox"
                                    checked={this.state.isHyperTension}
                                    onChange={this.handleChange}
                                    className="custom-control-input"
                                />
                                <label className="custom-control-label" htmlFor="isHyperTension">isHyperTension</label>
                            </div>
                        </div>

                        <div className="col-md-2 form-group cm_mt_5">
                            <div className="custom-control custom-checkbox cm_mt">
                                <input
                                    name="isDiabetes"
                                    id="isDiabetes"
                                    type="checkbox"
                                    checked={this.state.isDiabetes}
                                    onChange={this.handleChange}
                                    className="custom-control-input"
                                />
                                <label className="custom-control-label" htmlFor="isDiabetes">isDiabetes</label>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6 form-group">
                            <label htmlFor="staticEmail" className="col-form-label">height</label>
                            <div className="row">
                                <div className="col-6">
                                    <select
                                        name="heightConvert"
                                        className="form-control form-inline"
                                        value={this.state.heightConvert}
                                        onChange={this.handleChange}
                                    >
                                        <option value={1}>Centimeter</option>
                                        <option value={2}>Feet</option>
                                    </select>
                                </div>
                                <div className="col-6">
                                    <span className="sm_msg">{this.state.heightConvert === 1 ? 'Height in Centimeter' : 'Height in Feet'}</span>
                                    <input
                                        name="height"
                                        type="text"
                                        value={this.state.height}
                                        className={`form-control form-inline ${this.state.errors.errorHeight ? 'is-invalid' : ''}`}
                                        placeholder="height"
                                        onChange={this.handleChange}
                                    />
                                    {this.state.errors.errorHeight ? <span className="cm_error">Height is required.</span> : null}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label htmlFor="staticEmail" className="col-form-label">weight</label>
                            <div className="row">
                                <div className="col-6">
                                    <select
                                        name="weightConvert"
                                        className="form-control form-inline"
                                        value={this.state.weightConvert}
                                        onChange={this.handleChange}
                                    >
                                        <option value={1}>Kilogram</option>
                                        <option value={2}>lbs</option>
                                    </select>
                                </div>
                                <div className="col-6">
                                <span className="sm_msg">{this.state.weightConvert === 1 ? 'Weight in Kilogram' : 'Weight in lbs'}</span>
                                    <input
                                        type="text"
                                        className={`form-control form-inline ${this.state.errors.errorWeight ? 'is-invalid' : ''}`}
                                        placeholder="weight"
                                        name="weight"
                                        value={this.state.weight}
                                        onChange={this.handleChange}
                                    />
                                    {this.state.errors.errorWeight ? <span className="cm_error">Weight is required.</span> : null}
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-3 form-group">
                            <label htmlFor="beforePic" className="col-form-label">Before Pic</label>
                            <div className="cm_file_control_wrap">
                            <input
                                type="file"
                                name="beforePic"
                                className="form-control cm_file_control"
                                onChange={this.handleBeforePic}
                                accept="image/jpeg,image/jpg,image/png,"
                            />
                            <span className="cm_file_control_box" style={{backgroundImage: 'url('+this.state.beforePic+')'}}><i className="fa fa-picture-o" aria-hidden="true"></i></span>
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label htmlFor="afterPic" className="col-form-label">After Pic</label>
                            <div className="cm_file_control_wrap">
                                <input
                                    type="file"
                                    name="afterPic"
                                    className="form-control cm_file_control"
                                    onChange={this.handleAfterPic} 
                                    accept="image/jpeg,image/jpg,image/png,"  
                                />
                                <span className="cm_file_control_box" style={{backgroundImage: 'url('+this.state.afterPic+')'}}><i className="fa fa-picture-o" aria-hidden="true"></i></span>
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label htmlFor="profilePic" className="col-form-label">Profile Pic</label>
                            <div className="cm_file_control_wrap">
                                <input
                                    type="file"
                                    name="profilePic"
                                    className="form-control cm_file_control"
                                    onChange={this.handleProfilePic}
                                    accept="image/jpeg,image/jpg,image/png,"
                                />
                                <span className="cm_file_control_box" style={{backgroundImage: 'url('+this.state.profilePic+')'}}><i className="fa fa-picture-o" aria-hidden="true"></i></span>
                            </div>
                        </div>

                        <div className="col-md-3">
                        <div className="form-group">
                        <label htmlFor="countryCode" className="col-form-label">Country Code</label>
                            <input
                                name="countryCode"
                                type="text"
                                value={this.state.countryCode}
                                className={`form-control form-inline ${this.state.errors.errorCountryCode ? 'is-invalid' : ''}`}
                                placeholder="Country Code"
                                onChange={this.handleChange}
                            />
                            {this.state.errors.errorCountryCode ? <span className="cm_error">Country code is required.</span> : null}
                        </div>
                        <div className="cm_align_bottom">
                            <button type="submit" className="btn btn-primary btn-block">Save</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        )
    }
}

export default EditProfile;