import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ProductJob from '../productJob/ProductJob';
import { Accordion, Card, Button } from 'react-bootstrap';
import ProductServiceIncident from '../productServiceIncident'


class UserJobDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }
    render() {
        let { isFromUser, requestId } = this.props;
        return (

            <div >

                <Accordion defaultActiveKey="0">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <h4 className="cm_page_heading">Product Job</h4>
                            </Accordion.Toggle>


                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body> <ProductJob isFromUser={isFromUser} requestId={requestId} /></Card.Body>
                        </Accordion.Collapse>
                    </Card>                    
                </Accordion>



            </div>

        )
    }


}
export default UserJobDetails;