import axios from 'axios';
import API from '../Api';

export function POST(params) {
    return axios({
        method: 'post',
        url: API.ADMIN_LOGIN_V2,
        data: params,
        headers: {
            Authorization: API.AUTH,
            'x-fitstar-token':API.FIT_STAR_TOKEN,
        }
    })
}

export function verifyOTP(otp, token) {
    return axios({
        method: 'post',
        url: `${API.VERIFY_OTP}`,
        data: {otp:otp},
        headers: {
            Authorization: API.AUTH,
            accessToken : token
        }
    })
}

export function reSendOtp(token) {
    return axios({
        method: 'get',
        url: `${API.RESEND_OTP}`,
        headers: {
            Authorization: API.AUTH,
            accessToken : token
        }
    })
}

export function userAlreadyExist(params, token) {
    return axios({
        method: 'post',
        url: `${API.ADMIN_EXIST}`,
        data: params,
        headers: {
            Authorization: API.AUTH,
            accessToken : token
        }
    })
}

export function resetPassword(password, token) {
    return axios({
        method: 'post',
        url: `${API.RESET_PASSWORD}`,
        data: {newPassword:password},
        headers: {
            Authorization: API.AUTH,
            accessToken : token
        }
    })
}



export function getAdminProfile(params) {
    return axios.get(API.GET_ADMIN_PROFILE, params)
}

export function getAllSubAdmins(params) {
    return axios.get(API.GET_ALL_ADMINS, params)
}

export function addSubAdmin(params) {
    return axios.post(API.ADD_SUB_ADMIN, params)
}


export function changeDieticianStatus(params) {
    return axios.post(API.CHANGE_DIETICIAN_STATUS,params)
}

export function logout(params) {
    return axios.post(API.ADMIN_LOGOUT, params)
}


export function GET(params) {
    return axios({
        method: 'get',
        url: API.USER_GET_PROFILE,
        data: params.data,
        headers: {
            platform: 3,
            Authorization: API.AUTH,
        }
    })
}

export function PUT(params) {
    return axios({
        method: 'put',
        url: API.USER_EDIT_PROFILE,
        data: params.data,
        headers: {
            platform: 3,
            Authorization: API.AUTH,
        }
    })
}

export function DELETE(params) {
    return axios({
        method: 'delete',
        url: API.USER_DELETE_PROFILE,
        data: params.data,
        headers: {
            platform: 3,
            Authorization: API.AUTH,
        }
    })
}


export function updateUserCoupon(params) {
    return axios.post(API.UPDATE_USER_COUPON, params)
}

export function addProductPurchased(params) {
    return axios.post(API.ADD_PRODUCT_PURCHASED, params)
}

export function editSubAdmin(params) {
    return axios.post(API.EDIT_SUB_ADMIN, params)
}

export function editUserProfile(params) {
    return axios.post(API.EDIT_USER_PROFILE, params)
}

export function getUserTimeSlotsByAdmin(params) {
    return axios.get(API.GET_USER_TIME_SLOT_BY_ADMIN, params);
}

export function getRoles(params) {
    return axios.get(API.GET_ROLES, params);
}

export function getTrainersByAvailableSlotV2(params) {
    return axios.get(API.GET_TRAINERS_BY_AVAILABLE_SLOT_V2, params)
}

export function getUserFreeSessionsLeft(params) {
    return axios.get(API.GET_USER_FREE_SESSION_LEFT, params)
}

export function getUserPaymentDueSession(params) {
    return axios.get(API.GET_USER_PAYMENT_DUE_SESSIONS, params)
}

export function getStreamingTypeDisplayConfig(params) {
    return axios.get(API.GET_STREAMING_TYPE_DISPLAY, params)
}

export function updateStreamingTypeDisplayConfig(params) {
    return axios.post(API.UPDATE_STREAMING_TYPE_DISPLAY, params)
}
