import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Pagination from "react-js-pagination";
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable'
import { Link } from 'react-router-dom';
import { getRidersList, verifyRiderByAdmin, getRidersLocationLogs, deleteRider, editRider } from '../../config/services/riderService';
import { toast } from 'react-toastify';
import { Button, Modal, Badge, Form, InputGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from 'moment'
import { getPathLength } from 'geolib';
import Geocode from "react-geocode";
import ExportExcel from "./ExportExcel";
import Select from 'react-select';
import { getStationsListByAdmin } from '../../config/services/stationsService';
Geocode.setApiKey("AIzaSyDUF_h2x2-wYsEgBndF_xjwa7QGX6Rx-w4");


class DeliveryRiders extends Component {
    constructor(props) {
        super(props);

        this.searchTimeOut = null;
        this.state = {
            riderList: [],
            searchTimeOut: 0,
            name: '',
            loader: false,
            validationError: "",
            sortType: -1,
            sortField: 'created',
            pageNo: 1,
            count: 10,
            totalRiders: 20,
            tempRiderId: '',
            verifyRiderPopup: false,
            search: '',
            viewRiderTravelTimePopup: false,
            selectedDate: moment().valueOf(),
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
            distanceTravelled: 0,
            viewImagesPopup: false,
            tempImages: [],
            stationsList: [],
            filterByStation: '',
            deleteRiderPopup: false,
            tripsDone: [],
            viewTripsDonePopup: false,
            riderName: "",
            stationName: "",
            stationId: "",
            editRiderPopup: false,
            riderStation:{}
        }
    }


    closeModal = () => this.setState({
        validationError: "",
        tempRiderId: '',
        verifyRiderPopup: false,
        viewRiderTravelTimePopup: false,
        distanceTravelled: 0,
        viewImagesPopup: false,
        tempImages: [],
        deleteRiderPopup: false,
        tripsDone: [],
        viewTripsDonePopup: false,
        riderName: "",
        stationName: "",
        stationId: "",
        editRiderPopup: false,
        riderStation:{}
    })

    submitVerifyRider = (e) => {
        e.preventDefault()
        let { tempRiderId } = this.state
        let params = {
            deliveryRiderId: tempRiderId
        }

        this.setState({ loader: true })
        verifyRiderByAdmin(params)
            .then(res => {
                this.setState({ loader: false })
                const response = res.data;
                if (response.statusCode == 1) {
                    toast.success(response.responseData.message)
                    this.closeModal();
                    this.getRidersList();
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }
            })
    }


    componentDidMount() {
        this.getRidersList();
        this.getAllStations();
    }


    getAllStations = () => {
        getStationsListByAdmin()
            .then(res => {
                const response = res.data;
                if (response.statusCode == 1) {
                    let stationsList = response.responseData.result.getStations
                    stationsList.map(station => {
                        station.label = station.stationName
                        station.value = station._id
                    })
                    stationsList.push({ label: "All Stations", value: '' })
                    this.setState({ stationsList })
                }
                else if (response.statusCode == 0) {
                    console.log(response.error.errorMessage, ":::ERROR")
                }
            })
    }


    handlePagination = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getRidersList() });


    getRidersList = (params) => {
        let { pageNo, count, sortField, sortType, search, dateFrom, dateTo, filterByStation } = this.state
        let paramsObj = {
            params: {
                pageNo: pageNo - 1, count, sortField, sortType, search, dateFrom, dateTo, filterByStation
            }
        }

        let self = this;
        this.setState({ loader: true })
        getRidersList(paramsObj)
            .then(res => {
                const response = res.data
                if (response.statusCode == 1) {
                    self.setState({
                        riderList: response.responseData.result,
                        totalRiders: response.responseData.totalCount, loader: false
                    })
                } else if (response.statusCode == 0) {
                    console.log(response.error.errorMessage, ":::ERROR")
                    this.setState({ loader: false })
                }
            })
    }



    handleChange = (e) => {
        const { name, value, type } = e.target
        this.setState({ [name]: value })
    }


    handleSearch = (e) => {
        this.setState({ search: e.target.value })

        if (this.searchTimeOut)
            clearTimeout(this.searchTimeOut);

        this.searchTimeOut = setTimeout(
            function () {
                this.getRidersList();
            }
                .bind(this),
            400
        );
    }


    handleSortList = (sortField) => {
        const { sortType } = this.state;
        let sortOrder = sortType == 1 ? -1 : 1;
        this.setState({ sortField: sortField, loader: true, sortType: sortOrder }, () => { this.getRidersList() })
    }

    verifyRider = (rider) => {
        this.setState({ tempRiderId: rider._id, verifyRiderPopup: true })
    }

    viewRiderTravelTime = (rider) => {
        this.setState({ tempRiderId: rider._id, viewRiderTravelTimePopup: true }, () => this.getRidersLocationLogs())
    }

    selectDate = (date) => {
        this.setState({ selectedDate: moment(date).valueOf(), dateFrom: moment(date).startOf('day').valueOf(), dateTo: moment(date).endOf('day').valueOf() }, () => {
            this.getRidersList()
        })
    }

    getRidersLocationLogs = () => {
        let { dateFrom, dateTo, tempRiderId } = this.state
        let paramsObj = { params: { dateFrom, dateTo, riderId: tempRiderId } }

        getRidersLocationLogs(paramsObj)
            .then(res => {
                const response = res.data
                if (response.statusCode == 1) {
                    let Locations = response.responseData.result
                    let TravelledLatLong = []
                    Locations.map(locat => {
                        TravelledLatLong.push({ latitude: parseFloat(locat.lat), longitude: parseFloat(locat.long) })
                    })
                    let distanceTravelled = getPathLength(TravelledLatLong) / 1000 //In KM
                    this.setState({ distanceTravelled })
                }
                else if (response.statusCode == 0) {
                    this.setState({ loader: false, validationError: response.error.errorMessage })
                }
            })
    }

    parseTimeDifference = (riderDetails) => {
        let { loginTime, logoutTime } = riderDetails

        if (loginTime && logoutTime) {
            var start_date = loginTime;
            var end_date = logoutTime;
            return moment.utc(moment(end_date).diff(moment(start_date))).format("HH:mm")
        } else {
            return "-"
        }
    }


    viewImage = (images) => {
        let tempImages = []
        let samplePromise = images.map(image => {
            return Geocode.fromLatLng(image.lat, image.long)
                .then((res, err) => {
                    if (err) {
                        console.log(err, "::Err")
                    }
                    else {
                        image.locationName = res.results[0].formatted_address;
                        tempImages.push(image)
                    }
                })
        })

        Promise.all(samplePromise)
            .then(res => {
                this.setState({ tempImages, viewImagesPopup: true })
            })
    }

    handleChangeCountPerPage = (e) => {
        let { name, value } = e.target

        if (value) {
            this.setState({ count: value }, () => { this.getRidersList() })
        } else {
            this.setState({ count: 10 }, () => { this.getRidersList() })
        }
    }


    handleFilterStation = (station) => {
        this.setState({ filterByStation: station.value }, () => this.getRidersList())
    }


    handleDeleteRider = (rider) => {
        this.setState({ deleteRiderPopup: true, tempRiderId: rider._id })
    }


    submitDeleteRider = (e) => {
        e.preventDefault()
        let { tempRiderId } = this.state
        let params = {
            riderId: tempRiderId
        }
        this.setState({ loader: true })

        deleteRider(params)
            .then(res => {
                this.setState({ loader: false })
                const response = res.data;
                if (response.statusCode == 1) {
                    toast.success(response.responseData.message)
                    this.closeModal();
                    this.getRidersList();
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }
            })
    }


    viewTrips = (trips) => {
        // trips.map(trip => {

        //    return this.getLocationFromLatLong(trip.startLocation[1], trip.startLocation[0])
        //         .then(res => {
        //             trip.startLocationName = res

        //            return this.getLocationFromLatLong(trip.endLocation[1], trip.endLocation[0])
        //         })
        //         .then(res => {
        //             trip.endLocationName = res
        //         })
        // })
        this.setState({ tripsDone: trips, viewTripsDonePopup: true })
    }

    getLocationFromLatLong = (lat, long) => {
        return Geocode.fromLatLng(lat, long)
            .then(res => {
                return res.results[0].formatted_address;
            })
    }

    handleEditRider = (rider) => {
        let { name, stationName, stationId, _id } = rider
        let riderStation = {label: stationName, value:stationId}
        this.setState({ riderName: name, riderStation, tempRiderId: _id, editRiderPopup: true,  })
    }

    handleChangeStation=(riderStation)=>{
        this.setState({riderStation})
    }


    submitEditRider=()=>{
        let { riderName, riderStation,tempRiderId } = this.state
        if(!riderName){
            this.setState({validationError:"Please Enter Rider's Name"})
        }else if(!riderStation.label){
            this.setState({validationError:"Please Select Station"})
        }else{

            let params={
                riderId:tempRiderId,
                name:riderName,
                stationName: riderStation.label,
                stationId: riderStation.value
            }

            editRider(params)
            .then(res=>{
                this.setState({ loader: false })
                const response = res.data;
                if (response.statusCode == 1) {
                    toast.success(response.responseData.message)
                    this.closeModal();
                    this.getRidersList();
                } else if (response.statusCode == 0) {
                    this.setState({ validationError: response.error.errorMessage })
                }                
            })
        }
    }



    render() {
        const {
            riderList, loader, validationError, sortType, pageNo, count, totalRiders, verifyRiderPopup, viewRiderTravelTimePopup, selectedDate, distanceTravelled, viewImagesPopup,
            tempImages, stationsList, deleteRiderPopup, tripsDone, viewTripsDonePopup, editRiderPopup, riderName,riderStation
        } = this.state;

        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Delivery Riders</li>
                        </ol>
                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="cm_page_heading">Delivery Riders</h4>
                                        {riderList.length && <ExportExcel riderList={riderList} />}
                                    </div>


                                    <Form.Group className='mr-2'>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text >Select Date</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <DatePicker
                                                className="form-control text-center"
                                                onChange={this.selectDate}
                                                showYearDropdown
                                                placeholderText="Select Date....."
                                                selected={new Date(selectedDate)}
                                                maxDate={new Date()}
                                                dateFormat="MMMM d, yyyy"
                                            />
                                        </InputGroup>
                                    </Form.Group>


                                    <Form.Group md="2">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text >Number Of Riders:</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="number"
                                                className="form-control cm_page_count"
                                                name="count"
                                                onChange={this.handleChangeCountPerPage}
                                                min={2}
                                                placeholder='Ex: 10'
                                            />
                                        </InputGroup>
                                    </Form.Group>

                                    <div className="col-md-3 text-right cm_search">
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name"
                                            name="search"
                                            onChange={this.handleSearch}
                                        />
                                        <i className="fa fa-search" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive cm_table_height ">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No </th>
                                        <th scope="col" className="filter_text">Name
                                            <i className={`fa fa-sort-alpha-${sortType == 1 ? 'asc' : 'desc'}`} />
                                            <button onClick={() => this.handleSortList('name')}></button>
                                        </th>
                                        <th>Phone No.</th>
                                        <th scope="col">Verification</th>
                                        <th scope="col">Last Three Images</th>
                                        <th scope="col">
                                            <Select
                                                className='cm_select_filter'
                                                isSearchable
                                                placeholder='Station'
                                                options={stationsList}
                                                onChange={(val) => this.handleFilterStation(val)}
                                            />
                                        </th>

                                        <th scope="col">Distance</th>
                                        <th scope="col">Checkin Time</th>
                                        <th scope="col">Checkout Time</th>
                                        <th scope="col">Total Time</th>
                                        <th scope="col">Did Login?</th>
                                        <th scope="col">Trips</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {riderList.map((rider, index) => (
                                        <tr key={index}>
                                            <td>{((index + 1) + ((pageNo - 1) * count))}</td>
                                            <td> {rider.name} </td>
                                            <td>{rider.fullPhoneNumber}</td>
                                            <td>{rider.isVerfied ? 'Verified' :
                                                <Button variant="outline-info" size="sm" onClick={() => this.verifyRider(rider)}>Verify</Button>}</td>
                                            <td>{rider.imagesForDay.length ?
                                                <Button variant="outline-info" size="sm" onClick={() => this.viewImage(rider.imagesForDay)}>View Images</Button>
                                                : "No Image"}
                                            </td>
                                            <td>{rider.stationName}</td>
                                            <td>{(rider.distanceTravelled).toFixed(2)} Km</td>
                                            <td>{rider.loginTime ? moment(rider.loginTime).format('hh:mm A') : "-"}</td>
                                            <td>{rider.logoutTime ? moment(rider.logoutTime).format('hh:mm A') : "-"}</td>
                                            <td>{this.parseTimeDifference(rider)}</td>
                                            <td>{rider.didLogin ? "Yes" : "No"}</td>
                                            <td>{rider.trips.length ?
                                                <Button variant="outline-info" size="sm" onClick={() => this.viewTrips(rider.trips)}>{rider.trips.length} Trip</Button> : 'No Trips'}</td>
                                            <td>
                                                <button className="btn btn-danger btn-xs ml-2" onClick={() => this.handleDeleteRider(rider)} ><i className="fa fa-trash" /></button>
                                                <button className="btn btn-info btn-xs ml-2" onClick={() => this.handleEditRider(rider)} ><i className="fa fa-pencil" /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                            {!riderList.length ? <h3 className="empty_error">Sorry, We couldn't find any content! <span>{this.state.deviceSearch}</span></h3> : null}
                        </div>

                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={count}
                            totalItemsCount={totalRiders}
                            pageRangeDisplayed={4}
                            onChange={this.handlePagination}
                        />
                    </div>
                </div>


                <Modal show={verifyRiderPopup} onHide={this.closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6> Are you sure, you want to mark him as verified? </h6></Modal.Title>
                        <h6 className="text-center error_message">{validationError}</h6>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button size="sm" variant="success" onClick={this.submitVerifyRider}>
                            Verify
                            </Button>
                        <Button size="sm" variant="danger" onClick={this.closeModal}>
                            Close
                            </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={editRiderPopup} onHide={this.closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6> Edit Details </h6></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form>
                            <div className="form-group">
                                <label>Rider Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="riderName"
                                    value={riderName}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Station</label>
                                <Select
                                    className='cm_select_filter'
                                    isSearchable
                                    placeholder='Station'
                                    options={stationsList}
                                    value={riderStation}
                                    onChange={(val) => this.handleChangeStation(val)}
                                />
                            </div>
                        </form>
                        <h6 className="text-center error_message">{validationError}</h6>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="success" onClick={this.submitEditRider}>
                            Submit
                            </Button>
                        <Button size="sm" variant="danger" onClick={this.closeModal}>
                            Close
                            </Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={deleteRiderPopup} onHide={this.closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h6> Are you sure, you want to delete this rider?</h6></Modal.Title>
                        <h6 className="text-center error_message">{validationError}</h6>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button size="sm" variant="dark" onClick={this.closeModal}>
                            Close
                            </Button>
                        <Button size="sm" variant="danger" onClick={this.submitDeleteRider}>
                            Delete
                            </Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    show={viewImagesPopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Images and Location</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body  text-center">
                                <div className="form-group">
                                    {tempImages.map((imageObj, i) => (
                                        <div key={i} className='mt-2'>
                                            <p><b>Location {i + 1}</b> : {imageObj.locationName}</p>
                                            <p><b>Time</b> : {moment(imageObj.locationTime).format('Do MMM YYYY MM:HH A')}</p>
                                            <img title={"Taken at " + imageObj.locationName} src={imageObj.image} width="100%" className="mt-1" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" size="sm" onClick={this.closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>


                <Modal
                    show={viewTripsDonePopup}
                    onHide={this.closeModal}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Trips</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body">
                                <div className="form-group">
                                    {tripsDone.map((trip, i) => (
                                        <div key={i} className='mt-2'>
                                            <h5><b>Trip {i + 1}</b></h5>
                                            <p>
                                                <b>Check In At</b> : {moment(trip.checkInTime).format('hh:mm A')}
                                                &nbsp; &nbsp;
                                                <b>Check Out At</b> : {moment(trip.checkOutTime).format('hh:mm A')}
                                            </p>
                                            <p><b>Distance</b> : {(trip.distance).toFixed(2)} Km</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" size="sm" onClick={this.closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </HOC >

        );
    }
}

export default DeliveryRiders;