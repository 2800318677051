import axios from 'axios';
import API from '../Api';


export function getFitnessPlanByAdmin(params) {
    return axios.get(API.GET_FITNESS_PLAN_BY_ADMIN,params)
}

export const FitnessPlanService = {
    getFitnessPlanByAdmin
};
