import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { getUserLogs } from '../../config/services/usersService'
import TabTitle from './TabTitle'
class Logs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userLogs: []
        }
    }

    getUserLogs = () => {
        const { userId } = this.state;
        let paramsObj = {
            params: { notesOfUserId: userId }
        }
        getUserLogs(paramsObj)
            .then((res) => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.setState({
                        userLogs: response.responseData.result,
                        loader: false
                    })
                } else if (response.statusCode == 0) {
                    // cogoToast.error(response.error.errorMessage);
                }
            })
    }

    componentDidMount() {
        const { id } = this.props;
        this.setState({ userId: id }, () => {
            this.getUserLogs();
        })
    }
    render() {
        const { userLogs } = this.state
        return (
            <div className="user-ui-wrapper">
                <TabTitle title="Logs" />
                <div className="user-ui-inner-wrapper">
                    <div className="user-navigation">
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first"><i className="fa fa-cutlery mr-1 side_icons" /> Diet logs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second"> <i className="fa fa-heartbeat mr-1 side_icons" />Fitness logs</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="user-ui-content">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <ul className="list-unstyled">
                                    {userLogs.filter(notes => notes.noteType === 1).map((notes, i) => (
                                        <li className="logs-items" key={i}>
                                            <h4 className="logs-heading">{notes.note}</h4>
                                            <div className="log-aminities">
                                                <div className="log-auther-date">
                                                    <span className="label">Recorded by : </span>
                                                    <span className="value">{(notes.recordedBy && notes.recordedBy.email) ? notes.recordedBy.email : '--'}</span>
                                                </div>
                                                <div className="log-auther-date">
                                                    <span className="label">Recorded on : </span>
                                                    <span className="value">{dateFormat(notes.created, "d mmmm yyyy")}</span>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ul className="list-unstyled">
                                    {userLogs.filter(notes => notes.noteType === 2).map((notes, i) => (
                                        <div className="logs-items" key={i}>
                                            <h4 className="logs-heading">{notes.note}</h4>
                                            <div className="log-aminities">
                                                <div className="log-auther-date">
                                                    <span className="label">Recorded by : </span>
                                                    <span className="value">{(notes.recordedBy && notes.recordedBy.email) ? notes.recordedBy.email : '--' }</span>
                                                </div>
                                                <div className="log-auther-date">
                                                    <span className="label">Recorded on : </span>
                                                    <span className="value">{dateFormat(notes.created, "d mmmm yyyy")}</span>
                                                </div>
                                            </div>
                                        </div>))}
                                </ul>
                            </Tab.Pane>

                        </Tab.Content>
                    </div>
                </div>
            </div>
        );
    }
}

export default Logs;