import React, {useState, Fragment, useEffect, useRef} from "react";
import Dropzone from 'react-dropzone';
import { Button } from 'react-bootstrap';
import Popup from "reactjs-popup";
import * as _ from 'lodash';
import { addVendors, updateVendor, generateServiceableVendorUploadTemplate, upsertServiceableVendorsEntries, getExistingUserEntries } from "../../config/services/vendorService";
import {
  getAllPincodes,
  getAllStates,
  getAllCitys,
} from "../../config/services/servicePincodes";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import Select from 'react-select';
import { List, InfiniteLoader } from "react-virtualized";
import {TechnicianTypes} from "./Constants";
import {serviceCategoryFilterOptions} from "../utils/Util";
const {  removeDuplicates, } = require("../utils/Util.js");

let style = { textTransform: "capitalize", fontWeight: "Bold" };
let styleForWrapper = { marginBottom: "0.7em" };

const addVendor = ({
  hide,
  addVendorModal,
  getVendors,
  selectedVendor,
  actionType,
  _ismounted,
}) => {
  let labels = ["state", "city"];
  const initialState = {
    error: "",
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    state: [],
    tat: "",
    countycode: "91",
    selectedPincode: {},
    states: [],
    city: [],
    pincode: [],
    service: {},
    demoGraphic: [],
    isError: true,
    newError: {},
    statePageNo: 0,
    stateCount: 1000,
    cityCount: 3000,
    pincodeCount: 10000,
    requestsExcelSheet: undefined,
    validationError: undefined,
    technicianType: ""
  };

  const [state, setState] = useState({ ...initialState });
  let [ismounted, setIsmounted] = useState(_ismounted);
  const [selectedPincode, setPincode] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setselectedCity] = useState([]);
  const [renderValue, setRenderValue] = useState(false);
  const [newError, setNewError] = useState({});
  let [filteredpincodes, setFilteredpincodes] = useState([]);
  let [count, setCount] = useState(200);
  let [pageno, setPageNo] = useState(0);
  let [totalcount, setTotalCount] = useState(0);
  let [filteredcount, setFilteredCount] = useState(0);
  let pageNo = 0;
  let [isfetching, setIsFetching] = useState(false);
  let [loadmore, setLoadmore] = useState(false);
  const selectedStatesRef = useRef(!_.isNil(selectedVendor) && !_.isEmpty(selectedVendor.state) ? selectedVendor.state : [])
  const isUpdateModal = actionType === 'Update Vendor'
 
  const handleChange = (e, value, selector) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    switch (selector) {
      case "firstname":
        newError.firstname =
          value.length < 3 ? "First Name must be 3 characters long!" : "";
        break;
      case "lastname":
        newError.lastname =
          value.length < 3 ? "Last Name must be 3 characters long!" : "";
        break;
      case "email":
        newError.email =
          value.length > 0
            ? re.test(value)
              ? ""
              : "Please enter a valid email!"
            : "";
        break;
      case "mobile":
        newError.mobile =
          value.length > 9 && value.length < 11
            ? ""
            : "Please enter a valid mobile!";
        break;
      default:
        break;
    }
    console.log(selector + value)
    setState({ ...state, [selector]: value });
    setNewError(newError);
  };


  useEffect(() => {
    // initialize function
    if (selectedVendor) {      
      setIsmounted(true);
      setLoadmore(true);      
    }
    getStateLists();
  }, []);

  useEffect(() => {
    setPageNo(0);
    if (ismounted) {
      return;
    }
    setLoadmore(false);
    if (!selectedState || selectedState.length === 0) {
      setselectedCity([]);
      setPincode([]);
      setFilteredpincodes([]);
      setFilteredCount(0);
      setRemoved([]);
      setRenderkey(renderkey + 1);
    } 
  }, [selectedState]);

  useEffect(() => {
    setPageNo(0);

    if (ismounted) {
      return;
    }
    setLoadmore(false);
    if (!selectedCity || (selectedCity.length === 0 && !ismounted)) {
      setFilteredpincodes([]);
      setRemoved([]);
      setFilteredCount(0);
      setState({ ...state, pincode: [] }); 
    } else {
      // setFilteredpincodes([]);
      // setRemoved([]);
      // setPincode([]);
      console.log('call pincode')
    }
  }, [selectedCity]);

  useEffect(() => {
    if (
      selectedState &&
      selectedState.length > 1 &&
      selectedCity &&
      selectedCity.length === 0
    ) {
      setPincode([]);
    }
  }, [selectedPincode]);

  useEffect(() => {
    if (selectedVendor) {
      let states = [];
      let cities = [];
      let __result = [];
      let _removed = [];

      if (selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        states = removeDuplicates(selectedVendor.pincode, "state").map((data) => {
          return { _id: data.state, label: data.state, value: data.state};
        });
      }     

      if (selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        cities = removeDuplicates(selectedVendor.pincode, "city").map((data) => {
          return { _id: data.city, label: data.city, value: data.city, state: data.state };
        });
      }

      if (selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        // __result = selectedVendor.pincode.map((element) => {
        //   return element.pincode;
        // });

        let getPincode = [];
        selectedVendor.pincode.forEach(key => {          
          getPincode.push({
                label: key.pincode,
                value: key._id,
                _id: key._id,
                city: key.city,
                state: key.state
            })
        })
        state.pincode = getPincode;
        setPincodes(); 
      }

      if (selectedVendor.removedpincode && selectedVendor.removedpincode.length > 0) {
        _removed = selectedVendor.removedpincode.map((element) => {
          return element.pincode;
        });
      }

      setState({
        ...state,
        firstname: selectedVendor.firstName,
        lastname: selectedVendor.lastName,
        mobile: selectedVendor.mobileNo,
        email: selectedVendor.email,
        technicianType: selectedVendor.technicianType
      });
      setSelectedState(states);
      setselectedCity(cities);

      if(selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        setTotalCount(selectedVendor.pincode.length);
      } else {
        setTotalCount(0);
      }
      if(selectedVendor.pincode && selectedVendor.pincode.length > 0) {
        setFilteredCount(selectedVendor.pincode.length);
      } else {
        setFilteredCount(0);
      }
      
      setRemoved(_removed)      
      // setFilteredpincodes(__result);
      setRenderkey(renderkey + 1);

    }
  }, [selectedVendor]);

  const handleSubmit = () => {
    const matcherForFeilds = {
      "firstname": "First name",
      "lastname": "Last name",
      "mobile": "Mobile",
      "email": "Email",
    }


    // this part of the validation is for the plain text and number feilds 
    let feildsToValidate = ["firstname", "lastname", "mobile"]

    feildsToValidate.forEach(feild => {
      if (state[feild] === '' || state[feild] === null || state[feild] === undefined) {
        newError[feild] = `${matcherForFeilds[feild]} cannot be left blank`
      }
    });

    // this part of validation is for the dropdowns
    let dropDownFeilds = ["selectedCity", "selectedState", "selectedPincode"];
    dropDownFeilds.forEach((feild) => {
      if (feild === "selectedCity") {
        newError[feild] =
          selectedCity && selectedCity.length > 0 ? "" : "City Cannot be blank";
      } else if (feild === "selectedState") {
        newError[feild] =
          selectedState && selectedState.length > 0
            ? ""
            : "state Cannot be blank";
      } else {
        newError[feild] =
          filteredpincodes && filteredpincodes.length > 0
            ? ""
            : "pincode Cannot be blank";
      }
    });

    for (var key in newError) {
      if (newError[key] === "") {
        delete newError[key];
      }
    }

    setRenderValue(!renderValue);
    if (Object.keys(newError).length < 1) {
      const params = {
        firstName: state.firstname,
        lastName: state.lastname,
        email: state.email,
        mobileNo: state.mobile,
        countryCode: state.countycode,
        city: selectedCity,
        state: selectedState,
        tat: state.tat,        
        pincode: filteredpincodes,
        removepincodes: removed,
        user_id: selectedVendor ? selectedVendor._id : "",
        technicianType:state.technicianType
      };
      if (actionType == "Add new Vendor") {
        if (!_.isEmpty(state.requestsExcelSheet)) {
          addVendors(params).then((response) => {
            if (response && response.data.statusCode === 1) {
                // hide();
                // toast.success(`${state.firstname} added as vendor !`);
                let p = new FormData()
                p.append('serviceableVendorsExcelSheet', state.requestsExcelSheet)
                p.append('vendorId', response.data.responseData.result._id)
                upsertServiceableVendorsEntries(p).then(r => {
                  if (r && r.data.statusCode === 1) {
                    // maybe do more here
                    hide();
                    getVendors();
                    toast.success(`${state.firstname} added as serviceable vendor !`);
                    hide();
                  } else {
                    // trigger delete vendor
                    console.log("vandor addition failed as serv failed!")
                  }
                })
              hide();
            } else {
              setState({
                ...state,
                error: response.data.statusCode === 0 ? response.data.error.errorMessage : "Something went  wrong during saving the data, please  try again"
              });
            }
          });
        } else {
          setState({
            ...state,
            error: "Vendor Pincode Costing data is required for a new vendor!"
          });
        }
        
      } else {
        updateVendor(params).then((response) => {
          if (response && response.data.statusCode === 1) {
            toast.success(
              `${params.firstName + params.lastName} Edited successfull!`
            );
            if (!_.isEmpty(state.requestsExcelSheet)) {
              let p = new FormData()
              p.append('serviceableVendorsExcelSheet', state.requestsExcelSheet)
              p.append('vendorId', params.user_id)
              p.append('override', "true") // this is for override
              upsertServiceableVendorsEntries(p).then(r => {
                // maybe do more here
                if (r && r.data.statusCode === 1) {
                  // maybe do more here
                  toast.success(`${state.firstname} updated serviceable vendor entries !`);
                } else {
                  // trigger delete vendor
                  console.log("vendor updation failed as serv failed!")
                }
              })
            }
            getVendors();
            hide();
          } else {
            setState({ ...state, error: "Vendor Not updated!" });
            toast.error("Vendor Not updated !");
          }
        });
      }
    }
  };



  const getStateLists = () => {   
    const { statePageNo, stateCount, states } = state;
    let params = { search: '', sortField: "_id", sortType: 1, pageNo: statePageNo, count: stateCount }
    return getAllStates({ params })
        .then(res => {
            let response = res.data;            
            if (response.statusCode == 1) {
                let data = res.data.responseData
                let totalCount = data.totalCount;
                let pgno = statePageNo + 1;

                if (data.result && data.result.length > 0) {
                    data.result.forEach(key => {
                      states.push({
                            label: key._id,
                            value: key._id,
                            _id: key._id
                        })
                    })
                }

                state.statePageNo = pgno;
                state.states = states;
                if (totalCount > (pgno * stateCount)) {
                    getStateLists();
                }
            } else if (response.statusCode == 0) {
                toast.error(response.error.errorMessage)
            }
        })
  }

  const serviceStateSelect = (e) => {  
    setIsmounted(false);
    let getSelectedState = []
    const { statePageNo, stateCount, cityCount, states, city, pincode } = state;
    let getCity = selectedCity;
    selectedStatesRef.current = e.map(m => m.value)

    if(e && e.length > 0) {   
        if(e.length > selectedState.length) {   
            // add city and pincodes             
            let findState = e[e.length - 1].label;
            let params = {
                count: cityCount,
                pageNo: statePageNo,
                states: findState
            }
            setselectedCity([]);
            getAllCitys({ params }).then(res => {                   
                if (res && res.data.statusCode === 1) {
                    let getcitys = res.data.responseData.result;
                    if (getcitys && getcitys.length > 0) {
                      // need this filter as there can be collisions in cities like ASANSOl which exist both in maharashtra and west bengal
                        getcitys.filter(c => selectedStatesRef.current.includes(c.state.state)).forEach(key => {
                          getCity.push({
                                label: key._id,
                                value: key._id,
                                _id: key._id,
                                state: key.state.state
                            })
                        })                       
                        setselectedCity(getCity);

                        let cities = getcitys.map((city) => city._id).join();  
                        getPincodesFromCities(cities,0); 
                    }                                                                                         
                } else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage);
                }
            })
        } else {
            // remove city and pincodes
            let getPreviousState = selectedState.map((state) => state.value);
            let getCurrentState = e.map((state) => state.value);
            var differenceState = getPreviousState.filter(x => getCurrentState.indexOf(x) === -1);
            if(differenceState && differenceState.length > 0) {
                differenceState.forEach(state => { 
                    if(selectedCity && selectedCity.length > 0) {                        
                        var getSelectedCities = selectedCity.filter(function(el) { return el.state != state; });
                        setselectedCity(getSelectedCities);
                    }
                    
                    // update pincodes
                    let getCurrentState = e.map((s) => s.label); 
                    setPincodeAfterRemoveState(getCurrentState);  
                });
            }               
        }
        getSelectedState = e;
        setSelectedState(getSelectedState);
    } else {
        let emptySelectedState = [];
        setSelectedState(emptySelectedState);
    }     
  }

  const serviceCitySelect = (e) => {
    setIsmounted(false);
    let getSelectedCity = []
    if(e && e.length > 0) {
        if(e.length > selectedCity.length) {   
            let getcities = e[e.length - 1].label;
            getPincodesFromCities(getcities,0);                
        } else {
            let getPreviousCities = selectedCity.map((city) => city.value);
            let getCurrentCities = e.map((city) => city.value);
            var differenceCities = getPreviousCities.filter(x => getCurrentCities.indexOf(x) === -1);            
            if(differenceCities && differenceCities.length > 0) {                
                differenceCities.forEach(city => { 
                    setPincodeAfterRemoveCity(e);  
                });
            }     
        }
        getSelectedCity = e;
        setselectedCity(getSelectedCity);
    } else {
      setselectedCity(getSelectedCity);
    }  
  }

  // for city
  const loadCityServices = (key) => {
    let params = { search: key, sortField: "_id", sortType: 1 };    
    if (selectedState.length > 0) {
      params["states"] = selectedState.map((city) => city._id).join();
    }
  
    return getAllCitys({ params }).then((res) => {      
        if (res && res.data.statusCode === 1) {        
          let citys = res.data.responseData.result;
          let cities = citys.map((data) => {
            return { _id: data._id, label: data._id, value: data._id, state: data.state.state };
          });
          return cities;
        } else if (res.data.statusCode == 0) {
          console.log(res.data.error.errorMessage);
        }
    });
  };

  const getPincodesFromCities = (getcities, pageNo) => {  
    const { statePageNo, stateCount, cityCount, pincodeCount, states, city, pincode } = state;  
    let getPincode = pincode;
    if (getcities) {
        let params = {
            count: pincodeCount,
            pageNo: pageNo,
            pincodeSearch: "",
            pincodes: [],
            citys: getcities
        };

        return getAllPincodes({ params }).then((res) => {
            if (res && res.data.statusCode === 1) {
                let data = res.data.responseData;
                let result = data[0].result;
                let totalPage = data[1]

                if (result && result.length > 0) {
                    result.filter(p => selectedStatesRef.current.includes(p.state)).forEach(key => {
                      getPincode.push({
                            label: key.pincode,
                            value: key._id,
                            _id: key._id,
                            city: key.city,
                            state: key.state
                        })
                    })
                    setState({ ...state, pincode: getPincode });
                    setPincodes();
                }               
            } else if (res.data.statusCode == 0) {
                console.log(res.data.error.errorMessage);
            }
        })
    } 
  }

  const setPincodeAfterRemoveState = (getCurrentState) => {
    const { pincode } = state; 
    if(pincode && pincode.length > 0) {      
        const getCurrentPincode = pincode.filter(el => getCurrentState.includes(el.state));
        state.pincode = getCurrentPincode;
        setPincodes();                       
    }           
  }

  const setPincodeAfterRemoveCity = (getCurrentCity) => {
    let getCurrentCityName = getCurrentCity.map((s) => s.label);
    let getCurrentStateName = getCurrentCity.map((s) => s.state);

    const { pincode } = state; 
    if(pincode && pincode.length > 0) {   
        const getCurrentPincode = pincode.filter(p => getCurrentCityName.includes(p.city) && getCurrentStateName.includes(p.state));
        state.pincode = getCurrentPincode;
        setPincodes();                       
    } 
  } 


  // assign add filter pincode
  const setPincodes = () => {
    const { pincode } = state; 
    if(pincode && pincode.length > 0) {  
      let getPincodes = pincode.map((pin) => pin.label);   
      const currentSelectPincodes = getPincodes.filter(value => !removed.includes(value));         
      setFilteredpincodes(currentSelectPincodes);   

      const currentRemovePincodes = getPincodes.filter(value => removed.includes(value));
      setRemoved(currentRemovePincodes);         
    }
  }



  let [renderkey, setRenderkey] = useState(1);
  let [removekey, setRemoveKey] = useState(1);
  let [removed, setRemoved] = useState([]);

  let removeChip = (index) => {
    let _filteredpincodes = filteredpincodes;

    let removeitems = removed;
    removeitems.push(_filteredpincodes[index]);

    setRemoved(removeitems);

    setRemoveKey(removekey + 1)

    _filteredpincodes.splice(index, 1);

    setFilteredpincodes(_filteredpincodes);
    let length = _filteredpincodes.length;
    setFilteredCount(length);
    setRenderkey(renderkey + 1);
  };

  let addChip = (data, index) => {
    let removeitems = removed;

    removeitems.splice(index, 1);

    setRemoved(removeitems);

    setRemoveKey(removekey + 1);

    setRenderkey(renderkey + 1);

    let _filteredpincodes = filteredpincodes;

    _filteredpincodes.unshift(data);

    setFilteredCount(_filteredpincodes.length);

    setFilteredpincodes(_filteredpincodes);
  };

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    return (
      <div className="cm-state1" key={index} style={style}>
        {filteredpincodes[index]}
        <button
          onClick={() => {
            removeChip(index);
          }}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  function removedrowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    return (
      <div className="cm-state2" key= {index + 'removed'} style={style}>
        {removed[index]}
        <svg
          onClick={() => {
            addChip(removed[index], index);
          }}
          width="5em"
          height="3em"
          viewBox="0 0 16 16"
          className="bi bi-check cursor-pointer"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
          />
        </svg>
      </div>
    );
  }

  
  const renderOptions = (val) => {
    if (val === "city") {
      return (
        <div>
          < AsyncSelect
              isMulti
              isSearchable
              require={true}
              // cacheOptions
              onChange={(val) => serviceCitySelect(val)}
              placeholder="city"
              options={state.city}
              value={selectedCity}
              loadOptions={loadCityServices}
          />

          {newError && newError["selectedCity"] && (
            <label style={{ color: "red" }}>{newError["selectedCity"]}</label>
          )}
        </div>
      );
    }
    if (val === "state") {
      return (
        <div key={renderkey}>
          < Select
              isMulti
              isSearchable
              require={true}
              placeholder="state"
              onChange={(e) => serviceStateSelect(e)}
              options={state.states}
              value={selectedState}
          />

          {newError && newError["selectedState"] && (
            <label style={{ color: "red" }}>{newError["selectedState"]}</label>
          )}
        </div>
      );
    }
  };

  const generateLatestTemplate = () => {
    if (!_.isEmpty(filteredpincodes)) {
      generateServiceableVendorUploadTemplate({ pincodes: filteredpincodes })
      .then(res => {
          let response = res.data;
          console.log('res', response);
          if (response.statusCode === 1) {
              window.open(response.responseData.result.Location, "_blank");
          } else if (response.statusCode == 0) {
              console.log(response.error.errorMessage)
          }
      })
      .catch(err=>{
          console.log('err',err);
      })
    }
  }

  const generateExistingCostingDataForVendor = () => {
    getExistingUserEntries({ vendorId: selectedVendor._id, pincodes: filteredpincodes })
      .then(res => {
          let response = res.data;
          console.log('res', response);
          if (response.statusCode === 1) {
              window.open(response.responseData.result.Location, "_blank");
          } else if (response.statusCode == 0) {
              console.log(response.error.errorMessage)
          }
      })
      .catch(err=>{
          console.log('err',err);
      })
  }

  const handleFileDrop = (accepted, rejected) => {
      if (accepted && accepted.length) {
          setState({ ...state, requestsExcelSheet: accepted[0], error: '' })
      }
      else if (rejected && rejected.length) {
          setState({ ...state, error: "This file format is not supported. Format should be 'xls, xlsx or csv'", requestsExcelSheet: '' })
      }
  }
  
    return (
    <Popup
      className="addvender-form"
      open={addVendorModal}
      closeOnDocumentClick={false}
      closeOnEscape
      lockScroll={true}
      onClose={() => {
        hide();
      }}
      key={actionType}
    >
      <div key={actionType} className="cm_modal pl-5 pr-5 modal-width550">

        <div className="text-center mb-4 mt-5">
          <button type="button" class="close" onClick={() => hide()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </div>

        <h3 className="text-center mb-4 mt-5">{actionType}</h3>
        {state && state.error && (
          <div className="text-danger text-center">{state.error}</div>
        )}
        <Fragment>
          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>firstname</label>
              <input
                type={"text"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "firstname")}
                name={"firstname"}
                value={state.firstname}
                placeholder={"Firstname"}
              />

              {newError && newError.firstname && (
                <label style={{ color: "red" }}>{newError.firstname}</label>
              )}
            </div>
          </Fragment>
            <Fragment>
                <label style={{ ...style }}>Technician Type</label>
                <Select
                    className={`cm_min_width min_height_new`}
                    onChange={(e) => handleChange(e, e.value, "technicianType")}
                    placeholder="Choose Technician Type"
                    value = {state.technicianType ? TechnicianTypes.find(element => element.value === state.technicianType) : TechnicianTypes[1]}
                    options={TechnicianTypes}
                    optionClassName="option-AS"
                    menuPlacement="auto"
                    classNamePrefix="custom"
                />
            </Fragment>

          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>lastname</label>
              <input
                type={"text"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "lastname")}
                name={"lastname"}
                value={state.lastname}
                placeholder={"Lastname"}
              />
              {newError && newError.lastname && (
                <label style={{ color: "red" }}>{newError.lastname}</label>
              )}
            </div>
          </Fragment>
          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>email</label>
              <input
                type={"email"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "email")}
                name={"email"}
                value={state.email}
                placeholder={"Email"}
                disabled={actionType == 'Update Vendor'}
              />
              {newError && newError.email && (
                <label style={{ color: "red" }}>{newError.email}</label>
              )}
            </div>
          </Fragment>
          <Fragment>
            <div style={{ ...styleForWrapper }} className="form-group">
              <label style={{ ...style }}>mobile</label>
              <input
                type={"number"}
                className="form-control"
                onChange={(e) => handleChange(e, e.target.value, "mobile")}
                name={"mobile"}
                value={state.mobile}
                placeholder={"Mobile"}
                maxLength={10}
              />
              {newError && newError.mobile && (
                <label style={{ color: "red" }}>{newError.mobile}</label>
              )}
            </div>
          </Fragment>
          {labels.map((label, index) => {
            return (
              <div style={{ ...styleForWrapper }} key={index} className="form-group">
                <label style={{ textTransform: "capitalize" }}>{label}</label>
                {renderOptions(label)}
              </div>
            );
          })}
          { (filteredpincodes || removed.length > 0) ? (
            <div className="row cm-state-container">
              <div key={removekey} className="state-wrapper col-6">
                {removed && removed.length > 0 ? (
                  <List
                    className="cm-state"
                    width={400}
                    height={300}
                    rowCount={removed.length}
                    rowHeight={20}
                    rowRenderer={removedrowRenderer}
                    key={renderkey + 9}
                  />
                ) : (
                    ""
                  )}
              </div>

              <div key={renderkey} className="state-wrapper col-6">
                <List
                      width={400}
                      height={300}
                      rowCount={filteredpincodes.length}
                      rowHeight={20}
                      rowRenderer={rowRenderer}
                      // onRowsRendered={onRowsRendered}
                      key={renderkey + 7}
                      className="cm-state"
                    />
              </div>
            </div>
            ) : (
              ""
          )}
        </Fragment>

        {!isUpdateModal ? <div className="row col-md-4">
            <Button variant="info" onClick={generateLatestTemplate} size="sm">Download Costing Template</Button>
        </div> :
        <div className="row col-md-4">
          <Button variant="info" onClick={generateExistingCostingDataForVendor} size="sm">Download Existing Costing Data</Button>
        </div> 
        }

        <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
          {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
              <section>
                  <div {...getRootProps()} className="dropzone text-center">
                    <input {...getInputProps()} />
                    <i className="fa fa-cloud-upload" />
                    {isDragActive ? (<p>Drop it like super hot!</p>) : (acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>)}
                  </div>
              </section>
          )}
        </Dropzone>

        <button
          onClick={handleSubmit}
          className="btn btn-primary btn-block mt-2"
        >
          Save
        </button>
      </div>
    </Popup>
  );
};

export default addVendor;