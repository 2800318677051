import axios from 'axios';
import API from '../Api';

const accessToken = localStorage.getItem('accessToken');

export function Post(params) {
    return axios({
        method: 'post',
        url: API.USER_CHANGE_PASSWORD,
        data: params,
    })
}


export function forgotPassword(params) {
    return axios({
        method: 'post',
        url: API.ADMIN_FORGET_PASSWORD,
        data: params,
    })
}

export const ResetPassword = {
    Post,
    forgotPassword
};