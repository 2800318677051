import axios from 'axios';
import API from '../Api';

export function getProductServiceIncidentList(params) {
    return axios.get(API.GET_PRODUCTSERVICEINCIDENT_LIST, params)
}

export function addNoteToServicerequest (params) {
    return axios.post(API.ADD_NOTE_TO_REQUEST, params)
}

