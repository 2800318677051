import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getTransactions, changeStatusReward, updateDeliveryStatus } from '../../config/services/rewardTransactionService';
import { toast } from 'react-toastify';
import ExportExcel from "../deliveryRiders/ExportExcel";
import moment from 'moment';
import { DatePicker, Space } from 'antd';
import { Dropdown, Button, Modal, InputGroup, FormControl, Alert } from 'react-bootstrap';
import SearchSpinner from '../common/SearchSpinner';
const { RangePicker } = DatePicker;


class RewardTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            transactionsList: [],
            accessiblePaths: [],
            roleName: '',
            search: '',
            count: 10,
            totalTransactions: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            AddRolePopup: false,
            deleteRolePopup: false,
            viewRolePopup: false,
            title: '',
            imagePreview: '',
            titleErrorMsg: '',
            editRolePopup: false,
            tempRoleId: "",
            acceptOrRejectPopup: false,
            transactionStatus: '',
            updateTackingIdPopup: false,

            viewDetailsPopup: false,
            userDetails: {},
            productDetails: {},
            address: {},
            startDate: moment().startOf('day').valueOf(),
            endDate: moment().endOf('day').valueOf(),
            searchStatus: false
        }
    }

    componentDidMount() {
        this.getTransactions();
    }


    downloadExcel = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField,
                dateFrom: this.state.startDate, dateTo: this.state.endDate, download: 1
            }
        }
        getTransactions(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    // console.log("kjhgddj", response)
                    window.open(response.responseData.file, "_blank")

                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })

    }

    getTransactions = () => {
        let { search, count, pageNo, sortType, sortField, startDate, endDate, searchStatus } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField, dateFrom: startDate, dateTo: endDate
            }
        }
        getTransactions(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        transactionsList: response.responseData.result,
                        totalTransactions: response.responseData.totalCount,
                        loader: false,
                        searchStatus: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    closeModal = () => this.setState({});

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, titleErrorMsg: '' })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? -1 : 1 }, () => this.getTransactions());

    handleSearch = e => {
        this.setState({ search: e.target.value, searchStatus: true }, () => this.getTransactions());
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getTransactions() });

    getStatus = (status) => {
        switch (status) {
            case 1:
                return 'Pending'
            case 2:
                return 'Accepted'
            case 3:
                return 'Rejected'
            case 4:
                return 'Cancelled'
            default:
                return "-"
        }
    }

    changeLeaveStatus = (transaction, status) => {
        this.setState({ transaction, transaction, transactionStatus: status, acceptOrRejectPopup: true })
    }


    closeModal = () => this.setState({
        acceptOrRejectPopup: false,
    });

    submitChangeLeaveStatus = (e) => {
        e.preventDefault();
        const { transaction, transactionStatus, } = this.state

        let paramsObj = {
            transactionId: transaction._id,
            status: transactionStatus,

        }

        this.setState({ loader: true })
        changeStatusReward(paramsObj)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getTransactions()
                    this.closeModal()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handleFilter = () => {

    }

    updateDeliveryStatus = (transaction, status) => {
        let paramsObj = {
            transactionDetailsId: transaction._id,
            deliveryRequest: status
        }
        this.setState({ loader: true })
        this.updateDeliveryStatusApi(paramsObj)
    }


    updateDeliveryStatusApi = (paramsObj) => {
        updateDeliveryStatus(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.getTransactions()
                    this.handleClose()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    updateTrackingId = () => {
        let { tempTransactionDetailsId, trackingId } = this.state
        let paramsObj = {
            transactionDetailsId: tempTransactionDetailsId,
            trackingId
        }
        if (!trackingId) {
            this.setState({ titleErrorMsg: "Tracking Id* is mandatory" })
        } else {
            this.setState({ loader: true })
            this.updateDeliveryStatusApi(paramsObj)
        }
    }

    updateTrackingIdPopup = (transaction) => {
        this.setState({
            updateTackingIdPopup: true,
            tempTransactionDetailsId: transaction._id,
            trackingId: transaction.trackingId
        })

    }


    handleClose = () => {
        this.setState({
            updateTackingIdPopup: false,
            viewDetailsPopup: false,
        })
    }

    parseDeliveryRequestStatus = (status) => {
        switch (status) {
            case 1:
                return "Pending";
            case 2:
                return "Delivered";
            case 3:
                return "Undelivered";
            case 4:
                return "Cancelled";
        }
    }

    viewTrackingIdPopup = (transaction) => {
        let address = {
            deliveryRequest: transaction.deliveryRequest,
            trackingId: transaction.trackingId,
            userId: transaction.userId,
            addressLine1: transaction.addressLine1,
            addressLine2: transaction.addressLine2,
            city: transaction.city,
            state: transaction.state,
            country: transaction.country,
            zip: transaction.zip,
        }
        this.setState({ viewDetailsPopup: true, userDetails: transaction.userDetails, productDetails: transaction.productDetails, address })
    }

    dateChange = (dates, dateStrings) => {
        let startDate = dates ? moment(dates[0]).startOf('day').valueOf() : null
        let endDate = dates ? moment(dates[1]).endOf('day').valueOf() : null
        this.setState({ startDate, endDate }, () => {
            this.getTransactions()
        })
    }


    render() {
        const {
            loader,
            transactionsList,
            search,
            pageNo,
            count,
            totalTransactions,
            acceptOrRejectPopup,
            transactionStatus,
            updateTackingIdPopup,
            titleErrorMsg,
            trackingId,
            viewDetailsPopup,
            userDetails,
            productDetails,
            address,
            searchStatus
        } = this.state;


        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Reward Transactions</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="cm_page_heading">Reward Transactions</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search position-relative">
                                        {searchStatus ?
                                            <SearchSpinner type="text-info" />
                                            : null}
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by Name, Email or Product Name"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" />
                                    </div>
                                </div>
                                <div class="row col-sm-12 align-items-center">
                                    <div className="mr-3">
                                        <RangePicker
                                            // disabledDate={disabledDate}
                                            defaultValue={[moment(this.state.startDate), moment(this.state.endDate)]}
                                            onChange={this.dateChange}
                                            dropdownClassName="upar_index"
                                        />

                                    </div>
                                    <div>
                                        <a className="btn btn-primary btn-block" style={{ color: 'white' }} onClick={this.downloadExcel}>Export To Excel</a>
                                    </div>
                                </div>
                                {/* <ExportExcel riderList={transactionsList} /> */}
                            </div>
                        </div>
                        <div className="table-responsive custom-table-view">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('firstName')}>User's Name &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('email')}>User's Email &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('productName')}>Product's Name &nbsp;  <i className="fa fa-sort" />
                                        </th>

                                        <th scope="col" className="filter_text">Tracking Id
                                        </th>
                                        <th id="filter_text" className={this.state.FilterStatus ? 'filter_text open' : 'filter_text'} onClick={this.toggleFilterStatus}>
                                            Delivery Status <i className="fa fa-filter" />
                                            <ul>
                                                <li onClick={() => this.handleFilter(1)}>
                                                    Pendind
                                                </li>
                                                <li onClick={() => this.handleFilter(2)}>
                                                    Delivered
                                                </li>
                                                <li onClick={() => this.handleFilter(3)}>
                                                    Undelivered
                                                </li>
                                                <li onClick={() => this.handleFilter(4)}>
                                                    Cancelled
                                                </li>
                                                <li onClick={() => this.handleFilter('')}>
                                                    All
                                                </li>
                                            </ul>
                                        </th>

                                        <th scope="col" className="filter_text">Action &nbsp; </th>


                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('coinsUsed')}>Coins Used &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Status &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className="fa fa-sort" />
                                        </th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        transactionsList.map((transaction, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">
                                                        {transaction.userDetails.firstName + " " + transaction.userDetails.lastName}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {transaction.userDetails.email}</span>
                                                    </td>

                                                    <td><span className="cm_table_three_dots">
                                                        {transaction.productDetails.productName}</span>
                                                    </td>
                                                    <td>
                                                        {
                                                            transaction.productDetails.productType == 1 ?

                                                                <span className="cm_table_three_dots">
                                                                    <Button className="cm_trackingId" variant="outline-info"
                                                                        onClick={() => this.updateTrackingIdPopup(transaction)}>
                                                                        {transaction.trackingId ? transaction.trackingId : "Set"}
                                                                    </Button>
                                                                </span>
                                                                : 'N/A'
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            transaction.productDetails.productType == 1 ?
                                                                <Dropdown className="cm_dropdown">
                                                                    <Dropdown.Toggle className="cm_trackingId" variant="outline-primary" id="dropdown-basic">
                                                                        {this.parseDeliveryRequestStatus(transaction.deliveryRequest)}
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 1)}>Pending</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 2)}>Delivered</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 3)}>Undelivered</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => this.updateDeliveryStatus(transaction, 4)}>Cancel</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                : 'N/A'
                                                        }
                                                    </td>
                                                    <td className="cm_no_wrap">
                                                        <Button variant="outline-info"
                                                            onClick={() => this.viewTrackingIdPopup(transaction)}>
                                                            View
                                                        </Button>
                                                    </td>




                                                    <td><span className="cm_table_three_dots">
                                                        {transaction.coinsUsed}</span>
                                                    </td>


                                                    <td className="cm_no_wrap">
                                                        {transaction.status == 1 ?
                                                            <div className="d-flex">

                                                                <Dropdown className="cm_dropdown">
                                                                    <Dropdown.Toggle className="cm_trackingId" variant="outline-primary" id="dropdown-basic">
                                                                        {this.parseDeliveryRequestStatus(transaction.deliveryRequest)}
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item>Pending</Dropdown.Item>
                                                                        <Dropdown.Item onClick={(val) => this.changeLeaveStatus(transaction, 2)} >Accept</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => this.changeLeaveStatus(transaction, 3)} >Reject</Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>



                                                                {/* <div className="col">
                                                                <i className="fa fa-check-circle cm_accept" title="Accept"
                                                                    onClick={(val) => this.changeLeaveStatus(transaction, 2)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <i className="fa fa-times-circle-o cm_reject" title="Reject"
                                                                    onClick={(val) => this.changeLeaveStatus(transaction, 3)}
                                                                />
                                                            </div> */}
                                                            </div> : <p style={{ color: this.getStatus(transaction.status) == "Rejected" ? 'red' : this.getStatus(transaction.status) == "Accepted" ? 'green' : 'gray' }}>{this.getStatus(transaction.status)}</p>
                                                        }
                                                    </td>
                                                    <td className="cm_no_wrap">{dateFormat(transaction.created, "d mmmm yyyy")}</td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalTransactions}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!transactionsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>


                        <Modal
                            show={acceptOrRejectPopup}
                            onHide={this.closeModal}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            backdrop="static"
                        >
                            <Modal.Header>
                                <Modal.Title>Are you sure, you want to
                                    {transactionStatus === 2 ? " accept" : transactionStatus === 3 ? " reject" : "-"} this request?</Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="text-center">
                                <button className="btn btn-danger cm_danger" onClick={this.closeModal}>No</button> &nbsp; &nbsp;
                                <button className="btn btn-success" onClick={this.submitChangeLeaveStatus} >Yes</button>
                            </Modal.Body>
                        </Modal>

                        <Modal show={updateTackingIdPopup} backdrop="static"  onHide={this.handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Update Tracking Id</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text># TrackingId</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="Enter tracking id" name="trackingId" value={trackingId}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>

                                {titleErrorMsg && <Alert variant="danger"> {titleErrorMsg} </Alert>}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={this.updateTrackingId}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>



                        <Modal show={viewDetailsPopup} backdrop="static" onHide={this.handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{userDetails.firstName + " " + userDetails.lastName}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                Product:             {productDetails.productName}
                                <br /> Address line 1:{address.addressLine1}
                                <br /> Address line 2:{address.addressLine2}
                                <br /> City:          {address.city}
                                <br /> State:         {address.state}
                                <br /> Country:       {address.country}
                                <br /> Zip:           {address.zip}
                                <br /> Mobile:        {userDetails.fullMobileNo}
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="danger" onClick={this.handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>


                    </div>
                </div>
            </HOC>
        )
    }
}



export default RewardTransactions;