import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Link,withRouter } from 'react-router-dom';
import { getAllDataCount } from '../../config/services/usersService';
import { TreadmillSvg, CouponsSvg, LoaderSvg } from '../Reusable';
import Popup from "reactjs-popup"

class DeshbordChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Loader: true,
            AllCountData: {},
            sessionExpired:false,
        }
    }


    componentDidMount() {
        let token = localStorage.getItem('accessToken');
        if (token) {
            getAllDataCount()
                .then(res => {
                    if (res.data.statusCode == 1) {
                        this.setState({ AllCountData: res.data.responseData.totalCounts, Loader: false, })
                    }else{
                        this.setState({Loader: false, })
                    }
                })
        }
    }

    render() {
        let { coupon, device, user } = this.state.AllCountData;
        return (
            <div className="chart_wrapper">
                <div className="row mb-3">
                    <div className="col-lg-4 col-md-6 mb-3">
                        <Link to="/users">
                            <div className="cm_card firstbox-card">
                                <span className="all_users">
                                    <i className="fa fa-users" aria-hidden="true"></i>
                                </span>
                                <h3>Users.</h3>
                                <h2>{user}</h2>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6 mb-3">
                        <Link to="/device">
                            <div className="cm_card">
                                <span className="all_users">
                                    <TreadmillSvg />
                                </span>
                                <h3>Device.</h3>
                                <h2>{device}</h2>
                            </div>
                        </Link>
                    </div>



                    <div className="col-lg-4 col-md-6">
                        <Link to="/coupons">
                            <div className="cm_card lastbox-card">
                                <span className="all_users">
                                    <CouponsSvg />
                                </span>
                                <h3>Coupons.</h3>
                                <h2>{coupon}</h2>
                            </div>
                        </Link>
                    </div>
                </div>

                {this.state.Loader
                    ? <div className="loader_wrapper"><LoaderSvg /></div>
                    : null
                }

            </div>
        )
    }
}

export default DeshbordChart;