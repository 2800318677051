
function calorieBreakDown(selectedValue) {

    let perDayCalories = selectedValue.value;
    let recommendedMorningSnacksCalories = 0;
    let recommendedBreakfastCalories = 0;
    let recommendedMidMorningCalories = 0;
    let recommendedLunchCalories = 0;
    let recommendedMidEveningCalories = 0;
    let recommendedDinnerCalories = 0;
    let recommendedBedTimeCalories = 0;

    switch (selectedValue.value) {
        case 1200:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 300;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 300;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 300;
            recommendedBedTimeCalories = 50;
            break;
        case 1250:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 300;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 300;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 300;
            recommendedBedTimeCalories = 100;
            break;
        case 1300:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 300;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 325;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 325;
            recommendedBedTimeCalories = 100;
            break;
        case 1350:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 300;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 350;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 350;
            recommendedBedTimeCalories = 100;
            break;
        case 1400:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 350;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 350;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 350;
            recommendedBedTimeCalories = 100;
            break;
        case 1450:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 350;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 375;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 375;
            recommendedBedTimeCalories = 100;
            break;
        case 1500:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 350;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 400;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 400;
            recommendedBedTimeCalories = 100;
            break;
        case 1550:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 400;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 400;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 400;
            recommendedBedTimeCalories = 100;
            break;
        case 1600:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 350;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 425;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 425;
            recommendedBedTimeCalories = 100;
            break;
        case 1650:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 400;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 450;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 450;
            recommendedBedTimeCalories = 100;
            break;
        case 1700:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 400;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 475;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 475;
            recommendedBedTimeCalories = 100;
            break;
        case 1750:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 400;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 500;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 500;
            recommendedBedTimeCalories = 100;
            break;
        case 1800:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 450;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 500;
            recommendedMidEveningCalories = 100;
            recommendedDinnerCalories = 500;
            recommendedBedTimeCalories = 100;
            break;
        case 1850:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 450;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 500;
            recommendedMidEveningCalories = 150;
            recommendedDinnerCalories = 500;
            recommendedBedTimeCalories = 100;
            break;
        case 1900:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 450;
            recommendedMidMorningCalories = 100;
            recommendedLunchCalories = 500;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 500;
            recommendedBedTimeCalories = 100;
            break;
        case 1950:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 450;
            recommendedMidMorningCalories = 150;
            recommendedLunchCalories = 500;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 500;
            recommendedBedTimeCalories = 100;
            break;
        case 2000:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 500;
            recommendedMidMorningCalories = 150;
            recommendedLunchCalories = 500;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 500;
            recommendedBedTimeCalories = 100;
            break;
        case 2050:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 500;
            recommendedMidMorningCalories = 200;
            recommendedLunchCalories = 500;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 500;
            recommendedBedTimeCalories = 100;
            break;
        case 2100:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 500;
            recommendedMidMorningCalories = 200;
            recommendedLunchCalories = 525;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 525;
            recommendedBedTimeCalories = 100;
            break;
        case 2150:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 500;
            recommendedMidMorningCalories = 200;
            recommendedLunchCalories = 550;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 550;
            recommendedBedTimeCalories = 100;
            break;
        case 2200:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 500;
            recommendedMidMorningCalories = 200;
            recommendedLunchCalories = 575;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 575;
            recommendedBedTimeCalories = 100;
            break;
        case 2250:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 500;
            recommendedMidMorningCalories = 200;
            recommendedLunchCalories = 600;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 600;
            recommendedBedTimeCalories = 100;
            break;
        case 2300:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 550;
            recommendedMidMorningCalories = 200;
            recommendedLunchCalories = 600;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 600;
            recommendedBedTimeCalories = 100;
            break;
        case 2350:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 600;
            recommendedMidMorningCalories = 200;
            recommendedLunchCalories = 600;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 600;
            recommendedBedTimeCalories = 100;
            break;
        case 2400:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 600;
            recommendedMidMorningCalories = 250;
            recommendedLunchCalories = 600;
            recommendedMidEveningCalories = 200;
            recommendedDinnerCalories = 600;
            recommendedBedTimeCalories = 100;
            break;
        case 2450:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 600;
            recommendedMidMorningCalories = 250;
            recommendedLunchCalories = 600;
            recommendedMidEveningCalories = 250;
            recommendedDinnerCalories = 600;
            recommendedBedTimeCalories = 100;
            break;
        case 2500:
            recommendedMorningSnacksCalories = 50;
            recommendedBreakfastCalories = 600;
            recommendedMidMorningCalories = 250;
            recommendedLunchCalories = 600;
            recommendedMidEveningCalories = 250;
            recommendedDinnerCalories = 600;
            recommendedBedTimeCalories = 100;
            break;
    }

    let respObj = {
        recommendedMorningSnacksCalories,
        recommendedBreakfastCalories,
        recommendedMidMorningCalories,
        recommendedLunchCalories,
        recommendedMidEveningCalories,
        recommendedDinnerCalories,
        recommendedBedTimeCalories,
        perDayCalories
    }

    return respObj
}


module.exports = {
    calorieBreakDown
}